import React, { useRef } from 'react';

/** unform  */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** service */
import api from '../../../services/api';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** compoents */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { Toast } from 'primereact/toast';

const AccessUserGroupCreate = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const formHandle = async (data: any) => {
    try {
      const schemaUserGroup = Yup.object({
        title: Yup.string().required('Por favor, informe um título!'),
      });
      await schemaUserGroup.validate(data, { abortEarly: false });
      await api.post('/user-group-system-access', data);
      navigate.push('/user-access/user-group/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/user-access/user-group/list')}
      />
      <Toast ref={toast} />
      <div className="card md:w-6 sm:w-full">
        <Form ref={formRef} onSubmit={formHandle}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="">Título</label>
              <InputText name="title" placeholder="Ex: Seu Título" />
            </div>
            <div className="field col-12 md:col flex">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger mr-3"
              />
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AccessUserGroupCreate;
