import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import Calendar from '../../../components/Inputs/InputCalendar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { CostCenterModel } from '../../../types/cost';
import { Option } from '../../../types/optional';
import { currencyToFloat, numberToCurrency } from '../../../utils/number';
import BillsToReceivableHeader from '../header';
import IBillsReceivableDTO from './dtos/IBillsReceivableDTO';
import formatCurrency from '../../../utils/numbers/FormatCurrency';

const situationOption = [
  { name: 'Nenhum', value: null },
  { name: 'Pago', value: 'P' },
  { name: 'Aguardando', value: 'A' },
  { name: 'Vencido', value: 'V' },
];

const BillsToReceibleList = (): React.ReactElement => {
  const date = new Date();

  const [loading, setLoading] = useState<boolean>(true);

  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [initialDate, setInitialDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );
  const [situation, setSituation] = useState('');
  const [billsReceivable, setBillsReceivable] = useState<IBillsReceivableDTO[]>(
    [],
  );
  const [costCenter, setCostCenter] = useState<Option[] | undefined>(undefined);
  const [costCenterSelected, setSelectedCostCenter] = useState('');
  const [search, setSearch] = useState('');
  const [menuOption, setMenuOption] = useState({
    bp_id: '',
    id: '',
    status_payment: '',
  });

  const [ttPagas, setTtPagas] = useState<number>(0);
  const [ttPagar, setTtPagar] = useState<number>(0);
  const [ttVencidas, setTtVencidas] = useState<number>(0);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status_payment: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    cost_center_id: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });

  const onGlobalFilterStatusPayment = (value: any) => {
    const _filter = { ...filters };
    _filter['status_payment'].value = value;

    setFilters(_filter);
  };

  const onGlobalFilterCostCenter = (value: any) => {
    const _filter = { ...filters };
    _filter['cost_center_id'].value = value;

    setFilters(_filter);
  };

  const onGlobalFilterChange2 = (e: any) => {
    const value = e.target.value;
    const _filter = { ...filters };
    _filter['global'].value = value;

    setGlobalFilter(value);
  };

  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const menu = useRef<any>(null);

  const navigate = useHistory();

  const cancelBill = async () => {
    try {
      setLoading(true);

      await api.delete(
        `${process.env.REACT_APP_SERVER_URL}/bills-receivable/${menuOption.id}`,
      );

      await handleSearchBills();

      toast('success', 'Sucesso!', 'Conta Cancelada!');
    } catch (error: any) {
      toast('error', 'Error', error.response.data.error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const printPromissory = async () => {
    setLoading(true);

    const { data } = await api.get(`/reports/promissory/${menuOption.id}`);

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
    );

    setLoading(false);
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    data: any,
  ) => {
    // event?.currentTarget?.toggle(event)
    menu?.current?.toggle(event);
    setMenuOption({
      ...menuOption,
      bp_id: data.bp_id,
      id: data.id,
      status_payment: data.status_payment,
    });
  };

  const mountMenu = (): any => {
    const items: Array<any> = [];

    if (
      menuOption.status_payment === 'A' ||
      menuOption.status_payment === 'V'
    ) {
      items.push({
        label: 'Editar',
        icon: 'fa-solid fa-edit',
        command: () => {
          navigate.push(`/bills/receive/edit/${menuOption.id}`);
        },
      });

      items.push({
        label: 'Baixar',
        icon: 'pi pi-arrow-circle-down',
        command: () => {
          navigate.push(`/bills/receive/drop/${menuOption.id}`);
        },
      });

      items.push({
        label: 'Imprimir Promissória',
        icon: 'pi pi-print',
        command: () => {
          printPromissory();
        },
      });
    }

    items.push({
      label: 'Cancelar',
      icon: 'pi pi-trash',
      command: () => {
        cancelBill();
      },
      template: (item: any, options: any) => {
        return (
          <a
            className={options.className}
            target={item.target}
            onClick={options.onClick}
          >
            <span
              className={classNames(options.iconClassName, item.icon)}
              style={{ color: 'red' }}
            ></span>
            <span className={options.labelClassName}>{item.label}</span>
          </a>
        );
      },
    });

    return items;
  };

  const handleSearchBills = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(`/bills-receivable/se/search`, {
        params: {
          searchField: search,
          dateStart: initialDate,
          dateEnd: finalDate,
        },
      });

      setBillsReceivable(data);
    } catch (error: any) {
      toast('error', 'Falha', error.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const filterToCostCenter = (e: string) => {
    setSelectedCostCenter(e);
  };

  const imageBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>{format(new Date(rowData.date_to_pay), 'dd/MM/yyyy')}</span>
      </>
    );
  };

  const valueBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {formatCurrency(
            rowData.status_payment === 'P'
              ? rowData.value
              : rowData.value_total,
          )}
        </span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      rowData.status !== 'C' && (
        <>
          {rowData.status_payment === 'V' && (
            <span className={`product-badge status-outofstock`}>VENCIDO</span>
          )}
          {rowData.status_payment === 'A' && (
            <span className={`customer-badge status-new`}>AGUARDANDO</span>
          )}
          {rowData.status_payment === 'P' && (
            <span className={`product-badge status-instock`}>PAGO</span>
          )}
          {rowData.status_payment === 'C' && (
            <span className={`product-badge status-outofstock`}>CANCELADA</span>
          )}
        </>
      )
    );
  };

  const renderColumnStatus = (rowData: any) => {
    return (
      <>
        {rowData.status === 'A' && (
          <span className={`product-badge status-instock`}>Ok</span>
        )}
        {rowData.status === 'C' && (
          <span className={`product-badge status-outofstock`}>C</span>
        )}
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    const mountOption = mountMenu();
    const id = nanoid();
    return (
      <>
        <Menu model={mountOption} popup data-menu={rowData.id} ref={menu} />
        <Button
          icon="pi pi-bars"
          onClick={event => openMenu(event, id, rowData)}
          disabled={rowData.status === 'C' ? true : false}
        />
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">Recebidas</h5>
          <span className={`product-badge status-instock`}>
            {new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(ttPagas)}
          </span>
        </div>
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">A receber</h5>
          <span className={`customer-badge status-new`}>
            {new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(ttPagar)}
          </span>
        </div>
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">Atrasadas</h5>
          <span className={`product-badge status-outofstock`}>
            {new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(ttVencidas)}
          </span>
        </div>
      </div>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={onGlobalFilterChange2}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  useEffect(() => {
    handleSearchBills();
  }, []);

  useEffect(() => {
    const summary = billsReceivable.reduce(
      (acc, item) => {
        if (item.status === 'A') {
          if (item.status_payment === 'P') {
            acc.pagas += Number(item.value);
          }

          if (item.status_payment === 'V') {
            acc.vencidas += Number(item.value);
          }

          if (item.status_payment === 'A' || item.status_payment === 'V') {
            acc.pagar += Number(item.value);
          }
        }

        return acc;
      },
      {
        pagas: 0,
        pagar: 0,
        vencidas: 0,
      },
    );

    setTtPagar(summary.pagar);
    setTtPagas(summary.pagas);
    setTtVencidas(summary.vencidas);
  }, [billsReceivable]);

  useEffect(() => {
    api.get(`/cost-center`).then(({ data }) => {
      const costCenterOption = data.map((item: CostCenterModel) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      costCenterOption.unshift({ label: 'Nenhum', value: null });
      setCostCenter(costCenterOption);
    });
  }, []);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <BillsToReceivableHeader />
        <div className="card">
          <Form ref={formRef} onSubmit={handleSearchBills}>
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Busca Inteligente</label>

                <div className="p-inputgroup">
                  <Button label="Pesquisar" />
                  <InputText
                    placeholder="Ex.: João da silva..."
                    name="search"
                    onChange={e => setSearch(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Data Inicial</label>
                <Calendar
                  name="initial_date"
                  value={initialDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e: any) => setInitialDate(e.value)}
                  maxDate={finalDate}
                  showIcon
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Data Final</label>
                <Calendar
                  name="final_date"
                  value={finalDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e: any) => setFinalDate(e.value)}
                  minDate={initialDate}
                  showIcon
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">Status</label>
                <Dropdown
                  inputId="dropdown"
                  value={situation}
                  placeholder="Selecionar..."
                  options={situationOption}
                  onChange={e => {
                    setSituation(e.value);
                    onGlobalFilterStatusPayment(e.value);
                  }}
                  optionLabel="name"
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">Centro de Custo</label>
                <Dropdown
                  value={costCenterSelected}
                  options={costCenter}
                  placeholder="Selecionar..."
                  filterBy="label"
                  onChange={e => {
                    onGlobalFilterCostCenter(e.value);
                    filterToCostCenter(e.value);
                  }}
                  optionLabel="label"
                />
              </div>
              <div className="field col-12 md:col-4 flex align-items-end">
                <label htmlFor=""></label>
                <Button
                  label="Filtrar contas"
                  type="submit"
                  icon="pi pi-filter"
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </div>
        <div className="col">
          <div className="grid">
            <div className="col card">
              <DataTable
                value={billsReceivable}
                selection={selectedProducts}
                onSelectionChange={e => setSelectedProducts(e.value)}
                dataKey="id"
                paginator
                loading={loading}
                loadingIcon="pi pi-spinner text-primary"
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                filters={filters}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} contas"
                globalFilter={globalFilter}
                emptyMessage="Não existe contas."
                header={header}
                responsiveLayout="scroll"
                size="small"
              >
                <Column header="#" body={renderColumnPosition}></Column>
                <Column field="customer" header="Cliente" sortable></Column>
                <Column field="document_sr" header="Sr" sortable></Column>
                <Column field="document_num" header="N." sortable></Column>
                <Column field="parcel_sr" header="Parcela"></Column>
                <Column header="Vencimento" body={imageBodyTemplate}></Column>
                <Column
                  field="value"
                  header="Total"
                  sortable
                ></Column>
                <Column
                  field="value_total"
                  header="A receber"
                  sortable
                ></Column>
                <Column
                  field="center_cost"
                  hidden
                  header="Centro de custo"
                ></Column>
                <Column
                  field="status_payment"
                  header="Situação"
                  align={'center'}
                  body={statusBodyTemplate}
                  sortable
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  align={'center'}
                  body={e => renderColumnStatus(e)}
                ></Column>
                <Column header="*" body={actionBodyTemplate}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: Window, nextProps: Window) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BillsToReceibleList, comparisonFn);
