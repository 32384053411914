/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** Joi */
import * as Yup from 'yup';

import useToastContext from '../../../hooks/toast';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/* Components */
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';

import { AxiosError } from 'axios';
import { ProgressBar } from 'primereact/progressbar';
import api from '../../../services/api';

type AdditionalModel = {
  title: string;
  limit: number;
  required: string;
  description: string;
};

const AdditionalCreate = (): React.ReactElement => {
  /* state */
  const [limitAdd, setLimitAdd] = useState<number>(9);
  const [totalSize, setTotalSize] = useState(0);

  const [loading, setLoadingStatus] = useState<boolean>(false);

  const [titulo, setTitulo] = useState<string>();
  const [limite, setLimite] = useState<number>(10);
  const [obrigatorio, setObrigatorio] = useState<string>('N');
  const [descricao, setDescricao] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const fileUploadRef = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    if (props.files.length > 1) {
      props.files[0] = props.files[1];

      onTemplateRemove(file, props.onRemove);
    }

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-plus',
    label: 'Escolher Imagem',
    className: 'custom-choose-btn',
  };

  const handleSubmit = async (data: AdditionalModel) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        title: Yup.string().required('Por favor, informe um título'),
        description: Yup.string().nullable(),
        limit: Yup.number()
          .typeError('Informe um número')
          .required('Por Favor, informe um limite'),
        required: Yup.string(),
      });

      await validation.validate(data, { abortEarly: false });

      const res = await api.post('/additionals', {
        title: titulo,
        description: descricao,
        imagem_url: null,
        limit: limite,
        required: obrigatorio,
        status: 'A',
      });

      if (fileUploadRef.current.files) {
        const form = new FormData();
        form.append('image_url', fileUploadRef.current.files[0]);
        await api.patch(`/additionals/image/${res.data.id}`, form);
      }

      toast('success', 'Sucesso', 'Adicional criado com sucesso!');

      navigate.push('/additionals/list');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', e.response?.data.validation.body.message);
        }
      }

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/additionals/list')}
      />
      {loading && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-9 sm:w-full"
        />
      )}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <div className="card md:w-9 sm:w-full">
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Título</label>
              <InputText
                name="title"
                placeholder="Título Adicional"
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Limite</label>
              <InputNumber
                name="limit"
                value={limite}
                onValueChange={e => setLimite(Number(e.value))}
                showButtons
                min={1.0}
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                useGrouping={false}
              />
            </div>
            <div className="field col-12 md:col-3 flex align-items-end justify-content-center">
              <label htmlFor="required">Obrigatório</label>
              <InputSwitch
                trueValue="S"
                falseValue="N"
                name="required"
                checked={obrigatorio}
                onChange={e => setObrigatorio(e.value === true ? 'S' : 'N')}
                className="ml-3"
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Imagem</label>
              <FileUpload
                ref={fileUploadRef}
                name="file"
                maxFileSize={100000}
                multiple={false}
                accept="image/*"
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                chooseOptions={chooseOptions}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                invalidFileSizeMessageSummary=""
                invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic" className="mb-2">
                Descrição
              </label>
              <br />
              <InputTextEdit name="description" style={{ height: 275 }} />
            </div>
          </div>
          <div className="col-12 flex flex-wrap p-0">
            <div className="col pl-0"></div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loading}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AdditionalCreate;
