import React, { useRef, useState } from 'react';

/* unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** service */
import api from '../../../services/api';

/* components */
import { InputText } from '../../../components/Inputs/InputText';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { Button } from 'primereact/button';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';

const CostCenterCreate = (): React.ReactElement => {
  /* ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** entry */
  const [entry, setEntry] = useState('E');

  /** navigate */
  const navigate = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schemaCostCenter = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
        description: Yup.string(),
        kind: Yup.string(),
      });
      await schemaCostCenter.validate(data, { abortEarly: false });
      await api.post('/cost-center', data);
      navigate.push('/cost-center/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/cost-center/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form onSubmit={handleSubmit} ref={formRef}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Título</label>
              <InputText name="title" placeholder="Título" />
            </div>
            <div className="field col-12 md:col-4 flex align-items-center">
              <label htmlFor="basic" className="m-0">
                Saida
              </label>
              <InputSwitch
                name="kind"
                className="ml-4 mr-4"
                falseValue="S"
                trueValue="E"
                checked={entry}
                onChange={e => setEntry(`${e.value}`)}
              />
              <label htmlFor="basic" className="m-0">
                Entrada
              </label>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea name="description" placeholder="Descrição" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mt-4">
            <div className="field col-12 md:col flex">
              <Button
                label="Cancelar"
                className="mr-2 p-button-raised p-button-danger"
              />
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CostCenterCreate;
