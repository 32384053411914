import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../services/api';

/** utils */
import getValidationErrors from '../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { TabPanel } from 'primereact/tabview';
import { InputDropDown } from '../../components/Inputs/InputDropDown';
import { InputNumber } from '../../components/Inputs/InputNumber';
import { InputSwitch } from '../../components/Inputs/InputSwitch';
import { InputText } from '../../components/Inputs/InputText';
import { TabViews } from '../../components/TabPanel';
import useToastContext from '../../hooks/toast';
import { Option } from '../../types/optional';
import messageRequestError from '../../utils/messageRequestError';
import IOptionsDTO from '../business/dtos/IOptionsDTO';
import { IParametersOs, emptyParamsOS } from './dtos/IParametersOS';
import { useHistory } from 'react-router-dom';
import {
  IParametersConsigned,
  emptyParametersConsigned,
} from './dtos/IParametersConsigned';
import { emptyCommissioned } from '../comissioned/dtos/ICommissioned';

const Parameters = (): React.ReactElement => {
  /** hooks */
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();
  const router = useHistory();

  /** opts */
  const [naturesOperationOpts, setNaturesOperationOpts] = useState<Option[]>(
    [],
  );
  const [costCenterOpts, setCostCenterOpts] = useState<Array<Option>>([]);
  const [tablePrices, setTablePrices] = useState<Array<IOptionsDTO>>([]);

  /** states: estoque */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [parEstNatureOperation, setParEstNatureOperation] =
    useState<string>('');
  const [parEstConsumeStock, setParEstConsumeStock] = useState<boolean>(false);
  const [parEstAcceptStockNegative, setParEstAcceptStockNegative] =
    useState<boolean>(false);
  const [parEstUpdateCostProduct, setParEstUpdateCostProduct] =
    useState<boolean>(false);
  const [parEstLimitTags, setParEstLimitTags] = useState<number>(12);
  const [parEstSkuAutomatic, setParEstSkuAutomatic] = useState<boolean>(false);

  /** states: contas a pagar */
  const [parConPagQntMinParcelas, setParConPagQntMinParcelas] =
    useState<number>(1);
  const [parConPagQntMaxParcelas, setParConPagQntMaxParcelas] =
    useState<number>(12);
  const [parConPagCostCenter, setParConPagCostCenter] = useState<string>('');

  /** states: pdv */
  const [parPdvNaturezaOperacao, setParPdvNaturezaOperacao] =
    useState<string>('');
  const [parPdvCostCenter, setParPdvCostCenter] = useState<string>('');
  const [tablePriceOpts, setTablePriceOpts] = useState<IOptionsDTO[]>([]);
  const [parPdvTablePrice, setParPdvTablePrice] = useState<string>('');
  const [parPdvGeraCupomNFiscal, setParPdvGeraCupomNFiscal] =
    useState<boolean>(false);

  /** states: vendas */
  const [parVenProposalExpireIn, setParVenProposalExpireIn] =
    useState<number>(3);
  const [parVenNatureOperationSale, setParVenNatureOperationSale] =
    useState<string>('');
  const [parVenNatureOperationSwap, setParVenNatureOperationSwap] =
    useState<string>('');
  const [parVenNatureOperationDevolution, setParVenNatureOperationDevolution] =
    useState<string>('');
  const [parVenCostCenterSale, setParVenCostCenterSale] = useState<string>('');
  const [parVenReportModel, setParVenReportModel] = useState<string>('');

  /** states: ordem de serviços */
  const [parametersOS, setParametersOS] =
    useState<IParametersOs>(emptyParamsOS);

  const [parametersConsigned, setParametersConsigned] =
    useState<IParametersConsigned>(emptyParametersConsigned);

  const [statusOSOpts, setStatusOSOpts] = useState<IOptionsDTO[]>([]);
  const [userOpts, setUserOpts] = useState<IOptionsDTO[]>([]);
  const [contractOSOpts, setContractOSOpts] = useState<IOptionsDTO[]>([]);

  /** state: nfe */
  const [parNfeSendMailToClient, setParNfeSendMailToClient] =
    useState<boolean>(false);
  const [parNfeEmitNfeWithProposal, setParNfeEmitNfeWithProposal] =
    useState<boolean>(false);

  /** state: nfce */
  const [parNfceSendMailToClient, setParNfceSendMailToClient] =
    useState<boolean>(false);

  /** state: contas a receber */
  const [parConRecQntMinParcelas, setParConRecQntMinParcelas] =
    useState<number>(1);
  const [parConRecQntMaxParcelas, setParConRecQntMaxParcelas] =
    useState<number>(12);
  const [parConRecTaxaJurosPadrao, setParConRecTaxaJurosPadrao] =
    useState<number>(0);
  const [parConRecVariacaoDias, setParConRecVariacaoDias] =
    useState<number>(30);
  const [parConRecTaxaMultaPadrao, setParConRecTaxaMultaPadrao] =
    useState<number>(0);
  const [parConRecCostCenter, setParConRecCostCenter] = useState<string>('');
  const [parConRecEnvioEmailCobranca, setParConRecEnvioEmailCobranca] =
    useState<boolean>(false);
  const [parConRecEnvioEmailEmiteBoleto, setParConRecEnvioEmailEmiteBoleto] =
    useState<boolean>(false);

  /** state: integração */
  const [parIntTitulo, setParIntTitulo] = useState<string>('');
  const [parIntToken, setParIntToken] = useState<string>('');
  const [parIntUser, setParIntUser] = useState<string>('');
  const [parIntPass, setParIntPass] = useState<string>('');

  /** state: api */
  const [parApiUser, setParApiUser] = useState<string>('');
  const [parApiPass, setParApiPass] = useState<string>('');

  /** state: banco */
  const [parBancoUser, setParBancoUser] = useState<string>('');
  const [parBancoPass, setParBancoPass] = useState<string>('');

  /** state: locação */
  const [parLocPropostaExpiraEm, setParLocPropostaExpiraEm] =
    useState<number>(3);
  const [parLocNaturezaOperacaoSale, setParLocNaturezaOperacaoSale] = useState<
    string | null
  >(null);
  const [parLocNaturezaOperacaoSwap, setParLocNaturezaOperacaoSwap] = useState<
    string | null
  >(null);
  const [
    parLocNaturezaOperacaoDevolution,
    setParLocNaturezaOperacaoDevolution,
  ] = useState<string | null>(null);
  const [parLocCostCenter, setParLocCostCenter] = useState<string | null>(null);

  /** state: replicador */
  const [parRepFilialId, setParRepFilialId] = useState<string | null>(null);
  const [parRepReplicacoesDia, setParRepReplicacoesDia] = useState<number>(1);

  const [expireLocation, setExpireLocation] = useState<number | null>(0);
  const [operationNatureLocation, setOperationNatureLocation] = useState();
  const [operationNatureSwapLocation, setOperationNatureSwapLocation] =
    useState();
  const [operationNatureDevolutionLocation, setOperationNatureDevolution] =
    useState();
  const [costCenterLocation, setCostCenterLocation] = useState();

  const [emitNfe, setEmitNfe] = useState<boolean>(false);

  /** state: ecommerce */
  const [parEcoTablePrice, setParEcoTablePrice] = useState<string>('');
  const [parEcoTimeCache, setParEcoTimeCache] = useState<number>();
  const [stores, setStores] = useState<Array<IOptionsDTO>>([]);
  const [parEcoStore, setParEcoStore] = useState<string>('');
  const [commissionedOpts, setCommissionedsOpts] = useState<Array<IOptionsDTO>>(
    [],
  );
  const [parEcoCommissioned, setParEcoCommissioned] = useState<string>('');
  const [parEcoNatOpe, setParEcoNatOpe] = useState<string>('');

  /** functions  */
  const listNatOpes = async () => {
    setLoadingStatus(true);
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['entrada', 'saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setNaturesOperationOpts(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      })
      .catch(e =>
        toast(
          'error',
          'Erro',
          `Falha ao listar naturezas de operação.: ${messageRequestError(e)}`,
        ),
      )
      .finally(() => setLoadingStatus(false));
  };

  const listCostCenters = async () => {
    setLoadingStatus(true);
    await api
      .get('/cost-center')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setCostCenterOpts(opts);
      })
      .catch(e =>
        toast(
          'error',
          'Erro',
          `Falha ao listar centros de custos.: ${messageRequestError(e)}`,
        ),
      )
      .finally(() => setLoadingStatus(false));
  };

  const listTablePrices = async () => {
    setLoadingStatus(true);
    await api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setTablePriceOpts(
            data.map((e: any) => {
              return {
                value: e.id,
                label: e.title,
              };
            }),
          );
        }
      })
      .catch(e =>
        toast(
          'error',
          'Erro',
          `Falha ao listar centros de custos.: ${messageRequestError(e)}`,
        ),
      )
      .finally(() => setLoadingStatus(false));
  };

  const listStores = async () => {
    setLoadingStatus(true);
    await api
      .get('/stores')
      .then(({ data }) => {
        setStores(
          data.map((i: any) => {
            return {
              label: i.title,
              value: i.id,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao listar lojas.: ${messageRequestError(e)}`,
        );
      })
      .finally(() => setLoadingStatus(false));
  };
  const listCommissioneds = async () => {
    setLoadingStatus(true);
    await api
      .get('/commissioneds')
      .then(({ data }) => {
        setCommissionedsOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.name,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao listar comissionados.: ${messageRequestError(e)}`,
        );
      })
      .finally(() => setLoadingStatus(false));
  };
  const listUsers = async () => {
    setLoadingStatus(true);
    await api
      .post('/users/list', {
        pagination: {
          page: 1,
          perPage: 100,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        setUserOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.name,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao listar comissionados.: ${messageRequestError(e)}`,
        );
      })
      .finally(() => setLoadingStatus(false));
  };
  const listOSStatus = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/status/list?page=1&perPage=50&status=A')
      .then(({ data }) => {
        const opts = data.listAllOsStatus.map((i: any) => {
          return {
            label: i.title,
            value: i.id,
          };
        });

        if (opts.length > 0) {
          setStatusOSOpts(opts);
        }
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao listar comissionados.: ${messageRequestError(e)}`,
        );
      })
      .finally(() => setLoadingStatus(false));
  };

  const listOSContracts = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/contract/list?page=1&perPage=500&status=A')
      .then(({ data }) => {
        const opts = data.listAllOsContract.map((i: any) => {
          return {
            label: i.title,
            value: i.id,
          };
        });

        if (opts.length > 0) {
          setContractOSOpts(opts);
        }
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao listar comissionados.: ${messageRequestError(e)}`,
        );
      })
      .finally(() => setLoadingStatus(false));
  };

  useEffect(() => {
    listStores();
    listCommissioneds();
    listUsers();
    listOSStatus();
    listOSContracts();
    listNatOpes();
    listCostCenters();
    listTablePrices();
  }, []);

  useEffect(() => {
    api.get('/params').then(({ data }) => {
      /** setParams: estoque */

      if (data.length > 0) {
        setParEstNatureOperation(
          data[0].paramsEstoque === null
            ? null
            : data[0].paramsEstoque.natureza_operacao_id,
        );
        setParEstConsumeStock(
          data[0].paramsEstoque === null
            ? null
            : data[0]?.paramsEstoque?.consome_estoque_prod,
        );
        setParEstAcceptStockNegative(
          data[0].paramsEstoque === null
            ? false
            : data[0]?.paramsEstoque?.aceita_estoque_negativo,
        );
        setParEstUpdateCostProduct(
          data[0].paramsEstoque === null
            ? true
            : data[0]?.paramsEstoque?.atualiza_custo_prod_entrada,
        );
        setParEstSkuAutomatic(
          data[0].paramsEstoque === null
            ? false
            : data[0]?.paramsEstoque?.gera_sku_automatico,
        );
        setParEstLimitTags(
          data[0].paramsEstoque === null
            ? 12
            : Number(data[0]?.paramsEstoque?.limite_tags_produto),
        );

        /** setParams: contas a pagar */
        setParConPagQntMinParcelas(
          data[0].paramsContasPagar === null
            ? 1
            : Number(data[0].paramsContasPagar.qnt_min_parcelas),
        );
        setParConPagQntMaxParcelas(
          data[0].paramsContasPagar === null
            ? 12
            : Number(data[0].paramsContasPagar.qnt_max_parcelas),
        );
        setParConPagCostCenter(
          data[0].paramsContasPagar === null
            ? null
            : data[0]?.paramsContasPagar.cost_center_id,
        );

        /** setParams: pdv */
        setParPdvNaturezaOperacao(
          data[0].paramsPdv === null
            ? null
            : data[0]?.paramsPdv.natureza_operacao_id,
        );
        setParPdvCostCenter(
          data[0].paramsPdv === null ? null : data[0]?.paramsPdv.cost_center_id,
        );
        setParPdvTablePrice(
          data[0].paramsPdv === null ? null : data[0].paramsPdv.table_price_id,
        );
        setParPdvGeraCupomNFiscal(
          data[0].paramsPdv === null
            ? true
            : data[0]?.paramsPdv.gera_cupom_n_fiscal,
        );

        /** setParams: vendas */
        setParVenProposalExpireIn(
          data[0].paramsVendas === null
            ? 3
            : data[0]?.paramsVendas?.proposta_expira_em,
        );
        setParVenNatureOperationSale(
          data[0].paramsVendas === null
            ? null
            : data[0]?.paramsVendas?.natureza_operacao_id,
        );
        setParVenNatureOperationSwap(
          data[0].paramsVendas === null
            ? null
            : data[0]?.paramsVendas?.nat_ope_troca_id,
        );
        setParVenNatureOperationDevolution(
          data[0].paramsVendas === null
            ? null
            : data[0]?.paramsVendas?.nat_ope_devol_id,
        );
        setParVenCostCenterSale(
          data[0].paramsVendas === null
            ? null
            : data[0]?.paramsVendas?.cost_center_id,
        );
        setParVenReportModel(
          data[0].paramsVendas === null
            ? null
            : data[0]?.paramsVendas?.report_model_id,
        );

        setParametersOS(
          data[0].paramsOS === null
            ? emptyParamsOS
            : {
                commissioned_id: data[0].paramsOS.commissioned_id || '',
                contract_id: data[0].paramsOS.contract_id || '',
                nat_ope_id: data[0].paramsOS.nat_ope_id || '',
                status_id: data[0].paramsOS.status_id || '',
                technical_id: data[0].paramsOS.technical_id || '',
              },
        );

        setParametersConsigned(
          data[0].paramsConsigned === null
            ? emptyParametersConsigned
            : {
                stock_control: data[0].paramsConsigned.stock_control,
              },
        );

        /** setParams Nfe*/
        setParNfeSendMailToClient(
          data[0].paramsNfe === null ? false : data[0]?.paramsNfe?.envia_email,
        );
        setParNfeEmitNfeWithProposal(
          data[0].paramsNfe === null
            ? false
            : data[0]?.paramsNfe?.emit_nfe_with_proposal,
        );

        /** setParams Nfce*/
        setParNfceSendMailToClient(
          data[0].paramsNfce === null
            ? false
            : data[0]?.paramsNfce?.envia_email,
        );

        /** setParams contas receber*/
        setParConRecQntMinParcelas(
          data[0].paramsContasReceber === null
            ? 1
            : Number(data[0]?.paramsContasReceber?.qnt_min_parcelas),
        );
        setParConRecQntMaxParcelas(
          data[0].paramsContasReceber === null
            ? 12
            : Number(data[0]?.paramsContasReceber?.qnt_max_parcelas),
        );
        setParConRecTaxaJurosPadrao(
          data[0].paramsContasReceber === null
            ? 0.0
            : Number(data[0]?.paramsContasReceber.taxa_juros_padrao),
        );
        setParConRecVariacaoDias(
          data[0].paramsContasReceber === null
            ? 30
            : Number(data[0]?.paramsContasReceber.variacao_dias),
        );
        setParConRecTaxaMultaPadrao(
          data[0].paramsContasReceber === null
            ? 0.0
            : Number(data[0]?.paramsContasReceber.taxa_multa_padrao),
        );
        setParConRecCostCenter(
          data[0].paramsContasReceber === null
            ? null
            : data[0]?.paramsContasReceber.cost_center_id,
        );
        setParConRecEnvioEmailCobranca(
          data[0].paramsContasReceber === null
            ? false
            : data[0]?.paramsContasReceber.envio_email_cobranca,
        );
        setParConRecEnvioEmailEmiteBoleto(
          data[0].paramsContasReceber === null
            ? false
            : data[0]?.paramsContasReceber.envio_email_emite_boleto,
        );

        /** setParams: integraçòes */
        setParIntTitulo(
          data[0].paramsIntegracoes === null
            ? null
            : data[0].paramsIntegracoes.title,
        );
        setParIntToken(
          data[0].paramsIntegracoes === null
            ? null
            : data[0].paramsIntegracoes.token,
        );
        setParIntUser(
          data[0].paramsIntegracoes === null
            ? null
            : data[0].paramsIntegracoes.user,
        );
        setParIntPass(
          data[0].paramsIntegracoes === null
            ? null
            : data[0].paramsIntegracoes.pass,
        );

        /** setParams: api */
        setParApiUser(
          data[0].paramsApi === null ? null : data[0].paramsApi.user,
        );
        setParApiPass(
          data[0].paramsApi === null ? null : data[0].paramsApi.pass,
        );

        /** setParams: banco*/
        setParBancoUser(
          data[0].paramsBanco === null ? null : data[0].paramsBanco.user,
        );
        setParBancoPass(
          data[0].paramsBanco === null ? null : data[0].paramsBanco.pass,
        );

        /** setParams: locação */
        setParLocPropostaExpiraEm(
          data[0].paramsLocacao === null
            ? 3
            : data[0].paramsLocacao.proposta_expira_em,
        );
        setParLocNaturezaOperacaoSale(
          data[0].paramsLocacao === null
            ? null
            : data[0]?.paramsLocacao?.natureza_operacao_id,
        );
        setParLocNaturezaOperacaoSwap(
          data[0].paramsLocacao === null
            ? null
            : data[0].paramsLocacao.nat_ope_troca_id,
        );
        setParLocNaturezaOperacaoDevolution(
          data[0].paramsLocacao === null
            ? null
            : data[0].paramsLocacao.nat_ope_devol_id,
        );
        setParLocCostCenter(
          data[0].paramsLocacao === null
            ? null
            : data[0].paramsLocacao.cost_center_id,
        );

        /** setParams: replicador */
        setParRepFilialId(
          data[0].paramsReplicador === null
            ? null
            : data[0].paramsReplicador.filial_id,
        );
        setParRepReplicacoesDia(
          data[0].paramsReplicador === null
            ? 0
            : data[0].paramsReplicador.replicacoes_dia,
        );

        /** setParams: ecommerce */
        setParEcoTablePrice(
          data[0].paramsEcommerce === null
            ? null
            : data[0].paramsEcommerce.table_price_id,
        );

        setParEcoTimeCache(
          data[0].paramsEcommerce === null
            ? null
            : data[0].paramsEcommerce.cache_time,
        );
        setParEcoStore(
          data[0].paramsEcommerce === null
            ? null
            : data[0].paramsEcommerce.store_id,
        );
        setParEcoCommissioned(
          data[0].paramsEcommerce === null
            ? null
            : data[0].paramsEcommerce.commissioned_id,
        );
        setParEcoNatOpe(
          data[0].paramsEcommerce === null
            ? null
            : data[0].paramsEcommerce.nat_ope_id,
        );
      }
    });
  }, []);

  const handleSubmit = async (data: any) => {
    data.params.pEstoque = {
      natureza_operacao_id: parEstNatureOperation,
      consome_estoque_prod: parEstConsumeStock,
      aceita_estoque_negativo: parEstAcceptStockNegative,
      atualiza_custo_prod_entrada: parEstUpdateCostProduct,
      gera_sku_automatico: parEstSkuAutomatic,
      limite_tags_produto: parEstLimitTags,
    };

    data.params.pContasPagar = {
      qnt_min_parcelas: parConPagQntMinParcelas,
      qnt_max_parcelas: parConPagQntMaxParcelas,
      cost_center_id: parConPagCostCenter,
    };

    data.params.pPdv = {
      natureza_operacao_id: parPdvNaturezaOperacao,
      cost_center_id: parPdvCostCenter,
      table_price_id: parPdvTablePrice,
      gera_cupom_n_fiscal: parPdvGeraCupomNFiscal,
    };

    data.params.pVendas = {
      proposta_expira_em: parVenProposalExpireIn,
      natureza_operacao_id: parVenNatureOperationSale,
      nat_ope_troca_id: parVenNatureOperationSwap,
      nat_ope_devol_id: parVenNatureOperationDevolution,
      cost_center_id: parVenCostCenterSale,
      report_model_id: parVenReportModel,
    };

    data.params.pOS = {
      ...parametersOS,
      status_id:
        parametersOS.status_id === '' ? undefined : parametersOS.status_id,
      contract_id:
        parametersOS.contract_id === '' ? undefined : parametersOS.contract_id,
    };
    data.params.pConsigned = parametersConsigned;

    data.params.pNfe = {
      envia_email: parNfeSendMailToClient,
      emit_nfe_with_proposal: parNfeEmitNfeWithProposal,
    };

    data.params.pNfce = {
      envia_email: parNfceSendMailToClient,
    };

    data.params.pContasReceber = {
      qnt_min_parcelas: parConRecQntMinParcelas,
      qnt_max_parcelas: parConRecQntMaxParcelas,
      taxa_juros_padrao: parConRecTaxaJurosPadrao,
      variacao_dias: parConRecVariacaoDias,
      taxa_multa_padrao: parConRecTaxaMultaPadrao,
      cost_center_id: parConRecCostCenter,
      envio_email_cobranca: parConRecEnvioEmailCobranca,
      envio_email_emite_boleto: parConRecEnvioEmailEmiteBoleto,
    };

    data.params.pLocacao = {
      proposta_expira_em: parLocPropostaExpiraEm,
      natureza_operacao_id: parLocNaturezaOperacaoSale,
      nat_ope_troca_id: parLocNaturezaOperacaoSwap,
      nat_ope_devol_id: parLocNaturezaOperacaoDevolution,
      cost_center_id: parLocCostCenter,
    };

    data.params.pReplicador = {
      filial_id: parRepFilialId,
      replicacoes_dia: parRepReplicacoesDia,
    };

    data.params.pEcommerce = {
      table_price_id: parEcoTablePrice,
      cache_time: parEcoTimeCache,
      store_id: parEcoStore,
      nat_ope_id: parEcoNatOpe,
      commissioned_id: parEcoCommissioned,
    };

    try {
      form.current?.setErrors({});

      const schema = Yup.object().shape({
        params: Yup.object().shape({
          pEstoque: Yup.object().shape({
            natureza_operacao_id: Yup.string()
              .required('Selecione natureza operação, para estoque')
              .uuid('Estoque - Natureza Operação - Informe um id válido!'),
            consome_estoque_prod: Yup.boolean(),
            aceita_estoque_negativo: Yup.boolean(),
            atualiza_custo_prod_entrada: Yup.boolean(),
            gera_sku_automatico: Yup.boolean(),
            limite_tags_produto: Yup.number().min(1).max(24),
          }),
          pContasReceber: Yup.object().shape({
            qnt_min_parcelas: Yup.number(),
            qnt_max_parcelas: Yup.number(),
            taxa_juros_padrao: Yup.number(),
            variacao_dias: Yup.number(),
            taxa_multa_padrao: Yup.number(),
            cost_center_id: Yup.string()
              .required('Selecione centro de custo, para contas receber')
              .uuid('Contas Receber - Centro de custo - Informe um id válido!'),
            envio_email_cobranca: Yup.boolean(),
            envio_email_emite_boleto: Yup.boolean(),
          }),
          pContasPagar: Yup.object().shape({
            qnt_min_parcelas: Yup.number().min(0).max(24),
            qnt_max_parcelas: Yup.number().min(0).max(24),
            cost_center_id: Yup.string()
              .required('Selecione centro de custo, contas a pagar')
              .uuid('Contas a pagar - Centro de Custo - Informe um id válido!'),
          }),
          pPdv: Yup.object().shape({
            natureza_operacao_id: Yup.string()
              .required('Selecione natureza operação, para pdv')
              .uuid('Pdv - Natureza de Operação - Informe um id valido!'),
            cost_center_id: Yup.string()
              .required('Selecione centro de custo, para pdv')
              .uuid('Pdv - Centro de custo - Informe um id valido!'),
            table_price_id: Yup.string()
              .nullable()
              .required('Informe uma tabela de preços para o PDV!')
              .uuid('Pdv - Tabela de preços - Informe um id valido!'),
            gera_cupom_n_fiscal: Yup.boolean(),
          }),
          pVendas: Yup.object().shape({
            proposta_expira_em: Yup.number().required().min(1),
            natureza_operacao_id: Yup.string()
              .required('Selecione natureza operação, para vendas')
              .uuid('Vendas - Natureza de Operarção - Informe um id valido!'),
            nat_ope_troca_id: Yup.string()
              .required('Selecione natureza operação, para vendas')
              .uuid('Vendas - Nat. Ope. Troca - Informe um id valido!'),
            nat_ope_devol_id: Yup.string()
              .required('Selecione natureza operação, para vendas')
              .uuid('Vendas - Nat. Ope. Devolução - Informe um id valido!'),
            cost_center_id: Yup.string()
              .required('Selecione centro de custo, para vendas')
              .uuid('Vendas - Centro de Custo - Informe um id válido!'),
            report_model_id: Yup.string()
              .required('Selecione um modelo de pedido, para vendas')
              .uuid('Vendas - Modelo de pedido - Informe um id válido'),
          }),
          pOS: Yup.object().shape({
            nat_ope_id: Yup.string()
              .uuid('OS - Natureza de operação - Informe um id válido!')
              .required('Selecione natureza de operação, para OS!'),
            status_id: Yup.string()
              .optional()
              .uuid('OS - Status - Informe um id válido!'),
            technical_id: Yup.string()
              .uuid('OS - Técnico/Profissional - Informe um id válido!')
              .required('Selecione o técnico, para OS!'),
            commissioned_id: Yup.string()
              .uuid('OS - Comissionado - Informe um id válido!')
              .optional(),
            contract_id: Yup.string()
              .optional()
              .uuid('OS - Contrato/Garantia - Informe um id válido!'),
          }),
          pConsigned: Yup.object().shape({
            stock_control: Yup.boolean(),
          }),
          pLocacao: Yup.object().shape({
            proposta_expira_em: Yup.number(),
            natureza_operacao_id: Yup.string()
              .nullable(true)
              .uuid('Locação - Natureza de operação - Informe um id valido!'),
            nat_ope_troca_id: Yup.string()
              .nullable(true)
              .uuid('Locação - Nat. Ope. Troca - Informe um id valido!'),
            nat_ope_devol_id: Yup.string()
              .nullable(true)
              .uuid('Locação - Nat. Ope. Devolução - Informe um id valido!'),
            cost_center_id: Yup.string()
              .nullable(true)
              .uuid('Locação - Centro de custo - Informe um id valido!'),
          }),
          pNfe: Yup.object().shape({
            envia_email: Yup.boolean(),
            emit_nfe_with_proposal: Yup.boolean(),
          }),
          pNfce: Yup.object().shape({
            envia_email: Yup.boolean(),
          }),
          pEcommerce: Yup.object().shape({
            table_price_id: Yup.string()
              .required('Selecione uma tabela de preço, para Ecommerce')
              .uuid('Ecommerce - Tabela de preço - Informe um id válido!'),
            cache_time: Yup.number().integer().positive(),
            store_id: Yup.string()
              .required('Selecione uma loja, para Ecommerce')
              .uuid('Ecommerce - Loja - Informe um id válido!'),
            nat_ope_id: Yup.string()
              .required('Selecione uma natureza de operação , para Ecommerce')
              .uuid('Ecommerce - Nat. Ope. - Informe um id válido!'),
            commissioned_id: Yup.string()
              .required('Selecione um comissionado, para Ecommerce')
              .uuid('Ecommerce - Comissionado - Informe um id válido!'),
          }),
          pReplicador: Yup.object().shape({
            filial_id: Yup.string().uuid().nullable(true),
            replicacoes_dia: Yup.number().integer().nullable(true),
          }),
        }),
      });

      await schema.validate(data, { abortEarly: false });

      const res = await api.put('/params', data);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso!', 'Parâmetros atualizado!', 8000);
        router.push('/');
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0], 8000);
      } else {
        toast('error', 'Error', error.response?.data?.error, 8000);
      }
    }
  };

  return (
    <div className="card">
      <Form ref={form} onSubmit={handleSubmit} placeholder={''}>
        <Scope path="params">
          <TabViews renderActiveOnly={false}>
            <TabPanel header="Estoque">
              <Scope path="pEstoque">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label htmlFor="dropdown">
                      Natureza de Operação padrão
                    </label>
                    <InputDropDown
                      name="natureza_operacao_id"
                      options={naturesOperationOpts}
                      value={parEstNatureOperation}
                      className="w-6"
                      placeholder="Selecionar..."
                      onChange={e => setParEstNatureOperation(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Consome estoque dos produtos?</label>
                    <InputSwitch
                      name="consome_estoque_prod"
                      checked={parEstConsumeStock}
                      onChange={e => setParEstConsumeStock(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Aceita estoque negativo?</label>
                    <InputSwitch
                      name="aceita_estoque_negativo"
                      checked={parEstAcceptStockNegative}
                      onChange={e => setParEstAcceptStockNegative(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Atualiza custo do produto na entrada?
                    </label>
                    <InputSwitch
                      name="atualiza_custo_prod_entrada"
                      checked={parEstUpdateCostProduct}
                      onChange={e => setParEstUpdateCostProduct(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Gera sku automático?</label>
                    <InputSwitch
                      name="gera_sku_automatico"
                      checked={parEstSkuAutomatic}
                      onChange={e => setParEstSkuAutomatic(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Limite de tags por produto?</label>
                    <InputNumber
                      name="limite_tags_produto"
                      showButtons
                      value={parEstLimitTags}
                      onChange={e => setParEstLimitTags(Number(e.value))}
                      className="w-6"
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Contas a receber">
              <Scope path="pContasReceber">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Quantidade mínima de parcelas</label>
                    <InputNumber
                      name="qnt_min_parcelas"
                      value={parConRecQntMinParcelas}
                      onChange={e =>
                        setParConRecQntMinParcelas(Number(e.value))
                      }
                      showButtons
                      placeholder="0,00"
                      className="w-6"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Quantidade máxima de parcelas?
                    </label>
                    <InputNumber
                      name="qnt_max_parcelas"
                      value={parConRecQntMaxParcelas}
                      onChange={e =>
                        setParConRecQntMaxParcelas(Number(e.value))
                      }
                      showButtons
                      placeholder="0,00"
                      className="w-6"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Taxa juros base padrão (%)</label>
                    <InputNumber
                      name="taxa_juros_padrao"
                      placeholder="0,00"
                      className="w-6"
                      value={parConRecTaxaJurosPadrao}
                      onChange={e =>
                        setParConRecTaxaJurosPadrao(Number(e.value))
                      }
                      suffix="%"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Variação de dias padrão?</label>
                    <InputNumber
                      name="variacao_dias"
                      placeholder="00"
                      showButtons
                      value={parConRecVariacaoDias}
                      onChange={e => setParConRecVariacaoDias(Number(e.value))}
                      className="w-6"
                      min={1}
                      max={100}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Taxa multa padrão (%)</label>
                    <InputNumber
                      name="taxa_multa_padrao"
                      placeholder="0,00 %"
                      className="w-6"
                      value={parConRecTaxaMultaPadrao}
                      onChange={e =>
                        setParConRecTaxaMultaPadrao(Number(e.value))
                      }
                      suffix="%"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Centro de custo padrão</label>
                    <InputDropDown
                      options={costCenterOpts}
                      name="cost_center_id"
                      value={parConRecCostCenter}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParConRecCostCenter(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Envio de email de cobrança?</label>
                    <InputSwitch
                      name="envio_email_cobranca"
                      checked={parConRecEnvioEmailCobranca}
                      onChange={e => setParConRecEnvioEmailCobranca(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Envio de email na emissão de boleto?
                    </label>
                    <InputSwitch
                      name="envio_email_emite_boleto"
                      checked={parConRecEnvioEmailEmiteBoleto}
                      onChange={e => setParConRecEnvioEmailEmiteBoleto(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Contas a Pagar">
              <Scope path="pContasPagar">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Quantidade mínima de parcelas</label>
                    <InputNumber
                      name="qnt_min_parcelas"
                      value={parConPagQntMinParcelas}
                      onChange={e =>
                        setParConPagQntMinParcelas(Number(e.value))
                      }
                      showButtons
                      placeholder="0,00"
                      className="w-6"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Quantidade máxima de parcelas?
                    </label>
                    <InputNumber
                      name="qnt_max_parcelas"
                      value={parConPagQntMaxParcelas}
                      onChange={e =>
                        setParConPagQntMaxParcelas(Number(e.value))
                      }
                      showButtons
                      placeholder="0,00"
                      className="w-6"
                      min={1}
                      minFractionDigits={2}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Centro de custo padrão</label>
                    <InputDropDown
                      name="cost_center_id"
                      options={costCenterOpts}
                      value={parConPagCostCenter}
                      onChange={e => setParConPagCostCenter(e.value)}
                      placeholder="Selecionar..."
                      className="w-6"
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="PDV">
              <Scope path="pPdv">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label htmlFor="dropdown">
                      Natureza de Operação padrão
                    </label>
                    <InputDropDown
                      name="natureza_operacao_id"
                      options={naturesOperationOpts}
                      className="w-6"
                      value={parPdvNaturezaOperacao}
                      placeholder="Selecionar..."
                      onChange={e => setParPdvNaturezaOperacao(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Centro de custo padrão</label>
                    <InputDropDown
                      options={costCenterOpts}
                      name="cost_center_id"
                      value={parPdvCostCenter}
                      className="w-6"
                      placeholder="Selecionar..."
                      onChange={e => setParPdvCostCenter(e.value)}
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Tabela de preços padrão</label>
                    <InputDropDown
                      options={tablePriceOpts}
                      name="table_price_id"
                      className="w-6"
                      value={parPdvTablePrice}
                      placeholder="Selecionar..."
                      onChange={e => setParPdvTablePrice(e.value)}
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Gera cupom não fiscal?</label>
                    <InputSwitch
                      name="gera_cupom_n_fiscal"
                      checked={parPdvGeraCupomNFiscal}
                      onChange={e => setParPdvGeraCupomNFiscal(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Vendas">
              <Scope path="pVendas">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Propostas expiram em quantos dias?
                    </label>
                    <InputNumber
                      name="proposta_expira_em"
                      showButtons
                      value={parVenProposalExpireIn}
                      className="w-6"
                      onChange={e => setParVenProposalExpireIn(Number(e.value))}
                      placeholder="0,00"
                      min={1}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="dropdown">
                      Natureza de Operação padrão
                    </label>
                    <InputDropDown
                      name="natureza_operacao_id"
                      options={naturesOperationOpts}
                      value={parVenNatureOperationSale}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParVenNatureOperationSale(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="dropdown">Natureza de Operação troca</label>
                    <InputDropDown
                      name="nat_ope_troca_id"
                      options={naturesOperationOpts}
                      value={parVenNatureOperationSwap}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParVenNatureOperationSwap(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="dropdown">
                      Natureza de Operação devolução
                    </label>
                    <InputDropDown
                      name="nat_ope_devol_id"
                      options={naturesOperationOpts}
                      value={parVenNatureOperationDevolution}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParVenNatureOperationDevolution(e.value)
                      }
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="cost_center_id">
                      Centro de custo padrão
                    </label>
                    <InputDropDown
                      options={costCenterOpts}
                      name="cost_center_id"
                      value={parVenCostCenterSale}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParVenCostCenterSale(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="report_model_id">Modelo de pedido</label>
                    <InputDropDown
                      options={[
                        {
                          value: 'a00ecdb0-33b9-11ee-b746-d3beb0de4bbd',
                          label: 'Relatório de pedidos padrão',
                        },
                        {
                          value: 'a0b2488c-33b9-11ee-b3c9-630ced144ad7',
                          label: 'Relatório de pedidos s/ adic.',
                        },
                      ]}
                      name="report_model_id"
                      value={parVenReportModel}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParVenReportModel(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Ordem de serviço">
              <Scope path="pOS">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="nat_ope_id">
                      Natureza de Operação padrão
                    </label>
                    <InputDropDown
                      name="nat_ope_id"
                      options={naturesOperationOpts}
                      value={
                        parametersOS !== null ? parametersOS.nat_ope_id : ''
                      }
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParametersOS({
                          ...parametersOS,
                          nat_ope_id: e.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="status_id">Status padrão</label>
                    <InputDropDown
                      name="status_id"
                      options={statusOSOpts}
                      value={
                        parametersOS !== null ? parametersOS.status_id : ''
                      }
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParametersOS({ ...parametersOS, status_id: e.value })
                      }
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="technical_id">
                      Técnico/Profissional padrão
                    </label>
                    <InputDropDown
                      name="technical_id"
                      options={userOpts}
                      value={
                        parametersOS !== null ? parametersOS.technical_id : ''
                      }
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParametersOS({
                          ...parametersOS,
                          technical_id: e.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="commissioned_id">Comissionado padrão</label>
                    <InputDropDown
                      name="commissioned_id"
                      options={commissionedOpts}
                      value={
                        parametersOS !== null
                          ? parametersOS.commissioned_id
                          : ''
                      }
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParametersOS({
                          ...parametersOS,
                          commissioned_id: e.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="contract_id">
                      Contrato/Garantia padrão
                    </label>
                    <InputDropDown
                      name="contract_id"
                      options={contractOSOpts}
                      value={
                        parametersOS !== null ? parametersOS.contract_id : ''
                      }
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParametersOS({
                          ...parametersOS,
                          contract_id: e.value,
                        })
                      }
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Consignado">
              <Scope path="pConsigned">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Usa estoque da rota?</label>
                    <InputSwitch
                      name="stock_control"
                      checked={parametersConsigned.stock_control}
                      onChange={e =>
                        setParametersConsigned({
                          ...parametersConsigned,
                          stock_control: e.value,
                        })
                      }
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Nfe">
              <Scope path="pNfe">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Envia email para o cliente?</label>
                    <InputSwitch
                      name="envia_email"
                      checked={parNfeSendMailToClient}
                      onChange={e => setParNfeSendMailToClient(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Emite Nfe com proposta?</label>
                    <InputSwitch
                      name="envia_email"
                      checked={parNfeEmitNfeWithProposal}
                      onChange={e => setParNfeEmitNfeWithProposal(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Nfce">
              <Scope path="pNfce">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Envia email para o cliente?</label>
                    <InputSwitch
                      name="envia_email"
                      checked={parNfceSendMailToClient}
                      onChange={e => setParNfceSendMailToClient(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="E-Commerce">
              <Scope path="pEcommerce">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="table_price_id">Tabela de preços</label>
                    <InputDropDown
                      name="table_price_id"
                      options={tablePriceOpts}
                      value={parEcoTablePrice}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParEcoTablePrice(e.value)}
                      filter
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="cache_time">Tempo do cache</label>
                    <InputDropDown
                      name="cache_time"
                      options={[
                        { label: '5min', value: 300 },
                        { label: '15min', value: 900 },
                        { label: '1hr', value: 3600 },
                        { label: '6h', value: 21600 },
                      ]}
                      value={parEcoTimeCache}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParEcoTimeCache(e.value)}
                      filter
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="store_id">Loja</label>
                    <InputDropDown
                      name="store_id"
                      options={stores}
                      value={parEcoStore}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParEcoStore(e.value)}
                      filter
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="commissioned_id">Comissionado</label>
                    <InputDropDown
                      name="commissioned_id"
                      options={commissionedOpts}
                      value={parEcoCommissioned}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParEcoCommissioned(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="nat_ope_id">Natureza de operação</label>
                    <InputDropDown
                      name="nat_ope_id"
                      options={naturesOperationOpts}
                      value={parEcoNatOpe}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParEcoNatOpe(e.value)}
                      filter
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Integrações">
              <Scope path="pIntegracoes">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Título</label>
                    <InputText
                      name="title"
                      className="w-6"
                      value={parIntTitulo}
                      onChange={e => setParIntTitulo(e.currentTarget.value)}
                      placeholder="Mercado livre"
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Token</label>
                    <InputText
                      name="token"
                      className="w-6"
                      value={parIntToken}
                      onChange={e => setParIntToken(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Usuário</label>
                    <InputText
                      name="user"
                      className="w-6"
                      value={parIntUser}
                      onChange={e => setParIntUser(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Senha</label>
                    <InputText
                      name="pass"
                      className="w-6"
                      value={parIntPass}
                      onChange={e => setParIntPass(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="API">
              <Scope path="pApi">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Usuário</label>
                    <InputText
                      name="user"
                      className="w-6"
                      value={parApiUser}
                      onChange={e => setParApiUser(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Senha</label>
                    <InputText
                      name="pass"
                      className="w-6"
                      value={parApiPass}
                      onChange={e => setParApiPass(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Banco">
              <Scope path="pBanco">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Usuário</label>
                    <InputText
                      name="user"
                      className="w-6"
                      value={parBancoUser}
                      onChange={e => setParBancoUser(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Senha</label>
                    <InputText
                      name="pass"
                      className="w-6"
                      value={parBancoPass}
                      onChange={e => setParBancoPass(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Locações">
              <Scope path="pLocacao">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">
                      Proposta/Orçamentos expiram em quantos dias?
                    </label>
                    <InputNumber
                      name="proposta_expira_em"
                      showButtons
                      value={parLocPropostaExpiraEm}
                      className="w-6"
                      onChange={e => setParLocPropostaExpiraEm(Number(e.value))}
                      placeholder="0,00"
                      min={1}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="dropdown">
                      Natureza de Operação padrão
                    </label>
                    <InputDropDown
                      name="natureza_operacao_id"
                      options={naturesOperationOpts}
                      value={parLocNaturezaOperacaoSale}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParLocNaturezaOperacaoSale(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="dropdown">Natureza de Operação troca</label>
                    <InputDropDown
                      name="nat_ope_troca_id"
                      options={naturesOperationOpts}
                      value={parLocNaturezaOperacaoSwap}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParLocNaturezaOperacaoSwap(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="nat_ope_devol_id">
                      Natureza de Operação devolução
                    </label>
                    <InputDropDown
                      name="nat_ope_devol_id"
                      options={naturesOperationOpts}
                      value={parLocNaturezaOperacaoDevolution}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e =>
                        setParLocNaturezaOperacaoDevolution(e.value)
                      }
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="cost_center_id">
                      Centro de custo padrão
                    </label>
                    <InputDropDown
                      name="cost_center_id"
                      options={costCenterOpts}
                      value={parLocCostCenter}
                      placeholder="Selecionar..."
                      className="w-6"
                      onChange={e => setParLocCostCenter(e.value)}
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>

            <TabPanel header="Replicador">
              <Scope path="pReplicador">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex align-items-center justify-content-between">
                    <label className="m-0">Replicações por dia</label>
                    <InputNumber
                      name="replicacoes_dia"
                      showButtons
                      value={parRepReplicacoesDia}
                      className="w-6"
                      onChange={e => setParRepReplicacoesDia(Number(e.value))}
                      placeholder="0"
                      min={1}
                    />
                  </div>
                </div>

                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-6 flex justify-content-between">
                    <label htmlFor="cost_center_id">Token filial</label>
                    <InputText
                      name="filial_id"
                      className="w-6"
                      type={'text'}
                      value={parRepFilialId?.toString()}
                      onChange={e => setParRepFilialId(e.currentTarget.value)}
                      placeholder="*****"
                    />
                  </div>
                </div>
              </Scope>
            </TabPanel>
          </TabViews>
        </Scope>
        <div className="col">
          <Button label="Salvar" icon="pi pi-check" loading={loadingStatus} />
        </div>
      </Form>
    </div>
  );
};

export default Parameters;
