import { EnumPriority } from '../enum/EnumPriority';
import IOptionsDTO from '../pages/business/dtos/IOptionsDTO';

const defaultPriorities: IOptionsDTO[] = [
  { label: 'Baixa', value: EnumPriority.BAIXA },
  { label: 'Média', value: EnumPriority.MEDIA },
  { label: 'Alta', value: EnumPriority.ALTA },
];

export default defaultPriorities;
