import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { DataTable } from '../../../../components/DataTable';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import ReportTagHeaderMenu from '../header-menu';

const ReportTagRing: React.FC = (): React.ReactElement => {
  /** const */

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState<string>('');
  const [productTable, setProductTable] = useState<Array<any>>([]);
  const [qnt, setQnt] = useState<number | null>(1);
  const [tablePrice, setTablePrice] = useState<IOptionsDTO[]>([]);
  const [selectedTablePrice, setSelectedTablePrice] = useState();
  const [productsId, setProductsId] = useState<Array<any>>([]);
  const [showPrice, setShowPrice] = useState<boolean>(false);

  /** functions */
  const searchProduct = (event: any) => {
    if (event.query.length >= 3) {
      api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const productOptions = ammountOptions(data.products);
          productOptions.length <= 0 &&
            toast('warn', 'Error', 'Nenhum produto encontrado!');

          setProducts(productOptions);
        })
        .catch(error => toast('warn', 'Error', error.response.data.error));
    }
  };

  const deleteProduct = (id: string) => {
    const filterProduct = productTable.filter((item: any) => item.value !== id);
    const filterProductId = filterProduct.map(item => item.value);
    setProductTable(filterProduct);
    setProductsId(filterProductId);
  };

  const selectProduct = (e: IOptionsDTO) => {
    setProductTable([...productTable, e]);
    setProductsId([...productsId, e.value]);
    setProduct('');
  };

  const handleSubmit = async (d: any) => {
    formRef.current?.setErrors({});
    try {
      if (!productsId.length) {
        formRef.current?.setFieldError('product_id', 'error');
        toast('error', 'Error', 'Por favor, informe pelo meno 1 produto!');
        return;
      }

      if (showPrice === true) {
        const schemaReports = Yup.object({
          tablePriceId: Yup.string().required(
            'Por favor, informe a tabela de preço!',
          ),
        });

        await schemaReports.validate(d, { abortEarly: false });
      }

      const res = await api.post(`/reports/tag/ring`, {
        products: productsId,
        showPrices: showPrice,
        qnt: qnt,
        tablePriceId: d.tablePriceId,
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Etiquetas geradas com sucesso!');

        window.open(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
        );
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  /** render */
  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.titulo,
        value: item.id,
        figure: item.image_url,
        sku: item.sku,
        code: item.codigo,
      };
    });
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => deleteProduct(rowData.value)}
        />
      </div>
    );
  };

  /** useEffect */
  useEffect(() => {
    api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        const optTablePrice = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setTablePrice(optTablePrice);
      });
  }, []);

  return (
    <>
      <ReportTagHeaderMenu />
      <div className="card w-6">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
        >
          <div className="field col-12 md:col-12 ">
            <label htmlFor="basic">Produto</label>
            <InputAutoComplete
              name="product_id"
              value={product}
              completeMethod={e => searchProduct(e)}
              suggestions={products}
              field="label"
              onSelect={e => selectProduct(e.value)}
              onChange={e => setProduct(e.value)}
              itemTemplate={itemTemplate}
              placeholder="Buscar produto..."
            />
          </div>

          <div className="field col-12 md:col">
            <DataTable
              value={productTable}
              selectionMode="single"
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              responsiveLayout="scroll"
              emptyMessage="Nenhum produto selecionado!"
            >
              <Column field="code" header="#"></Column>
              <Column field="label" header="Nome"></Column>
              <Column field="code" header="Cod"></Column>
              <Column
                header="Ações"
                headerStyle={{ width: '10%', minWidth: '10rem' }}
                body={actionBodyTemplate}
              ></Column>
            </DataTable>
          </div>

          <div className="field col-12 md:col-5 flex align-items-center">
            <label className="mr-3">Mostrar preços</label>
            <InputSwitch
              name="showPrices"
              checked={showPrice}
              onChange={e => setShowPrice(e.value)}
            />
          </div>
          <div className="field col-12 md:col-7 flex align-items-center">
            <label className="mr-3">Qnt. Etiquetas</label>
            <InputNumber
              inputId="horizontal"
              value={qnt}
              onValueChange={e => setQnt(e.value)}
              showButtons
              name="qnt"
              buttonLayout="horizontal"
              step={1}
              max={100}
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="decimal"
              currency="BRL"
            />
          </div>

          <div className="field col-12 md:col">
            <label htmlFor="">Tabela preço</label>
            <InputDropDown
              name="tablePriceId"
              options={tablePrice}
              value={selectedTablePrice}
              disabled={!showPrice}
              style={{ background: !showPrice ? 'var(--surface-200)' : '' }}
              onChange={e => setSelectedTablePrice(e.value)}
              placeholder="Selecionar..."
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportTagRing;
