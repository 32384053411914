import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { IRegisterUser } from '../dtos/IRegisterUser';
import RegisterCashHeader from '../header';

interface RegisterCashOpen {
  register_id: string;
  balance_open: string;
}

const CashOpen = (): React.ReactElement => {
  const hoje = new Date();

  const toast = useToastContext();
  const [load, setLoad] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();

  const [user, setUser] = useState<{ id: string; name: string } | undefined>(
    undefined,
  );
  const [options, setOptions] = useState<IRegisterUser[]>([]);
  const [register, setRegister] = useState<IRegisterUser>({
    value: '',
    label: '',
    id: '',
    b_id: '',
    codigo: 0,
    title: '',
    operator_id: '',
    operator_exclusive: '',
    register_only_own: '',
    wallet_id: '',
    user_id: '',
    status: '',
    created_at: null,
    updated_at: null,
    operator: {
      id: '',
      b_id: '',
      name: '',
      status: '',
    },
  });

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (user && options.length > 0) {
      const register = options.find(r => r.operator_id === user.id);

      if (register) {
        setRegister(register);
      } else {
        toast(
          'warn',
          'Alerta',
          'O usuário logado não possui um caixa cadastrado!',
        );
      }
    }
  }, [user, options]);

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const handleSelectRegister = (d: any) => {
    const reg = options.find((i: IRegisterUser) => i.id === d.value);
    if (reg) {
      setRegister(reg);

      setBalance(0);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setLoad(true);

      d.register_id = register.value;
      d.balance_open = balance;

      const schemaOpenCash = Yup.object({
        register_id: Yup.string().required(
          'Por favor, selecione registradora!',
        ),
        balance_open: Yup.string().required(
          'Por favor, informe saldo do caixa!',
        ),
      });

      await schemaOpenCash.validate(d, { abortEarly: false });

      await api
        .post(`/register-cash/open`, {
          register_id: register.value,
          balance_open: balance,
          balance_open_date: hoje,
        })
        .then(({ data }) => {
          toast('success', 'Sucesso', 'Caixa Aberto!');
          navigate.push('/orders/list');
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => {
          setLoad(false);
        });
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const loadRegisterUsers = async () => {
    setLoad(true);
    await api
      .get('/register-user')
      .then(({ data }) => {
        if (data) {
          setOptions(
            data.map((i: any) => {
              return {
                value: i.id,
                label: `${i.codigo} - ${i.title} / ${i.operator.name}`,
                ...i,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    loadUserData();
    loadRegisterUsers();
  }, []);

  return (
    <>
      <RegisterCashHeader />
      {load && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <Form ref={formRef} onSubmit={d => handleSubmit(d)} className="card w-6" placeholder={''} onPointerEnterCapture={null} onPointerLeaveCapture={null}>
        <div className="p-fluid grid formgrid">
          <div className="field col-12">
            <label htmlFor="register_id">Registradora</label>
            <InputDropDown
              name="register_id"
              value={register.value}
              options={options}
              onChange={e => handleSelectRegister(e)}
              disabled
              placeholder="Selecionar..."
            />
          </div>

          <div className="field col-12 md:col-8">
            <label htmlFor="operator">Operador do caixa</label>
            <InputText
              name="operator"
              className="surface-200"
              disabled
              value={register.label}
              placeholder="Ex.: Bruna (Caixa)"
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="dropdown">Saldo do caixa</label>
            <InputNumber
              inputId="minmaxfraction"
              mode="decimal"
              name="balance_open"
              placeholder="R$ 0,00"
              value={balance}
              onChange={e => setBalance(e.value as number)}
              minFractionDigits={2}
              maxFractionDigits={2}
              className={!validate(register.value) ? 'surface-200' : ''}
              disabled={!validate(register.value)}
            />
          </div>
        </div>
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Abrir"
              icon="fa-solid fa-check"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default CashOpen;
