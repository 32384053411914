import { useContext } from 'react';
import { ParamsConsignedContext } from '../context/ParamsConsignedContext';

export function useParamsConsigned() {
  const context = useContext(ParamsConsignedContext);
  if (!context) {
    throw new Error('Falha ao acessar o hook de parâmetros de consignado!');
  }
  return context;
}
