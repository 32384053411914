import { v4 } from 'uuid';
import { EnumBusinessRegimeTributario } from '../../../enum/EnumBusinessRegimeTributario';
import { EnumBusinessSize } from '../../../enum/EnumBusinessSize';
import { EnumFollowUp } from '../../../enum/EnumFollowUp';
import { EnumPessoa } from '../../../enum/EnumPessoa';
import { ICity } from '../../person/dtos/IPerson';
import { EnumSimNao } from '../../../enum/EnumSimNão';

export interface IBusinessList {
  id: string;
  logo_url: string;
  name: string;
  cpf_cnpj: string;
  status: string;
  bussines_followup: string;
}

interface IBusiness {
  id: string;
  name: string;
  nickname: string;
  kind_business: string;
  cpf_cnpj: string;
  rg_ie: string;
  insc_municipal: string;
  tax_free: string;
  cnae: string;
  main_activity_id: string;
  regime_tributario: string;
  bussines_size: string;
  bussines_followup: string;
  employers_qnt: number;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  contact_mobile: string;
  contact_site: string;
  logo_url: string;
  config_id: string;
  valid_to_emit_nfe: boolean;
  valid_to_emit_nfce: boolean;
  cert_url: string;
  cert_expiration: string;
}

export const emptyBusiness: IBusiness = {
  id: v4(),
  name: '',
  nickname: '',
  kind_business: EnumPessoa.JURIDICA,
  cpf_cnpj: '',
  rg_ie: '',
  insc_municipal: '',
  tax_free: EnumSimNao.SIM,
  cnae: '',
  main_activity_id: '',
  regime_tributario: EnumBusinessRegimeTributario.NENHUM,
  bussines_size: EnumBusinessSize.PEQ,
  bussines_followup: EnumFollowUp.COMERCIO,
  employers_qnt: 5,
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  contact_mobile: '',
  contact_site: '',
  logo_url: '',
  config_id: '',
  valid_to_emit_nfe: false,
  valid_to_emit_nfce: false,
  cert_url: '',
  cert_expiration: '',
};

interface IAddress {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city_id: string;
  city?: ICity;
  post_code: string;
}

export const emptyAddress: IAddress = {
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city_id: '',
  post_code: '',
};

interface IConfig {
  producao: boolean;
  rps: {
    lote: number;
    businessConfigRpsNumeracoes: { numero: number; serie: string }[];
  };
  prefeitura: {
    login: string;
    senha: string;
  };
}

export const emptyConfig: IConfig = {
  producao: false,
  rps: {
    lote: 0,
    businessConfigRpsNumeracoes: [{ serie: '0', numero: 0 }],
  },
  prefeitura: {
    login: '',
    senha: '',
  },
};

export interface IBusinessDTO {
  business: Partial<IBusiness>;
  address: Partial<IAddress>;
  config: Partial<IConfig>;
}

export const emptyBusinessDTO: IBusinessDTO = {
  business: emptyBusiness,
  address: emptyAddress,
  config: emptyConfig,
};
