import React, { useEffect, useRef, useState } from 'react';

/** services */
import api from '../../../services/api';

/** react router-dom */
import { useHistory } from 'react-router-dom';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** utils */

import { format } from 'date-fns';
import { numberToCurrency } from '../../../utils/number';

/** components */
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { SideBar } from '../../../components/Sidebar';
import { Option } from '../../../types/optional';
import { Customer } from '../../../types/person';
import useSearchCustomer from '../../../hooks/useSearchCustomer';
import useToastContext from '../../../hooks/toast';

const OrderSwap = (): React.ReactElement => {
  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [selectOrder, setSelectOrder] = useState<Array<any>>([]);

  /** search cliente */
  const [customers, setCustomers] = useState<Option[]>([]);
  const [costumer, setCustomer] = useState('');

  /** orders */
  const [orders, setOrders] = useState<Array<any>>([]);

  /** width */
  const [width, setWidth] = useState('60vw');

  const [crediary, setCrediary] = useState(0);

  const [error, setError] = useState(false);

  /** navigate */
  const navigate = useHistory();

  /** ref */
  const toastRef = useRef<any>(null);
  const form = useRef<FormHandles>(null);

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const toast = useToastContext();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toastRef?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const searchOrderByCustomer = async (idCustomer: string) => {
    try {
      const { data } = await api.get(
        `/order-header/by-customer-id/${idCustomer}`,
      );
      setOrders(data.orders);
    } catch (error: any) {
      showToast('error', 'Error', error?.response?.data?.error);
    }
  };

  const selectCustomer = (e: AutoCompleteSelectParams) => {
    searchOrderByCustomer(e.value.value);
  };

  const checkQuantity = (
    qnt: number,
    qntChanged: number,
    priceProduct: number,
    indice: number,
  ) => {
    if (qntChanged > qnt) {
      showToast(
        'error',
        'Error',
        'O total de itens é maior do que a quantidade!',
      );
      return;
    }
    setCrediary(qntChanged * priceProduct);
    const cloneOrderDevolution = selectOrder;
    cloneOrderDevolution[indice].swap_total = qntChanged * priceProduct;
    cloneOrderDevolution[indice].swap_qnt = qntChanged;

    setSelectOrder(cloneOrderDevolution);
  };

  /** search customer */
  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const codeBodyColumn = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const dateBodyColumn = (rowData: any) => {
    return <span>{format(new Date(rowData.data_venda), 'dd/MM/yyyy')}</span>;
  };
  const moneyBodyColumn = (rowData: any) => {
    return <span>{numberToCurrency(rowData.total)}</span>;
  };
  const qntBodyColumn = (rowData: any) => {
    return (
      <span>{numberToCurrency(parseFloat(rowData.products_qnt_total))}</span>
    );
  };
  const skuBodyColumn = (rowData: any) => {
    return <span>{rowData.orderSwapItens[0]?.product?.sku}</span>;
  };
  const productBodyColumn = (rowData: any) => {
    return <span>{rowData.orderSwapItens[0]?.product?.title}</span>;
  };
  const valueFormatLiquid = (rowData: any, field: string) => {
    return (
      <span>
        {numberToCurrency(parseFloat(rowData.orderSwapItens[0][field]))}
      </span>
    );
  };
  const replaceBodyColumn = (rowData: any) => {
    return (
      <InputNumber
        placeholder="0,00"
        minFractionDigits={
          rowData.orderSwapItens[0]?.product?.decimalHouses[0].qnt_out
        }
        onChange={e =>
          checkQuantity(
            parseFloat(rowData.products_qnt_total),
            e.value as number,
            parseFloat(rowData.orderSwapItens[0].value_unit_liquid),
            rowData.orderSwapItens.length - 1,
          )
        }
      />
    );
  };
  const crediaryBodyColumn = () => {
    return <span>{crediary ? numberToCurrency(crediary) : ''}</span>;
  };

  /** handle swap */
  const handleSwapProduct = async () => {
    try {
      if (!selectOrder.length) {
        showToast('error', 'Error', 'Por favor, selecione um pedido!');
        setError(true);
        return;
      }

      const newSwapItems = selectOrder.map((item: any, indice: number) => {
        return {
          position: indice + 1,
          id: item.orderSwapItens[0].id,
          product_id: item.orderSwapItens[0].product_id,
          qnt: item.products_qnt_total,
          value_unit_liquid: item.orderSwapItens[0].value_unit_liquid,
          value_total_liquid: item.orderSwapItens[0].value_total_liquid,
          swap_qnt: item.swap_qnt,
          swap_total: item.swap_qnt,
        };
      });
      await api.post('/swap', {
        swap: {
          order_id: selectOrder[0].id,
        },
        swapItens: newSwapItems,
      });
      navigate.push('/swaps');
    } catch (error: any) {
      showToast('error', 'Error', error?.response?.data?.error);
    }
  };

  const searchOrderToDevolution = async (idOrder: string) => {
    try {
      const { data } = await api.get(`/devolution/order/${idOrder}`);
      setSelectOrder([data.orderToDevolution]);
    } catch (error: any) {
      showToast('error', 'Error', error?.response?.data?.error);
    }
  };

  const selectingOrderTable = (e: DataTableSelectionChangeParams) => {
    searchOrderToDevolution(e.value.id);
    setOpenModalOrder(false);
    setError(false);
  };

  useEffect(() => {
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  }, []);

  return (
    <>
      <div className="card">
        <Form
          ref={form}
          onSubmit={handleSwapProduct}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <Toast ref={toastRef} />
          <div className="p-fluid grid formgrid mb-3 flex">
            <div className="field col-12 md:col-6 flex flex-wrap justify-content-between align-items-center">
              <label htmlFor="basic" style={{ flex: 2 }}>
                Cliente
              </label>
              <InputAutoComplete
                name="customer_id"
                value={costumer}
                completeMethod={e => searchCustomer(e.query)}
                suggestions={customers}
                field="label"
                onSelect={e => selectCustomer(e)}
                style={{ flex: 2 }}
                onChange={e => setCustomer(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar cliente..."
              />
            </div>
            <div className="field col-12 md:col-6 flex flex-wrap justify-content-between align-items-center">
              <label htmlFor="basic">Pedido</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectOrder.length
                    ? selectOrder[0]?.orderSwapItens[0]?.product?.title
                    : 'Selecionar Pedido'
                }
                disabled={!orders.length}
                onClick={() => setOpenModalOrder(true)}
                aria-controls="overlay_panel"
                className={
                  !error
                    ? 'select-product-button w-6 min-w-max'
                    : 'p-button-raised p-button-danger w-6 min-w-max'
                }
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <DataTable
                value={selectOrder}
                responsiveLayout="scroll"
                emptyMessage="Não existe pedidos selecionados"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
              >
                <Column field="sku" header="Sku" body={skuBodyColumn}></Column>
                <Column
                  field="product"
                  header="Produto"
                  body={productBodyColumn}
                ></Column>
                <Column
                  field="products_qnt_total"
                  header="Quantidade"
                  body={qntBodyColumn}
                ></Column>
                <Column
                  field="total_vendido"
                  header="Valor"
                  body={(rowData: any) =>
                    valueFormatLiquid(rowData, 'value_unit_liquid')
                  }
                ></Column>
                <Column
                  field="total"
                  header="Total"
                  body={(rowData: any) =>
                    valueFormatLiquid(rowData, 'value_total_liquid')
                  }
                ></Column>
                <Column
                  field="replace"
                  header="Devolver"
                  body={replaceBodyColumn}
                ></Column>
                <Column
                  field=""
                  header="Crédito"
                  body={crediaryBodyColumn}
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-end">
            <div className="field col-12 md:col-5 flex align-items-center justify-content-end">
              <label htmlFor="">Crédito</label>
              <InputText
                disabled
                className="surface-200 ml-4 w-4"
                value={crediary ? `${numberToCurrency(crediary)}` : '0,00'}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-end">
            <div className="field col-12 md:col-4">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-4">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={openModalOrder}
        position="right"
        style={{ width }}
        onHide={() => setOpenModalOrder(false)}
      >
        <div className="col">
          <DataTable
            value={orders}
            selectionMode="single"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            selection={selectOrder}
            onSelectionChange={e => selectingOrderTable(e)}
            dataKey="id"
            responsiveLayout="scroll"
            emptyMessage="Não existe pedidos"
          >
            <Column field="code" header="Code" body={codeBodyColumn}></Column>
            <Column field="cliente" header="Cliente"></Column>
            <Column field="cond_pagamento" header="Cond. Pagamento"></Column>
            <Column
              field="data_venda"
              header="Data venda"
              body={dateBodyColumn}
            ></Column>
            <Column field="pedido" header="Pedido"></Column>
            <Column
              field="total"
              header="Valor"
              body={moneyBodyColumn}
            ></Column>
          </DataTable>
        </div>
      </SideBar>
    </>
  );
};

export default OrderSwap;
