import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
const ReportTagHeaderMenu = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Etiqueta de produtos"
        className="mb-2 mr-2"
        icon="fa-solid fa-box"
        onClick={() => router.push('/reports/tags')}
      />

      <Button
        label="Etiqueta com alça"
        className="mb-2 mr-2"
        icon="fa-solid fa-ring"
        onClick={() => router.push('/reports/tags/ring')}
      />

      <Button
        label="Etiqueta roupas"
        className="mb-2 mr-2"
        icon="fa-solid fa-shirt"
        onClick={() => router.push('/reports/tags/clothes')}
      />
    </>
  );
};

export default ReportTagHeaderMenu;
