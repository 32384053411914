import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useRef, useState } from 'react';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import messageRequestError from '../../../../utils/messageRequestError';
import BillsToPayHeader from '../../../bills-to-pay/header';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';

const ReportBillsPayableByProvider: React.FC = () => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [provider, setProvider] = useState<string>('');
  const [providers, setProviders] = useState<IOptionsDTO[]>([]);
  const [providerOpts, setProviderOpts] = useState<IOptionsDTO[]>([]);

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const statusPaymentsOpts: IOptionsDTO[] = [
    { label: 'Todas', value: 'T' },
    { label: 'Pagar', value: 'A' },
    { label: 'Pagas', value: 'P' },
    { label: 'Atrasadas', value: 'V' },
  ];
  const [statusPayments, setStatusPayments] = useState<IOptionsDTO[]>(['T']);

  const statusDocumentOpts: IOptionsDTO[] = [
    { label: 'Todos', value: 'T' },
    { label: 'Ativos', value: 'A' },
    { label: 'Cancelados', value: 'C' },
  ];
  const [statusDocuments, setStatusDocuments] = useState<IOptionsDTO[]>(['A']);

  /** useEffect */

  /** functions */
  const searchProvider = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      setLoadingStatus(true);

      api
        .get(`/persons/provider/src?search=${e.query}`)
        .then(({ data }) => {
          const opts = data.providers.map((i: any) => {
            return {
              value: i.id,
              label: i.name,
            };
          });
          setProviderOpts(opts);
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
          return;
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleAddProvider = async (e: AutoCompleteSelectParams) => {
    if (!validate(e.value)) toast('warn', 'Alerta', 'Repita a seleção!');

    const p = providerOpts.find(i => i.value === e.value);

    if (p) {
      setProviders([...providers.filter(r => r.value !== e.value), p]);
    }

    setProvider('');
  };

  const handleRemProvider = async (id: string) => {
    const providersNoId = providers.filter(e => e.value !== id);
    setProviders(providersNoId);
    setProvider('');
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      data.date_start = dateStart as Date;
      data.date_end = dateEnd as Date;
      data.status_payments = statusPayments;
      data.status_documents = statusDocuments;

      const schemaValidate = Yup.object({
        date_start: Yup.date().required('Informe a data inicial!'),
        date_end: Yup.date().required('Informe a data final!'),
        status_payments: Yup.array()
          .required('Informe o status de pagamento!')
          .min(1, 'Informe pelo menos 1 status de pagamento!'),
        status_documents: Yup.array()
          .required('Informe o status do documento!')
          .min(1, 'Informe pelo menos 1 status do documento!'),
      });
      await schemaValidate.validate(data, { abortEarly: false });

      const res = await api.post('/reports/v2/bills-payable', {
        filter: {
          provider_ids: providers.map(e => e.value),
          date_start: dateStart as Date,
          date_end: dateEnd as Date,
          status_payments: statusPayments,
          status_documents: statusDocuments,
        },
      });

      if (res.status >= 200 || res.status <= 299) {
        window.open(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
        );
      }
    } catch (err: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', messageRequestError(err));
      } else {
        toast('error', 'Error', messageRequestError(err));
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** renders */
  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const renderColumnRemProvider = (id: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-trash"
          className="p-button-danger"
          onClick={() => handleRemProvider(id)}
        />
      </div>
    );
  };

  return (
    <>
      <BillsToPayHeader />
      <div className="card col-12 md:col-6">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="p-fluid grid formgrid"
        >
          <div className="field col-12">
            <label htmlFor="provider_id">Fornecedor</label>
            <InputAutoComplete
              name="provider_id"
              value={provider}
              completeMethod={e => searchProvider(e)}
              suggestions={providerOpts}
              className="w-6"
              field="label"
              onChange={e => setProvider(e.value)}
              onSelect={e => handleAddProvider(e.value)}
              itemTemplate={itemTemplate}
              placeholder="Buscar fornecedores..."
            />
          </div>

          <DataTable
            value={providers}
            selectionMode="single"
            selection={() => null}
            dataKey="id"
            responsiveLayout="scroll"
            className="field col-12"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column field="label" header="Nome"></Column>
            <Column
              header="*"
              body={e => renderColumnRemProvider(e.value)}
            ></Column>
          </DataTable>

          <div className="field col-6 md:col-6">
            <label htmlFor="date_start">Data inicial</label>
            <Calendar
              name="date_start"
              value={dateStart}
              dateFormat="dd/mm/yy"
              onChange={e => setDateStart(e.value)}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-6 md:col-6">
            <label htmlFor="date_end">Data final</label>
            <Calendar
              name="date_end"
              value={dateEnd}
              dateFormat="dd/mm/yy"
              onChange={e => setDateEnd(e.value)}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-6 md:col-6">
            <label htmlFor="status_payments">Situação pagamento</label>
            <MultiSelect
              name="status_payments"
              options={statusPaymentsOpts}
              value={statusPayments}
              onChange={e => setStatusPayments(e.value)}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-6 md:col-6">
            <label htmlFor="status_documents">Situação documento</label>
            <MultiSelect
              name="status_documents"
              options={statusDocumentOpts}
              value={statusDocuments}
              onChange={e => setStatusDocuments(e.value)}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </Form>
      </div>
    </>
  );
};
export default ReportBillsPayableByProvider;
