import React, { useState, useRef } from 'react';

/** uuid */
import { v4 } from 'uuid';

/** react-rouer-dom */
import { useHistory } from 'react-router-dom';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ColorPicker } from 'primereact/colorpicker';
import { InputPhone } from '../../../../components/Inputs/InputPhone';
import { ProgressBar } from 'primereact/progressbar';

const ConsultantCreate = (): React.ReactElement => {
  const navigate = useHistory();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);

  /** state */
  const [phone, setPhone] = useState<string>('');
  const [whatsapp, setWhatsapp] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [color, setColor] = useState('#dddddd');

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const chooseOptions = {
    icon: 'pi pi-plus',
    label: 'Escolher Avatar',
    className: 'custom-choose-btn',
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };
  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };
  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 40, height: 40 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };
  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };
  const onTemplateClear = () => {
    setTotalSize(0);
  };

  /** handle consultant */

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schemaConsultant = Yup.object().shape({
        nome: Yup.string().required('Por favor, informe o nome'),
        email: Yup.string()
          .email('Por favor, informe email válido!')
          .required('Por favor, informe um email'),
        fone: Yup.string(),
        whatsapp: Yup.string().matches(
          /\(\d{2,}\)\s?\d?\s?\d{4,}-?\d{4}/g,
          'Por favor, informe número de telefone válido',
        ),
      });
      await schemaConsultant.validate(data, { abortEarly: false });
      const response = await api.post('/mkt/consultant', {
        consultant: {
          ...data,
          id: v4(),
          fone: data.fone !== '' ? data.fone : '',
          bkg_color: data.bkg_color ? data.bkg_color : color,
        },
      });

      if (fileUploadRef?.current?.files) {
        const formData = new FormData();
        formData.append('avatar_url', fileUploadRef.current.files[0]);
        await api.patch(
          `/mkt/consultant/upload/in/${response.data.createConsultant.id}`,
          formData,
        );
      }

      navigate.push('/mkt/consultor/listar');
      formRef.current?.setErrors({});
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/mkt/consultor/listar')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Nome</label>
              <InputText name="nome" placeholder="Nome" />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">E-mail</label>
              <InputText name="email" placeholder="example@mail.com" />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Whatsapp</label>
              <InputPhone
                name="whatsapp"
                placeholder="(00) 0 0000-0000"
                value={whatsapp}
                onChange={e => setWhatsapp(e.currentTarget.value)}
              />
            </div>
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Fone</label>
              <InputPhone
                name="fone"
                placeholder="(00) 0 0000-0000"
                value={phone}
                onChange={e => setPhone(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Cor</label>

              <div className="relative">
                <InputText
                  name="bkg_color"
                  value={color}
                  onChange={e => setColor(e.currentTarget.value)}
                  maxLength={7}
                />
                <ColorPicker
                  value={color}
                  onChange={e => setColor(e.value as string)}
                  style={{
                    position: 'absolute',
                    right: 3,
                    top: 3,
                    height: '100%',
                  }}
                ></ColorPicker>
              </div>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <FileUpload
                ref={fileUploadRef}
                name="file"
                maxFileSize={1000000}
                multiple
                accept="image/*"
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                chooseOptions={chooseOptions}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                invalidFileSizeMessageSummary=""
                invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
              />
            </div>
          </div>
          {/* <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12"> */}
          <Button
            type="submit"
            label="Salvar"
            className="w-full"
            loading={loadingStatus}
          />
          {/* </div>
          </div> */}
        </Form>
      </div>
    </>
  );
};

export default ConsultantCreate;
