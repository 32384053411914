export interface ISummary {
  object: {
    qnt: number;
    items: number;
  };
  service: {
    qnt: number;
    items: number;
    add: number;
    desc: number;
    total: number;
  };
  product: {
    qnt: number;
    items: number;
    add: number;
    desc: number;
    total: number;
  };
  totals: {
    bruto: number;
    desc: number;
    add: number;
    juros: number;
    total: number;
  };
}

export const emptySummary: ISummary = {
  object: {
    qnt: 0,
    items: 0,
  },
  service: {
    qnt: 0,
    items: 0,
    add: 0,
    desc: 0,
    total: 0,
  },
  product: {
    qnt: 0,
    items: 0,
    add: 0,
    desc: 0,
    total: 0,
  },
  totals: {
    bruto: 0,
    desc: 0,
    add: 0,
    juros: 0,
    total: 0,
  },
};
