import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** components */
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

/** services */
import api from '../../../services/api';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** components */
import { Column } from 'primereact/column';
import { DataTable } from '../../../components/DataTable';
import Calendar from '../../../components/Inputs/InputCalendar';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';

/** utils */
import { format } from 'date-fns';
import { ProgressBar } from 'primereact/progressbar';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { parseToCnpj, parseToCpf } from '../../../utils/cpf_cnpj_format';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { numberToCurrency } from '../../../utils/number';

const MetaComissionedCreate = (): React.ReactElement => {
  const navigate = useHistory();

  const date = new Date();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [openModalBusiness, setOpenModalBusiness] = useState(false);
  const [openModalComissioned, setOpenModalComissioned] = useState(false);
  const [selectBusiness, setSelectBusiness] = useState<any>(null);
  const [metaBusiness, setMetaBusiness] = useState<any>();
  const [commissioneds, setComissioneds] = useState();
  const [selectComissioned, setSelectComissioned] = useState<any>();
  const [meta, setMeta] = useState<number | null>();
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );
  const [width, setWidth] = useState('70vw');
  const [errorMeta, setErrorMeta] = useState(false);
  const [errorComissioned, setErrorComissioned] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** handle new meta commissioned */
  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);

      const schemaMetaComissioned = Yup.object({
        meta_value: Yup.string().required(
          'Por favor, informe o valor da meta!',
        ),
        date_start: Yup.string().required(
          'Por favor, informe uma data inicial!',
        ),
        date_end: Yup.string().required('Por favor, informe uma data final!'),
      });
      await schemaMetaComissioned.validate(data, { abortEarly: false });

      if (!selectBusiness?.id) {
        setErrorMeta(true);
        toast('error', 'Error', 'Por favor, selecione a meta!');
        return;
      }
      if (!selectComissioned?.id) {
        setErrorComissioned(true);
        toast('error', 'Error', 'Por favor, selecione a meta do comissionado!');
        return;
      }
      setErrorComissioned(false);
      setErrorMeta(false);

      await api.post('/meta-commissioned', {
        meta_business_id: selectBusiness.id,
        commissioned_id: selectComissioned.id,
        meta_value: meta,
        date_start: format(initialDate as Date, 'yyyy-MM-dd'),
        date_end: format(finalDate as Date, 'yyyy-MM-dd'),
      });
      navigate.push('/meta-commissioneds/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** table */

  const numberColumnBody = (rowData: any, value: string) => {
    return <span>{numberToCurrency(parseFloat(rowData[value]))}</span>;
  };
  const codeColumnBody = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const cpfColumnBody = (rowData: any) => {
    return (
      <span>
        {rowData.cpf_cnpj.length > 11
          ? parseToCnpj(rowData.cpf_cnpj)
          : parseToCpf(rowData.cpf_cnpj)}
      </span>
    );
  };

  const comissionedColumnBody = (rowData: any) => {
    return <span>{parseFloat(rowData.comissao_geral)}%</span>;
  };

  const selectRowMetaTable = (e: any) => {
    setSelectBusiness(e.value);
    setOpenModalBusiness(false);
  };

  const selectComissionedOnChange = (e: any) => {
    setSelectComissioned(e.value);
    setOpenModalComissioned(false);
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/meta-business')
      .then(({ data }) => {
        setMetaBusiness(data);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/commissioneds')
      .then(({ data }) => {
        setComissioneds(data);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  });

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/meta-commissioneds/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-7 sm:w-full"
        />
      )}
      <div className="card md:w-7 sm:w-full">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col flex flex-wrap justify-content-between">
              <label htmlFor="basic">Meta</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectBusiness?.id
                    ? `${selectBusiness?.title} - ${numberToCurrency(
                      parseFloat(selectBusiness.meta_total),
                    )}`
                    : 'Selecionar Meta'
                }
                onClick={() => setOpenModalBusiness(true)}
                aria-controls="overlay_panel"
                className={
                  errorMeta
                    ? `select-product-button w-6 min-w-max p-button-danger`
                    : `select-product-button w-6 min-w-max`
                }
              />
            </div>
            <div className="field col-12 md:col flex flex-wrap justify-content-between">
              <label htmlFor="basic">Comissionado</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectComissioned?.id
                    ? `${selectComissioned.name}`
                    : 'Selecionar Comissionado'
                }
                onClick={() => setOpenModalComissioned(true)}
                aria-controls="overlay_panel"
                className={
                  errorComissioned
                    ? `select-product-button w-6 min-w-max p-button-danger`
                    : `select-product-button w-6 min-w-max`
                }
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Início Meta</label>
              <Calendar
                name="date_start"
                value={initialDate}
                showIcon
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Fim Meta</label>
              <Calendar
                name="date_end"
                value={finalDate}
                showIcon
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Meta</label>
              <InputNumber
                name="meta_value"
                value={meta}
                placeholder="R$ 0,00"
                onChange={e => setMeta(e.value)}
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Vendido</label>
              <InputText
                disabled
                className="surface-200"
                placeholder="R$ 0,00"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={openModalBusiness}
        position="right"
        style={{ width }}
        onHide={() => setOpenModalBusiness(false)}
      >
        <DataTable
          value={metaBusiness}
          selectionMode="single"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          selection={selectBusiness}
          onSelectionChange={e => selectRowMetaTable(e)}
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column field="code" header="#" body={codeColumnBody}></Column>
          <Column field="title" header="Título"></Column>
          <Column
            header="Meta Vendida"
            body={(rowData: any) => numberColumnBody(rowData, 'meta_total')}
          ></Column>
          <Column
            header="Meta Total"
            body={(rowData: any) => numberColumnBody(rowData, 'meta_sold')}
          ></Column>
        </DataTable>
      </SideBar>
      <SideBar
        visible={openModalComissioned}
        position="right"
        style={{ width }}
        onHide={() => setOpenModalComissioned(false)}
      >
        <DataTable
          value={commissioneds}
          selectionMode="single"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          selection={selectComissioned}
          onSelectionChange={e => selectComissionedOnChange(e)}
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column field="code" header="#" body={codeColumnBody}></Column>
          <Column field="name" header="Nome"></Column>
          <Column header="CPF/CNPJ" body={cpfColumnBody}></Column>
          <Column header="Comissão" body={comissionedColumnBody}></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default MetaComissionedCreate;
