export const uploadCertEmptyTemplate = (cert_url: string) => {
  return !cert_url ? (
    <>
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-id-card mt-3 p-0"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
      </div>
    </>
  ) : (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <i
          className="fa-solid fa-id-card mr-2"
          style={{ fontSize: '30px' }}
        ></i>
        <p>Certificado registrado.</p>
      </div>
    </div>
  );
};
