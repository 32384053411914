import { IConDevolutionItem } from '../dtos/IConDevolutionDTO';

export const emptyConDevolutionItem: IConDevolutionItem = {
  id: '',
  value: '',
  label: '',
  pos: 0,
  sku: '',
  con_devolution_id: '',
  table_price_id: '',
  table_price_description: '',
  product_id: '',
  product_description: '',
  product: null,
  qnt: 1,
  vlr_unit: 0,
  add: 0,
  desc: 0,
  vlr_liq: 0,
  total: 0,
  checked: false,
  status: '',
  user_id: '',
  created_at: new Date(),
  updated_at: new Date(),
  qnt_out: 2,
  value_out: 2,
};
