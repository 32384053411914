export interface IParametersOs {
  nat_ope_id: string;
  status_id: string;
  technical_id: string;
  commissioned_id: string;
  contract_id: string;
}

export const emptyParamsOS: IParametersOs = {
  nat_ope_id: '',
  status_id: '',
  technical_id: '',
  commissioned_id: '',
  contract_id: '',
};
