import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';
import TopMenu from '../../user/top-menu';

const AccessList = (): React.ReactElement => {
  const toast = useRef<any>(null);
  const navigate = useHistory();

  const [globalFilter, setGlobalFilter] = useState('');
  const [deleteAccessUserDialog, setDeleteAccessUserDialog] = useState(false);
  const [userSystem, setUserSystem] = useState<Array<any>>([]);
  const [userSys, setUserSys] = useState<any>();
  const [loading, setLoading] = useState(true);

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeleteUserAccess = (sysItem: any) => {
    setUserSys(sysItem);
    setDeleteAccessUserDialog(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() =>
            navigate.push(`/user-access/set/edit/${rowData.user_access_id}`)
          }
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteUserAccess(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Acessos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const hideDeleteAccessUserDialog = () => {
    setDeleteAccessUserDialog(false);
  };

  const deleteUserAccessApi = async () => {
    try {
      await api.delete(`/user-system-access/${userSys.user_access_id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteUserAccess = () => {
    const _userSystem = userSystem.filter(
      val => val.user_access_id !== userSys.user_access_id,
    );
    setUserSystem(_userSystem);
    setDeleteAccessUserDialog(false);
    setUserSys(undefined);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteUserAccessApi();
  };

  const deleteAccessUserDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteAccessUserDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUserAccess}
      />
    </React.Fragment>
  );

  const searchUserSystemAccess = async () => {
    try {
      const { data } = await api.get('/user-system-access');
      setUserSystem(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchUserSystemAccess();
  }, []);

  return (
    <>
      <TopMenu />
      <div className="card">
        <DataTable
          value={userSystem}
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} acessos"
          globalFilter={globalFilter}
          emptyMessage="Não existe acessos."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="user_access_id"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="name" header="Usuário"></Column>
          <Column header="Status" body={e => renderColumnStatus(e)}></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteAccessUserDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteAccessUserDialogFooter}
        onHide={hideDeleteAccessUserDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {userSys && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{userSys?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AccessList;
