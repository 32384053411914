import { useQuery } from 'react-query';
import { INatureOperation } from '../pages/nature-operation/dtos/INatureOperation';
import api from '../services/api';

interface IFind {
  paginate: {
    page: number;
    perPage: number;
    status: string[];
    kind: string[];
  };
}

export default function useLoadNatureOperationsShort({ paginate }: IFind) {
  const url = '/naturezas-operacao/list/short';

  const { data, error, isLoading, isFetched } = useQuery<INatureOperation[]>(
    ['nature-operations', url],
    () =>
      api
        .post(url, {
          paginate: {
            page: 1,
            perPage: 100,
            status: paginate.status,
            kind: paginate.kind,
          },
        })
        .then(response => response.data),
    { staleTime: 3000, cacheTime: 3000 },
  );
  return { data, error, isLoading, isFetched };
}
