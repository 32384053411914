import React, { useContext, useEffect } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

interface RouterApp extends RouteProps {
  pageId: string | undefined;
}

export const RouterAppDynamic: React.FC<RouterApp> = ({ pageId, ...rest }) => {
  const pathname = useLocation();
  const navigate = useHistory();

  const { state } = useContext(AppContext);

  useEffect(() => {
    if (pageId) {
      const hasAccess = state.access.find(i => i === pageId);
      if (!hasAccess) {
        navigate.push('/access');
        return;
      }
    }
  }, [pathname]);

  return <Route {...rest} />;
};
