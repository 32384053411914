import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

const userRecorderList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** toast */
  const toast = useRef<any>(null);

  /** dialog */
  const [dialogDeleteRegistersDialog, setDialogDeleteRegister] =
    useState(false);

  /** cash register */
  const [cashRegister, setCashRegister] = useState<any>();
  const [cash, setCash] = useState<any>();

  /** loading */
  const [loading, setLoading] = useState(true);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const confirmDeleteAdditional = (cash: any) => {
    setCash(cash);
    setDialogDeleteRegister(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/register-user/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteAdditional(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de usuários</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const hideDeleteRegisterDialog = () => {
    setDialogDeleteRegister(false);
  };

  const deleteRegisterApi = async () => {
    try {
      await api.delete(`/register-user/${cash.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteRegister = () => {
    const _cashs = cashRegister.filter((val: any) => val.id !== cash?.id);
    setCashRegister(_cashs);
    setDialogDeleteRegister(false);
    setCash(undefined);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteRegisterApi();
  };

  const deleteAdditionalDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteRegisterDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteRegister}
      />
    </React.Fragment>
  );

  const searchUserRegister = async () => {
    try {
      const { data } = await api.get('/register-user');
      setCashRegister(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchUserRegister();
  }, []);

  return (
    <>
      <Button
        label="Novo Usuário"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/register-user/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={cashRegister}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} usuários registradores"
          globalFilter={globalFilter}
          emptyMessage="Não existe usuários."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column header="Titular" field="title" sortable></Column>
          <Column field="codigo" header="Código" sortable></Column>

          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '8rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteRegistersDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteAdditionalDialogFooter}
        onHide={hideDeleteRegisterDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {cash && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{cash?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default userRecorderList;
