import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar as SideBarSearchOsKindObject } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';

const osKindObject: React.FC = () => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** hooks */
  const router = useHistory();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [kindObjectOpts, setKindObjectOpts] = useState<IOptionsDTO[]>([]);

  const [modalSearchOsKindObjectVisible, setModalSearchOsKindObjectVisible] =
    useState<boolean>(false);
  const [kindObjectKeyword, setContractKeyword] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setId('');
    setTitulo('');
    setIsEdit(false);
  };

  const handleSubmit = async () => {
    try {
      setLoadingStatus(true);
      if (isEdit) {
        const res = await api.put('/os/kind-object', {
          id,
          title: titulo,
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Tipo de objeto editado com sucesso!');
        }
      } else {
        const res = await api.post('/os/kind-object', {
          title: titulo,
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Tipo de objeto cadastrado com sucesso!');
        }
      }

      clearForm();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchKindObject = async () => {
    try {
      setLoadingStatus(true);
      const res = await api.get('/os/kind-object/list?status=A');

      if (res.status >= 200 && res.status <= 299) {
        const opts = res.data.listAllOsKindObject.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setKindObjectOpts(opts);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSelectKindObject = async (id: string) => {
    const dt = kindObjectOpts.find(i => i.value === id);

    if (!dt) {
      toast('warn', 'Alerta', 'Falha ao selecionar o item!');
      return;
    }

    setId(dt.value);
    setTitulo(dt.label);
    setIsEdit(true);
    setModalSearchOsKindObjectVisible(false);
  };

  const handleRemKindObject = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao exlcuir, tente novamente!');
      return;
    }

    try {
      setLoadingStatus(true);

      const res = await api.delete(`/os/kind-object/${id}`);
      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item excluído com sucesso!');
        searchKindObject();
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** render */
  const renderRemButton = (id: string) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemKindObject(id)}
      />
    );
  };

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => clearForm()}
      />
      <Button
        label="Buscar"
        className="mb-3 mx-2"
        icon="fa-solid fa-search"
        onClick={() => {
          setModalSearchOsKindObjectVisible(true);
          searchKindObject();
        }}
      />
      <Button
        label="O.S"
        className="mb-3 mx-2"
        icon="fa-solid fa-wrench"
        onClick={() => router.push('/os/new')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6">
        <Form ref={formRef} onSubmit={() => handleSubmit()}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12">
              <label htmlFor="title">Tipo de objeto</label>
              <InputText
                name="title"
                placeholder="Ex.: Telefone/Bicicleta..."
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={isEdit ? 'Editar' : 'Salvar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchOsKindObject
        visible={modalSearchOsKindObjectVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchOsKindObjectVisible(false)}
      >
        <h3>Buscar tipos de objeto</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="keyword"
                placeholder="Telefone..."
                type={'text'}
                value={kindObjectKeyword}
                onChange={e => setContractKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                onClick={() => searchKindObject()}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={kindObjectOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectKindObject(e.value.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="label" header="Título"></Column>
                <Column
                  header="*"
                  body={e => renderRemButton(e.value)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchOsKindObject>
    </>
  );
};

export default osKindObject;
