import { FormHandles } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import useToastContext from '../../../../hooks/toast';
import { Button } from 'primereact/button';
import { Form } from '@unform/web';
import { InputText } from '../../../../components/Inputs/InputText';
import { EnumPriority } from '../../../../enum/EnumPriority';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import api from '../../../../services/api';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import {
  ICrmAction,
  ICrmColumn,
  ICrmTag,
} from '../../../marketing/crm/dtos/ICrmDTO';
import { IUser } from '../../../user/dtos/IUser';
import messageRequestError from '../../../../utils/messageRequestError';
import defaultPriorities from '../../../../utils/defaultPriorities';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import InputNumberRange from '../../../../components/Inputs/InputNumberRange';
import CrmMenuTop from '../../../marketing/crm/top-menu';
import TimeRange from '../../../../components/Inputs/TimeRange.index';
import { endOfDay, isDate, startOfDay } from 'date-fns';
import Progress from '../../../../components/progress-bar';
import { EnumStatus } from '../../../../enum/EnumStatus';
import openReports from '../../../../utils/openReports';

const ReportCrmCards = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState<boolean>(false);

  const today = new Date();

  const [tags, setTags] = useState<ICrmTag[]>([]);
  const [tagsOpts, setTagsOpts] = useState<IOptionsDTO[]>([]);

  const [actions, setActions] = useState<ICrmAction[]>([]);
  const [actionsOpts, setActionsOpts] = useState<IOptionsDTO[]>([]);

  const [user, setUser] = useState<any>();
  const [usersOpts, setUsersOpts] = useState<IOptionsDTO[]>([]);

  const [panelsOpts, setPanelsOpts] = useState<IOptionsDTO[]>([]);

  const [columns, setColumns] = useState<ICrmColumn[]>([]);
  const [columnsOpts, setColumnsOpts] = useState<IOptionsDTO[]>([]);

  interface IFilter {
    title: string;
    tags: string[];
    panels: string[];
    columns: string[];
    value_range: number[];
    interactions: string[];
    priorities: EnumPriority[];
    members: string[];
    schedules: Date[];
    time_range: Date[];
  }

  const emptyFilter: IFilter = {
    title: '',
    tags: [],
    value_range: [0, 100000],
    interactions: [],
    priorities: [],
    members: [],
    schedules: [],
    time_range: [startOfDay(today), endOfDay(today)],
    panels: [],
    columns: [],
  };

  const [filter, setFilter] = useState<IFilter>(emptyFilter);

  useEffect(() => {
    if (columns && columns.length > 0) {
      setColumnsOpts(
        columns
          .filter(c => filter.panels.includes(c.panel_id))
          .map(column => {
            return {
              value: column.id,
              label: column.title,
            };
          }),
      );
    }
  }, [filter.panels]);

  useEffect(() => {
    const loadTags = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/crm/tag/all', {
          dataShow: { page: 1, perPage: 100, status: 'A' },
        });
        setTags(data);
        setTagsOpts(
          data.map((tag: ICrmTag) => ({
            value: tag.id,
            label: tag.title,
            pos: tag.position,
          })),
        );
      } catch (err) {
        toast('warn', 'Alerta', messageRequestError(err));
      } finally {
        setLoad(false);
      }
    };

    const loadActions = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/crm/action/all', {
          dataShow: { page: 1, perPage: 100, status: 'A' },
        });
        setActions(data);
        setActionsOpts(
          data.map((action: any) => ({
            value: action.id,
            label: action.title,
          })),
        );
      } finally {
        setLoad(false);
      }
    };

    const loadUsers = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/users/list', {
          pagination: { page: 1, perPage: 1000, status: ['A'] },
        });
        setUsersOpts(
          data.map((user: IUser) => ({ label: user.name, value: user.id })),
        );
      } finally {
        setLoad(false);
      }
    };

    const loadPanels = async (id: string) => {
      setLoad(true);
      await api
        .get(`/crm/panels/user/${id}`)
        .then(({ data }) => {
          if (data) {
            setPanelsOpts(
              data.map((i: any) => {
                return {
                  value: i.id,
                  label: i.title,
                };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    };

    const loadColumns = async () => {
      setLoad(true);
      await api
        .post(`/crm/column/all`, {
          dataShow: {
            page: 1,
            perPage: 100,
            status: EnumStatus.ATIVO,
          },
        })
        .then(({ data }) => {
          if (data) {
            setColumns(data);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    };

    const initializeData = async () => {
      await Promise.all([
        loadTags(),
        loadActions(),
        loadUsers(),
        loadPanels(user.id),
        loadColumns(),
      ]);
    };

    if (user && user.id) initializeData();
  }, [user]);

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const handleSubmit = async () => {
    if (
      filter.schedules.length !== 0 &&
      (!isDate(filter.schedules[0]) || !isDate(filter.schedules[1]))
    ) {
      toast(
        'warn',
        'Alerta',
        'Para buscar os cards pela data do lembrete, selecione o dia inicial e o dia final!',
      );
      return;
    }

    setLoad(true);
    await api
      .post('/crm/report/cards', { filter })
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'relatorio-crm-cards',
          );
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  const priorityTemplate = (option: IOptionsDTO) => {
    const color =
      option.value === EnumPriority.BAIXA
        ? '#689F38'
        : option.value === EnumPriority.MEDIA
        ? '#FBC02C'
        : '#D32F30';

    return (
      <div className="flex align-items-center">
        <i className="pi pi-circle-fill" style={{ color }}></i>
        <span className="ml-2">{option.label}</span>
      </div>
    );
  };

  const interactionsTemplate = (option: IOptionsDTO) => {
    const action = actions.find(a => a.id === option.value);

    if (action) {
      return (
        <div className="flex align-items-center">
          <i className={action.icon}></i>
          <span className="ml-2">{option.label}</span>
        </div>
      );
    }
  };

  const tagsTemplate = (option: IOptionsDTO) => {
    const tag = tags.find(t => t.id === option.value);
    if (tag) {
      return (
        <div className="flex align-items-center">
          <i className="pi pi-circle-fill" style={{ color: tag.color }}></i>
          <span className="ml-2">{option.label}</span>
        </div>
      );
    }
  };

  return (
    <>
      <CrmMenuTop />

      <Progress isLoad={load} className="md:w-7 sm:w-full" />

      <div className="card md:w-7 sm:w-full mb-2">
        <Form
          ref={null}
          onSubmit={handleSubmit}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="field col-12 md:col-12">
            <label htmlFor="title">Título</label>
            <InputText
              name="title"
              value={filter.title}
              onChange={e => setFilter({ ...filter, title: e.target.value })}
              placeholder="Ex.: João da Silva LTDA.."
            />
          </div>

          <div className="field col-12 md:col-6 pb-0">
            <label htmlFor="panels">
              Painéis{' '}
              {filter.panels && filter.panels.length > 0
                ? `[${filter.panels.length}]`
                : ''}
            </label>
            <MultiSelect
              name="panels"
              options={panelsOpts}
              value={filter.panels}
              onChange={e => setFilter({ ...filter, panels: e.value })}
              placeholder="Selecione..."
              display="chip"
              filter
            />
          </div>

          <div className="field col-12 md:col-6 pb-0">
            <label htmlFor="columns">
              Colunas{' '}
              {filter.columns && filter.columns.length > 0
                ? `[${filter.columns.length}]`
                : ''}
            </label>
            <MultiSelect
              name="columns"
              options={columnsOpts}
              value={filter.columns}
              onChange={e => setFilter({ ...filter, columns: e.value })}
              placeholder="Selecione..."
              display="chip"
              disabled={filter.panels.length === 0}
            />
          </div>

          <div className="field col-12 md:col-4 pb-0">
            <label htmlFor="schedules">Lembretes entre</label>
            <CalendarRange
              name="schedules"
              value={filter.schedules}
              onChange={e => {
                setFilter({ ...filter, schedules: e.value as Date[] });
              }}
              placeholder="00/00/00 ~ 00/00/00"
            />
          </div>

          <div className="field col-6 md:col-4">
            <label htmlFor="periodTime">Horário entre</label>
            <TimeRange
              name="periodTime"
              period={filter.time_range}
              disabled={
                filter.schedules.length !== 2 ||
                filter.schedules[0] === null ||
                filter.schedules[1] === null
              }
              onInitialTimeChange={e => {
                setFilter({
                  ...filter,
                  time_range: [e as Date, filter.time_range[1]],
                });
              }}
              onFinalTimeChange={e =>
                setFilter({
                  ...filter,
                  time_range: [filter.time_range[0], e as Date],
                })
              }
            />
          </div>

          <div className="field col-6 md:col-4">
            <label htmlFor="value_range">Valores entre</label>
            <InputNumberRange
              name="value_range"
              range={filter.value_range}
              defaultMinValue={0}
              defaultMaxValue={1000000}
              onMinValueChange={e =>
                setFilter({
                  ...filter,
                  value_range: [e as number, filter.value_range[1]],
                })
              }
              onMaxValueChange={e =>
                setFilter({
                  ...filter,
                  value_range: [filter.value_range[0], e as number],
                })
              }
            />
          </div>

          <div className="field col-6 md:col-3 pb-0">
            <label htmlFor="members">
              Membros{' '}
              {filter.members && filter.members.length > 0
                ? `[${filter.members.length}]`
                : ''}
            </label>
            <MultiSelect
              name="members"
              options={usersOpts}
              value={filter.members}
              onChange={e => setFilter({ ...filter, members: e.value })}
              placeholder="Selecione..."
              display="chip"
            />
          </div>

          <div className="field col-6 md:col-3 pb-0">
            <label htmlFor="tags">
              Tags{' '}
              {filter.tags && filter.tags.length > 0
                ? `[${filter.tags.length}]`
                : ''}
            </label>
            <MultiSelect
              name="tags"
              options={tagsOpts}
              value={filter.tags}
              onChange={e => setFilter({ ...filter, tags: e.value })}
              itemTemplate={tagsTemplate}
              placeholder="Selecione..."
              display="chip"
              filter
            />
          </div>

          <div className="field col-6 md:col-3">
            <label htmlFor="priorities">
              Prioridade{' '}
              {filter.priorities && filter.priorities.length > 0
                ? `[${filter.priorities.length}]`
                : ''}
            </label>
            <MultiSelect
              name="priority"
              options={defaultPriorities}
              value={filter.priorities}
              itemTemplate={priorityTemplate}
              onChange={e => setFilter({ ...filter, priorities: e.value })}
              placeholder="Selecione..."
              display="chip"
            />
          </div>

          <div className="field col-6 md:col-3 pb-0">
            <label htmlFor="interactions">
              Interações{' '}
              {filter.interactions && filter.interactions.length > 0
                ? `[${filter.interactions.length}]`
                : ''}
            </label>
            <MultiSelect
              name="interactions"
              options={actionsOpts}
              value={filter.interactions}
              onChange={e => setFilter({ ...filter, interactions: e.value })}
              placeholder="Selecione..."
              itemTemplate={interactionsTemplate}
              display="chip"
              filter
            />
          </div>

          <div className="field col-12 md:col-6 mt-4">
            <Button
              label="Limpar"
              icon="fa-solid fa-xmark"
              type="button"
              className="p-button-raised p-button-danger"
              onClick={() => {
                setFilter(emptyFilter);
              }}
            />
          </div>

          <div className="field col-12 md:col-6 mt-4">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              type="submit"
              loading={load}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportCrmCards;
