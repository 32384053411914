import { cnpj, cpf } from 'cpf-cnpj-validator';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useState } from 'react';
import AppBreadcrumb from '../AppBreadcrumb';

/* app-menu */
import AppMenu from '../AppMenu';

/* logout  */
import { LogOut } from '../../../hooks/auth';

/* react-router-dom */
import { Link, useHistory } from 'react-router-dom';

/* context */
import { AppContext } from '../../../context/AppContext';
import { useOrder } from '../../../hooks/useOrder';

const AppTopbar = (props: any): React.ReactElement => {
  const notificationsItemClassName = classNames('notifications-item', {
    'active-menuitem': props.topbarNotificationMenuActive,
  });
  const profileItemClassName = classNames('profile-item', {
    'active-menuitem fadeInDown': props.topbarUserMenuActive,
  });

  const { state, dispatch } = useContext(AppContext);

  const { clearData, clearOptions } = useOrder();

  const [user, setUser] = useState<any>();

  /* navigate */
  const navigate = useHistory();

  const logOut = () => {
    /* remove user statios */
    dispatch({ type: 'SET_USER', payload: { user: {} } });
    /* clean session */
    LogOut();

    /** clear order context */
    clearData();
    clearOptions();
    /* redirect */
    navigate.push('/signin');
  };

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className="layout-topbar">
      <div className="topbar-left">
        <button
          type="button"
          className="menu-button p-link"
          onClick={props.onMenuButtonClick}
        >
          <i className="pi pi-chevron-left"></i>
        </button>

        <Link to="/">
          <img
            id="logo-horizontal"
            className="horizontal-logo"
            src="/assets/layout/images/logo-white.svg"
            alt="diamond-layout"
          />
        </Link>

        <span className="topbar-separator"></span>

        <div
          className="layout-breadcrumb viewname"
          style={{ textTransform: 'uppercase' }}
        >
          <AppBreadcrumb routers={props.routers} />
        </div>

        <img
          id="logo-mobile"
          className="mobile-logo"
          src="/assets/layout/images/logo-dark.svg"
          alt="diamond-layout"
        />
      </div>

      <AppMenu
        model={props.menu}
        menuMode={props.menuMode}
        active={props.menuActive}
        mobileMenuActive={props.staticMenuMobileActive}
        onMenuClick={props.onMenuClick}
        onMenuitemClick={props.onMenuitemClick}
        onRootMenuitemClick={props.onRootMenuitemClick}
      ></AppMenu>

      <div className="layout-mask modal-in"></div>

      <div className="topbar-right">
        <ul className="topbar-menu">
          <li>
            {state.user.business && state.user.business.name} {' # '}
            {state.user.business &&
              (state.user.business.cpf_cnpj.length === 11
                ? cpf.format(state.user.business.cpf_cnpj)
                : cnpj.format(state.user.business.cpf_cnpj))}
          </li>
          <li className="search-item">
            <button
              type="button"
              className="p-link"
              onClick={props.onSearchClick}
            >
              <i className="pi pi-search"></i>
            </button>
          </li>
          <li className={notificationsItemClassName}>
            <button
              type="button"
              className="p-link"
              onClick={props.onTopbarNotification}
            >
              <i className="pi pi-bell"></i>
              <span className="topbar-badge">0</span>
            </button>
            <ul className="notifications-menu fade-in-up">
              <li role="menuitem">
                <button type="button" className="p-link" tabIndex={0}>
                  <i className="pi pi-shopping-cart"></i>
                  <div className="notification-item">
                    <div className="notification-summary">Vendas</div>
                    <div className="notification-detail">
                      Você tem <strong>0</strong> novos pedidos online.
                    </div>
                  </div>
                </button>
              </li>

              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-chart-line"></i>
                  <div className="notification-item">
                    <div className="notification-summary">Contas a pagar</div>
                    <div className="notification-detail">
                      Você tem <strong>0</strong> contas a pagar hoje.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-chart-bar"></i>
                  <div className="notification-item">
                    <div className="notification-summary">Contas a receber</div>
                    <div className="notification-detail">
                      Você tem <strong>0</strong> contas a receber hoje.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-exclamation-circle"></i>
                  <div className="notification-item">
                    <div className="notification-summary">
                      Reclamações via whatsapp
                    </div>
                    <div className="notification-detail">
                      <strong>0</strong>
                      rever.
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </li>

          <li className={profileItemClassName}>
            <button
              type="button"
              className="p-link"
              onClick={props.onTopbarUserMenu}
            >
              {state.user.avatar_url ? (
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}/files/img/${state.user.avatar_url}`}
                  alt="diamond-layout"
                  className="profile-image"
                />
              ) : (
                <i className="pi pi-user mr-2"></i>
              )}

              <span className="profile-name">
                {state.user.name.split(' ')[0]}
              </span>
            </button>
            <ul className="profile-menu fade-in-up">
              <li>
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate.push('/profile')}
                >
                  <i className="pi pi-user"></i>
                  <span>Perfil</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate.push('/parameters')}
                >
                  <i className="pi pi-cog"></i>
                  <span>Configurações</span>
                </button>
              </li>
              {user && user.id === '26cba209-30d2-4d1e-83c0-45361f89f939' && (
                <li>
                  <button
                    type="button"
                    className="p-link"
                    onClick={() => navigate.push('/control-panel')}
                  >
                    <i className="fa-solid fa-wrench"></i>
                    <span>Painel de controle</span>
                  </button>
                </li>
              )}
              <li>
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate.push('/plans/upgrade')}
                >
                  <i className="fa-regular fa-file"></i>
                  <span>Upgrade Planos</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate.push('/monthly-payments')}
                >
                  <i className="fa-solid fa-barcode"></i>
                  <span>Mensalidades</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate.push('/calendar')}
                >
                  <i className="pi pi-calendar"></i>
                  <span>Calendário</span>
                </button>
              </li>
              <li>
                <Link className="p-link" to="/chat">
                  <i className="pi pi-inbox"></i>
                  <span>Chat</span>
                </Link>
              </li>
              <li>
                <button type="button" className="p-link" onClick={logOut}>
                  <i className="pi pi-power-off"></i>
                  <span>Sair</span>
                </button>
              </li>
            </ul>
          </li>

          <li className="right-sidebar-item">
            <button
              type="button"
              className="p-link"
              onClick={props.onRightMenuButtonClick}
            >
              <i className="pi pi-align-right"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
