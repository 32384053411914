import React from 'react';
import { Button, ButtonProps } from 'primereact/button';
import useToastContext from '../../hooks/toast';

interface CopyButtonProps extends ButtonProps {
  text: string;
}

export const CopyButton: React.FC<CopyButtonProps> = props => {
  const toast = useToastContext();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(props.text)
      .then(() => {
        toast('success', 'Sucesso', 'Copiado para a àrea de transferência.');
      })
      .catch(error => {
        toast('warn', 'Falha', 'Falha ao copiar para a àrea de transferência.');
        console.error('Erro ao copiar o texto:', error);
      });
  };

  return (
    <Button {...props} icon="fa-solid fa-copy" onClick={copyToClipboard} />
  );
};
