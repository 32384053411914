import { useQuery } from 'react-query';
import { ITablePrice } from '../pages/tablePrice/dtos/ITablePriceDTO';
import api from '../services/api';

interface IProps {
  pagination: {
    page: number;
    perPage: number;
    status: string[];
  };
}

export default function useLoadPriceTables({ pagination }: IProps) {
  const url = '/table-prices/list';
  const { data, error, isLoading, isFetched } = useQuery<ITablePrice[]>(
    ['table-prices', url],
    () =>
      api
        .post(url, {
          pagination,
        })
        .then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
