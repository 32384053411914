import React, { useState } from 'react';

const Chat = () => {
  const [message, setMessage] = useState('');

  return (
    <>
      <div className="flex-1 border-1 surface-border surface-card border-round">
        <div className="flex flex-column h-full">
          <div className="flex align-items-center border-bottom-1 surface-border p-3 lg:p-6">
            <div className="relative flex align-items-center mr-3">
              <img
                src="/favicon.ico"
                alt="Ioni Bowcher"
                className="w-4rem h-4rem border-circle shadow-4"
              />

              <span className="w-1rem h-1rem border-circle border-2 surface-border absolute bottom-0 right-0 bg-green-400"></span>
            </div>
            <div className="mr-2">
              <span className="text-900 font-semibold block">Webger</span>
              <span className="text-700">Visto por ultimo 1 hora atrás</span>
            </div>
            <div className="flex align-items-center ml-auto">
              <button
                type="button"
                className="p-button p-component p-button-rounded p-button-outlined p-button-secondary mr-3 p-button-icon-only"
              >
                <span className="p-button-icon p-c pi pi-phone"></span>
                <span className="p-button-label p-c">&nbsp;</span>
              </button>
              <button
                type="button"
                className="p-button p-component p-button-rounded p-button-outlined p-button-secondary p-button-icon-only"
              >
                <span className="p-button-icon p-c pi pi-ellipsis-v"></span>
                <span className="p-button-label p-c">&nbsp;</span>
              </button>
            </div>
          </div>
          <div
            className="p-3 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto"
            style={{ maxHeight: '53vh' }}
          >
            <div>
              <div className="grid grid-nogutter mb-4">
                <div className="mr-3 mt-1">
                  <img
                    src="/favicon.ico"
                    alt="webger"
                    className="w-3rem h-3rem border-circle shadow-4"
                  />
                </div>
                <div className="col mt-3">
                  <p className="text-900 font-semibold mb-3">Webger</p>
                  <span
                    className="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round"
                    style={{ wordBreak: 'break-word', maxWidth: '80%' }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </span>
                  <p className="text-700 mt-3">
                    17:25<i className="pi pi-check ml-2 text-green-400"></i>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-nogutter mb-4">
                <div className="mr-3 mt-1">
                  <img
                    src="/favicon.ico"
                    alt="Ioni Bowcher"
                    className="w-3rem h-3rem border-circle shadow-4"
                  />
                </div>
                <div className="col mt-3">
                  <p className="text-900 font-semibold mb-3">Webger</p>
                  <span
                    className="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round"
                    style={{ wordBreak: 'break-word', maxWidth: '80%' }}
                  >
                    Sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </span>
                  <p className="text-700 mt-3">
                    17:26<i className="pi pi-check ml-2 text-green-400"></i>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-nogutter mb-4">
                <div className="col mt-3 text-right">
                  <span
                    className="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round"
                    style={{ wordBreak: 'break-word', maxWidth: '80%' }}
                  >
                    Sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </span>
                  <p className="text-700 mt-3">
                    17:26 <i className="pi pi-check ml-2 text-green-400"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 md:p-4 lg:p-6 flex flex-column sm:flex-row align-items-center mt-auto border-top-1 surface-border gap-3">
            <input
              id="message"
              type="text"
              placeholder="Digite uma mensagem..."
              className="p-inputtext p-component p-filled flex-1 w-full sm:w-auto border-round"
              value={message}
              onChange={e => setMessage(e.currentTarget.value)}
            />
            <div className="flex w-full sm:w-auto gap-3 relative">
              <button
                aria-label="Send"
                className="p-button p-component w-full sm:w-auto ml-3"
              >
                <span className="p-button-icon p-c p-button-icon-left pi pi-send"></span>
                <span className="p-button-label p-c">Enviar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
