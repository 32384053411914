import { v4 } from 'uuid';

export interface IBillReceivableResume {
  id: string;
  customer_id: string;
  customer_name: string;
  emited_day: Date;
  date_to_pay: Date;
  value: number;
  rest: number;
  doc_ser: string;
  doc_num: string;
  description: string;
  status_payment: string;
  user_id: string;
  user_name: string;
  status: string;
  created_at: Date;
}

export const emptyBillReceivableResume: IBillReceivableResume = {
  id: v4(),
  customer_id: '',
  customer_name: '',
  emited_day: new Date(),
  date_to_pay: new Date(),
  value: 0,
  rest: 0,
  doc_ser: '',
  doc_num: '',
  description: '',
  status_payment: '',
  user_id: '',
  user_name: '',
  status: '',
  created_at: new Date(),
};
