import React, { useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** components */
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import InputDate from '../../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** type */
import { Option } from '../../../../types/optional';

/** service */
import api from '../../../../services/api';

/** utisl */
import { format, startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import BillsToReceivableHeader from '../../../bills-to-receive/header';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';
import useToastContext from '../../../../hooks/toast';

const ReportsBillReceibleByCustomer: React.FC = (): React.ReactElement => {
  const hoje = new Date();

  /** ref */
  const form = useRef<FormHandles>(null);
  const toastRef = useRef<any>(null);

  const toast = useToastContext();

  /** initial date */
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  /** status */
  const situacaoOpts: IOptionsDTO[] = [
    { label: 'PAGAR', value: 'A' },
    { label: 'PAGAS', value: 'P' },
    { label: 'ATRASADAS', value: 'V' },
    { label: 'TODOS', value: 'T' },
  ];
  const [situacao, setSituacao] = useState<string>('T');

  const statusOpts: IOptionsDTO[] = [
    { label: 'ATIVO', value: 'A' },
    { label: 'CANCELADO', value: 'C' },
  ];
  const [status, setStatus] = useState<string>('A');

  /** providers */
  const [customers, setCustomers] = useState();

  const [customer, setSearchCustomer] = useState<any>();

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toastRef?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** autoComplete */
  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const productOptions = ammountOptions(customers);
        productOptions.length <= 0 &&
          showToast('error', 'Error', 'Nenhum produto encontrado!');

        setCustomers(productOptions);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleReports = async (data: any) => {
    try {
      setIsLoad(true);
      const schemaReports = Yup.object({
        status: Yup.string().required('Por favor, informe o status!'),
      });

      await schemaReports.validate(data, { abortEarly: false });

      const { data: response } = await api.get(`/reports/bills-receivable`, {
        params: {
          dateStart: format(initialDate as Date, 'yyyy-MM-dd'),
          dateEnd: format(finalDate as Date, 'yyyy-MM-dd'),
          customerId: customer?.value,
          situacao: situacao || 'T',
          status: status || 'A',
        },
      });

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <>
      <BillsToReceivableHeader />
      <div className="card md:w-6 sm:w-full">
        <Form
          ref={form}
          onSubmit={handleReports}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Cliente</label>
              <InputAutoComplete
                name="customer"
                value={customer}
                completeMethod={e => searchCustomer(e.query)}
                suggestions={customers}
                className="w-6"
                field="label"
                onChange={e => setSearchCustomer(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar clientes..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data venc. inicial</label>
              <InputDate
                name="date_initial"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
                showIcon
                iconPos="right"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data venc. final</label>
              <InputDate
                name="date_final"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
                showIcon
                iconPos="right"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Situação</label>
              <InputDropDown
                name="situacao"
                value={situacao}
                options={situacaoOpts}
                placeholder="Selecionar..."
                onChange={e => setSituacao(e.value)}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Status</label>
              <InputDropDown
                name="status"
                value={status}
                options={statusOpts}
                placeholder="Selecionar..."
                onChange={e => setStatus(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Imprimir"
                icon="fa-solid fa-print"
                className="p-button-success"
                type="submit"
                loading={isLoad}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsBillReceibleByCustomer;
