import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../../components/Modal';
import api from '../../../../services/api';
import { ICrmTag } from '../dtos/ICrmDTO';
import useToastContext from '../../../../hooks/toast';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  card_id: string;
}

const DialogTags = ({ isOpen, onRequestClose, card_id }: IProps) => {
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [tags, setTags] = useState<ICrmTag[]>([]);

  const emptyTag = {
    id: '',
    position: 0,
    color: '2ECC71',
    title: '',
    user_id: '',
  };
  const [tag, setTag] = useState<ICrmTag>(emptyTag);

  const loadTags = async () => {
    setLoad(true);
    await api
      .post('/crm/tag/all', {
        dataShow: { page: 1, perPage: 100, status: 'A' },
      })
      .then(({ data }) => {
        if (data) {
          setTags(
            data
              .sort((a: any, b: any) => a.position - b.position)
              .map((i: ICrmTag) => {
                return {
                  id: i.id,
                  position: i.position,
                  color: i.color,
                  title: i.title,
                };
              }),
          );
        }
      })
      .finally(() => setLoad(false));
  };

  interface ISetTag {
    card_id: string;
    tag_id: string;
  }

  const handleSetTag = async ({ card_id, tag_id }: ISetTag) => {
    setLoad(true);
    await api
      .post('/crm/cards/tags', {
        data: { card_id: card_id, tag_id: tag_id },
      })
      .then(({ data }) => {
        if (data) {
          setTag(emptyTag);
          onRequestClose();
        }
      })
      .finally(() => setLoad(false));
  };

  const handleCreateTag = async () => {
    if (tag.title === '') {
      toast('warn', 'Alerta', 'Informe um título para a nova tag!');
    } else {
      setLoad(true);
      await api
        .post('/crm/tag', {
          data: {
            title: tag.title,
            color: `#${tag.color}`,
          },
        })
        .then(async ({ data }) => {
          if (data) {
            setTag(emptyTag);
            await loadTags();
          }
        })
        .finally(() => setLoad(false));
    }
  };

  useEffect(() => {
    if (isOpen) loadTags();
  }, [isOpen]);

  return (
    <Dialog
      header="Etiquetas (Tags)"
      visible={isOpen}
      style={{ width: 512 }}
      onHide={() => onRequestClose()}
    >
      <div className="p-fluid grid formgrid">
        <div className="field col-6">
          <label htmlFor="title">Tag/Etiqueta</label>
          <InputText
            name="title"
            value={tag.title}
            onChange={e => setTag({ ...tag, title: e.currentTarget.value })}
            placeholder="Ex.: Cliente promissor"
            tooltip="Digite o título da tag e aperte enter para criar"
            tooltipOptions={{ position: 'top' }}
            onKeyDown={async e => {
              if (e.key === 'Enter') {
                await handleCreateTag();
              }
            }}
          />
        </div>
        <div className="field col-6">
          <label className="field col-6" htmlFor="color">
            Cor
          </label>
          <ColorPicker
            name="color"
            value={tag.color}
            onChange={e => setTag({ ...tag, color: e.value as string })}
            onKeyDown={async e => {
              if (e.key === 'Enter') {
                await handleCreateTag();
              }
            }}
            className="field col-12"
          />
        </div>
        <div className="field col-12 justify-content-center">
          <Button
            label="Criar nova tag"
            icon="pi pi-check"
            className="p-button-sm"
            type="button"
            onClick={async () => await handleCreateTag()}
            loading={load}
          />
        </div>
        <label className="field col-6 mt-3" htmlFor="color">
          Tags criadas:
        </label>
        <div className="field col-12">
          {tags
            .sort((a, b) => a.position - b.position)
            .map((i: ICrmTag) => (
              <Button
                key={i.id}
                label={i.title}
                style={{ background: `${i.color}`, borderColor: `${i.color}` }}
                icon="fa-solid fa-plus"
                type="button"
                className="mb-2"
                onClick={() => handleSetTag({ card_id: card_id, tag_id: i.id })}
                loading={load}
              />
            ))}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogTags;
