import React, { useRef, useState, useEffect } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** types */
import { Option } from '../../types/optional';

/** api */
import api from '../../services/api';

/** utils */
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';

/** types */
import IProductInOpts from '../order/Create/dtos/IProductInOpts';

/** components */
import { Button } from 'primereact/button';
import { InputDropDown } from '../../components/Inputs/InputDropDown';
import Calendar from '../../components/Inputs/InputCalendar';
import { InputAutoComplete } from '../../components/Inputs/InputAutoComplete';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';

import { Toast } from 'primereact/toast';
import { InputNumber } from '../../components/Inputs/InputNumber';
import useToastContext from '../../hooks/toast';
import showErrors from '../../utils/error';

const ConvertProduct = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const showToast = useToastContext();

  const [product, setProduct] = useState<any>();

  const [products, setSearchProducts] = useState<any>();

  const [productsOptsIn, setProductsOptsIn] = useState<IProductInOpts[]>([]);
  const [productIn, setProductIn] = useState<any>();

  const [numberOut, setNumberOut] = useState(1);
  const [numberIn, setNumberIn] = useState(1);

  const [loadingStatus, setLoadingStatus] = useState(false);

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
  };

  const clearInputs = () => {
    setNumberIn(0);
    setNumberOut(0);
    setProduct(undefined);
    setProductIn(undefined);
  };

  /** autoComplete */
  const searchProductOut = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const productOptions = ammountOptions(data.products);
          productOptions.length <= 0 &&
            showToast('error', 'Error', 'Nenhum produto encontrado!');

          setSearchProducts(productOptions);
        })
        .catch(error => showToast('error', 'Error', error.response.data.error));
    }
  };

  const searchProductIn = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const productOptions = ammountOptions(data.products);
          productOptions.length <= 0 &&
            showToast('error', 'Error', 'Nenhum produto encontrado!');

          setProductsOptsIn(productOptions);
        })
        .catch(error => showToast('error', 'Error', error.response.data.error));
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schema = Yup.object({
        product_out: Yup.string().required('Produto saída é Obrigatório!'),
        product_in: Yup.string().required('Produto entrada é Obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.post('/product-conversion', {
        conversion: {
          product_out: product?.value,
          qnt_out: numberOut,
          product_in: productIn?.value,
          qnt_int: numberIn,
        },
      });
      showToast('success', 'Sucesso!', 'Produto convertido!');
      clearInputs();
    } catch (error: any) {
      showErrors(error, form, showToast);
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Produto Saída</label>
              <InputAutoComplete
                name="product_out"
                value={product}
                completeMethod={e => searchProductOut(e)}
                suggestions={products}
                className="w-6"
                field="label"
                onChange={e => setProduct(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar Produto..."
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid ">
            <div className="field col-6 md:col"></div>
            <div className="field col-6 md:col">
              <label htmlFor="">Qnt</label>
              <InputNumber
                name="qnt_out"
                value={numberOut}
                showButtons
                onChange={e => setNumberOut(e.value as number)}
                minFractionDigits={2}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Produto Entrada</label>
              <InputAutoComplete
                name="product_in"
                value={productIn}
                completeMethod={e => searchProductIn(e)}
                field="label"
                onChange={e => setProductIn(e.value)}
                placeholder="Buscar Produto..."
                suggestions={productsOptsIn}
                itemTemplate={itemTemplate}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid ">
            <div className="field col-6 md:col"></div>
            <div className="field col-6 md:col">
              <label htmlFor="">Qnt</label>
              <InputNumber
                name="qnt_out"
                value={numberIn}
                onChange={e => setNumberIn(e.value as number)}
                showButtons
                minFractionDigits={2}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-6 md:col">
              <Button
                label="Limpar"
                className="p-button-danger"
                icon="pi pi-times"
                loading={loadingStatus}
                onClick={e => {
                  e.preventDefault();
                  clearInputs();
                }}
              />
            </div>
            <div className="field col-6 md:col">
              <Button
                label="Converter"
                className="p-button-success"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ConvertProduct;
