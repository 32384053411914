const convertIsoDateToString = (isoDate: string): string => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adiciona 1, pois os meses começam em 0
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export default convertIsoDateToString;
