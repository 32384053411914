import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import {
  InputText as InText,
  InputText,
} from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import useLoadPaymentConditions from '../../../hooks/useLoadPaymentConditions';
import useLoadTransporters from '../../../hooks/useLoadTransporters';
import { useOrderService } from '../../../hooks/useOrderService';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import formatCurrency from '../../../utils/numbers/FormatCurrency';
import openReports from '../../../utils/openReports';
import freteOpts from '../../order/types/freteOpts';
import IOs from '../dtos/IOs';
import { emptyOsTransporter } from '../types/EmptyOsTransporter';
import InputRowPaymentsOS from '../../../components/input-row/payment-os';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const OsCheckout = ({ isOpen, onRequestClose }: IProps): React.ReactElement => {
  const hoje = new Date();

  const params: { id: string } = useParams();
  const formRefFrete = useRef<FormHandles>(null);
  const router = useHistory();

  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [printOptions, setPrintOptions] = useState<{
    printOs: boolean;
    emitNfe: boolean;
    emitNfse: boolean;
  }>({ printOs: false, emitNfe: false, emitNfse: false });
  const [pay, setPay] = useState<{ value: string; rest: number }>({
    value: '',
    rest: 0,
  });

  const { orderService, paymentConditions, setData, clearData } =
    useOrderService();
  const { data: transporters } = useLoadTransporters({
    pagination: {
      page: 1,
      perPage: 100,
      status: ['A'],
    },
  });

  const handleClearData = () => {
    clearData();
    onRequestClose();
  };

  const handleSubmit = async () => {
    setLoad(true);

    const os: Partial<IOs> = {
      osHeader: {
        id: orderService.isEdit ? params.id : v4(),
        nat_ope_id: orderService.osHeader.nat_ope_id as string,
        customer_id: orderService.osHeader.customer_id as string,
        description: orderService.osHeader.description as string,
        kind_atendimento: orderService.osHeader.kind_atendimento as string,
        date_open: orderService.osHeader.date_open as Date,
        date_prevision: orderService.osHeader.date_prevision as Date,
        date_close: orderService.osHeader.osStatus?.is_last
          ? hoje
          : (orderService.osHeader.date_close as Date),
        contract_id: orderService.osHeader.contract_id as string,
        technical_id: orderService.osHeader.technical_id as string,
        commissioned_id: orderService.osHeader.commissioned_id as string,
        priority: orderService.osHeader.priority as string,
        os_status_id: orderService.osHeader.os_status_id as string,
        total_objects: Number(orderService.osHeader.total_objects),
        total_services: Number(orderService.osHeader.total_services),
        total_products: Number(orderService.osHeader.total_products),
        total_desc: Number(orderService.osHeader.total_desc),
        perc_desc: Number(orderService.osHeader.perc_desc),
        total_adic: Number(orderService.osHeader.total_adic),
        perc_adic: Number(orderService.osHeader.perc_adic),
        juros: Number(orderService.osHeader.juros),
        total: Number(orderService.osHeader.total),
        obs_public: orderService.osHeader.obs_public as string,
        obs_private: orderService.osHeader.obs_private as string,
      },
      osObjects: orderService.osObjects.map(i => {
        return {
          object_id: i.object_id as string,
          qnt: i.qnt as number,
          obs: i.obs as string,
          identifier: i.identifier as string,
        };
      }),
      osServices: orderService.osServices.map(i => {
        return {
          service_id: i.service_id as string,
          technical_id: i.technical_id || null,
          vlr_unit: i.vlr_unit as number,
          qnt: i.qnt as number,
          tt_unit: i.tt_unit as number,
          desc: i.desc as number,
          tt_desc: i.tt_desc as number,
          adic: i.adic as number,
          tt_adic: i.tt_adic as number,
          total: i.total as number,
          obs: i.obs as string,
        };
      }),
      osProducts: orderService.osProducts.map(i => {
        return {
          product_id: i.product_id as string,
          table_price_id: i.table_price_id as string,
          vlr_unit: i.vlr_unit as number,
          qnt: i.qnt as number,
          desc: i.desc as number,
          tt_desc: i.tt_desc as number,
          adic: i.adic as number,
          tt_adic: i.tt_adic as number,
          total: i.total as number,
          obs: i.obs as string,
        };
      }),
      osPayments: orderService.osPayments.map(p => {
        return {
          payment_id: p.payment_id,
          group_id: p.group_id,
          value_doc: p.value_doc,
          parcel: p.parcel,
          value_parcel: p.value_parcel,
          due_date: p.due_date,
        };
      }),
      osTransporter:
        orderService.osTransporter !== null
          ? {
              tracking_code: orderService.osTransporter.tracking_code,
              post_date: orderService.osTransporter.post_date,
              delivery_date: orderService.osTransporter.delivery_date,
              transporter_id: orderService.osTransporter.transporter_id,
              vehicle_plate: orderService.osTransporter.vehicle_plate,
              driver: orderService.osTransporter.driver,
              kind_freight: orderService.osTransporter.kind_freight,
              quantity: orderService.osTransporter.quantity,
              kind: orderService.osTransporter.kind,
              brand: orderService.osTransporter.brand,
              seal_number: orderService.osTransporter.seal_number,
              weight_brute: orderService.osTransporter.weight_brute,
              weight_liquid: orderService.osTransporter.weight_liquid,
              cubage: orderService.osTransporter.cubage,
              cost: orderService.osTransporter.cost,
              obs: orderService.osTransporter.obs,
            }
          : emptyOsTransporter,
    };

    if (orderService.isEdit === false) {
      await api
        .post('/os/open', { ...os })
        .then(async ({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Ordem de serviço aberta com sucesso!');

            if (printOptions.printOs === true) {
              await api.post(`/os/report/${data.openOs}`).then(({ data }) => {
                openReports(
                  `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
                  'ordem-servico',
                );
              });
            }

            handleClearData();
            onRequestClose();
            router.push('/os/list');
          }
        })
        .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
        .finally(() => setLoad(false));
    }

    if (orderService.isEdit === true) {
      await api
        .put('/os/edit', { ...os })
        .then(async ({ data }) => {
          if (data) {
            toast(
              'success',
              'Sucesso',
              'Ordem de serviço editada com sucesso!',
            );

            if (printOptions.printOs === true) {
              await api
                .post(`/os/report/${orderService.osHeader.id}`)
                .then(({ data }) => {
                  openReports(
                    `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
                    'ordem-servico',
                  );
                });
            }

            handleClearData();
            onRequestClose();
            router.push('/os/list');
          }
        })
        .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
        .finally(() => setLoad(false));
    }
  };

  return (
    <SideBar
      visible={isOpen}
      onHide={() => onRequestClose()}
      position="right"
      style={{ width: '50vw' }}
    >
      <h1>Checkout da O.S</h1>

      <div className="p-fluid grid formgrid text-bold">
        <div className="field col-6">
          <div className="flex align-items-center justify-content-between  my-1 py-1">
            <div className="">Cliente</div>
            <div className="">
              {orderService.osHeader.customer?.name || '[sem cliente]'}
            </div>
          </div>
          <div className="flex align-items-center justify-content-between  my-1 py-1">
            <div className="">Status</div>
            <div className="">
              {orderService.osHeader.osStatus
                ? orderService.osHeader.osStatus?.title
                : '[sem status]'}
            </div>
          </div>
          <div className="flex align-items-center justify-content-between  my-1 py-1">
            <div className="">Num. OS</div>
            <div className="">{orderService.osHeader.os_number || '[000]'}</div>
          </div>
          <div className="flex align-items-center justify-content-between  my-1 py-1">
            <div className="">Previsão de entrega</div>
            <div className="">
              {format(
                orderService.osHeader.date_prevision || hoje,
                'dd/MM/yy H:m',
              ) || '[sem previsão]'}
            </div>
          </div>
          <div className="flex align-items-center justify-content-between  my-1 py-1">
            <div className="">Total</div>
            <div className="">
              {formatCurrency(orderService.osHeader.total || 0)}
            </div>
          </div>
        </div>

        <div className="field col-6">
          <div className="flex justify-content-between align-items-center py-2">
            <span>Imprimir OS</span>
            <InputSwitch
              name="print_os"
              checked={printOptions.printOs}
              onChange={e =>
                setPrintOptions({ ...printOptions, printOs: e.value })
              }
              className="ml-3"
            />
          </div>
          <div className="flex justify-content-between align-items-center py-2">
            <span>Gerar Nfe</span>
            <InputSwitch
              name="emite_nfe"
              checked={printOptions.emitNfe}
              onChange={e =>
                setPrintOptions({ ...printOptions, emitNfe: e.value })
              }
              className="ml-3"
            />
          </div>
          <div className="flex justify-content-between align-items-center py-2">
            <span>Gerar Nfse</span>
            <InputSwitch
              name="emite_nfse"
              checked={printOptions.emitNfse}
              onChange={e =>
                setPrintOptions({ ...printOptions, emitNfse: e.value })
              }
              className="ml-3"
            />
          </div>
        </div>
      </div>

      <TabView>
        <TabPanel header="Pagamentos">
          <InputRowPaymentsOS
            show={{
              obs: true,
            }}
            isOpen={isOpen}
          />
        </TabPanel>
        <TabPanel header="Frete">
          <Form
            ref={formRefFrete}
            onSubmit={() => null}
            className="p-fluid grid formgrid"
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="field col-12 md:col-6">
              <label htmlFor="transporter_id">Transportadora</label>
              <InputDropDown
                name="transporter_id"
                options={transporters
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(i => {
                    return { value: i.id, label: `${i.name} - ${i.cpf_cnpj}` };
                  })}
                value={orderService.osTransporter?.transporter_id}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      transporter_id: e.value,
                    },
                  })
                }
                placeholder="Selecionar..."
                filter
                emptyFilterMessage="Nenhum item encontrado..."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="kind_freight">Tipo Frete</label>
              <InputDropDown
                name="kind_freight"
                options={freteOpts}
                value={orderService.osTransporter?.kind_freight}
                placeholder="Selecionar..."
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      kind_freight: e.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Veículo</label>
              <InText
                name="vehicle_plate"
                placeholder="Ex.: AAA-1234"
                value={orderService.osTransporter?.vehicle_plate || ''}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      vehicle_plate: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Motorista</label>
              <InText
                name="driver"
                placeholder="Ex.: João do Frete"
                value={orderService.osTransporter?.driver || ''}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      driver: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="delivery_date">Data entrega</label>
              <Calendar
                name="delivery_date"
                value={orderService.osTransporter?.delivery_date || hoje}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      delivery_date: e.value as Date,
                    },
                  })
                }
                dateFormat="d/m/yy"
                placeholder="00/00/00"
                showIcon
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="kind">Espécie</label>
              <InputText
                name="kind"
                placeholder="Ex.: VOL, PC, KIT..."
                value={orderService.osTransporter?.kind || ''}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      kind: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="quantity">Quantidade</label>
              <InputNumber
                name="quantity"
                value={orderService.osTransporter?.quantity}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      quantity: Number(e.value),
                    },
                  })
                }
                placeholder="0,00"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="brand">Marca</label>
              <InputText
                name="brand"
                placeholder="Ex.: Própia/Terceiros"
                value={orderService.osTransporter?.brand || ''}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      brand: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="seal_number">Número Lacre</label>
              <InputText
                name="seal_number"
                value={orderService.osTransporter?.seal_number || ''}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      seal_number: e.currentTarget.value,
                    },
                  })
                }
                placeholder="Ex.: 0000"
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Peso Bruto (KG)</label>
              <InputNumber
                name="weight_brute"
                value={orderService.osTransporter?.weight_brute}
                placeholder="0,00"
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      weight_brute: Number(e.value),
                    },
                  })
                }
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Peso Líquido (kG)</label>
              <InputNumber
                name="weight_liquid"
                value={orderService.osTransporter?.weight_liquid}
                placeholder="0,00"
                onFocus={e => e.target.select()}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      weight_liquid: Number(e.value),
                    },
                  })
                }
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-3">
              <label htmlFor="tracking_code">Código de rastreio</label>
              <InputText
                name="tracking_code"
                value={orderService.osTransporter?.tracking_code || ''}
                placeholder="Ex.: BR000000000EU"
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      tracking_code: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-3">
              <label htmlFor="post_date">Data da postagem</label>
              <Calendar
                name="post_date"
                value={orderService.osTransporter?.post_date || hoje}
                placeholder="00/00/00"
                dateFormat="d/m/yy"
                showIcon
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      post_date: e.value as Date,
                    },
                  })
                }
              />
            </div>

            <div className="field col-3">
              <label htmlFor="cubage">Cubagem (m3)</label>
              <InputNumber
                name="cubage"
                value={orderService.osTransporter?.cubage}
                placeholder="0,00"
                suffix=" m3"
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      cubage: Number(e.value),
                    },
                  })
                }
              />
            </div>

            <div className="field col-3">
              <label htmlFor="cost">Custo frete</label>
              <InputNumber
                name="cost"
                value={orderService.osTransporter?.cost}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      cost: Number(e.value),
                    },
                  })
                }
                placeholder="0,00"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Obs</label>
              <InputTextArea
                name="obs"
                value={orderService.osTransporter?.obs || undefined}
                onChange={e =>
                  setData({
                    ...orderService,
                    osTransporter: {
                      ...orderService.osTransporter,
                      obs: e.currentTarget.value,
                    },
                  })
                }
                placeholder="Ex: Entregar o(s) items na vendinha da esquina..."
                rows={5}
                cols={30}
              />
            </div>
          </Form>
        </TabPanel>
      </TabView>

      <div className="p-fluid grid formgrid">
        <Divider />
        <div className="field col-12 md:col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="fa-solid fa-xmark"
            className="p-button-danger"
            loading={load}
            onClick={() => handleClearData()}
          />
        </div>

        <div className="field col-12 md:col-6">
          <Button
            label={
              orderService.osHeader.osStatus?.is_last ? 'Faturar' : 'Salvar'
            }
            icon="fa-solid fa-hand-holding-dollar"
            className="p-button-success"
            type="submit"
            loading={load}
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
    </SideBar>
  );
};

export default OsCheckout;
