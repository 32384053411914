export default function formatPhoneNumbers(phoneString: string): {
  fixed: string;
  mobile: string;
} {
  // Expressões regulares para capturar telefones fixos e celulares
  const phoneRegex = /\(\d{2}\)\s?\d{4,5}-\d{4}/g;
  const cellRegex = /\(\d{2}\)\s?9\d{4}-\d{4}/;

  // Encontrar todos os telefones na string de entrada
  const phones = phoneString.match(phoneRegex) || [];

  // Variáveis para armazenar o telefone fixo e celular
  let fixed = '';
  let mobile = '';

  // Distribuir os telefones
  phones.forEach(phone => {
    if (cellRegex.test(phone)) {
      mobile = phone; // Número celular
    } else {
      fixed = phone; // Número fixo
    }
  });

  return { fixed, mobile };
}
