import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** type */
import { BankModel } from '../../../types/banks';

/** services */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** empty bank */
const emptyBank = {
  id: '',
  codigo: '',
  nome: '',
  url: '',
  emite_bol: false,
  status: '',
};

const BankList = (): React.ReactElement => {
  /** state */
  const [banks, setBanks] = useState<BankModel[]>([]);
  const [bank, setBank] = useState<BankModel>(emptyBank);
  const [globalFilter, setGlobalFilter] = useState('');
  const [deleteBankDialog, setDeleteBankDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  /** dialog  */

  const hideDeleteBankDialog = () => {
    setDeleteBankDialog(false);
  };

  const confirmDeleteBank = (bank: BankModel) => {
    setBank(bank);
    setDeleteBankDialog(true);
  };

  const deleteBankRequest = async () => {
    try {
      await api.delete(`/bancos/${bank.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteBank = () => {
    const _banks = banks.filter(val => val.id !== bank.id);
    setBanks(_banks);
    setDeleteBankDialog(false);
    setBank(emptyBank);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Aplicação Deletada',
      life: 3000,
    });
    deleteBankRequest();
  };

  const deleteBankDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteBankDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteBank}
      />
    </React.Fragment>
  );

  const codeBodyTemplate = (rowData: BankModel, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const ratingBodyTemplate = (rowData: BankModel) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: BankModel) => {
    return (
      <div className="flex justify-content-end">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/banks/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteBank(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Bancos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchBanks = async () => {
    try {
      const { data } = await api.get('bancos');
      setBanks(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchBanks();
  }, []);

  return (
    <>
      <Button
        label="Novo Banco"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/banks/create')}
      />
      <Toast ref={toast} />

      <div className="card">
        <DataTable
          value={banks}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          rowsPerPageOptions={[10, 20, 30]}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} bancos"
          globalFilter={globalFilter}
          emptyMessage="Não existe bancos."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="id"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>

          <Column field="codigo" header="Código"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column
            field="status"
            header="Status"
            body={ratingBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteBankDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteBankDialogFooter}
        onHide={hideDeleteBankDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {bank && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{bank?.nome}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default BankList;
