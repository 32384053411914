/* eslint-disable react/react-in-jsx-scope */
import { FormHandles } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import IOptionsDTO, { emptyOption } from '../../../business/dtos/IOptionsDTO';
import api from '../../../../services/api';
import { IRouteList } from '../../route/dtos/IRouteDTO';
import formatCurrency from '../../../../utils/FormatCurrency';
import { format } from 'date-fns';
import useToastContext from '../../../../hooks/toast';
import messageRequestError from '../../../../utils/messageRequestError';
import ConsignedTopMenuReport from '../top-menu-report';
import Progress from '../../../../components/progress-bar';
import { Form } from '@unform/web';
import { SelectButton } from 'primereact/selectbutton';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { Divider } from '../../../../components/Divider';
import { Button } from 'primereact/button';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { IProductPriceSearch } from '../../../product/dtos/IProductDTO';
import { DataTable } from '../../../../components/DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import openReports from '../../../../utils/openReports';

const ReportRouteStock = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const inputRefProduct = useRef<HTMLInputElement>(null);

  const toast = useToastContext();
  const [load, setLoad] = useState<boolean>(false);

  const [routesOpts, setRoutesOpts] = useState<IOptionsDTO[]>([]);
  const [routes, setRoutes] = useState<string[]>([]);

  const [productsOpts, setProductsOpts] = useState<IOptionsDTO[]>([]);
  const [products, setProducts] = useState<IOptionsDTO[]>([]);
  const [product, setProduct] = useState<IOptionsDTO>(emptyOption);

  const filterTypeOpts: IOptionsDTO[] = [
    { label: 'Rotas', value: 'ROTA' },
    { label: 'Produtos', value: 'PROD' },
  ];
  const [filterType, setFilterType] = useState<string>(filterTypeOpts[0].value);

  useEffect(() => {
    loadRoutes();
  }, []);

  useEffect(() => {
    if (filterType === filterTypeOpts[0].value) {
      setProducts([]);
    } else {
      setRoutes([]);
    }
  }, [filterType]);

  const loadRoutes = async () => {
    setLoad(true);
    await api
      .post('/consigned/route/list', {
        data: { page: 1, perPage: 128, block: false, status: 'A' },
      })
      .then(({ data }) => {
        if (data) {
          setRoutesOpts(
            data.map((i: IRouteList) => {
              return {
                value: i.id,
                label: `${i.sequence} - ${i.customer.name} - ${format(
                  new Date(i.date_open),
                  'dd/MM/yy',
                )} - ${formatCurrency(i.total)}`,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const searchProduct = async (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      await api
        .post(`/products/v2/prices/search`, {
          data: {
            keyword: event.query,
            show: { page: 1, perPage: 1000, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProductsOpts(
              data.map((i: IProductPriceSearch) => {
                return { label: i.title, value: i.id };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const itemTemplateProduct = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{`${i.label}`}</div>
      </div>
    );
  };

  const handleProductSelected = (e: AutoCompleteSelectParams) => {
    const productsSelected = products;
    productsSelected.push(e.value);

    setProducts([...productsSelected]);
    setProduct(emptyOption);
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemProduct(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  const handleRemProduct = (id: string) => {
    const noId = products.filter(i => i.value !== id);
    setProducts(noId);
  };

  const handleSubmit = async () => {
    if (filterType === filterTypeOpts[0].value && routes.length === 0) {
      toast(
        'warn',
        'Alerta',
        'Informe pelo menos uma rota para gerar o relatório!',
      );
    } else if (
      filterType === filterTypeOpts[1].value &&
      products.length === 0
    ) {
      toast(
        'warn',
        'Alerta',
        'Informe pelo menos um produto para gerar o relatório!',
      );
    } else {
      setLoad(true);
      await api
        .post('/reports/consigned/route-stock', {
          data: {
            filter_type: filterType,
            routes,
            products: products.map(p => p.value),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Relatório gerado com sucesso!');
            openReports(
              `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
              'consignado-estoque-em-rota',
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  return (
    <>
      <ConsignedTopMenuReport />
      <Progress isLoad={load} className="w-6" />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="card w-6"
        placeholder={''}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
        <div className="p-fluid grid formgroup">
          <div className="field col-12">
            <label htmlFor="filterType"> Filtrar por</label>
            <SelectButton
              value={filterType}
              onChange={e => setFilterType(e.value)}
              options={filterTypeOpts}
            />
          </div>
          <div className="field col-12">
            {filterType === filterTypeOpts[0].value && (
              <>
                <label htmlFor="route">Rotas {`[${routes.length}]`}</label>
                <MultiSelect
                  name="route"
                  value={routes}
                  options={routesOpts || []}
                  placeholder="Selecionar..."
                  onChange={e => setRoutes(e.value)}
                  filter
                  display="chip"
                />
              </>
            )}
            {filterType === filterTypeOpts[1].value && (
              <>
                <label htmlFor="product">
                  Produtos {`[${products.length}]`}
                </label>

                <InputAutoComplete
                  inputRef={inputRefProduct}
                  name="product_search"
                  value={product}
                  field="label"
                  completeMethod={e => searchProduct(e)}
                  suggestions={productsOpts}
                  onSelect={e => handleProductSelected(e)}
                  onChange={e => setProduct(e.value)}
                  itemTemplate={e => itemTemplateProduct(e)}
                  placeholder={'Buscar produto...'}
                />

                <Divider />

                <div className="p-fluid grid formgroup">
                  <DataTable
                    value={products}
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 20, 30]}
                    size="small"
                    emptyMessage="Nenhum produto adicionado..."
                    selectionMode="single"
                    loading={load}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} produtos."
                    className="field col-12"
                  >
                    <Column header="#" body={renderColumnPosition}></Column>
                    <Column header="Produto" field="label"></Column>
                    <Column
                      header="*"
                      body={e => renderCollumnMenu(e.value)}
                      align={'center'}
                    ></Column>
                  </DataTable>
                </div>
              </>
            )}
          </div>
          <Divider />

          <div className="field col-6 mb-0 pb-0">
            <Button
              label="Cancelar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={load}
            />
          </div>

          <div className="field col-6 mb-0 pb-0">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ReportRouteStock;
