import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../../../components/DataTableColumns/RenderColumnShortName';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputText } from '../../../components/Inputs/InputText';
import MenuPopUp from '../../../components/MenuPopPup';
import {
  Dialog as ModalInfoCash,
  Dialog as ModalInfoSaller,
} from '../../../components/Modal';
import { AppContext } from '../../../context/AppContext';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import openReports from '../../../utils/openReports';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IOrderListShortDTO from '../dtos/IOrderListShortDTO';
import OrderHeaderButtons from '../header-buttons';
import registradoOpts from '../types/registradoOpts';
import situacaoOpts from '../types/situacaoOpts';
import tiposOpts from '../types/tiposOpts';

const orderList = (): React.ReactElement => {
  /** hooks */
  const navigate = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const { state } = useContext(AppContext);

  /** const */
  const hoje = new Date();

  /** states */
  const [load, setLoad] = useState<boolean>(false);

  const [busca, setBusca] = useState<string>('');
  const [filtroGlobal, setFiltroGlobal] = useState<string>('');

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const [mostraFiltros, setMostraFiltros] = useState<boolean>(false);

  const [situacao, setSituacao] = useState<string>('');

  const [vendedor, setVendedor] = useState<string>('');
  const [vendedorOpts, setVendedorOpts] = useState<IOptionsDTO[]>([]);

  const [tipo, setTipo] = useState<string>('');
  const [registrado, setRegistrado] = useState<string>('');
  const [numPedido, setNumPedido] = useState<number>();

  const [pedidoSelecionado, setPedidoSelecionado] =
    useState<IOrderListShortDTO>({
      pedido_id: '',
      pedido: 0,
      primeira_compra: false,
      loja_id: '',
      loja_nome: '',
      cliente_id: '',
      cliente_nome: '',
      cliente_cpf_cnpj: '',
      comissionado_id: '',
      comissionado_nome: '',
      nat_operacao_id: '',
      nat_operacao: '',
      pedido_data: hoje,
      pedido_tipo: '',
      pedido_registrado: '',
      valor_pedido: 0,
      valor_total: 0,
      valor_produtos: 0,
      pedido_status: '',
      order_origin: '',
      user_name: '',
    });
  const [pedidos, setPedidos] = useState<IOrderListShortDTO[]>([]);

  const [tableFilter, setTableFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pedido_tipo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    pedido_status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const [modalRegisterOrderVisible, setModalRegisterOrderVisible] =
    useState<boolean>(false);
  const [modalInfoVendedorVisible, setModalInfoVendedorVisible] =
    useState<boolean>(false);
  const [modalInfoCaixaVisible, setModalInfoCaixaVisible] =
    useState<boolean>(false);

  const [caixaInfo, setCaixaInfo] = useState<{
    cash_entradas: number;
    cash_number: number;
    cash_qnt_ped_s_registro: number;
    cash_saidas: number;
    cash_saldo: number;
  }>({
    cash_entradas: 0,
    cash_number: 0,
    cash_qnt_ped_s_registro: 0,
    cash_saidas: 0,
    cash_saldo: 0,
  });

  const [comissionadoInfo, setComissionadoInfo] = useState<{
    fault_meta: number;
    meta_daily: number;
    meta_month: number;
    proposals: number;
    qnt_orders: number;
    sold_month: number;
    sold_today: number;
  }>({
    fault_meta: 0,
    meta_daily: 0,
    meta_month: 0,
    proposals: 0,
    qnt_orders: 0,
    sold_month: 0,
    sold_today: 0,
  });

  const [sumAtivos, setSumAtivos] = useState<number>(0);
  const [sumRegistrados, setSumRegistrados] = useState<number>(0);
  const [sumCancelados, setSumCancelados] = useState<number>(0);
  const [sumPedidos, setSumPedidos] = useState<number>(0);
  const [sumPropostas, setSumPropostas] = useState<number>(0);

  /** functions */
  const handleFilterOrders = async () => {
    let ped: IOrderListShortDTO[] = pedidos;

    if (situacao !== '') {
      const aux = pedidos.filter(i => i.pedido_status === situacao);
      ped.push(...aux);
    }

    if (vendedor !== '') {
      const aux = ped.filter(i => i.comissionado_id === vendedor);
      ped = [];
      ped.push(...aux);
    }

    if (tipo !== '') {
      const aux = ped.filter(i => i.pedido_tipo === tipo);
      ped = [];
      ped.push(...aux);
    }

    if (registrado !== '') {
      const aux = ped.filter(i => i.pedido_registrado === registrado);
      ped = [];
      ped.push(...aux);
    }

    if (numPedido) {
      const aux = ped.filter(i => i.pedido === numPedido);
      ped = [];
      ped.push(...aux);
    }

    if (ped.length === 0) {
      toast(
        'warn',
        'Alerta',
        'Nenhum registro encontrado com os parâmetros informados!',
      );
      setPedidos([]);
      return;
    }

    setPedidos(ped);
  };

  const handleClearForm = () => {
    setBusca('');
    setDateStart(startOfMonth(hoje));
    setDateEnd(endOfMonth(hoje));
    setSituacao('');
    setVendedor('');
    setTipo('');
    setRegistrado('');
    setNumPedido(undefined);
  };

  const printOrder = async (orderId: string) => {
    setLoad(true);

    await api
      .post('/reports/v2/order', {
        id: orderId,
        promissory: 0,
      })
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
            'pedido',
          );
        }
      })
      .catch((err: any) =>
        toast(
          'error',
          'Erro',
          `Falha ao abrir relatório, tente novamente! [${messageRequestError(
            err,
          )}]`,
        ),
      )
      .finally(() => setLoad(false));
  };

  const printProposal = async (orderId: string) => {
    try {
      setLoad(true);

      const { data } = await api.get(`/reports/proposal/${orderId}`);

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
      );
    } catch (error: any) {
      toast('error', 'Error!', error?.response?.data?.error, 5000);
    } finally {
      setLoad(false);
    }
  };

  const openWhatsappUrl = async (orderId: String) => {
    try {
      setLoad(true);
      const { data } = await api.get(`/order/whatsapp/url/${orderId}`);
      window.open(data.whatsappUrl);
    } catch (error: any) {
      toast('error', 'Error!', error?.response?.data?.error, 5000);
    } finally {
      setLoad(false);
    }
  };

  const printCupom = async (orderId: string) => {
    try {
      setLoad(true);

      await api.get(`/reports/coupom/${orderId}`);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${state.user.id.replace(
          /-/g,
          '',
        )}.pdf`,
      );
      toast('success', 'Sucesso!', 'Cupom gerado!');
    } catch (error: any) {
      toast('warn', 'Erro!', error?.response?.data?.error, 10000);
    } finally {
      setLoad(false);
    }
  };

  const emitNfe = async (orderId: string) => {
    setLoad(true);

    await api
      .post(`/nfe/emit/${orderId}`)
      .then(({ data }) => {
        if (data) {
          toast(
            'info',
            'Envio',
            `NF-e enviada para processamento na SEFAZ!\n
            Aguarde 10s e atualize o status da NF-e para ter o retorno!`,
            8000,
          );
          navigate.push('/nfe/export/list');
        }
      })
      .catch((err: any) => {
        if (
          err.response.data?.error?.message &&
          err.response.data?.error?.message.includes('vencido')
        ) {
          toast(
            'warn',
            'Alerta',
            `Falha ao emitir o documento fiscal. ${err.response.data?.error?.message}`,
            10000,
          );
        } else {
          toast(
            'warn',
            'Alerta',
            `Falha ao emitir o documento fiscal. ${messageRequestError(err)}`,
            10000,
          );
        }
      })
      .finally(() => setLoad(false));
  };

  const emitNfce = async (orderId: string) => {
    try {
      setLoad(true);

      await api.post(`/nfce/emit/${orderId}`);

      toast('success', 'Sucesso!', 'NFC-e gerado com sucesso!');

      navigate.push('/nfce/export/list');
    } catch (error: any) {
      toast('warn', 'Erro!', error?.response?.data?.error, 10000);
    } finally {
      setLoad(false);
    }
  };

  const cloneOrder = async (id: string) => {
    setLoad(true);
    await api
      .get(`/order/clone/${id}`)
      .then(data => {
        if (data.status >= 200 || data.status <= 299) {
          toast('success', 'Sucesso!', 'Pedido clonado como uma nova proposta');
          searchOrders();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  const cancelOrder = async (orderId: string) => {
    try {
      setLoad(true);

      await api.delete(`/order/cancel/${orderId}`);

      searchOrders();
      toast('success', 'Sucesso!', 'Pedido cancelado!');
    } catch (error: any) {
      toast('warn', 'Error!', error?.response?.data?.error, 5000);
    } finally {
      setLoad(false);
    }
  };

  const registerOrder = async (orderId: string) => {
    try {
      setLoad(true);

      await api.post('/register-cash-movimentations/r/order', {
        order_id: orderId,
      });

      searchOrders();
      setModalRegisterOrderVisible(false);

      toast('success', 'Sucesso!', 'Pedido Registrado!');
    } catch (error: any) {
      setModalRegisterOrderVisible(false);
      toast('warn', 'Erro!', error?.response?.data?.error, 10000);
    } finally {
      setLoad(false);
    }
  };

  const cancelRegisterOrder = async (orderId: string) => {
    try {
      setLoad(true);

      await api.delete(`/register-cash-movimentations/r/order/`, {
        data: {
          order_id: orderId,
        },
      });

      toast('success', 'Sucesso!', 'Registro Cancelado!');
      searchOrders();
    } catch (error: any) {
      toast('warn', 'Error!', 'Problema ao cancelar pedido!', 5000);
    } finally {
      setLoad(false);
    }
  };

  const searchOrders = async () => {
    try {
      setLoad(true);
      const { data } = await api.get(`/order-header/short/list`, {
        params: {
          search: busca,
          dateStart: dateStart,
          dateEnd: dateEnd,
        },
      });

      setPedidos(data);
    } catch (e: any) {
      toast('warn', 'Alerta', `Erro na busca de pedidos: [${e}]`, 5000);
    } finally {
      setLoad(false);
    }
  };

  const onGlobalFilterChange = (value: any) => {
    const filters = { ...tableFilter };
    filters['global'].value = value;

    setTableFilter(filters);
  };

  useEffect(() => {
    const summary = pedidos.reduce(
      (acc, item) => {
        if (item.pedido_status === 'A') acc.ativos++;
        if (item.pedido_status === 'C') acc.cancelados++;
        if (item.pedido_registrado === 'S') acc.registrados++;
        if (item.pedido_tipo === 'PED') acc.qntPedidos++;
        if (item.pedido_tipo === 'PED' && item.pedido_status === 'A')
          acc.sumPedidos += item.valor_total;
        if (item.pedido_tipo === 'PRO') acc.qntPropostas++;
        if (item.pedido_tipo === 'PRO' && item.pedido_status === 'A')
          acc.sumPropostas += item.valor_total;
        return acc;
      },
      {
        ativos: 0,
        registrados: 0,
        cancelados: 0,
        qntPedidos: 0,
        sumPedidos: 0,
        qntPropostas: 0,
        sumPropostas: 0,
      },
    );

    setSumAtivos(summary.ativos);
    setSumRegistrados(summary.registrados);
    setSumCancelados(summary.cancelados);
  }, [pedidos]);

  /** render */
  const tableHeader = () => {
    const value = tableFilter['global'] ? tableFilter['global'].value : '';
    return (
      <Form
        ref={null}
        onSubmit={() => null}
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Ativos</h5>
              <span className={`product-badge status-instock `}>
                {sumAtivos}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Registrados</h5>
              <span className={`customer-badge status-new `}>
                {sumRegistrados}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Cancelados</h5>
              <span className={`product-badge status-outofstock`}>
                {sumCancelados}
              </span>
            </div>
            <Button
              label="Info. vendedor"
              icon="pi pi-external-link"
              onClick={() => setModalInfoVendedorVisible(true)}
            />
            <Button
              label="Info. caixa"
              className="md:ml-3 sm:ml-0"
              icon="pi pi-external-link"
              onClick={() => setModalInfoCaixaVisible(true)}
            />
          </div>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              name="search"
              type="search"
              value={value || ''}
              onChange={e => onGlobalFilterChange(e.currentTarget.value)}
              placeholder="Buscar..."
            />
          </span>
        </div>
      </Form>
    );
  };

  const renderColumnDate = (rowData: any) => {
    return <span>{format(new Date(rowData.pedido_data), 'dd/MM/yy')}</span>;
  };

  const renderColumnDecimal = (rowData: any) => {
    return (
      <span>
        {new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(rowData.valor_total)}
      </span>
    );
  };

  const renderCollumnCustomerNameAndKindOrder = (rowData: any) => {
    return (
      <>
        <span>{rowData.cliente_nome}</span>
        {rowData.pedido_tipo === 'PED' ? (
          <span
            className="customer-badge ml-3"
            style={{
              color: 'var(--green-800)',
              background: 'var(--green-200)',
            }}
          >
            PED
          </span>
        ) : (
          <span
            className="customer-badge ml-3"
            style={{
              color: 'var(--orange-800)',
              background: 'var(--orange-200)',
            }}
          >
            PRO
          </span>
        )}
      </>
    );
  };

  const renderCollumnStatus = (rowData: any) => {
    return (
      <>
        {rowData.pedido_status === 'A' && (
          <span className={`product-badge status-instock mx-1`}>A</span>
        )}

        {rowData.pedido_status === 'C' && (
          <span className={`product-badge status-outofstock mx-1`}>C</span>
        )}

        {rowData.pedido_registrado === 'S' && (
          <span className={`customer-badge status-new mx-1`}>R</span>
        )}
      </>
    );
  };

  const renderCollumnOrigin = (rowData: any) => {
    switch (rowData.order_origin) {
      case 'PDV':
        return (
          <i
            className="fa-solid fa-cash-register"
            style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
          ></i>
        );
      case 'STR':
        return (
          <i
            className="fa-solid fa-cart-shopping"
            style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
          ></i>
        );
      case 'TER':
        return (
          <i
            className="fa-solid fa-file-lines"
            style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
          ></i>
        );
    }
  };

  const mountMenu = (rowData: IOrderListShortDTO) => {
    const items: any[] = [];

    if (
      pedidoSelecionado.pedido_tipo === 'PRO' &&
      pedidoSelecionado.pedido_status === 'A'
    ) {
      items.push({
        label: 'Editar',
        icon: 'fa-solid fa-file',
        command: () => navigate.push(`/orders/create/${rowData.pedido_id}`),
      });
    }

    if (pedidoSelecionado.order_origin === 'PDV') {
      items.push({
        label: 'Gerar Nfce',
        icon: 'fa-solid fa-file-pdf',
        command: () => emitNfce(rowData.pedido_id),
      });
    }

    if (pedidoSelecionado.order_origin === 'TER') {
      items.push({
        label: 'Gerar Nfe',
        icon: 'fa-solid fa-file-pdf',
        command: () => emitNfe(rowData.pedido_id),
      });

      items.push({
        label: 'Gerar Nfce',
        icon: 'fa-solid fa-file-pdf',
        command: () => emitNfce(rowData.pedido_id),
      });
    }

    items.push({
      label: 'Clonar ped/pro',
      icon: 'fa-solid fa-clone',
      command: () => cloneOrder(rowData.pedido_id),
    });

    if (
      pedidoSelecionado.pedido_tipo === 'PED' &&
      pedidoSelecionado.pedido_status !== 'C' &&
      pedidoSelecionado.pedido_status !== 'E' &&
      pedidoSelecionado.order_origin !== 'PDV'
    ) {
      items.push({
        label: 'Imprimir pedido',
        icon: 'fa-solid fa-print',
        command: () => printOrder(rowData.pedido_id),
      });
    }

    if (
      pedidoSelecionado.pedido_tipo === 'PRO' &&
      pedidoSelecionado.pedido_status !== 'C' &&
      pedidoSelecionado.pedido_status !== 'E' &&
      pedidoSelecionado.order_origin !== 'PDV'
    ) {
      items.push({
        label: 'Imprimir proposta',
        icon: 'fa-solid fa-print',
        command: () => printProposal(rowData.pedido_id),
      });
    }

    if (pedidoSelecionado.order_origin === 'PDV') {
      items.push({
        label: 'Imprimir cupom',
        icon: 'pi pi-print',
        command: () => {
          printCupom(rowData.pedido_id);
        },
      });
    }

    if (
      pedidoSelecionado.pedido_registrado === 'N' &&
      pedidoSelecionado.pedido_status !== 'C' &&
      pedidoSelecionado.pedido_tipo !== 'PRO'
    ) {
      items.push({
        label: 'Registrar pedido',
        icon: 'fa-solid fa-file',
        command: () => registerOrder(rowData.pedido_id),
      });
    }

    if (pedidoSelecionado.pedido_registrado === 'S') {
      items.push({
        label: 'Cancelar registro pedido',
        icon: 'fa-solid fa-xmark',
        command: () => cancelRegisterOrder(rowData.pedido_id),
      });
    }

    if (pedidoSelecionado.pedido_status !== 'C') {
      items.push({
        label: 'Cancelar Pedido',
        icon: 'pi pi-trash',
        command: () => {
          cancelOrder(rowData.pedido_id);
        },
      });
    }

    items.push({
      label: 'Whatsapp',
      icon: 'fa-brands fa-whatsapp',
      command: () => {
        openWhatsappUrl(rowData.pedido_id);
      },
    });

    return items;
  };

  const renderCollumnMenu = (rowData: any) => {
    const mountOpts = mountMenu(rowData);

    return (
      <>
        <MenuPopUp
          model={mountOpts}
          rowData={rowData}
          setData={setPedidoSelecionado}
        />
      </>
    );
  };

  /** useEffect */
  useEffect(() => {
    api.get(`/commissioneds`).then(({ data }) => {
      const opts = data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      if (opts.length > 0) {
        setVendedorOpts([{ label: 'Todos', value: '' }, ...opts]);
      }
    });

    api
      .get('/meta-commissioned/li/info')
      .then(({ data }) => {
        setComissionadoInfo(data);
      })
      .catch(e => {
        setComissionadoInfo({
          fault_meta: 0,
          meta_daily: 0,
          meta_month: 0,
          proposals: 0,
          qnt_orders: 0,
          sold_month: 0,
          sold_today: 0,
        });
      });

    api
      .get('/register-cash/info')
      .then(({ data }) => {
        setCaixaInfo(data);
      })
      .catch(e => {
        setCaixaInfo({
          cash_entradas: 0,
          cash_number: 0,
          cash_qnt_ped_s_registro: 0,
          cash_saidas: 0,
          cash_saldo: 0,
        });
      });
  }, []);

  useEffect(() => {
    searchOrders();
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        <OrderHeaderButtons />
        <div className="card mb-2 pb-2">
          <Form
            ref={formRef}
            onSubmit={() => null}
            placeholder={''}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-5">
                <label htmlFor="search">Busca inteligente</label>
                <InputText
                  name="search"
                  type="search"
                  value={busca}
                  onChange={e => setBusca(e.currentTarget.value)}
                  placeholder="Ex: João da silva..."
                  min={3}
                  max={32}
                  autoComplete="off"
                />
              </div>

              <div className="field col-12 md:col-2">
                <label htmlFor="dateStart">Período inicial</label>
                <Calendar
                  name="dateStart"
                  value={dateStart}
                  onChange={e => setDateStart(e.value as Date)}
                  dateFormat="dd/mm/yy"
                  placeholder="00/00/00"
                  showIcon
                />
              </div>

              <div className="field col-12 md:col-2">
                <label htmlFor="dateEnd">Período final</label>
                <Calendar
                  name="dateEnd"
                  value={dateEnd}
                  onChange={e => setDateEnd(e.value as Date)}
                  dateFormat="dd/mm/yy"
                  placeholder="00/00/00"
                  showIcon
                />
              </div>

              <div className="field col-12 md:col-3 flex align-items-end">
                <Button
                  label="Buscar"
                  icon="pi pi-filter"
                  className="p-button p-button-success"
                  onClick={e => searchOrders()}
                />
              </div>

              {!mostraFiltros && (
                <div className="field col-12">
                  <i
                    className="fa-solid fa-arrow-down mr-3"
                    style={{ color: 'var(--primary-color)' }}
                  ></i>
                  <a
                    href="#"
                    className="cursor-pointer font-bold"
                    style={{ color: 'var(--primary-color)' }}
                    onClick={e => {
                      e.preventDefault();
                      setMostraFiltros(true);
                    }}
                  >
                    Exibir mais filtros
                  </a>
                </div>
              )}

              {mostraFiltros && (
                <>
                  <div className="field col-12 md:col-4">
                    <label htmlFor="situacao">Situação</label>
                    <Dropdown
                      value={situacao}
                      options={situacaoOpts}
                      onChange={e => {
                        setSituacao(e.value);
                      }}
                      placeholder="Selecionar..."
                      optionLabel="label"
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <label htmlFor="vendedor">Vendedor</label>
                    <Dropdown
                      value={vendedor}
                      options={vendedorOpts}
                      onChange={e => {
                        setVendedor(e.value);
                      }}
                      placeholder="Selecionar..."
                      optionLabel="label"
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <label htmlFor="tipo">Tipo</label>
                    <Dropdown
                      value={tipo}
                      options={tiposOpts}
                      onChange={e => {
                        setTipo(e.value);
                      }}
                      placeholder="Selecionar..."
                      optionLabel="label"
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <label htmlFor="registrado">Registrado</label>
                    <Dropdown
                      value={registrado}
                      options={registradoOpts}
                      onChange={e => {
                        setRegistrado(e.value);
                      }}
                      placeholder="Selecionar..."
                      optionLabel="label"
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <label htmlFor="numPedido">Número ped/pro</label>
                    <InputText
                      name="numPedido"
                      value={numPedido}
                      onChange={e =>
                        setNumPedido(Number(e.currentTarget.value))
                      }
                      placeholder="Ex: 1"
                    />
                  </div>

                  <div className="field col-12 md:col-2 flex align-items-end">
                    <Button
                      label="Limpar"
                      icon="fa-solid fa-xmark"
                      className="p-button-raised p-button-danger"
                      onClick={() => handleClearForm()}
                    />
                  </div>
                  <div className="field col-12 md:col-2 flex align-items-end">
                    <Button
                      label="Aplicar filtros"
                      icon="pi pi-filter"
                      className="p-button-raised p-button-info"
                      onClick={() => handleFilterOrders()}
                    />
                  </div>

                  {mostraFiltros && (
                    <div className="field col-12 md:col-3 mb-0 pb-0">
                      <i
                        className="fa-solid fa-arrow-up mr-3"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <a
                        href="#"
                        className="cursor-pointer font-bold"
                        style={{ color: 'var(--primary-color)' }}
                        onClick={e => {
                          e.preventDefault();
                          setMostraFiltros(false);
                        }}
                      >
                        Esconder filtros
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </Form>
        </div>
        <div className="card">
          <DataTable
            dataKey="id"
            header={tableHeader}
            value={pedidos}
            selection={pedidoSelecionado}
            onSelectionChange={e => setPedidoSelecionado(e.value)}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            loading={load}
            loadingIcon="pi pi-spinner text-primary"
            filters={tableFilter}
            onFilter={e => setTableFilter(e.tableFilter)}
            globalFilter={filtroGlobal}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} pedidos"
            emptyMessage="Não existem pedidos..."
            responsiveLayout="scroll"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="N." field="pedido" sortable></Column>
            <Column
              header="Data"
              field="pedido_data"
              body={e => renderColumnDate(e)}
            ></Column>
            <Column
              header="Cliente"
              field="cliente_nome"
              sortable
              body={e => renderCollumnCustomerNameAndKindOrder(e)}
            ></Column>
            <Column
              header="Total"
              field="valor_total"
              sortable
              body={e => renderColumnDecimal(e)}
            ></Column>
            <Column
              header="Status"
              field="pedido_status"
              body={e => renderCollumnStatus(e)}
            ></Column>
            <Column
              header="Origem"
              field="order_origin"
              sortable
              body={e => renderCollumnOrigin(e)}
              align={'center'}
            ></Column>
            <Column
              header="Usuário"
              body={e => renderColumnShortName(e.user_name)}
            ></Column>
            <Column header="*" body={e => renderCollumnMenu(e)}></Column>
          </DataTable>
        </div>
      </div>
      <ModalInfoSaller
        header="Informações do vendedor"
        visible={modalInfoVendedorVisible}
        onHide={() => setModalInfoVendedorVisible(false)}
      >
        <div className="surface-section">
          <div className="text-700 mb-5">
            Fique atento com suas metas de vendas e ações a serem executadas
            para cumprimento dos objetivos do mês.
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">Meta do mês</div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {comissionadoInfo.meta_month
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(comissionadoInfo.meta_month)
                  : `R$ 0,00`}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">Vendido mês</div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {comissionadoInfo.sold_month
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(comissionadoInfo.sold_month)
                  : `R$ 0,00`}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Vendido hoje
              </div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {comissionadoInfo.sold_today
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(comissionadoInfo.sold_today)
                  : `R$ 0,00`}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Propostas em aberto
              </div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {comissionadoInfo.proposals
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(comissionadoInfo.proposals)
                  : `R$ 0,00`}
              </div>
            </li>

            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Falta para bater meta
              </div>
              <div
                className="w-6 md:w-6 md:flex-order-0 flex-order-1"
                style={{
                  color: `${
                    comissionadoInfo.fault_meta > 0
                      ? 'var(--green-500)'
                      : 'var(--pink-500)'
                  }`,
                  fontWeight: 900,
                }}
              >
                {comissionadoInfo.fault_meta
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(comissionadoInfo.fault_meta)
                  : `R$ 0,00`}
              </div>
            </li>

            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Pedidos fechados
              </div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {comissionadoInfo.qnt_orders
                  ? Number(comissionadoInfo.qnt_orders)
                  : 0}{' '}
                <span>qnt</span>
              </div>
            </li>
          </ul>
        </div>
      </ModalInfoSaller>

      <ModalInfoCash
        header="Informações do caixa"
        visible={modalInfoCaixaVisible}
        onHide={() => setModalInfoCaixaVisible(false)}
      >
        <div className="surface-section">
          <div className="text-700 mb-5">
            Fique atento com seus dados de faturamento para fechamento de caixa
            e realização de movimentações.
          </div>
          <ul className="list-none p-0 m-0">
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Caixa registrador
              </div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {`${Number(caixaInfo.cash_number)} - [ABERTO]` || 0}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Seus pedidos sem registro
              </div>
              <div className="text-900 w-6 md:w-6 md:flex-order-0 flex-order-1">
                {`${Number(caixaInfo.cash_qnt_ped_s_registro)} qnt` || 0}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Entradas no caixa
              </div>
              <div
                className="w-6 md:w-6 md:flex-order-0 flex-order-1"
                style={{ color: 'var(--blue-600)' }}
              >
                {caixaInfo.cash_entradas
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(caixaInfo.cash_entradas)
                  : 'R$ 0,00'}
              </div>
            </li>
            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div className="text-700 w-6 md:w-6 font-medium">
                Saídas no caixa
              </div>
              <div
                className="w-6 md:w-6 md:flex-order-0 flex-order-1"
                style={{ color: 'var(--pink-600)' }}
              >
                {caixaInfo.cash_saidas
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(caixaInfo.cash_saidas)
                  : `R$ 0,00`}
              </div>
            </li>

            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
              <div
                className="text-900 w-6 md:w-6 font-medium"
                style={{ fontWeight: 900 }}
              >
                Saldo do caixa
              </div>
              <div
                className="w-6 md:w-6 md:flex-order-0 flex-order-1"
                style={{ fontWeight: 900, color: 'var(--green-600)' }}
              >
                {caixaInfo.cash_saldo
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(caixaInfo.cash_saldo)
                  : `R$ 0,00`}
              </div>
            </li>
          </ul>
        </div>
      </ModalInfoCash>
    </div>
  );
};
const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(orderList, comparisonFn);
