/* eslint-disable react/react-in-jsx-scope */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import { endOfDay, endOfMonth, startOfMonth, startOfYear } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { useEffect, useRef, useState } from 'react';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputChecked } from '../../../../components/Inputs/InputChecked';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputText } from '../../../../components/Inputs/InputText';
import MenuPopUp from '../../../../components/MenuPopPup';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import { convertStringToDate } from '../../../../utils/date/ConvertStringToDate';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import UfsOpts from '../../../person/types/UfsOpts';
import CrmMenuTop from '../../crm/top-menu';
import DialogSendCrmData from '../dialog-send-crm-data';
import { IEstabelecimento } from '../dtos/IEstabelecimentoDTO';
import SidebarRfbData from '../sidebar-data';
import { EnumStatus } from '../../../../enum/EnumStatus';
import Progress from '../../../../components/progress-bar';

const RfbConsultaAvancada = () => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string | null>(null);
  const [cnaes, setCnaes] = useState<IOptionsDTO[]>([]);
  const [cnaeCodigo, setCnaeCodigo] = useState<string[]>([]);
  const [cnaeWithSecondary, setCnaeWithSecondary] = useState<boolean | null>(
    null,
  );
  const [natJuridics, setNatJuridics] = useState<IOptionsDTO[]>([]);
  const [natJuridic, setNatJuridic] = useState<string[]>([]);
  const [situations, setSituations] = useState<IOptionsDTO[]>([
    { value: '01', label: 'Nula' },
    { value: '02', label: 'Ativa' },
    { value: '03', label: 'Suspensa' },
    { value: '04', label: 'Inapta' },
    { value: '08', label: 'Baixada' },
  ]);
  const [situation, setSituation] = useState<string | null>('02');
  const [ufs, setUfs] = useState<IOptionsDTO[]>(UfsOpts);
  const [uf, setUf] = useState<string[]>([]);
  const [citiesOpts, setCitiesOpts] = useState<IOptionsDTO[]>([]);
  const [citiesSelected, setCitiesSelected] = useState<string[]>([]);
  const [dateOpening, setDateOpening] = useState<Date[] | undefined>([
    startOfYear(hoje),
    endOfDay(hoje),
  ]);
  const [capital, setCapital] = useState<number[]>([0, 1000000.0]);
  const [isMei, setisMei] = useState<boolean | null>(null);
  const [isMatriz, setIsMatriz] = useState<boolean | null>(null);
  const [isFilial, setIsFilial] = useState<boolean | null>(null);
  const [hasMobile, setHasMobile] = useState<boolean | null>(null);
  const [hasPhone, setHasPhone] = useState<boolean | null>(null);
  const [hasEmail, setHasEmail] = useState<boolean | null>(null);

  const [load, setLoad] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [sidebarDataVisible, setSidebarDataVisible] = useState(false);

  const [dialogSendCrmDataVisible, setDialogSendCrmDataVisible] =
    useState(false);

  const [users, setUsers] = useState<IOptionsDTO[]>([]);
  const [user, setUser] = useState<IOptionsDTO>({} as IOptionsDTO);

  const [panels, setPanels] = useState<IOptionsDTO[]>([]);
  const [panel, setPanel] = useState<IOptionsDTO>({} as IOptionsDTO);

  const [business, setBusiness] = useState<IEstabelecimento[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<
    IEstabelecimento[]
  >([]);

  const [companiesAlreadyServed, setCompaniesAlreadyServed] = useState<
    { rfb_id: string; user_id: string }[]
  >([]);

  const loadCnaes = async () => {
    setLoad(true);
    await api
      .post('/rfb/cnae/all')
      .then(({ data }) => {
        if (data) {
          setCnaes(
            data.map((i: any) => {
              return {
                value: i.codigo,
                label: `${i.codigo} - ${i.descricao}`,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  const loadUsers = async () => {
    setLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.name,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadCompaniesAlreadyServed = async () => {
    setLoad(true);
    await api
      .post('/crm/card/list-rfb', {
        dataShow: {
          page: 1,
          perPage: 1000,
          status: EnumStatus.ATIVO,
        },
      })
      .then(({ data }) => {
        if (data) {
          setCompaniesAlreadyServed(data);
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadCnaeSuggestions = async () => {
    setLoad(true);
    await api
      .get('/rfb/cnae-suggestions')
      .then(({ data }) => {
        if (data !== null) setCnaeCodigo(data);
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  const loadNatJu = async () => {
    setLoad(true);
    await api
      .post('/rfb/natju/all')
      .then(({ data }) => {
        if (data) {
          setNatJuridics(
            data.map((i: any) => {
              return {
                label: i.descricao,
                value: i.codigo,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  const loadCitiesOpts = async (uf: string) => {
    setLoad(true);
    await api
      .get(`/cities/by/uf/${uf}`)
      .then(({ data }) => {
        if (data) {
          const newCities: IOptionsDTO[] = citiesOpts;

          data.forEach((i: any) => {
            newCities.push({
              value: i.siafi,
              label: `${i.name} - ${i.uf.sigla}`,
            });
          }),
            setCitiesOpts(newCities);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  const handleSubmit = async (d: any) => {
    if (uf.length === 0) {
      toast('warn', 'Alerta', 'Informe pelo menos um estado na sua busca!');
      return;
    }

    setLoad(true);
    await api
      .post('/rfb/estabelecimento/search', {
        data: {
          nome: name || null,
          cnae: {
            codigo: cnaeCodigo || [],
            com_secundario: cnaeWithSecondary || null,
          },
          nat_juridica: natJuridic || [],
          situacao: situation || null,
          endereco: {
            uf: uf,
            cidade: citiesSelected || [],
          },
          abertura: {
            start: dateOpening
              ? new Date(dateOpening[0]).toISOString()
              : startOfMonth(hoje),
            end: dateOpening
              ? new Date(dateOpening[1]).toISOString()
              : endOfMonth(hoje),
          },
          capital: {
            start: capital[0],
            end: capital[1],
          },
          filter: {
            is_mei: isMei || null,
            is_matriz: isMatriz || null,
            is_filial: isFilial || null,
            has_mobile: hasMobile || null,
            has_phone: hasPhone || null,
            has_email: hasEmail || null,
          },
          show: {
            page: 1,
            per_page: 100,
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          const businesses = data.map((i: any) => {
            return {
              id: i.cnpj,
              cnpj: cnpj.format(`${i.cnpj}`),
              razao_social: i.empresa.razao_social
                ? i.empresa.razao_social.length > 32
                  ? `${i.empresa.razao_social.slice(0, 32)}...`
                  : i.empresa.razao_social
                : '*****',
              nome_fantasia: i.nome_fantasia
                ? i.nome_fantasia.length > 32
                  ? `${i.nome_fantasia}...`
                  : i.nome_fantasia
                : '*****',
              cnae: i.cnae_fiscal_principal,
              uf: i.uf,
              city: i.cidade.descricao || 'sem cidade',
              open_at: i.data_inicio_atividade,
            };
          });

          const businessWithoutDuplicity: IEstabelecimento[] = [];

          for (const business of businesses) {
            if (!businessWithoutDuplicity.some(b => b.cnpj === business.cnpj))
              businessWithoutDuplicity.push(business);
          }

          setBusiness(businessWithoutDuplicity);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleOpenSidebarData = (id: string) => {
    setId(id);
    setSidebarDataVisible(true);
  };

  const handleOpenDialogSendCrmData = () => {
    setDialogSendCrmDataVisible(true);
  };

  const mountMenu = (row: any) => {
    const items: any[] = [];

    items.push({
      label: 'Ver dados',
      icon: 'fa-solid fa-search',
      command: () => handleOpenSidebarData(row.id),
    });

    return items;
  };

  const renderCollumnMenu = (row: any) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={() => {}} />
      </>
    );
  };

  useEffect(() => {
    loadNatJu();
    loadUsers();
    loadCnaes();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <CrmMenuTop />
      <Progress isLoad={load} />
      <div className="card mb-2">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="field col-12 md:col-5 mb-1">
            <label htmlFor="nome">Nome fantasia / Razão social</label>
            <InputText
              name="nome"
              value={name || ''}
              onChange={e => setName(e.currentTarget.value)}
              placeholder="Ex.: Empresa Acme Ltda"
            />
          </div>
          <div className="field col-12 md:col-3 mb-1">
            <label htmlFor="cnae.codigo">
              Cnae {cnaeCodigo.length > 0 ? `[${cnaeCodigo.length}]` : ''}
              <InputChecked
                name="cnae.com_secundario"
                checked={cnaeWithSecondary}
                onChange={e => setCnaeWithSecondary(e.checked)}
                className="mx-2"
                tooltip="Buscar também o cnae informado nos secundários do cadastro da empresa?"
                tooltipOptions={{ position: 'bottom' }}
              />
              <small>secundário também?</small>
            </label>
            <MultiSelect
              name="cnae.codigo"
              options={cnaes}
              value={cnaeCodigo}
              onChange={e => setCnaeCodigo(e.value)}
              placeholder="Selecione..."
              filter
              tooltip="Você pode informar mais de 1 tipo de cnae para ajudar a encontrar a empresa."
              tooltipOptions={{ position: 'bottom' }}
              display="chip"
            />
          </div>
          <div className="field col-6 md:col-2 mb-1">
            <label htmlFor="nat_juridica">
              Nat. jurídica{' '}
              {natJuridic.length > 0 ? `[${natJuridic.length}]` : ''}
            </label>
            <MultiSelect
              name="nat_juridica"
              options={natJuridics}
              value={natJuridic}
              onChange={e => setNatJuridic(e.value)}
              placeholder="Selecione..."
              filter
              display="chip"
            />
          </div>
          <div className="field col-6 md:col-2 mb-1">
            <label htmlFor="situacao">Situação</label>
            <InputDropDown
              name="situaao"
              options={situations}
              value={situation}
              onChange={e => setSituation(e.value)}
              placeholder="Selecione..."
            />
          </div>

          <div className="field col-12 md:col-6 mb-1">
            <label htmlFor="abertura">Abertura entre</label>
            <CalendarRange
              name="abertura"
              value={dateOpening}
              onChange={e => setDateOpening(e.value as Date[])}
              placeholder="00/00/00 ~ 00/00/00"
              tooltip="Selecione as datas de início e de término."
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-4 md:col-2 mb-1">
            <label htmlFor="uf">
              Uf {uf.length > 0 ? `[${uf.length}]` : ''}
            </label>
            <MultiSelect
              name="uf"
              value={uf}
              onChange={async e => {
                setUf(e.value);
                const _uf = ufs.find(
                  (i: IOptionsDTO) => i.value === e.value[e.value.length - 1],
                );
                if (_uf) {
                  await loadCitiesOpts(_uf.value);
                }
              }}
              options={ufs}
              placeholder="Selecione..."
              display="chip"
              filter
            />
          </div>
          <div className="field col-8 md:col-4 mb-1">
            <label htmlFor="cidade">
              Cidade{' '}
              {citiesSelected.length > 0 ? `[${citiesSelected.length}]` : ''}
            </label>
            <MultiSelect
              name="cidade"
              options={citiesOpts}
              value={citiesSelected}
              onChange={e => setCitiesSelected(e.value)}
              placeholder="Selecione..."
              display="chip"
              filter
              disabled={uf.length === 0}
            />
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="is_mei"
              checked={isMei}
              onChange={e => setisMei(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas MEI?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>MEI</span>
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="is_matriz"
              checked={isMatriz}
              onChange={e => setIsMatriz(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas matriz?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>Matriz</span>
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="is_filial"
              checked={isFilial}
              onChange={e => setIsFilial(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas filiais?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>Filial</span>
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="has_mobile"
              checked={hasMobile}
              onChange={e => setHasMobile(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas que tem celular/whatsapp?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>Celular</span>
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="has_phone"
              checked={hasPhone}
              onChange={e => setHasPhone(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas que tem telefone fixo?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>Fixo</span>
          </div>

          <div className="field col-2 md:col-1 flex align-items-center my-3">
            <InputChecked
              name="has_email"
              checked={hasEmail}
              onChange={e => setHasEmail(e.checked)}
              className="mr-2"
              tooltip="Mostrar somente empresas que tem email?"
              tooltipOptions={{ position: 'bottom' }}
            />
            <span>Email</span>
          </div>

          <div className="field col-6 md:col-2 mb-1">
            <label htmlFor="capital.start">Capital inicial</label>
            <InputNumber
              name="capital.start"
              value={capital[0]}
              onChange={e => setCapital([Number(e.value), capital[1]])}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
            />
          </div>

          <div className="field col-6 md:col-2 mb-1">
            <label htmlFor="capital.final">Capital final</label>
            <InputNumber
              name="capital.final"
              value={capital[1]}
              onChange={e => setCapital([capital[0], Number(e.value)])}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end mt-2 mb-1">
            <Button
              label="Buscar"
              icon="fa-solid fa-search"
              type="submit"
              loading={load}
              disabled={uf.length === 0}
            />
          </div>
        </Form>
      </div>

      {showMessage && (
        <Message
          severity="warn"
          text="Nós não cobramos pelos dados abaixo listados pois são públicos, apensa cobramos pelo armazenamento e processamento dos dados."
          className="w-full mb-2"
        />
      )}

      {selectedCompanies && selectedCompanies.length > 0 && (
        <div className="field col-4 ">
          <Button
            label="Enviar CRM"
            icon="fa-solid fa-hand-point-right"
            type="button"
            loading={load}
            onClick={() => handleOpenDialogSendCrmData()}
          />
        </div>
      )}

      <DataTable
        value={business}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Empresas não encontradas..."
        selectionMode="multiple"
        selection={selectedCompanies}
        onRowClick={e => {
          const companyAlreadyServed = companiesAlreadyServed.find(
            c => c.rfb_id === e.data.id,
          );

          if (companyAlreadyServed) {
            toast(
              'warn',
              'Alerta',
              `Esta empresa já está sendo atendida por ${
                users.find(u => u.value === companyAlreadyServed?.user_id)
                  ?.label
              }`,
            );
          } else {
            let newSelection = selectedCompanies;

            if (newSelection.find(c => c.id === e.data.id)) {
              newSelection = newSelection.filter(c => c.id !== e.data.id);
            } else {
              newSelection.push(e.data as IEstabelecimento);
            }

            setSelectedCompanies([...newSelection]);
          }
        }}
        metaKeySelection={false}
        dataKey="id"
        dragSelection
        loading={load}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={`Exibindo {first} / {last} de {totalRecords} empresas.`}
        className="card w-full mt-2 text-sm"
      >
        <Column header="#" body={renderColumnPosition}></Column>
        <Column header="CNPJ" field="cnpj" className="text-xs"></Column>
        <Column header="Razão Social" field="razao_social"></Column>
        <Column header="Nome Fantasia" field="nome_fantasia"></Column>
        <Column
          header="CNAE"
          field="cnae"
          className="text-xs"
          sortable
        ></Column>
        <Column header="UF" field="uf" className="text-xs" sortable></Column>
        <Column
          header="Cidade"
          field="city"
          className="text-xs"
          sortable
        ></Column>
        <Column header="Abertura" field="open_at" sortable></Column>
        <Column
          header="*"
          body={e => renderCollumnMenu(e)}
          align={'center'}
        ></Column>
      </DataTable>

      <SidebarRfbData
        id={id}
        isOpen={sidebarDataVisible}
        onRequestClose={() => setSidebarDataVisible(false)}
      />

      <DialogSendCrmData
        companies={selectedCompanies}
        isOpen={dialogSendCrmDataVisible}
        onRequestClose={() => {
          setSelectedCompanies([]);
          setDialogSendCrmDataVisible(false);
        }}
      />
    </>
  );
};

export default RfbConsultaAvancada;
