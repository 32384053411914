import IOptionsDTO from '../../business/dtos/IOptionsDTO';

export default [
  {
    label: 'Masculino',
    value: 'M',
  },
  { label: 'Feminino', value: 'F' },
  { label: 'Outros', value: 'O' },
] as IOptionsDTO[];
