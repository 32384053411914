import React, { useRef, useEffect, useState } from 'react';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import getValidationErrors from '../../../utils/getErrorsValidation';
import api from '../../../services/api';

const NcmEdit = (): React.ReactElement => {
  const navigate = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  // params
  const params: { id: string } = useParams();

  /** useRef */
  const toast = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: { codigo: string; descricao: string }) => {
    try {
      setLoadingStatus(true);

      const schemaNcm = Yup.object({
        codigo: Yup.string()
          .required('Por favor, informe o código!')
          .max(7, 'No máximo 7 números em código!'),
        descricao: Yup.string().required('Por favor, informe a descrição!'),
      });

      await schemaNcm.validate(data, { abortEarly: false });

      const res = await api.put(`/ncm/${params.id}`, data);

      navigate.push('/ncm/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    api.get(`/ncm/${params.id}`).then(({ data }) => {
      formRef.current?.setData({
        codigo: data.codigo,
        descricao: data.descricao,
      });
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-check"
        onClick={() => navigate.push('/ncm/list')}
      />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Código</label>
              <InputText name="codigo" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea name="descricao" />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default NcmEdit;
