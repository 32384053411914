import React, { useRef, useEffect } from 'react';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** services */
import api from '../../../services/api';

const PrintedNfeEdit = (): React.ReactElement => {
  const navigate = useHistory();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** params */
  const params: { id: string } = useParams();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleAddPrintedNfe = async (data: {
    title: string;
    description: string;
  }) => {
    try {
      const schemaPrintedNfe = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
        description: Yup.string().required('Por favor, informe a descrição!'),
      });

      await schemaPrintedNfe.validate(data, { abortEarly: false });
      await api.put(`/impressos-nfe/${params.id}`, data);
      navigate.push('/impresso-nfe/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get(`/impressos-nfe/${params.id}`).then(({ data }) => {
      formRef.current?.setData({
        title: data.title,
        description: data.description,
      });
    });
  }, []);

  return (
    <>
      <>
        <Button
          label="Listar"
          icon="fa-solid fa-list"
          className="mb-3"
          onClick={() => navigate.push('/impresso-nfe/list')}
        />
        <Toast ref={toast}></Toast>

        <Form ref={formRef} onSubmit={handleAddPrintedNfe}>
          <div className="card md:w-6 sm:w-full">
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="basic">Título</label>
                <InputText placeholder="Seu título..." name="title" />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="basic">Descrição </label>

                <InputTextArea name="description" />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
              <div className="field col-12 md:col-5">
                <Button
                  label="Cancelar"
                  className="p-button-raised p-button-danger"
                />
              </div>
              <div className="field col-12 md:col-5">
                <Button label="Salvar" />
              </div>
            </div>
          </div>
        </Form>
      </>
    </>
  );
};

export default PrintedNfeEdit;
