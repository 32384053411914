export default [
  {
    label: 'Sem frete',
    value: 'OUT',
  },
  {
    label: 'Frete por conta do remetente',
    value: 'CIF',
  },

  {
    label: 'Frete por conta do destinatário',
    value: 'FOB',
  },
];
