import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OrderDevolutionHeader = (): React.ReactElement => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Devoluções"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/order-replace/list')}
      />

      <Button
        label="Devolução"
        className="mb-2 mr-2"
        icon="fa-solid fa-refresh"
        onClick={() => navigate.push('/order-replace')}
      />

      <Button
        label="Pedidos"
        className="mb-2 mr-2"
        icon="fa-solid fa-file-invoice-dollar"
        onClick={() => navigate.push('/orders/list')}
      />
    </>
  );
};

export default OrderDevolutionHeader;
