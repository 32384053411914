import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import RegisterCashHeader from '../../register-cash/header';

const CashMovimentationOutPut: React.FC = (): React.ReactElement => {
  /** const */
  /** hooks */
  const navigate = useHistory();
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [value, setValue] = useState<number | null>();
  const [isBleed, setIsBleed] = useState<boolean>(false);

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);

      const schemaCreateCash = Yup.object({
        doc_ser: Yup.string()
          .max(3, 'O número de caracteres máximo são 3')
          .required('Por favor, informe série!'),
        doc_num: Yup.string().required(
          'Por favor, informe número do documento!, ',
        ),
        value: Yup.string().required('Por favor, informe o valor!'),
        obs: Yup.string(),
      });

      await schemaCreateCash.validate(data, { abortEarly: false });
      await api.post('/register-cash-movimentations/c/output', {
        ...data,
        value,
        bleeding: isBleed,
      });

      navigate.push('/orders/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <RegisterCashHeader />
      <div className="card w-6">
        <Form ref={form} onSubmit={d => handleSubmit(d)}>
          <div className="p-fluid grid formgrid">
            <div className="field col-8 md:col-8"></div>
            <div className="field col-4 md:col-4 flex align-items-center">
              <InputSwitch
                name="is_bleed"
                checked={isBleed}
                onChange={e => setIsBleed(e.value)}
              />
              <label htmlFor="dropdown" className="ml-3">
                É sangria?
              </label>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Doc. Série</label>
              <InputText
                name="doc_ser"
                placeholder={isBleed === true ? 'SAN' : '000'}
                value={isBleed === true ? 'SAN' : undefined}
                disabled={isBleed}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Doc. Número</label>
              <InputText keyfilter="int" name="doc_num" placeholder="000" />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Valor</label>
              <InputNumber
                name="value"
                value={value}
                placeholder="R$ 0,00"
                onValueChange={e => setValue(e.value)}
                mode="currency"
                currency="BRL"
                minFractionDigits={2}
                maxFractionDigits={5}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Obs</label>
              <InputTextArea name="obs" placeholder={'Digite aqui...'} />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CashMovimentationOutPut;
