import { endOfMonth, startOfMonth } from 'date-fns';
import { EnumPriority } from '../../../../enum/EnumPriority';

export interface IFilter {
  keyword: string;
  period: Date[];
  kind_atendimento: string;
  os_number: number | null;
  priority: string[];
  status_os: string[];
  status: string;
}

const hoje = new Date();

export const emptyFilter: IFilter = {
  keyword: '',
  period: [startOfMonth(hoje), endOfMonth(hoje)],
  kind_atendimento: 'INT',
  os_number: 0,
  priority: [EnumPriority.MEDIA],
  status_os: [],
  status: 'A',
};
