import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import { Column } from 'primereact/column';
import { emptyNfceResume, INfceResume } from '../types/INfceResume';
import api from '../../../services/api';
import useToastContext from '../../../hooks/toast';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnSitutacao from '../../../components/DataTableColumns/RenderColumnSitutacao';
import { EnumNfeStatus } from '../../../enum/EnumNfeStatus';
import MenuPopUp from '../../../components/MenuPopPup';
import axios from 'axios';

interface IProps {
  customer_id: string;
}

const ContentNfce = ({ customer_id }: IProps): React.ReactElement => {
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [doc, setDoc] = useState<INfceResume>(emptyNfceResume);
  const [docs, setDocs] = useState<INfceResume[]>([]);

  const loadDocs = async (id: string) => {
    setIsLoad(true);
    await api
      .post('/nfce/customer/resume', {
        data: { id },
        pagination: { page: 1, perPage: 1000, status: ['A', 'C'] },
      })
      .then(res => {
        if (res) {
          setDocs(res.data);
        }
      })
      .catch(e => {
        toast(
          'warn',
          'Alerta',
          `Falha ao carregar as Nfce: ${e.message}`,
          8000,
        );
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const downloadPdf = async (url: string) => {
    const headers = { 'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316' };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'webger-nfce-pdf-';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfce!', 10000);
      });
  };

  const downloadXml = async (url: string) => {
    const headers = { 'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316' };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'webger-nfce-xml-';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfce!', 10000);
      });
  };

  const mountMenu = (row: INfceResume) => {
    const items: any[] = [];

    if (row.status === EnumNfeStatus.CONC) {
      items.push(
        {
          label: 'Baixar Nfce',
          icon: 'pi pi-fw pi-file-pdf',
          command: () => downloadPdf(row.url_pdf),
        },
        {
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(row.url_xml),
        },
      );
    }
    return items;
  };

  const renderRowMenu = (row: INfceResume) => {
    const model = mountMenu(row);
    if (model.length > 0) {
      return (
        <>
          <MenuPopUp model={model} rowData={row} setData={doc} />
        </>
      );
    }
  };

  useEffect(() => {
    loadDocs(customer_id);
  }, [customer_id]);

  return (
    <>
      <DataTable
        value={docs}
        paginator
        rows={10}
        loading={isLoad}
        loadingIcon="pi pi-spinner text-primary"
        rowsPerPageOptions={[10, 20, 30]}
        className="datatable-responsive m-0 p-0"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} adicionais"
        emptyMessage="Sem Nfce."
        responsiveLayout="scroll"
        size="small"
      >
        <Column field="id" header="#" body={renderColumnPosition}></Column>
        <Column field="order" header="Pedido"></Column>
        <Column
          field="customer_name"
          header="Cliente"
          headerStyle={{ width: '20%' }}
        ></Column>
        <Column
          field="emissao"
          header="Emissão"
          body={r => renderColumnDate(r.emissao)}
        ></Column>
        <Column field="serie" header="Serie" align={'center'}></Column>
        <Column field="numero" header="Número" align={'center'}></Column>
        <Column
          field="valor"
          header="Total"
          body={r => renderColumnDecimal(r.valor)}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={r => renderColumnSitutacao(r.status)}
        ></Column>
        <Column header="*" body={r => renderRowMenu(r)}></Column>
      </DataTable>
    </>
  );
};

export default ContentNfce;
