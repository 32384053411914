import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import VariationItemsHeader from '../header';
import IVariationItem from './dtos/IVariationItem';

const VariationItemsList = (): React.ReactElement => {
  /** const */

  /** hooks */
  const toast = useToastContext();
  const navigate = useHistory();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const [variationItems, setVariationItems] = useState<Array<IVariationItem>>(
    [],
  );

  /** functions */
  const listVariationItems = async () => {
    setLoadingStatus(true);
    await api
      .get('/variations-itens')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            id: i.id,
            image_url: i.image_url,
            variation_title: i.variation.title,
            variation_item_title: i.title,
            value: Number(i.value),
            status: i.status,
          };
        });

        setVariationItems(opts);
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          'Falha ao buscar as variações de itens no sistema!',
        );
      })
      .finally(() => setLoadingStatus(false));
  };

  const deleteVariationItem = async (id: string) => {
    setLoadingStatus(true);
    await api
      .delete(`/variations-itens/${id}`)
      .then(data => {
        if (data.status >= 200 || data.status <= 299) {
          toast('success', 'Sucesso', 'Item exlcuído com sucesso!');
        }
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao excluir o item!');
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffects */
  useEffect(() => {
    listVariationItems();
  }, []);

  /** renders */
  const renderColumnImage = (row: IVariationItem) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {row.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${row.image_url}`}
            alt={row.variation_item_title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const renderColumnsButtons = (row: IVariationItem) => {
    return (
      <>
        <div className="flex justify-content-center">
          <Button
            icon="pi pi-pencil"
            className="p-button-raised mr-4"
            type="button"
            onClick={() => navigate.push(`/variations-items/edit/${row.id}`)}
          />
          <Button
            icon="pi pi-trash"
            className="p-button-raised p-button-danger"
            type="button"
            onClick={async () => {
              await deleteVariationItem(row.id);
              await listVariationItems();
            }}
          />
        </div>
      </>
    );
  };

  const renderDatatableHeaderSearch = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Variação</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  return (
    <>
      <VariationItemsHeader />
      <div className="card">
        <DataTable
          value={variationItems}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} itens variação"
          globalFilter={globalFilter}
          emptyMessage="Não existe item variação."
          header={renderDatatableHeaderSearch}
          responsiveLayout="scroll"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column header="Foto" body={e => renderColumnImage(e)}></Column>
          <Column field="variation_title" header="Variação"></Column>
          <Column field="variation_item_title" header="Item Variação"></Column>

          <Column
            field="value"
            header="Valor"
            body={e => renderColumnDecimal(e.value)}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={e => renderColumnStatus(e)}
            align={'center'}
          ></Column>
          <Column
            header="*"
            align={'center'}
            body={e => renderColumnsButtons(e)}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default VariationItemsList;
