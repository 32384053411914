import React, { useRef, useEffect } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { Toast } from 'primereact/toast';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

const MainActivityEdit = (): React.ReactElement => {
  const navigate = useHistory();

  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const params: { id: string } = useParams();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleMainActivity = async (data: any) => {
    try {
      const schemaMainActivity = Yup.object({
        title: Yup.string().required('Por favor, informe o título'),
        description: Yup.string().required('Por favor, informe a descrição'),
      });
      await schemaMainActivity.validate(data, { abortEarly: false });

      await api.put(`/main-activity/${params.id}`, data);
      navigate.push('/main-activity/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get(`/main-activity/${params.id}`).then(({ data }) => {
      formRef.current?.setData({
        title: data.title,
        description: data.description,
      });
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/main-activity/list')}
      />
      <div className="card w-6">
        <Toast ref={toast} />

        <Form ref={formRef} onSubmit={handleMainActivity}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Título</label>
              <InputText name="title" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea name="description" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default MainActivityEdit;
