export default [
  {
    label: 'Frete por conta da empresa',
    value: 'CIF',
  },

  {
    label: 'Frete por conta do cliente',
    value: 'FOB',
  },

  {
    label: 'Outros',
    value: 'OUT',
  },
];
