import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';

/** services */
import api from '../../../services/api';
import { numberToCurrency } from '../../../utils/number';

/** menu options */

const MetaComissionedList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [metaComissioneds, setMetaComissioneds] = useState();

  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */

  /* navigate */
  const navigate = useHistory();

  /** table */

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const codeColumnBody = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const metaColumnBody = (rowData: any, value: string) => {
    return <span>{numberToCurrency(parseFloat(rowData[value]))}</span>;
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() =>
            navigate.push(`/meta-commissioneds/edit/${rowData.id}`)
          }
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Metas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchMetaComissioned = async () => {
    try {
      const { data } = await api.get('/meta-commissioned');
      setLoading(false);
      setMetaComissioneds(data);
    } catch (error: any) {
      showToast('error', 'Falha', error.response?.data?.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    searchMetaComissioned();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/meta-commissioneds/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={metaComissioneds}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} metas"
          globalFilter={globalFilter}
          emptyMessage="Não existe meta de comissionados."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeColumnBody}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="name" header="Comissionado"></Column>
          <Column
            header="Valor de Meta"
            body={(rowData: any) => metaColumnBody(rowData, 'meta_value')}
          ></Column>
          <Column
            field="price"
            header="Meta Vendida"
            body={(rowData: any) => metaColumnBody(rowData, 'meta_sold')}
          ></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column header="Ações" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default MetaComissionedList;
