import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { CalendarChangeParams } from 'primereact/calendar';
import { Chip } from 'primereact/chip';
import React, { useRef, useState } from 'react';
import { Divider } from '../../../../components/Divider';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import Progress from '../../../../components/progress-bar';
import useToastContext from '../../../../hooks/toast';
import useLoadCommissioneds from '../../../../hooks/useLoadCommissioneds';
import useLoadNatureOperationsShort from '../../../../hooks/useLoadNatureOperationsShort';
import useLoadOsStatus from '../../../../hooks/useLoadOsStatus';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import IOptionsDTO, { emptyOption } from '../../../business/dtos/IOptionsDTO';
import TopMenu from '../top-menu';
import { ISearch, emptySearch } from './dtos/ISearch';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const CommissionsOS = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customersList, setCustomersList] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<IOptionsDTO>(emptyOption);

  const [filter, setFilter] = useState<ISearch>(emptySearch);

  const { data: commissioneds } = useLoadCommissioneds();
  const { data: natOpes } = useLoadNatureOperationsShort({
    paginate: {
      page: 1,
      perPage: 100,
      status: ['A'],
      kind: ['saida', 'troca', 'devolucao'],
    },
  });
  const { data: osStatus } = useLoadOsStatus();

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddCustomer = (e: AutoCompleteSelectParams) => {
    if (e.value) {
      if (!filter.customers.find(i => i === e.value.value)) {
        setCustomersList([...customersList, e.value]);
        setFilter({
          ...filter,
          customers: [...filter.customers, e.value.value],
        });
        setCustomer(emptyOption);
      }
    }
  };

  const handleRemCustomer = (id: string) => {
    setCustomersList(customersList.filter(i => i.value !== id));
    setFilter({ ...filter, customers: filter.customers.filter(i => i !== id) });
    setCustomer(emptyOption);
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    await api
      .post('/reports/commissions/os', { filter: filter })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Relatório geerado com sucesso!');
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
            'comissoes-os',
          );
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  return (
    <>
      <TopMenu />
      <Progress isLoad={isLoad} />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card w-8"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12">
            <label htmlFor="customer">
              Clientes{' '}
              {filter.customers.length > 0
                ? `[${filter.customers.length}]`
                : ``}
            </label>
            <InputAutoComplete
              name="customer"
              suggestions={customers}
              value={customer}
              completeMethod={e => searchCustomer(e.query)}
              onSelect={e => handleAddCustomer(e)}
              field="label"
              onChange={e => setCustomer(e.value)}
              itemTemplate={itemTemplate}
              placeholder="Buscar cliente..."
            />
          </div>

          <div className="field col-12 flex">
            {customersList.map(i => {
              return (
                <Chip
                  key={i.value}
                  label={i.label}
                  removable
                  onRemove={e => {
                    handleRemCustomer(i.value);
                  }}
                  className="mr-1"
                />
              );
            })}
          </div>

          <div className="field col-12">
            <label htmlFor="commissioneds">
              Comissionados{' '}
              {filter.commissioneds.length > 0
                ? `[${filter.commissioneds.length}]`
                : ''}
            </label>
            <MultiSelect
              name="commissioneds"
              options={commissioneds?.map(i => {
                return {
                  value: i.id,
                  label: i.name,
                };
              })}
              value={filter.commissioneds}
              onChange={e => setFilter({ ...filter, commissioneds: e.value })}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-12">
            <label htmlFor="nat_ope">
              Naturezas de operação{' '}
              {filter.natureOperations.length > 0
                ? `[${filter.natureOperations.length}]`
                : ''}
            </label>
            <MultiSelect
              name="nat_ope"
              options={natOpes?.map(i => {
                return {
                  value: i.id,
                  label: i.title,
                };
              })}
              value={filter.natureOperations}
              onChange={e =>
                setFilter({ ...filter, natureOperations: e.value })
              }
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-6">
            <label htmlFor="period_open">Período de abertura</label>
            <CalendarRange
              name="period_open"
              showIcon
              value={filter.period_open}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setFilter({ ...filter, period_open: e.value as Date[] })
              }
            />
          </div>

          <div className="field col-6">
            <label htmlFor="period_close">Período de encerramento</label>
            <CalendarRange
              name="period_close"
              showIcon
              value={filter.period_close}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setFilter({ ...filter, period_close: e.value as Date[] })
              }
            />
          </div>

          <div className="field col-12">
            <label htmlFor="os_status">
              Status{' '}
              {filter.os_status.length > 0
                ? `[${filter.os_status.length}]`
                : ''}
            </label>
            <MultiSelect
              name="os_status"
              options={osStatus?.map(i => {
                return {
                  value: i.id,
                  label: i.title,
                };
              })}
              value={filter.os_status}
              onChange={e => setFilter({ ...filter, os_status: e.value })}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-6">
            <label htmlFor="status">Ativo/Cancelado?</label>
            <MultiSelect
              name="status"
              options={[
                { value: 'A', label: 'Ativo' },
                { value: 'C', label: 'Cancelado' },
                { value: undefined, label: 'Todos' },
              ]}
              value={filter.status}
              onChange={e => setFilter({ ...filter, status: e.value })}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-6 flex justify-content-start align-items-center">
            <InputSwitch
              name="field"
              checked={filter.closed}
              onChange={e => setFilter({ ...filter, closed: e.value })}
            />
            <label htmlFor="dropdown" className="ml-3">
              Finalizado
            </label>
          </div>

          <Divider />

          <div className="field col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Gerar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default CommissionsOS;
