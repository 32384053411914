import React, { useRef, useState, useEffect } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** service */
import api from '../../../services/api';

/** utils */
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';

/** types */
import { Option } from '../../../types/optional';

/** components */
import { Button } from 'primereact/button';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import Calendar from '../../../components/Inputs/InputCalendar';
import { Toast } from 'primereact/toast';

const ReportProposal = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);

  const [commisioneds, setCommisioneds] = useState<Option[]>([]);
  const [selectedCommisioned, setSelectedComissioned] = useState();

  const date = new Date();

  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfDay(new Date(date.getFullYear(), date.getMonth(), 1)),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  const toast = useRef<any>(null);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReport = async (data: any) => {
    try {
      if ((initialDate as Date) > (finalDate as Date)) {
        showToast(
          'error',
          'Error',
          'Por favor, informe uma data inicial menor que a final!',
        );
        return;
      }
      const { data: response } = await api.get('/reports/proposal-list', {
        params: {
          dateStart: `${initialDate}`,
          dateEnd: `${finalDate}`,
          commissionedId: data.comissionedId,
        },
      });
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  useEffect(() => {
    api.get(`/commissioneds`).then(({ data }) => {
      const optComissioneds = data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCommisioneds(optComissioneds);
    });
  }, []);

  return (
    <>
      <div className="card w-6">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReport}>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Comissionado</label>
              <InputDropDown
                name="comissionedId"
                placeholder="Selecionar..."
                options={commisioneds}
                value={selectedCommisioned}
                onChange={e => setSelectedComissioned(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Inicial</label>
              <Calendar
                name="inital_date"
                value={initialDate}
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Final</label>
              <Calendar
                name="final_date"
                value={finalDate}
                onChange={e => setFinalDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col mt-4">
              <Button label="Gerar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportProposal;
