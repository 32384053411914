import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import { Column } from 'primereact/column';
import useToastContext from '../../../hooks/toast';
import { emptyOrderResume, IOrderResume } from '../types/IOrderResume';
import api from '../../../services/api';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import RenderColumnLock from '../../../components/DataTableColumns/RenderColumnLock';
import { renderColumnOrderOrigin } from '../../../components/DataTableColumns/RenderColumnOrderOrigin';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import MenuPopUp from '../../../components/MenuPopPup';
import { AxiosError, AxiosResponse } from 'axios';
import openReports from '../../../utils/openReports';
import messageRequestError from '../../../utils/messageRequestError';
import { setLocale } from 'yup';
import { EnumOrderOrigin } from '../../../enum/EnumOrderOrigin';
import { EnumOrderType } from '../../../enum/EnumOrderType';

interface IProps {
  customer_id: string;
}

const ContentPedidos = ({ customer_id }: IProps): React.ReactElement => {
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [doc, setDoc] = useState<IOrderResume>(emptyOrderResume);
  const [docs, setDocs] = useState<IOrderResume[]>([]);

  const loadDocs = async (id: string) => {
    setIsLoad(true);
    await api
      .post('/order/customer/resume', {
        data: { id },
        pagination: { page: 1, perPage: 1000, status: ['A', 'C'] },
      })
      .then(res => {
        if (res) {
          setDocs(res.data);
        }
      })
      .catch(e => {
        toast(
          'warn',
          'Alerta',
          `Falha ao carregar os pedidos: ${e.message}`,
          8000,
        );
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const printOrder = async (id: string) => {
    setIsLoad(true);
    await api
      .post(`/reports/v2/order`, { id, promissory: 0 })
      .then((res: AxiosResponse) => {
        if (res.data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
            'pedido',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const printPropose = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/proposal/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
            'proposta',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const printCupom = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/coupom/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
            'cupom',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const renderMenu = (row: IOrderResume) => {
    const items: any[] = [];

    if (
      row.order_origin === EnumOrderOrigin.TERMINAL ||
      row.order_origin === EnumOrderOrigin.LOJA
    ) {
      if (row.order_type === EnumOrderType.PEDIDO) {
        items.push({
          label: 'Imprimir pedido',
          icon: 'fa-solid fa-print',
          command: () => printOrder(row.id),
        });
      }

      if (row.order_type === EnumOrderType.PROPOSTA) {
        items.push({
          label: 'Imprimir proposta',
          icon: 'fa-solid fa-print',
          command: () => printPropose(row.id),
        });
      }
    }

    if (row.order_origin === EnumOrderOrigin.PDV) {
      items.push({
        label: 'Imprimir cupom',
        icon: 'fa-solid fa-print',
        command: () => printCupom(row.id),
      });
    }

    return items;
  };

  const renderRowMenu = (row: IOrderResume) => {
    const model = renderMenu(row);
    if (model.length > 0) {
      return (
        <>
          <MenuPopUp model={model} rowData={row} setData={setDoc} />
        </>
      );
    }
  };

  useEffect(() => {
    loadDocs(customer_id);
  }, [customer_id]);

  return (
    <>
      <DataTable
        value={docs}
        paginator
        rows={10}
        loading={isLoad}
        loadingIcon="pi pi-spinner text-primary"
        rowsPerPageOptions={[10, 20, 30]}
        className="datatable-responsive m-0 p-0"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} adicionais"
        emptyMessage="Sem pedidos."
        responsiveLayout="scroll"
        size="small"
      >
        <Column field="id" header="#" body={renderColumnPosition}></Column>
        <Column field="order" header="Pedido"></Column>
        <Column field="customer_name" header="Cliente"></Column>
        <Column
          field="order_date"
          header="Data"
          body={r => renderColumnDate(r.order_date)}
        ></Column>
        <Column field="nat_ope_title" header="Nat. Operação"></Column>
        <Column field="order_type" header="Tipo"></Column>
        <Column
          field="total"
          header="Total"
          body={r => renderColumnDecimal(r.total)}
        ></Column>
        <Column
          field="order_origin"
          header="Origem"
          body={r => renderColumnOrderOrigin(r.order_origin)}
          align={'center'}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={r => renderColumnStatus(r)}
          align={'center'}
        ></Column>
        <Column header="*" body={r => renderRowMenu(r)}></Column>
      </DataTable>
    </>
  );
};

export default ContentPedidos;
