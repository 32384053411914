import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDate from '../../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnFlag from '../../../../components/DataTableColumns/RenderColumnFlag';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../../../../components/DataTableColumns/RenderColumnShortName';
import renderColumnStatus from '../../../../components/DataTableColumns/RenderColumnStatus';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { InputText } from '../../../../components/Inputs/InputText';
import MenuPopUp from '../../../../components/MenuPopPup';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import ConsignedDevolutionCheck from '../../sidebar-check';
import ConsignedTopMenu from '../../top-menu';
import { IList, ISearchResponse } from '../dtos/IConOrderDTO';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const ConsignedOrderList: React.FC = () => {
  const hoje = new Date();
  const router = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [orderId, setOrderId] = useState<string>('');
  const [sidebarCheckVisible, setSidebarCheckVisible] =
    useState<boolean>(false);

  const [keyword, setKeyword] = useState<string | null>(null);
  const [period, setPeriod] = useState<Date[]>([
    startOfMonth(hoje),
    endOfMonth(hoje),
  ]);

  const statusOpts = [
    { value: 'T', label: 'Todos' },
    { value: 'A', label: 'Ativo' },
    { value: 'C', label: 'Cancelado' },
  ];
  const [status, setStatus] = useState<string>('T');

  const [sequence, setSequence] = useState<number | null>(null);

  const [check, setCheck] = useState<boolean | null>(null);

  const [invoiced, setInvoiced] = useState<boolean | null>(null);

  const [natOpeOpts, setNatOpeOpts] = useState<IOptionsDTO[]>([]);
  const [natOpes, setNatOpes] = useState<string[]>([]);

  const orderTypes = [
    { value: 'T', label: 'Todos' },
    { value: 'PED', label: 'Pedido' },
    { value: 'PRO', label: 'Proposta' },
  ];
  const [orderType, setOrderType] = useState<string>('T');

  const [totals, setTotals] = useState<number[]>([0, 100000.0]);

  const [orders, setOrders] = useState<IList[]>([]);
  const [order, setOrder] = useState<IList | null>(null);

  const loadNatOpe = async () => {
    setLoad(true);
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setNatOpeOpts(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadOrders = async () => {
    setLoad(true);
    await api
      .post('/consigned/invoice/list', {
        data: { page: 1, perPage: 1000, status: 'T' },
      })
      .then(({ data }) => {
        if (data) {
          setOrders(data.map((i: IList) => i));
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleSubmit = async (d: any) => {
    setLoad(true);
    await api
      .post('/consigned/invoice/search', {
        data: {
          keyword: keyword || null,
          filter: {
            nat_ope: natOpes || [],
            order_type: orderType,
            sequence: sequence || null,
            check: check || null,
            invoiced: invoiced || null,
            total: {
              start: totals[0] || 0,
              end: totals[1] || 100000,
            },
            period: {
              start: period[0] as Date,
              end: period[1] as Date,
            },
          },
          show: {
            page: 1,
            perPage: 1000,
            status,
          },
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setOrders(
            data.map((i: ISearchResponse) => {
              return {
                id: i.id,
                sequence: i.sequence,
                customer_id: i.customer_id,
                commissioned_id: null,
                date_digitation: i.date_digitation,
                date_sell: i.date_sell,
                nat_ope_id: null,
                order_type: i.order_type,
                invoiced: i.invoiced,
                nfe_emited: i.nfe_emited,
                discount: null,
                additional: null,
                fees: null,
                qnt_products: null,
                qnt_itens: null,
                total_comission: null,
                order_value: null,
                order_total: i.order_total,
                expire_day: null,
                checked: i.checked,
                obs: null,
                user_id: i.user_id,
                status: i.status,
                route: {
                  id: null,
                  sequence: i.route,
                },
                customer: {
                  id: null,
                  codigo: null,
                  customer: null,
                  name: i.customer_name,
                  cpf_cnpj: null,
                  block: null,
                  image_url: null,
                  status: null,
                  last_transaction: null,
                  type: null,
                },
                user: {
                  id: null,
                  name: i.user_name,
                  status: null,
                },
              };
            }),
          );
        } else {
          setOrders([]);
        }
      })
      .catch((err: any) => {
        setOrders([]);
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const printOrder = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/order/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-pedido',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const printOrderTwoColumns = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/order/two/columns/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-pedido',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const printOrderCheck = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/order/check/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-pedido-conferencia',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const exportOrderToExcel = async (id: string) => {
    setLoad(true);
    await api
      .get(`/consigned/invoice/excel/${id}`)
      .then(({ data }) => {
        if (data) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Webger - pedido consignado');

          worksheet.addRow(['Webger - Consignados']);
          worksheet.addRow(['']);
          worksheet.addRow([
            `CLIENTE:`,
            data.customer,
            '',
            'PED/ROTA:',
            data.origin,
          ]);
          worksheet.addRow([
            `USUÁRIO:`,
            data.user,
            '',
            'DATA:',
            data.date_digitation,
          ]);

          let tableRows = [];

          for (const item of data.items) {
            tableRows.push([
              item.pos,
              item.sku,
              item.qnt,
              item.vlr_unit,
              item.total,
            ]);
          }

          worksheet.addTable({
            name: 'Lista',
            ref: 'A6',
            headerRow: true,
            totalsRow: false,
            columns: [
              { name: '#', filterButton: true },
              { name: 'SKU', filterButton: false },
              { name: 'QNT.', filterButton: true },
              { name: 'VLR. UNIT.', filterButton: false },
              { name: 'TOTAL', filterButton: true },
            ],
            rows: tableRows,
          });

          worksheet.columns = [
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 8,
            },
          ];

          worksheet.addRow(['']);
          worksheet.addRow(['', '', '', `VALOR TOTAL:`, data.total_order]);
          worksheet.addRow([
            '',
            '',
            '',
            `TOTAL PRODUTOS:`,
            data.total_products,
          ]);
          worksheet.addRow(['', '', '', `TOTAL ITENS:`, data.total_items]);

          workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveAs(blob, 'webger-pedido-consignado.xlsx');
          });
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleCancelOrder = async (id: string) => {
    setLoad(true);
    await api
      .delete(`/consigned/invoice/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', `O pedido foi cancelado com sucesso!`);
          loadOrders();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleCloneOrder = async (id: string) => {
    setLoad(true);
    await api
      .get(`/consigned/invoice/clone/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Pedido clonado com sucesso!');
          loadOrders();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const mountMenu = (row: any) => {
    const items: any[] = [];

    if (row.order_type === 'PRO' && row.status === 'A') {
      items.push({
        label: 'Editar',
        icon: 'fa-solid fa-file-edit',
        command: async () => {
          router.push(`/consigned/order/update/${row.id}`);
        },
      });
    }

    if (row.status !== 'C' && row.checked !== true) {
      items.push({
        label: 'Conferir',
        icon: 'fa-solid fa-square-check',
        command: async () => {
          setOrderId(row.id);
          setSidebarCheckVisible(true);
        },
      });
    }

    items.push({
      label: 'Imprimir',
      icon: 'fa-solid fa-print',
      command: () => printOrder(row.id),
    });

    items.push({
      label: 'Imprimir em 2 colunas',
      icon: 'fa-solid fa-print',
      command: () => printOrderTwoColumns(row.id),
    });

    items.push({
      label: 'Imprimir conferência',
      icon: 'fa-solid fa-check-square',
      command: () => printOrderCheck(row.id),
    });

    items.push({
      label: 'Exportar excel',
      icon: 'fa-solid fa-file-export',
      command: () => exportOrderToExcel(row.id),
    });

    if (row.status !== 'C' && row.invoiced !== true) {
      items.push({
        label: 'Faturar',
        icon: 'fa-solid fa-sack-dollar',
        command: () => alert('clicou'),
      });
    }

    items.push({
      label: 'Clonar',
      icon: 'fa-solid fa-copy',
      command: async () => handleCloneOrder(row.id),
    });

    items.push({
      label: 'Nfe',
      icon: 'fa-solid fa-file-pdf',
      command: () => alert('clicou'),
    });

    if (row.status !== 'C') {
      items.push({
        label: 'Cancelar',
        icon: 'fa-solid fa-trash',
        command: async () => handleCancelOrder(row.id),
      });
    }

    return items;
  };

  const renderCollumnMenu = (row: any) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={() => {}} />
      </>
    );
  };

  const renderColumnNameType = (r: IList) => {
    return (
      <>
        <span>{r.customer.name}</span>
        {r.order_type === 'PED' ? (
          <span
            className="customer-badge ml-3"
            style={{
              color: 'var(--green-800)',
              background: 'var(--green-200)',
            }}
          >
            PED
          </span>
        ) : (
          <span
            className="customer-badge ml-3"
            style={{
              color: 'var(--orange-800)',
              background: 'var(--orange-200)',
            }}
          >
            PRO
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    loadNatOpe();
    loadOrders();
  }, []);

  useEffect(() => {
    loadOrders();
  }, [sidebarCheckVisible]);

  return (
    <>
      <ConsignedTopMenu />
      <Form ref={formRef} onSubmit={d => handleSubmit(d)} className="card">
        <div className="p-fluid grid formgrid">
          <div className="field col-4">
            <label htmlFor="keyword">Nome</label>
            <InputText
              name="keyword"
              placeholder="Ex.: João da Silva"
              value={keyword || ''}
              onChange={e => setKeyword(e.currentTarget.value)}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="period">Período</label>
            <CalendarRange
              name="period"
              value={period}
              onChange={e => setPeriod(e.value as Date[])}
              placeholder="00/00/00 ~ 00/00/00"
              showIcon
            />
          </div>
          <div className="field col-2">
            <label htmlFor="status">Status</label>
            <InputDropDown
              name="status"
              value={status}
              options={statusOpts}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
            />
          </div>
          <div className="field col-2">
            <label htmlFor="sequence">N. Ped.</label>
            <InputNumber
              name="sequence"
              value={sequence}
              onChange={e => setSequence(e.value)}
              placeholder="000"
            />
          </div>
          <div className="field col-2">
            <label htmlFor="nat_ope">
              Natureza de Ope.{' '}
              {natOpes?.length > 0 ? `(${natOpes?.length})` : ''}
            </label>
            <MultiSelect
              name="nat_ope"
              options={natOpeOpts || []}
              value={natOpes}
              onChange={e => setNatOpes(e.value)}
              placeholder="Selecionar..."
              filter
              display="chip"
            />
          </div>
          <div className="field col-2">
            <label htmlFor="order_type">Pedido/Proposta</label>
            <InputDropDown
              name="order_type"
              value={orderType}
              options={orderTypes}
              placeholder="Selecionar..."
              onChange={e => setOrderType(e.value)}
            />
          </div>
          <div className="field col-1">
            <label htmlFor="total_start">Pedidos entre</label>
            <InputNumber
              name="total_start"
              value={totals[0]}
              onChange={e => setTotals([e.value as number, totals[1]])}
              placeholder="0,00"
            />
          </div>
          <div className="field col-1">
            <label htmlFor="total_end">.</label>
            <InputNumber
              name="total_end"
              value={totals[1]}
              onChange={e => setTotals([totals[0], e.value as number])}
              placeholder="100.000,00"
            />
          </div>
          <div className="field col-2 flex align-items-end">
            <InputSwitch
              name="check"
              className="mr-3"
              checked={check}
              onChange={e => setCheck(e.value as boolean)}
            />
            <label htmlFor="dropdown" className="m-0">
              Conferidos
            </label>
          </div>
          <div className="field col-2 flex align-items-end">
            <InputSwitch
              name="invoiced"
              className="mr-3"
              checked={invoiced}
              onChange={e => setInvoiced(e.value as boolean)}
            />
            <label htmlFor="dropdown" className="m-0">
              Faturados
            </label>
          </div>
          <div className="field col-2 md:col-2 flex align-items-end">
            <Button
              name="btn_search"
              label="Buscar"
              icon="fa-solid fa-search"
              type="submit"
              loading={load}
            />
          </div>
        </div>
      </Form>

      <DataTable
        value={orders}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Pedidos não encontrados..."
        selectionMode="single"
        loading={load}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} pedidos consignados."
        className="card"
      >
        <Column header="#" body={renderColumnPosition}></Column>
        <Column header="Rota" field="route.sequence" align={'center'}></Column>
        <Column header="Ped." field="sequence" align={'center'}></Column>
        <Column header="Cliente" body={r => renderColumnNameType(r)}></Column>
        <Column
          header="Digitação"
          body={r => renderColumnDate(r.date_digitation)}
          align={'center'}
        ></Column>
        <Column
          header="Faturamento"
          body={r => renderColumnDate(r.date_sell)}
          align={'center'}
        ></Column>
        <Column
          header="Faturado"
          body={r => renderColumnFlag(r.invoiced)}
          align={'center'}
        ></Column>
        <Column
          header="Conferido"
          body={r => renderColumnFlag(r.checked)}
          align={'center'}
        ></Column>
        <Column
          header="Nfe"
          body={r => renderColumnFlag(r.nfe_emited)}
          align={'center'}
        ></Column>
        <Column
          header="Total"
          body={r => renderColumnDecimal(r.order_total)}
        ></Column>
        <Column
          header="Status"
          body={r => renderColumnStatus(r)}
          align={'center'}
        ></Column>
        <Column
          header="Usuário"
          body={r => renderColumnShortName(r.user.name)}
        ></Column>
        <Column
          header="*"
          body={e => renderCollumnMenu(e)}
          align={'center'}
        ></Column>
      </DataTable>

      <ConsignedDevolutionCheck
        id={orderId}
        isOpen={sidebarCheckVisible}
        isOrder={true}
        onRequestClose={() => {
          setOrderId('');
          setSidebarCheckVisible(false);
        }}
      />
    </>
  );
};

export default ConsignedOrderList;
