type User = {
  avatar_url: string;
  birthday: string;
  contract_id: string;
  cpf_cnpj: string;
  created_at: string;
  email: string;
  id: string;
  key_user: boolean;
  name: string;
  old_password: string;
  phone: string;
  status: string;
  token: string;
  b_id: string;
  business: {
    name: string;
    cpf_cnpj: string;
  };
};

type FormInfor = {
  name: string;
  code: string;
};

type Access = {
  _f: string;
};

export type StateProps = {
  user: User;
  favorites: any;
  forms: Array<FormInfor>;
  access: string[];
};

type StateAction = {
  user: User;
  token: string;
  favorites: any;
  forms: Array<FormInfor>;
  access: string[];
};

export const initialState: StateProps = {
  user: {
    avatar_url: '',
    b_id: '',
    birthday: '',
    contract_id: '',
    cpf_cnpj: '',
    created_at: '',
    email: '',
    id: '',
    key_user: false,
    name: '',
    old_password: '',
    phone: '',
    status: '',
    token: '',
    business: {
      name: '',
      cpf_cnpj: '',
    },
  },
  favorites: {},
  forms: [],
  access: [],
};

export type ActionProps = {
  payload: StateAction;
  type: string;
};

export const useReducerApp = (
  state: StateProps,
  action: ActionProps,
): StateProps => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload.user, favorites: {} };
    case 'SET_PROFILE':
      return {
        ...state,
        user: { ...state.user, avatar_url: action.payload.user.avatar_url },
      };
    case 'SET_FAVORITES':
      return { ...state, favorites: action.payload.favorites };
    case 'SET_FORMS':
      return { ...state, forms: action.payload.forms };
    case 'SET_ACCESS':
      return { ...state, access: action.payload.access };
  }
  return state;
};
