interface IProductLowStock {
  codigo: string;
  title: string;
  unity: string;
  stock: number;
  stock_min: number;
}

interface IRegisterData {
  title: string;
  balance_open_date: Date;
  last_record: Date;
}

interface IOrderData {
  order: number;
  sell_date: Date;
  total: number;
}

export interface IDashboard {
  order_summary: {
    order: {
      qnt: number;
      total: number;
    };
    proposal: {
      qnt: number;
      total: number;
    };
    canceled: {
      qnt: number;
      total: number;
    };
    orders: IOrderData[];
  };
  dash_tt_visitas: number;
  cash_summary: {
    cash_summary: {
      qnt: number;
      total: number;
    };
    registers: IRegisterData[];
  };
  product_stock: {
    stock_low: number;
    products: IProductLowStock[];
  };
  orders_summary: {
    orders_month: [];
  };
  product_solds: {
    solds: {
      title: string;
      total: number;
    }[];
  };
  wallet_summary: {
    resume: {
      period: string;
      entrada: number;
      saida: number;
    }[];
  };
  dash_elements: {
    id: string;
    element_id: string;
    visible: boolean;
  }[];
}

export const emptyDashboard: IDashboard = {
  order_summary: {
    order: {
      qnt: 0,
      total: 0,
    },
    proposal: {
      qnt: 0,
      total: 0,
    },
    canceled: {
      qnt: 0,
      total: 0,
    },
    orders: [],
  },
  dash_tt_visitas: 0,
  cash_summary: {
    cash_summary: {
      qnt: 0,
      total: 0,
    },
    registers: [],
  },
  product_stock: {
    stock_low: 0,
    products: [],
  },
  orders_summary: {
    orders_month: [],
  },
  product_solds: {
    solds: [],
  },
  wallet_summary: {
    resume: [],
  },
  dash_elements: [],
};
