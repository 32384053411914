import { v4 } from 'uuid';
import { EnumStatus } from '../../../enum/EnumStatus';
import IOsObject from '../dtos/IOsObject';

export const emptyOsObject: IOsObject = {
  id: v4(),
  b_id: '',
  os_id: '',
  object_id: '',
  object_title: '',
  qnt: 1,
  obs: '',
  user_id: '',
  status: EnumStatus.ATIVO,
  identifier: '',
};
