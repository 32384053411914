import { InputNumber } from 'primereact/inputnumber';
import React, { useState } from 'react';

interface InputNumberRangeProps {
  onMinValueChange: (value: number | null) => void;
  onMaxValueChange: (value: number | null) => void;
  defaultMinValue: number;
  defaultMaxValue: number;
  range: number[];
  name: string;
}

const InputNumberRange: React.FC<InputNumberRangeProps> = ({
  onMinValueChange,
  onMaxValueChange,
  defaultMinValue,
  defaultMaxValue,
  name,
  range,
}) => {
  const handleValorMinimoChange = (e: { value: number | null }) => {
    onMinValueChange(e.value === null ? defaultMinValue : e.value);
  };

  const handleValorMaximoChange = (e: { value: number | null }) => {
    onMaxValueChange(e.value === null ? defaultMaxValue : e.value);
  };

  return (
    <div>
      <div className="p-inputgroup">
        <InputNumber
          name={name}
          value={range[0]}
          onValueChange={handleValorMinimoChange}
          placeholder="0,00"
          inputClassName="text-center"
          minFractionDigits={2}
          maxFractionDigits={2}
          onFocus={e => e.target.select()}
          locale="pt-BR"
        />
        <span
          className="p-inputgroup-addon"
          style={{ borderLeft: 0, borderRight: 0 }}
        >
          até
        </span>
        <InputNumber
          name={name}
          value={range[1]}
          onValueChange={handleValorMaximoChange}
          placeholder="1.000,00"
          inputClassName="text-center"
          minFractionDigits={2}
          maxFractionDigits={2}
          onFocus={e => e.target.select()}
          locale="pt-BR"
        />
      </div>
    </div>
  );
};

export default InputNumberRange;
