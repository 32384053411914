import React, { useRef } from 'react';

/* unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** service */
import api from '../../../services/api';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

interface CrediariesRequest {
  title: string;
  crediaries: string;
}

const CostCenterCreate = (): React.ReactElement => {
  /* ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleCreate = async (data: CrediariesRequest) => {
    try {
      const schemaCrediaries = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
        description: Yup.string(),
      });
      await schemaCrediaries.validate(data, { abortEarly: false });
      await api.post('/crediaries', { ...data, status: 'A' });
      navigate.push('/crediaries/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/crediaries/list')}
      />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />

        <Form onSubmit={handleCreate} ref={formRef}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Título</label>
              <InputText placeholder="Digite aqui.." name="title" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea placeholder="Descrição" name="description" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mt-4">
            <div className="field col-12 md:col flex">
              <Button
                label="Cancelar"
                className="mr-2 p-button-raised p-button-danger"
              />
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CostCenterCreate;
