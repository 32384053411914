/* eslint-disable no-prototype-builtins */
import { StateProps } from '../reducers/AppReducer';

export const countHits = (
  state: StateProps,
  pathname: string,
  dispatch: React.Dispatch<any>,
): void => {
  const cloneState = state;

  if (pathname !== '/' && cloneState.favorites.hasOwnProperty(pathname)) {
    cloneState.favorites[`${pathname}`] += 1;
  } else if (
    pathname !== '/' &&
    !cloneState.favorites.hasOwnProperty(pathname)
  ) {
    cloneState.favorites[`${pathname}`] = 1;
  }

  if (pathname) {
    dispatch({
      type: 'SET_FAVORITES',
      payload: { favorites: cloneState.favorites },
    });
  }
};
