import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import MenuPopUp from '../../../components/MenuPopPup';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import IProductListDTO from '../dtos/IProductListDTO';
import ProductHeaderButtons from '../header-buttons';

const ListProducts: React.FC = () => {
  const navigate = useHistory();
  const toast = useToastContext();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [produto, setProduto] = useState<IProductListDTO>();
  const [produtos, setProdutos] = useState<IProductListDTO[]>([]);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const searchProducts = async () => {
    try {
      setLoadingStatus(true);

      const { data } = await api.get(`/products/show/table`);
      if (data.length > 0) {
        setProdutos(data);
      }
    } catch (e: any) {
      toast('warn', 'Alerta', 'Falha ao buscar produtos!');
    } finally {
      setLoadingStatus(false);
    }
  };

  const deleteProduct = async (id: string) => {
    try {
      setLoadingStatus(true);

      await api.delete(`/products/${id}`);

      searchProducts();

      toast('success', 'Sucesso', 'Produto deletado!');
    } catch (error: any) {
      toast('error', 'Error', error?.response?.data?.error);
      return;
    } finally {
      setLoadingStatus(false);
    }
  };

  const cloneProduct = async (id: string) => {
    try {
      setLoadingStatus(true);

      await api.get(`/products/clone/${id}`);

      searchProducts();

      toast('success', 'Sucesso', 'Produto clonado!');
    } catch (error: any) {
      toast('error', 'Error', error?.response?.data?.error);
      return;
    } finally {
      setLoadingStatus(false);
    }
  };

  const generateBarCode = () => {
    return null;
  };

  const renderColumnNumber = (rowData: any, indice: any) => {
    return <span>{indice?.rowIndex + 1}</span>;
  };

  const renderColumnImage = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData?.productImages[0] ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.productImages[0].image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const renderHeaderDataTable = () => {
    return (
      <>
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 className="m-0">Lista de Produtos</h5>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Buscar..."
              autoComplete="off"
            />
          </span>
        </div>
      </>
    );
  };

  const renderColumnCategory = (rowData: any) => {
    return <span>{rowData.productGroups[0].group.title}</span>;
  };

  const renderColumnBrand = (rowData: any) => {
    return <span>{rowData?.productBrand[0]?.brand?.title}</span>;
  };

  const renderColumnStatus = (rowData: any) => {
    return (
      <div className="flex">
        {rowData.status === 'A' ? (
          <i className="fa-solid fa-check text-green-500"></i>
        ) : (
          <i className="pi pi-times text-pink-700"></i>
        )}{' '}
        {rowData.block === true ? (
          <i className="mx-2 fa-solid fa-lock text-pink-700"></i>
        ) : (
          <i className="mx-2 fa-solid fa-lock-open text-blue-500"></i>
        )}
      </div>
    );
  };

  const renderColumnOptions = (rowData: any) => {
    const items = [
      {
        label: 'Editar',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          navigate.push(`/products/edit/${rowData.id}`);
        },
      },
      {
        label: 'Clonar',
        icon: 'pi pi-fw pi-clone',
        command: () => {
          cloneProduct(rowData.id);
        },
      },
      {
        label: 'Deletar',
        icon: 'pi pi-fw pi-trash text-pink-500',
        command: () => {
          deleteProduct(rowData.id);
        },
      },
    ];
    return <MenuPopUp model={items} rowData={rowData} setData={setProduto} />;
  };

  useEffect(() => {
    searchProducts();
  }, []);

  return (
    <>
      <ProductHeaderButtons />
      <div className="card">
        <DataTable
          value={produtos}
          selection={produto}
          onSelectionChange={e => setProduto(e.value)}
          dataKey="id"
          loading={loadingStatus}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} produtos"
          globalFilter={globalFilter}
          emptyMessage="Não existe produtos."
          header={renderHeaderDataTable}
          responsiveLayout="scroll"
        >
          <Column field="code" header="N°" body={renderColumnNumber}></Column>
          <Column field="sku" header="Sku" sortable align={'center'}></Column>
          <Column header="Foto" body={renderColumnImage}></Column>
          <Column field="title" header="Descrição" sortable></Column>
          <Column
            header="Categoria"
            body={renderColumnCategory}
            sortable
          ></Column>
          <Column header="Marca" body={renderColumnBrand}></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={renderColumnStatus}
          ></Column>
          <Column body={renderColumnOptions}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ListProducts;
