import { v4 } from 'uuid';

export interface IOrderResume {
  id: string;
  order: number;
  customer_id: string;
  customer_name: string;
  order_date: Date;
  nat_ope_id: string;
  nat_ope_title: string;
  order_type: string;
  total: number;
  order_origin: string;
  status: string;
}

export const emptyOrderResume: IOrderResume = {
  id: v4(),
  order: 0,
  customer_id: '',
  customer_name: '',
  order_date: new Date(),
  nat_ope_id: '',
  nat_ope_title: '',
  order_type: '',
  total: 0,
  order_origin: '',
  status: '',
};
