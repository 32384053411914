import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';

/** menu options */

const OriginTaxesList = (): React.ReactElement => {
  /* refs */
  const toast = useRef<any>(null);

  /** state */

  const [globalFilter, setGlobalFilter] = useState('');

  /** origin taxes */
  const [originTaxes, setOriginTaxes] = useState<Array<any>>([]);
  const [originSelected, setOriginSelected] = useState<any>();

  /** dialog */
  const [dialogDeletOriginTaxesDialog, setDialogDeletOriginTaxes] =
    useState(false);

  const [loading, setLoading] = useState(true);

  const hideDeleteOriginTaxesDialog = () => {
    setDialogDeletOriginTaxes(false);
  };

  const deleteOriginTaxesApi = async () => {
    try {
      await api.delete(`/tributos-origem/${originSelected.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteOriginTaxes = () => {
    const _originTaxes = originTaxes.filter(
      val => val.id !== originSelected.id,
    );
    setOriginTaxes(_originTaxes);
    setDialogDeletOriginTaxes(false);
    setOriginSelected(undefined);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'tributo Deletado',
      life: 3000,
    });
    deleteOriginTaxesApi();
  };

  const deleteCsosnDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteOriginTaxesDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteOriginTaxes}
      />
    </React.Fragment>
  );

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const confirmDeleteOriginTaxes = (brand: any) => {
    setOriginSelected(brand);
    setDialogDeletOriginTaxes(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button icon="pi pi-pencil" className="p-button-raised mr-4" disabled />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteOriginTaxes(rowData)}
        />
      </div>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <span>
        {rowData.status === 'A' ? (
          <Checkbox checked={true} />
        ) : (
          <i className="pi pi-times text-pink-400"></i>
        )}
      </span>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Tributos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchTribOri = async () => {
    try {
      const { data } = await api.get(`/tributos-origem`);
      setOriginTaxes(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchTribOri();
  }, []);

  return (
    <>
      <div className="card">
        <DataTable
          value={originTaxes}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} tributos"
          globalFilter={globalFilter}
          emptyMessage="Não existe tributos."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="codigo"
            header="Código"
            align="center"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column header="Descrição" field="descricao"></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeletOriginTaxesDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteCsosnDialogFooter}
        onHide={hideDeleteOriginTaxesDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {originSelected && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{originSelected?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default OriginTaxesList;
