import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../../components/DataTable';
import { Dialog } from '../../../../components/Modal';

/** services */
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import api from '../../../../services/api';

/** menu options */

type Group = {
  description: string;
  group_father: string;
  group_id: string;
  id: string;
  status: string;
  title: string;
};

const ConsultantList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [dialogDeleteGroup, setDialogDeleteGroup] = useState(false);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** state dialog */
  const [consultant, setConsultant] = useState<any>();

  /** consultant */
  const [consultants, setConsustants] = useState<Array<any>>([]);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const columnCreateAt = (rowData: any) => {
    return <span>{format(new Date(rowData.created_at), 'dd/MM/yyyy')}</span>;
  };

  const statusBodyTemplate = (rowData: Group) => {
    return rowData.status === 'A' ? (
      <i className="fa-solid fa-check"></i>
    ) : (
      <i className="fa-solid fa-xmark"></i>
    );
  };

  const confirmDeleteConsultantt = (group: any) => {
    setConsultant(group);
    setDialogDeleteGroup(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex align-items-end justify-content-between">
        <Button
          icon="pi pi-copy"
          className="p-button-success"
          onClick={() => {
            toast.current?.show({
              severity: 'info',
              summary: '',
              detail: 'Link copiado!',
              life: 3000,
            });
            window.navigator.clipboard.writeText(rowData.consultant_url);
          }}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-info"
          onClick={() => navigate.push(`/mkt/consultor/editar/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => confirmDeleteConsultantt(rowData)}
        />
      </div>
    );
  };

  /** delete dialog  */

  const hideDeleteGroupDialog = () => {
    setDialogDeleteGroup(false);
  };

  const deleteConsultantApi = async () => {
    try {
      await api.delete(`/mkt/consultant/${consultant.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteGroup = () => {
    const _consultants = consultants.filter(val => val.id !== consultant.id);
    setConsustants(_consultants);
    hideDeleteGroupDialog();
    setConsultant(undefined);
    deleteConsultantApi();
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Consultor Deletado',
      life: 3000,
    });
  };

  const deleteConsultantDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteGroupDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteGroup}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Consultores</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchConsultant = async () => {
    try {
      const { data } = await api.get('/mkt/consultant');
      setConsustants(data.listAllConsultant);
      setLoading(false);
    } catch (error: any) {
      toast.current?.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchConsultant();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/mkt/consultor/novo')}
      />
      <Button
        label="Leads"
        icon="pi pi-list"
        className="mb-3 ml-3"
        onClick={() => navigate.push('/mkt/lead/listar')}
      />
      <div className="card">
        <Toast ref={toast} />
        <DataTable
          value={consultants}
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} of {totalRecords} consultores"
          globalFilter={globalFilter}
          emptyMessage="Não existe consultores."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="whatsapp" header="Whatsapp"></Column>
          <Column
            field="whatsapp"
            header="Criado Em"
            body={columnCreateAt}
          ></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>

          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteGroup}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteConsultantDialogFooter}
        onHide={hideDeleteGroupDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {consultant && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{consultant?.nome}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ConsultantList;
