import React, { useRef, useEffect } from 'react';

/* unform */
import { useField } from '@unform/core';

/** api */
import { locale, addLocale } from 'primereact/api';

/** config language date */
import languageCalendar from '../../../config/calendar';

/** prime-react component */
import { Calendar as Calendars, CalendarProps } from 'primereact/calendar';
import { addYears } from 'date-fns';

/** type calendar */
interface PropsCalendar extends CalendarProps {
  name: string;
}

const Calendar = (props: PropsCalendar): React.ReactElement => {
  const inputRef = useRef(null);

  const hoje = new Date();

  addLocale('pt', languageCalendar);

  locale('pt');

  const { fieldName, registerField, error } = useField(props.name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Calendars
      inputRef={inputRef}
      {...props}
      className={error ? `p-invalid ${props.className}` : `${props.className}`}
      inputClassName="text-center"
      mask="99/99/9999"
      monthNavigator
      yearNavigator
      yearRange={`1970:${addYears(hoje, 20).getFullYear()}`}
    />
  );
};

export default Calendar;
