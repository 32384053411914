import React, { useState, useRef, useEffect } from 'react';

/* unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** service */
import api from '../../../services/api';

/* react-router-dom */
import { useHistory } from 'react-router-dom';
import useToastContext from '../../../hooks/toast';

/** components */
import { Button } from 'primereact/button';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText as InText } from 'primereact/inputtext';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { Option } from '../../../types/optional';
import { Person } from '../../../types/person';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import convertToNumber from '../../../utils/number';
import { addMonths } from 'date-fns';
import { ProgressBar } from 'primereact/progressbar';
import useSearchCustomer from '../../../hooks/useSearchCustomer';
import messageRequestError from '../../../utils/messageRequestError';

type TypePaymentResponse = {
  id: string;
  title: string;
  description: string;
};

type CreditAnalysisCreateRequest = {
  person_id: string;
  payment_condition_id: string;
  limit_brute: string;
  date_on: string;
  date_off: string;
  days_to_block: string;
  desc_autorized: string;
};

const CreditAnalysisCreate = () => {
  const hoje = new Date();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  /** customer */
  const [customers, setCustomers] = useState(undefined);
  const [customerSelected, setCustomerSelected] = useState<Option>();
  const [search, setSearchCustomer] = useState('');

  /** block */
  const [block, setBlock] = useState('false');

  /** date */
  const [dateOn, setDateOn] = useState<Date | Date[] | undefined>(hoje);
  const [dateOff, setDateOff] = useState<Date | Date[] | undefined>(
    addMonths(hoje, 6),
  );

  /** payment condition */
  const [paymentCondition, setPaymentCondition] = useState<Option[]>([]);
  const [paymentConditionSelect, setPaymentConditionSelect] = useState();

  /** toast */
  const toast = useToastContext();

  /** navigate */
  const navigate = useHistory();

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: Person) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  /** ref */
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = async (data: CreditAnalysisCreateRequest) => {
    try {
      data.person_id = customerSelected?.value || '';

      setIsLoad(true);
      formRef.current?.setErrors({});
      const schemaCreditAnalysis = Yup.object({
        person_id: Yup.string()
          .required('Por favor, selecione um cliente!')
          .uuid('Id do cliente inválido!'),
        payment_condition_id: Yup.string().required(
          'Por favor, selecione condição de pagamento preferencial!',
        ),
        limit_brute: Yup.string().required(
          'Por favor, informe o limite bruto!',
        ),
        date_on: Yup.string().required(
          'Por favor, informe o começo da validade!',
        ),
        date_off: Yup.string().required(
          'Por favor, informe o final da validade!',
        ),
        days_to_block: Yup.string().required(
          'Por favor, informe a quantidade de dias para bloqueio!',
        ),
        desc_autorized: Yup.string().required('Por favor, informe o desconto'),
      });
      await schemaCreditAnalysis.validate(data, { abortEarly: false });

      await api.post('/credit-analise', {
        ...data,
        limit_brute: parseFloat(convertToNumber(data.limit_brute)),
        days_to_block: parseInt(data.days_to_block),
        desc_autorized: parseFloat(
          data.desc_autorized.replace('%', '').replace(',', '.'),
        ),
        date_on: dateOn,
        date_off: dateOff,
      });
      navigate.push('/credit-analise/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  /** template auto complete */
  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** search customer */

  const searchCustomer = async (event: AutoCompleteCompleteMethodParams) => {
    setIsLoad(true);

    try {
      const customers = await useSearchCustomer({ search: event.query });

      if (customers && customers.length > 0) {
        const customerOption = ammountOptions(customers);
        setCustomers(customerOption);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  /** select an customer */

  const selectCustomer = (e: Option) => {
    setCustomerSelected(e);
  };

  useEffect(() => {
    setIsLoad(true);
    api
      .get('/payments-condition')
      .then(({ data }) => {
        const typePayments = data.map((item: TypePaymentResponse) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setPaymentCondition(typePayments);
      })
      .finally(() => setIsLoad(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/credit-analise/list')}
      />
      {isLoad && <ProgressBar mode="indeterminate" style={{ height: '4px' }} />}
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Cliente</label>
              <InputAutoComplete
                name="person_id"
                value={search}
                completeMethod={e => searchCustomer(e)}
                suggestions={customers}
                field="label"
                onSelect={e => selectCustomer(e.value)}
                onChange={e => setSearchCustomer(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar Cliente..."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Condição pagamento preferencial</label>
              <InputDropDown
                name="payment_condition_id"
                options={paymentCondition}
                value={paymentConditionSelect}
                onChange={e => setPaymentConditionSelect(e.value)}
                placeholder="Selecione..."
                emptyFilterMessage="Nenhum item encontrado!"
                filter
                showClear
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Válido à partir de</label>
              <Calendar
                name="date_on"
                value={dateOn}
                onChange={e => setDateOn(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Válido até</label>
              <Calendar
                name="date_off"
                value={dateOff}
                onChange={e => setDateOff(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Bloqueia com quantos dias?</label>
              <InputNumber
                name="days_to_block"
                mode="decimal"
                showButtons
                value={1}
                min={1}
                max={100}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Desconto Autorizado (%)</label>
              <InputNumber
                mode="decimal"
                name="desc_autorized"
                suffix="%"
                value={0}
                placeholder="0,00%"
                minFractionDigits={2}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-4">
            <div className="field col-12 md:col-3 flex align-items-center">
              <InputSwitch
                className="mr-4"
                checked={block}
                onChange={e => setBlock(`${e.value}`)}
                name="blockeed"
                trueValue="true"
                falseValue="false"
              />
              <label htmlFor="basic" className="m-0">
                Bloqueado?
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Limite Consumido</label>
              <InText disabled className="surface-200" />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Limite Bruto</label>
              <InputNumber
                name="limit_brute"
                mode="decimal"
                placeholder="R$ 0,00"
                minFractionDigits={1}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Limite Disponivel</label>
              <InText disabled className="surface-200" />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={isLoad}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreditAnalysisCreate;
