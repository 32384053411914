import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { IRegisterCash } from '../dtos/IRegisterCash';
import RegisterCashHeader from '../header';

const CashClose = (): React.ReactElement => {
  const [load, setLoad] = useState(false);
  const hoje = new Date();

  const navigate = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [options, setOptions] = useState<IRegisterCash[]>([]);
  const [cash, setCash] = useState<IRegisterCash>({
    value: '',
    label: '',
    id: 0,
    serie: 0,
    register_id: '',
    title: '',
    balance_open_date: null,
    last_record: null,
  });

  const [balance, setBalance] = useState(0);

  const [user, setUser] = useState<{ id: string; name: string } | undefined>(
    undefined,
  );

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  useEffect(() => {
    if (user && validate(user.id)) {
      loadOpenCash();
    }
  }, [user]);

  const handleSelectCash = (e: any) => {
    const reg = options.find(i => i.register_id === e.value);
    if (reg) {
      setCash({
        value: reg.value,
        label: reg.label,
        id: reg.id,
        serie: reg.serie,
        register_id: reg.register_id,
        title: reg.title,
        balance_open_date: new Date(reg.balance_open_date as Date),
        last_record: new Date(reg.last_record as Date),
      });

      setBalance(0);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setLoad(true);
      formRef.current?.setErrors({});

      d.register_id = cash.register_id;
      d.balance_close_user = balance;

      const schemaCashClosed = Yup.object({
        register_id: Yup.string()
          .uuid('Informe a registradora!')
          .required('Por favor, Informe Registradora!'),
        balance_close_user: Yup.number().required(
          'Informe um saldo para o fechamento!',
        ),
      });
      await schemaCashClosed.validate(d, { abortEarly: false });

      await api
        .post('/register-cash/close', {
          register_id: cash.register_id,
          balance_close_user: balance,
          balance_close_date: hoje,
        })
        .then(({ data }) => {
          toast('success', 'Sucesso', 'Caixa fechado!');
          navigate.push('/reports/register-cash/by-register');
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => {
          setLoad(false);
        });
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const loadOpenCash = async () => {
    setLoad(true);
    await api
      .get('/register-cash/list/open/cash')
      .then(({ data }) => {
        if (data) {
          const userCash = data.find((i: any) => i.operator_id === user?.id);

          const opts = data.map((i: IRegisterCash) => {
            return {
              ...i,
              value: i.register_id,
              label: `${i.serie} - ${i.title}`,
            };
          });
          setOptions(opts);

          const _cash = opts.find(
            (o: any) => o.register_id === userCash.register_id,
          );
          if (_cash) setCash(_cash);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <>
      <RegisterCashHeader />
      {load && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card w-6"
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12">
            <label htmlFor="register_id">Registradora</label>
            <InputDropDown
              value={cash.value}
              name="register_id"
              dataKey="register_id"
              options={options}
              placeholder="Selecionar..."
              onChange={e => handleSelectCash(e)}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="operator">Operador Caixa</label>
            <InputText
              name="operator"
              value={
                validate(cash.register_id)
                  ? `${cash.serie} - ${cash.title}`
                  : '-'
              }
              className="surface-200"
              disabled
            />
          </div>

          <div className="field col-4">
            <label htmlFor="dropdown">Abertura Caixa</label>
            <InputText
              name="open_cash"
              value={
                cash && cash.balance_open_date
                  ? format(new Date(cash.balance_open_date), 'dd/MM/yyyy H:mm')
                  : '00/00/00 0:00'
              }
              className="surface-200"
              disabled
            />
          </div>
          <div className="field col-4">
            <label htmlFor="dropdown">Última Operação</label>
            <InputText
              name="last_transaction"
              value={
                cash && cash.last_record
                  ? format(new Date(cash.last_record), 'dd/MM/yyyy H:mm')
                  : '00/00/00 0:00'
              }
              className="surface-200"
              disabled
            />
          </div>

          <div className="field col-4">
            <label htmlFor="dropdown">Saldo do Caixa</label>
            <InputNumber
              name="balance_close_user"
              placeholder="R$ 0,00"
              mode="decimal"
              value={balance}
              onChange={e => setBalance(e.value as number)}
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={!validate(cash.register_id)}
              className={!validate(cash.register_id) ? 'surface-200' : ''}
            />
          </div>
        </div>

        <Divider />

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Fechar"
              icon="fa-solid fa-check"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default CashClose;
