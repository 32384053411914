import React, { useEffect, useRef, useState } from 'react';

/** components */
import { Button } from 'primereact/button';

/** types */
import { Option } from '../../../../types/optional';

/** yup */

/** services */
import api from '../../../../services/api';

/** utils */
import useToastContext from '../../../../hooks/toast';

/** form */
import { Form } from '@unform/web';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';

import { AxiosError, AxiosResponse } from 'axios';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Column } from 'primereact/column';
import { DropdownChangeParams } from 'primereact/dropdown';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../../components/DataTable';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import optionsFilter from './types/FilterOptions';

const ReportCurrentCount: React.FC = () => {
  /** hooks */
  const navigate = useHistory();
  const formRef = useRef(null);
  const toast = useToastContext();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const itemTemplateAutoComplete = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const [filtrarPor, setFiltrarPor] = useState<string>(optionsFilter[0].value);
  const [tableTitle, setTableTitle] = useState<string>('');

  const [produto, setProduto] = useState<{
    id: string;
    sku: string;
    label: string;
    value: string;
  }>({ id: '', sku: '', label: '', value: '' });
  const [produtos, setProdutos] = useState<string[]>([]);
  const [produtosOpts, setProdutosOpts] = useState<IOptionsDTO[]>([]);

  const [grupo, setGrupo] = useState<IOptionsDTO>({ label: '', value: '' });
  const [grupos, setGrupos] = useState<string[]>([]);
  const [gruposOpts, setGruposOpts] = useState<IOptionsDTO[]>([]);

  const [tag, setTag] = useState<IOptionsDTO>({ label: '', value: '' });
  const [tags, setTags] = useState<string[]>([]);
  const [tagsOpts, setTagsOpts] = useState<IOptionsDTO[]>([]);

  const [marca, setMarca] = useState<IOptionsDTO>({ label: '', value: '' });
  const [marcas, setMarcas] = useState<string[]>([]);
  const [marcasOpts, setMarcasOpts] = useState<IOptionsDTO[]>([]);

  const [fornecedor, setFornecedor] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });
  const [fornecedorOpts, setFornecedorOpts] = useState<IOptionsDTO[]>([]);

  const [itens, setItens] = useState<
    { id: string; sku: string; label: string; value: string }[]
  >([]);

  /** useEffect */
  useEffect(() => {
    setLoadingStatus(true);
    api.get('/groups').then(({ data }) => {
      if (data.length > 0) {
        setGruposOpts(
          data.map((i: any) => {
            return {
              label: i.title,
              value: i.id,
            };
          }),
        );
      }
    });

    api.get('/tags').then(({ data }) => {
      if (data.length > 0) {
        setTagsOpts(
          data.map((i: any) => {
            return {
              label: i.tag,
              value: i.id,
            };
          }),
        );
      }
    });

    api.get('/brands').then(({ data }) => {
      if (data.length > 0) {
        setMarcasOpts(
          data.map((i: any) => {
            return {
              label: i.title,
              value: i.id,
            };
          }),
        );
      }
    });

    setLoadingStatus(false);
  }, []);

  /** functions */

  const searchProvider = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      setLoadingStatus(true);
      await api
        .get(`/persons/provider/src?search=${e.query}`)
        .then(({ data }) => {
          const opts = data.providers.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          });

          if (opts.length === 0) {
            toast('error', 'Error', 'Nenhum fornecedor encontrado!');
            return;
          }

          setFornecedorOpts(opts);
        })
        .catch((error: any) => {
          toast('error', 'Error', error.response.data.error);
          return;
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      await api
        .get(`/products/v2/search?src=${e.query}`)
        .then(({ data }) => {
          const productsOpts = data.products.map((item: any) => {
            return {
              value: item.id,
              label: item.titulo,
              sku: item.sku,
              stock: item.estoque,
              price_out: item.price_out,
              qnt_out: item.qnt_out,
            };
          });

          if (productsOpts.length <= 0) {
            toast('error', 'Error', 'Nenhum produto encontrado!');
          }

          setProdutosOpts(productsOpts);
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast('warn', 'Alerta', e.response.data.error);
          } else {
            toast('error', 'Erro', e.message);
          }
        });
    }
  };

  const handleProductSelected = async (e: AutoCompleteSelectParams) => {
    const aux: any = e;

    setProduto({
      id: aux.id,
      label: aux.label,
      value: aux.value,
      sku: aux.sku,
    });
  };

  const handleAddItem = () => {
    setItens([...itens, produto]);
    setProduto({ id: '', sku: '', label: '', value: '' });
  };

  const handleRemItem = async (id: string) => {
    const itensNoId = itens.filter(i => i.id !== id);
    setItens(itensNoId);
    setProduto({ id: '', sku: '', label: '', value: '' });
  };

  const handleSubmit = async () => {
    try {
      setLoadingStatus(true);

      let res: AxiosResponse;

      if (filtrarPor === 'P') {
        res = await api.post('/reports/products/stock/now', {
          filterType: filtrarPor,
          providerId: fornecedor.value,
          filterId: itens.map(i => i.value),
        });
      }

      if (filtrarPor === 'G') {
        res = await api.post('/reports/products/stock/now', {
          filterType: filtrarPor,
          providerId: fornecedor.value,
          filterId: grupos.map(i => i),
        });
      }

      if (filtrarPor === 'T') {
        res = await api.post('/reports/products/stock/now', {
          filterType: filtrarPor,
          providerId: fornecedor.value,
          filterId: tags.map(i => i),
        });
      }

      if (filtrarPor === 'B') {
        res = await api.post('/reports/products/stock/now', {
          filterType: filtrarPor,
          providerId: fornecedor.value,
          filterId: marcas.map(i => i),
        });
      }

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Relatório gerado com sucesso!');

        window.open(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
        );
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        toast('error', 'Erro', error.response?.data.error);
        return;
      }
      toast('error', 'Erro', error.errors[0]);
      return;
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleChangeFilter = (e: DropdownChangeParams) => {
    setFiltrarPor(e.value);
    const aux = optionsFilter.find(i => i.value === e.value);
    if (aux) setTableTitle(aux.label);
    setItens([]);
    setProduto({ id: '', sku: '', label: '', value: '' });
    setMarcas([]);
    setTags([]);
    setGrupos([]);
  };

  /** renders */
  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const buttonRemItemTable = (rowId: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItem(rowId)}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        label="Produtos"
        className="p-buton-info mb-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/products/list')}
      />
      <div className="card" style={{ width: '50vw' }}>
        <Form ref={formRef} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="provider_id">Fornecedor</label>
              <InputAutoComplete
                name="provider_id"
                suggestions={fornecedorOpts}
                value={fornecedor}
                completeMethod={e => searchProvider(e)}
                field="label"
                onChange={e => setFornecedor(e.value)}
                itemTemplate={itemTemplateAutoComplete}
                placeholder="Buscar fornecedor..."
              />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="">Filtrar por</label>
              <InputDropDown
                name="contribuinte"
                value={filtrarPor}
                options={optionsFilter}
                placeholder="Selecionar..."
                onChange={e => handleChangeFilter(e)}
                autoFocus={true}
              />
            </div>

            {filtrarPor === 'P' && (
              <>
                <div className="field col-12 md:col-8">
                  <label htmlFor="product_id">Produto</label>
                  <InputAutoComplete
                    name="product_id"
                    value={produto}
                    completeMethod={e => searchProduct(e)}
                    suggestions={produtosOpts}
                    field="label"
                    onSelect={e => handleProductSelected(e.value)}
                    onChange={e => setProduto(e.value)}
                    itemTemplate={itemTemplate}
                    placeholder="Buscar Produto..."
                  />
                </div>
                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    label="Adicionar"
                    icon="fa-solid fa-plus"
                    onClick={() => handleAddItem()}
                    loading={loadingStatus}
                    disabled={
                      produto.value === '' || produto?.value?.length < 3
                        ? true
                        : false
                    }
                  />
                </div>
              </>
            )}

            {filtrarPor === 'G' && (
              <>
                <div className="field col-12 md:col-8 mx-0">
                  <label htmlFor="product_groups">Grupo/Categoria</label>
                  <MultiSelect
                    name="product_groups"
                    options={gruposOpts}
                    value={grupos}
                    placeholder="Selecionar..."
                    onChange={e => setGrupos(e.value)}
                    tooltip={'Categorias do produto'}
                    tooltipOptions={{ position: 'bottom' }}
                    display="chip"
                  />
                </div>
                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    label="Adicionar"
                    icon="fa-solid fa-plus"
                    onClick={() => handleAddItem()}
                    loading={loadingStatus}
                    disabled={grupos.length === 0 ? true : false}
                  />
                </div>
              </>
            )}

            {filtrarPor === 'T' && (
              <>
                <div className="field col-12 md:col-8 mx-0">
                  <label htmlFor="product_tags">Tag</label>
                  <MultiSelect
                    name="product_tags"
                    options={tagsOpts}
                    value={tags}
                    placeholder="Selecionar..."
                    onChange={e => setTags(e.value)}
                    tooltip={'Tags do produto'}
                    tooltipOptions={{ position: 'bottom' }}
                    display="chip"
                  />
                </div>
                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    label="Adicionar"
                    icon="fa-solid fa-plus"
                    onClick={() => handleAddItem()}
                    loading={loadingStatus}
                    disabled={tags.length === 0 ? true : false}
                  />
                </div>
              </>
            )}

            {filtrarPor === 'B' && (
              <>
                <div className="field col-12 md:col-8 mx-0">
                  <label htmlFor="product_brands">Marcas</label>
                  <MultiSelect
                    name="product_brands"
                    options={marcasOpts}
                    value={marcas}
                    placeholder="Selecionar..."
                    onChange={e => setMarcas(e.value)}
                    tooltip={'Marcas do produto'}
                    tooltipOptions={{ position: 'bottom' }}
                    display="chip"
                  />
                </div>
                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    label="Adicionar"
                    icon="fa-solid fa-plus"
                    onClick={() => handleAddItem()}
                    loading={loadingStatus}
                    disabled={marcas.length === 0 ? true : false}
                  />
                </div>
              </>
            )}
          </div>

          {filtrarPor === 'P' && (
            <div className="p-fluid grid formgrid">
              <DataTable
                value={itens}
                responsiveLayout="scroll"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage={`Itens não informados...`}
                selectionMode="single"
                className="field col-12 md:col-12"
                loading={loadingStatus}
              >
                <Column field="sku" header="Sku" sortable></Column>
                <Column field="label" header={tableTitle} sortable></Column>
                <Column
                  body={row => buttonRemItemTable(row.id)}
                  className="p-1"
                ></Column>
              </DataTable>
            </div>
          )}

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <Button
                label="IMPRIMIR"
                className="p-button-success"
                icon="fa-solid fa-print"
                loading={loadingStatus}
                onClick={() => handleSubmit()}
                disabled={itens.length === 0}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportCurrentCount;
