import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText as InputTe } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import InventoryHeaderButtons from '../header-buttons';
import { IInventoryList } from './dtos/IInventoryList';
import { IInventoryItem } from './dtos/IIventoryItem';

const InventoryDigitation = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [inventoryOpts, setInventoryOpts] = useState<IOptionsDTO[]>([]);
  const [inventory, setInventory] = useState<string>('');

  const [inventoryItems, setInventoryItems] = useState<IInventoryItem[]>([]);

  const [openDate, setOpenDate] = useState<Date>();
  const [seqIni, setSeqIni] = useState<number>(0);
  const [seqFim, setSeqFim] = useState<number>(0);

  const [ordenationOpts, setOrdenationOpts] = useState<IOptionsDTO[]>([
    {
      label: 'SKU do Produto',
      value: 'ID',
    },
    {
      label: 'Descrição do produto',
      value: 'DE',
    },
    {
      label: 'Sequencia de inserção',
      value: 'SE',
    },
  ]);
  const [ordenation, setOrdenation] = useState<string>('');
  const [teamCount, setTeamCount] = useState<string>('');

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      data.inventory_id = inventory;
      data.team_count = teamCount;

      const schema = Yup.object({
        inventory_id: Yup.string()
          .uuid('Informe um id válido!')
          .required('Informe o inventário!'),
        team_count: Yup.string().required('Informe os envolvidos na contagem!'),
      });
      await schema.validate(data, { abortEarly: false });

      const inv = {
        inventory_id: inventory,
        team_count: teamCount || '',
        items: inventoryItems.map((i: IInventoryItem) => {
          return {
            sequence: i.sequence as number,
            product_id: i.product_id as string,
            qnt_contada: i.qnt_contada as number,
          };
        }),
      };

      const res = await api.put('/inventory/set/count', inv);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', `Inventário editado com sucesso!`);

        navigate.push('/inventory/encerramento');

        handleClear();
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSelectInventory = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Informe um id valido para o inventário!');
      return;
    }

    try {
      // buscar inventário na api
      const res = await api.get(`/inventory/full/${id}`);

      if (res.status >= 200 || res.status <= 299) {
        const { data } = res;

        if (data.items.length === 0) {
          toast(
            'warn',
            'Alerta',
            'Nenhum produto foi encontrado relacionado ao inventário!',
          );
          return;
        }

        setInventory(data.id);
        setOpenDate(new Date(data.date_open));
        setSeqIni(data.seq_inicial);
        setSeqFim(data.seq_final);
        setOrdenation(data.order_by);
        setTeamCount(data.obs || 'Não informado...');

        setInventoryItems(data.items);
      }
      // setar dados do cabeçalho do inventário
      // setar dados do corpo do inventário
    } catch (e: any) {
      toast('error', 'Erro', e.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleEditItemQntEdit = (id: string, qnt: number) => {
    const cloneInventoryItems = inventoryItems;
    const indexOfItem = cloneInventoryItems.findIndex(
      (i: IInventoryItem) => i.id === id,
    );
    cloneInventoryItems[indexOfItem].qnt_contada = Number(qnt);
    setInventoryItems(cloneInventoryItems);
  };

  const handleSetQntZeroForAll = () => {
    const newInventoryItems: Array<IInventoryItem> = [];

    for (const item of inventoryItems) {
      item.qnt_contada = 0;
      newInventoryItems.push(item);
    }
    setInventoryItems(newInventoryItems);
  };

  const handleClear = () => {
    setLoadingStatus(false);
    setInventory('');
    setInventoryItems([]);
    setOpenDate(undefined);
    setSeqIni(0);
    setSeqFim(0);
    setOrdenation('');
    setTeamCount('');
  };

  const renderColumnEditQnt = (rowData: IInventoryItem) => {
    return (
      <InputNumber
        name={`${rowData.id}`}
        value={rowData.qnt_contada}
        onChange={e => handleEditItemQntEdit(rowData.id, Number(e.value))}
        minFractionDigits={2}
        maxFractionDigits={2}
        placeholder="0,00"
      />
    );
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/inventory/li/short/opened')
      .then(({ data }) => {
        const opts = data.map((i: IInventoryList) => {
          return {
            value: i.id,
            label: `INVENTÁRIO [${i.num_sequencia}] ABERTO - ${format(
              new Date(i.date_open),
              'dd/MM/yy H:mm',
            )}`,
          };
        });

        setInventoryOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Erro', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <InventoryHeaderButtons />
      <Form ref={formRef} onSubmit={handleSubmit}>
        {loadingStatus && (
          <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
        )}
        <div className="card">
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Lote do inventário</label>
              <InputDropDown
                name="inventory_id"
                options={inventoryOpts}
                placeholder="Selecionar..."
                value={inventory}
                onChange={e => handleSelectInventory(e.value)}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Data abertura</label>
              <Calendar
                value={openDate}
                dateFormat="d/m/yy"
                disabled
                placeholder="00/00/00"
                className="surface-200"
                showIcon
              />
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor="dropdown">Seq. Ini.</label>
              <InputTe disabled className="surface-200" value={seqIni} />
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor="dropdown">Seq. Fin.</label>
              <InputTe disabled className="surface-200" value={seqFim} />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Ordenar por</label>
              <Dropdown
                options={ordenationOpts}
                value={ordenation}
                placeholder="Selecionar..."
                onChange={e => setOrdenation(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Equipe contagem</label>
              <InputText
                name="team_count"
                placeholder="Ex.: Joao e Maria"
                value={teamCount}
                onChange={e => setTeamCount(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3 flex align-items-end">
              <Button
                label="Zerar todos"
                className="p-button-raised p-button-danger"
                type="button"
                onClick={() => handleSetQntZeroForAll()}
              />
            </div>
            <div className="field col-12 md:col-3 flex align-items-end">
              <Button
                label="Limpar"
                className="p-button-raised p-button-danger"
                type="reset"
                onClick={() => handleClear()}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <DataTable
                value={inventoryItems}
                responsiveLayout="scroll"
                emptyMessage="Nenhuma opção"
                loading={loadingStatus}
                paginator
                rows={100}
                rowsPerPageOptions={[100, 150, 200]}
                size="small"
              >
                <Column field="sequence" header="SEQ" sortable></Column>
                <Column field="product.sku" header="SKU" sortable></Column>
                <Column
                  field="product.title"
                  header="Descrição"
                  sortable
                ></Column>
                <Column field="product.unity" header="UND"></Column>
                <Column
                  field="product.stock"
                  header="Estoque"
                  body={e => renderColumnDecimal(e.product.stock)}
                ></Column>
                <Column
                  field=""
                  header="Qnt. Contada"
                  body={e => renderColumnEditQnt(e)}
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar digitação"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default InventoryDigitation;
