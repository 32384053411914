import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** typing */
import { ApplicationModel } from '../../../types/application';

/** api */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** empty application */
const emptyApplication: ApplicationModel = {
  id: '',
  description: '',
  sigla: '',
  status: '',
  title: '',
};

const ApplicationList = (): React.ReactElement => {
  /** state */
  const [applications, setApplications] = useState<Array<ApplicationModel>>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [deleteAdditionalDialog, setDeleteApplicationtDialog] = useState(false);
  const [application, setApplication] =
    useState<ApplicationModel>(emptyApplication);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  /** delete dialog */
  const hideDeleteApplicationDialog = () => {
    setDeleteApplicationtDialog(false);
  };

  const deleteApplicationRequest = async () => {
    try {
      await api.delete(`/applications/${application.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteApplication = () => {
    const _applications = applications.filter(val => val.id !== application.id);
    setApplications(_applications);
    setDeleteApplicationtDialog(false);
    setApplication(emptyApplication);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Aplicação Deletada',
      life: 3000,
    });
    deleteApplicationRequest();
  };

  const deleteApplicationDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteApplicationDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteApplication}
      />
    </React.Fragment>
  );

  const confirmDeleteApplication = (application: ApplicationModel) => {
    setApplication(application);
    setDeleteApplicationtDialog(true);
  };

  /** table layout */

  const codeBodyTemplate = (rowData: ApplicationModel, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const statusBodyTemplate = (rowData: ApplicationModel) => {
    return rowData.status === 'A' ? (
      <i className="fa-solid fa-check"></i>
    ) : (
      <i className="fa-solid fa-xmark"></i>
    );
  };

  const actionBodyTemplate = (rowData: ApplicationModel) => {
    return (
      <div className="flex justify-content-end">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/applications/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteApplication(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Aplicações</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchApplication = async () => {
    try {
      const { data } = await api.get('applications');
      setApplications(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchApplication();
  }, []);

  return (
    <>
      <Button
        label="Nova"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/applications/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={applications}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} aplicações"
          globalFilter={globalFilter}
          emptyMessage="Não existe aplicações."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="id"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>

          <Column field="title" header="Título"></Column>
          <Column field="sigla" header="Sigla"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteAdditionalDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteApplicationDialogFooter}
        onHide={hideDeleteApplicationDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {application && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{application?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ApplicationList;
