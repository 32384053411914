import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import format from 'date-fns/format';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import ICCeDTO from './dtos/ICCeDTO';

interface IFastFormProps {
  id: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export function NfeCCeForm({ id, isOpen, onRequestClose }: IFastFormProps) {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const hoje = new Date();

  const [description, setDescription] = useState<string>('');
  const [dataCCe, setDataCCe] = useState<ICCeDTO>();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const findCCeByNfeId = async (id: string) => {
    try {
      setLoadingStatus(true);

      if (validate(id)) {
        await api.get(`/nfe/has/cce/${id}`).then(({ data }) => {
          setDataCCe(data);
        });
      }
    } catch (e: any) {
      toast('warn', 'Alerta', e.response.data.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const refreshStatusCCe = async (id: string) => {
    try {
      setLoadingStatus(true);
      if (validate(id)) {
        await api.get(`/nfe/cce/status/${id}`);

        findCCeByNfeId(id);
      }
    } catch (e: any) {
      toast('warn', 'Alerta', e.response.data.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const downloadPdf = async (url: string) => {
    setLoadingStatus(true);
    const headers = { 'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316' };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'webger-cce-pdf';
        a.click();
        window.open(url);
      })
      .catch(() => toast('error', 'Error', 'Erro ao exibir nfe!'))
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  const downloadXml = async (url: string) => {
    setLoadingStatus(true);
    const headers = { 'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316' };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'webger-cce-xml';
        a.click();
        window.open(url);
      })
      .catch(() => toast('error', 'Error', 'Erro ao exibir nfe!'))
      .finally(() => setLoadingStatus(false));
  };

  const handleSubmit = async () => {
    try {
      setLoadingStatus(true);

      if (description.length <= 15) {
        toast(
          'warn',
          'Alerta',
          'A descrição da carta de correção eletrônica deve ter no mínimo 15 caracteres!',
        );
        return;
      }

      const res = await api.post(`/nfe/cce/${id}`, {
        correcao: description.replace(/(\r\n|\n|\r)/gm, ' '),
      });

      if (res.status >= 200 || res.status <= 299) {
        toast(
          'success',
          'Sucesso',
          'Carta de correção eletrônica enviada com sucesso! Aguarde um momento para a Sefaz processar o arquivo.',
        );
        setDescription('');
      }
    } catch (e: any) {
      toast('error', 'Erro', e.response.data.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    findCCeByNfeId(id);
  }, [id]);

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => onRequestClose()}
      style={{ width: '50vw' }}
    >
      <h2>Emitir carta de correção CCe</h2>
      <Form
        ref={formRef}
        onSubmit={() => handleSubmit()}
        className="p-fluid grid formgrid"
        placeholder={''}
      >
        <div className="field col-12 text-900 font-bold">
          Destinatário.: {dataCCe?.customer}
        </div>
        <div className="field col-4">
          Emissão.: {format(new Date(dataCCe?.emited || hoje), 'dd/MM/yy')}
        </div>
        <div className="field col-4">
          Status.: <Tag severity="success" value={dataCCe?.status}></Tag>
        </div>
        <div className="field col-4">
          Valor.:{' '}
          {new Intl.NumberFormat('BRL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(dataCCe?.value as number)}
        </div>

        <Divider />
        {dataCCe?.sefaz_response && (
          <div className="field col-12">
            Resposta Sefaz.: {dataCCe?.sefaz_response || ''}
          </div>
        )}

        <div className="field col-4 text-center">
          <Button
            label="Atualizar"
            className="custom-choose-btn p-button-rounded justify-content-center bg-blue-500 border-blue-500 hover:bg-blue-800"
            iconPos="left"
            icon="fa-solid fa-arrows-rotate"
            loading={loadingStatus}
            onClick={() => refreshStatusCCe(id)}
            type="button"
          />
        </div>

        {dataCCe?.url_pdf !== null && (
          <div className="field col-4 text-center">
            <Button
              label="Baixar .pdf"
              className="custom-choose-btn p-button-rounded justify-content-center bg-bluegray-300 border-bluegray-300 hover:bg-bluegray-500"
              iconPos="left"
              icon="fa-solid fa-file-pdf"
              loading={loadingStatus}
              type="button"
              onClick={() => downloadPdf(dataCCe?.url_pdf as string)}
            />
          </div>
        )}

        {dataCCe?.url_xml !== null && (
          <div className="field col-4 text-center">
            <Button
              label="Baixar .xml"
              className="custom-choose-btn p-button-rounded justify-content-center bg-bluegray-300 border-bluegray-300 hover:bg-bluegray-500"
              iconPos="left"
              icon="fa-solid fa-file-code"
              loading={loadingStatus}
              type="button"
              onClick={() => downloadXml(dataCCe?.url_xml as string)}
            />
          </div>
        )}

        <Divider />

        <div className="field col-12 md:col-12">
          <label htmlFor="correcao">
            Correção ({description.length}) caracteres
          </label>
          <InputTextArea
            name="correcao"
            style={{ height: '15vh' }}
            placeholder="Ex.: A quantidade correta de volumes é 999"
            value={
              dataCCe?.status === 'CONCLUIDO'
                ? (dataCCe.description as string)
                : (description as string)
            }
            onChange={e => setDescription(e.currentTarget.value)}
            readOnly={dataCCe?.status === 'CONCLUIDO'}
            rows={5}
            cols={30}
          />
        </div>
        <div className="field col-12 md:col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            className="p-button-danger"
            loading={loadingStatus}
          />
        </div>

        <div className="field col-12 md:col-6">
          <Button
            label="Salvar"
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={loadingStatus}
            disabled={dataCCe?.status === 'CONCLUIDO' ? true : false}
          />
        </div>
      </Form>
    </SideBar>
  );
}
