import React from 'react';

/* styled */
import * as S from './styled';

/* components */

type PropTabs = {
  enable: number;
  enableBefore: boolean;
  index: number;
  children: React.ReactNode;
  setTabEnable: React.Dispatch<React.SetStateAction<number>>;
};

export const Tabs: React.FC<PropTabs> = ({
  index,
  children,
  setTabEnable,
  enable,
  enableBefore,
}) => {
  const activeButton = () => {
    setTabEnable(index);
  };

  return (
    <S.Tabs
      className={`w-4 p-1 text-l font-bold  ${index === enable ? 'bg-primary' : ''
        } mr-2`}
      onClick={activeButton}
      classEnabled={index === enable}
      enableBefore={enableBefore}
    >
      {children}
    </S.Tabs>
  );
};
