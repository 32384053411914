import { v4 } from 'uuid';
import { ITablePrice } from '../../tablePrice/dtos/ITablePriceDTO';

export interface IProductPrice {
  id: string;
  table_price_id: string;
  tablePrice: ITablePrice | undefined;
  product_id: string;
  product: any;
  vlr_venda: number;
  vlr_venda_min_s_lib: number;
  vlr_venda_min_c_lib: number;
  qnt_venda_min: number;
  qnt_venda_max: number;
  margin: number;
  lucre: number;
  comissao: number;
}

export const emptyProductPrice: IProductPrice = {
  id: v4(),
  table_price_id: '',
  tablePrice: undefined,
  product_id: '',
  product: {
    id: '',
    title: '',
    stock: 0,
  },
  vlr_venda: 0,
  vlr_venda_min_s_lib: 0,
  vlr_venda_min_c_lib: 0,
  qnt_venda_min: 1,
  qnt_venda_max: 1000,
  margin: 0,
  lucre: 0,
  comissao: 0,
};
