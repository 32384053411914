import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../../../components/DataTableColumns/RenderColumnShortName';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import MenuPopUp from '../../../components/MenuPopPup';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { IBillsToPayList } from '../../bills-to-pay/dtos/IBillsToPay';
import { ModalCreateBillPayable } from '../../bills-to-pay/modal-create-bill';
import IInputProducts from '../dtos/IInputProducts';
import InputProductsHeader from '../input-products-header';
import messageRequestError from '../../../utils/messageRequestError';
import { AxiosResponse } from 'axios';

const InputProductsList = (): React.ReactElement => {
  /** const */
  /** hooks */
  const toast = useToastContext();

  const hoje = new Date();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [paymentData, setPaymentData] = useState<Partial<IBillsToPayList>>();
  const [inputProducts, setInputProducts] = useState<Array<IInputProducts>>([]);

  const [paymentModalVisible, setPaymentModalVisible] =
    useState<boolean>(false);

  /** functions */
  const listInputProducts = async () => {
    setLoadingStatus(true);
    await api
      .get('/input-products')
      .then(({ data }) => {
        setInputProducts(data);
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          'Falha ao listar as entradas! Procure o suporte!',
        );
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  const cancelInputProducts = async (id: string) => {
    setLoadingStatus(true);
    await api
      .delete(`/input-products/id/${id}`)
      .then(async data => {
        if (data.status >= 200 && data.status <= 299) {
          toast('success', 'Sucesso', 'A entrada foi cancelada com sucesso!');
          await listInputProducts();
        }
      })
      .catch((e: AxiosResponse) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => setLoadingStatus(false));
  };

  /** render */
  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Entrada de Produto</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const renderColumnOptions = (row: IInputProducts) => {
    const items = [
      {
        label: 'Gerar conta a pagar',
        icon: 'fa-solid fa-barcode',
        command: () => {
          setPaymentData({
            provider_id: row.provider_id,
            provider_name: row.provider,
            input_product_id: row.id,
            value: Number(row.value),
            ser: row.serie,
            number: row.number,
          });
          setPaymentModalVisible(true);
        },
      },
      {
        label: 'Cancelar',
        icon: 'fa-solid fa-xmark',
        command: () => {
          cancelInputProducts(row.id);
        },
      },
    ];

    return (
      <MenuPopUp
        disable={row.status === 'C'}
        model={items}
        rowData={row}
        setData={setPaymentData}
      />
    );
  };

  /** useEffect */
  useEffect(() => {
    listInputProducts();
  }, []);

  return (
    <>
      <InputProductsHeader />
      <div className="card">
        <DataTable
          value={inputProducts}
          dataKey="id"
          paginator
          rows={10}
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} entradas"
          globalFilter={globalFilter}
          emptyMessage="Não existem entradas."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="id" header="#" body={renderColumnPosition}></Column>
          <Column field="provider" header="Fornecedor" sortable></Column>
          <Column
            field="date_in"
            header="Data"
            sortable
            body={e => renderColumnDate(e.date_in)}
          ></Column>
          <Column header="Nat. Ope" field="nat_ope" sortable></Column>
          <Column field="serie" header="Ser." align={'center'}></Column>
          <Column field="number" header="Num." align={'center'}></Column>
          <Column
            header="Valor"
            body={r => renderColumnDecimal(r.value)}
            align={'right'}
          ></Column>
          <Column
            header="Status"
            body={r => renderColumnStatus(r)}
            align={'center'}
            sortable
          ></Column>
          <Column
            header="Usuário"
            body={r => renderColumnShortName(r.user)}
            sortable
          ></Column>

          <Column header="*" body={r => renderColumnOptions(r)}></Column>
        </DataTable>
        <ModalCreateBillPayable
          isOpen={paymentModalVisible}
          onRequestClose={() => setPaymentModalVisible(false)}
          data={paymentData}
        />
      </div>
    </>
  );
};

export default InputProductsList;
