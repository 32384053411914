import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';

interface Iprops {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
  isOrder: boolean;
}

const ConsignedDevolutionCheck = ({
  isOpen,
  onRequestClose,
  id,
  isOrder,
}: Iprops): React.ReactElement => {
  const hoje = new Date();

  const [load, setLoad] = useState<boolean>(false);

  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const skuRef = useRef<HTMLInputElement>(null);
  const qntRef = useRef<HTMLInputElement>(null);
  const router = useHistory();

  interface IData {
    route: number;
    sequence: number;
    customer: string;
    date_digitation: Date;
    total: number;
  }
  const [data, setData] = useState<IData>({
    route: 0,
    sequence: 0,
    customer: '',
    date_digitation: hoje,
    total: 0,
  });

  const [sku, setSku] = useState<number>(0);
  const [qnt, setQnt] = useState<number>(0);

  const [skuFocus, setSkuFocus] = useState(false);
  const [qntFocus, setQntFocus] = useState(false);

  interface IItems {
    id: string;
    pos: number;
    sku: string;
    product_name: string;
    qnt: number;
    vlr_liq: number;
    total: number;
    status: boolean | null;
  }
  const [itemsOriginal, setItemsOriginal] = useState<IItems[]>([]);
  const [itemsDigited, setItemsDigited] = useState<any[]>([]);

  const [isChecked, setIsChecked] = useState(false);

  const loadOrder = async () => {
    if (validate(id)) {
      setLoad(true);
      await api
        .get(`/consigned/invoice/${id}`)
        .then(({ data }) => {
          if (data) {
            setData({
              route: data.routeOfOrder.sequence,
              sequence: data.sequence,
              customer: data.customer.name,
              date_digitation: new Date(data.date_digitation),
              total: Number(data.order_total),
            });

            setItemsOriginal(
              data.items.map((i: any) => {
                return {
                  id: i.id,
                  pos: i.pos,
                  sku: i.product.sku,
                  product_name: i.product.title,
                  qnt: Number(i.qnt),
                  vlr_liq: Number(i.vlr_liq),
                  total: Number(i.total),
                };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  const loadDevolution = async () => {
    if (validate(id)) {
      setLoad(true);
      await api
        .get(`/consigned/devolution/${id}`)
        .then(({ data }) => {
          if (data) {
            setData({
              route: data.route.sequence,
              sequence: data.sequence,
              customer: data.customer.name,
              date_digitation: new Date(data.date_digitation),
              total: Number(data.total),
            });

            setItemsOriginal(
              data.items.map((i: any) => {
                return {
                  id: i.id,
                  pos: i.pos,
                  sku: i.product.sku,
                  product_name: i.product.title,
                  qnt: Number(i.qnt),
                  vlr_liq: Number(i.vlr_liq),
                  total: Number(i.total),
                };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  const handleAddItem = () => {
    if (sku.toString().length < 3) {
      toast('warn', 'Alerta', 'SKU deve ter mais de 3 caracteres!');
      setSkuFocus(true);
      skuRef?.current?.focus();
      return;
    }

    if (qnt <= 0) {
      toast('warn', 'Alerta', 'Qnt. deve ser maior que 0,00');
      setQntFocus(true);
      qntRef?.current?.focus();
      return;
    }

    const item = {
      id: v4(),
      pos: itemsDigited.length + 1,
      sku: sku,
      qnt: qnt,
      status: null,
    };

    setItemsDigited([...itemsDigited, item]);

    clearRow();

    setQntFocus(false);
    setSkuFocus(true);
    skuRef?.current?.focus();
  };

  const handleRemItem = (id: string) => {
    const noId = itemsDigited.filter((i: any) => i.id !== id);
    setItemsDigited([...noId]);
  };

  function handleCheckItems(
    itemsDigitacao: any[],
    itemsOriginal: any[],
  ): any[] {
    if (itemsDigitacao.length === 0) {
      setIsChecked(false);
      return [];
    }

    const copyOriginal: any[] = [...itemsOriginal];

    const res = itemsDigitacao.map(itemDig => {
      const indexItemOri = copyOriginal.findIndex(
        itemOri =>
          itemOri.sku.toString() === itemDig.sku.toString() &&
          Number(itemOri.qnt) === Number(itemDig.qnt),
      );

      if (indexItemOri !== -1) {
        copyOriginal.splice(indexItemOri, 1);
      }

      return { ...itemDig, status: indexItemOri !== -1 };
    });

    const hasFail = res.find(
      (i: any) => i.status === false || i.status === null,
    );
    if (!hasFail) {
      setIsChecked(true);
    }

    if (itemsDigitacao.length !== itemsOriginal.length) {
      setIsChecked(false);
    }

    return res;
  }

  const handleSubmit = async (d: any) => {
    const reg = { ...data };

    if (isChecked === false) {
      toast(
        'warn',
        'Alerta',
        'Não é possível editar o item para conferido quando a opção "Não bateu" está selecionada!',
        10000,
      );
      return;
    }

    if (validate(id)) {
      if (isOrder === true) {
        setLoad(true);
        await api
          .put(`/consigned/invoice/check/${id}`)
          .then(({ data }) => {
            if (data) {
              toast(
                'success',
                'Sucesso',
                `O pedido.: ${reg.sequence} foi marcado como conferido!`,
              );
              clearForm();
              setData({} as IData);
              onRequestClose();
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setLoad(false));
      } else {
        setLoad(true);
        await api
          .put(`/consigned/devolution/check/${id}`)
          .then(({ data }) => {
            if (data) {
              toast(
                'success',
                'Sucesso',
                `A devolução.: ${reg.sequence} foi marcada como conferida!`,
              );
              clearForm();
              setData({} as IData);
              onRequestClose();
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setLoad(false));
      }
    }
  };

  const clearRow = () => {
    setSku(0);
    setQnt(0);
  };

  const clearForm = () => {
    clearRow();
    setItemsDigited([]);
    setItemsOriginal([]);
    setIsChecked(false);
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-xmark"
          className="p-button-danger p-button-rounded"
          onClick={() => handleRemItem(id)}
        />
      </div>
    );
  };

  const renderColumnStatus = (status: boolean | null) => {
    if (status === null) {
      return (
        <i
          className="fa-solid fa-circle-question text-blue-500"
          style={{ fontSize: '1.5rem' }}
        ></i>
      );
    } else if (status === true) {
      return (
        <i
          className="fa-solid fa-circle-check text-green-500"
          style={{ fontSize: '1.5rem' }}
        ></i>
      );
    } else {
      return (
        <i
          className="fa-solid fa-circle-xmark text-pink-600"
          style={{ fontSize: '1.5rem' }}
        ></i>
      );
    }
  };

  const headerOriginal = (
    <h3>
      Itens originais ~[Itens.: {itemsOriginal.length} / Produtos.: {0}]
    </h3>
  );
  const headerDigited = (
    <h3>
      Itens digitados ~ [Itens.: {itemsDigited.length} / Produtos.: {0}]
    </h3>
  );

  useEffect(() => {
    if (validate(id)) {
      if (isOrder) {
        loadOrder();
      } else {
        loadDevolution();
      }
    }
  }, [isOpen]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        style={{ width: '80vw' }}
      >
        <h3>Conferência de itens {isOrder ? 'do Pedido' : 'da Devolução'}</h3>
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
        >
          <Divider />
          <div className="field col-2">
            <label htmlFor="sequence_route">Rota</label>
            <InputText
              name="sequence_route"
              value={data.route}
              placeholder="000"
              readOnly={true}
              disabled={true}
              className="surface-200 text-center"
            />
          </div>
          <div className="field col-2">
            <label htmlFor="sequence">{isOrder ? 'Pedido' : 'Devolução'}</label>
            <InputText
              name="sequence"
              value={data.sequence}
              placeholder="000"
              readOnly={true}
              disabled={true}
              className="surface-200 text-center"
            />
          </div>
          <div className="field col-4">
            <label htmlFor="customer">Cliente</label>
            <InputText
              name="customer"
              value={data.customer}
              placeholder="Ex.: João da Silva"
              readOnly={true}
              disabled={true}
              className="surface-200"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="date_digitation">Data digitação</label>
            <Calendar
              name="date_digitation"
              value={data.date_digitation}
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              showIcon
              iconPos="right"
              readOnlyInput
              disabled
              className="surface-200"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="total">Total</label>
            <InputNumber
              name="total"
              value={data.total}
              mode="decimal"
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              readOnly
              disabled
              className="surface-200"
            />
          </div>

          <Divider align="left" type="dashed" className="mt-0">
            <div className="inline-flex align-items-center">
              <span className="p-tag">DIGITAÇÃO</span>
            </div>
          </Divider>

          <div className="field col-3">
            <label htmlFor="sku">Sku</label>
            <InputNumber
              inputRef={skuRef}
              name="sku"
              value={sku}
              onChange={e => {
                setSku(Number(e.value));
              }}
              placeholder="Inserir código + Enter"
              autoFocus={skuFocus}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setQntFocus(true);
                  qntRef?.current?.focus();
                }
              }}
              aria-describedby="sku"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="qnt">Quantidade</label>
            <InputNumber
              inputRef={qntRef}
              name="qnt"
              mode="decimal"
              minFractionDigits={3}
              placeholder="0,00"
              maxFractionDigits={3}
              value={qnt}
              onChange={e => setQnt(Number(e.value))}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddItem();
                }
              }}
              onFocus={e => e.target.select()}
              autoFocus={qntFocus}
            />
          </div>

          <div className="field col-1 flex relative align-items-center justify-content-start">
            <div className="flex mb-2">
              <Button
                type="submit"
                loading={load}
                className="p-button-success flex absolute"
                icon="fa-solid fa-plus"
              />
            </div>
          </div>

          <div className="field col-6"></div>

          <DataTable
            header={headerDigited}
            value={itemsDigited}
            responsiveLayout="scroll"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 150]}
            size="small"
            emptyMessage="Itens não digitados..."
            selectionMode="single"
            loading={load}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} pagamentos"
            className="field col-6"
            footerColumnGroup={null}
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="SKU" field="sku"></Column>
            <Column
              header="QNT."
              body={r => renderColumnDecimal(r.qnt)}
            ></Column>
            <Column
              header="Status"
              field="status"
              body={r => renderColumnStatus(r.status)}
            ></Column>

            <Column header="*" body={e => renderCollumnMenu(e.id)}></Column>
          </DataTable>

          <DataTable
            header={headerOriginal}
            value={itemsOriginal}
            responsiveLayout="scroll"
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 150]}
            size="small"
            emptyMessage="Itens não carregados..."
            selectionMode="single"
            loading={load}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} pagamentos"
            className="field col-6"
            footerColumnGroup={null}
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="SKU" field="sku"></Column>
            <Column header="Produto" field="product_name"></Column>
            <Column
              header="Qnt."
              body={r => renderColumnDecimal(r.qnt)}
            ></Column>
            <Column
              header="Vlr."
              body={r => renderColumnDecimal(r.vlr_liq)}
            ></Column>
            <Column
              header="Total"
              body={r => renderColumnDecimal(r.total)}
            ></Column>
          </DataTable>

          <div className="field col-4"></div>
          <div className="field col-4">
            <div className="flex justify-content-between py-2">
              <span className="checked">Não bateu</span>
              <InputSwitch
                name="checked"
                checked={isChecked}
                onChange={e => setIsChecked(e.value)}
              />
              <span className="">Sim bateu</span>
            </div>
          </div>
          <div className="field col-4"></div>

          <Divider />

          <div className="field col-12 md:col-4">
            <Button
              label="Limpar"
              type="reset"
              icon="fa-solid fa-xmark"
              className="p-button-danger"
              onClick={() => null}
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-4">
            <Button
              label="Comparar"
              type="button"
              icon="fa-solid fa-refresh"
              className="p-button-info"
              onClick={() => {
                setItemsDigited(handleCheckItems(itemsDigited, itemsOriginal));
              }}
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-4">
            <Button
              label="Salvar"
              icon="fa-solid fa-check"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </Form>
      </SideBar>
    </>
  );
};

export default ConsignedDevolutionCheck;
