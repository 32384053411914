import { v4 } from 'uuid';

export interface IOsStatus {
  id: string;
  b_id: string;
  position: number;
  title: string;
  is_last: boolean;
  field_color: string;
  user_id: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export const emptyOsStatus: IOsStatus = {
  id: v4(),
  b_id: '',
  position: 0,
  title: '',
  is_last: false,
  field_color: '',
  user_id: '',
  status: '',
  created_at: '',
  updated_at: '',
};
