import React, { useContext } from 'react';

/* components */
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';

/** context */
import { AppSettingContext } from '../../../context/AppSettingContext';

/* props */

type PropsAppConfig = {
  configActive: boolean;
  componentTheme: string;
  menuMode: string;
  onMenuModeChange: (e: any) => void;
  changeColorScheme: (e: any) => void;
  colorScheme: string;
  changeMenuTheme: (
    name: string,
    logoColor: string,
    componentTheme: string,
  ) => void;
  menuTheme: string;
  changeComponentTheme: (theme: string) => void;
  onConfigClick: () => void;
  inputStyle: string;
  onConfigButtonClick: () => void;
  rippleActive: boolean;
  onRippleChange: (e: any) => void;
  onInputStyleChange: (inputStyle: string) => void;
};

const AppConfig: React.FC<PropsAppConfig> = props => {
  const menuThemes = [
    {
      name: 'white',
      color: '#ffffff',
      logoColor: 'dark',
      componentTheme: props.componentTheme,
    },
    {
      name: 'darkgray',
      color: '#343a40',
      logoColor: 'white',
      componentTheme: props.componentTheme,
    },
    {
      name: 'blue',
      color: '#1976d2',
      logoColor: 'white',
      componentTheme: 'blue',
    },
    {
      name: 'bluegray',
      color: '#455a64',
      logoColor: 'white',
      componentTheme: 'lightgreen',
    },
    {
      name: 'brown',
      color: '#5d4037',
      logoColor: 'white',
      componentTheme: 'cyan',
    },
    {
      name: 'cyan',
      color: '#0097a7',
      logoColor: 'white',
      componentTheme: 'cyan',
    },
    {
      name: 'green',
      color: '#388e3C',
      logoColor: 'white',
      componentTheme: 'green',
    },
    {
      name: 'indigo',
      color: '#303f9f',
      logoColor: 'white',
      componentTheme: 'indigo',
    },
    {
      name: 'deeppurple',
      color: '#512da8',
      logoColor: 'white',
      componentTheme: 'deeppurple',
    },
    {
      name: 'orange',
      color: '#F57c00',
      logoColor: 'dark',
      componentTheme: 'orange',
    },
    {
      name: 'pink',
      color: '#c2185b',
      logoColor: 'white',
      componentTheme: 'pink',
    },
    {
      name: 'purple',
      color: '#7b1fa2',
      logoColor: 'white',
      componentTheme: 'purple',
    },
    {
      name: 'teal',
      color: '#00796b',
      logoColor: 'white',
      componentTheme: 'teal',
    },
  ];

  const componentThemes = [
    { name: 'blue', color: '#42A5F5' },
    { name: 'green', color: '#66BB6A' },
    { name: 'lightgreen', color: '#9CCC65' },
    { name: 'purple', color: '#AB47BC' },
    { name: 'deeppurple', color: '#7E57C2' },
    { name: 'indigo', color: '#5C6BC0' },
    { name: 'orange', color: '#FFA726' },
    { name: 'cyan', color: '#26C6DA' },
    { name: 'pink', color: '#EC407A' },
    { name: 'teal', color: '#26A69A' },
  ];

  const { dispatch } = useContext(AppSettingContext);

  const changeTypeOfMenu = (e: any) => {
    props.onMenuModeChange(e);
    dispatch({ type: 'setMenuType', payload: { menuType: e.value } });
  };

  const changeColorScheme = (e: any) => {
    props.changeColorScheme(e);
    dispatch({ type: 'setColorScheme', payload: { colorScheme: e.value } });
  };

  const changeInputStyle = (e: any) => {
    props.onInputStyleChange(e.value);
    dispatch({ type: 'setInputStyle', payload: { inputStyle: e.value } });
  };

  const changeMenuTheme = (theme: {
    name: string;
    logoColor: string;
    componentTheme: string;
  }) => {
    dispatch({ type: 'setMenuThemes', payload: { menuThemes: theme.name } });
    dispatch({ type: 'setLogoColor', payload: { logoColor: theme.logoColor } });
    dispatch({
      type: 'setComponentThemes',
      payload: { componentThemes: theme.componentTheme },
    });

    props.changeMenuTheme(theme.name, theme.logoColor, theme.componentTheme);
  };

  const onConfigButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    props.onConfigButtonClick(event);
    event.preventDefault();
  };

  const getMenuThemes = () => {
    if (props.colorScheme === 'light') {
      return (
        <div className="layout-themes">
          {menuThemes.map(theme => {
            const checkStyle = theme.name === 'white' ? 'black' : 'white';
            return (
              <div key={theme.name}>
                <button
                  type="button"
                  className="p-link"
                  style={{ cursor: 'pointer', backgroundColor: theme.color }}
                  onClick={() => changeMenuTheme(theme)}
                  title={theme.name}
                >
                  {props.menuTheme === 'layout-sidebar-' + theme.name && (
                    <span className="check flex align-items-center justify-content-center">
                      <i
                        className="pi pi-check"
                        style={{ color: checkStyle }}
                      ></i>
                    </span>
                  )}
                </button>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        <p>
          Os temas de menu estão disponíveis apenas no modo de luz e nos modos
          de menu estático, fino e de sobreposição por design, pois superfícies
          grandes podem emitir muito brilho no modo escuro.
        </p>
      </div>
    );
  };

  const changeComponentTheme = (name: string) => {
    props.changeComponentTheme(name);
    dispatch({
      type: 'setComponentThemes',
      payload: { componentThemes: name },
    });
  };

  const getComponentThemes = () => {
    return (
      <div className="layout-themes">
        {componentThemes.map(theme => {
          return (
            <div key={theme.name}>
              <button
                type="button"
                className="p-link"
                style={{ cursor: 'pointer', backgroundColor: theme.color }}
                onClick={() => changeComponentTheme(theme.name)}
                title={theme.name}
              >
                {props.componentTheme === theme.name && (
                  <span className="check flex align-items-center justify-content-center">
                    <i className="pi pi-check" style={{ color: 'white' }}></i>
                  </span>
                )}
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const componentThemesElement = getComponentThemes();
  const menuThemesElement = getMenuThemes();
  const configClassName = classNames('layout-config', {
    'layout-config-active': props.configActive,
  });
  return (
    <div id="layout-config">
      <Button
        className="layout-config-button"
        icon="pi pi-cog p-button-icon"
        type="button"
        onClick={onConfigButtonClick}
      ></Button>
      <div className={configClassName} onClick={props.onConfigClick}>
        <h5>Tipo de menu</h5>
        <div className="field-radiobutton">
          <RadioButton
            name="menuMode"
            value="static"
            checked={props.menuMode === 'static'}
            inputId="mode1"
            onChange={changeTypeOfMenu}
          ></RadioButton>
          <label htmlFor="mode1">Estático</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="menuMode"
            value="overlay"
            checked={props.menuMode === 'overlay'}
            inputId="mode2"
            onChange={changeTypeOfMenu}
          ></RadioButton>
          <label htmlFor="mode2">Sobreposto</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="menuMode"
            value="slim"
            checked={props.menuMode === 'slim'}
            inputId="mode3"
            onChange={changeTypeOfMenu}
          ></RadioButton>
          <label htmlFor="mode3">Slim</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="menuMode"
            value="horizontal"
            checked={props.menuMode === 'horizontal'}
            inputId="mode4"
            onChange={changeTypeOfMenu}
          ></RadioButton>
          <label htmlFor="mode4">Horizontal</label>
        </div>
        <hr />

        <h5>Esquema de cores</h5>
        <div className="field-radiobutton">
          <RadioButton
            name="colorScheme"
            value="dark"
            checked={props.colorScheme === 'dark'}
            inputId="theme1"
            onChange={changeColorScheme}
          ></RadioButton>
          <label htmlFor="theme1">Escuro</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="colorScheme"
            value="dim"
            checked={props.colorScheme === 'dim'}
            inputId="theme2"
            onChange={changeColorScheme}
          ></RadioButton>
          <label htmlFor="theme2">Dim</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            name="colorScheme"
            value="light"
            checked={props.colorScheme === 'light'}
            inputId="theme3"
            onChange={changeColorScheme}
          ></RadioButton>
          <label htmlFor="theme3">Claro</label>
        </div>

        <hr />

        <h5>Estilo de campos</h5>
        <div className="field-radiobutton">
          <RadioButton
            inputId="input_outlined"
            name="inputstyle"
            value="outlined"
            checked={props.inputStyle === 'outlined'}
            onChange={changeInputStyle}
          />
          <label htmlFor="input_outlined">Delineado</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton
            inputId="input_filled"
            name="inputstyle"
            value="filled"
            checked={props.inputStyle === 'filled'}
            onChange={changeInputStyle}
          />
          <label htmlFor="input_filled">Preenchido</label>
        </div>

        <hr />

        <h5>Efeito Cascata</h5>
        <InputSwitch
          checked={props.rippleActive}
          onChange={props.onRippleChange}
        />

        <hr />

        <h5>Tema Menu</h5>
        {menuThemesElement}

        <hr />

        <h5>Tema Componentes</h5>
        {componentThemesElement}
      </div>
    </div>
  );
};

export default AppConfig;
