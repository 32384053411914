import React, { useRef, useEffect, useState } from 'react';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** service */
import api from '../../../services/api';

/** unform */
import { Form } from '@unform/web';

/** components */
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { FormHandles } from '@unform/core';
import { Toast } from 'primereact/toast';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { ProgressBar } from 'primereact/progressbar';

const SubGroupEdit = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** params */
  const params: { id: string } = useParams();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** toast */
  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      const schemaReports = Yup.object({
        group_id: Yup.string().required('Por favor, informe o grupo!'),
        title: Yup.string().required('Por favor, informe o nome do grupo!'),
      });
      await schemaReports.validate(data, { abortEarly: false });
      await api.put(`/groups/${params.id}`, data);
      navigate.push('/sub-group/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** groups */
  const [groupsOption, setGroupsOption] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  /** description */
  const [description, setDescription] = useState<string | null>('');

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/groups')
      .then(({ data }) => {
        const groupOpt = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setGroupsOption(groupOpt);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get(`/groups/${params.id}`)
      .then(({ data }) => {
        form.current?.setData({
          title: data[0].title,
        });
        setSelectedGroup(data[0].group_id);
        setDescription(data[0].description);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        icon="fa-solid fa-list"
        className="mb-3"
        onClick={() => navigate.push('/sub-group/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Grupo</label>
              <InputDropDown
                name="group_id"
                options={groupsOption}
                value={selectedGroup}
                placeholder="Selecionar..."
                onChange={e => setSelectedGroup(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Nome do Sub Grupo</label>
              <InputText name="title" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Descrição</label>
              <InputTextEdit
                name="description"
                style={{ height: 120 }}
                value={description as string}
                onTextChange={e => setDescription(e.htmlValue)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
                onClick={() => navigate.push('/sub-group/list')}
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SubGroupEdit;
