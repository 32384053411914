import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { addDays, addMonths, addYears } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { InputMask as InputM } from 'primereact/inputmask';
import { InputText as InputT, InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnCpfCnpj from '../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnPositiveNegative from '../../../components/DataTableColumns/RenderColumnPositiveNegative';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import {
  SideBar as ModalSearchCustomer,
  SideBar as ModalSearchDocument,
} from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import PerfectDivision from '../../../utils/perfectDivision';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IClienteDTO from '../../dropBillsReceivable/dtos/IClienteDTO';
import IContaReceberDTO from '../../dropBillsReceivable/dtos/IContaReceberDTO';
import { ICustomerSearchItemDTO } from '../dtos/ICustomerSearchItemDTO';
import BillsToReceivableHeader from '../header';
import KindOfPeriods from '../types/KindOfPeriods';
import { IBillsReceivableRenegotiatedDTO } from './dtos/IBillsReceivableRenegotiatedDTO';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

const BillsToReceivableRenegotiation: React.FC = () => {
  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  /** const */
  const hoje = new Date();

  /** useStates */
  const [loadingGlobal, setLoadingGlobal] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [modalSearchCustomerVisible, setModalSearchCustomerVisible] =
    useState<boolean>(false);
  const [modalSearchDocumentVisible, setModalSearchDocumentVisible] =
    useState<boolean>(false);

  const [customerOpts, setCustomerOpts] = useState<ICustomerSearchItemDTO[]>();
  const [customer, setCustomer] = useState<IClienteDTO>({
    id: '',
    cpf_cnpj: '',
  } as IClienteDTO);
  const [customerKeyword, setCustomerKeyword] = useState<string>('');

  const [occorrenceOpts, setOcorrenceOpts] =
    useState<IOptionsDTO[]>(KindOfPeriods);
  const [occorrence, setOccorrence] = useState<string>('MES');

  const [firstPayment, setFirstPayment] = useState<Date | Date[] | undefined>(
    hoje,
  );

  const [billsReceivableOpts, setBillsReceivableOpts] = useState<
    IContaReceberDTO[]
  >([]);
  const [billReceivable, setBillReceivable] = useState<IContaReceberDTO>(
    {} as IContaReceberDTO,
  );
  const [billsItems, setBillsItems] = useState<IContaReceberDTO[]>([]);

  const [billsRenegotiated, setBillsRenegotiated] = useState<
    IBillsReceivableRenegotiatedDTO[]
  >([]);

  const [ttDocumentos, setTtDocumentos] = useState<number>(0);
  const [ttJuros, setTtJuros] = useState<number>(0);
  const [ttMulta, setTtMulta] = useState<number>(0);
  const [ttAdic, setTtAdic] = useState<number>(0);
  const [ttDesc, setTtDesc] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [parcels, setParcels] = useState<number>(1);

  /** functions */
  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const opts = customers.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            customer: item.customer,
            name: item.name,
            cpf_cnpj: item.cpf_cnpj,
            block: item.block,
            image_url: item.image_url,
            status: item.status,
          };
        });

        setCustomerOpts(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSelectCustomer = (customer: IClienteDTO) => {
    setCustomer(customer);
    setModalSearchCustomerVisible(false);
  };

  const handleModalSearchDocumentVisible = () => {
    setModalSearchDocumentVisible(true);
    searchDocuments(customer.id);
  };

  const searchDocuments = async (customerId: string) => {
    if (customerId) {
      setLoadingDocument(true);
      await api
        .get(`/bills-receivable/v2/receivable/docs/${customerId}`)
        .then(({ data }) => {
          if (data.length === 0) {
            toast(
              'warn',
              'Alerta',
              `Nenhuma conta a receber foi encontrada do cliente [${customer.name}]`,
            );
            return;
          }

          const opts = data.listBillsToReceiveByCustomerId.map(
            (item: IContaReceberDTO) => {
              return {
                id: item.id,
                name: item.name,
                serie: item.serie,
                number: item.number,
                parcel: item.parcel,
                value: item.value,
                value_total: item.value_total,
                duo_date: item.duo_date,
                emited_date: item.emited_date,
                days_delay: item.days_delay,
                juros: item.juros,
                multa: item.multa,
                total: item.total,
              };
            },
          );

          setBillsReceivableOpts(opts);
        })
        .catch((e: any) => {
          toast('error', 'Erro', e.response.data.error);
          return;
        })
        .finally(() => setLoadingDocument(false));
    }
  };

  const handleAddBillToNegotitation = (document: IContaReceberDTO) => {
    const documentInItems = billsItems.find(
      (i: IContaReceberDTO) => i.id === document.id,
    );
    if (documentInItems) {
      toast('warn', 'Alerta', 'O documento já está na lista de renegociação!');
      return;
    }

    setBillsItems([...billsItems, document]);
    setModalSearchDocumentVisible(false);
  };

  const handleRemBillToNegotiation = (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'O id informado é inválido!');
      return;
    }

    const isNoId = billsItems.filter((i: IContaReceberDTO) => i.id !== id);
    setBillsItems(isNoId);
  };

  const handleRecalculate = () => {
    const newParcels = PerfectDivision(total, parcels);
    const renegotiation: Array<IBillsReceivableRenegotiatedDTO> = [];

    let pos: number = 0;
    for (const parcel of newParcels) {
      let overdue: Date = hoje;

      switch (occorrence !== null) {
        case occorrence === 'DIA':
          overdue = addDays(hoje, pos);
          break;
        case occorrence === 'SEM':
          overdue = addDays(hoje, pos * 7);
          break;
        case occorrence === 'QUI':
          overdue = addDays(hoje, pos * 15);
          break;
        case occorrence === 'MES':
          overdue = addMonths(hoje, pos);
          break;
        case occorrence === 'ANO':
          overdue = addYears(hoje, pos);
          break;
        default:
          break;
      }

      renegotiation.push({
        id: v4(),
        customer_id: customer.id,
        serie: 'REN',
        number: `${pos + 1}`,
        parcel: `${pos + 1}#${newParcels.length}`,
        emited_date: hoje,
        duo_date: overdue,
        value: parcel,
      });

      pos++;
    }

    setBillsRenegotiated(renegotiation);
  };

  const handleSubmit = async (d: any) => {
    try {
      setLoadingGlobal(true);
      formRef.current?.setErrors({});

      if (!validate(customer.id)) {
        toast('warn', 'Alerta', 'Informe um cliente na busca!');
        return;
      }

      if (billsItems.length === 0) {
        toast('warn', 'Alerta', 'Informe os documentos a serem renegociados!');
        return;
      }

      if (billsRenegotiated.length === 0) {
        toast('warn', 'Alerta', 'Clique em gerar parcelas!');
        return;
      }

      interface IRenegotiation {
        customer_id: string;
        bills: Array<string>;
        renegotiation: Array<IBillsReceivableRenegotiatedDTO>;
      }

      const data: IRenegotiation = {
        customer_id: customer.id,
        bills: billsItems.map((i: IContaReceberDTO) => {
          return i.id;
        }),
        renegotiation: billsRenegotiated,
      };

      const res = await api.post('/bills-receivable/v2/renegotiation', data);

      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso', 'Documentos renegociados com sucesso!');
        navigate.push('/bills/receive/list');
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingGlobal(false);
    }
  };

  /** render */
  const renderButtonRemBillToList = (id: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-xmark"
          className="p-button-danger"
          onClick={() => handleRemBillToNegotiation(id)}
        />
      </div>
    );
  };

  const renderHeaderDatatableDocument = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <span className="block w-full mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputT
          name="search"
          type="search"
          className="w-full"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  /** useEffect */
  useEffect(() => {
    const summaryDocuments = billsItems.reduce(
      (acc, item) => {
        acc.restante += Number(item.value_total);
        acc.juros += Number(item.juros);
        acc.multa += Number(item.multa);
        acc.total += Number(item.total);
        return acc;
      },
      {
        restante: 0,
        juros: 0,
        multa: 0,
        total: 0,
      },
    );

    setTtDocumentos(summaryDocuments.restante);
    setTtJuros(summaryDocuments.juros);
    setTtMulta(summaryDocuments.multa);
    setTotal(summaryDocuments.total + ttAdic - ttDesc);
  }, [billsItems, ttAdic, ttDesc]);

  return (
    <>
      <BillsToReceivableHeader />
      {loadingGlobal && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-12 sm:w-full"
        />
      )}
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card w-8"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <Button
              label={!customer.id ? 'Buscar cliente' : customer.name}
              type="button"
              icon="fa-solid fa-search"
              iconPos="right"
              className={`p-button-outlined ${
                !customer.id ? 'p-button-danger' : 'p-button-info'
              } ml-auto`}
              onClick={() => setModalSearchCustomerVisible(true)}
            />
          </div>

          <div className="field col-12 md:col-12">
            <Button
              label={
                !billReceivable.id
                  ? 'Buscar documentos'
                  : `Sr.: ${billReceivable.serie} No.: ${
                      billReceivable.number
                    } Parcela.: ${
                      billReceivable.parcel
                    } Valor.: ${new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(billReceivable.value_total)}`
              }
              type="button"
              icon="fa-solid fa-search"
              iconPos="right"
              className={`p-button-outlined ${
                billsItems.length === 0 ? 'p-button-danger' : 'p-button-info'
              } ml-auto`}
              onClick={() => handleModalSearchDocumentVisible()}
              disabled={!customer.id}
            />
          </div>

          <div className="field col-12 md:col-12">
            <h5 className="text-center">DOCUMENTOS PARA RENEGOCIAÇÃO</h5>
            <DataTable
              value={billsItems}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum item encontrado!"
              loading={loadingGlobal}
              onSelectionChange={e => null}
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column field="serie" header="Série"></Column>
              <Column field="number" header="Número"></Column>
              <Column field="parcel" header="Parcela"></Column>
              <Column
                field="duo_date"
                header="Vencimento"
                body={e => renderColumnDate(e.duo_date)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="days_delay"
                header="Atraso"
                body={e => renderColumnPositiveNegative(e.days_delay)}
              ></Column>
              <Column
                field="juros"
                header="Juros"
                body={e => renderColumnDecimal(e.juros)}
              ></Column>
              <Column
                field="multa"
                header="Multa"
                body={e => renderColumnDecimal(e.multa)}
              ></Column>
              <Column
                field="total"
                header="Total"
                body={e => renderColumnDecimal(e.total)}
              ></Column>
              <Column
                header="*"
                body={e => renderButtonRemBillToList(e.id)}
              ></Column>
            </DataTable>
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="tt_documents">Total documentos</label>
            <InputNumber
              name="tt_documents"
              value={ttDocumentos}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              readOnly
              disabled
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="tt_juros">Juros</label>
            <InputNumber
              name="tt_juros"
              value={ttJuros}
              onChange={e => setTtJuros(e.value as number)}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              readOnly
              disabled
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="tt_multa">Multa</label>
            <InputNumber
              name="tt_multa"
              value={ttMulta}
              onChange={e => setTtMulta(e.value as number)}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              readOnly
              disabled
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="tt_adic">Adic. (+)</label>
            <InputNumber
              name="tt_adic"
              value={ttAdic}
              onChange={e => setTtAdic(e.value as number)}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={billsItems.length === 0}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="tt_desc">Desc. (-)</label>
            <InputNumber
              name="tt_desc"
              value={ttDesc}
              onChange={e => setTtDesc(e.value as number)}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={billsItems.length === 0}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="total">Total</label>
            <InputNumber
              name="total"
              value={total}
              onChange={e => setTotal(e.value as number)}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled
              readOnly
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="parcel">Parcelas</label>
            <InputNumber
              name="parcel"
              value={parcels}
              onChange={e => setParcels(Number(e.value))}
              placeholder="0,00"
              tooltip={'Informe a quantidade de parcelas'}
              tooltipOptions={{ position: 'bottom' }}
              minFractionDigits={2}
              maxFractionDigits={2}
              showButtons
              buttonLayout="horizontal"
              min={1}
              max={999}
              disabled={total <= 0}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="occorrence">Ocorrência</label>
            <InputDropDown
              name="occorrence"
              options={occorrenceOpts}
              value={occorrence}
              placeholder="Selecionar..."
              onChange={e => setOccorrence(e.value)}
              disabled={total <= 0}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="emited">Vencimento inicial</label>
            <Calendar
              name="emited"
              dateFormat="dd/mm/yy"
              value={firstPayment as Date}
              onChange={e => setFirstPayment(e.value as Date)}
              minDate={hoje}
              showIcon
              iconPos="right"
              disabled={total <= 0}
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              label="Limpar parcelas"
              className="p-button-danger"
              type="button"
              loading={loadingGlobal}
              disabled={billsRenegotiated.length === 0}
              onClick={() => setBillsRenegotiated([])}
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              label="Gerar parcelas"
              className="p-button-primary"
              type="button"
              loading={loadingGlobal}
              disabled={total <= 0}
              onClick={() => handleRecalculate()}
            />
          </div>

          <Divider />

          <div className="field col-12 md:col-12">
            <h5 className="text-center">NOVAS PARCELAS</h5>
            <DataTable
              value={billsRenegotiated}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum item encontrado!"
              loading={loadingGlobal}
              onSelectionChange={e => null}
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column
                field="emited_date"
                header="Emissão"
                body={e => renderColumnDate(e.emited_date)}
              ></Column>
              <Column
                field="duo_date"
                header="Vencimento"
                body={e => renderColumnDate(e.duo_date)}
                sortable
              ></Column>
              <Column field="serie" header="Série"></Column>
              <Column field="number" header="Número" sortable></Column>
              <Column field="parcel" header="Parcela"></Column>
              <Column
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
            </DataTable>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingGlobal}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Renegociar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingGlobal}
            />
          </div>
        </div>
      </Form>

      <ModalSearchCustomer
        visible={modalSearchCustomerVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchCustomerVisible(false)}
      >
        <h3>Buscar cliente</h3>
        <Form
          ref={null}
          onSubmit={() => null}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="customer_keyword">Pesquisar</label>
              <InputText
                name="customer_keyword"
                placeholder="Nome do cliente"
                type={'text'}
                value={customerKeyword}
                onChange={e => setCustomerKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={customerKeyword.length < 3 ? true : false}
                onClick={() => searchCustomer(customerKeyword)}
                loading={loadingGlobal}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={customerOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={isLoad}
                onSelectionChange={e => handleSelectCustomer(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="name" header="Nome"></Column>
                <Column
                  field="cpf_cnpj"
                  header="CPF/CNPJ"
                  body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </ModalSearchCustomer>

      <ModalSearchDocument
        visible={modalSearchDocumentVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchDocumentVisible(false)}
      >
        <>
          <h3>Buscar documento</h3>
          <div className="p-fluid grid formgrid">
            <div className="p-inputgroup field col-12 md:col-4">
              <span className="p-inputgroup-addon">Nome</span>
              <InputT
                name="document_keyword"
                placeholder="..."
                value={customer.name || '[cliente não selecionado]'}
                readOnly
                disabled
              />
            </div>

            <div className="p-inputgroup field col-12 md:col-4">
              <span className="p-inputgroup-addon">Cpf/Cnpj</span>
              <InputM
                name="document_cpf_cnpj"
                placeholder={
                  customer.cpf_cnpj.length === 11
                    ? '000.000.000-00'
                    : '00.000.000/0000-00'
                }
                value={customer.cpf_cnpj || '-'}
                mask={
                  customer.cpf_cnpj.length === 11
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                readOnly
                disabled
              />
            </div>

            <div className="field col-12 md:col-4 flex align-items-center">
              {customer.id !== '' ? (
                customer.block === 'S' ? (
                  <Tag
                    value="Bloqueado"
                    icon="fa-solid fa-lock"
                    rounded
                    className="px-3"
                    severity={'danger'}
                  />
                ) : (
                  <Tag
                    value="Habilitado"
                    icon="fa-solid fa-lock-open"
                    rounded
                    className="px-3"
                    severity={'success'}
                  />
                )
              ) : (
                ''
              )}
            </div>

            <DataTable
              header={renderHeaderDatatableDocument}
              globalFilter={globalFilter}
              selection={billReceivable}
              onSelectionChange={e => handleAddBillToNegotitation(e.value)}
              selectionMode="single"
              loading={loadingDocument}
              value={billsReceivableOpts}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum documento encontrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column field="name" header="Nome"></Column>
              <Column field="serie" header="Série"></Column>
              <Column field="number" header="Número"></Column>
              <Column field="parcel" header="Parcela"></Column>
              <Column
                field="duo_date"
                header="Vencimento"
                body={e => renderColumnDate(e.duo_date)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="days_delay"
                header="Atraso"
                body={e => renderColumnPositiveNegative(e.days_delay)}
              ></Column>
              <Column
                field="juros"
                header="Juros"
                body={e => renderColumnDecimal(e.juros)}
              ></Column>
              <Column
                field="multa"
                header="Multa"
                body={e => renderColumnDecimal(e.multa)}
              ></Column>
              <Column
                field="total"
                header="Total"
                body={e => renderColumnDecimal(e.total)}
              ></Column>
            </DataTable>
          </div>
        </>
      </ModalSearchDocument>
    </>
  );
};

export default BillsToReceivableRenegotiation;
