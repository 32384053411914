import React, { createContext, useReducer, useEffect } from 'react';

import {
  AppLevelAccessProps,
  initialState,
  ActionProps,
  AccessLevelReducerApp,
} from '../reducers/AppAccessLevel';

type ContextType = {
  state: AppLevelAccessProps;
  dispatch: React.Dispatch<any>;
};

export const AppContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

export const mainReducer = (
  state: AppLevelAccessProps,
  action: ActionProps,
): AppLevelAccessProps => ({
  ...AccessLevelReducerApp(state, action),
});

type Props = { children: React.ReactNode };

const AccessLevelProvider: React.FC<Props> = ({
  children,
}): React.ReactElement => {
  const localState = JSON.parse(localStorage.getItem('accessLevel') || '{}');

  const [state, dispatch] = useReducer(mainReducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem('accessLevel', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AccessLevelProvider;
