import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DropdownChangeParams } from 'primereact/dropdown';
import { InputSwitchChangeParams } from 'primereact/inputswitch';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../../components/Divider';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import formatCurrency from '../../../../utils/FormatCurrency';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import { IPrice, IProductPriceSearch } from '../../../product/dtos/IProductDTO';
import ConsignedTopMenu from '../../top-menu';
import {
  IConOrder,
  IConOrderPayment,
  IProductRow,
  emptyProductRow,
} from '../dtos/IConOrderDTO';
import ConOrderPayments from '../sidebar-invoice-checkout';
import { emptyConOrder } from '../types/emptyConOrder';
import { Image } from 'primereact/image';
import { ReactComponent as NoImage } from '../../../../assets/icons/no-image.svg';
import { Dialog } from '../../../../components/Modal';
import {
  IParametersConsigned,
  emptyParametersConsigned,
} from '../../../parameters/dtos/IParametersConsigned';
import { useParamsConsigned } from '../../../../hooks/useParamsConsigned';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const ConsignedOrderUpdate: React.FC = () => {
  const hoje = new Date();

  const params: { id: string } = useParams();
  const router = useHistory();
  const formHeaderRef = useRef<FormHandles>(null);
  const formProductRef = useRef<FormHandles>(null);
  const inputRefProduct1 = useRef<HTMLInputElement>(null);
  const inputRefProduct2 = useRef<HTMLInputElement>(null);
  const inputRefQnt = useRef<HTMLInputElement>(null);

  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [sku, setSku] = useState<number>(0);
  const [isKeyboard, setIsKeyboard] = useState<boolean>(false);

  const [order, setOrder] = useState<IConOrder>(emptyConOrder);

  const [keyword, setKeyword] = useState<string>('');

  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);

  const [commissioneds, setCommissioneds] = useState<IOptionsDTO[]>([]);

  const [natOpes, setNatOpes] = useState<IOptionsDTO[]>([]);

  const [tablePrices, setTablePrices] = useState<IOptionsDTO[]>([]);
  const [tablePrice, setTablePrice] = useState<string>('');

  const [products, setProducts] = useState<IProductPriceSearch[]>([]);
  const [product, setProduct] = useState<IProductPriceSearch | undefined>(
    undefined,
  );
  const [productFocus1, setProduct1Focus] = useState(false);
  const [productFocus2, setProduct2Focus] = useState(false);

  const { paramsConsigned, fetchParamsConsigned } = useParamsConsigned();

  const [items, setItems] = useState<IProductRow[]>([]);
  const [item, setItem] = useState<IProductRow>(emptyProductRow);

  const [qnt, setQnt] = useState<number>(1);
  const [qntFocus, setQntFocus] = useState(false);
  const [vlrUnit, setVlrUnit] = useState(0);
  const [add, setAdd] = useState(0);
  const [desc, setDesc] = useState(0);
  const [liq, setLiq] = useState(0);
  const [total, setTotal] = useState(0);

  const [productViewVisible, setProductViewVisible] = useState(false);
  const [productImageUrl, setProductImageUrl] = useState<string | null>(null);

  const [checkoutVisible, setCheckoutVisible] = useState(false);

  const [payments, setPayments] = useState<IConOrderPayment[]>([]);
  const [payment, setPayment] = useState<IConOrderPayment | undefined>(
    undefined,
  );

  const [routeCustomer, setRouteCustomer] = useState<string>('');

  useEffect(() => {
    if (items.length > 0 && tablePrice === '') {
      setTablePrice(items[0].table_price_id);
    }
  }, [items]);

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const searchProduct = async (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      await api
        .post(`/products/v2/prices/search`, {
          data: {
            keyword: event.query,
            show: { page: 1, perPage: 1000, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProducts(data.map((i: IProductPriceSearch) => i));
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        });
    }
  };

  const searchProductById = async (id: string) => {
    if (validate(id)) {
      await api
        .get(`/products/v2/prices/${id}`)
        .then(({ data }) => {
          if (data) {
            setProduct({ ...data });
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const searchProductBySku = async () => {
    if (sku.toString().length < 3) {
      toast(
        'warn',
        'Alerta',
        'SKU do produto deve ter no mínimo 3 caracteres!',
      );
      return;
    }
    setIsLoad(true);
    await api
      .get(`products/v2/prices/sku/${sku}`)
      .then(({ data }) => {
        if (data.prices.length === 0) {
          toast(
            'warn',
            'Alerta',
            `O produto.: ${data.title} não tem nenhum preço cadastrado no sistema!`,
          );
          setItem(emptyProductRow);
          return;
        }

        const tp = data.prices.find(
          (i: IPrice) => i.table_price_id === tablePrice,
        );

        const vlr_unit = Number(
          Number(tp.vlr_venda || data.prices[0].vlr_venda).toFixed(
            data.decimalHouses[0].price_out || 2,
          ),
        );

        setProduct({ ...data });
        setQnt(1);
        setVlrUnit(vlr_unit);
        setAdd(0);
        setDesc(0);
        setLiq(vlr_unit);
        setTotal(vlr_unit);

        setItem({
          ...data,
          id: v4(),
          pos: data.pos,
          stock: Number(data.stock) || 0,
          sku: data.sku,
          table_price_id: tp
            ? tp.table_price_id
            : data.prices[0].table_price_id,
          table_price_description: tp
            ? tp.tablePrice.title
            : data.prices[0].tablePrice.title,
          product_id: data.id,
          product_description: data.title,
          value: data.id,
          label: data.title,
          image_url:
            data.productImages && data.productImages.length > 0
              ? data.productImages[0].image_url
              : null,
          lucre: Number(data.prices[0].vlr_venda) - Number(data.cost_billing),
          lucre_aliq: 0,
          qnt_out: Number(data.decimalHouses[0].qnt_out),
          price_out: Number(data.decimalHouses[0].price_out),
          qnt: 1,
          vlr_unit: Number(data.prices[0].vlr_venda),
          adic: 0,
          add_aliq: 0,
          desc: 0,
          desc_aliq: 0,
          vlr_liq: Number(data.prices[0].vlr_venda),
          total: Number(data.prices[0].vlr_venda),
        });

        setQntFocus(true);
        inputRefQnt?.current?.focus();
      })
      .catch(err => {
        toast(
          'warn',
          'Ops...',
          'Nenhum produto foi encontrado com os parâmetros informados!' +
            messageRequestError(err),
          10000,
        );
      })
      .finally(() => setIsLoad(false));
  };

  const loadOrder = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/consigned/invoice/${id}`)
      .then(({ data }) => {
        if (data) {
          setTablePrice(data.items[0].table_price_id);
          setOrder({ ...data });
          setItems(
            data.items.map((i: any) => {
              return {
                id: i.id,
                pos: Number(i.pos),
                stock: Number(i.product.stock),
                sku: i.product.sku,
                table_price_id: i.table_price_id,
                table_price_description: i.product.prices.find(
                  (j: any) => j.table_price_id === i.table_price_id,
                )?.tablePrice.title,
                product_id: i.product_id,
                product_description: i.product.title,
                value: i.product_id,
                label: i.product.title,
                lucre: 0,
                lucre_aliq: 0,
                qnt: Number(i.qnt),
                qnt_out: Number(i.product.decimalHouses[0].qnt_out || 2),
                vlr_unit: Number(i.vlr_unit),
                value_out: Number(i.product.decimalHouses[0].price_out || 2),
                adic: Number(i.add),
                add_aliq: 0,
                desc: Number(i.desc),
                desc_aliq: 0,
                vlr_liq: Number(i.vlr_liq),
                total: Number(i.total),
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setIsLoad(false));
  };

  const loadCommissioneds = async () => {
    setIsLoad(true);
    await api
      .get('/commissioneds')
      .then(({ data }) => {
        if (data) {
          setCommissioneds(
            data.map((i: any) => {
              return {
                value: i.id,
                label: `${i.name} ~ ${i.nickname}`,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setIsLoad(false));
  };

  const loadNatOpe = async () => {
    setIsLoad(true);
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setNatOpes(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setIsLoad(false));
  };

  const loadTablePrices = async () => {
    setIsLoad(true);
    await api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setTablePrices(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setIsLoad(false));
  };

  const handleProductSelected = (e: AutoCompleteSelectParams) => {
    const p: IProductPriceSearch = e.value;
    if (p.prices.length === 0) {
      toast(
        'warn',
        'Alerta',
        `O produto.: ${p.title} não tem nenhum preço cadastrado no sistema!`,
      );
      setItem(emptyProductRow);
      return;
    }

    const tp = p.prices.find((i: IPrice) => i.table_price_id === tablePrice);
    const vlr_unit = Number(
      Number(tp?.vlr_venda || p.prices[0].vlr_venda).toFixed(
        p.decimalHouses[0].price_out || 2,
      ),
    );

    setProduct(p);
    setQnt(1);
    setVlrUnit(vlr_unit);
    setAdd(0);
    setDesc(0);
    setLiq(vlr_unit);
    setTotal(vlr_unit);

    setItem({
      ...item,
      id: v4(),
      product_id: p.id,
      value: p.id,
      product_description: p.title,
      label: p.title,
      image_url:
        p.productImages && p.productImages.length > 0
          ? p.productImages[0].image_url || null
          : null,
      table_price_id: tp ? tp.table_price_id : p.prices[0].table_price_id,
      table_price_description: tp
        ? tp.tablePrice.title
        : p.prices[0].tablePrice.title,
      qnt: qnt,
      vlr_unit: Number(p.prices[0].vlr_venda),
      qnt_out: p.decimalHouses[0].qnt_out,
      price_out: p.decimalHouses[0].price_out,
      total: Number(p.prices[0].vlr_venda),
    });
  };

  const handleTablePriceSelect = (e: DropdownChangeParams) => {
    const pp = product?.prices.find(
      (i: IPrice) => i.table_price_id === e.value,
    );
    if (pp) {
      setItem({
        ...item,
        table_price_id: pp.table_price_id,
        table_price_description: pp.tablePrice.title,
        qnt: qnt,
        vlr_unit: pp.vlr_venda,
        total: pp.vlr_venda,
      });
      setVlrUnit(pp.vlr_venda);
    }
  };

  const clearRow = () => {
    setSku(0);
    setProduct(undefined);
    setItem(emptyProductRow);
    setQnt(1.0);
    setVlrUnit(0.0);
    setAdd(0.0);
    setDesc(0.0);
    setLiq(0.0);
    setTotal(0.0);
  };

  const handleAddProduct = () => {
    if (!product || !validate(product.id)) {
      toast('warn', 'Alerta', 'Informe um produto!');
      if (isKeyboard) {
        setProduct2Focus(true);
        inputRefProduct2?.current?.focus();
      } else {
        setProduct1Focus(true);
        inputRefProduct1?.current?.focus();
      }
      return;
    }

    if (!validate(item.table_price_id)) {
      toast(
        'warn',
        'Alerta',
        `Informe uma tabela de preços para o produto.: ${item.product_description}`,
      );
      return;
    }

    if (item.vlr_unit <= 0) {
      toast(
        'warn',
        'Alerta',
        `O valor unitário do produto.: ${item.product_description} deve ser maior que 0.`,
      );
      return;
    }

    if (item.qnt <= 0) {
      toast(
        'warn',
        'Alerta',
        `A quantidade do produto.: ${item.product_description} deve ser maior que 0.`,
      );
      return;
    }

    const i: IProductRow = {
      ...item,
      pos: items.length + 1,
      product_id: item.product_id,
      product_description: item.product_description,
      table_price_id: item.table_price_id,
      table_price_description: item.table_price_description,
      qnt: item.qnt,
      vlr_unit: vlrUnit,
      adic: add,
      desc: desc,
      vlr_liq: liq,
      total: total,
    };

    const noId = items.filter((it: IProductRow) => it.id !== i.id);

    setItems([...noId, i]);

    clearRow();
  };

  const handleRemProduct = (id: string) => {
    const noItems = items.filter((i: IProductRow) => i.id !== id);
    setItems([...noItems]);
  };

  const handleViewProduct = (id: string) => {
    const url = items.find((i: IProductRow) => i.id === id)?.image_url;
    setProductImageUrl(url || null);

    setProductViewVisible(true);
  };

  const handleRowSelect = async (e: any) => {
    const i = items.find((i: IProductRow) => i.id === e.data.id);

    if (i) {
      setItem({
        ...i,
        id: i.id,
        pos: i.pos,
        sku: i.sku,
        table_price_id: i.table_price_id,
        table_price_description: i.table_price_description,
        product_id: i.product_id,
        value: i.value,
        product_description: i.product_description,
        label: i.label,
        image_url: i.image_url,
        qnt: i.qnt,
        qnt_out: i.qnt_out,
        vlr_unit: i.vlr_unit,
        price_out: i.price_out,
        adic: i.adic,
        desc: i.desc,
        vlr_liq: i.vlr_liq,
        total: i.total,
      });

      await searchProductById(e.data.product_id);

      setSku(Number(i.sku || 0));
      setQnt(i.qnt);
      setVlrUnit(i.vlr_unit);
      setAdd(Number(i.adic || 0));
      setDesc(i.desc);
      setLiq(i.vlr_liq);
      setTotal(i.total);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formHeaderRef.current?.setErrors({});

      d.customer_id = order.customer_id;
      d.nat_ope = order.nat_ope_id;
      d.items = items;
      d.payments = payments;
      d.date_digitation = hoje;

      setOrder({
        ...order,
        nat_ope_id: order.nat_ope_id,
        discount: summary?.totalDesc || 0,
        additional: summary?.totalAdd || 0,
        fees: summary?.totalFees || 0,
        qnt_products: summary?.qntProdutos || 0,
        qnt_itens: summary?.qntItems || 0,
        total_comission: 0,
        order_value: summary?.totalVenda || 0,
        order_total: summary?.total || 0,
        items: items.map((i: IProductRow) => {
          return {
            id: i.id,
            pos: i.pos,
            con_order_id: order.id,
            product_id: i.product_id,
            product: {
              title: i.product_description,
            },
            table_price_id: i.table_price_id,
            qnt: i.qnt,
            vlr_unit: i.vlr_unit,
            add: i.adic,
            desc: i.desc,
            vlr_liq: i.vlr_liq,
            total: i.total,
            checked: false,
          };
        }),
      });

      const validation = Yup.object({
        route: Yup.string()
          .uuid('Informe a rota que este pedido pertence!')
          .required('Informe a rota que este pedido pertence!'),
        nat_ope: Yup.string()
          .uuid('Informe a natureza de operação do pedido!')
          .required('Informe a natureza de operação do pedido!'),
        customer_id: Yup.string()
          .uuid('Informe o cliente do pedido!')
          .required('Informe o cliente do pedido!'),
        checked: Yup.boolean().required(
          'Informe se o pedido já foi conferido ou não!',
        ),
        date_digitation: Yup.date().required('Informe a data da digitação!'),
        items: Yup.array()
          .min(1, 'Informe pelo menos 1 produto no pedido!')
          .required('Informe pelo menos 1 produto no pedido!'),
      });
      await validation.validate(d, { abortEarly: false });
      setCheckoutVisible(true);
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formHeaderRef.current?.setErrors(errors);
        toast('warn', 'Erro', err.errors[0]);
      } else {
        toast('error', 'Erro', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleCheckOrder = async (id: string) => {
    setIsLoad(true);
    await api
      .put(`/consigned/invoice/check/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'O pedido foi marcado como conferido!');
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setIsLoad(false));
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemProduct(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  const renderColumnViewProduct = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-eye"
          onClick={() => handleViewProduct(id)}
        />
      </>
    );
  };

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const itemTemplateCustomer = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  useEffect(() => {
    const _liq = Number(vlrUnit) + Number(add) - Number(desc);
    const _total = _liq * Number(qnt);
    setLiq(_liq);
    setTotal(_total);
  }, [keyword, product, qnt, vlrUnit, add, desc, liq, total]);

  interface ISummary {
    qntProdutos: number;
    qntItems: number;
    totalVenda: number;
    totalAdd: number;
    totalFees: number;
    totalDesc: number;
    total: number;
  }
  const [summary, setSummary] = useState<ISummary | undefined>(undefined);

  useEffect(() => {
    const summary = items.reduce(
      (a: any, i: IProductRow) => {
        a.qntProdutos += 1;
        a.qntItems += Number(i.qnt);
        a.totalVenda += Number(i.vlr_unit) * Number(i.qnt);
        a.totalAdd += Number(i.adic);
        a.totalDesc += Number(i.desc);
        a.total += Number(i.total);
        return a;
      },
      {
        qntProdutos: 0,
        qntItems: 0,
        totalVenda: 0,
        totalAdd: 0,
        totalFees: 0,
        totalDesc: 0,
        total: 0,
      },
    );
    setSummary({ ...summary });
  }, [items]);

  useEffect(() => {
    loadCommissioneds();
    loadNatOpe();
    loadTablePrices();
    if (!paramsConsigned) fetchParamsConsigned();
    if (params && validate(params.id)) loadOrder(params.id);
  }, []);

  useEffect(() => {
    if (order.route_id && order.route_id !== '') {
      loadRoute(order.route_id);
    }
  }, [order.route_id]);

  const loadRoute = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/consigned/route/${id}`)
      .then(({ data }) => {
        if (data) {
          setRouteCustomer(data.customer_id);
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  return (
    <>
      <ConsignedTopMenu />
      <div className="card">
        <Form
          ref={formHeaderRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgroup"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="field col-6 mb-0 pb-0">
            <label htmlFor="route">Rota</label>
            <InputDropDown
              name="route"
              value={order.routeOfOrder.id}
              options={[
                {
                  value: order.routeOfOrder.id,
                  label: `Rota.: ${order.routeOfOrder.sequence}`,
                },
              ]}
              disabled
            />
          </div>
          <div className="field col-2 mb-0 pb-0">
            <label htmlFor="nat_ope">Nat. operação</label>
            <InputDropDown
              name="nat_ope"
              value={order.nat_ope_id}
              options={natOpes || []}
              placeholder="Selecionar..."
              onChange={e => setOrder({ ...order, nat_ope_id: e.value })}
              filter
              required
            />
          </div>
          <div className="field col-2 mb-0 pb-0">
            <label htmlFor="commissioned">Comissionado</label>
            <InputDropDown
              name="commissioned"
              value={order.commissioned_id}
              options={commissioneds}
              placeholder="Selecionar..."
              onChange={e => setOrder({ ...order, commissioned_id: e.value })}
              filter
              required
            />
          </div>
          <div className="field col-2 mb-0 pb-0">
            <label htmlFor="table_price">Tabela preços</label>
            <InputDropDown
              name="table_price"
              value={tablePrice}
              options={tablePrices}
              placeholder="Selecionar..."
              onChange={e => setTablePrice(e.value)}
              filter
              required
            />
          </div>
          <div className="field col-6 mb-1 pb-1 mb-0 pb-0">
            <label htmlFor="dropdown">
              Cliente
              <i
                className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                onClick={() => null}
              ></i>
            </label>
            <InputAutoComplete
              name="customer_id"
              suggestions={customers}
              value={{
                value: order?.customer?.id,
                label: order?.customer?.name,
              }}
              completeMethod={e => searchCustomer(e.query)}
              field="label"
              onChange={e => setOrder({ ...order, customer_id: e.value })}
              itemTemplate={itemTemplateCustomer}
              placeholder="Buscar cliente..."
              tooltip={
                paramsConsigned && paramsConsigned.stock_control === true
                  ? order.customer_id === routeCustomer
                    ? 'Pedidos para a própria rota se tratam de reposições do estoque da rota.'
                    : 'Para repor o estoque da rota, selecione a própria rota.'
                  : 'Informe o nome do cliente.'
              }
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>
          <div className="field col-2 mb-0 pb-0">
            <label htmlFor="period">Digitação</label>
            <Calendar
              name="date_digitation"
              value={new Date(order.date_digitation)}
              placeholder="00/00/00"
              dateFormat="d/m/y"
              showIcon
              readOnlyInput
              disabled
              className="surface-200"
            />
          </div>
          <div className="field col-2 mb-0 pb-0">
            <label htmlFor="sequence">N. Ped.</label>
            <InputNumber
              name="sequence"
              value={order.sequence}
              placeholder="000"
              readOnly
              disabled
              className="surface-200"
            />
          </div>
          <div className="field col-2 flex align-items-end mb-0">
            <InputSwitch
              name="checked"
              className="mr-3"
              checked={order.checked}
              onChange={async (e: InputSwitchChangeParams) => {
                setOrder({ ...order, checked: e.value });
                if (e.value === true && validate(params.id)) {
                  await handleCheckOrder(params.id);
                }
              }}
              disabled={!validate(params.id) || order.checked}
            />
            <label htmlFor="dropdown" className="m-0">
              Conferido
            </label>
          </div>
        </Form>

        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">PRODUTO</span>
          </div>
        </Divider>

        <Form
          ref={formProductRef}
          onSubmit={d => null}
          className="p-fluid grid formgroup"
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          {isKeyboard === false && (
            <div className="col-3">
              <label htmlFor="product_search">
                Produto
                <i
                  className={`fa-solid fa-keyboard ${
                    isKeyboard ? 'text-green-500' : 'text-grey-500'
                  } ml-2 cursor-pointer`}
                  onClick={() => setIsKeyboard(!isKeyboard)}
                ></i>
              </label>
              <InputAutoComplete
                inputRef={inputRefProduct1}
                name="product_search"
                value={item}
                field="label"
                completeMethod={e => searchProduct(e)}
                suggestions={products}
                onSelect={e => handleProductSelected(e)}
                onChange={e => setItem(e.value)}
                itemTemplate={e => itemTemplateProduct(e)}
                placeholder={`${
                  isKeyboard ? 'Inserir código + Enter' : 'Buscar produto...'
                }`}
                autoFocus={productFocus1}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    setQntFocus(true);
                    inputRefQnt?.current?.focus();
                  }
                }}
              />
            </div>
          )}
          {isKeyboard === true && (
            <div className="col-3">
              <label htmlFor="product_input">
                SKU/Código
                <i
                  className={`fa-solid fa-keyboard ${
                    isKeyboard ? 'text-green-500' : 'text-grey-500'
                  } ml-2 cursor-pointer`}
                  onClick={() => setIsKeyboard(!isKeyboard)}
                ></i>
              </label>
              <InputNumber
                inputRef={inputRefProduct2}
                name="product_input"
                value={sku}
                onChange={e => {
                  setSku(e.value || 0);
                }}
                placeholder="Inserir código + Enter"
                autoFocus={productFocus2}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    searchProductBySku();
                  }
                }}
                aria-describedby="product_input"
              />
              <small id="product_input">
                {item.product_description
                  ? item.product_description
                  : 'Digite SKU + Enter'}
              </small>
            </div>
          )}
          <div className="col-2">
            <label htmlFor="table_price_id">Tabela preço</label>
            <InputDropDown
              name="table_price_id"
              options={product?.prices.map((i: IPrice) => {
                return {
                  value: i.table_price_id,
                  label: i.tablePrice.title,
                };
              })}
              value={item.table_price_id}
              onChange={e => handleTablePriceSelect(e)}
              disabled={product?.prices.length === 0 ? true : false}
              className={product?.prices.length === 0 ? `surface-200` : ``}
              placeholder="Selecionar..."
            />
          </div>
          <div className="col-1">
            <label htmlFor="qnt">Qnt.</label>
            <InputNumber
              inputRef={inputRefQnt}
              name="qnt"
              value={qnt}
              onChange={e => {
                setQnt(Number(e.value));
                setItem({ ...item, qnt: Number(e.value) });
              }}
              mode="decimal"
              minFractionDigits={item.qnt_out}
              placeholder="0,00"
              maxFractionDigits={item.qnt_out}
              autoFocus={qntFocus}
              onFocus={e => e.target.select()}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setQntFocus(false);
                  if (isKeyboard) {
                    setProduct2Focus(true);
                    inputRefProduct2?.current?.focus();
                  } else {
                    setProduct1Focus(true);
                    inputRefProduct1?.current?.focus();
                  }

                  handleAddProduct();
                }
              }}
              disabled={product?.prices.length === 0 ? true : false}
              className={product?.prices.length === 0 ? `surface-200` : ``}
            />
          </div>
          <div className="col-1">
            <label htmlFor="vlr_unit">Vlr.</label>
            <InputNumber
              name="vlr_unit"
              value={vlrUnit}
              onChange={e => setVlrUnit(e.value as number)}
              mode="decimal"
              placeholder="R$ 0,00"
              minFractionDigits={item.price_out}
              maxFractionDigits={item.price_out}
              disabled={product?.prices.length === 0 ? true : false}
              className={product?.prices.length === 0 ? `surface-200` : ``}
            />
          </div>
          <div className="col-1">
            <label htmlFor="add">Add.</label>
            <InputNumber
              name="add"
              value={add}
              onChange={e => setAdd(e.value as number)}
              mode="decimal"
              placeholder="R$ 0,00"
              minFractionDigits={item.price_out}
              maxFractionDigits={item.price_out}
              disabled={product?.prices.length === 0 ? true : false}
              className={product?.prices.length === 0 ? `surface-200` : ``}
            />
          </div>
          <div className="col-1">
            <label htmlFor="add">Desc.</label>
            <InputNumber
              name="desc"
              value={desc}
              onChange={e => setDesc(e.value as number)}
              mode="decimal"
              placeholder="R$ 0,00"
              minFractionDigits={item.price_out}
              maxFractionDigits={item.price_out}
              disabled={product?.prices.length === 0 ? true : false}
              className={product?.prices.length === 0 ? `surface-200` : ``}
            />
          </div>
          <div className="col-1">
            <label htmlFor="liq">Liq.</label>
            <InputNumber
              name="liq"
              value={liq}
              onChange={e => setLiq(e.value as number)}
              mode="decimal"
              placeholder="R$ 0,00"
              disabled
              className="surface-200"
              minFractionDigits={item.price_out}
              maxFractionDigits={item.price_out}
            />
          </div>
          <div className="col-1">
            <label htmlFor="total">Total</label>
            <InputNumber
              name="total"
              value={total}
              onChange={e => setTotal(e.value as number)}
              mode="decimal"
              placeholder="R$ 0,00"
              disabled
              className="surface-200"
              minFractionDigits={item.price_out}
              maxFractionDigits={item.price_out}
            />
          </div>

          <div className="field col-1 flex align-items-end mt-4">
            <Button
              type="submit"
              loading={isLoad}
              disabled={!validate(item.product_id) ? true : false}
              className="p-button-success"
              icon="fa-solid fa-plus"
              onClick={e => handleAddProduct()}
            />
          </div>
        </Form>

        <Divider />

        <div className="p-fluid grid formgroup">
          <DataTable
            value={items}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Produtos não encontrados..."
            selectionMode="single"
            onRowSelect={e => handleRowSelect(e)}
            loading={isLoad}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} produtos de consignados."
            className="field col-12"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column
              header="Produto"
              field="product_description"
              sortable
            ></Column>
            <Column header="Tabela" field="table_price_description"></Column>
            <Column
              header="Qnt."
              body={r => renderColumnDecimal(r.qnt)}
            ></Column>
            <Column
              header="Vlr."
              body={r => renderColumnDecimal(r.vlr_unit)}
            ></Column>
            <Column
              header="Add."
              body={r => renderColumnDecimal(r.adic)}
            ></Column>
            <Column
              header="Desc."
              body={r => renderColumnDecimal(r.desc)}
            ></Column>
            <Column
              header="Liq."
              body={r => renderColumnDecimal(r.vlr_liq)}
            ></Column>
            <Column
              header="Total"
              body={r => renderColumnDecimal(r.total)}
            ></Column>
            <Column
              header="Ver"
              body={e => renderColumnViewProduct(e.id)}
            ></Column>
            <Column
              header="*"
              body={e => renderCollumnMenu(e.id)}
              align={'center'}
            ></Column>
          </DataTable>

          <Divider />

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">RESUMOS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Aliq. comissão.:</div>
              <div className="flex">0,00</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Valor comissão.:</div>
              <div className="flex">0,00</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt. produtos.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntProdutos || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt. itens.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntItems || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Em aberto há.:</div>
              <div className="flex">000 dias</div>
            </div>
          </div>

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">ANOTAÇÕES</span>
              </div>
            </Divider>
            {/* <InputTextArea
              name="obs"
              value={
                order.obs.length >= 64 ? order.obs.slice(0, 64) : order.obs
              }
              placeholder="Obs.:"
              rows={5}
              cols={30}
              disabled={true}
            /> */}
            Obs.: {order.obs.length >= 64 ? order.obs.slice(0, 64) : order.obs}
          </div>

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">TOTAIS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Total produtos.:</div>
              <div className="flex">
                {formatCurrency(summary?.totalVenda || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Adicionais.:</div>
              <div className="flex">
                {formatCurrency(summary?.totalAdd || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Juros.:</div>
              <div className="flex">
                {formatCurrency(summary?.totalFees || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Descontos.:</div>
              <div className="flex">
                {formatCurrency(summary?.totalDesc || 0)}
              </div>
            </div>
            <div
              className="flex justify-content-between flex-wrap my-2 py-2 font-bold"
              style={{ borderTop: '0.2rem dashed var(--surface-500)' }}
            >
              <div className="flex">Total.:</div>
              <div className="flex">{formatCurrency(summary?.total || 0)}</div>
            </div>
          </div>

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Cancelar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Faturar"
              icon="pi pi-check"
              className="p-button-success"
              type="button"
              onClick={() => formHeaderRef.current?.submitForm()}
              loading={isLoad}
            />
          </div>
        </div>
      </div>

      <Dialog
        visible={productViewVisible}
        onHide={() => {
          setProductViewVisible(false);
          setProductImageUrl(null);
        }}
        className="container"
        header={`Foto do produto`}
      >
        <div className="row">
          <div className="col align-items-center">
            {productImageUrl && productImageUrl !== null ? (
              <Image
                src={
                  productImageUrl === null || productImageUrl === ''
                    ? `${process.env.REACT_APP_SERVER_URL}/files/img/no-photo.jpg`
                    : `${process.env.REACT_APP_SERVER_URL}/files/img/${productImageUrl}`
                }
                alt={item.label}
                width="200"
                height="200"
                preview
              />
            ) : (
              <>
                <label>Produto sem foto cadastrada!</label>
                <Divider />
                <NoImage
                  className="align-self-center"
                  width="200"
                  height="200"
                  fill="#eee"
                />
              </>
            )}
          </div>
        </div>
      </Dialog>

      <ConOrderPayments
        isOpen={checkoutVisible}
        onRequestClose={() => setCheckoutVisible(false)}
        invoiceDTO={order}
        routeCustomer={routeCustomer}
      />
    </>
  );
};

export default ConsignedOrderUpdate;
