import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const CrmMenuTop = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Paineis"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/mkt/crm/panels')}
      />

      <Button
        label="Site da receita"
        className="mb-2 mr-2"
        icon="fa-brands fa-searchengin"
        onClick={() => router.push('/mkt/rfb/search')}
      />

      <Button
        label="Cards"
        className="mb-2 mr-2"
        icon="fa-solid fa-address-card"
        onClick={() => router.push('/reports/crm/cards')}
      />
    </>
  );
};

export default CrmMenuTop;
