import React from 'react';

const RenderColumnLock = (value: boolean): React.ReactElement => {
  return value === false ? (
    <i className="fa-solid fa-circle-xmark text-pink-600"></i>
  ) : (
    <i className="fa-solid fa-circle-check text-green-500"></i>
  );
};

export default RenderColumnLock;
