import React, { useEffect, useRef, useState } from 'react';

/** services */
import api from '../../services/api';

/** components */
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { DataTable } from '../../components/DataTable';

const Payments = (): React.ReactElement => {
  const [purchase, setPurshase] = useState<Array<any>>([]);
  const [menuOption, setMenuOption] = useState({ print: '' });

  const menu = useRef<any>(null);

  const [plans] = useState([
    {
      id: '0498f29e-e823-11ec-ab6e-43f4f34bca93',
      name: 'Paládio',
      price: 189.9,
      price_string: '189.90',
    },
    {
      id: '052de570-e823-11ec-b57e-5f11594c1a7b',
      name: 'Titânio',
      price: 349.9,
      price_string: '349.90',
    },
    {
      id: '05b7865e-e823-11ec-a48f-6bfa099cea9b',
      name: 'Adamantium',
      price: 699.9,
      price_string: '699.90',
    },
    {
      id: '06455ab0-e823-11ec-b70f-dbb3c6b4cf1f',
      name: 'Vibranium',
      price: 999.9,
      price_string: '999.90',
    },
  ]);

  const columnDescription = (rowData: any) => {
    return (
      <span>
        {plans.find(item => item.id === rowData.plano_contratado_id)?.name}
      </span>
    );
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    data: any,
  ) => {
    // event?.currentTarget?.toggle(event)
    setMenuOption({ ...menuOption, print: data.gn_link });
    menu?.current?.toggle(event);
  };

  const columnCode = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const columnDueDate = (rowData: any) => {
    return <span>{format(new Date(rowData.gn_expire_at), 'dd/MM/yyyy')}</span>;
  };

  const columnBodyPrice = (rowData: any) => {
    return (
      <span>
        R${' '}
        {
          plans.find(item => item.id === rowData.plano_contratado_id)
            ?.price_string
        }
      </span>
    );
  };

  const columnBodyStatus = (rowData: any) => {
    return (
      <>
        {rowData.gn_status === 'waiting' ? (
          <span className="customer-badge status-new">Aguardando</span>
        ) : rowData.gn_status === 'paid' ? (
          <span className="customer-badge status-qualified">Pago</span>
        ) : (
          <span className="customer-badge status-unqualified">Cancelado</span>
        )}
      </>
    );
  };

  const columnBodyAction = (rowData: any) => {
    const items = [
      {
        label: 'Imprimir Recibo',
        icon: 'pi pi-fw pi-print',
        command: () => {
          window.open(menuOption.print);
        },
      },
    ];
    const id = nanoid();
    return (
      <>
        <Menu model={items} popup ref={menu} />

        <Button
          icon="pi pi-bars"
          onClick={event => openMenu(event, id, rowData)}
        />
      </>
    );
  };

  useEffect(() => {
    api.get('/system/purchase/v1').then(({ data }) => {
      setPurshase(data.purchases);
    });
  }, []);

  return (
    <>
      <DataTable
        value={purchase}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} boletos"
        emptyMessage="Não existe pagamentos."
        responsiveLayout="scroll"
      >
        <Column field="description" header="#" body={columnCode}></Column>
        <Column
          field="description"
          header="Descrição"
          body={columnDescription}
        ></Column>
        <Column
          field="description"
          header="Vencimento"
          body={columnDueDate}
        ></Column>
        <Column
          field="description"
          header="Valor"
          body={columnBodyPrice}
        ></Column>
        <Column
          field="description"
          header="Status"
          body={columnBodyStatus}
        ></Column>
        <Column
          field="description"
          header="Ações"
          body={columnBodyAction}
        ></Column>
      </DataTable>
    </>
  );
};

export default Payments;
