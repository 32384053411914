import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';
import useToastContext from '../../../hooks/toast';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** components */
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from '../../../components/DataTable';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';

/** services */
import { ProgressBar } from 'primereact/progressbar';
import api from '../../../services/api';

type MetaBusiness = {
  title: string;
  meta_total: string;
};

const MainActivityCreate = (): React.ReactElement => {
  const navigate = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [openModalBusiness, setOpenModalBusiness] = useState(false);
  const [selectBusiness, setSelectBusiness] = useState<any>(null);
  const [business, setBusiness] = useState<any>();

  const [metaTotal, setMetaTotal] = useState<number | null>();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const codeColumn = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const logoColumn = (rowData: any) => {
    return (
      <span>
        {rowData.logo_url !== '' ? (
          <Avatar
            image={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.logo_url}`}
            className="mr-2"
            shape="circle"
          />
        ) : (
          <Avatar
            icon="pi pi-user"
            className="mr-2"
            style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
            shape="circle"
          />
        )}
      </span>
    );
  };

  /** handle */

  const handleSubmit = async (data: MetaBusiness) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schemaMeta = Yup.object({
        title: Yup.string().required('Por favor, informe um título!'),
        meta_total: Yup.string().required('Por favor, informe meta total!'),
      });
      await schemaMeta.validate(data, { abortEarly: false });

      if (!selectBusiness?.id) {
        toast('error', 'Error', 'Por favor, selecione a empresa!');
        setOpenModalBusiness(true);
        return;
      }
      await api.post('/meta-business', {
        business_id: selectBusiness?.id,
        title: data.title,
        meta_total: metaTotal,
      });
      navigate.push('/meta-business/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/business')
      .then(({ data }) => {
        setBusiness(data);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/meta-business/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col flex justify-content-between">
              <label htmlFor="basic">Empresa</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectBusiness?.name
                    ? selectBusiness?.name
                    : 'Selecionar Empresa'
                }
                onClick={() => setOpenModalBusiness(true)}
                aria-controls="overlay_panel"
                className="select-product-button w-6"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Título</label>
              <InputText name="title" placeholder="Ex: Minha meta" />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Meta Total</label>
              <InputNumber
                name="meta_total"
                prefix="R$ "
                value={metaTotal}
                onChange={e => setMetaTotal(e.value)}
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="R$ 0,00"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={openModalBusiness}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setOpenModalBusiness(false)}
      >
        <DataTable
          selectionMode="single"
          value={business}
          selection={selectBusiness}
          onSelectionChange={e => {
            setSelectBusiness(e.value);
            setOpenModalBusiness(false);
          }}
          dataKey="id"
          responsiveLayout="scroll"
          emptyMessage="Não existe empresas"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
        >
          <Column field="id" header="#" body={codeColumn}></Column>
          <Column field="name" header="Nome"></Column>
          <Column field="category" header="Logo" body={logoColumn}></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default MainActivityCreate;
