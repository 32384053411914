import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { SideBar as SideBarSearchOsContract } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';

const osContract: React.FC = () => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** hooks */
  const router = useHistory();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [texto, setTexto] = useState<string>('');

  const [contratoOpts, setContratoOpts] = useState<
    { id: string; title: string; text: string }[]
  >([]);

  const [modalSearchOsContractVisible, setModalSearchOsContractVisible] =
    useState<boolean>(false);
  const [contractKeyword, setContractKeyword] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setLoadingStatus(false);
    setId('');
    setTitulo('');
    setTexto('');
    setIsEdit(false);
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        title: Yup.string()
          .min(8, 'O título do contrato deve ter no mínimo 8 caracteres!')
          .required('Informe o título do contrato!'),
        text: Yup.string()
          .min(60, 'O texto do contrato deve ter no mínimo 32 caracteres!')
          .required('Informe o texto do contrato!'),
      });

      await validation.validate(data, { abortEarly: false });

      if (isEdit) {
        const res = await api.put('/os/contract', {
          osContract: {
            id,
            title: titulo,
            text: texto,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Contrato editado com sucesso!');
        }
      } else {
        const res = await api.post('/os/contract', {
          osContract: {
            title: titulo,
            text: texto,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Contrato cadastrado com sucesso!');
        }
      }

      clearForm();
      listContracts();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchContract = async (keyword: string) => {
    if (keyword.length >= 3) {
      await api
        .get(`/os/contract/v1/search${keyword}`)
        .then(({ data }) => {
          const opts = data.searchOsContract.map((i: any) => {
            return {
              id: i.id,
              title: i.title,
              text: i.text,
            };
          });

          setContratoOpts(opts);
        })
        .catch((e: any) => {
          if (e instanceof Yup.ValidationError) {
            const errors = getValidationErrors(e);
            formRef.current?.setErrors(errors);
            toast('error', 'Error', e.errors[0]);
          } else {
            toast('error', 'Error', e.response?.data?.error);
          }
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleSelectContract = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao selecionar o item!');
      return;
    }

    const dt = contratoOpts.find(i => i.id === id);
    if (!dt) {
      toast('warn', 'Alerta', 'Falha ao buscar o item!');
      return;
    }

    setIsEdit(true);

    setId(dt.id);
    setTitulo(dt.title);
    setTexto(dt.text);

    setModalSearchOsContractVisible(false);
  };

  const handleRemContract = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao excluir o item, tente novamente!');
      return;
    }

    try {
      setLoadingStatus(true);

      const res = await api.delete(`/os/contract/${id}`);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item excluído com sucesso!');
      }

      listContracts();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listContracts = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/contract/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsContract.map((i: any) => {
          return {
            id: i.id,
            title: i.title,
            text: i.text,
          };
        });

        setContratoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));
  };

  /** render */
  const renderRemButton = (id: string) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemContract(id)}
      />
    );
  };

  /** useeffect */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/os/contract/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsContract.map((i: any) => {
          return {
            id: i.id,
            title: i.title,
            text: i.text,
          };
        });

        setContratoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => clearForm()}
      />
      <Button
        label="Buscar"
        className="mb-3 mx-2"
        icon="fa-solid fa-search"
        onClick={() => setModalSearchOsContractVisible(true)}
      />
      <Button
        label="O.S"
        className="mb-3 mx-2"
        icon="fa-solid fa-wrench"
        onClick={() => router.push('/os/new')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card">
        <Form ref={formRef} onSubmit={d => handleSubmit(d)}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12">
              <label htmlFor="title">Título do contrato</label>
              <InputText
                name="title"
                placeholder="Ex.: CONTRATO & GARANTIA DE SERVIÇOS"
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12">
              <label htmlFor="text">Conteúdo do contrato</label>
              <InputTextEdit
                name="text"
                style={{ height: '40vh' }}
                value={texto}
                onTextChange={e => setTexto(e.htmlValue as string)}
                placeholder="Ex.: DE UM LADO CONTRATADO ... E DE OUTRO A CONTRATADA"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={isEdit ? 'Editar' : 'Salvar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchOsContract
        visible={modalSearchOsContractVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchOsContractVisible(false)}
      >
        <h3>Buscar contratos</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="keyword"
                placeholder="Contrato de..."
                type={'text'}
                value={contractKeyword}
                onChange={e => setContractKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={contractKeyword.length < 3 ? true : false}
                onClick={() => searchContract(contractKeyword)}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={contratoOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectContract(e.value.id)}
              >
                <Column header="#" body={renderColumnPosition}></Column>
                <Column field="title" header="Título"></Column>
                <Column header="*" body={e => renderRemButton(e.id)}></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchOsContract>
    </>
  );
};

export default osContract;
