import { FormHandles } from '@unform/core';
import { Form as FormDatatable, Form as FormHeader } from '@unform/web';
import { AxiosError } from 'axios';
import { endOfMonth, isDate, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDate from '../../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../../components/DataTableColumns/RenderColumnStatus';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import MenuPopUp from '../../../../components/MenuPopPup';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import ClearName from '../../../../utils/ClearName';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import openReports from '../../../../utils/openReports';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import IDevolution from '../dtos/IDevolution';
import OrderDevolutionHeader from '../header';
import ReportDevolution from '../report/devolution';

const OrderDevolutionList = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formHeaderRef = useRef<FormHandles>(null);
  const formDatatableRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [reportDevolutionVisible, setReportDevolutionVisible] =
    useState<boolean>(false);

  const [customerKeyword, setCustomerKeyword] = useState<string>('');
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const [devolutions, setDevolutions] = useState<Array<IDevolution>>([]);
  const [devolution, setDevolution] = useState<IDevolution>();

  const [statusOpts, setStatusOpts] = useState<IOptionsDTO[]>([
    { value: 'A', label: 'Ativo' },
    { value: 'C', label: 'Cancelado' },
  ]);
  const [status, setStatus] = useState<Array<string>>(['A']);

  /** functions */
  const handleSubmit = async (d: any) => {
    return null;
  };

  const searchDevolutions = async () => {
    if (!isDate(dateStart)) {
      toast('warn', 'Alerta', 'A data de início da pesquisa é inválida!');
      return;
    }

    if (!isDate(dateEnd)) {
      toast('warn', 'Alerta', 'A data final da pesquisa é inválida!');
      return;
    }

    if (status.length === 0) {
      toast('warn', 'Alerta', 'Informe pelo menos um status para a pesquisa!');
      return;
    }

    setLoadingStatus(true);
    await api
      .post('/devolution/search', {
        filter: {
          customer: customerKeyword || '',
          date_start: dateStart as Date,
          date_end: dateEnd as Date,
          status: status.length === 0 ? ['A', 'C'] : status,
        },
      })
      .then(({ data }) => {
        if (data.searchOrderDevolution.length > 0) {
          const opts = data.searchOrderDevolution.map((i: IDevolution) => {
            return {
              id: i.id,
              devolution: i.devolution,
              nat_ope_id: i.nat_ope_id,
              nature_operation: i.nature_operation,
              order_id: i.order_id,
              order: i.order,
              customer: i.customer,
              date: i.date,
              total: i.total,
              user_devolution_id: i.user_devolution_id,
              user: i.user,
              status: i.status,
            };
          });

          setDevolutions(opts);
        }
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          if (e.response?.data?.message === 'Validation failed') {
            toast('warn', 'Alerta', e.response?.data.validation.body.message);
          }
        }

        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formHeaderRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0]);
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleCancelDevolution = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'O id da devolução é inválido!');
      return;
    }
    setLoadingStatus(true);
    await api
      .delete(`/devolution/${id}`)
      .then(d => {
        if (d.status >= 200 || d.status <= 299) {
          toast('success', 'Sucesso', 'Devolução cancelada com sucesso!');
          searchDevolutions();
        }
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          if (e.response?.data?.message === 'Validation failed') {
            toast(
              'warn',
              'Alerta',
              e.response?.data.validation.body.message,
              10000,
            );
          }
        }

        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formHeaderRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0], 10000);
        } else {
          toast('error', 'Error', e.response?.data?.error, 10000);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const handlePrintDevolution = async (id: string) => {
    try {
      setLoadingStatus(true);

      const { data } = await api.get(`/reports/devolution/${id}`);

      openReports(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
        'devolucao',
      );
    } catch (e: any) {
      toast(
        'warn',
        'Alerta',
        'Falha ao abrir o relatório, tente novamente!',
        5000,
      );
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSelectDevolution = (d: DataTableSelectionChangeParams) => {
    return null;
  };

  /** renders */
  const mountMenu = (row: IDevolution) => {
    const items: any[] = [];

    items.push({
      label: 'Imprimir',
      icon: 'fa-solid fa-print',
      command: () => handlePrintDevolution(row.id),
    });

    items.push({
      label: 'Emitir Nfe',
      icon: 'fa-solid fa-file',
      command: () => alert('Função em homologação!'),
    });

    items.push({
      label: 'Cancelar',
      icon: 'fa-solid fa-xmark',
      command: () => handleCancelDevolution(row.id),
    });

    return items;
  };

  const renderTableMenu = (row: any) => {
    const menuOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={menuOpts} rowData={row} setData={setDevolution} />
      </>
    );
  };

  /** useEffect */
  useEffect(() => {
    searchDevolutions();
  }, []);

  return (
    <>
      <OrderDevolutionHeader />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <FormHeader
        ref={formHeaderRef}
        onSubmit={e => handleSubmit(e)}
        className="card mb-2 pb-2"
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-4">
            <label htmlFor="customer_keyword">Cliente</label>
            <InputText
              name="customer_keyword"
              type="search"
              value={customerKeyword}
              onChange={e => setCustomerKeyword(e.currentTarget.value)}
              placeholder="Ex: João da silva..."
              min={3}
              max={32}
              autoComplete="off"
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="date_start">Período inicial</label>
            <Calendar
              name="date_start"
              value={dateStart}
              onChange={e => setDateStart(e.value as Date)}
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              showIcon
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="date_end">Período final</label>
            <Calendar
              name="date_end"
              value={dateEnd}
              onChange={e => setDateEnd(e.value as Date)}
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              showIcon
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="status">Status</label>
            <MultiSelect
              name="status"
              options={statusOpts}
              value={status}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
              display="chip"
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              label="Buscar"
              icon="pi pi-filter"
              className="p-button p-button-success"
              onClick={e => searchDevolutions()}
              type="submit"
            />
          </div>
        </div>
      </FormHeader>

      <FormDatatable
        ref={formHeaderRef}
        onSubmit={e => handleSubmit(e)}
        className="card"
      >
        <DataTable
          dataKey="id"
          value={devolutions}
          selection={devolution}
          onSelectionChange={e => handleSelectDevolution(e.value)}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} pedidos"
          emptyMessage="Não existem devoluções..."
          responsiveLayout="scroll"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column header="Dev." field="devolution" align={'center'}></Column>
          <Column header="Ped." field="order" align={'center'}></Column>
          <Column header="Nat. Ope." field="nature_operation"></Column>
          <Column header="Cliente" field="customer"></Column>
          <Column
            header="Data"
            field="date"
            body={e => renderColumnDate(e.date)}
            align={'center'}
          ></Column>
          <Column
            header="Total"
            field="total"
            body={e => renderColumnDecimal(e.total)}
            align={'right'}
          ></Column>
          <Column
            header="Status"
            field="status"
            body={e => renderColumnStatus(e)}
            align={'center'}
          ></Column>
          <Column
            header="Usuário"
            field="user"
            body={e => ClearName(e.user)}
            align={'left'}
          ></Column>
          <Column
            header="*"
            body={e => renderTableMenu(e)}
            align={'center'}
          ></Column>
        </DataTable>
      </FormDatatable>

      <ReportDevolution
        key={v4()}
        id={v4()}
        isOpen={reportDevolutionVisible}
        onRequestClose={() => setReportDevolutionVisible(false)}
      />
    </>
  );
};

export default OrderDevolutionList;
