import React, { useEffect, useRef, useState } from 'react';

/* unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** utils */
import useToastContext from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getErrorsValidation';

/** service */
import api from '../../../services/api';

/* react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** components */
import { format } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText as InText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { TabPanel } from 'primereact/tabview';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDateTime from '../../../components/DataTableColumns/RenderColumnDateTime';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { SideBar } from '../../../components/Sidebar';
import { TabViews } from '../../../components/TabPanel';
import { Option } from '../../../types/optional';
import { Person } from '../../../types/person';
import convertToNumber, {
  currencyToFloat,
  numberToCurrency,
} from '../../../utils/number';
import messageRequestError from '../../../utils/messageRequestError';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

type TypePaymentResponse = {
  id: string;
  title: string;
};

type CreditAnalysisCreateRequest = {
  person_id: string;
  payment_condition_id: string;
  limit_brute: string;
  date_on: string;
  date_off: string;
  days_to_block: string;
  desc_autorized: string;
};

const CreditAnalysisEdit = (): React.ReactElement => {
  const [isLoad, setIsLoad] = useState<boolean>(false);

  /** customer */
  const [customers, setCustomers] = useState(undefined);
  const [customerSelected, setCustomerSelected] = useState<Option>();
  const [search, setSearchCustomer] = useState('');

  /** width sidebar */
  const [width, setWidth] = useState('60vw');

  /** block */
  const [block, setBlock] = useState<boolean>(false);

  /** params */
  const params: { id: string } = useParams();

  /** date */
  const [dateOn, setDateOn] = useState<Date | Date[] | undefined>(new Date());
  const [dateOff, setDateOff] = useState<Date | Date[] | undefined>(new Date());

  /** payment condition */
  const [paymentCondition, setPaymentCondition] = useState<Option[]>([]);
  const [paymentConditionSelect, setPaymentConditionSelect] =
    useState<string>();

  /** limit grots */
  const [grossLimit, setLimitGrossLimit] = useState(0);

  /** descount */
  const [descount, setDescount] = useState<number | null>(0);

  /** consumed limit */
  const [consumedLimit, setConsumedLimit] = useState('');

  /** person id */
  const [personId, setPersonId] = useState('');

  /** limit liquid */
  const [limitLiquid, setLimitLiquid] = useState('');

  /** edition history */
  const [editionHistory, setEditionHistory] = useState();

  /** blocked days */
  const [blockedDays, setBlockDays] = useState<number | undefined>(0);

  /** aside  */
  const [purchasesHistoricSideBar, setPurchasesHistoricSideBar] =
    useState(false);
  const [purchasesHistoric, setPurchasesHistoric] = useState<any[]>();

  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState();

  const [expiredDocsModal, setExpiredDocsModal] = useState(false);
  const [expiredDocs, setExpiredDocs] = useState<any[]>();

  const [toExpiredDocsModal, setToExpiredDocsModal] = useState(false);

  const [toProductsCompostModal, setToProductsCompostModal] = useState(false);
  const [productsCompost, setProductCompost] = useState<any[]>();

  const [creditAnalysisHistoric, setCreditAnalysisHistoric] = useState<any[]>();

  /** toast */
  const toast = useToastContext();

  const apiGetHistoricPurchase = async () => {
    setIsLoad(true);
    const { data } = await api
      .get(`order-header/by-customer-id/${personId}`)
      .finally(() => setIsLoad(false));
    setPurchasesHistoric(data.orders);
  };

  const apiGetPaymentHistoric = async () => {
    setIsLoad(true);
    await api
      .get(`/bills-receivable/by-customer-id/${personId}`)
      .then(({ data }) => {
        const opts = data.drops.map((i: any) => {
          return {
            id: i.id,
            operacao: Number(i.operacao),
            registradora: Number(i.registradora),
            doc_serie: i.doc_serie,
            doc_numero: i.doc_numero,
            parcela: Number(i.parcela),
            serie_parcela: i.serie_parcela,
            vencimento: i.vencimento,
            pagamento: i.pagamento,
            baixa: Number(i.baixa),
            tipo_baixa: i.tipo_baixa,
          };
        });
        setPaymentHistory(opts);
      })
      .finally(() => setIsLoad(false));
  };

  const apiGetDocsExpired = async () => {
    setIsLoad(true);
    const { data } = await api
      .get(`/bills-receivable/v1/overdue/by-customer-id/${personId}`)
      .finally(() => setIsLoad(false));
    setExpiredDocs(data);
  };

  const apiGetToExpiredDocs = async () => {
    setIsLoad(true);
    const { data } = await api
      .get(`/bills-receivable/v1/opened/by-customer-id/${personId}`)
      .finally(() => setIsLoad(false));
    setExpiredDocs(data);
  };

  const apiGetToProductsCompost = async () => {
    setIsLoad(true);
    const { data } = await api
      .get(`/persons/most-acquired-products/${personId}`)
      .finally(() => setIsLoad(false));

    setProductCompost(data.mostAcquired);
  };

  /** open modal historic purchase and loading data */

  const loadingHistoricPurchase = () => {
    apiGetHistoricPurchase();
    setPurchasesHistoricSideBar(true);
  };

  const loadingPaymentHistoric = () => {
    apiGetPaymentHistoric();
    setPaymentHistoryModal(true);
  };

  const loadingExpiredDocs = () => {
    apiGetDocsExpired();
    setExpiredDocsModal(true);
  };

  const loadingToExpiredDocs = () => {
    apiGetToExpiredDocs();
    setToExpiredDocsModal(true);
  };

  const loadingProductCompost = () => {
    apiGetToProductsCompost();
    setToProductsCompostModal(true);
  };

  /** navigate */
  const navigate = useHistory();

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: Person) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  /** ref */
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = async (data: CreditAnalysisCreateRequest) => {
    try {
      setIsLoad(true);

      data.person_id = customerSelected?.value || '';

      const schemaCreditAnalysis = Yup.object({
        person_id: Yup.string()
          .required('Por favor, selecione um cliente!')
          .uuid('Id do cliente inválido!'),
        payment_condition_id: Yup.string().required(
          'Por favor, selecione condição de pagamento preferencial!',
        ),
        limit_brute: Yup.string().required(
          'Por favor, informe o limite bruto!',
        ),
        date_on: Yup.string().required(
          'Por favor, informe o começo da validade!',
        ),
        date_off: Yup.string().required(
          'Por favor, informe o final da validade!',
        ),
        days_to_block: Yup.string().required(
          'Por favor, informe a quantidade de dias para bloqueio!',
        ),
        desc_autorized: Yup.string().required('Por favor, informe o desconto'),
      });
      await schemaCreditAnalysis.validate(data, { abortEarly: false });

      await api.put(`/credit-analise/${params.id}`, {
        ...data,
        limit_brute: parseFloat(convertToNumber(data.limit_brute)),
        days_to_block: parseInt(data.days_to_block),
        desc_autorized: parseFloat(
          data.desc_autorized.replace('%', '').replace(',', '.'),
        ),
        date_on: dateOn,
        date_off: dateOff,
        is_block: block,
      });
      navigate.push('/credit-analise/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  /** template auto complete */
  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** search customer */

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const customerOption = ammountOptions(customers);

        setCustomers(customerOption);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleBlockPerson = async (isBlock: boolean) => {
    setIsLoad(true);

    await api
      .put(`/persons/${isBlock ? 'disable' : 'enable'}/${personId}`)
      .then(({ data: res }) => {
        if (res) {
          toast(
            'success',
            'Sucesso',
            `Cliente ${isBlock ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
          );

          setBlock(isBlock);
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  /** select an customer */

  const selectCustomer = (e: Option) => {
    setCustomerSelected(e);
  };

  /** table */
  const dateColumnTable = (e: any) => {
    return <span>{format(new Date(e.created_at), 'dd/MM/yyyy')}</span>;
  };

  const limitBeforeTable = (e: any) => {
    return <span>R$ {numberToCurrency(parseFloat(e.value_old))}</span>;
  };

  /** table */
  const columBodyDate = (date: string) => {
    return <span>{format(new Date(date), 'dd/MM/yyyy')}</span>;
  };

  useEffect(() => {
    setIsLoad(true);

    api.get(`/credit-analise/${params.id}`).then(({ data }) => {
      setCustomerSelected({
        label: data.personShort.name,
        value: data.personShort.id,
      });
      setSearchCustomer(data.personShort.name);
      setPersonId(data.person_id);
      setPaymentConditionSelect(data.payment_condition.id);
      setDateOff(new Date(data.date_off));
      setDateOn(new Date(data.date_on));
      formRef.current?.setData({
        days_to_block: data.days_to_block,
        desc_autorized: `${numberToCurrency(parseFloat(data.desc_autorized))}%`,
        limit_brute: `${numberToCurrency(parseFloat(data.limit_brute))}`,
      });

      setBlock(data.is_block);
      setConsumedLimit(
        numberToCurrency(
          parseFloat(data.limit_brute) - parseFloat(data.limit_liquid),
        ),
      );
      setDescount(parseFloat(data.desc_autorized));
      setLimitLiquid(numberToCurrency(parseFloat(data.limit_liquid)));
      setLimitGrossLimit(currencyToFloat(data.limit_brute));
    });

    api.get('/payments-condition').then(({ data }) => {
      const typePayments = data.map((item: TypePaymentResponse) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      setPaymentCondition(typePayments);
    });
    api.get(`/credit-analise-historic/${params.id}`).then(({ data }) => {
      setEditionHistory(data);
    });

    setIsLoad(false);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  });

  useEffect(() => {
    api.get(`/credit-analise/${params.id}`).then(({ data }) => {
      const options = [
        {
          description: 'Primeira Compra',
          date: data.primeira_compra_dt
            ? format(new Date(data.primeira_compra_dt), 'dd/MM/yyyy')
            : '00/00/00',
          value: `R$ ${numberToCurrency(parseFloat(data.primeira_compra_vlr))}`,
        },
        {
          description: 'Última Compra',
          date: data.ultima_compra_dt
            ? format(new Date(data.ultima_compra_dt), 'dd/MM/yyyy')
            : '00/00/00',
          value: `R$ ${numberToCurrency(parseFloat(data.ultima_compra_vlr))}`,
        },
        {
          description: 'Menor Compra',
          date: data.menor_compra_dt
            ? format(new Date(data.menor_compra_dt), 'dd/MM/yyyy')
            : '00/00/00',
          value: `R$ ${numberToCurrency(parseFloat(data.menor_compra_vlr))}`,
        },
        {
          description: 'Maior Compra',
          date: data.maior_compra_dt
            ? format(new Date(data.maior_compra_dt), 'dd/MM/yyyy')
            : '00/00/00',
          value: `R$ ${numberToCurrency(parseFloat(data.maior_compra_vlr))}`,
        },
        {
          description: 'Qnt Total de Compras',
          value: data.qnt_total_compras,
        },
        {
          description: 'Frequência de Compras',
          value: `[${data.frequencia_compra}]`,
        },
        {
          description: 'Menor Atraso',
          value: `[${data.menor_atraso}]`,
        },
        {
          description: 'Documentos Recebidos',
          value: data.doc_recebidos,
        },
        {
          description: 'Documentos Vencidos',
          value: data.doc_vencidos,
        },
        {
          description: 'Documentos a Vencer',
          value: data.doc_vencer,
        },
      ];
      setCreditAnalysisHistoric(options);
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        type="button"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/credit-analise/list')}
      />
      {isLoad && <ProgressBar mode="indeterminate" style={{ height: '4px' }} />}
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <TabViews>
            <TabPanel header="Análise">
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6">
                  <label htmlFor="basic">Cliente</label>
                  <InputAutoComplete
                    name="person_id"
                    value={search}
                    completeMethod={e => searchCustomer(e.query)}
                    suggestions={customers}
                    field="label"
                    onSelect={e => selectCustomer(e.value)}
                    onChange={e => setSearchCustomer(e.value)}
                    itemTemplate={itemTemplate}
                    disabled
                    style={{ background: 'var(--surface-200)' }}
                    placeholder="Buscar cliente..."
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="basic">
                    Condição de pagamento preferencial
                  </label>
                  <InputDropDown
                    name="payment_condition_id"
                    options={paymentCondition}
                    value={paymentConditionSelect}
                    onChange={e => setPaymentConditionSelect(e.value)}
                    placeholder="Selecione..."
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Válido à partir de</label>
                  <Calendar
                    name="date_on"
                    value={dateOn}
                    onChange={e => setDateOn(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Válido até</label>
                  <Calendar
                    name="date_off"
                    value={dateOff}
                    onChange={e => setDateOff(e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Bloqueia com quantos dias?</label>
                  <InputNumber
                    name="days_to_block"
                    mode="decimal"
                    showButtons
                    value={blockedDays}
                    onChange={e => setBlockDays(e.value as number)}
                    min={0}
                    max={100}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Desconto autorizado (%)</label>
                  <InputNumber
                    mode="decimal"
                    name="desc_autorized"
                    suffix="%"
                    value={descount}
                    onChange={e => setDescount(e.value)}
                    placeholder="0,00%"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-4">
                <div className="field col-12 md:col-3 flex align-items-end justify-content-center">
                  <InputSwitch
                    className="mr-4"
                    checked={block}
                    onChange={e => handleBlockPerson(e.value)}
                    name="is_block"
                  />
                  <label htmlFor="basic" className="m-0">
                    Bloqueado?
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Limite consumido</label>
                  <InText
                    disabled
                    value={consumedLimit}
                    className="surface-200"
                    placeholder="0,00"
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Limite bruto</label>
                  <InputNumber
                    name="limit_brute"
                    value={grossLimit}
                    placeholder="R$ 0,00"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="basic">Limite disponivel</label>
                  <InText
                    disabled
                    value={limitLiquid}
                    className="surface-200"
                    placeholder="0,00"
                  />
                </div>
              </div>
              <div className="p-fluid grid  formgrid mb-3 flex">
                <div className="field col-12 md:col-6">
                  <DataTable
                    value={editionHistory}
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 20, 30]}
                    size="small"
                  >
                    <Column
                      field="edit"
                      body={dateColumnTable}
                      header="Data Edição"
                    ></Column>
                    <Column
                      field="id"
                      header="Limite Anterior"
                      body={limitBeforeTable}
                    ></Column>
                    <Column field="user.name" header="Usuário"></Column>
                  </DataTable>
                </div>

                <div className="field col-12 md:col-6">
                  <Button
                    type="button"
                    icon="pi pi-search"
                    label={'Histórico de compras'}
                    aria-haspopup
                    onClick={() => loadingHistoricPurchase()}
                    className="select-product-button mt-3"
                  />
                  <Button
                    type="button"
                    icon="pi pi-search"
                    label={'Histórico de pagamentos'}
                    aria-haspopup
                    onClick={() => loadingPaymentHistoric()}
                    className="select-product-button mt-3"
                  />
                  <Button
                    type="button"
                    icon="pi pi-search"
                    label={'Documentos vencidos'}
                    aria-haspopup
                    onClick={() => loadingExpiredDocs()}
                    className="select-product-button mt-3"
                  />
                  <Button
                    type="button"
                    icon="pi pi-search"
                    label={'Documentos à vencer'}
                    aria-controls="overlay_panel"
                    onClick={() => loadingToExpiredDocs()}
                    className="select-product-button mt-3"
                  />
                  <Button
                    type="button"
                    icon="pi pi-search"
                    label={'Produtos + comprados'}
                    onClick={() => loadingProductCompost()}
                    className="select-product-button mt-3"
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6">
                  <Button
                    label="Limpar"
                    type="reset"
                    icon="pi pi-times"
                    className="p-button-danger"
                    loading={isLoad}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <Button
                    label="Salvar"
                    icon="pi pi-check"
                    className="p-button-success"
                    type="submit"
                    loading={isLoad}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Dados">
              <DataTable
                value={creditAnalysisHistoric}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                className="w-6"
                responsiveLayout="scroll"
              >
                <Column field="description" header="Descrição" />
                <Column field="date" header="Data" />
                <Column field="value" header="Valor" />
              </DataTable>
            </TabPanel>
          </TabViews>
        </Form>
      </div>
      {/* sidebar */}
      <SideBar
        onHide={() => setPurchasesHistoricSideBar(false)}
        visible={purchasesHistoricSideBar}
        position="right"
        style={{ width }}
      >
        <h3>Histórico de compras</h3>
        <DataTable
          value={purchasesHistoric}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          emptyMessage="Não existe informações"
          responsiveLayout="scroll"
        >
          <Column field="cliente" header="Cliente" />
          <Column field="comissionado" header="Comissionado" />
          <Column field="pedido" header="Pedido" />
          <Column field="cond_pagamento" header="Cond Pag" />
          <Column field="loja" header="Loja" />
          <Column
            header="Vencimento"
            body={(e: any) => columBodyDate(e.data_venda)}
          />
          <Column field="natureza_operacao" header="Natureza Operação" />
          <Column
            field="total"
            header="Total"
            body={(e: any) => renderColumnDecimal(e.total)}
            align={'right'}
          />
        </DataTable>
      </SideBar>
      <SideBar
        onHide={() => setPaymentHistoryModal(false)}
        visible={paymentHistoryModal}
        position="right"
        style={{ width }}
      >
        <h3>Histórico de pagamentos</h3>
        <DataTable
          value={paymentHistory}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
          emptyMessage="Nenhuma informção encontrada!"
        >
          <Column header="#" body={renderColumnPosition} />
          <Column field="registradora" header="Reg." align={'center'} />
          <Column field="operacao" header="Ope." align={'center'} />
          <Column field="doc_numero" header="Num" align={'center'} />
          <Column field="doc_serie" header="Série" align={'center'} />
          <Column field="parcela" header="Parcela" align={'center'} />
          <Column field="serie_parcela" header="Sequência" align={'center'} />
          <Column
            field="vencimento"
            header="Vencimento"
            body={e => renderColumnDate(e.vencimento)}
            align={'center'}
          />
          <Column field="tipo_baixa" header="Tipo baixa" align={'center'} />
          <Column
            header="Baixa"
            body={(e: any) => renderColumnDecimal(e.baixa)}
            align={'right'}
          />

          <Column
            field="pagamento"
            header="Pagamento"
            body={e => renderColumnDateTime(e.pagamento)}
          />
        </DataTable>
      </SideBar>
      <SideBar
        onHide={() => setExpiredDocsModal(false)}
        visible={expiredDocsModal}
        position="right"
        style={{ width }}
      >
        <h3>Documentos vencidos</h3>
        <DataTable
          value={expiredDocs}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
          emptyMessage="Nenhuma informção encontrada!"
        >
          <Column header="#" body={renderColumnPosition} />
          <Column field="cliente" header="Cliente" />
          <Column field="doc_numero" header="Doc. Num" />
          <Column field="doc_serie" header="Doc. Série" />
          <Column field="parcela" header="Parcela" />
          <Column field="serie_parcela" header="Série Parcela" />
          <Column
            field="vencimento"
            header="Vencimento"
            body={(e: any) => columBodyDate(e.vencimento)}
          />
          <Column
            field="valor"
            header="Valor"
            body={e => renderColumnDecimal(e.valor)}
            align={'right'}
          />
        </DataTable>
      </SideBar>
      <SideBar
        onHide={() => setToExpiredDocsModal(false)}
        visible={toExpiredDocsModal}
        position="right"
        style={{ width }}
      >
        <h3>Documentos à vencer</h3>
        <DataTable
          value={expiredDocs}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
          emptyMessage="Nenhuma informção encontrada!"
        >
          <Column header="#" body={renderColumnPosition} />
          <Column field="cliente" header="Cliente" />
          <Column field="doc_numero" header="Doc. Num" />
          <Column field="doc_serie" header="Doc. Série" />
          <Column field="parcela" header="Parcela" />
          <Column field="serie_parcela" header="Série Parcela" />
          <Column
            field="vencimento"
            header="Vencimento"
            body={(e: any) => columBodyDate(e.vencimento)}
          />
          <Column field="valor" header="Valor" />
        </DataTable>
      </SideBar>
      <SideBar
        onHide={() => setToProductsCompostModal(false)}
        visible={toProductsCompostModal}
        position="right"
        style={{ width }}
      >
        <h3>Produtos mais comprados</h3>
        <DataTable
          value={productsCompost}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
          emptyMessage="Nenhuma informção encontrada!"
        >
          <Column header="#" body={renderColumnPosition} />
          <Column field="codigo" header="Código" align={'center'} />
          <Column field="descricao" header="Descrição" />
          <Column
            field="estoque"
            header="Estoque"
            body={e => renderColumnDecimal(e.estoque)}
            align={'right'}
          />
          <Column
            field="custo"
            header="Custo"
            body={e => renderColumnDecimal(e.custo)}
            align={'right'}
          />
          <Column
            field="qnt"
            header="Qnt"
            body={e => renderColumnDecimal(e.qnt)}
            align={'right'}
          />
          <Column
            field="total"
            header="Total"
            body={e => renderColumnDecimal(e.total)}
            align={'right'}
          />
          <Column field="unidade" header="Unidade" align={'center'} />
        </DataTable>
      </SideBar>
    </>
  );
};

export default CreditAnalysisEdit;
