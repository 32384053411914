/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';

/** Unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** sevice */
import api from '../../../services/api';

/** react-router-dom */
import { useParams } from 'react-router-dom';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** Yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';

const BrandsEdit = (): React.ReactElement => {
  /** state */
  const [totalSize, setTotalSize] = useState(0);

  const [title, setTitle] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  /** params */
  const params: { id: string } = useParams();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** ref */
  const toast = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);
  const fileUploadRef = useRef<any>(null);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** navigate */
  const navigate = useHistory();

  /** Uplaod options */

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };
  const emptyTemplate = () => {
    return !imageUrl ? (
      <>
        <div className="flex align-items-center flex-column">
          <i
            className="pi pi-image mt-3 p-5"
            style={{
              fontSize: '5em',
              borderRadius: '50%',
              backgroundColor: 'var(--surface-b)',
              color: 'var(--surface-d)',
            }}
          ></i>
          <span
            style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
            className="my-5"
          >
            Arraste e solte a imagem aqui
          </span>
        </div>
      </>
    ) : (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt=""
            role="presentation"
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${imageUrl}`}
            width={100}
          />
        </div>

        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 40, height: 40 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => setImageUrl(undefined)}
        />
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    if (props.files.length > 1) {
      props.files[0] = props.files[1];

      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 40, height: 40 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-plus',
    label: 'Escolher Imagem',
    className: 'custom-choose-btn',
  };

  const handleSubmit = async (data: { title: string }) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const schemaBrands = Yup.object({
        title: Yup.string().required('Por favor, informe um título'),
      });

      await schemaBrands.validate(data, { abortEarly: false });

      await api.put(`/brands/${params.id}`, {
        title: data.title,
        image_url: imageUrl,
      });

      if (fileUploadRef.current?.files) {
        const form = new FormData();
        form.append('image_url', fileUploadRef.current.files[0]);
        const aux = await api.patch(`/brands/image/${params.id}`, form);
      }

      showToast('success', '', 'Marca editada com sucesso!');

      navigate.push('/brands/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get(`/brands/${params.id}`)
      .then(({ data }) => {
        setTitle(data.title);
        setImageUrl(data.image_url);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Toast ref={toast}></Toast>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/brands/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Título</label>
              <InputText
                name="title"
                value={title}
                onChange={e => setTitle(e.currentTarget.value)}
                placeholder="Marca famosa"
              />
            </div>
            <div className="field col-12 md:col">
              <FileUpload
                ref={fileUploadRef}
                name="file"
                maxFileSize={100000}
                multiple
                accept="image/png, image/jpeg, image/jpg, image/bmp"
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                chooseOptions={chooseOptions}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                invalidFileSizeMessageSummary=""
                invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BrandsEdit;
