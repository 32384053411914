import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const WalletHeader = (): React.ReactElement => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Listar"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/wallet/list')}
      />
      <Button
        label="Nova"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/wallet/create')}
      />
      <Button
        label="Gráfico"
        className="mb-2 mr-2"
        icon="fa-solid fa-chart-column"
        onClick={() => navigate.push('/wallet/resumo')}
      />
      <Button
        label="Relatório"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/report/wallet')}
      />
    </>
  );
};

export default WalletHeader;
