import React, { useEffect, useRef, useState } from 'react';

/** react router dom */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from '../../../../components/DataTable';
import { SideBar } from '../../../../components/Sidebar';

/** api */
import api from '../../../../services/api';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import InventoryHeaderButtons from '../../../inventory/header-buttons';

const ReportsInventoryStock: React.FC = (): React.ReactElement => {
  const [selectProviderModal, setSelectProviderModal] = useState(false);

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** error */
  const [error, setError] = useState(false);

  const selectInventory = (e: any) => {
    setSelectInventory(e.value);
    setSelectProviderModal(false);
    setError(false);
  };

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** inventory */
  const [inventory, setInventory] = useState();

  const [selectedInvetory, setSelectInventory] = useState<any>();

  const [width, setWidth] = useState('60vw');

  /** router */
  const router = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const qntBodyTemplate = (rowData: any) => {
    return <span>{rowData.items.length}</span>;
  };

  const renderColumnInventory = (row: any) => {
    return (
      <span>
        Num. {row.num_sequencia} | Abertura:{' '}
        {format(new Date(row.date_open), 'dd/MM/yy H:mm')}
      </span>
    );
  };

  const handleReports = async () => {
    try {
      if (!selectedInvetory?.id) {
        setError(true);
        showToast('error', 'Error', 'Por favor, informe o inventário!');
        return;
      }

      const { data } = await api.get(
        `/reports/inventory/count/${selectedInvetory.id}`,
      );
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error?.response?.data.error);
    }
  };
  useEffect(() => {
    api.get(`/inventory/full`).then(({ data }) => {
      setInventory(data);
    });
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  }, []);

  return (
    <>
      <InventoryHeaderButtons />
      <Toast ref={toast} />
      <div className="card md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Inventário</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedInvetory
                    ? `Num. ${selectedInvetory.num_sequencia}`
                    : 'Selecionar Inventário'
                }
                aria-haspopup
                onClick={() => setSelectProviderModal(true)}
                aria-controls="overlay_panel"
                className={
                  error
                    ? `select-product-button w-7 p-button-raised p-button-danger`
                    : `select-product-button w-7`
                }
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <Button
                label="Imprimir"
                className="p-button-success"
                icon="fa-solid fa-print"
                type="submit"
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={selectProviderModal}
        position="right"
        style={{ width }}
        onHide={() => setSelectProviderModal(false)}
      >
        <DataTable
          value={inventory}
          selectionMode="single"
          selection={selectedInvetory}
          onSelectionChange={e => selectInventory(e)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
        >
          <Column header="#" body={codeBodyTemplate}></Column>
          <Column header="Id" body={e => renderColumnInventory(e)}></Column>
          <Column
            field="items"
            header="Qnt de Items"
            body={qntBodyTemplate}
          ></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default ReportsInventoryStock;
