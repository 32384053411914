import React, { useEffect, useRef, useState } from 'react';

/** Form */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** services */
import api from '../../../../services/api';

/** react router dom */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';
import { Option } from '../../../../types/optional';

const especieOption = [
  { value: '01', label: 'DM - Duplicata Mercantil' },
  { value: '02', label: 'NP - Nota promissória' },
  { value: '03', label: 'NS - Nota de seguro' },
  { value: '04', label: 'DS - Duplicata de Serviço' },
  { value: '05', label: 'REC - Recibo' },
  { value: '06', label: 'LC - Letra de Câmbio' },
  { value: '07', label: 'ND - Nota de Débito' },
  { value: '08', label: 'BDP - Boleto de Proposta' },
  { value: '09', label: 'LC - Letra de Câmbio' },
  { value: '10', label: 'WR - Warrant' },
  { value: '11', label: 'CH - Cheque' },
  { value: '12', label: 'CS - Cobrança Seriada' },
  { value: '13', label: 'ME - Mensalidade escolar' },
  { value: '14', label: 'AS - Apólice de Seguro' },
  { value: '15', label: 'DD - Documento de Dívida' },
  { value: '16', label: '-- - Encargos Condominiais' },
  { value: '17', label: '-- - Conta de prestação de serviço' },
  { value: '18', label: '-- - Contrato' },
  { value: '19', label: '-- - Cosseguro' },
  { value: '20', label: 'DR - Duplicata Rural' },
  { value: '21', label: 'NPR - Nota Promissória Rural' },
  { value: '22', label: '-- - Dívida Ativa da União' },
  { value: '23', label: '-- - Dívida Ativa de Estado' },
  { value: '24', label: '-- - Dívida Ativa de Município' },
  { value: '25', label: 'DMI - Duplicata Mercantil por Indicação' },
  { value: '26', label: 'DSI - Duplicata de Serviço por Indicação' },
  { value: '27', label: 'NCC - Nota de Crédito Comercial' },
  { value: '28', label: 'NCE - Nota de Crédito para Exportação' },
  { value: '29', label: 'NCI - Nota de Crédito Industrial' },
  { value: '30', label: 'NCR - Nota de Crédito Rural' },
  { value: '32', label: 'TM - Triplicata Mercantil' },
  { value: '33', label: 'TS - Triplicata de Serviço' },
  { value: '34', label: 'FAT - Fatura' },
  { value: '35', label: 'PC - Parcela de Consórcio' },
  { value: '36', label: 'NF - Nota Fiscal' },
  { value: '37', label: 'CPR - Cédula de Produto Rural' },
  { value: '38', label: 'CC - Cartão de crédito' },
  { value: '99', label: '-- - Outros' },
];

const optionProtest = [
  { value: '1', label: 'Protestar Dias Corridos.' },
  { value: '2', label: 'Protestar Dias Úteis.' },
  { value: '3', label: 'Não Protestar.' },
  { value: '4', label: 'Protestar Fim Falimentar - Dias Úteis.' },
  { value: '5', label: 'Protestar Fim Falimentar - Dias Corridos.' },
  { value: '8', label: 'Negativação sem Protesto.' },
  {
    value: '9',
    label:
      'Cancelamento Protesto Automático (somente válido p/ Código Movimento Remessa = 31).',
  },
];

const devolution = [
  { value: '1', label: 'Baixar/Devolver.' },
  { value: '2', label: 'Não baixar / Não devolver.' },
  { value: '3', label: 'Cancelar prazo para baixa / Devolução.' },
];

const codDescount = [
  { value: '0', label: 'Sem instrução de desconto.' },
  { value: '1', label: 'Valor Fixo Até a Data Informada.' },
  { value: '2', label: 'Percentual Até a Data Informada.' },
  { value: '3', label: 'Valor por Antecipação Dia Corrido..' },
  { value: '4', label: 'Valor por Antecipação Dia Útil.' },
  { value: '5', label: 'Percentual Sobre o Valor Nominal Dia Corrido.' },
  { value: '6', label: 'Percentual Sobre o Valor Nominal Dia Útil.' },
];

const codJuros = [
  { value: '1', label: 'Valor por dia.' },
  { value: '2', label: 'Taxa mensal.' },
  { value: '3', label: 'Isento.' },
];

const multa = [
  { value: '0', label: 'Não registra multa (isento).' },
  { value: '1', label: 'Valor em Reais (Fixo).' },
  { value: '2', label: 'Valor em percentual com duas casas decimais..' },
];

const SetupCreate = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** convention */
  const [convention, setConvention] = useState<Array<Option>>([]);
  const [selectedConvention, setSelectedConvention] = useState();

  /** especie option */
  const [selectedEspecie, setSelectedEspecie] = useState();

  /** protest */
  const [selectedProtest, setSelectedProtest] = useState();

  /** devolution */
  const [selectedDevolution, setSelectedDevolution] = useState();

  /** descount */
  const [selectedDescount, setSelectedDescount] = useState();

  /** how  */
  const [selectedHowTaxes, setSelectedHowTaxes] = useState();

  /** taxes */
  const [selectedTaxes, setSelectedTaxes] = useState();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSetup = async (data: any) => {
    try {
      const schemaSetup = Yup.object().shape({
        titulo_infos: Yup.object().shape({
          title: Yup.string().required('Descrição é Obrigatório!'),
          convenio_id: Yup.string().required('Convênio é Obrigatório'),
          titulo_local_pagamento: Yup.string()
            .required('Local de pagamento é Obrigatório')
            .max(200, 'No máximo 200 caracteres!'),
          titulo_doc_especie: Yup.string().required(
            'Espécie de documento é Obrigatório',
          ),
          titulo_cod_desconto: Yup.string().required(
            'Código desconto é Obrigatório!',
          ),
          titulo_codigo_multa: Yup.string().required(
            'Código multa é Obrigatório',
          ),
          titulo_cod_protesto: Yup.string().required(
            'Código protesto é Obrigatório',
          ),
          titulo_codigo_juros: Yup.string().required(
            'Por favor, Informe o código do tipo de juros!',
          ),
          titulo_prazo_protesto: Yup.string().required(
            'Por favor, Informe a quantidade de dias para protesto!',
          ),
          titulo_cod_baixa_devolucao: Yup.string().required(
            'Código baixa devolução é Obrigatório',
          ),
          titulo_prazo_baixa: Yup.string(),
          titulo_mensagem01: Yup.string()
            .required('Mensagem boleto 1 é Obrigatório')
            .max(80, 'No máximo 80 caracteres!'),
          titulo_mensagem02: Yup.string().max(80, 'No máximo 80 caracteres!'),
          titulo_outros_acrescimos: Yup.string(),
          titulo_informacoes_adicionais: Yup.string(),
          titulo_instrucoes: Yup.string(),
        }),
      });

      await schemaSetup.validate(data, { abortEarly: false });

      const res = await api.post('/v2/boleto/setup', {
        convenio_id: data.titulo_infos.convenio_id,
        ...data,
      });

      if (res.status >= 200 || res.status <= 299) {
        showToast('success', 'Sucesso', 'Registro salvo com sucesso!');
      }

      navigate.push('/boleto/v1/setup/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get('/v2/boleto/convenio').then(({ data }) => {
      const coventionOpt = data.convenios.map((item: any) => {
        return {
          label: `${item.convenio_descricao}`,
          value: item.id,
        };
      });
      setConvention(coventionOpt);
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/boleto/v1/setup/list')}
      />
      <div className="card md:w-7 sm:w-full">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleSetup}>
          <Scope path="titulo_infos">
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Descrição</label>
                <InputText name="title" placeholder="Boleto BB" />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Convênio</label>
                <InputDropDown
                  name="convenio_id"
                  options={convention}
                  value={selectedConvention}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedConvention(e.value)}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Espécie de documento</label>
                <InputDropDown
                  name="titulo_doc_especie"
                  options={especieOption}
                  value={selectedEspecie}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedEspecie(e.value)}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Variação Carteira</label>
                <InputText
                  name="titulo_variacao_carteira"
                  keyfilter="int"
                  placeholder="000"
                  maxLength={3}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Outros acréscimos</label>
                <InputNumber
                  name="titulo_outros_acrescimos"
                  placeholder="0,00"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Código Protesto</label>
                <InputDropDown
                  name="titulo_cod_protesto"
                  options={optionProtest}
                  value={selectedProtest}
                  onChange={e => setSelectedProtest(e.value)}
                  placeholder="Selecionar..."
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Prazo p/ protesto</label>
                <InputNumber
                  name="titulo_prazo_protesto"
                  min={1}
                  placeholder="00"
                  showButtons
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Código baixa devolução</label>
                <InputDropDown
                  name="titulo_cod_baixa_devolucao"
                  options={devolution}
                  value={selectedDevolution}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedDevolution(e.value)}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Prazo baixa</label>
                <InputNumber
                  name="titulo_prazo_baixa"
                  min={1}
                  placeholder="00"
                  showButtons
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Código desconto</label>
                <InputDropDown
                  name="titulo_cod_desconto"
                  options={codDescount}
                  value={selectedDescount}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedDescount(e.value)}
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Código juros</label>
                <InputDropDown
                  name="titulo_codigo_juros"
                  options={codJuros}
                  value={selectedHowTaxes}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedHowTaxes(e.value)}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Código multa</label>
                <InputDropDown
                  name="titulo_codigo_multa"
                  options={multa}
                  value={selectedTaxes}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedTaxes(e.value)}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Informações adicionais</label>
                <InputText
                  name="titulo_informacoes_adicionais"
                  maxLength={128}
                  placeholder="Entregar ao setor financeiro"
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Instruções banco</label>
                <InputText
                  name="titulo_instrucoes"
                  placeholder="Não receber após 30 dias de vencimento..."
                  maxLength={128}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Local de pagamento (texto)</label>
                <InputTextArea
                  name="titulo_local_pagamento"
                  maxLength={200}
                  placeholder="Preferencialmente nas agências do BB.."
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Mensagem boleto 1</label>
                <InputTextArea
                  name="titulo_mensagem01"
                  maxLength={80}
                  placeholder="Preferencialmente nas agências do BB.."
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Mensagem boleto 2</label>
                <InputTextArea
                  name="titulo_mensagem02"
                  maxLength={80}
                  placeholder="Preferencialmente nas agências do BB.."
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid mb-3 ">
              <div className="field col-6 md:col">
                <Button
                  label="Cancelar"
                  className="p-button-raised p-button-danger"
                />
              </div>
              <div className="field col-6 md:col">
                <Button label="Salvar" />
              </div>
            </div>
          </Scope>
        </Form>
      </div>
    </>
  );
};

export default SetupCreate;
