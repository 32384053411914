import IOs from '../dtos/IOs';
import { emptyOsStatus } from '../osStatus/dtos/IOsStatus';
import { emptyOsTransporter } from './EmptyOsTransporter';
import { emptyOsHeader } from './emptyOsHeader';

export const emptyOs: IOs = {
  isEdit: false,
  osHeader: emptyOsHeader,
  osObjects: [],
  osServices: [],
  osProducts: [],
  osPayments: [],
  osStatus: emptyOsStatus,
  osTransporter: emptyOsTransporter,
};
