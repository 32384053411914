const renderColumnDecimal = (num: number, decimalHouses?: number) => {
  return (
    <span>
      {new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: decimalHouses || 2,
        maximumFractionDigits: decimalHouses || 2,
      }).format(Number(num))}
    </span>
  );
};

export default renderColumnDecimal;
