import React, { useRef } from 'react';

/** services */
import api from '../../../services/api';

/** components */
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

const ReportProvider = (): React.ReactElement => {
  const toast = useRef<any>(null);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReportsProvider = async () => {
    try {
      const { data: response } = await api.get(`/reports/providers`);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <div className="p-fluid grid formgrid ">
          <div className="field col-12 md:col">
            <Button label="Gerar" onClick={() => handleReportsProvider()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportProvider;
