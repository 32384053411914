import React, { useRef, useState } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** services */
import api from '../../../services/api';

/** navigate */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';

const VariationCreate: React.FC = (): React.ReactElement => {
  /** state */
  const [totalSize, setTotalSize] = useState(0);

  const [isRequired, setIsRequired] = useState<any>('S');
  const [description, setDescription] = useState<string | null>('');
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const form = useRef<FormHandles>(null);
  const fileUploadRef = useRef<any>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schemaVariation = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
        description: Yup.string(),
        limit: Yup.string().required('Por favor, informe o limite'),
      });
      await schemaVariation.validate(data, { abortEarly: false });

      const formData = new FormData();

      for (const i in data) {
        formData.set(i, data[i]);
      }

      if (fileUploadRef.current?.files?.length) {
        formData.set('image_url', fileUploadRef.current.files[0]);
      }
      await api.post('/variations', formData);
      navigate.push('/variations/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };

  const chooseOptions = {
    label: 'Adicionar Imagen',
    icon: 'pi pi-plus',
    className: 'p-button-raised p-2',
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 40, height: 40 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/variations/list')}
      />
      <Toast ref={toast} />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-8 sm:w-full"
        />
      )}
      <div className="card md:w-8 sm:w-full">
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Título</label>
              <InputText name="title" placeholder="Título da variação" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Descrição</label>
              <InputTextEdit
                name="description"
                value={description as string}
                onTextChange={e => setDescription(e.htmlValue)}
                style={{ height: 150 }}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <FileUpload
                name="demo[]"
                url="./upload"
                ref={fileUploadRef}
                accept="image/*"
                emptyTemplate={emptyTemplate}
                headerTemplate={headerTemplate}
                chooseOptions={chooseOptions}
                itemTemplate={itemTemplate}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-5">
              <label htmlFor="dropdown">Limite</label>
              <InputNumber
                name="limit"
                showButtons
                min={0}
                max={100}
                placeholder="00"
              />
            </div>
            <div className="field col-12 md:col-6 flex align-items-center">
              <InputSwitch
                name="required"
                className="ml-3"
                falseValue="N"
                trueValue="S"
                checked={isRequired}
                onChange={e => setIsRequired(`${e.value}`)}
              />
              <label htmlFor="dropdown" className="ml-3">
                Obrigatório?
              </label>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default VariationCreate;
