import React, { useRef, useState, useEffect } from 'react';

/** Form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** services */
import api from '../../../../services/api';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { Option } from '../../../../types/optional';
import { Toast } from 'primereact/toast';

const especieOption = [
  { label: 'Real', value: 'R$' },
  { label: 'Dolar', value: 'US$' },
];

const cnabOptions = [
  { label: '240', value: '240' },
  { label: '400', value: '400' },
];

const ConvenienceCreate = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** selected options */
  const [selectedEspecieOption, setSelectedEspecieOption] = useState();

  /** cnab options */
  const [selectedCnabOptions, setSelectedCnabOptions] = useState();

  /** account options */
  const [accountOption, setAccountOption] = useState<Array<Option>>([]);
  const [selectedAccountOption, setSelectedAccountOption] = useState();

  /** reset */
  const [reset, setReset] = useState(false);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** navigate */
  const navigate = useHistory();

  const handleSetup = async (data: any) => {
    try {
      const schema = Yup.object().shape({
        convenio_descricao: Yup.string().required(
          'Descrição convênio é Obrigatório!',
        ),
        conta_num_id: Yup.string().required('Conta é Obrigatório!'),
        convenio_numero: Yup.string().required('Convênio é Obrigatório'),
        convenio_carteira: Yup.string().max(3),
        convenio_especie: Yup.string().required('Espécie é Obrigatório'),
        convenio_padrao_CNAB: Yup.string().required('CNAB é Obrigatório'),
        convenio_numero_remessa: Yup.string(),
        convenio_reiniciar_diariamente: Yup.bool(),
      });
      await schema.validate(data, { abortEarly: false });

      await api.post('/v2/boleto/convenio', data);
      navigate.push('/boleto/v1/convenio/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get('/v2/boleto/conta').then((acc: any) => {
      const option = acc.data.contas.map((item: any) => {
        return {
          label: `${item.conta_codigo_banco} - ${item.conta_agencia}-${item.conta_agencia_DV} - ${item.conta_numero}-${item.conta_numero_DV}`,
          value: item.id,
        };
      });
      setAccountOption(option);
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/boleto/v1/convenio/list')}
      />
      <Toast ref={toast} />
      <div className="card">
        <Form ref={form} onSubmit={handleSetup}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Descrição convênio</label>
              <InputText name="convenio_descricao" placeholder="Convenio BB" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Conta</label>
              <InputDropDown
                name="conta_num_id"
                placeholder="Selecionar..."
                options={accountOption}
                value={selectedAccountOption}
                onChange={e => setSelectedAccountOption(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Convênio</label>
              <InputText
                name="convenio_numero"
                keyfilter="int"
                placeholder="0000"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Carteira</label>
              <InputText name="convenio_carteira" placeholder="0000" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Espécie</label>
              <InputDropDown
                name="convenio_especie"
                options={especieOption}
                value={selectedEspecieOption}
                placeholder="Selecionar..."
                onChange={e => setSelectedEspecieOption(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">CNAB</label>
              <InputDropDown
                name="convenio_padrao_CNAB"
                options={cnabOptions}
                value={selectedCnabOptions}
                placeholder="Selecionar..."
                onChange={e => setSelectedCnabOptions(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Número remessa</label>
              <InputText
                name="convenio_numero_remessa"
                keyfilter="int"
                placeholder="0000"
              />
            </div>
            <div className="field col-12 md:col-6 flex align-items-end">
              <label htmlFor="" className="mr-3">
                Reinicia diaramente
              </label>
              <InputSwitch
                name="convenio_reiniciar_diariamente"
                className="mb-1"
                checked={reset}
                onChange={e => setReset(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 ">
            <div className="field col-12 md:col">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ConvenienceCreate;
