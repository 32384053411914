import React, { useRef, useEffect } from 'react';

import { Checkbox as Input, CheckboxProps } from 'primereact/checkbox';

/* unform */
import { useField } from '@unform/core';

/* props inputText */
interface PropsInput extends CheckboxProps {
  name: string;
}

export const InputChecked: React.FC<PropsInput> = ({
  name,
  className,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'props.checked',
    });
  }, [fieldName, registerField]);

  return (
    <Input
      name={name}
      checked={defaultValue}
      ref={inputRef}
      {...rest}
      className={error ? `p-invalid ${className}` : `${className}`}
    />
  );
};
