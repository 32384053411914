import React, { useEffect, useRef, useState } from 'react';

/* unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** Joi */
import * as Yup from 'yup';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** typing */
import { Additional } from '../../../types/additional';
import { AdditionalItem } from '../../../types/additionalItem';
import { Option } from '../../../types/optional';

/** services */
import api from '../../../services/api';

/** react-router-dom  */
import { useHistory } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
} from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { currencyToFloat } from '../../../utils/number';

type AdditionalRequest = {
  id: number;
  additional_id: string;
  title: string;
  description: string;
  image_url: File | null;
  unt: string;
  value: string;
  status: string;
  removed: boolean;
};

const AdditionalItemCreate = (): React.ReactElement => {
  /** useState */
  const [itemAdditional, setItemAdditional] = useState<any[]>([]);
  const [itemAdditionalRequest, setAdditionalRequest] = useState<
    Array<AdditionalRequest>
  >([]);
  const [optionAdditional, setOptionAddition] = useState<Option[]>();
  const [additionalById, setSelectAdditionalById] = useState<any>(null);
  const [cloneAdditional, setCloneAdditional] = useState<Array<Additional>>([]);
  const [totalSize, setTotalSize] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** form ref */
  const formRef = useRef<FormHandles>(null);
  const fileUploadRef = useRef<any>(null);
  const toast = useRef<any>(null);

  /** toas */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /* navigate */
  const navigate = useHistory();

  /** form submited */
  const handleSubmit = async (data: AdditionalRequest) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schmemaAdditional = Yup.object({
        additional_id: Yup.string().required(
          'Por Favor, Selecione um Adicional',
        ),
        title: Yup.string().required('Por favor, Selecione um Título'),
        description: Yup.string(),
        unt: Yup.number()
          .typeError('Informe um número')
          .required('Por Favor, Informe uma Quantidade'),
        value: Yup.string().required('Por Favor, Informe um Valor'),
      });

      await schmemaAdditional.validate(data, { abortEarly: false });
      const findGroupAdditionals = cloneAdditional.find(
        item => item.id === additionalById,
      );

      const id = Math.floor(Math.random() * 10000);
      setItemAdditional([
        ...itemAdditional,
        {
          ...data,
          value: currencyToFloat(data.value),
          image_url: fileUploadRef.current?.files
            ? fileUploadRef.current?.files[0].objectURL
            : null,
          additional: findGroupAdditionals?.title,
          removed: true,
          id,
        },
      ]);

      const itemRequest: AdditionalRequest = {
        ...data,
        image_url: fileUploadRef.current.files
          ? fileUploadRef.current?.files[0]
          : null,
        id,
      };
      setAdditionalRequest([...itemAdditionalRequest, itemRequest]);
      formRef.current?.clearField('title');
      formRef.current?.clearField('value');
      formRef.current?.clearField('unt');
      fileUploadRef.current?.clear();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const saveItemsAdditional = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (itemAdditionalRequest.length <= 0) {
      showToast('error', 'Error', 'Por favor, adicione um item');
      return;
    }

    try {
      const form = new FormData();

      itemAdditionalRequest.map(async item => {
        form.append('additional_id', item.additional_id);
        form.append('title', item.title);
        form.append('description', `${item.description}`);
        form.append('image_url', item.image_url || '');
        form.append('unt', item.unt);
        form.append('value', `${currencyToFloat(item.value)}`);
        form.append('status', 'A');
      });
      await api.post(`/additionals-itens`, form);
      navigate.push('/additionals-items/list');
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const onTemplateRemove = (file: File, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 33 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };

  const onAdditionalChange = (e: { value: string }) => {
    setSelectAdditionalById(e.value);
  };

  const additionalsOptionTemplate = (option: {
    label: string;
    figure: string;
  }) => {
    return (
      <div className="country-item flex align-items-center">
        {option.figure ? (
          <>
            <img
              alt={option.label}
              src={`${process.env.REACT_APP_SERVER_URL}/files/img/${option.figure}`}
              className={`flag-${option.label.toLowerCase()}`}
              width={30}
            />
            <div className="max-w-max ml-3">{option.label}</div>
          </>
        ) : (
          <>
            <i className="pi pi-image text-4xl"></i>
            <div className="max-w-max ml-3">{option.label}</div>
          </>
        )}
      </div>
    );
  };

  const chooseOptions = {
    icon: '',
    className: 'p-button p-component',
    label: 'Selecionar Imagem',
  };

  /** table render */
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
    }).format(value);
  };

  const valueBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {formatCurrency(rowData.value)}
      </>
    );
  };

  const figureBodyTemplate = (rowData: AdditionalItem) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={
              /^blob/.test(rowData.image_url)
                ? `${rowData.image_url}`
                : `${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`
            }
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const removeItemAdditional = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    additional: AdditionalRequest,
  ) => {
    e.preventDefault();
    const filterAdditional = itemAdditional.filter(
      item => item.id !== additional.id,
    );

    const filterAdditionalRequest = itemAdditionalRequest.filter(
      item => item.id !== additional.id,
    );

    setAdditionalRequest(filterAdditionalRequest);
    setItemAdditional(filterAdditional);
  };

  const actionRemoveTemplate = (rowData: AdditionalRequest) => {
    return (
      <>
        {rowData.removed && (
          <Button
            label="Excluir"
            onClick={e => removeItemAdditional(e, rowData)}
            className="p-button-danger"
          />
        )}
      </>
    );
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('additionals')
      .then(({ data }) => {
        setCloneAdditional(data);
        const options = data.map((item: Additional) => {
          return {
            label: item.title,
            value: item.id,
            figure: item.image_url,
          };
        });
        setOptionAddition(options);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/additionals-items/list')}
      />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Grupos Adicionais</label>
              <InputDropDown
                placeholder="Selecione"
                name="additional_id"
                filter
                optionLabel="label"
                value={additionalById}
                options={optionAdditional}
                filterBy="label"
                onChange={onAdditionalChange}
                itemTemplate={additionalsOptionTemplate}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex">
            <div className="col p-0 min-width-300">
              <div className="field col-12 md:col">
                <label htmlFor="basic">Título</label>
                <InputText name="title" placeholder="Nome do item adicional" />
              </div>
              <div className="field col-12 md:col">
                <label htmlFor="basic">Descrição</label>
                <InputTextEdit name="description" style={{ height: 225 }} />
              </div>
            </div>
            <div className="col p-0 max-w-300">
              <div className="field col-12 md:col mt-5">
                <FileUpload
                  ref={fileUploadRef}
                  name="file"
                  maxFileSize={1000000}
                  multiple
                  accept="image/*"
                  headerTemplate={headerTemplate}
                  chooseOptions={chooseOptions}
                  itemTemplate={itemTemplate}
                  emptyTemplate={emptyTemplate}
                  invalidFileSizeMessageSummary=""
                  invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
                />
              </div>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Qnt</label>
              <InputNumber
                name="unt"
                showButtons
                min={0}
                placeholder="0,00"
                buttonLayout="horizontal"
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Valor</label>
              <InputNumber
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={5}
                placeholder="0,00"
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end">
              <Button icon="pi pi-plus" className="w-full" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <DataTable
                value={itemAdditional}
                responsiveLayout="scroll"
                emptyMessage="Nenhum item adicional adicionado!"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
              >
                <Column field="additional" header="ADICIONAL"></Column>
                <Column
                  field="name"
                  header="IMAGEM"
                  body={figureBodyTemplate}
                ></Column>
                <Column field="title" header="ITEM"></Column>
                <Column field="unt" header="UND"></Column>
                <Column
                  field="value"
                  header="VALOR"
                  body={valueBodyTemplate}
                ></Column>
                <Column header="Ação" body={actionRemoveTemplate}></Column>
              </DataTable>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => navigate.push('/additionals-items/list')}
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                onClick={e => saveItemsAdditional(e)}
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AdditionalItemCreate;
