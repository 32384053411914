import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import ProductHeaderButtons from '../../product/header-buttons';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

const ReportProductsSalled = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const hoje = new Date();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [productOpts, setProductOpts] = useState<Array<any>>([]);
  const [products, setProducts] = useState<Array<IOptionsDTO>>([]);
  const [product, setProduct] = useState<string>('');

  const [customerOpts, setCustomerOpts] = useState<Array<any>>([]);
  const [customers, setCustomers] = useState<Array<IOptionsDTO>>([]);
  const [customer, setCustomer] = useState<string>('');

  const [userOpts, setUserOpts] = useState<Array<IOptionsDTO>>([]);
  const [users, setUsers] = useState<Array<string>>();

  const [commissionedOpts, setCommissionedOpts] = useState<Array<IOptionsDTO>>(
    [],
  );
  const [commissioneds, setCommissioneds] = useState<Array<string>>([]);

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const loadCommissioneds = async () => {
    setIsLoad(true);
    await api
      .get('/commissioneds')
      .then(({ data }) => {
        setCommissionedOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.name,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => setIsLoad(false));
  };

  const loadUsers = async () => {
    setIsLoad(true);
    await api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        setUserOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.name,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => setIsLoad(false));
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const opts = customers.map((item: any) => {
          return {
            value: item.id,
            label: `${item.name} ~ ${item.cpf_cnpj}`,
          };
        });

        setCustomerOpts(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      await api
        .get(`/products/v2/search?src=${e.query}`)
        .then(({ data }) => {
          const opts = data.products.map((item: any) => {
            return {
              value: item.id,
              label: item.titulo,
              sku: item.sku,
              stock: item.estoque,
              price_out: item.price_out,
              qnt_out: item.qnt_out,
            };
          });

          if (opts.length <= 0) {
            toast('error', 'Error', 'Nenhum produto encontrado!');
          }

          setProductOpts(opts);
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        });
    }
  };

  const handleProductSelected = (d: any) => {
    if (validate(d.value)) {
      const hasInTable = products.find(i => i.value === d.value);
      if (hasInTable) {
        toast('warn', 'Alerta', `Produto já foi adicionado na tabela!`);
        return;
      }
    }
    const _p = { label: d.label, value: d.value };
    setProducts([...products, _p]);
    setProductOpts([]);
    setProduct('');
  };

  const handleCustomerSelect = (d: any) => {
    if (validate(d.value)) {
      const hasInTable = customers.find(i => i.value === d.value);
      if (hasInTable) {
        toast('warn', 'Alerta', `Cliente já foi adicionado na tabela!`);
        return;
      }
    }
    const _c = { label: d.label, value: d.value };
    setCustomers([...customers, _c]);
    setCustomerOpts([]);
    setCustomer('');
  };

  const handleRemProduct = (id: string) => {
    const noId = products.filter((i: any) => i.value !== id);
    setProducts(noId);
  };

  const handleRemCustomer = (id: string) => {
    const noId = customers.filter((i: any) => i.value !== id);
    setCustomers(noId);
  };

  const renderButtonRemoveProduct = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemProduct(id)}
        />
      </div>
    );
  };

  const renderButtonRemoveCustomer = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemCustomer(id)}
        />
      </div>
    );
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      d.date_start = dateStart;
      d.date_end = dateEnd;

      const validation = Yup.object({
        date_start: Yup.date().required('Informe a data de início da busca!'),
        date_end: Yup.date().required('Informe a data de fim da busca!'),
      });

      await validation.validate(d, { abortEarly: false });

      await api
        .post('/reports/products/salleds', {
          filter: {
            products: products.map(i => i.value) || [],
            customers: customers.map(i => i.value) || [],
            users: users || [],
            commissioneds: commissioneds || [],
            period: {
              start: dateStart,
              end: dateEnd,
            },
          },
        })
        .then(res => {
          toast(
            'success',
            'Sucesso',
            'Relatório gerado com sucesso. Esse relatório normalmente tem várias páginas e pode demorar um pouco. Aguarde!',
            10000,
          );
          window.open(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
          );
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
        })
        .finally(() => setIsLoad(false));
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      }

      toast('error', 'Erro', messageRequestError(e));
    } finally {
      setIsLoad(false);
    }
  };

  const renderItemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  useEffect(() => {
    loadUsers();
    loadCommissioneds();
  }, []);

  return (
    <>
      <ProductHeaderButtons />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card mx:w-8 md:w-8"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12">
            <label htmlFor="product_id">Produto</label>
            <InputAutoComplete
              name="product_id"
              value={product}
              completeMethod={e => searchProduct(e)}
              suggestions={productOpts}
              field="label"
              onSelect={e => handleProductSelected(e.value)}
              onChange={e => setProduct(e.value)}
              itemTemplate={renderItemTemplate}
              placeholder="Buscar Produto..."
              autoFocus={true}
            />
          </div>

          {products.length > 0 && (
            <DataTable
              value={products}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum produto informado!"
              className="field col-12"
              loading={isLoad}
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column field="label" header="Descrição"></Column>
              <Column
                header="*"
                body={row => renderButtonRemoveProduct(row.value)}
              ></Column>
            </DataTable>
          )}

          <div className="field col-12">
            <label htmlFor="customer_id">Cliente</label>
            <InputAutoComplete
              name="customer_id"
              value={customer}
              completeMethod={e => searchCustomer(e.query)}
              suggestions={customerOpts}
              field="label"
              onSelect={e => handleCustomerSelect(e.value)}
              onChange={e => setCustomer(e.value)}
              itemTemplate={renderItemTemplate}
              placeholder="Buscar cliente..."
              autoFocus={true}
            />
          </div>

          {customers.length > 0 && (
            <DataTable
              value={customers}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum cliente informado!"
              className="field col-12"
              loading={isLoad}
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column field="label" header="Descrição"></Column>
              <Column
                header="*"
                body={row => renderButtonRemoveCustomer(row.value)}
              ></Column>
            </DataTable>
          )}

          <div className="field col-6">
            <label htmlFor="commissioned_id">Comissionados</label>
            <MultiSelect
              name="commissioned_id"
              options={commissionedOpts}
              value={commissioneds}
              placeholder="Selecionar..."
              onChange={e => setCommissioneds(e.value)}
              tooltip={'Comissionados'}
              tooltipOptions={{ position: 'bottom' }}
              display="chip"
              filter
            />
          </div>

          <div className="field col-6">
            <label htmlFor="user_id">Usuários</label>
            <MultiSelect
              name="user_id"
              options={userOpts}
              value={users}
              placeholder="Selecionar..."
              onChange={e => setUsers(e.value)}
              tooltip={'Usuários'}
              tooltipOptions={{ position: 'bottom' }}
              display="chip"
              filter
            />
          </div>

          <div className="field col-6">
            <label>Data inicial</label>
            <Calendar
              name="date_start"
              value={dateStart}
              onChange={e => setDateStart(e.value)}
              dateFormat="dd/mm/yy"
              showIcon
            />
          </div>

          <div className="field col-6">
            <label>Data final</label>
            <Calendar
              name="date_end"
              value={dateEnd}
              onChange={e => setDateEnd(e.value)}
              dateFormat="dd/mm/yy"
              showIcon
            />
          </div>

          <Divider />

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={isLoad ? 'Aguarde...' : 'Imprimir'}
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ReportProductsSalled;
