import IOsTransporter from '../dtos/IOsTransporter';

const hoje = new Date();

export const emptyOsTransporter: IOsTransporter = {
  tracking_code: null,
  post_date: null,
  delivery_date: null,
  transporter_id: null,
  vehicle_plate: null,
  driver: null,
  kind_freight: 'OUT',
  quantity: null,
  kind: null,
  brand: null,
  seal_number: null,
  weight_brute: null,
  weight_liquid: null,
  cubage: null,
  cost: null,
  obs: null,
};
