import React, { useEffect, useState } from 'react';

/** services */
import api from '../../../../services/api';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../../components/DataTable';

/** menu options */

const AccountList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** account bank */
  const [accountBank, setAccountBank] = useState<Array<any>>([]);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const agencyBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.conta_agencia}-{rowData.conta_agencia_DV}
        </span>
      </>
    );
  };

  const accountBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.conta_numero}-{rowData.conta_numero_DV}
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/boleto/v1/conta/update/${rowData.id}`)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Contas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  useEffect(() => {
    api.get('/v2/boleto/conta').then(({ data }) => {
      setAccountBank(data.contas);
    });
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/boleto/v1/conta/create')}
      />
      <div className="card">
        <DataTable
          value={accountBank}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} contas"
          globalFilter={globalFilter}
          emptyMessage="Não existe Tags."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="conta_codigo_banco" header="Banco"></Column>
          <Column
            header="Agência"
            field="conta_agencia"
            body={agencyBodyTemplate}
          ></Column>
          <Column
            field="price"
            header="Conta"
            body={accountBodyTemplate}
          ></Column>
          <Column field="conta_tipo" header="Tipo"></Column>

          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default AccountList;
