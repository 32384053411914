import React, { useRef } from 'react';

/* unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** ypu */
import * as Yup from 'yup';

/** services */
import api from '../../../services/api';

/* components */
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

type ApplicationModel = {
  title: string;
  description: string;
  sigla: string;
};

const ApplicationCreate = (): React.ReactElement => {
  /* ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: ApplicationModel) => {
    formRef.current?.setErrors({});

    try {
      const schemaApplicationCreate = Yup.object({
        title: Yup.string().required('Por favor, Informe um título'),
        description: Yup.string(),
        sigla: Yup.string().max(6, 'Sigla deve ter no máximo 6 caracteres'),
      });

      await schemaApplicationCreate.validate(data, { abortEarly: false });
      await api.post('/applications', { ...data, status: 'A' });
      navigate.replace('/applications/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/applications/list')}
      />
      <div className="card w-6">
        <Toast ref={toast} />

        <Form onSubmit={handleSubmit} ref={formRef}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-8">
              <label htmlFor="basic">Título</label>
              <InputText name="title" placeholder="Ex: Despesas" />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Sigla</label>
              <InputText name="sigla" maxLength={6} placeholder="Ex: DESP" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea
                name="description"
                style={{ height: 150 }}
                placeholder="Despesas da empresa 1"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mt-4">
            <div className="field col-12 md:col flex">
              <Button
                label="Cancelar"
                className="mr-2 p-button-raised p-button-danger"
              />
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default ApplicationCreate;
