type User = {
  id: string;
  avatar_url: string;
  name: string;
  email: string;
  cpf_cnpj: string;
  phone: string;
  birthday: Date;
};

type PropsCredentialsData = {
  token: string;
  user: User;
  _b: string;
  _p: string;
  sys_status: boolean;
};

export const definedCredentials = (credentials: PropsCredentialsData): void => {
  localStorage.setItem('@wg:token', credentials.token);
  localStorage.setItem('@wg:user', JSON.stringify(credentials.user));
  localStorage.setItem('@wg:_p', credentials._p);
  localStorage.setItem('@wg:_b', credentials._b);
  localStorage.setItem('@wg:sys_status', `${credentials.sys_status}`);
};

export const LogOut = (): void => {
  localStorage.removeItem('@wg:token');
  localStorage.removeItem('@wg:user');
  localStorage.removeItem('@wg:_p');
  localStorage.removeItem('@wg:_b');
  localStorage.removeItem('@wg:sys_status');
};
