import React, { useState, useRef } from 'react';

/** components */
import { Button } from 'primereact/button';
import InputDate from '../../../../components/Inputs/InputCalendar';

/** yup */
import * as Yup from 'yup';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import { Toast } from 'primereact/toast';
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import api from '../../../../services/api';

const ReportsCheckOn: React.FC = (): React.ReactElement => {
  /** ref */
  const form = useRef<FormHandles>(null);

  /** toast */
  const toast = useRef<any>(null);

  /** initial date */
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReports = async (data: any) => {
    try {
      const schema = Yup.object().shape({
        inital_date: Yup.string()
          .required('Data Inicial é Obrigatorio!')
          .nullable(),
        final_date: Yup.string()
          .required('Data Final é Obrigatorio!')
          .nullable(),
      });

      await schema.validate(data, { abortEarly: false });

      if (initialDate && finalDate && initialDate > finalDate) {
        showToast(
          'error',
          'Error',
          'Data inical tem que ser maior que a final!',
        );
        form.current?.setFieldError('initial_date', 'error');
        return;
      }

      const { data: response } = await api.get(
        '/reports/bills-payable/cheque-on',
        {
          params: {
            dateStart: `${startOfDay(initialDate as Date)}`,
            dateEnd: `${endOfDay(finalDate as Date)}`,
          },
        },
      );
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleReports}>
          <Toast ref={toast} />
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="inital_date"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="final_date"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <Button label="Gerar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsCheckOn;
