import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import { Column } from 'primereact/column';
import api from '../../../services/api';
import {
  emptyBillPayableResume,
  IBillPayableResume,
} from '../types/IBillPayableResume';
import useToastContext from '../../../hooks/toast';
import RenderColumnStatusBills from '../../../components/DataTableColumns/RenderColumnStatusBills';
import RenderColumnStatusPayment from '../../../components/DataTableColumns/RenderColumnStatusPayment';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnShortName from '../../../components/DataTableColumns/RenderColumnShortName';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import MenuPopUp from '../../../components/MenuPopPup';
import { EnumStatus } from '../../../enum/EnumStatus';
import { EnumStatusPayment } from '../../../enum/EnumStatusPayment';
import { useHistory } from 'react-router-dom';
import openReports from '../../../utils/openReports';
import messageRequestError from '../../../utils/messageRequestError';
import { AxiosError, AxiosResponse } from 'axios';

interface IProps {
  provider_id: string;
}

const ContentBillPayable = ({ provider_id }: IProps): React.ReactElement => {
  const toast = useToastContext();
  const navigate = useHistory();

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [bill, setBill] = useState<IBillPayableResume>(emptyBillPayableResume);
  const [bills, setBills] = useState<IBillPayableResume[]>([]);

  const loadBills = async (id: string) => {
    setIsLoad(true);
    await api
      .post('bills-payable/resume', {
        data: { id: provider_id },
        pagination: { page: 1, perPage: 1000, status: ['A', 'C'] },
      })
      .then(res => {
        if (res) {
          setBills(res.data);
        }
      })
      .catch(e => {
        toast(
          'warn',
          'Alerta',
          `Falha ao carregar os documentos a pagar: ${e.message}`,
          8000,
        );
        return;
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const printReport = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`${process.env.REACT_APP_SERVER_URL}/reports/receipt?billId=${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
            'recibo',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const cancelBill = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`${process.env.REACT_APP_SERVER_URL}/bills-payable/${id}`)
      .then(res => {
        if (res) {
          toast('success', 'Sucesso', 'Conta cancelada com sucesso!');
          loadBills(provider_id);
        }
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const mountMenuItems = (row: IBillPayableResume) => {
    const items: any[] = [];

    if (row.status !== EnumStatus.CANCELADO) {
      if (row.status_payment !== EnumStatusPayment.PAGO) {
        items.push({
          label: 'Baixar',
          icon: 'pi pi-check-square',
          command: () => {
            navigate.push(`/bills/pay/drop/${row.id}`);
          },
        });
      }

      items.push(
        {
          label: 'Imprimir recibo',
          icon: 'pi pi-fw pi-print',
          command: () => printReport(row.id),
        },
        {
          label: 'Cancelar',
          icon: 'pi pi-fw pi-trash',
          command: () => cancelBill(row.id),
        },
      );
    }

    return items;
  };

  const renderRowMenu = (row: IBillPayableResume) => {
    const model = mountMenuItems(row);
    if (model.length > 0) {
      return (
        <>
          <MenuPopUp model={model} rowData={row} setData={setBill} />
        </>
      );
    }
  };

  useEffect(() => {
    loadBills(provider_id);
  }, [provider_id]);

  return (
    <>
      <DataTable
        value={bills}
        paginator
        rows={10}
        loading={isLoad}
        loadingIcon="pi pi-spinner text-primary"
        rowsPerPageOptions={[10, 20, 30]}
        className="datatable-responsive m-0 p-0"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} adicionais"
        emptyMessage="Sem pedidos."
        responsiveLayout="scroll"
        size="small"
      >
        <Column field="id" header="#" body={renderColumnPosition}></Column>
        <Column
          field="provider_name"
          header="Fornecedor"
          headerStyle={{ width: '20%' }}
        ></Column>
        <Column
          field="emited_day"
          header="Emissão"
          body={r => renderColumnDate(r.emited_day)}
        ></Column>
        <Column field="doc_ser" header="Sr." align={'center'}></Column>
        <Column field="doc_num" header="Num." align={'center'}></Column>
        <Column
          field="date_to_pay"
          header="Vencimento"
          body={r => renderColumnDate(r.date_to_pay)}
        ></Column>
        <Column
          field="value"
          header="Total"
          body={r => renderColumnDecimal(r.value)}
        ></Column>
        <Column
          field="rest"
          header="Restante"
          body={r => renderColumnDecimal(r.rest)}
        ></Column>
        <Column
          field="status_payment"
          header="Situação"
          align={'center'}
          body={r => RenderColumnStatusPayment(r.status, r.status_payment)}
        ></Column>
        <Column
          field="user_name"
          header="Usuário"
          body={r => renderColumnShortName(r.user_name)}
        ></Column>
        <Column
          field="status"
          header="Status"
          align={'center'}
          body={r => RenderColumnStatusBills(r.status)}
        ></Column>
        <Column field="description" header="Obs"></Column>
        <Column header="*" body={r => renderRowMenu(r)}></Column>
      </DataTable>
    </>
  );
};

export default ContentBillPayable;
