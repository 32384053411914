import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../../components/Inputs/InputTextEdit';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import IOsObjectDTO from '../dtos/IOsObjectDTO';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const FastFormOsObject = ({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [objetoOpts, setObjetoOpts] = useState<IOsObjectDTO[]>([]);
  const [tipoObjetoOpts, setTipoObjetoOpts] = useState<IOptionsDTO[]>([]);

  const [tipoObjeto, setTipoObjeto] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [modelo, setModelo] = useState<string>('');
  const [marca, setMarca] = useState<string>('');
  const [identificador, setIdentificador] = useState<string>('');
  const [anoFabricacao, setAnoFabricacao] = useState<string>('');
  const [anoModelo, setAnoModelo] = useState<string>('');
  const [obs, setObs] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setTipoObjeto('');
    setTitulo('');
    setModelo('');
    setMarca('');
    setIdentificador('');
    setAnoFabricacao('');
    setAnoModelo('');
    setObs('');

    onRequestClose();
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        title: Yup.string().required(
          'Informe a descrição do objeto/equipamento!',
        ),
        kind_object: Yup.string().uuid().required('Informe o tipo de objeto!'),
        model: Yup.string().required('Informe o modelo!'),
        brand: Yup.string().required('Informe a marca!'),
      });
      await validation.validate(data, { abortEarly: false });

      const res = await api.post('/os/object', {
        osObject: {
          kind_object_id: tipoObjeto,
          title: titulo,
          model: modelo,
          brand: marca,
          identificator: identificador,
          made_year: anoFabricacao,
          model_year: anoModelo,
          obs,
        },
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item salvo com sucesso!');
        clearForm();
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listObjects = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/object/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsObject.map((i: any) => {
          return {
            id: i.id,
            kind_object_id: i.kind_object_id,
            title: i.title,
            model: i.model,
            brand: i.brand,
            identificator: i.identificator,
            made_year: i.made_year,
            model_year: i.model_year,
            obs: i.obs,
          };
        });

        setObjetoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffect */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/os/kind-object/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsKindObject.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setTipoObjetoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));

    listObjects();
  }, []);

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => onRequestClose()}
      style={{ width: '60vw' }}
    >
      <h3>Novo objeto</h3>
      <Form ref={formRef} onSubmit={d => handleSubmit(d)}>
        <div className="p-fluid grid formgrid">
          <div className="field col-12">
            <label htmlFor="title">Objeto/Equipamento</label>
            <InputText
              name="title"
              placeholder="Ex.: Telefone"
              value={titulo}
              onChange={e => setTitulo(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-4 mx-0">
            <label htmlFor="kind_object">Tipo de objeto/equipamento</label>
            <InputDropDown
              name="kind_object"
              options={tipoObjetoOpts}
              value={tipoObjeto}
              onChange={e => setTipoObjeto(e.value)}
              placeholder="Selecionar..."
              tooltip={'O tipo de objeto: Telefone, Televisor...'}
              tooltipOptions={{ position: 'bottom' }}
              filter
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="model">Modelo</label>
            <InputText
              name="model"
              placeholder="Ex.: XYZ998A"
              value={modelo}
              onChange={e => setModelo(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="brand">Marca</label>
            <InputText
              name="brand"
              placeholder="Ex.: Apple"
              value={marca}
              onChange={e => setMarca(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="identificator">Identificador</label>
            <InputText
              name="identificator"
              placeholder="Ex.: 99883332022"
              value={identificador}
              onChange={e => setIdentificador(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="made_year">Ano fabricacao</label>
            <InputText
              name="made_year"
              placeholder="Ex.: 2023"
              value={anoFabricacao}
              keyfilter="int"
              maxLength={4}
              onChange={e => setAnoFabricacao(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="model_year">Ano modelo</label>
            <InputText
              name="model_year"
              placeholder="Ex.: 2023"
              keyfilter="int"
              maxLength={4}
              value={anoModelo}
              onChange={e => setAnoModelo(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-12">
            <InputTextEdit
              name="obs"
              style={{ height: '30vh' }}
              value={obs}
              onTextChange={e => setObs(e.htmlValue || '')}
              placeholder="Ex.: Este produto tem uma ótima saída!"
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={'Salvar'}
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </div>
      </Form>
    </SideBar>
  );
};

export default FastFormOsObject;
