import { useContext } from 'react';
import { OrderServiceContext } from '../context/OrderServiceContext';

export function useOrderService() {
  const context = useContext(OrderServiceContext);
  if (!context) {
    throw new Error('Falha ao acessar o hooks de ordem de serviço context!');
  }
  return context;
}
