export interface IUser {
  id: string;
  b_id: string;
  name: string;
  status: string;
  avatar_url?: string;
}

export const emptyUser: IUser = {
  id: '',
  b_id: '',
  name: '',
  status: '',
  avatar_url: '',
};
