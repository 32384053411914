import api from '../services/api';
import messageRequestError from '../utils/messageRequestError';

interface IRequestDTO {
  search: string;
  status?: string[];
}

export default async function useSearchCustomer({
  search,
  status,
}: IRequestDTO): Promise<any[] | undefined> {
  let customers = undefined;

  const url = '/persons/customer/src';

  if (search.length < 3) {
    throw new Error('A busca deve ter no mínimo 3 caracteres!');
  }

  await api
    .post(url, {
      data: {
        search,
        status: status ? status : ['A'],
      },
    })
    .then(({ data }) => {
      if (data) {
        if (data.length === 0) {
          throw new Error('Nenhum cliente encontrado!');
        }

        customers = data;
      }
    })
    .catch((err: any) => {
      throw new Error(err.toString().substring(7));
    });

  return customers;
}
