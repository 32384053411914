import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../../components/DataTable';

/** services */
import api from '../../../../services/api';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** menu options */

const ConvenienceList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** convention */
  const [convention, setConvention] = useState<Array<any>>([]);

  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** navigate */
  const navigate = useHistory();

  /* refs */

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const agencyBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.cedenteConta.conta_agencia}-
          {rowData.cedenteConta.conta_agencia_DV}
        </span>
      </>
    );
  };

  const accountBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.cedenteConta.conta_numero}-
          {rowData.cedenteConta.conta_numero_DV}
        </span>
      </>
    );
  };

  const statusBodyTemplate = () => {
    return (
      <div className="flex">
        <Checkbox checked={true}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() =>
            navigate.push(`/boleto/v1/convenio/update/${rowData.id}`)
          }
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Contas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const searchConve = async () => {
    try {
      const { data } = await api.get('/v2/boleto/convenio');
      setConvention(data.convenios);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchConve();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/boleto/v1/convenio/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={convention}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} convênios"
          globalFilter={globalFilter}
          emptyMessage="Não existe convenios."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="convenio_descricao" header="Descrição"></Column>
          <Column header="Número" field="convenio_numero"></Column>
          <Column
            field="price"
            header="Agência"
            body={agencyBodyTemplate}
          ></Column>
          <Column header="Conta" body={accountBodyTemplate}></Column>
          <Column field="cedenteConta.conta_tipo" header="Tipo"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ConvenienceList;
