import { useEffect, useRef, useState } from 'react';
import { Ilanes, IUser } from '../../dtos/ICrmDTO';
import { FormHandles } from '@unform/core';
import { Dialog } from '../../../../../components/Modal';
import { Form } from '@unform/web';
import { SelectButton } from 'primereact/selectbutton';
import IOptionsDTO from '../../../../business/dtos/IOptionsDTO';
import { InputDropDown } from '../../../../../components/Inputs/InputDropDown';
import api from '../../../../../services/api';
import messageRequestError from '../../../../../utils/messageRequestError';
import useToastContext from '../../../../../hooks/toast';
import { validate } from 'uuid';
import { Divider } from '../../../../../components/Divider';
import { Button } from 'primereact/button';
import { MultiSelect } from '../../../../../components/Inputs/InputMultSelect';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../utils/getErrorsValidation';
import Progress from '../../../../../components/progress-bar';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  lanes: Ilanes[];
}

interface ITransferContactsData {
  user_id: string;
  panel_id: string;
  card_ids: string;
  column_ids: string[];
  column_target_id?: string;
}

const emptyTransferData: ITransferContactsData = {
  user_id: '',
  panel_id: '',
  card_ids: '',
  column_ids: [],
  column_target_id: '',
};

const DialogTransferCRMContacts: React.FC<IProps> = ({
  isOpen,
  onRequestClose,
  lanes,
}: IProps) => {
  /** refs & focus */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  /** states */
  const [transferType, setTransferType] = useState<'COL' | 'CAR'>('CAR');
  const [transferContactsData, setTransferContactsData] =
    useState<ITransferContactsData>(emptyTransferData);

  const [users, setUsers] = useState<IUser[]>([]);
  const [panels, setPanels] = useState<IOptionsDTO[]>([]);
  const [columns, setColumns] = useState<IOptionsDTO[]>([]);
  const [cardsOpts, setCardsOpts] = useState<IOptionsDTO[]>([]);

  /** effects */
  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (lanes && lanes.length > 0) {
      const cardsOpts: IOptionsDTO[] = [];

      lanes.forEach(l =>
        l.cards.forEach(c => {
          cardsOpts.push({
            label: c.title,
            value: c.id,
          });
        }),
      );

      setCardsOpts(cardsOpts);
    }
  }, [lanes]);

  /** functions */
  const loadUsers = async () => {
    setIsLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(
            data.map((u: any) => {
              return {
                ...u,
                label: u.name,
                value: u.id,
              };
            }),
          );
        }
      })
      .finally(() => setIsLoad(false));
  };

  const loadPanelsByUser = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/crm/panels/user/${id}`)
      .then(({ data }) => {
        if (data) {
          setPanels(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadColumnsByPanel = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/crm/column/panel/${id}`)
      .then(({ data }) => {
        if (data) {
          setColumns(
            data
              .sort((a: any, b: any) => a.position - b.position)
              .map((i: any) => {
                return {
                  value: i.id,
                  label: i.title,
                };
              }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const handleTransferCards = async () => {
    try {
      formRef.current?.setErrors({});

      const validation = Yup.object({
        column_target_id: Yup.string()
          .required('Informe a coluna que irá receber os cards!')
          .uuid('O id da coluna que irá receber os cards é inválido!'),
        card_ids: Yup.array()
          .min(1, 'Selecione pelo menos um card!')
          .required('Selecione pelo menos um card!'),
      });

      await validation.validate(transferContactsData, { abortEarly: false });

      await api
        .post('/crm/card/transfer', {
          data: {
            card_ids: transferContactsData.card_ids,
            column_id: transferContactsData.column_target_id,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Card(s) transferido(s) com sucesso!');
            handleHide();
          }
        })
        .catch((err: any) => toast('error', 'Erro', messageRequestError(err)));
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', err.errors[0]);
      } else {
        toast('error', 'Alerta', err.response?.data?.error);
      }
    }
  };

  const handleTransferColumns = async () => {
    try {
      formRef.current?.setErrors({});

      const validation = Yup.object({
        column_ids: Yup.array()
          .min(1, 'Selecione pelo menos uma coluna!')
          .required('Selecione pelo menos uma coluna!'),
      });

      await validation.validate(transferContactsData, { abortEarly: false });

      await api
        .post('/crm/column/transfer', {
          data: {
            column_ids: transferContactsData.column_ids,
            target_panel_id: transferContactsData.panel_id,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast(
              'success',
              'Sucesso',
              'Coluna(s) transferido(s) com sucesso!',
            );
            handleHide();
          }
        })
        .catch((err: any) => toast('error', 'Erro', messageRequestError(err)));
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', err.errors[0]);
      } else {
        toast('error', 'Alerta', err.response?.data?.error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        user_id: Yup.string()
          .required('Informe o usuário que irá receber os contatos!')
          .uuid('O id do usuário que irá receber os contatos é inválido!'),
        panel_id: Yup.string()
          .required('Informe o painel que irá receber os contatos!')
          .uuid('O id do painel que irá receber os contatos é inválido!'),
      });

      await validation.validate(transferContactsData, { abortEarly: false });

      transferType === 'CAR' ? handleTransferCards() : handleTransferColumns();
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', err.errors[0]);
      } else {
        toast('error', 'Alerta', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleHide = () => {
    setTransferContactsData(emptyTransferData);
    onRequestClose();
  };

  /** templates */

  return (
    <Dialog
      visible={isOpen}
      onHide={handleHide}
      className="w-10 md:w-6"
      header="Transferir contatos"
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <Progress isLoad={isLoad} />
        <div className="field col-12">
          <label htmlFor="transfer_type">O que deseja transferir?</label>
          <SelectButton
            options={
              [
                { label: 'Card(s)', value: 'CAR' },
                { label: 'Coluna(s)', value: 'COL' },
              ] as IOptionsDTO[]
            }
            value={transferType}
            onChange={e => {
              if (e.value !== null) {
                setTransferType(e.value);
              }
            }}
          />
        </div>

        <Divider align="left" type="dashed" className="mt-0">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Contatos</span>
          </div>
        </Divider>

        {transferType === 'CAR' && (
          <div className="field col-12">
            <label htmlFor="card_ids">{`Card(s) ${
              transferContactsData.card_ids.length > 0
                ? `[${transferContactsData.card_ids.length}]`
                : ''
            }`}</label>
            <MultiSelect
              name="card_ids"
              value={transferContactsData.card_ids}
              options={cardsOpts}
              onChange={e => {
                formRef.current?.setFieldError('cards', '');

                setTransferContactsData({
                  ...transferContactsData,
                  card_ids: e.value,
                });
              }}
              placeholder="Selecione o(s) card(s)"
              filter
            />
          </div>
        )}

        {transferType === 'COL' && (
          <div className="field col-12">
            <label htmlFor="column_ids">{`Coluna(s) ${
              transferContactsData.column_ids.length > 0
                ? `[${transferContactsData.column_ids.length}]`
                : ''
            }`}</label>
            <MultiSelect
              name="column_ids"
              value={transferContactsData.column_ids}
              options={lanes.map(l => {
                return {
                  label: l.title,
                  value: l.id,
                } as IOptionsDTO;
              })}
              onChange={e => {
                formRef.current?.setFieldError('column_ids', '');

                setTransferContactsData({
                  ...transferContactsData,
                  column_ids: e.value,
                });
              }}
              placeholder="Selecione a(s) coluna(s)"
              filter
            />
          </div>
        )}

        <Divider align="left" type="dashed" className="mt-0">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Destino</span>
          </div>
        </Divider>

        <div className="field col-12 md:col-6">
          <label htmlFor="user_id">Usuário</label>
          <InputDropDown
            name="user_id"
            options={users}
            value={transferContactsData.user_id}
            placeholder="Selecione..."
            onChange={async e => {
              formRef.current?.setFieldError('user_id', '');
              setTransferContactsData({
                ...transferContactsData,
                user_id: e.value,
              });
              await loadPanelsByUser(e.value);
            }}
            filter
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="panel_id">Painel</label>
          <InputDropDown
            name="panel_id"
            options={panels}
            value={transferContactsData.panel_id}
            placeholder="Selecione..."
            disabled={!validate(transferContactsData.user_id)}
            onChange={async e => {
              formRef.current?.setFieldError('panel_id', '');

              setTransferContactsData({
                ...transferContactsData,
                panel_id: e.value,
              });
              await loadColumnsByPanel(e.value);
            }}
            filter
          />
        </div>

        {transferType === 'CAR' && (
          <div className="field col-12">
            <label htmlFor="column_target_id">Coluna</label>
            <InputDropDown
              name="column_target_id"
              value={transferContactsData.column_target_id}
              options={columns}
              placeholder="Selecione..."
              disabled={!validate(transferContactsData.panel_id)}
              onChange={e => {
                formRef.current?.setFieldError('column_target_id', '');

                setTransferContactsData({
                  ...transferContactsData,
                  column_target_id: e.value,
                });
              }}
              filter
            />
          </div>
        )}

        <div className="field col-12 mt-4">
          <Button
            name="btn_reset"
            type="reset"
            className="p-button-danger"
            icon="pi pi-times"
            label="Limpar"
            onClick={() => setTransferContactsData(emptyTransferData)}
          />
        </div>
        <div className="field col-12">
          <Button
            name="btn_submit"
            type="submit"
            className="p-button-success"
            icon="fa-solid fa-paper-plane"
            label="Transferir"
            loading={isLoad}
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default DialogTransferCRMContacts;
