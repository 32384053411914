import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';

type PrintedNfe = {
  description: string;
  id: string;
  status: string;
  title: string;
};

const emptyPrintedNfe = {
  description: '',
  id: '',
  status: '',
  title: '',
};

const PrintedNfeList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  /** dialog */
  const [printNfe, setPrintNfe] = useState<PrintedNfe>(emptyPrintedNfe);
  const [printseNfe, setPrintsNfe] = useState<PrintedNfe[]>([]);
  const [deletePrintedNfe, setDeletePrintedNfe] = useState(false);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: PrintedNfe, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const statusBodyTemplate = (rowData: PrintedNfe) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeletePrintNfe = (printed: PrintedNfe) => {
    setPrintNfe(printed);
    setDeletePrintedNfe(true);
  };

  const actionBodyTemplate = (rowData: PrintedNfe) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/impresso-nfe/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeletePrintNfe(rowData)}
        />
      </div>
    );
  };

  /** dialog */

  const hideDeletePrintedDialog = () => {
    setDeletePrintedNfe(false);
  };

  const deleteGroupApi = async () => {
    try {
      await api.delete(`/impressos-nfe/${printNfe.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deletePrinted = () => {
    const _prints = printseNfe.filter(val => val.id !== printNfe.id);
    setPrintsNfe(_prints);
    hideDeletePrintedDialog();
    setPrintNfe(emptyPrintedNfe);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteGroupApi();
  };

  const deletePrintedDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeletePrintedDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deletePrinted}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Impressos NFE</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchPrintedNfe = async () => {
    try {
      const { data } = await api.get('/impressos-nfe');
      setPrintsNfe(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchPrintedNfe();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/impresso-nfe/create')}
      />
      <div className="card">
        <DataTable
          value={printseNfe}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} impressos nfe"
          globalFilter={globalFilter}
          emptyMessage="Não existe Impressos Nfe."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="code" header="N°" body={codeBodyTemplate}></Column>
          <Column field="title" header="Título"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deletePrintedNfe}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deletePrintedDialogFooter}
        onHide={hideDeletePrintedDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {printNfe && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{printNfe?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default PrintedNfeList;
