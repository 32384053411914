export default [
  {
    label: 'Segunda',
    value: 'SEG',
  },
  {
    label: 'Terça',
    value: 'TER',
  },
  {
    label: 'Quarta',
    value: 'QUA',
  },
  {
    label: 'Quinta',
    value: 'QUI',
  },
  {
    label: 'Sexta',
    value: 'SEX',
  },
  {
    label: 'Sábado',
    value: 'SAB',
  },
  {
    label: 'Domingo',
    value: 'DOM',
  },
];
