import { useQuery } from 'react-query';
import { ITransporter } from '../pages/person/dtos/ITransporter';
import api from '../services/api';

interface IProps {
  pagination: {
    page: number;
    perPage: number;
    status: string[];
  };
}

export default function useLoadTransporters({ pagination }: IProps) {
  const url = '/persons/transporters';
  const { data, error, isLoading, isFetched } = useQuery<ITransporter[]>(
    ['transporters', url],
    () =>
      api
        .post(url, {
          pagination,
        })
        .then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
