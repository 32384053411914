import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

/** menu options */

type Group = {
  description: string;
  group_father: string;
  group_id: string;
  id: string;
  status: string;
  title: string;
};

const emptyGroup = {
  description: '',
  group_father: '',
  group_id: '',
  id: '',
  status: '',
  title: '',
};

const GroupList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [dialogDeleteGroup, setDialogDeleteGroup] = useState(false);

  /** state dialog */
  const [groups, setGroups] = useState<Group[]>([]);
  const [group, setGroup] = useState(emptyGroup);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const groupTemplate = (rowData: Group) => {
    return (
      <>
        {rowData.group_father ? (
          ''
        ) : (
          <i className="pi pi-times text-pink-700"></i>
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData: Group) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeleteGroup = (group: Group) => {
    setGroup(group);
    setDialogDeleteGroup(true);
  };

  const actionBodyTemplate = (rowData: Group) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/groups/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteGroup(rowData)}
        />
      </div>
    );
  };

  /** delete dialog  */

  const hideDeleteGroupDialog = () => {
    setDialogDeleteGroup(false);
  };

  const deleteGroupApi = async () => {
    try {
      await api.delete(`/groups/${group.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteGroup = () => {
    const _groups = groups.filter(val => val.id !== group.id);
    setGroups(_groups);
    hideDeleteGroupDialog();
    setGroup(emptyGroup);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteGroupApi();
  };

  const deleteGroupDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteGroupDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteGroup}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Grupos de produtos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchGroups = async () => {
    try {
      const { data } = await api.get('/groups');
      setGroups(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchGroups();
  }, []);

  return (
    <>
      <Button
        label="Novo Grupo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/groups/create')}
      />
      <div className="card">
        <DataTable
          value={groups}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} of {totalRecords} grupos"
          globalFilter={globalFilter}
          emptyMessage="Não existe grupos."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Grupo"></Column>
          <Column
            header="Sub Grupo"
            body={groupTemplate}
            align="center"
          ></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>

          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteGroup}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteGroupDialogFooter}
        onHide={hideDeleteGroupDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {group && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{group?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default GroupList;
