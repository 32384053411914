import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** typing */
import { AdditionalItem } from '../../../types/additionalItem';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

const emptyAdditional = {
  additional_id: '',
  created_at: '',
  description: '',
  id: '',
  image_url: '',
  status: '',
  title: '',
  unt: 0,
  updated_at: '',
  value: '',
  additional: {
    created_at: '',
    description: '',
    id: '',
    image_url: '',
    limit: 0,
    required: '',
    status: '',
    title: '',
    updated_at: '',
  },
};

const AdditionalItemList = (): React.ReactElement => {
  /** state */
  const [additionals, setAdditionalsItem] = useState<Array<AdditionalItem>>([]);
  const [deleteAdditionalDialog, setDeleteAdditionaltDialog] = useState(false);
  const [additional, setAdditional] = useState<AdditionalItem>(emptyAdditional);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  /** context  */

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const hideDeleteAdditionalDialog = () => {
    setDeleteAdditionaltDialog(false);
  };

  const formatCurrency = (value: string): string => {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const deleteAdditional = async () => {
    try {
      await api.delete(`/additionals-itens/${additional.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const confirmDeleteAdditional = (additionalsItem: AdditionalItem) => {
    setAdditional(additionalsItem);
    setDeleteAdditionaltDialog(true);
  };

  const deleteProduct = () => {
    const _additionals = additionals.filter(val => val.id !== additional.id);
    setAdditionalsItem(_additionals);
    setDeleteAdditionaltDialog(false);
    setAdditional(emptyAdditional);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteAdditional();
  };

  const deleteAdditionalDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteAdditionalDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData: AdditionalItem) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const statusBodyTemplate = () => {
    return (
      <div className="flex">
        <Checkbox checked={true}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: AdditionalItem) => {
    return (
      <div className="flex justify-content-between">
        <Button
          label="Editar"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/additionals-items/edit/${rowData.id}`)}
        />
        <Button
          label="Excluir"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteAdditional(rowData)}
        />
      </div>
    );
  };

  const codeBodyTemplate = (rowData: AdditionalItem, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const valueBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {formatCurrency(rowData.value)}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Adicionais</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchAdditionalItems = async () => {
    try {
      const { data } = await api.get('/additionals-itens');
      setAdditionalsItem(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchAdditionalItems();
  }, []);

  return (
    <>
      <Button
        label="Novo Item Adicional"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/additionals-items/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={additionals}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} itens adicionais"
          globalFilter={globalFilter}
          emptyMessage="Não existe item adicionais."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="id"
            header="N°"
            sortable
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="additional.title"
            header="Grupo"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="image_url"
            header="Foto"
            sortable
            body={nameBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="title"
            header="Item"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="unt"
            header="Und"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="value"
            header="Valor"
            body={valueBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '8rem' }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="action"
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteAdditionalDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteAdditionalDialogFooter}
        onHide={hideDeleteAdditionalDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {additionals && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{additional.additional?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AdditionalItemList;
