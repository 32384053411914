import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';

/** menu options */

const VariationList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** variation */
  const [variations, setVariations] = useState<Array<any>>([]);
  const [variation, setVariation] = useState<any>();

  /** dialog */
  const [dialogDeleteVariationDialog, setDialogDeleteVariation] =
    useState(false);

  /* refs */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeleteVariation = (variation: any) => {
    setVariation(variation);
    setDialogDeleteVariation(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/variations/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteVariation(rowData)}
        />
      </div>
    );
  };

  const imageBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Listar variações de produtos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const hideDeleteVariationDialog = () => {
    setDialogDeleteVariation(false);
  };

  const deleteVariationApi = async () => {
    try {
      await api.delete(`/variations/${variation.id}`);
      toast.current?.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'variação Deletada',
        life: 3000,
      });
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteVariation = () => {
    const _variations = variations.filter(val => val.id !== variation.id);
    setVariations(_variations);
    setDialogDeleteVariation(false);
    setVariation(undefined);

    deleteVariationApi();
  };

  const deleteVariationDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteVariationDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteVariation}
      />
    </React.Fragment>
  );

  const searchVariations = async () => {
    try {
      const { data } = await api.get('/variations');
      setVariations(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchVariations();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/variations/create')}
      />
      <div className="card">
        <DataTable
          value={variations}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} variações"
          globalFilter={globalFilter}
          emptyMessage="Não existe variações."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="name"
            header="Imagem"
            body={imageBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column
            field="limit"
            header="Limite de selação"
            headerStyle={{ width: '14%', minWidth: '8rem' }}
          ></Column>
          <Column
            field="required"
            header="Obrigatorio?"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteVariationDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteVariationDialogFooter}
        onHide={hideDeleteVariationDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {variation && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{variation?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default VariationList;
