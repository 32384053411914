import IOptionsDTO from '../../business/dtos/IOptionsDTO';

export default [
  {
    label: 'Não Informado',
    value: 'N',
  },
  {
    label: 'Simples Nacional',
    value: 'S',
  },
  {
    label: 'Regime normal',
    value: 'R',
  },
] as IOptionsDTO[];
