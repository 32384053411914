import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import {
  addHours,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import MenuPopUp from '../../../components/MenuPopPup';
import { Dialog as ModalNfceCancel } from '../../../components/Modal';
import { AppContext } from '../../../context/AppContext';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import OrderHeaderButtons from '../../order/header-buttons';
import INfceDTO from '../dtos/INfceDTO';
import FastFormDownloadXml from '../fast-form-download-nfce-xml';
import statusOpts from '../types/statusOpts';

const NfceList = (): React.ReactElement => {
  /** hooks */
  const navigate = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const { state } = useContext(AppContext);

  /** const */
  const hoje = new Date();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [busca, setBusca] = useState<string>('');
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );
  const [status, setStatus] = useState<string>('');
  const [usuario, setUsuario] = useState<string>('');
  const [usuarioOpts, setUsuarioOpts] = useState<IOptionsDTO[]>([]);
  const [pedido, setPedido] = useState<number>();

  const [nfces, setNfces] = useState<INfceDTO[]>([]);
  const [nfce, setNfce] = useState<INfceDTO>();

  const [justificativa, setJustificativa] = useState<string>('');

  const [tableFilter, setTableFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pedido_tipo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    pedido_status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });

  const [filtroGlobal, setFiltroGlobal] = useState<string>('');

  const [modalCancelNfceVisible, setModalCancelNfceVisible] =
    useState<boolean>(false);

  const [modalDownloadXmlVisible, setModalDownloadXmlVisible] =
    useState<boolean>(false);

  /** functions */
  const handleSearchNfce = async () => {
    try {
      setLoadingStatus(true);

      const { data } = await api.get(`/nfce/search`, {
        params: {
          search: busca,
          date_a: startOfDay(dateStart as Date),
          date_b: endOfDay(dateEnd as Date),
          status: status,
          user_id: usuario,
          pedido: pedido,
        },
      });

      if (data.length > 0) {
        setNfces(data);
      } else {
        toast(
          'warn',
          'Alerta',
          'Nenhuma nfce foi encontrada com os parâmetros informados!',
          10000,
        );
        setNfces([]);
      }
    } catch (e: any) {
      toast('warn', 'Alerta', 'Falha ao buscar Nfces', 10000);
    } finally {
      setLoadingStatus(false);
    }
  };
  const onGlobalFilterChange = (value: any) => {
    const filters = { ...tableFilter };
    filters['global'].value = value;

    setTableFilter(filters);
  };

  /** renders */
  const tableHeader = () => {
    const value = tableFilter['global'] ? tableFilter['global'].value : '';
    return (
      <Form
        ref={null}
        onSubmit={() => null}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Autorizadas</h5>
              <span className={`product-badge status-instock `}>
                {autorizadas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Aguardando</h5>
              <span className={`customer-badge status-new `}>{aguardando}</span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Rejeitadas</h5>
              <span className={`product-badge status-outofstock`}>
                {rejeitadas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Canceladas</h5>
              <span className={`product-badge status-outofstock`}>
                {canceladas}
              </span>
            </div>
            <div className="flex align-items-center mr-4 mb-2">
              <h5 className="m-0 mr-3">Qnt. Emitida</h5>
              <span className={`customer-badge status-new `}>{emitidas}</span>
            </div>
          </div>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              name="search"
              type="search"
              value={value || ''}
              onChange={e => onGlobalFilterChange(e.currentTarget.value)}
              placeholder="Buscar..."
              autoComplete="off"
            />
          </span>
        </div>
      </Form>
    );
  };

  const renderColumnPosition = (rowData: any, attr: any) => {
    return <>{attr.rowIndex + 1}</>;
  };

  const renderColumnDate = (rowData: any) => {
    return (
      <span>{format(addHours(new Date(rowData.emissao), 3), 'dd/MM/yy')}</span>
    );
  };

  const renderColumnDecimal = (rowData: any) => {
    return (
      <span>
        {new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(rowData.valor)}
      </span>
    );
  };

  const renderColumnUser = (rowData: INfceDTO) => {
    return <span>{rowData.user_name.slice(0, 12)}</span>;
  };

  const renderCollumnStatus = (rowData: any) => {
    return (
      <>
        {rowData.status === 'CONCLUIDO' && (
          <span className={`product-badge status-instock mx-1`}>CONCLUÍDO</span>
        )}

        {rowData.status === 'PROCESSANDO' && (
          <span className={`customer-badge status-new mx-1`}>PROCESSANDO</span>
        )}

        {rowData.status === 'AGUARDANDO_CANCELAMENTO' && (
          <span className={`customer-badge status-new mx-1`}>PROCESSANDO</span>
        )}

        {rowData.status === 'CANCELADO' && (
          <span className={`product-badge status-outofstock mx-1`}>
            CANCELADA
          </span>
        )}

        {rowData.status === 'REJEITADO' && (
          <>
            <Button
              className="product-badge status-outofstock mx-1"
              tooltip={rowData.ts_id}
            >
              REJEITADO
            </Button>
            <br />
            {rowData.ts_id}
          </>
        )}

        {rowData.status === 'EXCEPTION' && (
          <>
            <Button
              className="product-badge status-outofstock mx-1"
              tooltip={rowData.ts_id}
            >
              REJEITADO
            </Button>
            <br />
            {rowData.ts_id}
          </>
        )}
      </>
    );
  };

  const sendNfce = async (id: string) => {
    try {
      setLoadingStatus(true);

      const res = await api.post(`/nfce/emit/${id}`);

      if (res.status === 200)
        toast(
          'success',
          'Sucesso',
          'Cupom fiscal enviada para a sefaz!',
          10000,
        );
    } catch (e: any) {
      toast('error', 'Erro', e.response.data.error, 10000);
    } finally {
      setLoadingStatus(false);
    }
  };

  const refreshNfce = async (id: string) => {
    try {
      setLoadingStatus(true);

      const res = await api.get(`/nfce/status/${id}`);

      if (res.status === 200) {
        toast(
          'success',
          'Sucesso',
          'Status da nota fiscal foi atualizado!',
          10000,
        );
      }

      await handleSearchNfce();
    } catch (e: any) {
      toast('error', 'Erro', e.response.data.error, 10000);
    } finally {
      setLoadingStatus(false);
    }
  };

  const downloadPdf = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfce';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfce!', 10000);
      });
  };

  const downloadXml = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfce-xml';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfce!', 10000);
      });
  };

  const printNfce = async (url: string) => {
    const headers = {
      'x-api-key': `${
        process.env.REACT_APP_OPERATION_TYPE === 'HOM'
          ? process.env.REACT_APP_TS_TOKEN_TEST
          : process.env.REACT_APP_TS_TOKEN_NEXT
      }`,
    };
    await axios
      .get(url, {
        headers,
        responseType: 'blob',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'wg-nfce';
        a.click();
        window.open(url);
      })
      .catch(() => {
        toast('error', 'Error', 'Erro ao exibir nfce!', 10000);
      });
  };

  const searchNfces = async () => {
    setLoadingStatus(true);
    await api
      .get('/nfce/search', {
        params: {
          search: busca,
          date_a: startOfDay(dateStart as Date),
          date_b: endOfDay(dateEnd as Date),
          status: status,
          user_id: usuario,
          pedido: pedido,
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setNfces(data);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const printCCe = async (id: string) => {
    return null;
  };

  const cancelNfce = async () => {
    try {
      setLoadingStatus(true);

      const res = await api.post(`/nfce/cancel/${nfce?.id}`, { justificativa });

      if (res.status === 200) {
        toast('success', 'Sucesso', 'Nfce cancelada!', 10000);
      }

      setJustificativa('');
      setModalCancelNfceVisible(false);
      await handleSearchNfce();
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
    } finally {
      setLoadingStatus(false);
    }
  };

  const mountMenu = (rowData: INfceDTO) => {
    const items: any[] = [];

    if (rowData.status === 'PROCESSANDO') {
      items.push({
        label: 'Enviar Nfce',
        icon: 'pi pi-fw pi-cloud-upload',
        command: () => sendNfce(rowData.id),
      });
    }

    if (rowData.status !== 'CONCLUIDO') {
      items.push({
        label: 'Atualizar status',
        icon: 'pi pi-fw pi-refresh',
        command: () => {
          searchNfces();
          refreshNfce(rowData.id);
        },
      });

      if (rowData.status === 'REJEITADO') {
        items.push({
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(rowData.url_xml),
        });
      }
    }

    if (rowData.status === 'CONCLUIDO') {
      items.push(
        {
          label: 'Baixar Nfce',
          icon: 'pi pi-fw pi-file-pdf',
          command: () => downloadPdf(rowData.url_pdf),
        },
        {
          label: 'Baixa Xml',
          icon: 'pi pi-fw pi-code',
          command: () => downloadXml(rowData.url_xml),
        },
        {
          label: 'Imprimir Nfce',
          icon: 'pi pi-fw pi-print',
          command: () => printNfce(rowData.url_pdf),
        },

        {
          label: 'Cancelar Nfce',
          icon: 'pi pi-fw pi-times',
          command: () => {
            setModalCancelNfceVisible(true);
          },
        },
      );
    }

    return items;
  };

  const renderCollumnMenu = (rowData: INfceDTO) => {
    const mountOpts = mountMenu(rowData);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={rowData} setData={setNfce} />
      </>
    );
  };

  const [autorizadas, setAutorizadas] = useState<number>(0);
  const [canceladas, setCanceladas] = useState<number>(0);
  const [aguardando, setAguardando] = useState<number>(0);
  const [rejeitadas, setRejeitadas] = useState<number>(0);
  const [emitidas, setEmitidas] = useState<number>(0);

  /** useEffect */
  useEffect(() => {
    const summary = nfces.reduce(
      (acc, item) => {
        if (item.status === 'CONCLUIDO') acc.ttAutorizadas++;

        if (item.status === 'PROCESSANDO') acc.ttAguardando++;

        if (item.status === 'REJEITADO' || item.status === 'DENEGADO')
          acc.ttRejeitadas++;

        if (item.status === 'CANCELADO') acc.ttCanceladas++;

        acc.ttEmitidas++;

        return acc;
      },
      {
        ttAutorizadas: 0,
        ttCanceladas: 0,
        ttAguardando: 0,
        ttRejeitadas: 0,
        ttEmitidas: 0,
      },
    );

    setAutorizadas(summary.ttAutorizadas);
    setCanceladas(summary.ttCanceladas);
    setAguardando(summary.ttAguardando);
    setRejeitadas(summary.ttRejeitadas);
    setEmitidas(summary.ttEmitidas);
  }, [nfces]);

  const renderColumnOrderOS = (d: INfceDTO) => {
    if (d.order_id) {
      return <>{d.order_num}</>;
    } else {
      return <>{d.os_num}</>;
    }
  };

  useEffect(() => {
    api
      .get('/nfce/search', {
        params: {
          search: busca,
          date_a: startOfDay(dateStart as Date),
          date_b: endOfDay(dateEnd as Date),
          status: status,
          user_id: usuario,
          pedido: pedido,
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setNfces(data);
        }
      })
      .finally(() => setLoadingStatus(false));

    api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A', 'C'],
        },
      })
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        if (opts.length > 0) {
          setUsuarioOpts([{ label: 'Todos', value: '' }, ...opts]);
        }
      });
  }, []);

  return (
    <>
      <OrderHeaderButtons />
      <div className="grid">
        <div className="col-12">
          <div className="card mb-2 pb-2">
            <Form
              ref={null}
              onSubmit={() => null}
              placeholder={''}
              onPointerEnterCapture={null}
              onPointerLeaveCapture={null}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6">
                  <label htmlFor="search">Busca inteligente</label>
                  <InputText
                    name="search"
                    type="search"
                    value={busca}
                    onChange={e => setBusca(e.currentTarget.value)}
                    placeholder="Ex: João da silva..."
                    min={3}
                    max={32}
                    autoComplete="off"
                  />
                </div>

                <div className="field col-12 md:col-2">
                  <label htmlFor="dateStart">Período inicial</label>
                  <Calendar
                    name="dateStart"
                    value={dateStart}
                    onChange={e => setDateStart(e.value as Date)}
                    dateFormat="dd/mm/yy"
                    placeholder="00/00/00"
                    showIcon
                  />
                </div>

                <div className="field col-12 md:col-2">
                  <label htmlFor="dateEnd">Período final</label>
                  <Calendar
                    name="dateEnd"
                    value={dateEnd}
                    onChange={e => setDateEnd(e.value as Date)}
                    dateFormat="dd/mm/yy"
                    placeholder="00/00/00"
                    showIcon
                  />
                </div>

                <div className="field col-12 md:col-2 flex align-items-end">
                  <Button
                    label="Buscar Nfce"
                    icon="pi pi-filter"
                    className="p-button p-button-success"
                    onClick={e => handleSearchNfce()}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="status">Status</label>
                  <Dropdown
                    value={status}
                    options={statusOpts}
                    onChange={e => {
                      setStatus(e.value);
                    }}
                    placeholder="Selecionar..."
                    optionLabel="label"
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="usuario">Usuário</label>
                  <Dropdown
                    value={usuario}
                    options={usuarioOpts}
                    onChange={e => {
                      setUsuario(e.value);
                    }}
                    placeholder="Selecionar..."
                    optionLabel="label"
                  />
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="pedido">Pedido</label>
                  <InputText
                    name="pedido"
                    type="number"
                    value={pedido}
                    onChange={e => setPedido(Number(e.currentTarget.value))}
                    placeholder="Ex: 000"
                  />
                </div>
                <div className="field col-12 md:col-1 flex align-items-end">
                  <Button
                    icon="fa-solid fa-file-code"
                    label="XML"
                    onClick={() => setModalDownloadXmlVisible(true)}
                  />
                </div>
              </div>
            </Form>
          </div>

          <div className="card mb-2 pb-2">
            <DataTable
              dataKey="id"
              header={tableHeader}
              value={nfces}
              selection={nfce}
              onSelectionChange={e => setNfce(e.value)}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              loading={loadingStatus}
              loadingIcon="pi pi-spinner text-primary"
              filters={tableFilter}
              onFilter={e => setTableFilter(e.tableFilter)}
              globalFilter={filtroGlobal}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} Nfce"
              emptyMessage="Não existem Nfce..."
              responsiveLayout="scroll"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column
                header="Ped | O.S"
                body={d => renderColumnOrderOS(d)}
                align={'center'}
              ></Column>
              <Column header="Destinatário" field="person_name"></Column>
              <Column
                header="Emissão"
                field="emissao"
                body={e => renderColumnDate(e)}
              ></Column>
              <Column header="Sr." field="serie"></Column>
              <Column header="Número" field="numero"></Column>
              <Column
                header="Valor"
                field="valor"
                body={e => renderColumnDecimal(e)}
              ></Column>
              <Column
                header="Usuário"
                field="user_name"
                body={e => renderColumnUser(e)}
              ></Column>
              <Column
                header="Status"
                field="status"
                body={e => renderCollumnStatus(e)}
              ></Column>
              <Column header="*" body={e => renderCollumnMenu(e)}></Column>
            </DataTable>
          </div>
        </div>

        <ModalNfceCancel
          header="Cancelamento de Nfce"
          visible={modalCancelNfceVisible}
          onHide={() => setModalCancelNfceVisible(false)}
          style={{ width: '50vw' }}
        >
          <Form
            ref={null}
            onSubmit={() => null}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-12">
                <label className="font-bold">Destinatário:</label>
                <span>&nbsp; &nbsp; {nfce?.person_name}</span>
              </div>
              <div className="field col-12 md:col-4">
                <label className="font-bold">Pedido:</label>
                <span>&nbsp; &nbsp; {nfce?.order_num || nfce?.os_num}</span>
              </div>
              <div className="field col-12 md:col-4">
                <label className="font-bold">Nfce Sr:</label>
                <span>&nbsp; &nbsp; {nfce?.serie}</span>
              </div>
              <div className="field col-12 md:col-4">
                <label className="font-bold">Nfce N:</label>
                <span>&nbsp; &nbsp; {nfce?.numero}</span>
              </div>
              <div className="field col-12 md:col-6">
                <label className="font-bold">Emissão</label>
                <span>
                  &nbsp; &nbsp;{' '}
                  {format(new Date(nfce?.emissao || 0), 'dd/MM/yyyy')}
                </span>
              </div>
              <div className="field col-12 md:col-4">
                <label className="font-bold">Valor</label>
                <span className="ml-3">
                  {new Intl.NumberFormat('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  }).format(nfce?.valor || 0)}
                </span>
              </div>
              <div className="field col-12 md:col-12">
                <label className="font-bold">Justificativa</label>{' '}
                <span>{justificativa.length}/16</span>
                <InputTextArea
                  placeholder="Sua justificativa..."
                  name="justificativa"
                  style={{ height: 100 }}
                  onChange={e => setJustificativa(e.currentTarget.value)}
                  autoFocus={true}
                ></InputTextArea>
              </div>
            </div>
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-6">
                <Button
                  label="Limpar"
                  type="reset"
                  icon="pi pi-times"
                  className="p-button-danger"
                  loading={loadingStatus}
                />
              </div>

              <div className="field col-12 md:col-6">
                <Button
                  label="Salvar"
                  icon="pi pi-check"
                  className="p-button-success"
                  type="submit"
                  loading={loadingStatus}
                  disabled={justificativa.length <= 16}
                  onClick={() => cancelNfce()}
                />
              </div>
            </div>
          </Form>
        </ModalNfceCancel>
      </div>
      <FastFormDownloadXml
        isOpen={modalDownloadXmlVisible}
        onRequestClose={() => setModalDownloadXmlVisible(false)}
      />
    </>
  );
};

const comparisonFn = function (prevProps: Window, nextProps: Window) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(NfceList, comparisonFn);
