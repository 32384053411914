import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

import { ColorPicker, ColorPickerProps } from 'primereact/colorpicker';
interface InputColorProps extends ColorPickerProps {
  name: string;
}

export const InputColor: React.FC<InputColorProps> = ({
  name,
  format,
  placeholder,
  className,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ColorPicker
      id="color"
      name={name}
      format={format}
      defaultValue={defaultValue}
      ref={inputRef}
      {...rest}
      className={error ? `p-invalid ${className}` : `${className}`}
    />
  );
};
