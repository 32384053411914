export function convertStringToDate2(dateString: string): Date {
  // Verifica se a string está no formato "YYYY-MM-DD"
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    throw new Error(
      'Formato de data inválido. O formato esperado é YYYY-MM-DD.',
    );
  }

  // Cria um novo objeto Date a partir da string
  const [year, month, day] = dateString.split('-').map(Number);

  // Note que os meses em JavaScript são indexados a partir de 0
  // Portanto, subtraímos 1 do mês
  return new Date(year, month - 1, day);
}
