import React, { useEffect, useRef, useState } from 'react';

import { Dialog as ModalPayments } from '../../../components/Modal';
import { addDays, addMonths, format } from 'date-fns';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IItensPagamentoDTO from '../../input-products/create-handle/dtos/IItensPagamentoDTO';
import { FormHandles } from '@unform/core';
import { InputText } from '../../../components/Inputs/InputText';
import { Button } from 'primereact/button';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { DataTable } from '../../../components/DataTable';
import { Column } from 'primereact/column';
import api from '../../../services/api';
import PerfectDivision from '../../../utils/perfectDivision';
import { v4 } from 'uuid';
import useToastContext from '../../../hooks/toast';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { IBillsToPayList } from '../dtos/IBillsToPay';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: any;
}

export const ModalCreateBillPayable = ({
  isOpen,
  onRequestClose,
  data,
}: IProps): React.ReactElement => {
  const hoje = new Date();

  const toast = useToastContext();
  const formRefPayment = useRef<FormHandles>(null);
  const [loadingStatus, setLoad] = useState<boolean>(false);

  const [pagValor, setPagValor] = useState<number>();
  const [pagParcelas, setPagParcelas] = useState<number>(1);

  const [pagCentroCusto, setPagCentroCusto] = useState<string>();
  const [paymentCondition, setPaymentCondition] = useState<string>();
  const [docSerie, setDocSerie] = useState<string>();
  const [docNum, setDocNum] = useState<string>();
  const [pagObs, setPagObs] = useState<string>();

  const [centroCustoOpts, setCentroCustoOpts] = useState<IOptionsDTO[]>([]);
  const [provider, setProvider] = useState<IOptionsDTO>();

  const [pagVencimento, setPagVencimento] = useState<
    Date | Date[] | undefined
  >();

  const [itensPagamentoTmp, setItensPagamentoTmp] = useState<
    IItensPagamentoDTO[]
  >([]);

  const handleSubmitPayment = async () => {
    setLoad(true);

    if (itensPagamentoTmp.length === 0) {
      toast('warn', 'Alerta', 'Clique no botão gerar as parcelas!');
      setLoad(false);
      return;
    }

    const res = await api.post('/bills-payable/v2/create', {
      bill: {
        provider_id: provider ? provider.value : '',
        cost_center_id: pagCentroCusto,
        doc_serie: docSerie,
        doc_number: docNum,
        payment_condition_id: paymentCondition,
        occorrence: pagParcelas === 1 ? 'UN' : 'PA',
        emited: hoje,
        date_to_pay: pagVencimento,
        value: pagValor,
        obs: pagObs,
      },
      parcels:
        itensPagamentoTmp.length > 0
          ? itensPagamentoTmp.map(i => {
              return {
                id: i.id,
                pos: i.pos,
                parcel: i.parcel,
                date_to_pay: i.date_to_pay,
                value: i.value_parcel,
              };
            })
          : [],
    });

    setLoad(false);

    if (res.status >= 200 && res.status <= 299) {
      toast('success', 'Sucesso', 'Conta a pagar cadastrada com sucesso!');
      onRequestClose();
    }
  };

  const handleGeneratePaymentsParcels = async () => {
    if (pagValor === 0 || pagValor === undefined) {
      formRefPayment.current?.setErrors({ valor: 'Informe um valor!' });
      toast('warn', 'Alerta', 'Informe um valor para pagamento!');
      return;
    }

    if (pagParcelas === 0 || pagParcelas === undefined) {
      formRefPayment.current?.setErrors({
        parcelas: 'Informe uma quantidade de parcelas!',
      });
      toast('warn', 'Alerta', 'Informe um valor para as parcelas!');
      return;
    }

    if (pagVencimento === undefined) {
      formRefPayment.current?.setErrors({
        vencimento: 'Informe um centro de custo!',
      });
      toast('warn', 'Alerta', 'Informe um centro de custos!');
      return;
    }

    if (pagCentroCusto === undefined) {
      formRefPayment.current?.setErrors({
        cost_center: 'Informe um centro de custo!',
      });
      toast('warn', 'Alerta', 'Informe um centro de custos!');
      return;
    }

    const p = PerfectDivision(pagValor, pagParcelas);
    const pgtos: IItensPagamentoDTO[] = [];

    for (let i = 0; i < pagParcelas; i++) {
      const pgto: IItensPagamentoDTO = {
        id: v4(),
        pos: i + 1,
        parcel: `${i + 1}#${pagParcelas}`,
        parcels: pagParcelas,
        date_to_pay: addMonths(pagVencimento as Date, i),
        value_parcel: p[i],
        value_doc: pagValor,
        cost_center_id: pagCentroCusto,
      };
      pgtos.push(pgto);
    }
    setItensPagamentoTmp(pgtos);
  };

  const formatTableColumnToDecimal = (row: any, property: string) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(row[property]);
  };

  const formatTableColumnToDate = (rowData: any) => {
    return <span>{format(rowData.date_to_pay as Date, 'dd/MM/yyyy')}</span>;
  };

  const formatTableColumnId = (rowData: any, attr: any) => {
    return <span>{attr.rowIndex + 1}</span>;
  };

  const handleClearFormPayments = () => {
    setPagCentroCusto('');
    setPagValor(0);
    setPagParcelas(1);
    setPagVencimento(undefined);
    setPagObs('');
    setPaymentCondition('');
    setItensPagamentoTmp([]);
  };

  const loadCostCenters = async () => {
    setLoad(true);
    await api
      .get('cost-center')
      .then(({ data }) => {
        const opts: IOptionsDTO[] = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setCentroCustoOpts(opts);
      })
      .finally(() => setLoad(false));
  };

  const listPaymentCondition = async () => {
    try {
      setLoad(true);
      const res = await api.get('/payments-condition');

      if (res.status >= 200 || res.status <= 299) {
        const opts = res.data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        const def = opts.find((i: any) => i.label === 'Dinheiro');
        setPaymentCondition(def ? def.value : opts[0]);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRefPayment.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const receiveData = (d: Partial<IBillsToPayList>) => {
    if (d) {
      if (d.provider_name && d.provider_id)
        setProvider({ label: d.provider_name, value: d.provider_id });
      if (d.value) setPagValor(d.value);
      setDocSerie(d.ser);
      setDocNum(d.number);
      setPagVencimento(addDays(hoje, 30));
      setPagCentroCusto(centroCustoOpts.find(c => c.label === 'Vendas')?.value);

      setItensPagamentoTmp([]);
    }
  };

  useEffect(() => {
    loadCostCenters();
    listPaymentCondition();
    receiveData(data);
  }, [isOpen]);

  return (
    <ModalPayments
      header="Contas a pagar"
      visible={isOpen}
      onHide={() => onRequestClose()}
      style={{ width: '50vw' }}
    >
      <Form className="" ref={formRefPayment} onSubmit={handleSubmitPayment}>
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-9">
            <label htmlFor="serie">Fornecedor</label>
            <InputText
              name="provider_id"
              value={provider ? provider.label : ''}
              placeholder="Informe o fornecedor"
              readOnly
              className="surface-300"
            />
          </div>

          <div className="field col-12 md:col-3 flex align-items-end">
            <Button
              name="btn_gerar"
              loading={loadingStatus}
              type="button"
              className="p-button-info"
              icon="fa-solid fa-table-list"
              label="Gerar"
              onClick={handleGeneratePaymentsParcels}
              disabled={
                pagValor === 0 ||
                pagParcelas === 0 ||
                pagVencimento === undefined ||
                pagVencimento === null ||
                pagCentroCusto === ''
                  ? true
                  : false
              }
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="valor">Valor</label>
            <InputNumber
              name="valor"
              value={pagValor}
              onChange={e => setPagValor(Number(e.value))}
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="R$ 0,00"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="parcelas">Parcelas</label>
            <InputNumber
              name="parcelas"
              value={pagParcelas}
              onChange={e => setPagParcelas(Number(e.value))}
              showButtons
              minFractionDigits={2}
              maxFractionDigits={2}
              buttonLayout="horizontal"
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              useGrouping={false}
              placeholder="0"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="vencimento">Vencimento</label>
            <Calendar
              name="vencimento"
              value={pagVencimento}
              onChange={e => setPagVencimento(e.value)}
              dateFormat="d/m/yy"
              minDate={hoje}
              showIcon
              placeholder="00/00/00"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="cost_center">Centro de custo</label>
            <InputDropDown
              name="cost_center"
              options={centroCustoOpts}
              value={pagCentroCusto}
              onChange={e => setPagCentroCusto(e.value)}
              placeholder="Selecionar..."
              filter
              emptyFilterMessage="Nenhum item encontrado..."
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <DataTable
              value={itensPagamentoTmp}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhuma proposta encontrada!"
            >
              <Column field="id" header="#" body={formatTableColumnId}></Column>
              <Column
                field="parcel"
                header="Parcela"
                align={'center'}
                style={{ width: '10%' }}
              ></Column>
              <Column
                field="date_to_pay"
                header="Vencimento"
                body={row => formatTableColumnToDate(row)}
                align={'center'}
                style={{ width: '60%' }}
              ></Column>
              <Column
                field="value_parcel"
                header="Vlr. Parcela"
                body={row => formatTableColumnToDecimal(row, 'value_parcel')}
              ></Column>
            </DataTable>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-6 md:col-6">
            <Button
              name="limpar"
              loading={loadingStatus}
              type="reset"
              className="p-button-danger"
              label="Limpar"
              icon="pi pi-trash"
              onClick={handleClearFormPayments}
            />
          </div>
          <div className="field col-6 md:col-6">
            <Button
              loading={loadingStatus}
              type="submit"
              className="p-button-success ml-1"
              label="Salvar"
              icon="pi pi-check"
            />
          </div>
        </div>
      </Form>
    </ModalPayments>
  );
};
