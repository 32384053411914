import React, { useEffect, useRef } from 'react';

/** yup */
import * as Yup from 'yup';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { Toast } from 'primereact/toast';

const BankEdit = (): React.ReactElement => {
  /** redirect */
  const navigate = useHistory();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** params */
  const params: { id: string } = useParams();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleCreateBank = async (data: any) => {
    try {
      const schemaApplicationCreate = Yup.object({
        codigo: Yup.number().required('Por favor, Informe um código!'),
        nome: Yup.string().required('Por favor, Informe uma nome!'),
        url: Yup.string().url('Informe uma link Válida!'),
      });

      await schemaApplicationCreate.validate(data, { abortEarly: false });
      await api.put(`/bancos/${params.id}`, { ...data, status: 'A' });
      navigate.replace('/banks/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get(`/bancos/${params.id}`).then(({ data }) => {
      formRef.current?.setData({ codigo: data.codigo });
      formRef.current?.setData({ nome: data.nome });
      formRef.current?.setData({ url: data.url });
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/banks/list')}
      />
      <Toast ref={toast} />

      <div className="card w-6">
        <Form ref={formRef} onSubmit={handleCreateBank}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Código</label>
              <InputText name="codigo" />
            </div>
            <div className="field col-12 md:col-8">
              <label htmlFor="basic">Nome</label>
              <InputText name="nome" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">URL</label>
              <InputText placeholder="Ex: https://..." name="url" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BankEdit;
