import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { RoutePrivate } from './routers/index';

/* pages */
import { Access } from './pages/Access';
import { Error } from './pages/Error';
import { ForgotPassword } from './pages/forgotPassword';
import { NotFound } from './pages/NotFound';
import OsResume from './pages/osResume';
import FrenteDeCaixa from './pages/pdv';
import { ResetPassword } from './pages/resetPassword';
import { Signin } from './pages/signin';
import { Signup } from './pages/signup';

const AppWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  switch (location.pathname) {
    case '/os/resume':
      return <Route path="/os/resume" component={OsResume} />;
    case '/signin':
      return <Route path="/signin" component={Signin} />;
    case '/signup':
      return <Route path="/signup" component={Signup} />;
    case '/forgot-password':
      return <Route path="/forgot-password" component={ForgotPassword} />;
    case '/reset-password':
      return <Route path="/reset-password" component={ResetPassword} />;
    case '/error':
      return <Route path="/error" component={Error} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    case '/access':
      return <Route path="/access" component={Access} />;

    case '/frente-caixa':
      return (
        <RoutePrivate
          isPrivate
          path="/frente-caixa"
          component={FrenteDeCaixa}
        />
      );
    default:
      return <RoutePrivate isPrivate component={App} />;
  }
};

export default withRouter(AppWrapper);
