import LimpaCpfCnpj from '../LimpaCpfCnpj';

/**
 * Entradas esperadas
 * "62985099000" -> "(62) 9 8509-9000"
 * "6285099000"  -> "(62) "
 */
const formatPhoneNumber = (phone: string): string => {
  // Verifica se o número tem 11 dígitos (com o nono dígito) ou 10 dígitos

  phone = LimpaCpfCnpj(phone);

  const hasNineDigit = phone.length === 11;
  const hasEightDigit = phone.length === 10;

  // Usa regex para formatar o número de acordo com o tamanho
  return hasNineDigit
    ? phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
    : hasEightDigit
    ? phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    : phone;
};

export default formatPhoneNumber;
