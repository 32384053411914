import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../../components/DataTable';
import renderColumnCpfCnpj from '../../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import FormFooter from '../../../../components/FormFooter';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import {
  SideBar as SidebarSearchCustomer,
  SideBar as SidebarSearchOrder,
} from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import ClearName from '../../../../utils/ClearName';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import { ICustomerSearchItemDTO } from '../../../bills-to-receive/dtos/ICustomerSearchItemDTO';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import IDevolutionItem from '../dtos/IDevolutionItem';
import IOrdersShortByCustomerId from '../dtos/IOrdersShortByCustomerId';
import OrderDevolutionHeader from '../header';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const OrderDevolutionCreate = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  /** useStates */
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [natOpeOpts, setNatOpeOpts] = useState<Array<IOptionsDTO>>([]);
  const [natOpe, setNatOpe] = useState<string>('');

  const [customerOpts, setCustomerOps] = useState<
    Array<ICustomerSearchItemDTO>
  >([]);
  const [customer, setCustomer] = useState<ICustomerSearchItemDTO>({
    id: '',
    codigo: 0,
    customer: '',
    name: '',
    cpf_cnpj: '',
    block: false,
    image_url: '',
    status: '',
  });
  const [customerKeyword, setCustomerKeyword] = useState<string>('');

  const [ordersOpts, setOrdersOpts] = useState<Array<IOrdersShortByCustomerId>>(
    [],
  );
  const [order, setOrder] = useState<any>({} as any);

  const [devolutionItems, setDevolutionItems] = useState<
    Array<IDevolutionItem>
  >([]);

  const [summary, setSummary] = useState<{ total: number; items: number }>({
    total: 0,
    items: 0,
  });

  const [sidebarSearchCustomerVisible, setSidebarSearchCustomerVisible] =
    useState<boolean>(false);
  const [sidebarSearchOrderVisible, setSidebarSearchOrderVisible] =
    useState<boolean>(false);

  const validateItemsDevolution = (): boolean => {
    let isEdited: boolean = false;
    for (const item of devolutionItems) {
      if (item.devolution_qnt > 0) {
        isEdited = true;
      }
    }
    return isEdited;
  };

  /** functions */
  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});
      const validation = Yup.object({
        nat_ope_id: Yup.string()
          .uuid('Informe um id valido para Natureza de Operação!')
          .required('Natureza de operação é obrigatória!'),
      });

      await validation.validate(d, { abortEarly: false });

      if (!validateItemsDevolution()) {
        toast('warn', 'Falha', 'Informe os itens a serem devolvidos!');
        return;
      }

      const data = {
        devolution: { nat_ope_id: natOpe, order_id: order.id },
        devolution_items: devolutionItems.map((i: IDevolutionItem) => {
          return {
            position: i.position,
            order_body_id: i.order_body_id,
            product_id: i.product_id,
            sold_qnt: i.sold_qnt,
            value_unit: i.value_unit,
            value_total: i.value_total,
            devolution_qnt: i.devolution_qnt,
            devolution_total: i.devolution_total,
          };
        }),
      };

      const res = await api.post('/devolution', data);

      if (res.status >= 200 || res.status <= 299) {
        // abre mensagem de sucesso
        toast('success', 'Sucesso', 'Devolução realizada com sucesso!');
        // gera relatório
        // redireciona para listagem
        navigate.push('/order-replace/list');
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', e.response?.data.validation.body.message);
        }
      }

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleSelectCustomer = (e: any) => {
    if (!validate(e.id)) {
      toast(
        'warn',
        'Alerta',
        `O id do cliente [${e.name}] não é valido, tentar novamnete!`,
      );
      return;
    }

    setCustomer(e);
    setSidebarSearchCustomerVisible(false);
  };

  const listOrderById = async (id: string) => {
    if (validate(id)) {
      setIsLoad(true);
      await api
        .get(`/order-header/id/${id}`)
        .then(({ data }) => {
          setOrder({
            id: data.id,
            order: data.order as number,
            sell_date: new Date(data.sell_date),
            total: data.order_total as number,
          });
          const opts = data.orderBodyByOrderHeader.map((i: any, index: any) => {
            return {
              id: v4(),
              position: index + 1,
              order_body_id: i.id,
              product_id: i.product_id,
              product: i.product.title,
              sold_qnt: Number(i.qnt),
              value_unit: Number(i.value_unit_liquid),
              value_total: Number(i.value_total_liquid),
              devolution_qnt: 0,
              devolution_total: 0,
            };
          });

          setDevolutionItems(opts);
        })
        .catch((e: any) => {
          toast(
            'warn',
            'Alerta',
            'Falha ao buscar o pedido informado no sistema!',
          );
        })
        .finally(() => setIsLoad(false));
    } else {
      toast('warn', 'Alerta', 'O id informado do pedido é inválido!');
    }
  };

  const handleSelectOrder = async (e: any) => {
    await listOrderById(e.id);
    setSidebarSearchOrderVisible(false);
  };

  const handleEditItemQntEdit = (id: string, qnt: number) => {
    const cloneDevolutionItems = devolutionItems;
    const indexOfItem = cloneDevolutionItems.findIndex(
      (i: IDevolutionItem) => i.id === id,
    );

    if (Number(qnt) > cloneDevolutionItems[indexOfItem].sold_qnt) {
      toast(
        'warn',
        'Alerta',
        `A quantidade devolvida.: ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          qnt,
        )} é maior que a quantidade comprada.: ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(cloneDevolutionItems[indexOfItem].sold_qnt)}`,
        10000,
      );

      qnt = cloneDevolutionItems[indexOfItem].sold_qnt;
    }
    cloneDevolutionItems[indexOfItem].devolution_qnt = Number(qnt);
    const total =
      Number(cloneDevolutionItems[indexOfItem].value_unit) * Number(qnt);

    cloneDevolutionItems[indexOfItem].devolution_total = total;
    setDevolutionItems([...cloneDevolutionItems]);
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const opts = customers.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            customer: item.customer,
            name: item.name,
            cpf_cnpj: item.cpf_cnpj,
            block: item.block,
            image_url: item.image_url,
            status: item.status,
          };
        });

        setCustomerOps(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSidebarSearchOrderVisible = async () => {
    if (validate(customer.id)) {
      setSidebarSearchOrderVisible(true);
      await searchOrdersByCustomerId(customer.id);
    } else {
      toast('warn', 'Alerta', 'Selecione um cliente!');
      return;
    }
  };

  const searchOrdersByCustomerId = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/order-header/by-customer-id/${id}`)
      .then(({ data }) => {
        setOrdersOpts(data.orders);
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', 'Falha ao buscar documentos no sistema!');
      })
      .finally(() => setIsLoad(false));
  };

  const listNatureOperations = async () => {
    setIsLoad(true);
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setNatOpeOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.title,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', 'Falha ao listar as naturezas de operação!');
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const listParams = async () => {
    setIsLoad(true);
    await api
      .get('/params/devolution/default')
      .then(({ data }) => {
        setNatOpe(data.defaultDevolutionParams.natureza_operacao.id || '');
      })
      .catch((e: any) => {
        toast(
          'warn',
          'Alerta',
          'Falha ao listar osparâmetrospadrão de devolução!',
        );
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  /** useEffect */
  useEffect(() => {
    listParams();
    listNatureOperations();
  }, []);

  useEffect(() => {
    if (validate(customer.id)) {
      searchOrdersByCustomerId(customer.id);
    }
  }, [sidebarSearchOrderVisible]);

  useEffect(() => {
    const aux = devolutionItems.reduce(
      (acc, item) => {
        acc.total += item.devolution_total;
        acc.items += item.devolution_qnt;
        return acc;
      },
      {
        total: 0,
        items: 0,
      },
    );

    setSummary({
      total: aux.total,
      items: aux.items,
    });
  }, [devolutionItems]);

  /** render */
  const renderColumnEditQnt = (row: IDevolutionItem) => {
    return (
      <InputNumber
        inputClassName="text-center"
        name={`${row.id}`}
        value={row.devolution_qnt}
        onChange={e => handleEditItemQntEdit(row.id, Number(e.value))}
        max={Number(row.sold_qnt)}
        minFractionDigits={2}
        maxFractionDigits={2}
        placeholder="0,00"
      />
    );
  };

  return (
    <>
      <OrderDevolutionHeader />
      {isLoad && <ProgressBar mode="indeterminate" style={{ height: '4px' }} />}
      <Form
        ref={formRef}
        onSubmit={e => handleSubmit(e)}
        className="card w-8"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <label htmlFor="nat_ope_id">Natureza de operação</label>
            <InputDropDown
              name="nat_ope_id"
              options={natOpeOpts}
              value={natOpe}
              placeholder="Selecionar..."
              onChange={e => setNatOpe(e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <Button
              label={customer.id === '' ? 'Buscar cliente' : customer.name}
              type="button"
              icon={`fa-solid ${
                !validate(order.id) ? 'fa-search' : 'fa-check'
              }`}
              iconPos="right"
              className={`p-button-outlined ${
                customer.id === '' ? 'p-button-danger' : 'p-button-info'
              } ml-auto`}
              onClick={() => setSidebarSearchCustomerVisible(true)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <Button
              label={
                !validate(order.id)
                  ? 'Buscar pedido'
                  : `Ped.: ${order.order} - Data.: ${format(
                      order.sell_date,
                      'dd/MM/yy',
                    )} - ${new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.total)}`
              }
              type="button"
              icon={`fa-solid ${
                !validate(order.id) ? 'fa-search' : 'fa-check'
              }`}
              iconPos="right"
              className={`p-button-outlined ${
                !validate(order.id) ? 'p-button-danger' : 'p-button-info'
              } ml-auto`}
              onClick={() => handleSidebarSearchOrderVisible()}
            />
          </div>
        </div>

        {devolutionItems.length > 0 && (
          <div className="field col-12 md:col">
            <DataTable
              value={devolutionItems}
              responsiveLayout="scroll"
              emptyMessage="Nenhuma opção"
              loading={isLoad}
              paginator
              rows={100}
              rowsPerPageOptions={[100, 150, 200]}
              size="small"
              footer={`Total devolvido.: ${new Intl.NumberFormat('BRL', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(summary.total)}`}
            >
              <Column field="position" header="#" align={'center'}></Column>
              <Column field="product" header="Produto"></Column>
              <Column
                field="sold_qnt"
                header="Qnt."
                body={e => renderColumnDecimal(e.sold_qnt)}
              ></Column>
              <Column
                field="value_unit"
                header="Vlr."
                body={e => renderColumnDecimal(e.value_unit)}
              ></Column>
              <Column
                field="value_total"
                header="Total"
                body={e => renderColumnDecimal(e.value_total)}
                align={'right'}
              ></Column>
              <Column
                field="devolution_qnt"
                header="Qnt. devolvida"
                body={e => renderColumnEditQnt(e)}
                align={'center'}
              ></Column>
              <Column
                field="devolution_total"
                header="Total"
                body={e => renderColumnDecimal(e.devolution_total)}
                align={'right'}
              ></Column>
            </DataTable>
          </div>
        )}

        <FormFooter key={v4()} loadingStatus={isLoad} />
      </Form>

      <SidebarSearchCustomer
        visible={sidebarSearchCustomerVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setSidebarSearchCustomerVisible(false)}
      >
        <h3>Buscar cliente</h3>
        <Form
          ref={null}
          onSubmit={() => null}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="customer_keyword"
                placeholder="Nome do cliente"
                type={'text'}
                value={customerKeyword}
                onChange={e => setCustomerKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={customerKeyword.length < 3 ? true : false}
                onClick={() => searchCustomer(customerKeyword)}
                loading={isLoad}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={customerOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={isLoad}
                onSelectionChange={e => handleSelectCustomer(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="name" header="Nome"></Column>
                <Column
                  field="cpf_cnpj"
                  header="CPF/CNPJ"
                  body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SidebarSearchCustomer>

      <SidebarSearchOrder
        visible={sidebarSearchOrderVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setSidebarSearchOrderVisible(false)}
      >
        <h3>Buscar pedido</h3>
        <Form
          ref={null}
          onSubmit={() => null}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <DataTable
                value={ordersOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={isLoad}
                onSelectionChange={e => handleSelectOrder(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column
                  field="natureza_operacao"
                  header="Nat. Operação"
                ></Column>
                <Column field="cliente" header="Cliente"></Column>
                <Column field="cond_pagamento" header="Cond. PGTO"></Column>
                <Column field="pedido" header="Ped." align={'center'}></Column>
                <Column
                  field="total"
                  header="Total"
                  body={e => renderColumnDecimal(e.total)}
                  align={'right'}
                ></Column>
                <Column
                  field="comissionado"
                  header="Vendedor"
                  body={e => ClearName(e.comissionado)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SidebarSearchOrder>
    </>
  );
};

export default OrderDevolutionCreate;
