import { format } from 'date-fns';
import React from 'react';

const renderColumnDate = (date: string | Date) => {
  if (date === null) {
    return <span>-</span>;
  }

  if (date === undefined) {
    return <span>-</span>;
  }

  if (typeof date === 'string') {
    return <span>{format(new Date(date), 'dd/MM/yy')}</span>;
  }

  if (typeof date === 'object') {
    return <span>{format(date, 'dd/MM/yy')}</span>;
  }
};

export default renderColumnDate;
