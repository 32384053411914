export interface ISocio {
  cnpj_basico: string;
  identificador_socio: string;
  nome_socio_razao_social: string;
  cpf_cnpj_socio: string;
  qualificacao_socio: string;
  data_entrada_sociedade: string;
  pais: string | null;
  representante_legal: string;
  nome_do_representante: string | null;
  qualificacao_representante_legal: string;
  faixa_etaria: string;
}

export interface IBusiness {
  cnpj?: string;
  cnpj_basico: string;
  cnpj_ordem: string;
  cnpj_dv: string;
  identificador_matriz_filial: string;
  nome_fantasia: string | null;
  situacao_cadastral: string;
  data_situacao_cadastral?: string;
  motivo_situacao_cadastral: string;
  nome_cidade_exterior: string | null;
  pais: string | null;
  data_inicio_atividade?: string;
  cnae_fiscal_principal: string;
  cnae_fiscal_secundaria: string | null;
  tipo_logradouro: string;
  logradouro: string;
  numero: string;
  complemento: string | null;
  bairro: string;
  cep?: string;
  uf: string;
  municipio: string;
  cidade?: {
    descricao: string;
  };
  ddd_1: string;
  telefone_1?: string;
  ddd_2: string | null;
  telefone_2: string | null;
  ddd_fax: string | null;
  fax: string | null;
  correio_eletronico: string;
  situacao_especial: string | null;
  data_situacao_especial: string | null;
  cnaePrincipal: {
    codigo: string;
    descricao: string;
  };
  socios: ISocio[];
  empresa: {
    razao_social: string;
  };
  cnaesSecundarios: {
    codigo: string;
    descricao: string;
  }[];
}

export const emptyBusiness: IBusiness = {
  cnpj: undefined,
  cnpj_basico: '',
  cnpj_ordem: '',
  cnpj_dv: '',
  identificador_matriz_filial: '',
  nome_fantasia: null,
  situacao_cadastral: '',
  data_situacao_cadastral: undefined,
  motivo_situacao_cadastral: '',
  nome_cidade_exterior: null,
  pais: null,
  data_inicio_atividade: undefined,
  cnae_fiscal_principal: '',
  cnae_fiscal_secundaria: null,
  tipo_logradouro: '',
  logradouro: '',
  numero: '',
  complemento: null,
  bairro: '',
  cep: undefined,
  uf: '',
  municipio: '',
  cidade: {
    descricao: '',
  },
  ddd_1: '',
  telefone_1: undefined,
  ddd_2: null,
  telefone_2: null,
  ddd_fax: null,
  fax: null,
  correio_eletronico: '',
  situacao_especial: null,
  data_situacao_especial: null,
  cnaePrincipal: {
    codigo: '',
    descricao: '',
  },
  socios: [],
  empresa: {
    razao_social: '',
  },
  cnaesSecundarios: [],
};
