import Round from './Round';

export default function PerfectDivision(
  value: number,
  parcels: number,
): number[] {
  let amount = 0;
  const p: number[] = [];

  for (let i = 1; i <= parcels; i++) {
    if (i !== parcels) {
      const vlr = Round(value / parcels);
      p.push(vlr);
      amount += vlr;
    } else {
      p.push(Round(value - amount));
    }
  }

  return p;
}
