import React, { useState, useEffect } from 'react';

/** components */
import { CSSTransition } from 'react-transition-group';
import { AutoComplete } from 'primereact/autocomplete';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

const AppSearch = (props: any): React.ReactElement => {
  const searchInputEl = null;

  const [options, setOptions] = useState(props.searchOption);
  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState();

  const router = useHistory();

  const onInputKeydown = (event: React.KeyboardEvent) => {
    const key = event.which;

    //escape, tab and enter
    if (key === 27 || key === 9 || key === 13) {
      props.onSearchHide(event);
    }
  };

  const searchOptions = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredOptions;
      if (!event.query.trim().length) {
        _filteredOptions = [...options];
      } else {
        _filteredOptions = options.filter((options: any) => {
          return options.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      setFilteredOptions(_filteredOptions);
    }, 250);
  };

  const onEnter = () => {
    if (searchInputEl) {
      searchInputEl.focus();
    }
  };

  const selectedItemTemplate = () => {
    return <div style={{ backgroundColor: 'red' }}>adsadsa</div>;
  };

  const openRouter = (e: any) => {
    router.push(e.value.code);
    props.onSearchHide(event);
    setSelectedCountry1(null);
  };

  useEffect(() => {
    setOptions(props.searchOption);
  }, [props.searchOption]);

  return (
    <div className="layout-search" style={{ zIndex: '1000' }}>
      <CSSTransition
        classNames="search-container"
        timeout={{ enter: 400, exit: 400 }}
        in={props.searchActive}
        unmountOnExit
        onEnter={onEnter}
      >
        <div className="search-container" onClick={props.onSearchClick}>
          <i className="pi pi-search z-5"></i>
          <AutoComplete
            value={selectedCountry1}
            suggestions={filteredOptions}
            completeMethod={searchOptions}
            className="w-full"
            field="name"
            placeholder="Buscar"
            onChange={e => setSelectedCountry1(e.value)}
            optionGroupTemplate={selectedItemTemplate}
            aria-label="Countries"
            onSelect={e => openRouter(e)}
            onKeyPress={onInputKeydown}
            autoFocus
          />
        </div>
      </CSSTransition>
    </div>
  );
};

export default AppSearch;
