import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';

/** services */
import api from '../../../services/api';

/** menu options */

const MainActivityList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [mainActivity, setActivity] = useState<Array<any>>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  /* navigate */
  const navigate = useHistory();

  /** ref */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const deleteMainActivity = async (id: string) => {
    try {
      await api.delete(`/main-activity/${id}`);
      showToast('success', 'Sucesso!', 'Atividade principal deleta!');
      listAllMainActivity();
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/main-activity/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => deleteMainActivity(rowData.id)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Atividades</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const listAllMainActivity = async () => {
    try {
      const { data } = await api.get('/main-activity');
      setActivity(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  useEffect(() => {
    listAllMainActivity();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/main-activity/create')}
      />
      <div className="card">
        <Toast ref={toast} />

        <DataTable
          value={mainActivity}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} atividades"
          globalFilter={globalFilter}
          emptyMessage="Não existe atividades."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default MainActivityList;
