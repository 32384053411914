import React, { useRef, useState } from 'react';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const ReportContactCustomer = (): React.ReactElement => {
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const toast = useRef<any>(null);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReportsContactCustomer = async () => {
    try {
      setLoadingStatus(true);
      const { data: response } = await api.get(`/reports/customers/contacts`);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <div className="card w-6">
        <Toast ref={toast} />
        <div className="p-fluid grid formgrid ">
          <div className="field col-12 md:col">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
              onClick={() => handleReportsContactCustomer()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportContactCustomer;
