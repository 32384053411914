export const tipoPessoaOpts = [
  { label: 'Pessoa física', value: 'F' },
  { label: 'Pessoa jurídica', value: 'J' },
];

export const regimeTributarioOpts = [
  {
    label: 'Nenhum',
    value: '0',
  },
  {
    label: 'Simples Nacional',
    value: '1',
  },
  {
    label: 'Simples Nacional - Excesso',
    value: '2',
  },
  {
    label: 'Regime Normal - Lucro Presumido',
    value: '3',
  },
  {
    label: 'Normal - Lucro Real',
    value: '4',
  },
];

export const tamanhoEmpresaOpts = [
  {
    label: 'Micro Empreendedor Individual',
    value: 'MEI',
  },
  {
    label: 'Micro Empreendedor',
    value: 'MIC',
  },
  {
    label: 'Empresa Pequeno Porte',
    value: 'PEQ',
  },
  {
    label: 'Empresa Médio Porte',
    value: 'MED',
  },
  {
    label: 'Empresa Grande Porte',
    value: 'GRA',
  },
];

export const seguimentoEmpresaOpts = [
  {
    label: 'Comercio',
    value: 'COM',
  },
  {
    label: 'E-commerce',
    value: 'ECO',
  },
  {
    label: 'Industria',
    value: 'IND',
  },
  {
    label: 'Serviços',
    value: 'SER',
  },
];

export const qntFuncionariosOpts = [
  {
    label: '1 - 5',
    value: 5,
  },
  {
    label: '5 - 10',
    value: 10,
  },
  {
    label: '10 - 20',
    value: 20,
  },
  {
    label: '20 - 50',
    value: 50,
  },
  {
    label: '50 - 100',
    value: 100,
  },
  {
    label: '+100',
    value: 1000,
  },
];
