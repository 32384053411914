import React, { useRef, useState, useEffect, useContext } from 'react';

/** components */
import DefaultPage from '../../../components/DefaultPage';

/** types */
import { Option } from '../../../types/optional';

/** yup */
import * as Yup from 'yup';

const optsStatus: Option[] = [
  {
    label: 'Concluido',
    value: 'CONCLUIDO',
  },
  {
    label: 'Rejeitada',
    value: 'REJEITADO',
  },
  {
    label: 'Cancelada',
    value: 'CANCELADO',
  },
  {
    label: 'Processando cancelamento',
    value: 'AGUARDANDO_CANCELAMENTO',
  },
  {
    label: 'Processando envio',
    value: 'PROCESSANDO',
  },
];

/** unform */
import { Form } from '@unform/web';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import api from '../../../services/api';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import Calendar from '../../../components/Inputs/InputCalendar';
import { endOfMonth, startOfDay } from 'date-fns';
import { startOfMonth } from 'date-fns/esm';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import getValidationErrors from '../../../utils/getErrorsValidation';
import useToastContext from '../../../hooks/toast';

const ReportsNfe = () => {
  const form = useRef<any>(null);
  const toast = useToastContext();

  const [customers, setCustomers] = useState<Option[]>([]);
  const [customersSelected, setCustomersSelected] = useState<Array<string>>([]);

  /** nature operation */
  const [natOperations, setNatOperations] = useState<Option[]>([]);
  const [natOperationSelected, setNatOperationSelected] = useState<
    Array<string>
  >([]);

  /** users */
  const [users, setUsers] = useState<Option[]>([]);
  const [selectedUser, setSelectedUser] = useState<Array<string>>([]);

  /** status */
  const [statusSelected, setStatusSelected] = useState<Array<string>>([]);

  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfMonth(new Date()),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfMonth(new Date()),
  );

  const [loadingStatus, setLoadingStatus] = useState(false);

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schema = Yup.object().shape({
        customer_ids: Yup.array()
          .nullable()
          .of(Yup.string().uuid('Informe um id válido para os clientes!')),
        nat_operation_ids: Yup.array()
          .nullable()
          .of(
            Yup.string().uuid(
              'Informe um id válido para as naturezas de operação!',
            ),
          ),
        user_ids: Yup.array()
          .nullable()
          .of(Yup.string().uuid('Informe um id válido para os usuários!')),
        status: Yup.array().nullable().of(Yup.string()),
        date_start: Yup.string(),
        date_end: Yup.string(),
      });

      await schema.validate(data, { abortEarly: false });

      const { data: response } = await api.post(`/reports/nfe`, {
        filter: {
          customer_ids: customersSelected,
          nat_operation_ids: natOperationSelected,
          user_ids: selectedUser,
          status: statusSelected,
          date_start: initialDate,
          date_end: finalDate,
        },
      });

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        form.current?.setErrors(errors);
        toast(
          'error',
          'Erro em criar um relatório',
          `Erro: [${error.errors[0]}]`,
        );
        return;
      }
      if (error.response?.data?.error) {
        toast(
          'error',
          'Erro em criar um relatório',
          `Erro: [${error.response?.data?.error}]`,
        );
        return;
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/nfe/data/filter')
      .then(({ data }) => {
        setCustomers(
          data.dataFilter.clientes || {
            label: 'Sem clientes',
            value: [],
          },
        );
        setNatOperations(
          data.dataFilter.naturezas_operacoes || {
            label: 'Sem naturezas de operação',
            value: [],
          },
        );
        setUsers(
          data.dataFilter.usuarios || {
            label: 'Sem usuários!',
            value: [],
          },
        );
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className=" card md:w-6 sm:w-full">
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Clientes</label>
              <MultiSelect
                name="customer_ids"
                options={customers}
                value={customersSelected}
                onChange={e => setCustomersSelected(e.value)}
                placeholder="Selecionar..."
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Natureza de operação</label>
              <MultiSelect
                name="nat_operation_ids"
                options={natOperations}
                value={natOperationSelected}
                onChange={e => setNatOperationSelected(e.value)}
                placeholder="Selecionar..."
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Usuários</label>
              <MultiSelect
                name="user_ids"
                options={users}
                value={selectedUser}
                onChange={e => setSelectedUser(e.value)}
                placeholder="Selecionar..."
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Status</label>
              <MultiSelect
                name="status"
                options={optsStatus}
                value={statusSelected}
                onChange={e => setStatusSelected(e.value)}
                placeholder="Selecionar..."
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Início</label>
              <Calendar
                name="date_start"
                value={initialDate}
                onChange={e => setInitialDate(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Fim</label>
              <Calendar
                name="date_end"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-12 mt-4">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsNfe;
