import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { Toast } from 'primereact/toast';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** utils */
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { ProgressBar } from 'primereact/progressbar';

const GroupEdit = (): React.ReactElement => {
  const router = useHistory();

  const [description, setDescription] = useState<string | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const params: { id: string } = useParams();

  /** ref */
  const toast = useRef<any>(null);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: { title: string; description: string }) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schemaGroup = Yup.object({
        title: Yup.string().required('Por favor, informe o nome do grupo!'),
      });
      await schemaGroup.validate(data, { abortEarly: false });
      await api.put(`/groups/${params.id}`, data);
      router.push('/groups/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** ref */
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get(`/groups/${params.id}`)
      .then(({ data }) => {
        formRef.current?.setData({
          title: data[0].title,
        });
        setDescription(data[0].description);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => router.push('/groups/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Toast ref={toast}></Toast>

        <div className="card md:w-6 sm:w-full">
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Nome do grupo</label>
              <InputText placeholder="Grupo..." name="title" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <InputTextEdit
                name="description"
                value={description as string}
                style={{ height: '150px' }}
                onTextChange={e => setDescription(e.htmlValue)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default GroupEdit;
