import React, { useRef, useEffect } from 'react';

/* unform */
import { useField } from '@unform/core';

import {
  MultiSelect as InputMultiSelect,
  MultiSelectProps,
} from 'primereact/multiselect';

/* props inputText */
interface PropsInput extends MultiSelectProps {
  name: string;
}

export const MultiSelect: React.FC<PropsInput> = ({
  name,
  placeholder,
  className,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <InputMultiSelect
      placeholder={placeholder}
      name={name}
      ref={inputRef}
      {...rest}
      className={error ? `p-invalid ${className}` : `${className}`}
      filter
    />
  );
};
