import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validate } from 'uuid';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { IContractDTO } from './dtos/IContractDTO';

const renderContract = (contract: string) => {
  return <div dangerouslySetInnerHTML={{ __html: contract }} />;
};

const osPrintContract = () => {
  /** hooks */
  const params: { id: string } = useParams();
  const toast = useToastContext();

  /** useState */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [contract, setContract] = useState<IContractDTO>({
    id: '',
    codigo: 0,
    title: 'NÃO ENCONTRADO!',
    text: 'NÃO ENCONTRADO!',
  });

  const print = () => {
    window.print();
  };

  const searchContractById = async (id: string) => {
    if (validate(id)) {
      setLoadingStatus(true);
      await api
        .get(`/os/contract/${params.id}`)
        .then(({ data }) => {
          setContract({
            id: data.listByIdOsContract.id,
            codigo: data.listByIdOsContract.codigo,
            title: data.listByIdOsContract.title,
            text: data.listByIdOsContract.text,
          });
        })
        .catch((e: any) => {
          toast(
            'warn',
            'Alerta',
            'Falha ao carregar o contrato para impressão!',
          );
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  useEffect(() => {
    searchContractById(params.id);
  }, [params.id]);

  return (
    <>
      <Button
        type="button"
        label="Imprimir"
        icon="fa-solid fa-print"
        onClick={print}
        style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }}
      ></Button>

      <div className="grid">
        <div className="col">
          <div className="card">
            <div id="invoice-content">
              <div className="invoice">
                <h3>{contract.title}</h3>
                {renderContract(contract.text)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default osPrintContract;
