export interface IParametersStock {
  id: string;
  natureza_operacao_id: string;
  consome_estoque_prod: boolean;
  aceita_estoque_negativo: boolean;
  atualiza_custo_prod_entrada: boolean;
  gera_sku_automatico: boolean;
  limite_tags_produto: number;
  user_id: string;
  naturezaOperacao: {
    id: string;
    b_id: string;
    title: string;
    info_adicional: string | null;
    kind_nat_ope: string;
    status: string;
  };
}

export const emptyParametersStock: IParametersStock = {
  id: '',
  natureza_operacao_id: '',
  consome_estoque_prod: true,
  aceita_estoque_negativo: false,
  atualiza_custo_prod_entrada: true,
  gera_sku_automatico: true,
  limite_tags_produto: 10,
  user_id: '',
  naturezaOperacao: {
    id: '',
    b_id: '',
    title: '',
    info_adicional: null,
    kind_nat_ope: '',
    status: '',
  },
};
