import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** typing */
import { Additional } from '../../../types/additional';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

const emptyAdditional = {
  id: '',
  description: '',
  image_url: '',
  status: '',
  limit: 0,
  required: '',
  title: '',
  b_id: '',
};

const AdditionalList = (): React.ReactElement => {
  /** state */
  const [additionals, setAdditionals] = useState<Array<Additional>>([]);
  const [deleteAdditionalDialog, setDeleteAdditionaltDialog] = useState(false);
  const [additional, setAdditional] = useState<Additional>(emptyAdditional);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const hideDeleteAdditionalDialog = () => {
    setDeleteAdditionaltDialog(false);
  };

  const deleteAdditional = async () => {
    try {
      await api.delete(`/additionals/${additional.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const confirmDeleteAdditional = (additionals: any) => {
    setAdditional(additionals);
    setDeleteAdditionaltDialog(true);
  };

  const deleteProduct = () => {
    const _additionals = additionals.filter(val => val.id !== additional.id);
    setAdditionals(_additionals);
    setDeleteAdditionaltDialog(false);
    setAdditional(emptyAdditional);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteAdditional();
  };

  const deleteAdditionalDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteAdditionalDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData: Additional) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const requiredBodyTemplate = (rowData: Additional) => {
    return (
      <>{rowData.required === 'S' ? <span>Sim</span> : <span>Não</span>}</>
    );
  };

  const statusBodyTemplate = () => {
    return (
      <div className="flex">
        <Checkbox checked={true}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: Additional) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/additionals/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteAdditional(rowData)}
        />
      </div>
    );
  };

  const codeBodyTemplate = (rowData: Additional, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Adicionais</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchAdditionals = async () => {
    try {
      const { data } = await api.get('/additionals');
      setAdditionals(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error?.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchAdditionals();
  }, []);

  return (
    <>
      <Button
        label="Novo Adicional"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/additionals/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={additionals}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} adicionais"
          globalFilter={globalFilter}
          emptyMessage="Não existe Adicionais."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="id"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="image_url"
            header="Produto"
            body={nameBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="title"
            header="Título"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="limit"
            header="Lim. Itens"
            headerStyle={{ width: '14%', minWidth: '8rem' }}
          ></Column>
          <Column
            field="required"
            header="Obrigatorio"
            body={requiredBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="action"
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteAdditionalDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteAdditionalDialogFooter}
        onHide={hideDeleteAdditionalDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {additionals && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{additional?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AdditionalList;
