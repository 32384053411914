import { Form } from '@unform/web';
import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import WalletHeader from '../header';

type GraphData = {
  label: string;
  backgroundColor: string;
  data: Array<number>;
};

const resumeWallet: React.FC = () => {
  const hoje = new Date();

  const toast = useToastContext();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  interface IBalance {
    data: Date;
    value: number;
  }

  const [saldoInicio, setSaldoInicio] = useState<IBalance>({
    data: hoje,
    value: 0,
  });

  const [saldoFim, setSaldoFim] = useState<IBalance>({
    data: hoje,
    value: 0,
  });

  const [saldoHoje, setSaldoHoje] = useState<IBalance>({
    data: hoje,
    value: 0,
  });

  const [recebimentos, setRecebimentos] = useState<number>(0);
  const [pagamentos, setPagamentos] = useState<number>(0);

  interface IDatasets {
    label: string;
    backgroundColor: string;
    data: number[];
  }

  interface IData {
    labels: string[];
    datasets: IDatasets[];
  }

  const [data, setData] = useState<IData>({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
    datasets: [],
  });

  const searchDataGraph = async () => {
    try {
      const response = await api.post('wallets/movimentations/resume', {
        dataShow: {
          date_start: startOfDay(dateStart as Date),
          date_end: endOfDay(dateEnd as Date),
          status: 'A',
        },
      });

      setSaldoInicio({
        data:
          response.data.resumeWallet?.saldo_inicio.data || startOfMonth(hoje),
        value: response.data.resumeWallet?.saldo_inicio.value || 0,
      });

      setSaldoFim({
        data: response.data.resumeWallet.saldo_fim.data || endOfMonth(hoje),
        value: response.data.resumeWallet.saldo_fim.value || 0,
      });

      setSaldoHoje({
        data: response.data.resumeWallet.saldo_hoje.data || hoje,
        value: response.data.resumeWallet.saldo_hoje.value || 0,
      });

      setRecebimentos(response.data.resumeWallet.recebimentos);

      setPagamentos(response.data.resumeWallet.pagamentos);

      const receive: GraphData = {
        label: 'Recebimentos',
        backgroundColor: '#3498DB',
        data: [],
      };
      const payments: GraphData = {
        label: 'Pagamentos',
        backgroundColor: '#E74C3C',
        data: [],
      };

      const profits: GraphData = {
        label: 'Lucro/Prejuízo',
        backgroundColor: '#2ECC71',
        data: [],
      };

      for (
        let i = 0;
        i < response.data.resumeWallet.graph.recebimentos.length;
        i++
      ) {
        receive.data.push(
          Number(response.data.resumeWallet.graph.recebimentos[i].total),
        );
      }
      for (
        let i = 0;
        i < response.data.resumeWallet.graph.pagamentos.length;
        i++
      ) {
        payments.data.push(
          response.data.resumeWallet.graph.pagamentos[i].total * -1,
        );
      }
      for (let i = 0; i < receive.data.length; i++) {
        const difference = receive.data[i] - payments.data[i] || 0;

        profits.data.push(difference);
      }

      const ammountData = data;
      ammountData.datasets = [];
      ammountData.datasets.push(receive);
      ammountData.datasets.push(payments);
      ammountData.datasets.push(profits);
      ammountData.labels = response.data.resumeWallet.months_names;

      ammountData.datasets = ammountData.datasets.filter(
        dataset => !dataset.data.every(value => value === 0),
      );

      setData({ ...ammountData });
    } catch (error: any) {
      toast('error', 'Falha', error.response?.data?.error || error.message);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    searchDataGraph();
    setLoadingStatus(false);
  }, []);

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: '#ebedef',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ebedef',
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        ticks: {
          color: '#ebedef',
        },
        grid: {
          color: '#495057',
          drawBorder: false,
        },
      },
    },
  };

  return (
    <>
      <WalletHeader />
      <div className="card">
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="date_start">Início</label>
              <Calendar
                name="date_start"
                placeholder="00/00/00"
                dateFormat="dd/mm/yy"
                showIcon
                value={dateStart}
                onChange={e => setDateStart(e.value as Date)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="date_end">Fim</label>
              <Calendar
                name="date_end"
                placeholder="00/00/00"
                dateFormat="dd/mm/yy"
                showIcon
                value={dateEnd}
                onChange={e => setDateEnd(e.value as Date)}
              />
            </div>

            <div className="field col-12 md:col-4 flex align-items-end">
              <Button
                label="Buscar"
                className="p-button-success"
                onClick={() => searchDataGraph()}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Divider align="left" className="my-2">
                <div className="inline-flex align-items-center">
                  <i
                    className="fa-solid fa-chart-simple mr-2"
                    style={{ color: 'var(--primary-color)' }}
                  ></i>
                  <b>Gráficos</b>
                </div>
              </Divider>

              <Chart type="bar" data={data} options={options} />
            </div>

            <div className="field col-12 md:col-6">
              <Divider align="left" className="my-2">
                <div className="inline-flex align-items-center">
                  <i
                    className="fa-solid fa-circle-info mr-2"
                    style={{ color: 'var(--primary-color)' }}
                  ></i>
                  <b>Informações</b>
                </div>
              </Divider>

              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
                  <div>Pagamentos</div>
                  <div>
                    {new Intl.NumberFormat('BRL', {
                      currency: 'BRL',
                      style: 'currency',
                    }).format(pagamentos)}
                  </div>
                </div>

                <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
                  <div>Recebimentos</div>
                  <div>
                    {new Intl.NumberFormat('BRL', {
                      currency: 'BRL',
                      style: 'currency',
                    }).format(recebimentos)}
                  </div>
                </div>

                <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
                  <div>
                    Saldo início{' '}
                    {format(new Date(saldoInicio.data), 'dd/MM/yy')}
                  </div>
                  <div>
                    {new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(saldoInicio.value)}
                  </div>
                </div>

                <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
                  <div>
                    Saldo fim {format(new Date(saldoFim.data), 'dd/MM/yy')}
                  </div>
                  <div>
                    {new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(saldoFim.value)}
                  </div>
                </div>

                <div className="field col-12 md:col-12"></div>
                <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
                  <div className="text-800">
                    Saldo hoje {format(new Date(saldoHoje.data), 'dd/MM/yy')}
                  </div>

                  <div
                    style={{ borderTop: '1px solid #666666' }}
                    className="text-800"
                  >
                    {new Intl.NumberFormat().format(saldoHoje.value)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default resumeWallet;
