import { useEffect, useRef, useState } from 'react';
import useToastContext from '../../../hooks/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Form } from '@unform/web';
import Progress from '../../../components/progress-bar';
import api from '../../../services/api';
import { Tag } from 'primereact/tag';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import messageRequestError from '../../../utils/messageRequestError';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { Message } from 'primereact/message';

const DropBillsReceivableByBankReturnFile: React.FC = () => {
  /** useStates */
  const [load, setLoad] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<number>(0);
  const [banks, setBanks] = useState<IOptionsDTO[]>([]);
  const [selectedBank, setSelectedBank] = useState<string>('');

  const [resMessage, setResMessage] = useState<string>('');
  const [totalTitlesFile, setTotalTitlesFile] = useState<number>(0);

  /** hooks */
  const toast = useToastContext();
  const fileRef = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();

  /** useEffects */
  useEffect(() => {
    loadSetups();
  }, []);

  /** functions */
  const onTemplateSelect = (e: any) => {
    let _totalSize = fileSize;
    let files = e.files;

    Object.keys(files).forEach(key => {
      _totalSize += files[key].size || 0;
    });

    setFileSize(_totalSize);
  };

  const onTemplateUpload = (e: any) => {
    setResMessage('');
    let _totalSize = 0;

    e.files.forEach((file: any) => {
      _totalSize += file.size || 0;
    });

    setFileSize(_totalSize);
    toast('info', 'Sucesso', 'Arquivo anexado!');
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setResMessage('');
    setFileSize(fileSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setResMessage('');
    setFileSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = fileSize / 10000;
    const formatedValue =
      fileRef && fileRef.current ? fileRef.current.formatSize(fileSize) : '0 B';

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <Progress isLoad={load} />
        </div>
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <i className="fa-solid fa-file"></i>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="fa-solid fa-file-code image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte seu arquivo .txt aqui.
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'fa-solid fa-file-code',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };
  const uploadOptions = {
    icon: 'fa-solid fa-upload',
    iconOnly: true,
    className:
      'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
  };
  const cancelOptions = {
    icon: 'fa-solid fa-remove',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const loadSetups = async () => {
    setLoad(true);
    await api
      .get('/v2/boleto/setup')
      .then(({ data }) => {
        const opts = data.setups.map((item: any) => {
          return {
            label: `${item.title}-${item.convenio.cedenteConta.conta_agencia} - ${item.convenio.cedenteConta.conta_numero}`,
            value: `${item.id}`,
          };
        });

        if (opts.length === 0) {
          toast(
            'error',
            'Erro',
            'Nenhuma configuração de boletos encontrada para sua empresa!',
          );
          return;
        }

        setBanks(opts);
        setSelectedBank(opts[0].value);
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleSubmit = async () => {
    setLoad(true);

    const form = new FormData();
    form.append('file', fileRef.current.files[0]);
    form.append('setup_id', selectedBank);

    await api
      .post('/bills-receivable/v2/drop/by/return-file', form)
      .then(({ data }) => {
        if (data) {
          setTotalTitlesFile(data.total_titles_file);

          let _resMessage = `<div><b>Boletos baixados agora:</b> ${
            data.titles_dropped_now.length
          } 
          ${data.titles_dropped_now.map((t: string, i: number) => {
            return `<br /> <b>${i + 1}:</b> ${t}`;
          })}
          </div>`;

          _resMessage += `<div><b>Boletos que já estavam baixados:</b> ${
            data.titles_already_dropped.length
          } 
          ${data.titles_already_dropped.map((t: string, i: number) => {
            return `<br /> <b>${i + 1}:</b> ${t}`;
          })} </div>`;

          _resMessage += `<div><b>Boletos não encontrados:</b> ${
            data.titles_not_founded.length
          } 
          ${data.titles_not_founded.map((t: string, i: number) => {
            return `<br /> <b>${i + 1}:</b> ${t}`;
          })}
          </div>`;

          setResMessage(_resMessage);
          // fileRef.current?.clear();
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  return (
    <>
      <Progress isLoad={load} />
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          placeholder={''}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <h4>Baixa de contas a receber com arquivo de retorno bancário</h4>
          <p>Como conseguir o arquivo de retorno?</p>
          <p>
            R.: Para conseguir o arquivo de retorno bancário, entre em contato
            com o suporte do seu banco ou faça o download através do seu app
            Internet Banking.
          </p>

          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="">Configurações do Boleto</label>
              <InputDropDown
                name="boleto_config_id"
                value={selectedBank}
                options={banks}
                placeholder="Selecionar..."
                onChange={e => setSelectedBank(e.value)}
              />
            </div>
          </div>

          <Tooltip
            target=".custom-choose-btn"
            content="Escolher"
            position="bottom"
          />
          <Tooltip
            target=".custom-upload-btn"
            content="Enviar"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Limpar"
            position="bottom"
          />

          {resMessage !== '' && (
            <div className="flex w-full justify-content-center">
              <Message
                severity="success"
                content={() => (
                  <>
                    <div className="w-full flex justify-content-center my-2">
                      <b>Total de boletos no arquivo:</b> {totalTitlesFile}
                    </div>
                    <div
                      className="w-full flex justify-content-between"
                      dangerouslySetInnerHTML={{ __html: resMessage }}
                    ></div>
                  </>
                )}
                className="w-full md:w-6 p-fluid grid formgrid m-2"
              />
            </div>
          )}

          <FileUpload
            ref={fileRef}
            name="file"
            multiple={false}
            accept=".txt"
            maxFileSize={1000000}
            onUpload={onTemplateUpload}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
          />

          <div className="p-fluid grid formgrid mt-2">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={load === false ? 'Enviar' : 'Processando'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DropBillsReceivableByBankReturnFile;
