import React, { useState, useRef, useEffect } from 'react';

/** react-rouer-dom */
import { useHistory } from 'react-router-dom';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../../services/api';

/** react-router-dom */
import { useParams } from 'react-router-dom';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputMask } from '../../../../components/Inputs/InputMask';
import InputDate from '../../../../components/Inputs/InputCalendar';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';

import { Toast } from 'primereact/toast';
import { endOfDay } from 'date-fns/esm';
import { FileUpload } from 'primereact/fileupload';

const LeadsEdit = (): React.ReactElement => {
  const navigate = useHistory();

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);
  const upload = useRef<any>(null);

  /** state */
  const [place, setPlace] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  const date = new Date();
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  const params: { id: string } = useParams();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** handle consultant */

  const handleLead = async (data: any) => {
    try {
      const schema = Yup.object({
        nome: Yup.string().required('Por favor, informe o nome'),
        email: Yup.string()
          .email('Por favor, informe email válido!')
          .required('Por favor, informe um email'),
        whatsapp: Yup.string()
          .required('Por favor, informe o whatsapp!')
          .matches(
            /\(\d{2,}\)\s?\d?\s?\d{4,}-?\d{4}/g,
            'Por favor, informe número de telefone válido',
          ),
        seguradora: Yup.string()
          .nullable(true)
          .min(8, 'Seguradora deve conter no mínimo 8 caracteres!')
          .transform((_, val) => (val !== '' ? val : null)),

        placa: Yup.string().required('Por favor, informe a placa!'),
      });
      await schema.validate(data, { abortEarly: false });

      let fileName = null;
      if (upload.current?.length) {
        const form = new FormData();
        form.set('apolice_url', upload.current.files[0]);
        const response = await api.patch('/mkt/lead/upload', form);
        fileName = response.data.file_url;
      }

      await api.put('/mkt/lead/update', {
        lead: {
          ...data,
          vigencia_start: initialDate,
          id: params.id,
          vigencia_end: finalDate,
          seguradora: data.seguradora !== '' ? data.seguradora : null,
          apolice_url: fileName,
          obs: data.obs !== '' ? data.obs : null,
        },
      });

      navigate.push('/mkt/lead/listar');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  const changePlace = (place: string) => {
    setPlace(place);
  };

  useEffect(() => {
    api.get(`/mkt/lead/${params.id}`).then(({ data }) => {
      formRef.current?.setData({
        nome: data?.listByIdLead.nome,
        email: data?.listByIdLead.email,
        seguradora: data?.listByIdLead.seguradora,
        obs: data.listByIdLead.obs,
      });
      setWhatsapp(data?.listByIdLead?.whatsapp);
      setPlace(data.listByIdLead.placa.toLocaleUpperCase());
      setInitialDate(new Date(data.listByIdLead?.vigencia_start));
      setFinalDate(new Date(data.listByIdLead?.vigencia_end));
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/mkt/lead/listar')}
      />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleLead}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Nome</label>
              <InputText name="nome" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">E-mail</label>
              <InputText name="email" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Whatsapp</label>
              <InputMask
                name="whatsapp"
                mask="(99) 99999-9999"
                placeholder="(00) 00000-0000"
                value={whatsapp}
                onChange={e => setWhatsapp(e.value)}
              />
            </div>
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Placa</label>
              <InputMask
                name="placa"
                placeholder="000-0000"
                mask="***-****"
                value={place}
                onChange={e => changePlace(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Seguradora</label>
              <InputText name="seguradora" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Vigência início</label>
              <InputDate
                name="vigencia_start"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Vigência fim</label>
              <InputDate
                name="vigencia_end"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Apolice</label>
              <FileUpload
                mode="basic"
                name="apolice"
                ref={upload}
                url="https://primefaces.org/primereact/showcase/upload.php"
                accept="*"
                maxFileSize={1000000}
                auto
                chooseLabel="Escolher arquivo"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Obs</label>
              <InputTextArea name="obs" />
            </div>
          </div>
          <Button type="submit" label="Salvar" className="w-full" />
        </Form>
      </div>
    </>
  );
};

export default LeadsEdit;
