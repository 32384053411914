import { useQuery } from 'react-query';
import api from '../services/api';
import IListBillReceivableById from '../pages/bills-to-receive/list/dtos/IListBillReceivableById';

export default function useLoadBillReceivable(id: string) {
  const url = `/bills-receivable/v2/${id}`;
  const { data, error, isLoading, isFetched } = useQuery<
    IListBillReceivableById[]
  >(
    ['bill-receivable', url],
    () => api.get(url).then(response => response.data),
    { staleTime: 6000, cacheTime: 6000 },
  );

  return { data, error, isLoading, isFetched };
}
