import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import InputDate from '../../../components/Inputs/InputCalendar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import openReports from '../../../utils/openReports';
import WalletHeader from '../../wallet/header';

const ReportWallet = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Date>(startOfMonth(hoje));
  const [dateEnd, setDateEnd] = useState<Date>(endOfMonth(hoje));

  /** functions */
  const handleGenerateReport = async (dateStart: Date, dateEnd: Date) => {
    setLoadingStatus(true);
    await api
      .post('/reports/wallet/resume', {
        filter: { date_start: dateStart, date_end: dateEnd },
      })
      .then(({ data }) => {
        openReports(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
          'resumo-carteira',
        );
        toast('success', 'Sucesso', 'Relatório gerado com sucesso!');
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          if (e.response?.data?.message === 'Validation failed') {
            toast('warn', 'Alerta', e.response?.data.validation.body.message);
          }
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  return (
    <>
      <WalletHeader />
      <Form
        ref={formRef}
        onSubmit={() => handleGenerateReport(dateStart, dateEnd)}
        className="card w-6"
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-6 md:col-6">
            <label htmlFor="date_start">Data início</label>
            <InputDate
              name="date_start"
              value={dateStart}
              dateFormat="dd/mm/yy"
              onChange={e => setDateStart(e.value as Date)}
              showIcon
              iconPos="right"
            />
          </div>
          <div className="field col-6 md:col-6">
            <label htmlFor="date_end">Data fim</label>
            <InputDate
              name="date_end"
              value={dateEnd}
              dateFormat="dd/mm/yy"
              onChange={e => setDateEnd(e.value as Date)}
              showIcon
              iconPos="right"
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ReportWallet;
