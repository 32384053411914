import React, { useEffect, useRef, useState } from 'react';

/** component */
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Divider } from '../../../components/Divider';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber as InNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText as InText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { TabViews } from '../../../components/TabPanel';
import { Option } from '../../../types/optional';

/** unform */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** service */
import api from '../../../services/api';

/** data */
import {
  emissionType,
  goal,
  impersonalType,
} from '../../../data/nature-operation';

/** utils */
import { Application } from '../../../types/application';
import getValidationErrors from '../../../utils/getErrorsValidation';
import convertToNumber from '../../../utils/number';

/* react-router-dom */
import { ProgressBar } from 'primereact/progressbar';
import { useHistory } from 'react-router';

type CfopsOption = {
  aplicacao: string;
  descricao: string;
  id: string;
  status: string;
  codigo: string;
};

const typeNature = [
  { label: 'Entrada', value: 'entrada' },
  { label: 'Saída', value: 'saida' },
  { label: 'Troca', value: 'troca' },
  { label: 'Devolução', value: 'devolucao' },
];

interface ApplicationModel extends Application {
  id: string;
}

const AddOperationNature = (): React.ReactElement => {
  /* operation nature */
  const [valueMin, setValueMin] = useState<number | null>(1.0);
  const [valueMax, setValueMax] = useState<number | null>(100000.0);
  const [generateIcms, setGenerateIcms] = useState(false);
  const [listSeparation, setListSeparation] = useState(false);
  const [generateFinancial, setGenerateFinancial] = useState(true);
  const [allowExhange, setAllowExchange] = useState(false);
  const [makeSales, setMakeSales] = useState(true);
  const [issueNfe, setIssueNfe] = useState(true);
  const [issueNfce, setIssueNfce] = useState(false);
  const [generateComission, setGenerateComission] = useState(true);
  const [generateStock, setGenerateStock] = useState(true);
  const [applicationOption, setApplicationOption] = useState<Option[]>([]);
  const [applicationSelected, setApplicationSelected] = useState();
  const [printedModelOption, setPrintedModelOption] = useState<Option[]>([]);
  const [selectedPrintedModel, setSelectedPrintedModel] = useState();
  const [cfopsOptions, setCfopsOption] = useState<Option[]>([]);
  const [selectedCfopIn, setSelectedCfopIn] = useState();
  const [selectedCfopOut, setSelectedCfopOut] = useState();
  const [optionsOriginTax, setOptionOriginTax] = useState<Option[]>([]);
  const [selectedOriginTax, setSelectedOriginTax] = useState();
  const [optionsCsosn, setOptionsCosn] = useState<Option[]>([]);
  const [selectedCsosn, setSelectedCsosn] = useState();

  const [selectedGoal, setSelectedGoal] = useState<number | Option>(1);
  const [selectedPrinted, setSelectedPrinted] = useState<number | Option>(1);
  const [selectedEmissionType, setEmissionType] = useState<number | Option>(1);

  const [saidMovement, setSaidMovement] = useState<boolean>(true);
  const [faceToFaceSale, setFaceToFaceSale] = useState(true);
  const [finalCustomer, setFinalCustomer] = useState(true);
  const [icmsOptions, setIcmsOptions] = useState<Option[]>([]);
  const [selectedIcmsOptions, setSelectedIcmsOptions] = useState();
  const [ipiOption, setIpiOptions] = useState<Option[]>([]);
  const [selectedIpiOptions, setSelectedIpiOptions] = useState();
  const [pisOption, setPisOption] = useState<Option[]>([]);
  const [selectedPisOption, setSelectedPisOption] = useState();
  const [cofinsOption, setCofinsOption] = useState<Option[]>([]);
  const [selectedCofinsOption, setSelectedCofinsOption] = useState<Option[]>(
    [],
  );
  const [status, setStatus] = useState('A');
  const [selectedTypeNatureOperation, setSelectedTypeNatureOperation] =
    useState('saida');
  const [title, setTitle] = useState('');
  const [, setCostCenterOption] = useState();
  const [, setDescription] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(false);

  const navigate = useHistory();

  /** ref */
  const formRefNature = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** operation nature */

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRefNature.current?.setErrors({});
      const schemaAddNature = Yup.object().shape({
        title: Yup.string()
          .required('Titulo da Natureza da Operação é obrigatório')
          .min(
            5,
            'Titulo da Natureza da Operação, deve ter no mínimo 5 caracteres',
          )
          .max(
            60,
            'Titulo da Natureza da Operação, deve ter no máximo 60 caracteres',
          ),
        description: Yup.string().required(
          'Informar a descrição da natureza de operação!',
        ),
        info_adicionais: Yup.string(),
        val_min: Yup.string().required(
          'Valor Mínimo da Natureza da Operação é obrigatório',
        ),
        val_max: Yup.string().required(
          'Valor Máximo da Natureza da Operação é obrigatório',
        ),
        compoe_vendas: Yup.string().required(),
        gera_comissao: Yup.string().required(),
        kind_nat_ope: Yup.string().required(
          'Por favor, informe o tipo de natureza operação',
        ),
        gera_estoque: Yup.string().required(),
        emite_nfce: Yup.string().required(),
        emite_nfe: Yup.string().required(),
        gera_icms: Yup.string().required(),
        lista_separacao: Yup.string().required(),
        gera_financeiro: Yup.string().required(),
        permite_troca: Yup.string().required(),
        application_id: Yup.string().required(
          'Aplicação da Natureza da Operação é obrigatório',
        ),
        impresso_id: Yup.string().required(
          'Impressão da Natureza da Operação é obrigatório',
        ),
        cfop_in: Yup.string().required(
          'CFOP de Entrada da Natureza da Operação é obrigatório',
        ),
        cfop_out: Yup.string().required(
          'CFOP de Saida da Natureza da Operação é obrigatório',
        ),
        origem_id: Yup.string().required(
          'Origem da Natureza da Operação é obrigatório',
        ),
        csosn_id: Yup.string().required(
          'CSOSN da Natureza da Operação é obrigatório',
        ),
      });
      await schemaAddNature.validate(data, { abortEarly: false });

      const objectNatureOperation = {
        ...data,
        val_min: parseFloat(convertToNumber(data.val_min)),
        val_max: parseFloat(convertToNumber(data.val_max)),
        regras: {
          consumidor_final: data.regras.consumidor_final,
          finalidade: parseInt(data.regras.finalidade),
          presencial: data.regras.presencial,
          saida: data.regras.saida,
          tipo_emissao: parseInt(data.regras.tipo_emissao),
          tipo_impressao: parseInt(data.regras.tipo_impressao),
          ...(data.regras.icms?.cst_id
            ? {
              icms: {
                ...data.regras.icms,
                aliquota: parseFloat(
                  convertToNumber(data.regras.icms?.aliquota || '0'),
                ),
                base_calculo: parseFloat(
                  convertToNumber(data.regras.icms?.base_calculo || '0'),
                ),
                valor: parseFloat(
                  convertToNumber(data.regras.icms?.valor || '0'),
                ),
              },
            }
            : { icms: null }),
          ...(data.regras.pis?.cst_id
            ? {
              pis: {
                ...data.regras.pis,
                aliquota: parseFloat(
                  convertToNumber(data.regras.pis?.aliquota || '0'),
                ),
                base_calculo: parseFloat(
                  convertToNumber(data.regras.pis?.base_calculo || '0'),
                ),
                valor: parseFloat(
                  convertToNumber(data.regras.pis?.valor || '0'),
                ),
              },
            }
            : { pis: null }),
          ...(data.regras.cofins?.cst_id
            ? {
              cofins: {
                ...data.regras.pis,
                aliquota: parseFloat(
                  convertToNumber(data.regras.cofins?.aliquota || '0'),
                ),
                base_calculo: parseFloat(
                  convertToNumber(data.regras.cofins?.base_calculo || '0'),
                ),
                valor: parseFloat(
                  convertToNumber(data.regras.cofins?.valor || '0'),
                ),
              },
            }
            : { cofins: null }),
          ...(data.regras.ipi?.cst_id
            ? {
              ipi: {
                ...data.regras.ipi,
                aliquota: parseFloat(
                  convertToNumber(data.regras.ipi?.aliquota || '0'),
                ),
                base_calculo: parseFloat(
                  convertToNumber(data.regras.ipi?.base_calculo || '0'),
                ),
                valor: parseFloat(
                  convertToNumber(data.regras.ipi?.valor || '0'),
                ),
              },
            }
            : { ipi: null }),
        },
      };
      await api.post('/naturezas-operacao', {
        data: objectNatureOperation,
      });
      showToast('success', 'Sucesso!', 'Natureza de operação criada');
      navigate.push('/operation-nature/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefNature.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/applications')
      .then(({ data }) => {
        const opts = data.map((item: ApplicationModel) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setApplicationOption(opts);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/impressos-nfe')
      .then(({ data }) => {
        const optionsPrintedModel = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setPrintedModelOption(optionsPrintedModel);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/cfops')
      .then(({ data }) => {
        const cfopsOption = data.map((item: CfopsOption) => {
          return {
            label: `${item.codigo} - ${item.descricao}`.substring(0, 60),
            value: item.id,
          };
        });
        setCfopsOption(cfopsOption);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/tributos-origem')
      .then(({ data }) => {
        const originTaxesOptions = data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`.substring(0, 60),
            value: item.id,
          };
        });
        setOptionOriginTax(originTaxesOptions);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/tributos-csosn')
      .then(({ data }) => {
        const csosnOptions = data.map((item: any) => {
          return {
            label: `${item.codigo} - ${item.descricao}`.substring(0, 60),
            value: item.id,
          };
        });
        setOptionsCosn(csosnOptions);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/trib-cst/icms')
      .then(({ data }) => {
        const tribIcms = data.map((item: any) => {
          return {
            label: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          };
        });
        setIcmsOptions(tribIcms);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/trib-cst/ipi')
      .then(({ data }) => {
        const ipiOptions = data.map((item: CfopsOption) => {
          return {
            label: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          };
        });
        setIpiOptions(ipiOptions);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/trib-cst/pis')
      .then(({ data }) => {
        const pisOptios = data.map((item: CfopsOption) => {
          return {
            label: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          };
        });
        setPisOption(pisOptios);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('/trib-cst/cofins')
      .then(({ data }) => {
        const cofinsOptions = data.map((item: CfopsOption) => {
          return {
            label: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          };
        });
        setCofinsOption(cofinsOptions);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get('cost-center')
      .then(({ data }) => {
        const optionsCostCenter = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setCostCenterOption(optionsCostCenter);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/operation-nature/list')}
      />
      <Toast ref={toast} />

      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}

      <Form ref={formRefNature} onSubmit={handleSubmit}>
        <div className="card">
          <TabViews renderActiveOnly={false}>
            <TabPanel header="Dados">
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-6">
                  <label htmlFor="dropdown">Título</label>
                  <InText
                    name="title"
                    value={title}
                    placeholder="Seu título"
                    onChange={e => setTitle(e.currentTarget.value)}
                  />
                </div>
                <div className="field col-12 md:col-6 flex align-items-end justify-content-center">
                  <label htmlFor="dropdown" className="m-0 text-lg">
                    Ativo
                  </label>
                  <InputSwitch
                    name="status"
                    className="ml-4"
                    checked={status}
                    onChange={e => setStatus(`${e.value}`)}
                    falseValue="C"
                    trueValue="A"
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col">
                  <label htmlFor="dropdown">Descrição</label>
                  <InputTextArea
                    name="description"
                    style={{ height: 100 }}
                    onChange={e => setDescription(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-4">
                  <label htmlFor="dropdown">Valor Mínimo</label>
                  <InNumber
                    inputId="minmaxfraction"
                    name="val_min"
                    placeholder="R$ 0,00"
                    value={valueMin}
                    onValueChange={e => setValueMin(e.value)}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={6}
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="dropdown">Valor Máximo</label>
                  <InNumber
                    inputId="minmaxfraction"
                    name="val_max"
                    placeholder="R$ 0,00"
                    value={valueMax}
                    onValueChange={e => setValueMax(e.value)}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={5}
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="dropdown">Tipo de natureza operação</label>
                  <InputDropDown
                    name="kind_nat_ope"
                    options={typeNature}
                    placeholder="Selecionar..."
                    value={selectedTypeNatureOperation}
                    onChange={e => setSelectedTypeNatureOperation(e.value)}
                  />
                </div>
              </div>
              <Divider align="left" type="dashed">
                <span className="p-tag p-2">Funções</span>
              </Divider>
              <div className="p-fluid grid formgrid mb-3 mt-6">
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    className="mr-4"
                    name="gera_icms"
                    checked={generateIcms}
                    onChange={e => setGenerateIcms(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Gerar ICMS?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="lista_separacao"
                    className="mr-4"
                    checked={listSeparation}
                    onChange={e => setListSeparation(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Lista de Separação?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="gera_financeiro"
                    className="mr-4"
                    checked={generateFinancial}
                    onChange={e => setGenerateFinancial(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Gerar Financeiro?</label>
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3 mt-3">
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="permite_troca"
                    className="mr-4"
                    checked={allowExhange}
                    onChange={e => setAllowExchange(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Permite Troca?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="compoe_vendas"
                    className="mr-4"
                    checked={makeSales}
                    onChange={e => setMakeSales(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Compõem Vendas?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="emite_nfe"
                    className="mr-4"
                    checked={issueNfe}
                    onChange={e => setIssueNfe(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Emite NFE?</label>
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3 mt-3">
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="emite_nfce"
                    className="mr-4"
                    checked={issueNfce}
                    onChange={e => setIssueNfce(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Emite NFCE?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="gera_comissao"
                    className="mr-4"
                    checked={generateComission}
                    onChange={e => setGenerateComission(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Gera Comissão?</label>
                </div>
                <div className="field col-12 md:col-4 flex">
                  <InputSwitch
                    name="gera_estoque"
                    className="mr-4"
                    checked={generateStock}
                    onChange={e => setGenerateStock(e.value)}
                    falseValue={false}
                    trueValue={true}
                  />
                  <label htmlFor="dropdown">Gera Estoque?</label>
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-6">
                  <label htmlFor="dropdown">Aplicação</label>
                  <InputDropDown
                    name="application_id"
                    options={applicationOption}
                    value={applicationSelected}
                    onChange={e => setApplicationSelected(e.value)}
                    placeholder="Selecionar..."
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                  />
                </div>
                <div className="field col-12 md:col-6 ">
                  <label htmlFor="dropdown">Modelo de Impressão</label>
                  <InputDropDown
                    name="impresso_id"
                    options={printedModelOption}
                    value={selectedPrintedModel}
                    onChange={e => setSelectedPrintedModel(e.value)}
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                    placeholder="Selecionar..."
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Tributação">
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-6">
                  <label htmlFor="dropdown">CFOP Interno</label>
                  <InputDropDown
                    name="cfop_in"
                    options={cfopsOptions}
                    value={selectedCfopIn}
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                    onChange={e => setSelectedCfopIn(e.value)}
                    placeholder="Selecionar..."
                    optionValue="value"
                  />
                </div>
                <div className="field col-12 md:col-6 ">
                  <label htmlFor="dropdown">CFOP Externo</label>
                  <InputDropDown
                    name="cfop_out"
                    options={cfopsOptions}
                    value={selectedCfopOut}
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                    placeholder="Selecionar..."
                    onChange={e => setSelectedCfopOut(e.value)}
                  />
                </div>
              </div>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-6">
                  <label htmlFor="dropdown">Origem Mercadoria</label>
                  <InputDropDown
                    name="origem_id"
                    options={optionsOriginTax}
                    value={selectedOriginTax}
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                    placeholder="Selecionar..."
                    onChange={e => setSelectedOriginTax(e.value)}
                  />
                </div>
                <div className="field col-12 md:col-6 ">
                  <label htmlFor="dropdown">
                    CSOSN - Código Situação Do Simples Nacional
                  </label>
                  <InputDropDown
                    name="csosn_id"
                    options={optionsCsosn}
                    emptyFilterMessage="Nenhum item encontrado!"
                    filter
                    showClear
                    value={selectedCsosn}
                    placeholder="Selecionar..."
                    onChange={e => setSelectedCsosn(e.value)}
                  />
                </div>
              </div>

              <Divider align="left" type="dashed">
                <span className="p-tag p-2">Regras</span>
              </Divider>
              <Scope path="regras">
                <div className="p-fluid grid formgrid mb-3">
                  <div className="field col-12 md:col-4">
                    <label htmlFor="dropdown">Finalidade</label>
                    <InputDropDown
                      name="finalidade"
                      options={goal}
                      value={selectedGoal}
                      emptyFilterMessage="Nenhum item encontrado!"
                      filter
                      showClear
                      placeholder="Selecionar..."
                      onChange={e => setSelectedGoal(e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <label htmlFor="dropdown">Tipo de Impressão</label>
                    <InputDropDown
                      name="tipo_impressao"
                      options={impersonalType}
                      value={selectedPrinted}
                      placeholder="Selecionar"
                      emptyFilterMessage="Nenhum item encontrado!"
                      filter
                      showClear
                      onChange={e => setSelectedPrinted(e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <label htmlFor="dropdown">Tipo de Emissão</label>
                    <InputDropDown
                      name="tipo_emissao"
                      options={emissionType}
                      value={selectedEmissionType}
                      placeholder="Selecionar"
                      emptyFilterMessage="Nenhum item encontrado!"
                      filter
                      showClear
                      onChange={e => setEmissionType(e.value)}
                    />
                  </div>
                </div>
                <div className="p-fluid grid formgrid mb-3 mt-3">
                  <div className="field col-12 md:col-4 flex">
                    <InputSwitch
                      name="saida"
                      className="mr-4"
                      checked={saidMovement}
                      onChange={e => setSaidMovement(e.value)}
                      trueValue={true}
                      falseValue={false}
                    />
                    <label htmlFor="dropdown">Movimentação de saida?</label>
                  </div>

                  <div className="field col-12 md:col-4 flex">
                    <InputSwitch
                      name="presencial"
                      className="mr-4"
                      checked={faceToFaceSale}
                      onChange={e => setFaceToFaceSale(e.value)}
                      trueValue={true}
                      falseValue={false}
                    />
                    <label htmlFor="dropdown">Venda presencial?</label>
                  </div>
                  <div className="field col-12 md:col-4 flex">
                    <InputSwitch
                      name="consumidor_final"
                      className="mr-4"
                      checked={finalCustomer}
                      onChange={e => setFinalCustomer(e.value)}
                      trueValue={true}
                      falseValue={false}
                    />
                    <label htmlFor="dropdown">Consumidor Final?</label>
                  </div>
                </div>
                <Divider align="left" type="dashed">
                  <span className="p-tag p-2">Tributos</span>
                </Divider>
                <TabView renderActiveOnly={false}>
                  <TabPanel header="ICMS">
                    <Scope path="icms">
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="dropdown">
                            CST (Código da situação tributária)
                          </label>
                          <InputDropDown
                            name="cst_id"
                            options={icmsOptions}
                            value={selectedIcmsOptions}
                            emptyFilterMessage="Nenhum item encontrado!"
                            filter
                            showClear
                            placeholder="Selecionar..."
                            onChange={e => setSelectedIcmsOptions(e.value)}
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Aliquota (%)</label>
                          <InNumber
                            name="aliquota"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Base de Cálculo</label>
                          <InNumber
                            name="base_calculo"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Valor (Fixo)</label>
                          <InNumber
                            name="valor"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                      </div>
                    </Scope>
                  </TabPanel>
                  <TabPanel header="IPI">
                    <Scope path="ipi">
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="dropdown">
                            CST (Código da situação tributária)
                          </label>
                          <InputDropDown
                            name="cst_id"
                            options={ipiOption}
                            value={selectedIpiOptions}
                            emptyFilterMessage="Nenhum item encontrado!"
                            filter
                            showClear
                            placeholder="Selecionar..."
                            onChange={e => setSelectedIpiOptions(e.value)}
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Aliquota (%)</label>
                          <InNumber
                            name="aliquota"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Base de Cálculo</label>
                          <InNumber
                            name="base_calculo"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Valor (Fixo)</label>
                          <InNumber
                            name="valor"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            placeholder="0,00"
                          />
                        </div>
                      </div>
                    </Scope>
                  </TabPanel>
                  <TabPanel header="PIS">
                    <Scope path="pis">
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="dropdown">
                            CST (Código da situação tributária)
                          </label>
                          <InputDropDown
                            name="cst_id"
                            options={pisOption}
                            emptyFilterMessage="Nenhum item encontrado!"
                            filter
                            showClear
                            value={selectedPisOption}
                            onChange={e => setSelectedPisOption(e.value)}
                            placeholder="Selecionar..."
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Aliquota (%)</label>
                          <InNumber
                            name="aliquota"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Base de Cálculo</label>
                          <InNumber
                            name="base_calculo"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Valor (Fixo)</label>
                          <InNumber
                            name="valor"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                      </div>
                    </Scope>
                  </TabPanel>
                  <TabPanel header="COFINS">
                    <Scope path="cofins">
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="dropdown">
                            CST (Código da situação tributária)
                          </label>
                          <InputDropDown
                            name="cst_id"
                            options={cofinsOption}
                            value={selectedCofinsOption}
                            emptyFilterMessage="Nenhum item encontrado!"
                            filter
                            showClear
                            onChange={e => setSelectedCofinsOption(e.value)}
                            placeholder="Selecionar..."
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Aliquota (%)</label>
                          <InNumber
                            name="aliquota"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Base de Cálculo</label>
                          <InNumber
                            name="base_calculo"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field col-12 md:col-4">
                          <label htmlFor="dropdown">Valor (Fixo)</label>
                          <InNumber
                            name="valor"
                            placeholder="0,00"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                      </div>
                    </Scope>
                  </TabPanel>
                </TabView>
              </Scope>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col">
                  <label htmlFor="dropdown">Informações Adicionais</label>
                  <InputTextArea name="info_adicionais" />
                </div>
              </div>
            </TabPanel>
          </TabViews>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-6 ">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddOperationNature;
