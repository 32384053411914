import { useEffect, useRef, useState } from 'react';
import { SideBar } from '../../../../components/Sidebar';
import { endOfDay, startOfDay } from 'date-fns';
import useToastContext from '../../../../hooks/toast';
import { FormHandles } from '@unform/core';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import { Form } from '@unform/web';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { Button } from 'primereact/button';
import { Divider } from '../../../../components/Divider';
import { DataTable } from '../../../../components/DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { AxiosError } from 'axios';
import Progress from '../../../../components/progress-bar';
import { v4 } from 'uuid';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const SidebarDownloadRemittancePack = ({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement => {
  /** refs & focus */
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);

  /** states */
  const today = new Date();
  const [load, setLoad] = useState<boolean>(false);

  interface IFiles {
    id: string;
    file: string;
  }
  const [zipFiles, setZipFiles] = useState<IFiles[]>([]);

  const [user, setUser] = useState<any>();

  const [period, setPeriod] = useState<Date[]>([
    startOfDay(today),
    endOfDay(today),
  ]);

  useEffect(() => {
    loadUserData();
  }, []);

  /** effects */
  useEffect(() => {
    if (isOpen) {
      loadFiles();
    }
  }, [isOpen]);

  /** functions */

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const loadFiles = async () => {
    setLoad(true);
    await api
      .get('/v2/boleto/remessa/pack/lote/list')
      .then(({ data }) => {
        if (data) {
          const opts = data.map((file: string) => {
            return {
              id: v4(),
              file,
            };
          });
          setZipFiles(opts);
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  const handleDownloadFile = async (file: string) => {
    try {
      setLoad(true);

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/${user.contract_id}/rem/${file}.zip`,
      );
      await loadFiles();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        toast('error', 'Erro', e.response?.data.error);
      } else {
        toast(
          'warn',
          'Alerta',
          'Falha ao empacotar arquivos de remessa da empresa! Repita o processo ou chame o suporte!',
        );
      }
    } finally {
      setLoad(false);
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    await api
      .post('/v2/boleto/remessa/pack/lote/create', {
        data: {
          period,
        },
      })
      .then(({ data }) => {
        if (data) {
          loadFiles();
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  /** templates */

  /** renders */
  const renderCollumnMenu = (file: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-download"
          className="p-button-info mr-2"
          onClick={() => handleDownloadFile(file)}
          type="button"
        />
      </div>
    );
  };

  return (
    <SideBar
      visible={isOpen}
      onHide={onRequestClose}
      position="right"
      className="w-10 md:w-4"
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
        className="p-fluid grid formgrid"
      >
        <h3 className="field col-12">Baixar lotes de remessas</h3>

        <div className="field col-8">
          <label htmlFor="period">Período</label>
          <CalendarRange
            name="period"
            value={period}
            onChange={e => {
              setPeriod(e.value as Date[]);
            }}
            placeholder="00/00/00 ~ 00/00/00"
          />
        </div>
        <div className="field col-4 flex align-items-end">
          <Button
            name="btn_submit"
            label="Gerar"
            className="p-button-success"
            type="submit"
            icon="fa-solid fa-upload"
            loading={load}
          />
        </div>

        <Progress isLoad={load} className="field col-12 mx-3" />

        {zipFiles.length > 0 && (
          <>
            <Divider />

            <DataTable
              value={zipFiles}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              loading={load}
              size="small"
              selectionMode="single"
              responsiveLayout="scroll"
              emptyMessage="Arquivos não encontrados..."
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} arquivos"
              className="field col-12"
              footerColumnGroup={null}
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column
                header="Arquivo (CNPJ - Data inicial - Data final)"
                sortable
                sortField="file"
                field="file"
                align={'left'}
              ></Column>
              <Column
                header="*"
                align={'center'}
                body={r => renderCollumnMenu(r.file)}
              ></Column>
            </DataTable>
          </>
        )}
      </Form>
    </SideBar>
  );
};

export default SidebarDownloadRemittancePack;
