import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import Calendar from '../../../components/Inputs/InputCalendar';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const FastFormDownloadNfeXml = ({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  interface IFiles {
    id: string;
    file: string;
  }
  const [zipFiles, setZipFiles] = useState<Array<IFiles>>([]);
  const [zipFile, setZipFile] = useState<string>('');

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  /** functions */
  const listFiles = async () => {
    setLoadingStatus(true);
    await api
      .get('/nfe/xml/pack/list')
      .then(({ data }) => {
        const opts = data.list.map((i: any) => {
          return {
            id: v4(),
            file: `${i}.zip`,
          };
        });
        setZipFiles(opts);
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', 'Falha ao listar os arquivos xml gerados.');
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  const handleAddFile = async (d: any) => {
    setLoadingStatus(true);
    await api
      .post('/nfe/xml/pack/create', {
        date_start: dateStart,
        date_end: dateEnd,
      })
      .then(data => {
        if (data.status >= 200 || data.status <= 299) {
          toast('success', 'Sucesso', 'Arquivos xml gerados com sucesso!');
        }
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          toast('error', 'Erro', e.response?.data.error);
        } else {
          toast(
            'warn',
            'Alerta',
            'Falha ao empacotar arquivos xml da empresa! Repita o processo ou chame o suporte!',
          );
        }
      })
      .finally(() => setLoadingStatus(false));
    await listFiles();
  };

  const handleRemFile = async (id: string) => {
    await listFiles();
  };

  const handleDownloadFile = async (file: string) => {
    try {
      setLoadingStatus(true);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/xml/${file.slice(
          8,
          file.length,
        )}`,
      );
      await listFiles();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        toast('error', 'Erro', e.response?.data.error);
      } else {
        toast(
          'warn',
          'Alerta',
          'Falha ao empacotar arquivos xml da empresa! Repita o processo ou chame o suporte!',
        );
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** useEffect */
  useEffect(() => {
    listFiles();
  }, []);

  /** renders */
  const renderCollumnMenu = (file: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-download"
          className="p-button-info mr-2"
          onClick={() => handleDownloadFile(file)}
          type="button"
        />
      </div>
    );
  };

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => onRequestClose()}
      style={{ width: '40vw' }}
    >
      <Form
        ref={formRef}
        onSubmit={d => handleAddFile(d)}
        className="p-fluid grid formgrid"
      >
        <h3 className="field col-12">Baixar lotes de arquivos xml</h3>
        <div className="col-4">
          <label htmlFor="date_start">Data inicial</label>
          <Calendar
            name="date_start"
            value={dateStart}
            onChange={e => setDateStart(e.value as Date)}
            dateFormat="dd/mm/yy"
            placeholder="00/00/00"
            showIcon
          />
        </div>
        <div className="col-4">
          <label htmlFor="date_end">Data final</label>
          <Calendar
            name="date_end"
            value={dateEnd}
            onChange={e => setDateEnd(e.value as Date)}
            dateFormat="dd/mm/yy"
            placeholder="00/00/00"
            showIcon
          />
        </div>
        <div className="col-4 flex align-items-end">
          <Button
            label="Gerar"
            type="submit"
            icon="fa-solid fa-upload"
            className="p-button-success"
          />
        </div>
        {zipFiles.length > 0 && (
          <>
            <Divider />
            <DataTable
              value={zipFiles}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Arquivos não encontrados..."
              selectionMode="single"
              loading={loadingStatus}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} pagamentos"
              className="field col-12"
              footerColumnGroup={null}
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column
                header="Arquivo (cnpj ~ data inicial ~ data final"
                field="file"
                align={'left'}
                sortField="file"
                sortable
              ></Column>
              <Column
                header="*"
                align={'center'}
                body={e => renderCollumnMenu(e.file)}
              ></Column>
            </DataTable>
          </>
        )}
      </Form>
    </SideBar>
  );
};

export default FastFormDownloadNfeXml;
