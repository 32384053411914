import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { SideBar as SideBarSearchOsKindObject } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IOsObjectDTO from './dtos/IOsObjectDTO';

const osObject: React.FC = () => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** hooks */
  const router = useHistory();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [objetoOpts, setObjetoOpts] = useState<IOsObjectDTO[]>([]);
  const [tipoObjetoOpts, setTipoObjetoOpts] = useState<IOptionsDTO[]>([]);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [tipoObjeto, setTipoObjeto] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [modelo, setModelo] = useState<string>('');
  const [marca, setMarca] = useState<string>('');
  const [identificador, setIdentificador] = useState<string>('');
  const [anoFabricacao, setAnoFabricacao] = useState<string>('');
  const [anoModelo, setAnoModelo] = useState<string>('');
  const [obs, setObs] = useState<string>('');

  const [modalSearchOsObjectVisible, setModalSearchOsObjectVisible] =
    useState<boolean>(false);
  const [objectKeyword, setObjectKeyword] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setId('');
    setTipoObjeto('');
    setTitulo('');
    setModelo('');
    setMarca('');
    setIdentificador('');
    setAnoFabricacao('');
    setAnoModelo('');
    setObs('');
    setObjectKeyword('');

    setIsEdit(false);
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        title: Yup.string().required(
          'Informe a descrição do objeto/equipamento!',
        ),
        kind_object: Yup.string().uuid().required('Informe o tipo de objeto!'),
        model: Yup.string().required('Informe o modelo!'),
        brand: Yup.string().required('Informe a marca!'),
      });
      await validation.validate(data, { abortEarly: false });

      if (isEdit) {
        const res = await api.put('/os/object', {
          osObject: {
            id,
            kind_object_id: tipoObjeto,
            title: titulo,
            model: modelo,
            brand: marca,
            identificator: identificador,
            made_year: anoFabricacao,
            model_year: anoModelo,
            obs,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Item editado com sucesso!');
        }
      } else {
        const res = await api.post('/os/object', {
          osObject: {
            kind_object_id: tipoObjeto,
            title: titulo,
            model: modelo,
            brand: marca,
            identificator: identificador,
            made_year: anoFabricacao,
            model_year: anoModelo,
            obs,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Item salvo com sucesso!');
        }
      }

      clearForm();
      listObjects();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchObject = async (keyword: string) => {
    if (keyword.length >= 3) {
      await api
        .get('/')
        .then(({ data }) => {
          const opts = data.map((i: any) => {
            return {
              id: i.id,
              kind_object_id: i.kind_object_id,
              title: i.title,
              model: i.model,
              brand: i.brand,
              identificator: i.identificator,
              made_year: i.made_year,
              model_year: i.model_year,
              obs: i.obs,
            };
          });

          setObjetoOpts(opts);
        })
        .catch((e: any) => {
          if (e instanceof Yup.ValidationError) {
            const errors = getValidationErrors(e);
            formRef.current?.setErrors(errors);
            toast('error', 'Error', e.errors[0]);
          } else {
            toast('error', 'Error', e.response?.data?.error);
          }
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleSelectObject = async (id: any) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao selecionar o item! Tente novamente!');
      return;
    }

    const dt = objetoOpts.find(i => i.id === id);

    if (!dt) {
      toast('warn', 'Alerta', 'Objeto não encontrado!');
      return;
    }

    setIsEdit(true);

    setId(dt.id);
    setTipoObjeto(dt.kind_object_id);
    setTitulo(dt.title);
    setModelo(dt.model);
    setMarca(dt.brand);
    setIdentificador(dt.identificator);
    setAnoFabricacao(dt.made_year);
    setAnoModelo(dt.model_year);
    setObs(dt.obs);

    setModalSearchOsObjectVisible(false);
  };

  const listObjects = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/object/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsObject.map((i: any) => {
          return {
            id: i.id,
            kind_object_id: i.kind_object_id,
            title: i.title,
            model: i.model,
            brand: i.brand,
            identificator: i.identificator,
            made_year: i.made_year,
            model_year: i.model_year,
            obs: i.obs,
          };
        });

        setObjetoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleRemObject = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao exlcuir, tente novamente!');
      return;
    }

    try {
      setLoadingStatus(true);

      const res = await api.delete(`/os/object/${id}`);
      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item excluído com sucesso!');
        listObjects();
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** render */
  const renderRemButton = (id: string) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemObject(id)}
      />
    );
  };

  /** useEffect */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/os/kind-object/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsKindObject.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setTipoObjetoOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response?.data?.error);
      })
      .finally(() => setLoadingStatus(false));

    listObjects();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => clearForm()}
      />
      <Button
        label="Buscar"
        className="mb-3 mx-2"
        icon="fa-solid fa-search"
        onClick={() => setModalSearchOsObjectVisible(true)}
      />
      <Button
        label="O.S"
        className="mb-3 mx-2"
        icon="fa-solid fa-wrench"
        onClick={() => router.push('/os/new')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card">
        <Form ref={formRef} onSubmit={d => handleSubmit(d)}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12">
              <label htmlFor="title">Objeto/Equipamento</label>
              <InputText
                name="title"
                placeholder="Ex.: Telefone"
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-4 mx-0">
              <label htmlFor="kind_object">Tipo de objeto/equipamento</label>
              <InputDropDown
                name="kind_object"
                options={tipoObjetoOpts}
                value={tipoObjeto}
                onChange={e => setTipoObjeto(e.value)}
                placeholder="Selecionar..."
                tooltip={'O tipo de objeto: Telefone, Televisor...'}
                tooltipOptions={{ position: 'bottom' }}
                filter
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="model">Modelo</label>
              <InputText
                name="model"
                placeholder="Ex.: XYZ998A"
                value={modelo}
                onChange={e => setModelo(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="brand">Marca</label>
              <InputText
                name="brand"
                placeholder="Ex.: Apple"
                value={marca}
                onChange={e => setMarca(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="identificator">Identificador</label>
              <InputText
                name="identificator"
                placeholder="Ex.: 99883332022"
                value={identificador}
                onChange={e => setIdentificador(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="made_year">Ano fabricacao</label>
              <InputText
                name="made_year"
                placeholder="Ex.: 2023"
                value={anoFabricacao}
                keyfilter="int"
                maxLength={4}
                onChange={e => setAnoFabricacao(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="model_year">Ano modelo</label>
              <InputText
                name="model_year"
                placeholder="Ex.: 2023"
                keyfilter="int"
                maxLength={4}
                value={anoModelo}
                onChange={e => setAnoModelo(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-12">
              <InputTextEdit
                name="obs"
                style={{ height: '30vh' }}
                value={obs}
                onTextChange={e => setObs(e.htmlValue || '')}
                placeholder="Ex.: Este produto tem uma ótima saída!"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={isEdit ? 'Editar' : 'Salvar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchOsKindObject
        visible={modalSearchOsObjectVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchOsObjectVisible(false)}
      >
        <h3>Buscar objeto</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="keyword"
                placeholder="Ex.: Telefone..."
                type={'text'}
                value={objectKeyword}
                onChange={e => setObjectKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={objectKeyword.length < 3 ? true : false}
                onClick={() => searchObject(objectKeyword)}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={objetoOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectObject(e.value.id)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="title" header="Título"></Column>
                <Column field="model" header="Model"></Column>
                <Column field="brand" header="Marca"></Column>
                <Column header="*" body={e => renderRemButton(e.id)}></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchOsKindObject>
    </>
  );
};

export default osObject;
