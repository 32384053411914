import React from 'react';

const RenderColumnStatusPayment = (
  status: string,
  statusPayment: string,
): React.ReactElement => {
  if (status !== 'C') {
    return (
      <>
        {statusPayment === 'A' && (
          <span className={`product-badge status-lowstock`}>Aguardando</span>
        )}

        {statusPayment === 'P' && (
          <span className={`product-badge status-instock`}>Pago</span>
        )}

        {statusPayment === 'V' && (
          <span className={`product-badge status-outofstock`}>Vencido</span>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default RenderColumnStatusPayment;
