import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { SideBar as SideBarSearchOsService } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IOsServiceDTO from './dtos/IOsServiceDTO';

const osService: React.FC = () => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** hooks */
  const router = useHistory();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [numServico, setNumServico] = useState<number>(0);
  const [titulo, setTitulo] = useState<string>('');
  const [unidServico, setUnidServico] = useState<string>('');
  const [unidServicoOpts, setUnidServicoOpts] = useState<IOptionsDTO[]>([]);
  const [preco, setPreco] = useState<number>(0);
  const [taxIssqn, setTaxIssqn] = useState<number>(0);
  const [taxCidadeCod, setTaxCidadeCod] = useState<string>('');
  const [codTributacao, setCodTributacao] = useState<string>('');
  const [codServico, setCodServico] = useState<string>('');
  const [comissao, setComissao] = useState<number>(0);
  const [obs, setObs] = useState<string>('');

  const [servicoOpts, setServicoOpts] = useState<IOsServiceDTO[]>([]);

  const [modalSearchOsServiceVisible, setModalSearchOsServiceVisible] =
    useState<boolean>(false);
  const [serviceKeyword, setServiceKeyword] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setId('');
    setNumServico(0);
    setTitulo('');
    setUnidServico('');
    setPreco(0);
    setTaxIssqn(0);
    setTaxCidadeCod('');
    setCodTributacao('');
    setCodServico('');
    setComissao(0);
    setObs('');

    setIsEdit(false);
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);

      formRef.current?.setErrors({});

      data.price = Number(preco);

      const validation = Yup.object({
        title: Yup.string().required('Informar o título!'),
        service_unity: Yup.string()
          .uuid('Informe unidade do serviço!')
          .required('Informe unidade do serviço!'),
        price: Yup.number()
          .positive('Informe um preço!')
          .required('Informe um preço!'),
      });
      await validation.validate(data, { abortEarly: false });

      if (isEdit) {
        await api
          .put('/os/service', {
            osService: {
              id,
              title: titulo,
              service_unity_id: unidServico,
              price: preco,
              tax_issqn: taxIssqn,
              tax_cod_city: taxCidadeCod,
              codigo_tributacao: codTributacao || '',
              codigo_servico: codServico || '',
              comission: comissao,
              obs,
            },
          })
          .then(data => {
            toast('success', 'Sucesso', 'Serviço editado com sucesso!', 5000);
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err), 10000);
          });
      } else {
        const res = await api.post('/os/service', {
          osService: {
            title: titulo,
            service_unity_id: unidServico,
            price: preco,
            tax_issqn: taxIssqn,
            tax_cod_city: taxCidadeCod,
            codigo_tributacao: codTributacao.toString(),
            codigo_servico: codServico.toString(),
            comission: comissao,
            obs,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Serviço cadastrado com sucesso!');
        }
      }

      listServices();
      clearForm();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchService = async (keyword: string) => {
    if (keyword.length >= 3) {
      await api
        .get(`/os/service/v1/search?src=${keyword}`)
        .then(({ data }) => {
          const opts = data.searchOsService.map((i: any) => {
            return {
              id: i.id,
              service_number: i.service_number,
              title: i.title,
              service_unity_id: i.service_unity_id,
              unity: i.unity,
              price: Number(i.price),
              tax_issqn: Number(i.tax_issqn),
              tax_cod_city: i.tax_cod_city,
              codigo_tributacao: i.codigo_tributacao,
              codigo_servico: i.codigo_servico,
              comission: Number(i.comission),
              obs: i.obs,
            };
          });

          setServicoOpts(opts);
        })
        .catch((e: any) => {
          if (e instanceof Yup.ValidationError) {
            const errors = getValidationErrors(e);
            formRef.current?.setErrors(errors);
            toast('error', 'Error', e.errors[0]);
          } else {
            toast('error', 'Error', e.response?.data?.error);
          }
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const listServices = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/service/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsService.map((i: any) => {
          return {
            id: i.id,
            service_number: i.service_number,
            title: i.title,
            service_unity_id: i.service_unity_id,
            unity: i.serviceUnity.title,
            price: Number(i.price),
            tax_issqn: Number(i.tax_issqn),
            tax_cod_city: i.tax_cod_city,
            codigo_tributacao: i.codigo_tributacao,
            codigo_servico: i.codigo_servico,
            comission: Number(i.comission),
            obs: i.obs,
          };
        });

        setServicoOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0]);
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleSelectService = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao listar o serviço!');
      return;
    }

    const dt = servicoOpts.find(i => i.id === id);
    if (!dt) {
      toast('warn', 'Alerta', 'Falha ao setar o serviço!');
      return;
    }

    setIsEdit(true);
    setId(dt.id || '');
    setNumServico(dt.service_number || 0);
    setTitulo(dt.title);
    setUnidServico(dt.service_unity_id);
    setPreco(dt.price);
    setTaxIssqn(dt.tax_issqn);
    setTaxCidadeCod(dt.tax_cod_city);
    setCodTributacao(dt.codigo_tributacao);
    setCodServico(dt.codigo_servico);
    setComissao(dt.comission);
    setObs(dt.obs);

    setModalSearchOsServiceVisible(false);
  };

  const handleRemService = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao exlcuir, tente novamente!');
      return;
    }

    try {
      setLoadingStatus(true);
      const res = await api.delete(`/os/service/${id}`);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item excluído com sucesso!');
      }

      listServices();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** render */
  const renderRemButton = (id: string) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemService(id)}
      />
    );
  };

  /** useEffet */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/os/service-unity?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsServiceUnity.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setUnidServicoOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0]);
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });

    listServices();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => clearForm()}
      />
      <Button
        label="Buscar"
        className="mb-3 mx-2"
        icon="fa-solid fa-search"
        onClick={() => setModalSearchOsServiceVisible(true)}
      />
      <Button
        label="O.S"
        className="mb-3 mx-2"
        icon="fa-solid fa-wrench"
        onClick={() => router.push('/os/new')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card">
        <Form ref={formRef} onSubmit={e => handleSubmit(e)}>
          <div className="p-fluid grid formgrid">
            <div className="field col-8">
              <label htmlFor="title">Serviço</label>
              <InputText
                name="title"
                placeholder="Ex.: Manutenção..."
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
                autoFocus={isEdit}
              />
            </div>

            <div className="field col-12 md:col-2 mx-0">
              <label htmlFor="service_unity">Unidade</label>
              <InputDropDown
                name="service_unity"
                options={unidServicoOpts}
                value={unidServico}
                onChange={e => setUnidServico(e.value)}
                placeholder="Selecionar..."
                tooltip={'Hr, Min, Dia...'}
                tooltipOptions={{ position: 'bottom' }}
              />
            </div>

            <div className="field md:col-2"></div>

            <div className="field col-12 md:col-2">
              <label htmlFor="price">Preço</label>
              <InputNumber
                name="price"
                placeholder="0,00"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={preco}
                onChange={e => setPreco(Number(e.value))}
                mode="decimal"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="commission">Comissão (%)</label>
              <InputNumber
                name="commission"
                placeholder="0,00%"
                value={comissao}
                onChange={e => setComissao(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="tax_issqn">Taxa ISSQN (%)</label>
              <InputNumber
                name="tax_issqn"
                placeholder="0,00%"
                value={taxIssqn}
                onChange={e => setTaxIssqn(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cod_tributacao">Código tributação</label>
              <InputText
                name="cod_tributacao"
                placeholder="Ex.: 99.99"
                value={codTributacao}
                onChange={e => setCodTributacao(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cod_servico">Código serviço</label>
              <InputText
                name="cod_servico"
                placeholder="Ex.: 9999"
                value={codServico}
                onChange={e => setCodServico(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="tax_cod_city">Código cidade</label>
              <InputText
                name="tax_cod_city"
                placeholder="Ex.: AAA00000"
                value={taxCidadeCod}
                onChange={e => setTaxCidadeCod(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-12">
              <InputTextEdit
                name="obs"
                style={{ height: '10vh' }}
                value={obs}
                onTextChange={e => setObs(e.htmlValue || '')}
                placeholder="Este produto tem uma ótima saída!"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={isEdit ? 'Editar' : 'Salvar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchOsService
        visible={modalSearchOsServiceVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchOsServiceVisible(false)}
      >
        <h3>Buscar serviço</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="keyword"
                placeholder="Telefone..."
                type={'text'}
                value={serviceKeyword}
                onChange={e => setServiceKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={serviceKeyword.length < 3 ? true : false}
                onClick={() => searchService(serviceKeyword)}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={servicoOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectService(e.value.id)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="title" header="Descrição"></Column>
                <Column field="unity" header="Unidade"></Column>
                <Column
                  field="price"
                  header="Preço"
                  body={e => renderColumnDecimal(e.price)}
                ></Column>
                <Column header="*" body={e => renderRemButton(e.id)}></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchOsService>
    </>
  );
};

export default osService;
