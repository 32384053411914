export const currencyToFloat = (value: string): number => {
  if (value) {
    try {
      return parseFloat(value?.replaceAll('.', '')?.replace(',', '')) / 100;
    } catch (e) {
      return 0;
    }
  } else {
    return 0;
  }
};

export const numberToCurrency = (value: number): string => {
  const floated = value.toFixed(2);
  let formmated = floated.toString().replace('.', ',');
  formmated = formmated.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return formmated;
};

export default function convertToNumber(value: string): string {
  return parseFloat(value.replace(/[^0-9,]*/g, '').replace(/,/gi, '.')).toFixed(
    2,
  );
}

export function numberToCurrencySome(value: number, fixed: number): string {
  const floated = value.toFixed(fixed);
  let formmated = floated.toString().replace('.', ',');
  formmated = formmated.replace(/(?=(\d{3})+(\D))\B/g, '.');
  return formmated;
}

export function currencyToFloatSome(
  value: string,
  placesDecimals: number,
): number {
  if (value) {
    const newValue = ['1'];
    for (let i = 0; i < placesDecimals; i++) {
      newValue.push('0');
    }
    try {
      return (
        parseFloat(value?.replaceAll('.', '')?.replace(',', '')) /
        parseFloat(newValue.join(''))
      );
    } catch (e) {
      return 0;
    }
  } else {
    return 0;
  }
}
