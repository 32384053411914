import { endOfMonth, startOfMonth } from 'date-fns';

export interface ISearch {
  customers: string[];
  commissioneds: string[];
  natureOperations: string[];
  period_open: Date[];
  period_close: Date[];
  closed: boolean | null;
  os_status: string[];
  status: string[];
}

const hoje = new Date();

export const emptySearch: ISearch = {
  customers: [],
  commissioneds: [],
  natureOperations: [],
  period_open: [startOfMonth(hoje), endOfMonth(hoje)],
  period_close: [],
  closed: true,
  os_status: [],
  status: ['A'],
};
