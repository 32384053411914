import { v4 } from 'uuid';
import { IProductRow } from '../dtos/IConOrderDTO';

export const emptyProductRow: IProductRow = {
  id: v4(),
  pos: 0,
  stock: 0,
  table_price_id: '',
  table_price_description: '',
  sku: '',
  product_id: '',
  product_description: '',
  value: '',
  lucre: 0,
  lucre_aliq: 0,
  label: '',
  image_url: null,
  qnt: 1,
  qnt_out: 2,
  vlr_unit: 0,
  price_out: 2,
  add: 0,
  add_aliq: 0,
  desc: 0,
  desc_aliq: 0,
  vlr_liq: 0,
  total: 0,
};
