import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import api from '../../../services/api';
import { parseToCnpj, parseToCpf } from '../../../utils/cpf_cnpj_format';
import BusinessHeaderButtons from '../header-buttons';
import Progress from '../../../components/progress-bar';
import { IBusinessList } from '../dtos/IBusinessDTO';
import { AxiosError, AxiosResponse } from 'axios';
import renderColumnCurrency from '../../../components/DataTableColumns/RenderColumnCurrency';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnCpfCnpj from '../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';

const BusinessList = (): React.ReactElement => {
  const navigate = useHistory();

  const [isLoad, setIsLoad] = useState(false);
  const [business, setBusiness] = useState();
  const [globalFilter, setGlobalFilter] = useState('');

  const loadBusiness = async () => {
    setIsLoad(true);
    await api
      .post(`/business/list`, {
        pagination: {
          page: 1,
          perPage: 10,
          status: ['A'],
        },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setBusiness(
            res.data.map((i: any) => {
              return {
                id: i.id,
                logo_url: i.logo_url,
                name: i.name,
                cpf_cnpj: i.cpf_cnpj,
                status: i.status,
                bussines_followup: i.bussines_followup,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {})
      .finally(() => {
        setIsLoad(false);
      });
  };

  const imageBodyTemplate = (row: IBusinessList) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {row.logo_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${row.logo_url}`}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const actionBodyTemplate = (row: IBusinessList) => {
    return (
      <Button
        icon="fa-solid fa-edit"
        onClick={() => navigate.push(`/business/edit/${row.id}`)}
      />
    );
  };

  const bodyTemplateFollowUp = (row: IBusinessList) => {
    const followUp = row.bussines_followup;
    return (
      <span>
        {followUp === 'COM'
          ? 'Comércio'
          : followUp === 'ECO'
          ? 'E-commerce'
          : followUp === 'IND'
          ? 'Industria'
          : 'Serviços'}
      </span>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Empresas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="Buscar"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  useEffect(() => {
    loadBusiness();
  }, []);

  return (
    <>
      <BusinessHeaderButtons />
      <Progress isLoad={isLoad} />
      <div className="card">
        <DataTable
          value={business}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          className="card"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} empresas"
          globalFilter={globalFilter}
          emptyMessage="Não existe empresa cadastrada."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="code" header="#" body={renderColumnPosition}></Column>
          <Column
            field="name"
            header="Logo"
            body={r => imageBodyTemplate(r)}
            align={'center'}
          ></Column>
          <Column field="name" header="Nome" align={'left'}></Column>
          <Column
            header="CPF/CNPJ"
            body={r => renderColumnCpfCnpj(r.cpf_cnpj)}
            align={'left'}
          ></Column>
          <Column
            field="bussines_followup"
            header="Seguimento"
            body={bodyTemplateFollowUp}
            align={'left'}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={r => renderColumnStatus(r)}
            align={'center'}
          ></Column>
          <Column
            body={r => actionBodyTemplate(r)}
            header="Ações"
            align={'center'}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default BusinessList;
