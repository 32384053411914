import { v4 } from 'uuid';
import { EnumStatus } from '../../../enum/EnumStatus';
import IOsPayment from '../dtos/IOsPayment';

export const emptyOsPayment: IOsPayment = {
  id: v4(),
  b_id: '',
  os_id: '',
  payment_id: '',
  value_doc: 0,
  value_parcel: 0,
  user_id: '',
  status: EnumStatus.ATIVO,
  group_id: '',
  payment: undefined,
  description: '',
  parcel: 0,
  due_date: new Date(),
};
