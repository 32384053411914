import React, { useRef, useState } from 'react';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** service */
import api from '../../../../services/api';

/** components */
import { startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import InputDate from '../../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import useToastContext from '../../../../hooks/toast';
import { Option } from '../../../../types/optional';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import ProductHeaderButtons from '../../../product/header-buttons';

const typeMovs: Array<Option> = [
  { label: 'Entrada', value: 'E' },
  { label: 'Saída', value: 'S' },
  { label: 'Inventário', value: 'I' },
  { label: 'Todos', value: 'T' },
];

const ReportsProductMov: React.FC = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  const hoje = new Date();

  const [load, setLoad] = useState<boolean>(false);
  const [typeMov, setTypeMov] = useState<Array<string>>(['T']);
  const [product, setProduct] = useState<any>();

  const [products, setSearchProducts] = useState<any>();

  /** initial date */
  const [start, setStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [end, setEnd] = useState<Date | Date[] | undefined>(endOfMonth(hoje));

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.titulo,
        value: item.id,
      };
    });
  };

  /** autoComplete */
  const searchProvider = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const productOptions = ammountOptions(data.products);
          productOptions.length <= 0 &&
            toast('error', 'Error', 'Nenhum produto encontrado!');

          setSearchProducts(productOptions);
        })
        .catch(error => toast('error', 'Error', error.response.data.error));
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoad(true);
      form.current?.setErrors({});

      if (typeMov.length === 0) {
        toast('warn', 'Alerta', 'Informe pelo menos 1 tipo de movimentação!');
        form.current?.setErrors({
          type_mov: 'Informe pelo menos 1 tipo de movimentação!',
        });
        return;
      }

      const schemaReports = Yup.object({
        type_mov: Yup.array().of(Yup.string()),
        product_id: Yup.string().required('Por favor, informe o produto!'),
      });
      await schemaReports.validate(data, { abortEarly: false });

      const { data: response } = await api.post(
        `/reports/products/movimentations`,
        {
          period: {
            start,
            end,
          },
          product_id: product.value,
          type_mov: typeMov,
        },
      );
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <ProductHeaderButtons />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12">
              <label htmlFor="product_id">Produto</label>
              <InputAutoComplete
                name="product_id"
                value={product}
                completeMethod={e => searchProvider(e)}
                suggestions={products}
                className="w-6"
                field="label"
                onChange={e => setProduct(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar Produto..."
              />
            </div>
            <InputText type="hidden" name="product_id" value={product?.value} />
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="date_initial"
                value={start}
                dateFormat="dd/mm/yy"
                onChange={e => setStart(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="date_final"
                value={end}
                dateFormat="dd/mm/yy"
                onChange={e => setEnd(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="type_mov">Status</label>
              <MultiSelect
                name="type_mov"
                options={typeMovs}
                value={typeMov}
                onChange={e => setTypeMov(e.value)}
                placeholder="Selecionar..."
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Imprimir"
                icon="fa-solid fa-print"
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsProductMov;
