export default function formatCurrency(num: string | number): string {
  let convNum: number = 0;

  if (typeof num === 'string') {
    convNum = Number(num);
  }

  if (typeof num === 'number') {
    if (!isNaN(num)) {
      convNum = Number(num);
    }
  }

  return new Intl.NumberFormat('BRL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(convNum);
}
