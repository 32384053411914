import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import localePt from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import PRCalendar from '../components/Inputs/InputCalendar';
import { Dialog } from '../components/Modal';

const CalendarDemo = (): React.ReactElement => {
  const [eventDialog, setEventDialog] = useState(false);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [changedEvent, setChangedEvent] = useState({
    title: '',
    start: null,
    end: null,
    allDay: null,
  });
  const [events] = useState(null);

  const eventClick = (e: any) => {
    const { title, start, end } = e.event;
    setEventDialog(true);
    setClickedEvent(e.event);
    setChangedEvent({ title, start, end, allDay: null });
  };

  const save = () => {
    setEventDialog(false);

    setChangedEvent({ title: '', start: null, end: '', allDay: null });
  };

  const reset = () => {
    const { title, start, end } = clickedEvent as any;
    setChangedEvent({ title, start, end, allDay: null });
  };

  const footer = (
    <>
      <Button
        type="button"
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={save}
      />
      <Button
        type="button"
        label="Reset"
        icon="pi pi-refresh"
        className="p-button-text"
        onClick={reset}
      />
    </>
  );

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card calendar-demo">
          <FullCalendar
            events={events}
            eventClick={eventClick}
            initialDate={new Date()}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            editable
            locale={localePt}
            selectable
            selectMirror
            dayMaxEvents
          />

          <Dialog
            visible={eventDialog && !!clickedEvent}
            style={{ width: '450px' }}
            header="Event Details"
            footer={footer}
            modal
            closable
            onHide={() => setEventDialog(false)}
          >
            <div className="p-fluid">
              <div className="field">
                <label htmlFor="title">Title</label>
                <InputText
                  id="title"
                  value={changedEvent.title}
                  onChange={e =>
                    setChangedEvent({
                      ...changedEvent,
                      ...{ title: e.target.value },
                    })
                  }
                  required
                  autoFocus
                />
              </div>
              <div className="field">
                <label htmlFor="start">From</label>
                <PRCalendar
                  id="start"
                  name="calendar"
                  value={changedEvent.start as any}
                  onChange={e =>
                    setChangedEvent({ ...changedEvent, ...{ start: e.value } })
                  }
                  showTime
                  appendTo={document.body}
                />
              </div>
              <div className="field">
                <label htmlFor="end">To</label>
                <PRCalendar
                  id="end"
                  name="calendarr"
                  value={changedEvent.end as any}
                  onChange={e =>
                    setChangedEvent({ ...changedEvent, ...{ end: e.value } })
                  }
                  showTime
                  appendTo={document.body}
                />
              </div>
              <div className="field-checkbox">
                <Checkbox
                  inputId="allday"
                  name="allday"
                  value="All Day"
                  checked={!!changedEvent.allDay}
                  onChange={e =>
                    setChangedEvent({ ...changedEvent, ...{ allDay: e.value } })
                  }
                />
                <label htmlFor="allday">All Day</label>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CalendarDemo, comparisonFn);
