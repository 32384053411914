import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RegisterCashHeader: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Abrir caixa"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/cash-register/open')}
      />

      <Button
        label="Fechar caixa"
        className="mb-2 mr-2"
        icon="fa-solid fa-xmark"
        onClick={() => navigate.push('/cash-register/close')}
      />

      <Button
        label="Entrada caixa"
        className="mb-2 mr-2"
        icon="fa-solid fa-down-long"
        onClick={() => navigate.push('/register-cash-movimentation/cash-in')}
      />

      <Button
        label="Saída caixa"
        className="mb-2 mr-2"
        icon="fa-solid fa-up-long"
        onClick={() => navigate.push('/register-cash-movimentation/cash-out')}
      />

      <Button
        label="Caixa"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/register-cash/by-register')}
      />
    </>
  );
};

export default RegisterCashHeader;
