import React from 'react';

const renderColumnStatus: React.FC = (row: any) => {
  return row.status === 'A' ? (
    <i className="fa-solid fa-check text-blue-500"></i>
  ) : (
    <i className="fa-solid fa-xmark text-pink-500"></i>
  );
};

export default renderColumnStatus;
