/* eslint-disable react/jsx-no-undef */
import React from 'react';
import styled from 'styled-components';
import { IItemBlog } from './dtos/IItemBlogDTO';

export const itemBlogTemplate = (post: IItemBlog) => {
  return (
    <ItemCardScroll
      href={post.link}
      className="block"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-11 h-6rem   cursor-pointer">
        <div className="col-12 h-6rem p-0  flex animation relative">
          <div className="col-4 h-6rem p-0">
            <img
              src={post.image_url}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: 'var(--border-radius)',
                borderBottomLeftRadius: 'var(--border-radius)',
              }}
            />
          </div>
          <div
            className="col-8 bg-primary h-6rem border-round-right-md"
            style={{
              borderTopRightRadius: 'var(--border-radius)',
              borderBottomRightRadius: 'var(--border-radius)',
            }}
          >
            <span style={{ fontSize: '1.25rem' }}>
              <strong>{post.title}</strong>
            </span>
            <br />
            <span className="text-overflow-ellipsis overflow-hidden">
              {post.description && post.description.length > 36
                ? `${post.description.substring(0, 36)}...`
                : post.description}
            </span>
            <br />
            {/* <div className="flex align-items-center mt-2">
                <span className="mr-3">Saiba +</span>
                <i className="pi pi-arrow-circle-right text-green-800"></i>
              </div> */}
          </div>
          <div className="animated flex align-items-center justify-content-center">
            <i className="pi pi-eye text-white text-7xl"></i>
          </div>
        </div>
      </div>
    </ItemCardScroll>
  );
};

const ItemCardScroll = styled.a`
  .animated {
    position: absolute;
    inset: 0;
    opacity: 0;
    background: var(--primary-color);
    transition: opacity 0.2s linear;
    border-radius: var(--border-radius);
  }

  &:hover .animated {
    opacity: 0.8;
  }
`;
