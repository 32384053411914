import { v4 } from 'uuid';
import IOsService from './IOsService';

export const emptyService: IOsService = {
  id: v4(),
  b_id: '',
  os_id: '',
  service_id: '',
  technical_id: null,
  vlr_unit: 0,
  qnt: 1,
  tt_unit: 0,
  desc: 0,
  tt_desc: 0,
  adic: 0,
  tt_adic: 0,
  total: 0,
  obs: '',
  user_id: '',
  status: '',
  service_title: null,
  technical_title: null,
};
