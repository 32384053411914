import React, { useEffect, useRef, useState } from 'react';

/* unform */
import { useField } from '@unform/core';

/* components */

import { Editor, EditorProps } from 'primereact/editor';

/* props inputText */
interface PropsInput extends EditorProps {
  name: string;
  defaultValue?: string;
}

export const InputTextEdit: React.FC<PropsInput> = ({
  name,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [text, setText] = useState<string>('');

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'props.value',
    });
  }, [fieldName, registerField]);

  return (
    <Editor
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={text}
      onTextChange={e => e.htmlValue && setText(e.htmlValue)}
      ref={inputRef}
      {...rest}
      className={error ? 'p-invalid' : ''}
    />
  );
};
