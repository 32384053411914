import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const InputProductsHeader: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Listar"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/input-products/list')}
      />
      <Button
        label="Entrada manual"
        icon="fa-solid fa-hand"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/input-products/create')}
      />
      <Button
        label="Entrada via XML"
        icon="fa-solid fa-file-code"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/input-products/create/xml')}
      />

      <Button
        label="Entradas no Estq."
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/reports/stock-control/input-historic')}
      />
      <Button
        label="Comprovante entrada"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() =>
          navigate.push('/reports/stock-control/receib-input-product')
        }
      />
    </>
  );
};

export default InputProductsHeader;
