import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { CalendarChangeParams } from 'primereact/calendar';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../../components/Divider';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import Progress from '../../../../components/progress-bar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import ConsignedTopMenuReport from '../top-menu-report';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const ReportDevolutionSearch = (): React.ReactElement => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);

  const [isLoad, setIsLoad] = useState(false);
  const toast = useToastContext();

  const [keyword, setKeyword] = useState('');

  const [routes, setRoutes] = useState<IOptionsDTO[]>([]);
  const [route, setRoute] = useState<IOptionsDTO[]>([]);

  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<IOptionsDTO[]>([]);

  const [dateDigitation, setDateDigitation] = useState<
    Date | Date[] | undefined
  >([startOfMonth(hoje), endOfMonth(hoje)]);

  const [dateDevolution, setDateDevolution] = useState<
    Date | Date[] | undefined
  >([]);

  const [natOpes, setNatOpes] = useState<IOptionsDTO[]>([]);
  const [natOpe, setNatOpe] = useState<string | null>(null);

  const [checked, setChecked] = useState<boolean | null>(null);
  const [nfeEmited, setNfeEmited] = useState<boolean | null>(null);

  const [total, setTotal] = useState<number[]>([0, 0]);

  const statusOpts = [
    { value: 'T', label: 'Todos' },
    { value: 'A', label: 'Ativo' },
    { value: 'C', label: 'Cancelado' },
  ];
  const [status, setStatus] = useState<string>('T');

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const loadNatOpe = async () => {
    setIsLoad(true);
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setNatOpes(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadRoutes = async () => {
    setIsLoad(true);
    await api
      .post('/consigned/route/list', {
        data: { page: 1, perPage: 100, block: null, status: 'A' },
      })
      .then(({ data }) => {
        if (data) {
          setRoutes(
            data.map((i: any) => {
              return {
                value: i.id,
                label: `${i.sequence} - ${i.customer.name} - ${format(
                  new Date(i.date_open),
                  'dd/MM/yy H:mm',
                )}`,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const handleAddCustomer = (id: string) => {
    if (validate(id)) {
      const hasCustomer = customer.find((i: IOptionsDTO) => i.value === id);
      if (!hasCustomer) {
        const _customer = customers.find((i: IOptionsDTO) => i.value === id);
        if (_customer) {
          setCustomer([...customer, _customer]);
        }
        setKeyword('');
      }
    }
  };

  const handleRemCustomer = (id: string) => {
    if (validate(id)) {
      const noId = customer.filter((i: IOptionsDTO) => i.value !== id);
      if (noId) {
        setCustomer([...noId]);
      }
      setKeyword('');
    }
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    await api
      .post('/reports/consigned/devolutions', {
        data: {
          routes: route,
          customers: customer.map((i: IOptionsDTO) => i.value),
          date_digitation: dateDigitation,
          date_devolution: dateDevolution,
          nat_opes: natOpe ? [natOpe] : [],
          checked: checked,
          nfe_emited: nfeEmited,
          totals: total,
          status: [status],
        },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Relatório gerado com sucesos!');
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-rotas',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const itemTemplateCustomer = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemCustomer(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  useEffect(() => {
    loadNatOpe();
    loadRoutes();
  }, []);

  return (
    <>
      <ConsignedTopMenuReport />
      <Progress isLoad={isLoad} className="w-10" />
      <div className="card w-10">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgroup"
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="customer">Cliente</label>
            <InputAutoComplete
              name="customer"
              value={keyword}
              suggestions={customers}
              completeMethod={e => searchCustomer(e.query)}
              field="label"
              onChange={e => {
                setKeyword(e.value);
              }}
              onSelect={e => handleAddCustomer(e.value.value)}
              itemTemplate={itemTemplateCustomer}
              placeholder="Buscar cliente..."
            />
          </div>

          {customer.length > 0 && (
            <DataTable
              value={customer}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Sem clientes..."
              selectionMode="single"
              loading={isLoad}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} clientes."
              className="field col-12"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column header="Nome" field="label"></Column>
              <Column
                header="*"
                body={e => renderCollumnMenu(e.value)}
                align={'center'}
              ></Column>
            </DataTable>
          )}

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="route">
              Rotas/Cobranças {route.length > 0 ? `[${route.length}]` : ''}
            </label>
            <MultiSelect
              name="route"
              options={routes}
              value={route}
              onChange={e => setRoute(e.value)}
              placeholder="Selecionar..."
              display="chip"
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="date_digitation">Aberturas</label>
            <CalendarRange
              name="date_digitation"
              dateFormat="d/m/y"
              showIcon
              value={dateDigitation}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setDateDigitation(e.value as Date[])
              }
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="date_devolution">Faturamento</label>
            <CalendarRange
              name="date_devolution"
              dateFormat="d/m/y"
              showIcon
              value={dateDevolution}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setDateDevolution(e.value as Date[])
              }
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="nat_ope">Nat. Operação</label>
            <InputDropDown
              name="nat_ope"
              value={natOpe}
              options={natOpes}
              placeholder="Selecionar..."
              onChange={e => setNatOpe(e.value)}
              filter
            />
          </div>

          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="totals_start">Pedidos entre</label>
            <InputNumber
              name="totals_start"
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={total[0] as number}
              onChange={(e: any) => {
                setTotal([Number(e.value), total[1]]);
              }}
              placeholder="0,00"
            />
          </div>
          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="totals_end">0,0 ~ 100,0</label>
            <InputNumber
              name="totals_end"
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={total[1] as number}
              onChange={(e: any) => {
                setTotal([total[0], Number(e.value)]);
              }}
              placeholder="0,00"
            />
          </div>

          <div className="field col-2 mb-0 pb-0 mb-1 pb-1">
            <label htmlFor="status">Status</label>
            <InputDropDown
              name="status"
              value={status}
              options={statusOpts}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
              filter
              required
            />
          </div>

          <div className="field col-3 mb-1 pb-1">
            <div className="flex justify-content-start py-2">
              <InputSwitch
                name="checked"
                checked={checked}
                onChange={e => setChecked(e.value)}
              />
              <span className="block pl-2">Conferido</span>
            </div>
          </div>

          <div className="field col-3 mb-1 pb-1">
            <div className="flex justify-content-start py-2">
              <InputSwitch
                name="nfe_emited"
                checked={nfeEmited}
                onChange={e => setNfeEmited(e.value)}
              />
              <span className="block pl-2">Com Nfe/Nfce</span>
            </div>
          </div>

          <Divider />

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Cancelar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportDevolutionSearch;
