import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OrderHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '10px',
      }}
    >
      <Button
        label="Pedido"
        style={{ minWidth: '95px' }}
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/orders/create')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Pedidos"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-file-invoice-dollar"
        onClick={() => navigate.push('/orders/list')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Pdv"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-cash-register"
        onClick={() => navigate.push('/frente-caixa')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Nfe"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-barcode"
        onClick={() => navigate.push('/nfe/export/list')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Nfce"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-qrcode"
        onClick={() => navigate.push('/nfce/export/list')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Clientes"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-users"
        onClick={() => navigate.push('/persons/list')}
      />
      <Button
        style={{ minWidth: '95px' }}
        label="Produtos"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-boxes-stacked"
        onClick={() => navigate.push('/products/list')}
      />

      <Button
        style={{ minWidth: '95px' }}
        label="Pedidos"
        className="mb-3 ml-2 p-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/orders/list')}
      />
    </div>
  );
};

export default OrderHeaderButtons;
