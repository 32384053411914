import axios from 'axios';
import { format } from 'date-fns';

const token = localStorage.getItem('@SimploSIS:token') || '';
const b_id = localStorage.getItem('@SimploSIS:_b') || '';

const hoje = format(new Date(), 'yyyyMMdd-Hmmss');

const openReports = (link: string, reportName?: string): void => {
  axios
    .get(`${link}`, {
      headers: {
        'x-api-key': `${process.env.TS_TOKEN_NEXT}`,
        'api-b-key': b_id,
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    .then(r => {
      const url = window.URL.createObjectURL(r.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `webger-${reportName ? reportName : 'relatorio'}-${hoje}`;
      a.click();
      window.open(url);
    });
};

export default openReports;
