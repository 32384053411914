import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ConsignedTopMenuReport = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Rotas"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/consigned/route/list')}
      />

      <Button
        label="Rotas"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/report/consigned/route/search')}
      />

      <Button
        label="Pedidos"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/report/consigned/order/search')}
      />
      <Button
        label="Devoluções"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/report/consigned/devolution/search')}
      />
    </>
  );
};

export default ConsignedTopMenuReport;
