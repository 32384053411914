import React, { useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** utils */
import { endOfDay, startOfDay } from 'date-fns';
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** service */
import api from '../../../../services/api';

/** components */
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import InputDate from '../../../../components/Inputs/InputCalendar';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Toast } from 'primereact/toast';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { Option } from '../../../../types/optional';
import InputProductsHeader from '../../../input-products/input-products-header';

const status: Array<Option> = [
  { label: 'Ativo', value: 'A' },
  { label: 'Cancelado', value: 'C' },
  { label: 'Todos', value: 'T' },
];

const ReportsStockInput: React.FC = (): React.ReactElement => {
  /** providers */
  const [providers, setProviders] = useState();

  const [provider, setSearchProvider] = useState<any>();

  /** initial date */
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );

  /** selected status */
  const [selectedStatus, setSelectedStatus] = useState('T');

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const handleReports = async (data: any) => {
    try {
      const schemaReports = Yup.object({
        status: Yup.string().required('Por favor, informe o status!'),
      });
      await schemaReports.validate(data, { abortEarly: false });
      const { data: response } = await api.get(`/reports/input`, {
        params: {
          dateStart: startOfDay(initialDate as Date),
          dateEnd: endOfDay(finalDate as Date),
          providerId: provider?.value,
          status: data.status,
        },
      });
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  /** autoComplete */
  const searchProvider = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      api
        .get(`/persons/provider/src?search=${event.query}`)
        .then(({ data }) => {
          const productOptions = ammountOptions(data.providers);
          productOptions.length <= 0 &&
            showToast('error', 'Error', 'Nenhum produto encontrado!');

          setProviders(productOptions);
        })
        .catch(error => showToast('error', 'Error', error.response.data.error));
    }
  };

  return (
    <>
      <InputProductsHeader />
      <div className="card w-6">
        <Form ref={form} onSubmit={handleReports}>
          <Toast ref={toast} />
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Fornecedor</label>
              <InputAutoComplete
                name="providerId"
                value={provider}
                completeMethod={e => searchProvider(e)}
                suggestions={providers}
                className="w-6"
                field="label"
                onChange={e => setSearchProvider(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar fornecedores..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="date_initial"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="date_final"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Status</label>
              <InputDropDown
                name="status"
                options={status}
                value={selectedStatus}
                placeholder="Selecionar..."
                onChange={e => setSelectedStatus(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button
                label="Imprimir"
                className="p-button-raised p-button-success"
                icon="fa-solid fa-print"
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsStockInput;
