import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectParams } from 'primereact/datatable';
import { DropdownChangeParams } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import useToastContext from '../../../hooks/toast';
import { useOrderService } from '../../../hooks/useOrderService';
import IOptionsDTO from '../../../pages/business/dtos/IOptionsDTO';
import IOsService from '../../../pages/os/dtos/IOsService';
import { emptyService } from '../../../pages/os/dtos/emptyService';
import FastFormOsService from '../../../pages/os/osService/FastFormOsService';
import IOsServiceDTO from '../../../pages/os/osService/dtos/IOsServiceDTO';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { DataTable } from '../../DataTable';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import renderResumeText from '../../DataTableColumns/RenderResumeText';
import { InputAutoComplete } from '../../Inputs/InputAutoComplete';
import { InputDropDown } from '../../Inputs/InputDropDown';
import { InputNumber } from '../../Inputs/InputNumber';
import { InputTextArea } from '../../Inputs/InputTextArea';

const InputRowServiceOs = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [serviceFocus, setServiceFocus] = useState(false);
  const serviceRef = useRef(null);

  const [qntFocus, setQntFocus] = useState(false);
  const qntRef = useRef(null);

  const [services, setServices] = useState<IOsServiceDTO[]>([]);
  const [service, setService] = useState<IOptionsDTO>({ value: '', label: '' });
  const [technicals, setTechnicals] = useState<IOptionsDTO[]>([]);
  const [item, setItem] = useState<Partial<IOsService>>(emptyService);

  const { orderService, addService, remService } = useOrderService();

  const clearRow = () => {
    setService({ value: '', label: '' });
    setItem({ ...emptyService, id: v4() });

    setServiceFocus(true);
    serviceRef.current?.focus();
  };

  const handleAddService = async (d: any) => {
    try {
      setLoad(true);

      formRef.current?.setErrors({});

      d.service_id = item.service_id;
      d.vlr_unit = item.vlr_unit;
      d.qnt = item.qnt;

      const schema = Yup.object({
        service_id: Yup.string()
          .uuid('Informe um serviço na O.S!')
          .required('Informe um serviço na O.S!'),
        vlr_unit: Yup.number().required('Informe um valor para o serviço!'),
        qnt: Yup.number()
          .positive('Informe uma quantidade!')
          .required('Informe uma quantidade!'),
      });

      await schema.validate(d, { abortEarly: false });

      addService(item);
      clearRow();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleRemService = (id: string) => {
    remService(id);
  };

  const handleSelectService = (e: any) => {
    setService({ value: e.value, label: e.label });
    setItem({
      ...item,
      service_id: e.value,
      service_title: e.label,
      vlr_unit: Number(e.price),
    });
  };

  const handleSelectTechnical = (e: DropdownChangeParams) => {
    const t = technicals.find(i => i.value === e.value);
    if (t) {
      setItem({ ...item, technical_id: t.value, technical_title: t.label });
    }
  };

  const loadTechnicals = async () => {
    setLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 100, status: ['A', 'C'] },
      })
      .then(({ data }) => {
        if (data) {
          setTechnicals(
            data.map((i: any) => {
              return {
                label: i.name,
                value: i.id,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const searchServices = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      api
        .get(`/os/service/v1/search?src=${e.query}`)
        .then(({ data }) => {
          if (data) {
            setServices(
              data.searchOsService.map((item: any) => {
                return {
                  label: item.title,
                  value: item.id,
                  unity: item.unity,
                  price: Number(item.price),
                };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  const calculateRow = () => {
    const _liq = Number(item.vlr_unit) + Number(item.adic) - Number(item.desc);
    const _total = Number(_liq) * Number(item.qnt);

    setItem({ ...item, total: _total });
  };

  const handleSelectRow = (e: DataTableSelectParams) => {
    const s = orderService.osServices.find(i => i.id === e.data.id);
    if (s) {
      setService({
        value: s.service_id || '',
        label: s.service_title || '[error]',
      });
      setItem(s);
      setServiceFocus(true);
      serviceRef.current?.focus();
    }
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const renderColumnServiceButtons = (rowId: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-xmark"
          className="p-button-danger"
          onClick={() => handleRemService(rowId)}
          type="button"
        />
      </div>
    );
  };

  useEffect(() => {
    loadTechnicals();
  }, []);

  useEffect(() => {
    calculateRow();
  }, [item.vlr_unit, item.qnt, item.adic, item.desc]);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={d => handleAddService(d)}
        className="p-fluid grid formgrid"
      >
        <div className="field col-12 md:col-4 lg:col-4">
          <label htmlFor="service_id">
            Serviço
            <i
              className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
              onClick={() => setSidebarVisible(true)}
            ></i>
          </label>
          <InputAutoComplete
            inputRef={serviceRef}
            name="service_id"
            suggestions={services}
            value={service}
            completeMethod={e => searchServices(e)}
            field="label"
            onSelect={e => handleSelectService(e.value)}
            onChange={e => setService(e.value)}
            itemTemplate={itemTemplate}
            placeholder="Buscar serviço..."
            autoFocus={serviceFocus}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setQntFocus(true);
                qntRef.current?.focus();
              }
            }}
          />
        </div>
        <div className="field col-12 md:col-3 lg:col-3">
          <label htmlFor="technical_id">Técnico</label>
          <InputDropDown
            name="technical_id"
            options={technicals}
            value={item.technical_id}
            disabled={!validate(item.id || '')}
            className={validate(item.id || '') === false ? 'surface-200' : ''}
            onChange={e => handleSelectTechnical(e)}
            placeholder="Selecionar..."
            filter
          />
        </div>

        <div className="field col-6 md:col-1 lg:col-1">
          <label htmlFor="service_qnt">Qnt.</label>
          <InputNumber
            inputRef={qntRef}
            name="qnt"
            value={item.qnt}
            onChange={e => {
              setItem({ ...item, qnt: Number(e.value) });
            }}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={qntFocus}
            disabled={!validate(item.id || '')}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddService(item);
                setQntFocus(false);
                serviceRef.current?.focus();
              }
            }}
          />
        </div>

        <div className="field col-6 md:col-1 lg:col-1">
          <label htmlFor="service_vlr_unit">Vlr.</label>
          <InputNumber
            onFocus={e => e.target.select()}
            name="service_vlr_unit"
            value={item.vlr_unit}
            onChange={e => setItem({ ...item, vlr_unit: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={false}
            className="surface-200"
            disabled={!validate(item.id || '')}
          />
        </div>

        <div className="field col-6 md:col-1 lg:col-1">
          <label htmlFor="service_adic">Adic.</label>
          <InputNumber
            onFocus={e => e.target.select()}
            name="service_adic"
            value={item.adic}
            onValueChange={e => setItem({ ...item, adic: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={false}
            disabled={!validate(item.id || '')}
          />
        </div>

        <div className="field col-6 md:col-1 lg:col-1">
          <label htmlFor="service_desc">Desc.</label>
          <InputNumber
            onFocus={e => e.target.select()}
            name="service_desc"
            value={item.desc}
            onValueChange={e => setItem({ ...item, desc: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={false}
            disabled={!validate(item.id || '')}
          />
        </div>

        <div className="field col-12 md:col-1">
          <label htmlFor="service_total">Total</label>
          <InputNumber
            onFocus={e => e.target.select()}
            name="service_total"
            value={item.total}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={false}
            disabled
            className="surface-200"
          />
        </div>

        <div className="field col-12 md:col-11 lg:col-11">
          <label htmlFor="service_obs">Observações serviço</label>
          <InputTextArea
            name="service_obs"
            value={item.obs}
            onChange={e => setItem({ ...item, obs: e.currentTarget.value })}
            placeholder="Ex: Troca do componentes xyz e realinhamento..."
            disabled={!validate(item.id || '')}
          />
        </div>

        <div className="field col-12 md:col-1 lg:col-1 pr-0 flex align-items-center justify-content-center">
          <Button
            type="submit"
            loading={load}
            disabled={!validate(item.id || '')}
            className="p-button-success w-6"
            icon="pi pi-plus"
          />
        </div>

        {orderService.osServices.length > 0 && (
          <DataTable
            value={orderService.osServices}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Serviços não informados..."
            selectionMode="single"
            onRowSelect={e => handleSelectRow(e)}
            className="field col-12 md:col-12"
          >
            <Column
              header="#"
              className="my-0 py-1"
              body={renderColumnPosition}
            ></Column>
            <Column
              field="service_title"
              header="Serviço"
              className="my-0 py-1"
              sortable
            ></Column>
            <Column
              field="technical_title"
              header="Técnico"
              className="my-0 py-1"
              sortable
            ></Column>
            <Column
              field="qnt"
              header="Qnt."
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.qnt)}
              sortable
            ></Column>
            <Column
              field="vlr_unit"
              header="Vlr."
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.vlr_unit)}
              sortable
            ></Column>
            <Column
              field="adic"
              header="Adic."
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.adic)}
              sortable
            ></Column>
            <Column
              field="desc"
              header="Desc."
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.desc)}
              sortable
            ></Column>
            <Column
              field="total"
              header="Total"
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.total)}
              sortable
            ></Column>
            <Column
              field="obs"
              header="Obs"
              className="my-0 py-1"
              body={row => renderResumeText(row.obs, 32)}
              sortable
            ></Column>
            <Column
              header="*"
              body={row => renderColumnServiceButtons(row.id)}
            ></Column>
          </DataTable>
        )}
      </Form>

      <FastFormOsService
        isOpen={sidebarVisible}
        onRequestClose={() => setSidebarVisible(false)}
      />
    </>
  );
};

export default InputRowServiceOs;
