import { addDays } from 'date-fns';
import { EnumPriority } from '../../../enum/EnumPriority';
import IOsHeader from '../dtos/IOsHeader';

const hoje = new Date();

export const emptyOsHeader: IOsHeader = {
  id: '',
  b_id: '',
  os_number: 0,
  nat_ope_id: '',
  customer_id: '',
  customer: { id: '', name: '' },
  description: '',
  kind_atendimento: 'INT',
  date_open: hoje,
  date_close: hoje,
  date_prevision: addDays(hoje, 7),
  contract_id: '',
  contract: {
    title: '',
  },
  technical_id: '',
  technical: {
    name: '',
  },
  commissioned_id: '',
  commissioned: {
    name: '',
  },
  priority: EnumPriority.MEDIA,
  os_status_id: '',
  osStatus: {
    title: '',
  },
  total_objects: 0,
  total_services: 0,
  total_products: 0,
  total_payments: 0,
  total_desc: 0,
  perc_desc: 0,
  total_adic: 0,
  perc_adic: 0,
  juros: 0,
  total: 0,
  obs_public: '',
  obs_private: '',
  user_id: '',
  user: {
    name: '',
  },
  status: 'A',
  close: false,
  os_registered: false,
};
