export function formatPostCode(cep: string): string {
  // Remove qualquer caractere não numérico
  const cleanCEP = cep.replace(/\D/g, '');

  // Verifica se o CEP tem exatamente 8 dígitos
  if (cleanCEP.length === 8) {
    return `${cleanCEP.slice(0, 2)}.${cleanCEP.slice(2, 5)}-${cleanCEP.slice(
      5,
    )}`;
  } else {
    // Retorna o CEP original se não tiver o formato esperado
    return cep;
  }
}
