import { useQuery } from 'react-query';
import { IOsStatus } from '../pages/os/osStatus/dtos/IOsStatus';
import api from '../services/api';

export default function useLoadOsStatus() {
  const url = '/os/status/list?page=1&perPage=50&status=A';

  const { data, error, isLoading, isFetched } = useQuery<IOsStatus[]>(
    ['os-status', url],
    () => api.get(url).then(response => response.data.listAllOsStatus),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
