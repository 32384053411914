import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import { Column } from 'primereact/column';
import useToastContext from '../../../hooks/toast';
import {
  emptyBillReceivableResume,
  IBillReceivableResume,
} from '../types/IBillReceivableResume';
import api from '../../../services/api';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import RenderColumnStatusPayment from '../../../components/DataTableColumns/RenderColumnStatusPayment';
import renderColumnShortName from '../../../components/DataTableColumns/RenderColumnShortName';
import RenderColumnStatusBills from '../../../components/DataTableColumns/RenderColumnStatusBills';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import MenuPopUp from '../../../components/MenuPopPup';
import { useHistory } from 'react-router-dom';
import { EnumStatusPayment } from '../../../enum/EnumStatusPayment';
import { classNames } from 'primereact/utils';
import messageRequestError from '../../../utils/messageRequestError';
import openReports from '../../../utils/openReports';
import { EnumStatus } from '../../../enum/EnumStatus';
import { AxiosResponse } from 'axios';

interface IProps {
  customer_id: string;
}

const ContentBillReceivable = ({ customer_id }: IProps): React.ReactElement => {
  const toast = useToastContext();
  const navigate = useHistory();

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [bill, setBill] = useState<IBillReceivableResume>(
    emptyBillReceivableResume,
  );
  const [bills, setBills] = useState<IBillReceivableResume[]>([]);

  const loadBills = async (id: string) => {
    setIsLoad(true);
    await api
      .post('bills-receivable/resume', {
        data: { id },
        pagination: { page: 1, perPage: 1000, status: ['A', 'C'] },
      })
      .then(res => {
        if (res) {
          setBills(res.data);
        }
      })
      .catch(e => {
        toast('warn', 'Alerta', messageRequestError(e), 8000);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const billPrint = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/promissory/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
            `conta-receber`,
          );
        }
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const billCancel = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/bills-receivable/${id}`)
      .then(res => {
        if (res) {
          toast('success', 'Sucesso', 'Documento cancelado com sucesso!');
          loadBills(customer_id);
        }
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const mountMenuItems = (row: IBillReceivableResume) => {
    const items: any[] = [];

    if (row.status === EnumStatus.ATIVO) {
      if (row.status_payment === EnumStatusPayment.AGUARDANDO) {
        items.push({
          label: 'Editar',
          icon: 'fa-solid fa-edit',
          command: () => {
            navigate.push(`/bills/receive/edit/${row.id}`);
          },
        });
      }

      if (row.status_payment !== EnumStatusPayment.PAGO) {
        items.push({
          label: 'Baixar',
          icon: 'pi pi-arrow-circle-down',
          command: () => {
            navigate.push(`/bills/receive/drop/${row.id}`);
          },
        });
      }

      items.push({
        label: 'Imp. Promissória',
        icon: 'pi pi-print',
        command: () => billPrint(row.id),
      });
    }

    if (row.status !== EnumStatus.CANCELADO) {
      items.push({
        label: 'Cancelar',
        icon: 'pi pi-trash',
        command: () => billCancel(row.id),
        template: (item: any, options: any) => {
          return (
            <a
              className={options.className}
              target={item.target}
              onClick={options.onClick}
            >
              <span
                className={classNames(options.iconClassName, item.icon)}
                style={{ color: 'red' }}
              ></span>
              <span className={options.labelClassName}>{item.label}</span>
            </a>
          );
        },
      });
    }

    return items;
  };

  const renderRowMenu = (row: IBillReceivableResume) => {
    const model = mountMenuItems(row);
    if (model.length > 0) {
      return (
        <>
          <MenuPopUp model={model} rowData={row} setData={setBill} />
        </>
      );
    }
  };

  useEffect(() => {
    loadBills(customer_id);
  }, [customer_id]);

  return (
    <>
      <DataTable
        value={bills}
        paginator
        rows={10}
        loading={isLoad}
        loadingIcon="pi pi-spinner text-primary"
        rowsPerPageOptions={[10, 20, 30]}
        className="datatable-responsive m-0 p-0"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} adicionais"
        emptyMessage="Sem contas a receber."
        responsiveLayout="scroll"
        size="small"
      >
        <Column field="id" header="#" body={renderColumnPosition}></Column>
        <Column
          field="customer_name"
          header="Cliente"
          headerStyle={{ width: '20%' }}
        ></Column>
        <Column
          field="emited_day"
          header="Emissão"
          align={'center'}
          body={r => renderColumnDate(r.emited_day)}
        ></Column>
        <Column field="doc_ser" header="Sr." align={'center'}></Column>
        <Column field="doc_num" header="Num." align={'center'}></Column>
        <Column
          field="date_to_pay"
          header="Vencimento"
          align={'center'}
          body={r => renderColumnDate(r.date_to_pay)}
        ></Column>
        <Column
          field="value"
          header="Total"
          align={'right'}
          body={r => renderColumnDecimal(r.value)}
        ></Column>
        <Column
          field="rest"
          header="Restante"
          align={'right'}
          body={r => renderColumnDecimal(r.rest)}
        ></Column>
        <Column
          field="status_payment"
          header="Situação"
          align={'center'}
          body={r => RenderColumnStatusPayment(r.status, r.status_payment)}
        ></Column>
        <Column
          field="user_name"
          header="Usuário"
          body={r => renderColumnShortName(r.user_name)}
        ></Column>
        <Column
          field="status"
          header="Status"
          align={'center'}
          body={r => RenderColumnStatusBills(r.status)}
        ></Column>
        <Column field="description" header="Obs"></Column>
        <Column header="*" body={r => renderRowMenu(r)}></Column>
      </DataTable>
    </>
  );
};

export default ContentBillReceivable;
