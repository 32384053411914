export const renderColumnOrderOrigin = (order_origin: string) => {
  switch (order_origin) {
    case 'PDV':
      return (
        <i
          className="fa-solid fa-cash-register"
          style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
        ></i>
      );
    case 'STR':
      return (
        <i
          className="fa-solid fa-cart-shopping"
          style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
        ></i>
      );
    case 'TER':
      return (
        <i
          className="fa-solid fa-file-lines"
          style={{ fontSize: '1.5rem', color: 'var(--gray-500)' }}
        ></i>
      );
  }
};
