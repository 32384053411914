import { useQuery } from 'react-query';
import { IOsContract } from '../pages/os/osContract/dtos/IOsContract';
import api from '../services/api';

export default function useLoadOsContracts() {
  const url = '/os/contract/list?page=1&perPage=500&status=A';

  const { data, error, isLoading, isFetched } = useQuery<IOsContract[]>(
    ['os-contracts', url],
    () => api.get(url).then(response => response.data.listAllOsContract),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
