import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import openReports from '../../../utils/openReports';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

interface IOsReportProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function OsReport({ isOpen, onRequestClose }: IOsReportProps) {
  const hoje = new Date();

  /** useStates */
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customerOpts, setCustomerOpts] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<string>('');

  const [dateToFilterIsOpts, setDateToFilterIsOpts] = useState<IOptionsDTO[]>([
    { value: 'E', label: 'Entrada' },
    { value: 'P', label: 'Previsão' },
    { value: 'F', label: 'Fechamento' },
  ]);
  const [dateToFilterIs, setDateToFilterIs] = useState<string>('F');

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const [situationsOpts, setSituationsOpts] = useState<IOptionsDTO[]>([]);
  const [situations, setSituations] = useState<string[]>([]);

  const [closedOpts, setClosedOpts] = useState<
    { value: string; label: string }[]
  >([
    { value: 'T', label: 'Todos' },
    { value: 'F', label: 'Fechadas' },
    { value: 'A', label: 'Abertas' },
  ]);
  const [closed, setClosed] = useState<string[]>(['T']);

  const [statusOpts, setStatusOpts] = useState<IOptionsDTO[]>([
    { value: 'T', label: 'Todos' },
    { value: 'A', label: 'Ativo' },
    { value: 'C', label: 'Cancelado' },
  ]);
  const [status, setStatus] = useState<string[]>(['T']);

  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** functions */
  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      const filter = {
        date_to_filter_is: dateToFilterIs,
        date_start: dateStart as Date,
        date_end: dateEnd as Date,
        customers:
          customers.length > 0
            ? customers.map(i => {
                return i.value;
              })
            : undefined,
        situations:
          situations.length > 0
            ? situations.map(i => {
                return i;
              })
            : undefined,
        closed:
          closed.length > 0
            ? closed.map(i => {
                return i;
              })
            : undefined,
        status:
          status.length > 0
            ? status.map(i => {
                return i;
              })
            : undefined,
      };

      const res = await api.post('/os/report/list', { filter });

      if (res.status >= 200 && res.status <= 299) {
        openReports(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
          'ordem-servico',
        );
      }
    } catch (e: any) {
      toast(
        'error',
        'Erro em criar um relatório',
        `Erro: [${e.response?.data?.error}]`,
      );
    } finally {
      setIsLoad(false);
    }
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const opts = customers.map((i: any) => {
          return {
            value: i.id,
            label: `${i.name} - ${i.cpf_cnpj}`,
          };
        });

        setCustomerOpts(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddCustomer = (e: AutoCompleteSelectParams) => {
    const c = customerOpts.find(i => i.value === e.value.value);

    if (c) {
      setCustomers([...customers.filter(i => i.value !== e.value.value), c]);
      setCustomer('');
    }
  };

  const handleRemCustomer = (id: string) => {
    const itensNoId = customers.filter(r => r.value !== id);
    setCustomers(itensNoId);
  };

  const loadOsStatus = async () => {
    setIsLoad(true);
    await api
      .get('/os/status/list?page=1&perPage=50&status=A')
      .then(({ data }) => {
        const opts = data.listAllOsStatus.map((d: any) => {
          return {
            label: `${d.position}-${d.title}`,
            value: d.id,
          };
        });

        setSituationsOpts(opts);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_BAD_REQUEST') {
          toast('warn', 'Alerta', e.response.data.error);
        } else {
          toast('error', 'Erro', e.message);
        }
      })
      .finally(() => setIsLoad(false));
  };

  /** useEffects */
  useEffect(() => {
    if (isOpen) {
      loadOsStatus();
    }
  }, [isOpen]);

  /** render */
  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const renderColumnButtonRem = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemCustomer(id)}
          type="button"
        />
      </div>
    );
  };

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => {
        onRequestClose();
      }}
      style={{ width: '50vw' }}
    >
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <h3>Gerar relatório das O.S</h3>

        <div className="p-fluid grid formgrid">
          <Scope path="filter">
            <div className="field col-11 md:col-11">
              <label htmlFor="customers">Cliente</label>
              <InputAutoComplete
                name="customers"
                suggestions={customerOpts}
                value={customer}
                completeMethod={e => searchCustomer(e.query)}
                onChange={e => setCustomer(e.value)}
                onSelect={e => handleAddCustomer(e)}
                field="label"
                itemTemplate={itemTemplate}
                placeholder="Buscar aqui..."
              />
            </div>

            <div className="field col-1 md:col-1 flex align-items-end justify-content-end">
              <Button
                name="btn_add_customer"
                type="button"
                icon="fa-solid fa-plus"
              />
            </div>

            {customers.length > 0 && (
              <DataTable
                value={customers}
                responsiveLayout="scroll"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum cliente selecionado..."
                selectionMode="single"
                loading={isLoad}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} clientes"
                className="field col-12 md:col-12"
              >
                <Column
                  field="id"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column header="Nome - Cpf/Cnpj" field="label"></Column>
                <Column
                  header="*"
                  body={e => renderColumnButtonRem(e.value)}
                ></Column>
              </DataTable>
            )}

            <div className="field col-12 md:col-4">
              <label htmlFor="date_to_filter_is">Qual data?</label>
              <InputDropDown
                name="date_to_filter_is"
                placeholder="Selecione..."
                options={dateToFilterIsOpts}
                value={dateToFilterIs}
                onChange={e => setDateToFilterIs(e.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="date_start">Data início</label>
              <Calendar
                name="date_start"
                showIcon={true}
                iconPos="right"
                placeholder="00/00/00"
                dateFormat="d/m/yy"
                value={dateStart}
                onChange={e => setDateStart(e.value as Date)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="date_end">Data fim</label>
              <Calendar
                name="date_end"
                showIcon={true}
                iconPos="right"
                placeholder="00/00/00"
                dateFormat="d/m/yy"
                value={dateEnd}
                onChange={e => setDateEnd(e.value as Date)}
              />
            </div>

            <div className="field col-12 md:col-12">
              <label htmlFor="situations">Situação da O.S</label>
              <MultiSelect
                name="situations"
                placeholder="Selecione..."
                display="chip"
                value={situations}
                onChange={e => setSituations(e.value)}
                options={situationsOpts}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="closed">Status O.S</label>
              <MultiSelect
                name="closed"
                placeholder="Selecione..."
                display="chip"
                value={closed}
                onChange={e => setClosed(e.value)}
                options={closedOpts}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="status">Status</label>
              <MultiSelect
                name="status"
                placeholder="Selecione..."
                display="chip"
                value={status}
                onChange={e => setStatus(e.value)}
                options={statusOpts}
              />
            </div>
          </Scope>

          <div className="field col-6 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              className="w-full p-button-danger"
              icon="pi pi-trash"
            />
          </div>
          <div className="field col-6 md:col-6">
            <Button
              label="Imprimir"
              type="submit"
              className="w-full p-button-success"
              icon="pi pi-print"
            />
          </div>
        </div>
      </Form>
    </SideBar>
  );
}
