import React, { useEffect, useRef, useState } from 'react';

/** components */
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from '../../components/Modal';

/** react router dom */
import { useHistory } from 'react-router-dom';

/** api */
import api from '../../services/api';
import messageRequestError from '../../utils/messageRequestError';
import useToastContext from '../../hooks/toast';
import { InputPassword } from '../../components/Inputs/InputPassword';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

const Plans = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(
    window.screen.width < 500 ? '90vw' : '50vw',
  );
  const [isHovering, setIsHovering] = useState(false);
  const [cardEvent, setCardEvent] = useState('');
  const [dialog, setDialog] = useState(false);
  const [planSelected, sePlanSelected] = useState<{
    id: string;
    name: string;
    price: number;
    price_string: string;
  }>();

  /** ref */
  const toast = useToastContext();

  const formRef = useRef<FormHandles>(null);

  const [plans] = useState([
    {
      id: '0498f29e-e823-11ec-ab6e-43f4f34bca93',
      name: 'Paládio',
      price: 189.9,
      price_string: '189.90',
    },
    {
      id: '052de570-e823-11ec-b57e-5f11594c1a7b',
      name: 'Titânio',
      price: 349.9,
      price_string: '349.90',
    },
    {
      id: '05b7865e-e823-11ec-a48f-6bfa099cea9b',
      name: 'Adamantium',
      price: 699.9,
      price_string: '699.90',
    },
    {
      id: '06455ab0-e823-11ec-b70f-dbb3c6b4cf1f',
      name: 'Vibranium',
      price: 999.9,
      price_string: '999.90',
    },
  ]);
  const [cupom, setCupom] = useState('');

  const navigate = useHistory();

  const handleMouseEnter = (card: string) => {
    setIsHovering(true);
    setCardEvent(card);
  };

  const handleMouseLeave = (card: string) => {
    setIsHovering(false);
    setCardEvent(card);
  };

  const signedPlan = async () => {
    setLoading(true);
    await api
      .post('/system/purchase/create', {
        plano_contratado_id: planSelected?.id,
        coupon: cupom !== '' ? cupom : null,
        value: planSelected?.price,
      })
      .then(({ data }) => {
        if (data) {
          toast(
            'success',
            'Sucesso',
            'Upgrade de plano realizado com sucesso!',
          );

          setDialog(false);
          navigate.push('/monthly-payments');
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoading(false));
  };

  const renderFooter = () => {
    return (
      <>
        <div>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => setDialog(false)}
            className="p-button-text"
          />
          <Button
            label={loading ? 'Assinando' : 'Assinar'}
            onClick={signedPlan}
            autoFocus
            loading={loading}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('90vw');
      } else {
        setWidth('50vw');
      }
    });
  });

  return (
    <>
      <div style={{ maxWidth: '1400px', margin: 'auto' }} className="mt-5">
        <div className="grid">
          {plans.map(item => {
            if (item.name === 'Paládio') {
              return (
                <div
                  className="col-12 md:col-3 sm:col-3 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter('one')}
                  onMouseLeave={() => handleMouseLeave('one')}
                  style={{
                    transform:
                      isHovering && cardEvent === 'one'
                        ? 'translateY(-15px)'
                        : 'translateY(0)',
                    transition: 'all 0.3s linear',
                  }}
                >
                  <div className="col-12 bg-primary border-round-top text-center select-none">
                    <span className="font-semibold text-2xl">Paládio</span>
                  </div>
                  <div className="col-12 bg-white">
                    <div className="col-12">
                      <div
                        className="m-auto max-w-max relative"
                        style={{ color: '#495057' }}
                      >
                        <sup
                          className="text-xl font-bold absolute"
                          style={{ left: '-25px' }}
                        >
                          R$
                        </sup>
                        <span
                          className="text-6xl font-bold text-color"
                          style={{ color: '#495057' }}
                        >
                          189
                        </span>
                        <sub className="text-4xl font-bold">.90</sub>
                        <sup className="text-xl font-bold text-primary absolute">
                          /mês
                        </sup>
                      </div>
                    </div>
                    <div className="col-12">
                      <ul className="list-none" style={{ color: '#495057' }}>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Vendas</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Inventário</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nf-e</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Boletos</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas pagar
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas receber
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte whatsapp
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte telefone
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Comissionados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Nfc-e</span>
                          <span className="ml-2 text-primary font-medium">
                            80 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            PDV - Frente de caixa
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte remoto
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Consignados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Vitrine de produtos
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            100 produtos
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Performa +</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Afiliados </span>
                          <span className="ml-2 text-primary font-medium">
                            1 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            API - Integração
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Replicador</span>
                          <span className="ml-2 text-primary font-medium">
                            4 rep/dia
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Migração (free)
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Validar
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-12 cursor-pointer bg-primary border-round-bottom text-center"
                    onClick={() => {
                      setDialog(true);
                      sePlanSelected(item);
                    }}
                  >
                    <span className="select-none font-semibold text-xl">
                      Contratar
                    </span>
                  </div>
                </div>
              );
            }
            if (item.name === 'Titânio') {
              return (
                <div
                  className="col-12 md:col-3 sm:col-3 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter('two')}
                  onMouseLeave={() => handleMouseLeave('two')}
                  style={{
                    transform:
                      isHovering && cardEvent === 'two'
                        ? 'translateY(-15px)'
                        : 'translateY(0)',
                    transition: 'all 0.3s linear',
                  }}
                >
                  <div className="col-12 bg-primary border-round-top text-center select-none">
                    <span className="font-semibold text-2xl">Titânio</span>
                  </div>
                  <div className="col-12 bg-white">
                    <div className="col-12">
                      <div
                        className="m-auto max-w-max relative"
                        style={{ color: '#495057' }}
                      >
                        <sup
                          className="text-xl font-bold absolute"
                          style={{ left: '-25px' }}
                        >
                          R$
                        </sup>
                        <span className="text-6xl font-bold">349</span>
                        <sub className="text-4xl font-bold">.90</sub>
                        <sup className="text-xl font-bold text-primary absolute">
                          /mês
                        </sup>
                      </div>
                    </div>
                    <div className="col-12">
                      <ul className="list-none" style={{ color: '#495057' }}>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Vendas</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Inventário</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nf-e</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Boletos</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas pagar
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas receber
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte whatsapp
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte telefone
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Comissionados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nfc-e</span>
                          <span className="ml-2 text-primary font-medium">
                            80 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            PDV - Frente de caixa
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte remoto
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Consignados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Vitrine de produtos
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            100 produtos
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Performa +</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Afiliados </span>
                          <span className="ml-2 text-primary font-medium">
                            1 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            API - Integração
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Replicador</span>
                          <span className="ml-2 text-primary font-medium">
                            4 rep/dia
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Migração (free)
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Validar
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-12 cursor-pointer bg-primary border-round-bottom text-center"
                    onClick={() => {
                      setDialog(true);
                      sePlanSelected(item);
                    }}
                  >
                    <span className="select-none  font-semibold text-xl">
                      Contratar
                    </span>
                  </div>
                </div>
              );
            }
            if (item.name === 'Adamantium') {
              return (
                <div
                  className="col-12 md:col-3 sm:col-3 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter('three')}
                  onMouseLeave={() => handleMouseLeave('three')}
                  style={{
                    transform:
                      isHovering && cardEvent === 'three'
                        ? 'translateY(-15px)'
                        : 'translateY(0)',
                    transition: 'all 0.3s linear',
                  }}
                >
                  <div className="col-12 bg-primary border-round-top text-center select-none">
                    <span className="font-semibold text-2xl">Adamantium</span>
                  </div>
                  <div className="col-12 bg-white">
                    <div className="col-12">
                      <div
                        className="m-auto max-w-max relative"
                        style={{ color: '#495057' }}
                      >
                        <sup
                          className="text-xl font-bold absolute"
                          style={{ left: '-25px' }}
                        >
                          R$
                        </sup>
                        <span className="text-6xl font-bold">699</span>
                        <sub className="text-4xl font-bold">.90</sub>
                        <sup className="text-xl font-bold text-primary absolute">
                          /mês
                        </sup>
                      </div>
                    </div>
                    <div className="col-12">
                      <ul className="list-none" style={{ color: '#495057' }}>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Vendas</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Inventário</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nf-e</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Boletos</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas pagar
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas receber
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte whatsapp
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte telefone
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Comissionados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nfc-e</span>
                          <span className="ml-2 text-primary font-medium">
                            80 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            PDV - Frente de caixa
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte remoto
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Consignados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Vitrine de produtos
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            100 produtos
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Performa +</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Afiliados </span>
                          <span className="ml-2 text-primary font-medium">
                            1 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            API - Integração
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">Replicador</span>
                          <span className="ml-2 text-primary font-medium">
                            4 rep/dia
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-xmark text-pink-600 text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Migração (free)
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Validar
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-12 cursor-pointer bg-primary border-round-bottom text-center"
                    onClick={() => {
                      setDialog(true);
                      sePlanSelected(item);
                    }}
                  >
                    <span className="select-none  font-semibold text-xl">
                      Contratar
                    </span>
                  </div>
                </div>
              );
            }
            if (item.name === 'Vibranium') {
              return (
                <div
                  className="col-12 md:col-3 sm:col-3 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter('fourh')}
                  onMouseLeave={() => handleMouseLeave('fourh')}
                  style={{
                    transform:
                      isHovering && cardEvent === 'fourh'
                        ? 'translateY(-15px)'
                        : 'translateY(0)',
                    transition: 'all 0.3s linear',
                  }}
                >
                  <div className="col-12 bg-primary border-round-top text-center select-none">
                    <span className="font-semibold text-2xl">Vibranium</span>
                  </div>
                  <div className="col-12 bg-white">
                    <div className="col-12">
                      <div
                        className="m-auto max-w-max relative"
                        style={{ color: '#495057' }}
                      >
                        <sup
                          className="text-xl font-bold absolute"
                          style={{ left: '-25px' }}
                        >
                          R$
                        </sup>
                        <span className="text-6xl font-bold">999</span>
                        <sub className="text-4xl font-bold">.90</sub>
                        <sup className="text-xl font-bold text-primary absolute">
                          /mês
                        </sup>
                      </div>
                    </div>
                    <div className="col-12">
                      <ul className="list-none" style={{ color: '#495057' }}>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Vendas</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Inventário</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitadas
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nf-e</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Boletos</span>
                          <span className="ml-2 text-primary font-medium">
                            40 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas pagar
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Contas receber
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte whatsapp
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte telefone
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Comissionados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Nfc-e</span>
                          <span className="ml-2 text-primary font-medium">
                            80 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            PDV - Frente de caixa
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Suporte remoto
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Consignados
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Vitrine de produtos
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            100 produtos
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Performa +</span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Afiliados </span>
                          <span className="ml-2 text-primary font-medium">
                            1 und
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            API - Integração
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Ilimitados
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">Replicador</span>
                          <span className="ml-2 text-primary font-medium">
                            4 rep/dia
                          </span>
                        </li>
                        <li className="mb-3">
                          <i className="fa-solid fa-check text-primary text-xl"></i>
                          <span className="ml-2 font-semibold">
                            Migração (free)
                          </span>
                          <span className="ml-2 text-primary font-medium">
                            Validar
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-12 cursor-pointer bg-primary border-round-bottom text-center"
                    onClick={() => {
                      setDialog(true);
                      sePlanSelected(item);
                    }}
                  >
                    <span className="select-none  font-semibold text-xl">
                      Contratar
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      <Dialog
        header="Assinatura"
        visible={dialog}
        style={{ width }}
        footer={renderFooter}
        onHide={() => setDialog(false)}
      >
        <Form
          onSubmit={signedPlan}
          ref={formRef}
          className="p-fluid grid formgrid"
        >
          <div className="field col-12 md:col-12">
            <span className="text-xl">
              Plano Escolhido: {planSelected?.name}
            </span>
          </div>
          <div className="field col-12 md:col-12">
            <span className="text-xl">Valor: {planSelected?.price_string}</span>
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="cupom">Possui Cupom?</label>
          </div>
          <div className="field col-12 md:col-12">
            <InputPassword
              placeholder="Seu cupom"
              name="cupom"
              value={cupom}
              onChange={e => setCupom(e.currentTarget.value)}
              feedback={false}
              toggleMask={true}
            />
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default Plans;
