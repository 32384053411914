export default function LimpaCpfCnpj(doc: string) {
  return doc
    .normalize('NFD')
    .replaceAll(/[^a-zA-Zs0-9]/g, '')
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('/', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '');
}
