import { useQuery } from 'react-query';
import { ICommissioned } from '../pages/comissioned/dtos/ICommissioned';
import api from '../services/api';

export default function useLoadCommissioneds() {
  const url = '/commissioneds';

  const { data, error, isLoading, isFetched } = useQuery<ICommissioned[]>(
    ['commissioneds', url],
    () => api.get(url).then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
