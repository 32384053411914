import { Tag } from 'primereact/tag';
import React from 'react';

const renderColumnSitutacao: React.FC = (field: any) => {
  switch (field) {
    case 'AUTORIZADA':
      return <Tag value={field} severity="success" className="px-3" />;
    case 'PROCESSANDO':
      return <Tag value={field} severity="info" className="px-3" />;
    case 'REJEITADO':
      return <Tag value={field} severity="warning" className="px-3" />;
    case 'DENEGADO':
      return <Tag value={field} severity="warning" className="px-3" />;
    case 'CANCELADO':
      return <Tag value={field} severity="danger" className="px-3" />;
    case 'CANCELADA':
      return <Tag value={field} severity="danger" className="px-3" />;
    default:
      return null;
  }
};

export default renderColumnSitutacao;
