import React from 'react';

/** sidebar primereact */
import { Sidebar, SidebarProps } from 'primereact/sidebar';

/** props sideBarprops */
interface SideBarProps extends SidebarProps {
  children: React.ReactElement[] | React.ReactElement;
}

export const SideBar = ({
  children,
  ...props
}: SideBarProps): React.ReactElement => {
  return <Sidebar {...props}>{children}</Sidebar>;
};
