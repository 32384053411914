export default [
  {
    label: 'Não informado',
    value: 0,
  },
  {
    label: 'Contribuinte ICMS',
    value: 1,
  },
  {
    label: 'Contribuinte isento de insc. no cad. de contribuintes de ICMS',
    value: 2,
  },
  {
    label: 'Não contribuinte',
    value: 9,
  },
];
