import { v4 } from 'uuid';
import { IProductRow } from '../../consigned/order/dtos/IConOrderDTO';
import { IRequestDTO } from '../order-sidebar-resume/dtos/IRequestDTO';

export const requestOrder: IRequestDTO = {
  orderHeader: {
    pedido_id: v4(),
    store_id: '',
    customer_id: '',
    customer_name: '',
    payment_condition_id: '',
    commissioned_id: '',
    natureza_operacao_id: '',
    order_origin_id: null,
    fit_descount: 0,
    discount_total: 0,
    fit_additional: 0,
    additional_total: 0,
    order_value: 0,
    order_total: 0,
    juros_total: 0,
    obs: null,
    doc_key: '',
  },
  orderBody: [],
  orderTransporter: {
    transporter_id: '',
    vehicle_plate: '',
    driver: '',
    delivery_date: new Date(),
    order_type: 'PED',
    kind_freight: 'FOB',
    quantity: 0,
    kind: '',
    brand: '',
    seal_number: '',
    weight_brute: 0,
    weight_liquid: 0,
    cubage: 0,
    obs: null,
    generate_promissory: false,
    emit_nfe: false,
    print_order: true,
  },
};

export const nullItem: IProductRow = {
  id: v4(),
  sku: '',
  pos: 0,
  stock: 0,
  table_price_id: '',
  table_price_description: '',
  product_id: '',
  product_description: '',
  value: '',
  lucre: 0,
  lucre_aliq: 0,
  label: '',
  qnt: 1,
  qnt_out: 2,
  price_out: 2,
  vlr_unit: 0,
  add: 0,
  add_aliq: 0,
  desc: 0,
  desc_aliq: 0,
  vlr_liq: 0,
  total: 0,
};
