import React, { useEffect, useRef } from 'react';

/* unform */
import { useField } from '@unform/core';

/* password primereact */
import { Password, PasswordProps } from 'primereact/password';

/* props inputText */
interface PropsInput extends PasswordProps {
  name: string;
}

export const InputPassword: React.FC<PropsInput> = ({
  name,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const header = <h6>Escolha uma senha</h6>;
  const footer = (
    <React.Fragment>
      <p className="mt-2">Sugestão</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>Pelo menos uma minúscula</li>
        <li>Pelo menos uma maiúscula</li>
        <li>Pelo menos um numérico</li>
        <li>Mínimo de 8 caracteres</li>
      </ul>
    </React.Fragment>
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Password
      placeholder={placeholder}
      name={name}
      defaultValue={defaultValue}
      header={header}
      footer={footer}
      inputRef={inputRef}
      {...rest}
      className={error ? 'p-invalid' : ''}
      autoComplete="new-password"
    />
  );
};
