import React, { useRef, useState, useEffect } from 'react';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** service */
import api from '../../../services/api';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** components */
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { Toast } from 'primereact/toast';

const StoreEdit = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** presential */
  const [presential, setPresential] = useState(true);

  /** useParams */
  const navigate = useHistory();
  const params: { id: string } = useParams();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleStore = async (data: any) => {
    try {
      const schemaReports = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
      });
      await schemaReports.validate(data, { abortEarly: false });
      api.put(`/stores/${params.id}`, {
        store: { ...data, presencial: presential },
      });
      navigate.push('/store/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get(`/stores/${params.id}`).then(({ data }) => {
      form.current?.setData({
        title: data.title,
        description: data.description,
        key: data.key,
        pass: data.pass,
        token: data.token,
      });
      setPresential(data.presencial);
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/store/list')}
      />
      <Toast ref={toast} />
      <div className="card md:w-6 md:w-full">
        <Form ref={form} onSubmit={handleStore}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Nome Loja</label>
              <InputText name="title" placeholder="Nome da Loja" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col flex align-items-center">
              <InputSwitch
                name="presencial"
                className="mr-3"
                checked={presential}
                onChange={e => setPresential(e.value)}
              />
              <label htmlFor="dropdown" className="m-0">
                Presencial?
              </label>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Descrição</label>
              <InputTextArea name="description" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Key</label>
              <InputText name="key" placeholder="ab_09sdf944fgf" />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Pass</label>
              <InputText name="pass" placeholder="ab_09sdf944fgf" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Token</label>
              <InputText name="token" placeholder="tk_sdfu023m" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default StoreEdit;
