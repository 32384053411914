import {
  Calendar,
  CalendarChangeParams,
  CalendarProps,
} from 'primereact/calendar';
import React from 'react';

interface TimeRangeProps extends CalendarProps {
  onInitialTimeChange: (value: Date) => void;
  onFinalTimeChange: (value: Date) => void;
  period: Date[];
  defaultInitialTime?: Date;
  defaultFinalTime?: Date;
  name: string;
}

const TimeRange = (props: TimeRangeProps): React.ReactElement => {
  const handleInitialTimeChange = (e: CalendarChangeParams) => {
    if (e.value && e.value !== null) {
      props.onInitialTimeChange(e.value as Date);
    }
  };

  const handleFinalTimeChange = (e: CalendarChangeParams) => {
    if (e.value && e.value !== null) {
      props.onFinalTimeChange(e.value as Date);
    }
  };

  return (
    <>
      <div className="p-inputgroup">
        <Calendar
          name={`${props.name}-initial`}
          value={props.period[0]}
          className={props.className}
          inputClassName="text-center"
          dateFormat="99:99"
          mask="99:99"
          placeholder="00:00"
          onChange={handleInitialTimeChange}
          timeOnly
          stepHour={1}
          stepMinute={15}
          maxDate={props.period[1]}
          disabled={props.disabled ? props.disabled : false}
        />
        <span
          className="p-inputgroup-addon"
          style={{ borderLeft: 0, borderRight: 0 }}
        >
          até
        </span>
        <Calendar
          name={`${props.name}-final`}
          value={props.period[1]}
          className={props.className}
          inputClassName="text-center"
          placeholder="23:59"
          dateFormat="99:99"
          mask="99:99"
          onChange={handleFinalTimeChange}
          timeOnly
          stepHour={1}
          stepMinute={15}
          minDate={props.period[0]}
          disabled={props.disabled ? props.disabled : false}
        />
      </div>
    </>
  );
};

export default TimeRange;