import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PaymentConditionsHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Listar"
        className="mb-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/payment-condition/list')}
      />
      <Button
        label="Nova"
        className="mb-2 ml-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/payment-condition/create')}
      />
    </>
  );
};

export default PaymentConditionsHeaderButtons;
