import { ReactNode } from 'react';
import { OrderServiceProvider } from './OrderServiceContext';
import { ParamsConsignedProvider } from './ParamsConsignedContext';
import { OrderProvider } from './OrderContext';

const ContextProviders = ({ children }: { children: ReactNode }) => {
  return (
    <OrderServiceProvider>
      <ParamsConsignedProvider>
        <OrderProvider>{children}</OrderProvider>
      </ParamsConsignedProvider>
    </OrderServiceProvider>
  );
};

export default ContextProviders;
