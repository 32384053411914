import React, { useEffect, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { parseToCnpj, parseToCpf } from '../../../utils/cpf_cnpj_format';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import renderColumnCpfCnpj from '../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnFlag from '../../../components/DataTableColumns/RenderColumnFlag';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import MenuPopUp from '../../../components/MenuPopPup';
import { Dialog } from '../../../components/Modal';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import { IUser } from '../../profile/dtos/IUser';
import TopMenu from '../top-menu';

const UserList = (): React.ReactElement => {
  const [load, setLoad] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const toast = useToastContext();

  const [users, setUsers] = useState<IUser[]>([]);

  const [user, setUser] = useState<any>();

  const router = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const profileBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.avatar_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.avatar_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const cpfOrCnpjBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.cpf_cnpj?.length > 11
            ? parseToCnpj(rowData.cpf_cnpj)
            : rowData.cpf_cnpj?.length <= 11
            ? parseToCpf(rowData.cpf_cnpj)
            : ''}
        </span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeleteUser = (user: any) => {
    setUser(user);
    setDeleteUserDialog(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => router.push(`/users/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </div>
    );
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const deleteUserApi = async () => {
    try {
      await api.delete(`/users/disable/${user.id}`);
      toast('success', 'Sucesso', 'Usuário desabilitado com sucesso!');
      await loadUsers();
    } catch (err: any) {
      toast('warn', 'Alerta', messageRequestError(err));
    }
  };

  const deleteUser = () => {
    const _users = users.filter(val => val.id !== user.id);
    setUsers(_users);
    setDeleteUserDialog(false);
    setUser(undefined);

    deleteUserApi();
  };

  const deleteUserDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUser}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Usuários cadastrados no sistema</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const loadUsers = async () => {
    setLoad(true);
    await api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A', 'C'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(data);
        }
      })
      .catch(err => toast('warn', 'Alerta', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  const mountMenu = (row: IUser) => {
    const items: any[] = [];

    items.push({
      label: 'Editar',
      icon: 'fa-solid fa-file-edit',
      command: () => router.push(`/users/edit/${row.id}`),
    });

    if (row.status !== 'C') {
      items.push({
        label: 'Desativar',
        icon: 'fa-solid fa-xmark',
        command: () => confirmDeleteUser(row),
      });
    }

    return items;
  };

  const renderCollumnMenu = (row: any) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={setUser} />
      </>
    );
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <TopMenu />
      <Progress isLoad={load} />
      <div className="card">
        <DataTable
          value={users}
          dataKey="id"
          loading={load}
          loadingIcon="pi pi-spinner text-primary"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} usuários"
          globalFilter={globalFilter}
          emptyMessage="Não existe usuários."
          header={header}
          responsiveLayout="scroll"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column header="Nome" field="name"></Column>
          <Column
            header="Cpf/Cnpj"
            field="name"
            body={r =>
              r.cpf_cnpj !== null ? renderColumnCpfCnpj(r.cpf_cnpj) : ''
            }
            align={'center'}
          ></Column>
          <Column
            field="key_user"
            header="Admin"
            body={r => renderColumnFlag(r.key_user)}
            align={'center'}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={r => renderColumnStatus(r)}
            align={'center'}
          ></Column>
          <Column
            header="*"
            body={e => renderCollumnMenu(e)}
            align={'center'}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteUserDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteUserDialogFooter}
        onHide={hideDeleteUserDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {user && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{user?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default UserList;
