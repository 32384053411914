import { Button } from "primereact/button"
import { useHistory } from "react-router-dom"

const HeaderButtons: React.FC = () => {

  const navigate = useHistory();

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="pi pi-plus"
        onClick={() => navigate.push('/boleto/v1/titulo/create')}
      />
      <Button
        label="Boletos"
        className="mb-3 ml-2"
        icon="fa-solid fa-money-check-dollar"
        onClick={() => navigate.push('/boleto/v1/titulo/list')}
      />
      <Button
        label="Remessas"
        className="mb-3 ml-2"
        icon="fa-solid fa-file-arrow-up"
        onClick={() => navigate.push('/boleto/v1/remessa/list')}
      />
    </>
  )
}

export default HeaderButtons;