import { useEffect, useState } from "react";
import OrderHeaderButtons from "../../order/header-buttons";
import { Form } from "@unform/web";
import CalendarRange from "../../../components/Inputs/CalendarRange";
import { Button } from "primereact/button";
import IOptionsDTO from "../../business/dtos/IOptionsDTO";
import api from "../../../services/api";
import useToastContext from "../../../hooks/toast";
import Progress from "../../../components/progress-bar";
import messageRequestError from "../../../utils/messageRequestError";
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subHours } from "date-fns";
import { Divider } from "../../../components/Divider";
import { Chart } from "primereact/chart";
import formatDecimal from "../../../utils/numbers/FormatDecimal";
import openReports from "../../../utils/openReports";

const ReportInputsByPaymentType = (): React.ReactElement => {

  const today = new Date();

  const [load, setLoad] = useState<boolean>(false);
  const toast = useToastContext();

  const [paymentConditions, setPaymentConditions] = useState<IOptionsDTO[]>([]);

  interface IFilter {
    period: Date[];
    paymentConditions: string[];
  }
  const emptyFilter: IFilter = {
    period: [startOfMonth(today), subHours(endOfMonth(today), 3)],
    paymentConditions: []
  }
  const [filter, setFilter] = useState<IFilter>(emptyFilter);

  interface IChartData {
    labels: Array<string>;
    datasets: {
      data: Array<number>;
      backgroundColor: Array<string>;
    }[];
  };
  const emptyChartData: IChartData = {
    labels: [],
    datasets: [
      { data: [], backgroundColor: [] }
    ]
  }
  const [chartData, setChartData] = useState<IChartData>(emptyChartData);
  const [total, setTotal] = useState<number>(0);

  interface IInputs {
    dinheiro: number;
    pix: number;
    cartao_credito: number;
    cartao_debito: number;
    boleto: number;
    deposito: number;
    cheque: number;
  }
  const emptyInputs: IInputs = {
    dinheiro: 0,
    pix: 0,
    cartao_credito: 0,
    cartao_debito: 0,
    boleto: 0,
    deposito: 0,
    cheque: 0,
  }

  const [inputs, setInputs] = useState<IInputs>(emptyInputs);

  interface IColors {
    dinheiro: string;
    pix: string;
    cartao_credito: string;
    cartao_debito: string;
    boleto: string;
    deposito: string;
    cheque: string;
  }
  const emptyColors: IColors = {
    dinheiro: '',
    pix: '',
    cartao_credito: '',
    cartao_debito: '',
    boleto: '',
    deposito: '',
    cheque: '',
  }
  const [colors, setColors] = useState<IColors>(emptyColors);

  useEffect(() => {
    Promise.all([
      loadPaymentConditions(),
      loadInputs()
    ])
  }, []);

  useEffect(() => {
    mountChartInputs();
  }, [inputs]);

  const mountChartInputs = () => {
    const chartData: IChartData = emptyChartData;
    const colors: IColors = emptyColors;

    if (inputs) {

      for (const [key, value] of Object.entries(inputs)) {

        const colorRandom = `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
          Math.random() * 255,
        )},${Math.floor(Math.random() * 255)})`

        if (key === 'dinheiro') {
          colors.dinheiro = colorRandom;
          chartData.labels.push('Dinheiro');
        }
        else if (key === 'pix') {
          colors.pix = colorRandom;
          chartData.labels.push('PIX');
        }
        else if (key === 'cartao_credito') {
          colors.cartao_credito = colorRandom;
          chartData.labels.push('Cartão crédito');
        }
        else if (key === 'cartao_debito') {
          colors.cartao_debito = colorRandom;
          chartData.labels.push('Cartão débito');
        }
        else if (key === 'boleto') {
          colors.boleto = colorRandom;
          chartData.labels.push('Boleto');
        }
        else if (key === 'deposito') {
          colors.deposito = colorRandom;
          chartData.labels.push('Deposito bancário');
        }
        else if (key === 'cheque') {
          colors.cheque = colorRandom;
          chartData.labels.push('Cheque');
        }

        chartData.datasets[0].data.push(Number(Number(value).toFixed(2)));
        chartData.datasets[0].backgroundColor.push(colorRandom)

      }

      setChartData(chartData);
      setColors(colors);

    }
  }

  const loadInputs = async () => {
    setLoad(true);

    await api.post(
      '/register-cash-movimentations/v2/search-inputs/by-payment-type',
      {
        period: [startOfDay(filter.period[0]), endOfDay(filter.period[1])],
      }
    ).then(({ data }) => {
      if (data) {
        setTotal(data.total);
        delete data.total;
        setInputs(data)
      }
    }).catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false))

  }

  const printReport = async () => {
    setLoad(true);

    await api.post(
      '/reports/inputs/by-payment-type',
      {
        period: filter.period,
      }
    ).then(({ data }) => {
      if (data) {
        openReports(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
          'resumo-entradas'
        )
      }
    }).catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false))

  }

  const loadPaymentConditions = async () => {
    setLoad(true);

    await api.get('/payments-condition')
      .then(({ data }) => {
        if (data) {
          setPaymentConditions(data.map((p: any) => {
            return {
              label: p.title,
              value: p.id
            }
          }))
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false))
  }

  return (
    <>
      <OrderHeaderButtons />
      <Progress isLoad={load} className="md:w-8" />
      <Form
        ref={null}
        onSubmit={loadInputs}
        className="card md:w-8 p-fluid grid formgrid mt-2"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >

        <div className="field col-12 md:col-6 mt-4">
          <label htmlFor="period">Período</label>
          <CalendarRange
            name="period"
            value={filter.period}
            onChange={e => setFilter({ ...filter, period: e.value as Date[] })}
            placeholder="00/00/00 ~ 00/00/00"
          />
        </div>

        <div className="field col-6 md:col-3 mt-2 flex align-items-end">
          <Button
            name="btn_print"
            type="button"
            label="Imprimir"
            loading={load}
            icon="fa-solid fa-print"
            onClick={printReport}
          />
        </div>

        <div className="field col-6 md:col-3 mt-2 flex align-items-end">
          <Button
            name="btn_submit"
            type="submit"
            label="Buscar"
            loading={load}
            icon="fa-solid fa-search"
            className="p-button-success"
          />
        </div>

        <div className="field col-12 md:col-6">
          <Divider align="left" className="my-2">
            <div className="inline-flex align-items-center">
              <i
                className="fa-solid fa-chart-simple mr-2"
                style={{ color: 'var(--primary-color)' }}
              ></i>
              <b>Gráfico</b>
            </div>
          </Divider>

          {total > 0 && (
            <div className="flex justify-content-center">

              <Chart type="pie" data={chartData} className="w-6" options={{
                plugins: {
                  legend: {
                    display: false
                  },
                },
              }} />
            </div>
          )}

          {total === 0 && (
            <div className="h-full flex justify-content-center align-items-center">
              Nenhuma entrada encontrada...
            </div>
          )}

        </div>

        <div className="field col-12 md:col-6">
          <Divider align="left" className="my-2">
            <div className="inline-flex align-items-center">
              <i
                className="fa-solid fa-circle-info mr-2"
                style={{ color: 'var(--primary-color)' }}
              ></i>
              <b>Informações</b>
            </div>
          </Divider>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.dinheiro}` }}></i> Entradas em dinheiro</div>
              <div>
                {formatDecimal(inputs.dinheiro)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.pix}` }}></i>Entradas em pix</div>
              <div>
                {formatDecimal(inputs.pix)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.cartao_credito}` }}></i>Entradas em cartão de crédito</div>
              <div>
                {formatDecimal(inputs.cartao_credito)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.cartao_debito}` }}></i>Entradas em cartão de débito</div>
              <div>
                {formatDecimal(inputs.cartao_debito)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.boleto}` }}></i>Entradas em boleto</div>
              <div>
                {formatDecimal(inputs.boleto)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.cheque}` }}></i>Entradas em cheque</div>
              <div>
                {formatDecimal(inputs.cheque)}
              </div>
            </div>

            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div><i className="pi pi-circle-fill mr-2" style={{ fontSize: '20px', color: `${colors.deposito}` }}></i>Entradas em depósito bancario</div>
              <div>
                {formatDecimal(inputs.deposito)}
              </div>
            </div>

            <div className="field col-12 md:col-12"></div>
            <div className="field col-12 md:col-12 flex align-items-center justify-content-between">
              <div className="text-800 font-bold">
                Total
              </div>

              <div
                style={{ borderTop: '1px solid #666666' }}
                className="text-800 font-bold"
              >
                {formatDecimal(total)}
              </div>
            </div>
          </div>
        </div>

      </Form>
    </>
  )
}

export default ReportInputsByPaymentType;