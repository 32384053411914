import React from 'react';

import {
  TabView,
  TabPanel as TabPanels,
  TabViewProps,
  TabPanelProps,
} from 'primereact/tabview';

/** interface tabView */
interface TabViewProp extends TabViewProps {
  children: React.ReactElement[];
}

interface TabPanelProp extends TabPanelProps {
  children: React.ReactElement;
}

export const TabViews = ({
  children,
  ...props
}: TabViewProp): React.ReactElement => {
  return <TabView {...props}>{children}</TabView>;
};

export const TabPanel = ({
  children,
  ...props
}: TabPanelProp): React.ReactElement => {
  return <TabPanels {...props}>{children}</TabPanels>;
};
