import { cnpj, cpf } from 'cpf-cnpj-validator';
import React from 'react';

const renderColumnCpfCnpj: React.FC<string | undefined | null> = cpfCnpj => {
  const doc =
    cpfCnpj !== undefined && cpfCnpj !== null
      ? cpfCnpj.length === 11
        ? cpf.format(cpfCnpj)
        : cnpj.format(cpfCnpj)
      : '***';
  return <>{doc}</>;
};

export default renderColumnCpfCnpj;
