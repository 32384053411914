/* eslint-disable react/prop-types */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import FormFooter from '../../../components/FormFooter';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import VariationItemsHeader from '../header';

import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

const VariationItemsCreate = (): React.ReactElement => {
  /** const */
  const chooseOptions = {
    icon: 'pi pi-plus',
    label: 'Escolher Imagem',
    className: 'custom-choose-btn',
  };

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const fileUploadRef = useRef<any>(null);
  const toast = useToastContext();
  const routes = useHistory();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [variationsOpts, setVariationsOpts] = useState<Array<IOptionsDTO>>([]);
  const [variation, setVariation] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [qnt, setQnt] = useState<number>(1);
  const [value, setValue] = useState<number>(0);

  const [totalSize, setTotalSize] = useState<number>(0);

  /** functions */
  const listVariations = async () => {
    setLoadingStatus(true);
    await api
      .get('/variations')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setVariationsOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar as variações!');
      })
      .finally(() => setLoadingStatus(false));
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const handleSubmit = async (d: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      d.qnt = qnt;
      d.value = value;

      const validation = Yup.object({
        variation_id: Yup.string()
          .uuid('O id da variação pai é inválido!')
          .required('O id da variação pai é obrigatório!'),
        title: Yup.string().required('Informe o título da variação filha!'),
        qnt: Yup.number().required('Informe uma quantidade!'),
        value: Yup.number().required('Informe um valor!'),
        description: Yup.string().nullable(),
      });

      await validation.validate(d, { abortEarly: false });

      const res = await api.post('/variations-itens', {
        variation_id: variation,
        title: title,
        image_url: null,
        description: description || '',
        unt: qnt || 0,
        value: Number(value) || 0,
        status: 'A',
      });

      if (fileUploadRef.current.files) {
        const form = new FormData();
        form.append('image_url', fileUploadRef.current.files[0]);
        await api.patch(`/variations-itens/image/${res.data.id}`, form);
      }

      toast('success', 'Sucesso', 'Variação filha criada com sucesso!');
      routes.push('/variations-items/list');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', e.response?.data.validation.body.message);
        }
      }

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** renders */
  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    if (props.files.length > 1) {
      props.files[0] = props.files[1];

      onTemplateRemove(file, props.onRemove);
    }

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };
  /** useEffects */
  useEffect(() => {
    listVariations();
  }, []);

  return (
    <>
      <VariationItemsHeader />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="w-6"
        />
      )}
      <Form ref={formRef} onSubmit={e => handleSubmit(e)} className="card w-6">
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <label htmlFor="variation_id">Variação pai</label>
            <InputDropDown
              name="variation_id"
              options={variationsOpts}
              value={variation}
              onChange={e => setVariation(e.value)}
              placeholder="Selecionar..."
            />
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="title">Título</label>
            <InputText
              name="title"
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
              placeholder="Título do item"
              maxLength={16}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="qnt">Quantidade</label>
            <InputNumber
              name="qnt"
              value={qnt}
              onChange={e => setQnt(Number(e.value))}
              showButtons
              buttonLayout="horizontal"
              placeholder="0"
              min={1}
              max={999}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="value">Valor</label>
            <InputNumber
              name="value"
              placeholder="0,00"
              value={value}
              onChange={e => setValue(Number(e.value))}
              minFractionDigits={2}
              maxFractionDigits={2}
            />
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="description">
              Descrição{' '}
              {description.length > 0
                ? `${description.length + 1} caracteres`
                : '0 caracteres'}
            </label>
            <InputTextEdit
              name="description"
              value={description as string}
              onTextChange={e => {
                if (e.htmlValue) {
                  if (e.htmlValue.length <= 32) {
                    setDescription(e.htmlValue as string);
                  }
                }
              }}
              style={{ height: 100 }}
            />
          </div>

          <div className="field col-12 md:col">
            <FileUpload
              ref={fileUploadRef}
              name="file"
              maxFileSize={100000}
              multiple={false}
              accept="image/*"
              onError={onTemplateClear}
              onClear={onTemplateClear}
              headerTemplate={headerTemplate}
              chooseOptions={chooseOptions}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              invalidFileSizeMessageSummary=""
              invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
            />
          </div>
        </div>

        <FormFooter key={null} loadingStatus={loadingStatus} />
      </Form>
    </>
  );
};

export default VariationItemsCreate;
