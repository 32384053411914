import { endOfMonth, startOfMonth } from 'date-fns';

export interface IOsList {
  id: string;
  customer: string;
  customer_id: string;
  contract_id: string;
  date_close: Date;
  date_open: Date;
  date_prevision: Date;
  kind_atendimento: string;
  os_number: number;
  os_status: string;
  color: string;
  os_status_id: string;
  priority: string;
  status: string;
  total: number;
  total_paied: number;
  close: boolean;
  os_registered: boolean;
  object_identifiers: string[];
}

export interface IFilter {
  keyword: string;
  os_number: number;
  kind_atendimento: string;
  period_open: Date[];
  period_prevision: Date[] | undefined;
  priority: string[];
  status_os: string[];
  total_range: number[];
  status?: string;
}

export const emptyFilter: IFilter = {
  keyword: '',
  os_number: 0,
  kind_atendimento: '',
  period_open: [startOfMonth(new Date()), endOfMonth(new Date())],
  period_prevision: undefined,
  priority: [],
  status_os: [],
  total_range: [0, 100000],
};
