import React, { useEffect, useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from '../../../components/DataTable';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import ReportTagHeaderMenu from '../tag/header-menu';

const tagModel: Array<IOptionsDTO> = [
  {
    label: '1 coluna',
    value: '74abd790-2ab8-11ed-91fc-eb953f6deb09',
  },
  {
    label: '2 colunas',
    value: '8a88f08e-2ab8-11ed-8346-23855b0c388d',
  },
  {
    label: '3 colunas',
    value: '8b2933aa-2ab8-11ed-bf87-cbbe7590b5ff',
  },
];

const ReportTicket: React.FC = (): React.ReactElement => {
  /** const */

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useState */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [products, setProducts] = useState([]);
  const [product, setSearchProduct] = useState<string>('');

  const [productTable, setProductTable] = useState<Array<any>>([]);

  const [qnt, setQnt] = useState<number>(1);

  const [tablePrice, setTablePrice] = useState<Array<IOptionsDTO>>([]);
  const [selectedTablePrice, setSelectedTablePrice] = useState<
    string | undefined
  >(undefined);
  const [productsId, setProductsId] = useState<Array<any>>([]);

  const [selectedTagModel, setSelectedTagModel] = useState<string>(
    tagModel[2].value,
  );

  const [showPrice, setShowPrice] = useState<boolean>(true);

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.titulo,
        value: item.id,
        figure: item.image_url,
        sku: item.sku,
        code: item.codigo,
      };
    });
  };

  const deleteProduct = (id: string) => {
    const filterProduct = productTable.filter((item: any) => item.value !== id);
    const filterProductId = filterProduct.map(item => item.value);
    setProductTable(filterProduct);
    setProductsId(filterProductId);
  };

  const selectProduct = (e: IOptionsDTO) => {
    setProductTable([...productTable, e]);
    setProductsId([...productsId, e.value]);
    setSearchProduct('');
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => deleteProduct(rowData.value)}
        />
      </div>
    );
  };

  const searchProduct = async (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      setLoadingStatus(true);
      await api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const opts = ammountOptions(data.products);
          if (opts.length <= 0) {
            toast('error', 'Error', 'Nenhum produto encontrado!');
            return;
          }

          setProducts(opts);
        })
        .catch(error => toast('error', 'Error', error.response.data.error))
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleReports = async (data: any) => {
    try {
      if (showPrice === true) {
        const schemaReports = Yup.object({
          tablePriceId: Yup.string().required(
            'Por favor, informe a tabela de preço!',
          ),
          tagModel: Yup.string().required(
            'Por favor, informe o model da etiqueta!',
          ),
        });
        await schemaReports.validate(data, { abortEarly: false });
      }

      if (!productsId.length) {
        formRef.current?.setFieldError('product_id', 'error');
        toast('error', 'Error', 'Por favor, informe pelo meno 1 produto!');
        return;
      }

      const res = await api.post(`/reports/tags`, {
        products: productsId,
        showPrices: showPrice,
        qnt: qnt,
        tagModel: data.tagModel,
        tablePriceId: data.tablePriceId,
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Etiquetas geradas com sucesso!');

        window.open(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${res.data.fileName}`,
        );
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        const optTablePrice = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setTablePrice(optTablePrice);
      });
  }, []);

  return (
    <>
      <ReportTagHeaderMenu />
      <div className="card md:w-6 sm:w-full">
        <Form ref={formRef} onSubmit={d => handleReports(d)}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12 ">
              <label htmlFor="basic">Produto</label>
              <InputAutoComplete
                name="product_id"
                value={product}
                completeMethod={e => searchProduct(e)}
                suggestions={products}
                field="label"
                onSelect={e => selectProduct(e.value)}
                onChange={e => setSearchProduct(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar produto..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <DataTable
                value={productTable}
                selectionMode="single"
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                responsiveLayout="scroll"
                emptyMessage="Nenhum produto selecionado!"
              >
                <Column field="code" header="#"></Column>
                <Column field="label" header="Nome"></Column>
                <Column field="code" header="Cod"></Column>
                <Column
                  header="Ações"
                  headerStyle={{ width: '10%', minWidth: '10rem' }}
                  body={actionBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-5 flex align-items-center">
              <label className="mr-3">Mostrar preços</label>
              <InputSwitch
                name="showPrices"
                checked={showPrice}
                onChange={e => setShowPrice(e.value)}
              />
            </div>
            <div className="field col-12 md:col-7 flex align-items-center">
              <label className="mr-3">Qnt. Etiquetas</label>
              <InputNumber
                inputId="horizontal"
                value={qnt}
                onValueChange={e => setQnt(e.value as number)}
                showButtons
                name="qnt"
                buttonLayout="horizontal"
                step={1}
                max={100}
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                mode="decimal"
                currency="BRL"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="">Tabela preço</label>
              <InputDropDown
                name="tablePriceId"
                options={tablePrice}
                value={selectedTablePrice}
                disabled={!showPrice}
                style={{ background: !showPrice ? 'var(--surface-200)' : '' }}
                onChange={e => setSelectedTablePrice(e.value)}
                placeholder="Selecionar..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="">Modelo Etiqueta</label>
              <InputDropDown
                name="tagModel"
                options={tagModel}
                value={selectedTagModel}
                onChange={e => setSelectedTagModel(e.value)}
                placeholder="Selecionar..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <Button
                label="Imprimir"
                icon="fa-solid fa-print"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportTicket;
