export type AppSetting = {
  menuType: string;
  colorScheme: string;
  inputStyle: string;
  rippleEffect: string;
  logoColor: string;
  menuThemes: string;
  componentThemes: string;
};

export const initialState: AppSetting = {
  menuType: '',
  colorScheme: '',
  inputStyle: '',
  rippleEffect: '',
  logoColor: '',
  menuThemes: '',
  componentThemes: '',
};

export type ActionProps = {
  payload: AppSetting;
  type: string;
};

export const SettingReducerApp = (
  state: AppSetting,
  action: ActionProps,
): AppSetting => {
  switch (action.type) {
    case 'setMenuType':
      return { ...state, menuType: action.payload.menuType };
    case 'setColorScheme':
      return { ...state, colorScheme: action.payload.colorScheme };
    case 'setInputStyle':
      return { ...state, inputStyle: action.payload.inputStyle };
    case 'setLogoColor':
      return { ...state, logoColor: action.payload.logoColor };
    case 'setRippleEffect':
      return { ...state, rippleEffect: action.payload.rippleEffect };
    case 'setMenuThemes':
      return { ...state, menuThemes: action.payload.menuThemes };
    case 'setComponentThemes':
      return { ...state, componentThemes: action.payload.componentThemes };
  }
  return state;
};
