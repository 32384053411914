import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IInventoryItemDTO from '../dtos/IInventoryItemDTO';
import InventoryHeaderButtons from '../header-buttons';

const InventoryOpen = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const orderByOpts: IOptionsDTO[] = [
    {
      label: 'Id do produto',
      value: 'ID',
    },
    {
      label: 'Descrição do produto',
      value: 'DE',
    },
    {
      label: 'Sequência de inserção',
      value: 'SE',
    },
  ];
  const [orderBy, setOrderBy] = useState<string>('');

  const selectByOpts: IOptionsDTO[] = [
    {
      label: 'Produto',
      value: 'product',
    },
    {
      label: 'Grupo',
      value: 'group',
    },
    {
      label: 'Tag',
      value: 'tag',
    },
    {
      label: 'Marca',
      value: 'brand',
    },
    {
      label: 'Todos',
      value: 'all',
    },
  ];
  const [selectBy, setSelectBy] = useState<string>('');

  const [groupOpts, setGroupOpts] = useState<IOptionsDTO[]>([]);
  const [group, setGroup] = useState<string>('');

  const [tagOpts, setTagOpts] = useState<IOptionsDTO[]>([]);
  const [tag, setTag] = useState<string>('');

  const [brandOpts, setBrandOpts] = useState<IOptionsDTO[]>([]);
  const [brand, setBrand] = useState<string>('');

  const [productOpts, setProductOpts] = useState<IInventoryItemDTO[]>([]);
  const [product, setProduct] = useState<IInventoryItemDTO>({
    id: '',
    sequence: 0,
    stock: 0,
    sku: '',
    description: '',
    und: '',
  });

  const [inventoryItens, setInventoryItens] = useState<IInventoryItemDTO[]>([]);

  /** useEffects */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/groups')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setGroupOpts(opts);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/tags')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            value: i.id,
            label: i.tag,
          };
        });

        setTagOpts(opts);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/brands')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setBrandOpts(opts);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  /** functions */
  const handleSubmit = async () => {
    try {
      formRef.current?.setErrors({});
      setLoadingStatus(true);

      if (inventoryItens.length === 0) {
        toast(
          'warn',
          'Alerta',
          'Informe pelo menos 1 item para ser inventáriado!',
        );
        return;
      }

      const res = await api.post('/inventory/open', {
        order_by: orderBy,
        items: inventoryItens,
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Inventário aberto com sucesso!');

        navigate.push('/inventory/digitacao');
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      setLoadingStatus(true);

      await api
        .get(`/products/v2/search?src=${e.query}`)
        .then(({ data }) => {
          const opts = data.products.map((i: any) => {
            return {
              id: i.id,
              sequence: 0,
              sku: i.sku,
              und: i.und,
              stock: Number(i.estoque),
              description: i.titulo,
            };
          });

          setProductOpts(opts);
        })
        .catch(e => {
          toast('error', 'Erro', e.response.data.error);
          return;
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleAddProduct = async (e: any) => {
    if (e.id === '') {
      toast('warn', 'Alerta', 'Produto não informado!');
      return;
    }

    if (!validate(e.id)) {
      toast('warn', 'Alerta', 'O id do produto é inválido!');
      return;
    }

    const checkProductAdd = inventoryItens.find(i => i.id === e.id);
    if (checkProductAdd) {
      toast('warn', 'Alerta', 'Produto já adicionado!');
      return;
    }

    setInventoryItens([
      ...inventoryItens,
      {
        id: e.id,
        sequence: inventoryItens.length + 1,
        sku: e.sku,
        stock: e.stock,
        und: e.und,
        description: e.description,
      },
    ]);
    setProduct({
      id: '',
      sequence: 0,
      sku: '',
      stock: 0,
      description: '',
      und: '',
    });
    setProductOpts([]);
  };

  const handleAddProductsAll = async () => {
    setLoadingStatus(true);
    await api
      .get(`/products/show/table`)
      .then(({ data }) => {
        const p = data.map((i: any, index: number) => {
          return {
            id: i.id,
            sequence: inventoryItens.length + (index + 1),
            sku: i.sku,
            und: i.unity,
            stock: Number(i.stock),
            description: i.title,
          };
        });
        setInventoryItens(p);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleAddProductsByGroup = async (e: any) => {
    setLoadingStatus(true);
    await api
      .get(`/products/li/group/${e}`)
      .then(({ data }) => {
        const p = data.map((i: any, index: number) => {
          return {
            id: i.product.id,
            sequence: inventoryItens.length + (index + 1),
            sku: i.product.sku,
            und: i.product.unity,
            stock: Number(i.product.stock),
            description: i.product.title,
          };
        });
        setInventoryItens(p);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleAddProductsByTag = async (e: any) => {
    setLoadingStatus(true);
    await api
      .get(`/products/li/tag/${e}`)
      .then(({ data }) => {
        const p = data.map((i: any, index: number) => {
          return {
            id: i.product.id,
            sequence: inventoryItens.length + (index + 1),
            sku: i.product.sku,
            und: i.product.unity,
            stock: Number(i.product.stock),
            description: i.product.title,
          };
        });
        setInventoryItens(p);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleAddProductsByBrand = async (e: any) => {
    setLoadingStatus(true);
    await api
      .get(`/products/li/brand/${e}`)
      .then(({ data }) => {
        const p = data.map((i: any, index: number) => {
          return {
            id: i.product.id,
            sequence: inventoryItens.length + (index + 1),
            sku: i.product.sku,
            und: i.product.unity,
            stock: Number(i.product.stock),
            description: i.product.title,
          };
        });
        setInventoryItens(p);
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleRemProduct = async (id: string) => {
    const itensNoId = inventoryItens.filter(i => i.id !== id);
    setInventoryItens(itensNoId);
  };

  const clearForm = () => {
    setLoadingStatus(false);
    setOrderBy('');
    setSelectBy('');
    setGroup('');
    setTag('');
    setBrand('');
    setProduct({
      id: '',
      description: '',
      sku: '',
      stock: 0,
      sequence: 0,
      und: '',
    });
    setProductOpts([]);
    setInventoryItens([]);
  };

  /** renders */
  const itemTemplate = (item: IInventoryItemDTO) => {
    return (
      <div className="country-item">
        <div>{item.description}</div>
      </div>
    );
  };

  const buttonRemItemTable = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemProduct(id)}
          type="button"
        />
      </div>
    );
  };

  return (
    <>
      <InventoryHeaderButtons />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card col-12 md:col-12">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="p-fluid grid formgrid"
          placeholder={''}
        >
          <div className="field col-3">
            <label htmlFor="order_by">Ordenar por</label>
            <InputDropDown
              name="order_by"
              options={orderByOpts}
              value={orderBy}
              onChange={e => setOrderBy(e.value)}
              placeholder="Selecionar..."
            />
          </div>

          <div className="field col-3">
            <label htmlFor="select_by">Selecionar por</label>
            <InputDropDown
              name="select_by"
              options={selectByOpts}
              value={selectBy}
              onChange={e => setSelectBy(e.value)}
              placeholder="Selecionar..."
              disabled={orderBy === '' ? true : false}
            />
          </div>

          {selectBy === 'product' && (
            <>
              <div className="field col-3">
                <label htmlFor="product_id">Produto</label>
                <InputAutoComplete
                  name="product_id"
                  value={product}
                  completeMethod={e => searchProduct(e)}
                  suggestions={productOpts}
                  field="description"
                  onChange={e => setProduct(e.value)}
                  itemTemplate={itemTemplate}
                  placeholder="Buscar produto..."
                />
              </div>

              <div className="field col-3 flex align-items-end">
                <Button
                  icon="pi pi-plus "
                  label="Adicionar"
                  type="button"
                  className="w-6 p-button-info mx-1"
                  onClick={() => handleAddProduct(product)}
                />
                <Button
                  icon="pi pi-times"
                  label="Limpar"
                  type="button"
                  onClick={() => clearForm()}
                  className="w-6 p-button-danger mx-1"
                />
              </div>
            </>
          )}

          {selectBy === 'group' && (
            <>
              <div className="field col-3">
                <label htmlFor="group_id">Grupo</label>
                <InputDropDown
                  name="group_id"
                  options={groupOpts}
                  value={group}
                  onChange={e => setGroup(e.value)}
                  placeholder="Selecionar..."
                />
              </div>

              <div className="field col-3 flex align-items-end">
                <Button
                  icon="pi pi-plus "
                  label="Adicionar"
                  type="button"
                  className="w-6 p-button-info mx-1"
                  onClick={() => handleAddProductsByGroup(group)}
                />
                <Button
                  icon="pi pi-times"
                  label="Limpar"
                  onClick={() => clearForm()}
                  className="w-6 p-button-danger mx-1"
                />
              </div>
            </>
          )}

          {selectBy === 'tag' && (
            <>
              <div className="field col-3">
                <label htmlFor="tag_id">Tag</label>
                <InputDropDown
                  name="tag_id"
                  options={tagOpts}
                  value={tag}
                  onChange={e => setTag(e.value)}
                  placeholder="Selecionar..."
                />
              </div>

              <div className="field col-3 flex align-items-end">
                <Button
                  icon="pi pi-plus "
                  label="Adicionar"
                  type="button"
                  className="w-6 p-button-info mx-1"
                  onClick={() => handleAddProductsByTag(tag)}
                />
                <Button
                  icon="pi pi-times"
                  label="Limpar"
                  onClick={() => clearForm()}
                  className="w-6 p-button-danger mx-1"
                />
              </div>
            </>
          )}

          {selectBy === 'brand' && (
            <>
              <div className="field col-3">
                <label htmlFor="brand_id">Marca</label>
                <InputDropDown
                  name="brand_id"
                  options={brandOpts}
                  value={brand}
                  onChange={e => setBrand(e.value)}
                  placeholder="Selecionar..."
                />
              </div>

              <div className="field col-3 flex align-items-end">
                <Button
                  icon="pi pi-plus "
                  label="Adicionar"
                  type="button"
                  className="w-6 p-button-info mx-1"
                  onClick={() => handleAddProductsByBrand(brand)}
                />
                <Button
                  icon="pi pi-times"
                  label="Limpar"
                  onClick={() => clearForm()}
                  className="w-6 p-button-danger mx-1"
                />
              </div>
            </>
          )}

          {selectBy === 'all' && (
            <>
              <div className="field col-3 flex align-items-end">
                <Button
                  icon="pi pi-plus "
                  label="Adicionar"
                  type="button"
                  className="w-6 p-button-info mx-1"
                  onClick={() => handleAddProductsAll()}
                />
                <Button
                  icon="pi pi-times"
                  label="Limpar"
                  onClick={() => clearForm()}
                  className="w-6 p-button-danger mx-1"
                />
              </div>
              <div className="field col-12 md:col-12">
                <Message
                  severity="warn"
                  text="Somente produtos ativos serão listados para inventário!"
                />
              </div>
            </>
          )}

          <DataTable
            value={inventoryItens}
            responsiveLayout="scroll"
            emptyMessage="Nenhum inventário para listar."
            paginator
            rows={100}
            rowsPerPageOptions={[100, 150, 200]}
            size="small"
            className="field col-12 md:col-12"
            loading={loadingStatus}
          >
            <Column header="#" body={renderColumnPosition} />
            <Column field="sku" header="SKU" />
            <Column field="description" header="DESCRIÇÃO" />
            <Column field="und" header="UND" />
            <Column
              field="stock"
              header="ESTOQUE"
              body={row => renderColumnDecimal(row.stock)}
            />
            <Column header="*" body={e => buttonRemItemTable(e.id)} />
          </DataTable>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => clearForm()}
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={loadingStatus ? 'Aguarde...' : 'Abrir'}
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
              disabled={inventoryItens.length === 0 ? true : false}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default InventoryOpen;
