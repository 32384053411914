import React from 'react';

const renderResumeText = (
  text: string | null,
  size: number,
): React.ReactElement => {
  if (text) {
    if (text.length <= size) {
      return <>{text}</>;
    } else {
      return <>{`${text.slice(0, size)}...`}</>;
    }
  } else {
    return <></>;
  }
};

export default renderResumeText;
