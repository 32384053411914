import { validate } from 'uuid';

export default function GetUUIDUrl(url: string): string {
  const equalPosition = url.indexOf('=');
  const urlIdLength = url.length;
  const id = url.slice(equalPosition + 1, urlIdLength);
  if (!validate(id)) {
    return 'fail-read-url';
  }

  return id;
}
