export interface IList {
  id: string;
  sequence: number;
  customer_id: string;
  commissioned_id: string;
  date_digitation: Date;
  date_sell: Date;
  nat_ope_id: string;
  order_type: string;
  invoiced: boolean;
  nfe_emited: boolean;
  discount: number;
  additional: number;
  fees: number;
  qnt_products: number;
  qnt_itens: number;
  total_comission: number;
  order_value: number;
  order_total: number;
  expire_day: Date;
  checked: boolean;
  obs: string;
  user_id: string;
  status: string;
  route: {
    id: string;
    sequence: number;
  };
  customer: {
    id: string;
    codigo: number;
    customer: string;
    name: string;
    cpf_cnpj: string;
    block: string;
    image_url: string;
    status: string;
    last_transaction: Date;
    type: string;
  };
  user: {
    id: string;
    name: string;
    status: string;
  };
}

export interface ISearchResponse {
  id: string;
  route: number;
  sequence: number;
  customer_id: string;
  customer_name: string;
  date_digitation: Date;
  date_sell: Date;
  order_type: string;
  invoiced: boolean;
  nfe_emited: boolean;
  order_total: number;
  checked: boolean;
  status: string;
  user_id: string;
  user_name: string;
}

export interface IProductRow {
  id: string;
  pos: number;
  stock: number;
  sku: string;
  table_price_id: string;
  table_price_description: string;
  product_id: string;
  product_description: string;
  image_url: string | null;
  obs?: string | null;

  lucre: number | null;
  lucre_aliq: number | null;

  qnt: number;
  qnt_out: number;
  vlr_unit: number;
  price_out: number;

  adic: number;
  adic_aliq: number | null;

  desc: number;
  desc_aliq: number | null;

  vlr_liq: number;
  total: number;

  value: string;
  label: string;
}

export const emptyProductRow: IProductRow = {
  id: '',
  pos: 0,
  stock: 0,
  sku: '',
  table_price_id: '',
  table_price_description: '',
  product_id: '',
  value: '',
  product_description: '',
  label: '',
  image_url: null,
  lucre: null,
  lucre_aliq: null,
  qnt: 0,
  qnt_out: 0,
  vlr_unit: 0,
  price_out: 0,
  adic: 0,
  adic_aliq: null,
  desc: 0,
  desc_aliq: null,
  vlr_liq: 0,
  total: 0,
  obs: null,
};

export interface IProductRowOs extends IProductRow {
  obs: string;
}

export interface IConOrderPayment {
  id: string;
  pos: number;
  con_order_id?: string;
  payment_id: string;
  payment: string;
  serie: string;
  parcel: number;
  total: number;
}

interface IProduct {
  id: string;
  codigo: number;
  sku: string;
  ean: string;
  title: string;
  unity: string;
  block: boolean;
  stock: number;
  stock_location: string;
  cost_billing: number;
  gain: number;
  decimalHouses?: {
    id: string;
    product_id: string;
    qnt_in: number;
    qnt_out: number;
    price_in: number;
    price_out: number;
    user_id: string;
    status: string;
  };
  prices?: {
    id: string;
    table_price_id: string;
    tablePrice: {
      id: string;
      title: string;
    };
    product_id: string;
    vlr_venda: number;
    vlr_venda_min_s_lib: number;
    vlr_venda_min_c_lib: number;
    qnt_venda_min: number;
    qnt_venda_max: number;
    comissao: number;
  }[];
}

export interface IConOrderItem {
  id: string;
  pos: number;
  con_order_id: string;
  table_price_id: string;
  product_id: string;
  product: {
    title: string;
  };
  qnt: number;
  vlr_unit: number;
  add: number;
  desc: number;
  vlr_liq: number;
  total: number;
  checked: boolean;
  user_id?: string;
  status?: string;
}

export interface IConOrder {
  id: string;
  route_id: string;
  routeOfOrder: {
    id: string;
    sequence: number;
  };
  sequence: number;
  customer_id: string;
  customer?: {
    id: string;
    codigo: number;
    customer: string;
    name: string;
    cpf_cnpj: string;
    block: string;
    image_url: string;
    status: string;
    last_transaction: Date;
    type: string;
  };
  commissioned_id: string | null;
  date_digitation: Date;
  date_sell: Date | null;
  nat_ope_id: string;
  order_type: string;
  invoiced: boolean;
  nfe_emited: boolean;
  discount: number;
  additional: number;
  fees: number;
  qnt_products: number;
  qnt_itens: number;
  total_comission: number;
  order_value: number;
  order_total: number;
  expire_day: Date;
  checked: boolean;
  obs: string;
  items: IConOrderItem[];
  payments: {
    id: string;
    pos: number;
    con_order_id: string;
    payment_id: string;
    total: number;
    serie: string;
    parcel: number;
    user_id: string;
    status: string;
  }[];
  user_id: string;
  status: string;
}
