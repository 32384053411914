export default function validateNumberRange(
  start: number | string,
  end: number | string,
  fieldName?: string,
): boolean {
  const startValue = typeof start === 'string' ? parseFloat(start) : start;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(startValue)) return false;

  const endValue = typeof end === 'string' ? parseFloat(end) : end;

  if (isNaN(endValue)) return false;

  if (startValue > endValue) return false;

  return true;
}
