import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PersonsHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Cliente"
        icon="fa-solid fa-user"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/persons/create')}
      />

      <Button
        label="Cliente rápido"
        icon="fa-solid fa-user"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/customer/short/create')}
      />

      <Button
        label="Clientes"
        icon="fa-solid fa-users"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/persons/list')}
      />

      <Button
        label="Análise de crédito"
        icon="fa-solid fa-users"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/credit-analise/list')}
      />

      <Button
        label="Clientes"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/reports/customers')}
      />

      <Button
        label="Clientes ativos"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/reports/customers/active')}
      />

      <Button
        label="Clientes contatos"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/reports/customers/contacts')}
      />
    </>
  );
};

export default PersonsHeaderButtons;
