import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';
import { currencyToFloat, numberToCurrency } from '../../../utils/number';

/** empty cost center */

type AnalysisCredit = {
  id: string;
  limit_brute: string;
  limit_liquid: string;
  payment_condition: {
    title: string;
  };
  personShort: {
    name: string;
  };
};

const emptyAnalysisCredit = {
  id: '',
  limit_brute: '',
  limit_liquid: '',
  payment_condition: {
    title: '',
  },
  personShort: {
    name: '',
  },
};

const AnalysisCreditList = (): React.ReactElement => {
  /** state */
  const [creditAnalysis, setCreditAnalysis] = useState<AnalysisCredit[]>([]);
  const [creditAnalysi, setCreditAnalysi] = useState(emptyAnalysisCredit);
  const [globalFilter, setGlobalFilter] = useState('');

  /** state dialog */
  const [dialogDeleteAnalysisrDialog, setDialogDeleteAnalysis] =
    useState(false);

  const [loadingStatus, setLoadingStatus] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  /** delete dialog */

  const hideDeleteAnalysisDialog = () => {
    setDialogDeleteAnalysis(false);
  };

  const confirmDeleteAnalysisCredit = (credit: AnalysisCredit) => {
    setCreditAnalysi(credit);
    setDialogDeleteAnalysis(true);
  };

  const deleteAnalysiCreditApi = async () => {
    try {
      await api.delete(`/credit-analise/${creditAnalysi.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteAnalysisCredit = () => {
    const _creditAnalysis = creditAnalysis.filter(
      val => val.id !== creditAnalysi.id,
    );
    setCreditAnalysis(_creditAnalysis);
    setDialogDeleteAnalysis(false);
    setCreditAnalysi(emptyAnalysisCredit);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteAnalysiCreditApi();
  };

  const deleteComissionedAnalysisFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteAnalysisDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteAnalysisCredit}
      />
    </React.Fragment>
  );

  /** table column */

  const codeBodyTemplate = (rowData: AnalysisCredit, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const limitBrutedBodyTemplate = (rowData: AnalysisCredit) => {
    return (
      <>
        <span>{numberToCurrency(currencyToFloat(rowData.limit_brute))}</span>
      </>
    );
  };

  const limiLiquidBodyTemplate = (rowData: AnalysisCredit) => {
    return (
      <div className="flex">
        <span>{numberToCurrency(currencyToFloat(rowData.limit_liquid))}</span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: AnalysisCredit) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised"
          onClick={() => navigate.push(`/credit-analise/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteAnalysisCredit(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Análises de Crédito</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchCreditAnalytic = async () => {
    try {
      const { data } = await api.get('/credit-analise');
      setCreditAnalysis(data);
      setLoadingStatus(false);
    } catch (error: any) {
      setLoadingStatus(false);
      toast?.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
      });
    }
  };

  useEffect(() => {
    searchCreditAnalytic();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/credit-analise/create')}
      />
      <div className="card">
        <DataTable
          value={creditAnalysis}
          dataKey="id"
          paginator
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} análise de crédito"
          globalFilter={globalFilter}
          emptyMessage="Ainda não existem análises de crédito cadastradas."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="personShort.name" header="Pessoa"></Column>
          <Column
            field="personShort"
            header="Limite Bruto"
            body={limitBrutedBodyTemplate}
          ></Column>
          <Column
            header="Limite Líquido"
            body={limiLiquidBodyTemplate}
          ></Column>
          <Column
            header="Condição de Pagamento"
            field="payment_condition.title"
          ></Column>
          <Column
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteAnalysisrDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteComissionedAnalysisFooter}
        onHide={hideDeleteAnalysisDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {creditAnalysi && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{creditAnalysi?.payment_condition.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AnalysisCreditList;
