import React, { useEffect, useRef, useState } from 'react';

/** services */
import api from '../../../../services/api';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** components */
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../../components/DataTable';
import { SideBar } from '../../../../components/Sidebar';
import InventoryHeaderButtons from '../../../inventory/header-buttons';

const ReportsInventoryDiff: React.FC = (): React.ReactElement => {
  const toast = useRef<any>(null);

  const [selectProvider, setSelectProvider] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState<any>();
  const [inventorys, setInventorys] = useState<Array<any>>([]);
  const [error, setError] = useState(false);

  /** with */
  const [width, setWidth] = useState('60vw');

  /** router */

  /** ref */
  const form = useRef<FormHandles>(null);

  /** toast  */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const selectedInventoryByTable = (e: DataTableSelectionChangeParams) => {
    setSelectedInventory(e.value);
    setSelectProvider(false);
    setError(false);
  };

  const handleReports = async () => {
    try {
      if (!selectedInventory?.id) {
        showToast('error', 'Error', 'Por favor, selecione o inventário!');
        setError(true);
        return;
      }

      const { data: response } = await api.get(
        `/reports/inventory/diff/${selectedInventory.id}`,
      );

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  /** table */
  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const collumTemplateId = (rowData: any, attr: any) => {
    return (
      <span>{`${rowData.num_sequencia} - abertura: ${format(
        new Date(rowData.date_open),
        'dd/MM/yy',
      )} - fechamento: ${format(
        new Date(rowData.date_close),
        'dd/MM/yy',
      )}`}</span>
    );
  };

  const qntItemsBodyTemplate = (rowData: any) => {
    return <span>{rowData.items.length}</span>;
  };

  useEffect(() => {
    api.get(`/inventory/full`).then(({ data }) => {
      setInventorys(data);
    });

    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('60vw');
      }
    });
  }, []);

  return (
    <>
      <InventoryHeaderButtons />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Inventário</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedInventory
                    ? `Inventário: ${selectedInventory?.num_sequencia}`
                    : 'Selecionar Inventário'
                }
                aria-haspopup
                onClick={() => setSelectProvider(true)}
                aria-controls="overlay_panel"
                className={
                  !error
                    ? 'select-product-button w-7'
                    : 'p-button-raised p-button-danger w-7'
                }
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mt-3">
            <div className="field col-12 md:col-12">
              <Button
                label="Imprimir"
                className="p-button-success"
                icon="fa-solid fa-print"
                type="submit"
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={selectProvider}
        position="right"
        style={{ width }}
        onHide={() => setSelectProvider(false)}
      >
        <DataTable
          value={inventorys}
          selectionMode="single"
          selection={selectedInventory}
          onSelectionChange={e => selectedInventoryByTable(e)}
          dataKey="id"
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
        >
          <Column field="code" header="Nº" body={codeBodyTemplate}></Column>
          <Column
            field="num_sequencia"
            header="Id"
            body={collumTemplateId}
          ></Column>
          <Column
            field="category"
            header="Qnt de Items"
            body={qntItemsBodyTemplate}
          ></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default ReportsInventoryDiff;
