import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ConsignedTopMenu = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Rotas"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/consigned/route/list')}
      />
      <Button
        label="Rota"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/consigned/route/open')}
      />

      <Button
        label="Pedidos"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/consigned/order/list')}
      />
      <Button
        label="Pedido"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/consigned/order/create')}
      />

      <Button
        label="Devoluções"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/consigned/devolution/list')}
      />

      <Button
        label="Devolução"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/consigned/devolution/create')}
      />

      <Button
        label="Relatórios"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/report/consigned/route/search')}
      />
    </>
  );
};

export default ConsignedTopMenu;
