export const goal = [
  { label: 'NFe Normal', value: 1 },
  { label: 'NFe Complementar', value: 2 },
  { label: 'NFe de Ajuste', value: 3 },
  { label: 'NFe de Devolução de Mercadoria', value: 4 },
];

export const impersonalType = [
  { label: 'Sem geração de DANFE', value: 0 },
  { label: 'Modelo Retrato', value: 1 },
  { label: 'Modelo Paisagem', value: 2 },
  { label: 'Modelo Simplificado', value: 3 },
  { label: 'DANFE NFC-e', value: 5 },
  {
    label:
      ' DANFE NFC- e em mensagem eletrônica(o envio de mensagem eletrônica pode ser feita de forma simultânea com a impressão do DANFE',
    value: 6,
  },
];

export const emissionType = [
  { label: 'Emissão normal', value: 1 },
  { label: 'Contingência FS-IA', value: 2 },
  { label: 'Contingência SCAN', value: 3 },
  { label: 'Contingência DPEC', value: 4 },
  { label: ' Contingência FS-DA', value: 5 },
  { label: 'Contingência SVC-AN', value: 6 },
  { label: 'Contingência SVC-RS', value: 7 },
];
