export function parseToCpf(value: string): string {
  let formmated = value;
  formmated = formmated.replace(/\D/g, '');
  formmated = formmated.replace(/(\d{3})(\d)/, '$1.$2');
  formmated = formmated.replace(/(\d{3})(\d)/, '$1.$2');
  formmated = formmated.replace(/(\d{3})(\d{2})$/, '$1-$2');

  return formmated;
}

export function parseToCnpj(value: string): string {
  let formmated = value;
  formmated = formmated.replace(/\D/g, '');
  formmated = formmated.replace(/(\d{2})(\d)/, '$1.$2');
  formmated = formmated.replace(/(\d{3})(\d)/, '$1.$2');
  formmated = formmated.replace(/(\d{3})(\d)/, '$1/$2');
  formmated = formmated.replace(/(\d{4})(\d)/, '$1-$2');
  formmated = formmated.replace(/(\d{2})(\d)/, '$1$2');
  return formmated;
}
