import React, { useEffect, useRef, useState } from 'react';

/** react router dom */
import { useHistory, useParams } from 'react-router-dom';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputMask } from '../../../components/Inputs/InputMask';
import { InputPhone } from '../../../components/Inputs/InputPhone';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';

type BusinessPixData = {
  business_bank_account_id: string;
  kind: string;
  key: string;
};

const BusinessPixEdit: React.FC = (): React.ReactElement => {
  const [selectProvider, setSelectProvider] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** account bank */
  const [accountBanks, setAccountBanks] = useState<any[]>([]);
  const [width, setWidth] = useState('60vw');

  /** selection account */
  const [selectedAccount, setSelectedAccount] = useState<any>();
  const [errorAccount, setErrorAccount] = useState(false);

  /** params */
  const params: { id: string } = useParams();

  /** router */
  const navigate = useHistory();

  const [typeKey, setTypeKey] = useState('');
  const [regexKey, setRegexKey] = useState('');

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** toast  */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const citySelectItems = [
    { label: 'Cpf', value: 'CPF' },
    { label: 'Chave Aleatório', value: 'CHA' },
    { label: 'Cnpj', value: 'CNP' },
    { label: 'Email', value: 'EMA' },
    { label: 'Telefone', value: 'TEL' },
  ];

  /** table */
  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const typeAccountBank = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {rowData.kind_account === 'P'
          ? 'Poupança'
          : rowData.kind_account === 'C'
            ? 'Corrente'
            : 'Simples'}
      </>
    );
  };

  const selectBankAccount = (value: string) => {
    setSelectedAccount(value);
    setSelectProvider(false);
  };

  const handleSubmit = async (data: BusinessPixData) => {
    try {
      setLoadingStatus(true);

      formRef.current?.setErrors({});

      const schemaAddPix = Yup.object({
        business_bank_account_id: Yup.string().required(
          'Por favor, informe uma conta!',
        ),
        kind: Yup.string().required('Por favor, informe o tipo da chave!'),
        key: Yup.string().required('Por favor, informe a chave!'),
      });
      if (!data.business_bank_account_id) {
        setErrorAccount(true);
        showToast('error', 'Error', 'Por favor, informe uma conta!');
        return;
      }
      setErrorAccount(false);
      await schemaAddPix.validate(data, { abortEarly: false });
      await api.put(`/business-pix-keys/${params.id}`, data);
      navigate.push('/business-pix/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const changeTypeKey = (e: string) => {
    if (e === 'CPF') {
      setRegexKey('999.999.999-99');
    } else if (e === 'CHA') {
      setRegexKey('');
    } else if (e === 'CNP') {
      setRegexKey('99.999.999/9999-99');
    } else if (e === 'EMA') {
      setRegexKey('');
    } else if (e === 'TEL') {
      setRegexKey('(99) 99999-9999');
    }
    setTypeKey(e);
  };

  const getBusinessPixSingle = async (bankAccount: any) => {
    const { data } = await api.get(`/business-pix-keys/${params.id}`);
    formRef.current?.setData({ key: data.key });
    const defineAccount = bankAccount?.find(
      (item: any) => item.id === data.business_bank_account_id,
    );
    setSelectedAccount(defineAccount);
    setTypeKey(data.kind);
  };

  const getBusinessBanksAccount = async () => {
    const { data } = await api.get('/business-banks-accounts');
    setAccountBanks(data);
    await getBusinessPixSingle(data);
  };

  useEffect(() => {
    getBusinessBanksAccount();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        icon="fa-solid fa-list"
        className="mb-3"
        onClick={() => navigate.push('/business-pix/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-8 sm:w-full"
        />
      )}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Toast ref={toast} />
        <div className="card md:w-8 sm:w-full" style={{ minWidth: 300 }}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Banco</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedAccount
                    ? `Banco: ${selectedAccount.bank.nome} - Ag: ${selectedAccount.bank_agence} - Conta: ${selectedAccount.bank_account}`
                    : 'Selecionar Conta'
                }
                aria-haspopup
                style={{
                  background: errorAccount ? '#ffcdd2' : '',
                  color: errorAccount ? '#c63737' : '',
                }}
                onClick={() => setSelectProvider(true)}
                aria-controls="overlay_panel"
                className="select-product-button w-7"
              />
            </div>
          </div>
          <InputText
            type="hidden"
            name="business_bank_account_id"
            value={selectedAccount?.id || ''}
          />
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Tipo de Chave</label>
              <InputDropDown
                options={citySelectItems}
                name="kind"
                placeholder="Selecionar"
                value={typeKey}
                onChange={e => changeTypeKey(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Chave</label>
              {regexKey !== '' && typeKey !== 'TEL' ? (
                <InputMask
                  mask={regexKey}
                  name="key"
                  placeholder="0000000"
                  autoClear={false}
                />
              ) : regexKey != '' && typeKey === 'TEL' ? (
                <InputPhone name="key" placeholder="(00) 0000-0000" />
              ) : (
                <InputText name="key" placeholder="0000000" />
              )}
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </div>
      </Form>
      <SideBar
        visible={selectProvider}
        position="right"
        style={{ width }}
        onHide={() => setSelectProvider(false)}
      >
        <DataTable
          value={accountBanks}
          selectionMode="single"
          selection={selectedAccount}
          onSelectionChange={e => selectBankAccount(e.value)}
          dataKey="id"
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
        >
          <Column field="code" header="Code" body={codeBodyTemplate}></Column>
          <Column field="titular" header="Titular"></Column>
          <Column field="bank_account" header="Conta Bancária"></Column>
          <Column field="bank_agence" header="Agência Bancária"></Column>
          <Column
            field="kind_account"
            header="Tipo de Conta"
            body={typeAccountBank}
          ></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default BusinessPixEdit;
