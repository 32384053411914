import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const InventoryHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Abertura"
        className="mb-2 mr-2"
        icon="fa-solid fa-lock-open"
        onClick={() => navigate.push('/inventory/abertura')}
      />
      <Button
        label="Digitação"
        className="mb-2 mr-2"
        icon="fa-solid fa-keyboard"
        onClick={() => navigate.push('/inventory/digitacao')}
      />
      <Button
        label="Encerramento"
        className="mb-2 mr-2"
        icon="fa-solid fa-lock"
        onClick={() => navigate.push('/inventory/encerramento')}
      />
      <Button
        label="Contagem"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/stock-control/inventory-stock')}
      />
      <Button
        label="Diferença"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/invetory/diff')}
      />
    </>
  );
};

export default InventoryHeaderButtons;
