import { v4 } from 'uuid';
import { IUser } from './IUser';

export interface IUserProfile extends IUser {
  person_caixa_n: number;
  person_qnt_caixas: number;
  person_comissionado_n: number;
  person_total_ped: number;
  person_total_pro: number;
  person_total_ven: number;
  person_pro_perdida: number;
}

const hoje = new Date();

export const emptyUserProfile: IUserProfile = {
  id: v4(),
  key_user: false,
  contract_id: '',
  b_id: '',
  name: '',
  email: '',
  password: '',
  re_password: '',
  old_password: '',
  avatar_url: '',
  cpf_cnpj: '',
  phone: '',
  birthday: hoje,
  status: 'A',
  business: {
    id: '',
    contract_id: '',
    name: '',
    cpf_cnpj: '',
    status: '',
    contact_site: '',
    contact_email: '',
  },
  person_caixa_n: 0,
  person_qnt_caixas: 0,
  person_comissionado_n: 0,
  person_total_ped: 0,
  person_total_pro: 0,
  person_total_ven: 0,
  person_pro_perdida: 0,
};
