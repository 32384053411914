import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Toast } from 'primereact/toast';
import api from '../../../services/api';

/** menu options */

const StateList = (): React.ReactElement => {
  /** state */
  const [ufs, setUfs] = useState<Array<any>>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  /** toast */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = () => {
    return (
      <div className="flex justify-content-between">
        <Button icon="pi pi-pencil" disabled className="p-button-raised mr-4" />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          disabled
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">UF&apos;S</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const searchUfs = async () => {
    try {
      const { data } = await api.get(`/ufs`);
      setUfs(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast?.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
      });
    }
  };

  useEffect(() => {
    searchUfs();
  }, []);

  return (
    <>
      <div className="card">
        <Toast ref={toast} />
        <DataTable
          value={ufs}
          dataKey="id"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} ufs"
          globalFilter={globalFilter}
          emptyMessage="Não existe ufs."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="sigla"
            header="UF"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column header="ESTADO" field="name"></Column>
          <Column
            field="ibge"
            header="IBGE"
            headerStyle={{ width: '14%', minWidth: '8rem' }}
          ></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default StateList;
