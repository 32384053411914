import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { ICustomerSearchItemDTO } from '../dtos/ICustomerSearchItemDTO';
import BillsToReceivableHeader from '../header';
import useLoadBillReceivable from '../../../hooks/useLoadBillReceivable';

const BillsReceivableEdit: React.FC = () => {
  /** const */
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  const params: { id: string } = useParams();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [customer, setCustomer] = useState<ICustomerSearchItemDTO>({
    id: '',
    codigo: 0,
    customer: '',
    name: '',
    cpf_cnpj: '',
    block: false,
    image_url: '',
    status: '',
  });

  const [emited, setEmited] = useState<Date>(hoje);
  const [dateToPay, setDateToPay] = useState<Date>(hoje);
  const [value, setValue] = useState<number>(0);

  const [costCenterOpts, setCostCenterOpts] = useState<IOptionsDTO[]>([]);
  const [costCenter, setCostCenter] = useState<string>('');

  const [docSerie, setDocSerie] = useState<string>('');
  const [docNum, setDocNum] = useState<string>('');

  const [paymentConditionOpts, setPaymentConditionOpts] = useState<
    IOptionsDTO[]
  >([]);
  const [paymentCondition, setPaymentCondition] = useState<string | null>('');

  const [obs, setObs] = useState<string>('');

  const handleSubmit = async (d: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      if (!validate(customer.id)) {
        toast(
          'warn',
          'Alerta',
          'Informe um cliente para cadastrar a conta a receber!',
        );
        return;
      }

      d.emited = emited as Date;
      d.date_to_pay = dateToPay as Date;
      d.value = Number(value);

      const validation = Yup.object({
        cost_center: Yup.string()
          .uuid('Id do centro de custo é inválido!')
          .required('Informe um centro de custo!'),
        doc_serie: Yup.string().required('Informe a série do documento!'),
        doc_number: Yup.string().required('Informe o número do documento!'),
        payment_condition: Yup.string()
          .uuid('O id da condição de pagamento é inválido!')
          .required('Informe uma condição de pagamento!'),
        occorrence: Yup.string().required('Informe a ocorrência das parcelas!'),
        emited: Yup.date().required('Informe a data de emissão!'),
        date_to_pay: Yup.date().required('Informe a data de pagamento!'),
        value: Yup.number()
          .positive('O valor deve ser um número positivo!')
          .required('Informe o valor da conta a pagar!'),
      });

      await validation.validate(d, { abortEarly: false });

      const res = await api.put(`/bills-receivable/v2/update/${params.id}`, {
        bill: {
          customer_id: customer.id,
          cost_center_id: costCenter,
          doc_serie: docSerie,
          doc_number: docNum,
          payment_condition_id: paymentCondition,
          occorrence: 'UN',
          emited: emited,
          date_to_pay: dateToPay,
          value: bill ? bill[0].value_total : value,
          value_total: value,
          obs: obs,
        },
        parcels: [],
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Conta a pagar cadastrada com sucesso!');

        navigate.push('/bills/receive/list');
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listCostCenter = async () => {
    try {
      setLoadingStatus(true);
      const res = await api.get('/cost-center');

      if (res.status >= 200 || res.status <= 299) {
        const opts = res.data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setCostCenterOpts(opts);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listPaymentCondition = async () => {
    try {
      setLoadingStatus(true);
      const res = await api.get('/payments-condition');

      if (res.status >= 200 || res.status <= 299) {
        const opts = res.data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setPaymentConditionOpts(opts);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const { data: bill, isLoading } = useLoadBillReceivable(params.id);

  /** useEffect */
  useEffect(() => {
    listCostCenter();
    listPaymentCondition();
  }, []);

  useEffect(() => {
    if (bill) {
      setPaymentCondition(bill[0].payment_condition_id);
      setCustomer({ ...customer, id: bill[0].cli_id, name: bill[0].name });
      setDocSerie(bill[0].serie);
      setDocNum(bill[0].number);
      setEmited(new Date(bill[0].emited_date));
      setDateToPay(new Date(bill[0].duo_date));
      setValue(Number(bill[0].value_total));
      setCostCenter(bill[0].cost_center_id);
      setObs(bill[0].description || '');
    }
  }, [bill]);

  return (
    <>
      <BillsToReceivableHeader />
      {loadingStatus ||
        (isLoading && (
          <ProgressBar
            mode="indeterminate"
            style={{ height: '4px' }}
            className="md:w-12 sm:w-full"
          />
        ))}
      <Form ref={formRef} onSubmit={d => handleSubmit(d)} className="card w-8" placeholder={''} onPointerEnterCapture={null} onPointerLeaveCapture={null}>
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <Button
              label={customer.name}
              disabled={true}
              type="button"
              icon="fa-solid fa-search"
              iconPos="right"
              className={`p-button-outlined ${customer.id === '' ? 'p-button-danger' : 'p-button-info'
                } ml-auto`}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="cost_center">Centro de custo</label>
            <InputDropDown
              name="cost_center"
              options={costCenterOpts}
              value={costCenter}
              placeholder="Selecionar..."
              disabled={true}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="doc_serie">Doc. serie</label>
            <InputText
              name="doc_serie"
              value={docSerie}
              disabled={true}
              placeholder="0"
              tooltip={'Série da nota ou ROM ou PED'}
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="doc_number">Doc. número</label>
            <InputText
              name="doc_number"
              value={docNum}
              disabled={true}
              placeholder="0"
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="payment_condition">Condição de pagamento</label>
            <InputDropDown
              name="payment_condition"
              options={paymentConditionOpts}
              value={paymentCondition}
              placeholder="Selecionar..."
              onChange={e => setPaymentCondition(e.value)}
              filter
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="occorrence">Ocorrência</label>
            <InputDropDown
              name="occorrence"
              options={[{ label: 'Único', value: 'UN' }]}
              disabled={true}
              value={'UN'}
              placeholder="Selecionar..."
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="emited">Emissão</label>
            <Calendar
              name="emited"
              dateFormat="dd/mm/yy"
              value={emited as Date}
              disabled={true}
              placeholder={format(emited, 'dd/MM/yy')}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="date_to_pay">Vencimento</label>
            <Calendar
              name="date_to_pay"
              dateFormat="dd/mm/yy"
              value={dateToPay as Date}
              onChange={e => setDateToPay(e.value as Date)}
              placeholder={format(dateToPay, 'dd/MM/yy')}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="value">Valor</label>
            <InputNumber
              name="value_total"
              value={value}
              onChange={e => setValue(Number(e.value))}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              tooltip={'Informe o valor total do documento'}
              tooltipOptions={{ position: 'bottom' }}
              disabled={customer.id === ''}
            />
          </div>

          <div className="field col-12 md:col-12">
            <InputTextEdit
              name="obs"
              style={{ height: '10vh' }}
              value={obs}
              onTextChange={e => setObs(e.htmlValue || '')}
              placeholder="E.: Pagamento da padaria."
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus || isLoading}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus || isLoading}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default BillsReceivableEdit;
