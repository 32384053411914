import { cnpj, cpf } from 'cpf-cnpj-validator';
import { format } from 'date-fns';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Stepper from 'react-stepper-horizontal';
import { validate } from 'uuid';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';
import GetUUIDUrl from '../../utils/GetUUIDUrl';

export default function OsResume() {
  /** const */
  const location = useLocation();
  const osId = GetUUIDUrl(location.search);

  /** hooks */
  const toast = useToastContext();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [osNum, setOsNum] = useState<number>(0);
  const [business, setBusiness] = useState<{
    name: string;
    doc: string;
    street: string;
    complement: string;
    city: string;
    uf: string;
    imgUrl: string | undefined;
  }>({
    name: '',
    doc: '',
    street: '',
    complement: '',
    city: '',
    uf: '',
    imgUrl: '',
  });
  const [customer, setCustomer] = useState<string>('');
  const [dateOs, setDateOs] = useState<Date | null>(null);
  const [previsaoOs, setPrevisaoOs] = useState<Date | null>(null);
  const [laudoOs, setLaudoOs] = useState<string>('');
  const [statusOpts, setStatusOpts] = useState<
    { id: string; position: number; title: string }[]
  >([]);
  const [status, setStatus] = useState<string>('');

  /** useEffects */
  useEffect(() => {
    if (validate(osId)) {
      setLoadingStatus(true);
      api
        .get(`/os/resume/${osId}`)
        .then(({ data }) => {
          setBusiness({
            name: data.resume.os.business.name || '-',
            doc: data.resume.os.business.cpf_cnpj || '-',
            street: `End.: ${
              data.resume.os.business.addresses[0].street || '-'
            }, Bairro.: ${
              data.resume.os.business.addresses[0].neighborhood || '-'
            }`,
            complement: `, Comp.: ${
              data.resume.os.business.addresses[0].complement || '-'
            }`,
            city: data.resume.os.business.addresses[0].city.name || '-',
            uf: data.resume.os.business.addresses[0].city.uf.sigla || '-',
            imgUrl: data.resume.os.business.logo_url || '',
          });

          if (data.resume.os.status === 'A') {
            setOsNum(data.resume.os.os_number || 0);
            setDateOs(
              data.resume.os.date_open
                ? new Date(data.resume.os.date_open)
                : null,
            );
            setPrevisaoOs(
              data.resume.os.date_prevision
                ? new Date(data.resume.os.date_prevision)
                : null,
            );

            setLaudoOs(
              data.resume.os.obs_public !== null &&
                data.resume.os.obs_public !== ''
                ? data.resume.os.obs_public
                : 'Em análise...',
            );
            setCustomer(data.resume.os.customer.name);
            setStatus(data.resume.os.os_status_id);

            if (data.resume.status.length > 0) {
              setStatusOpts(
                data.resume.status.map((i: any) => {
                  return {
                    id: i.id,
                    position: i.position,
                    title: i.title,
                  };
                }),
              );
            }
          }
        })
        .catch((e: any) => {
          toast(
            'error',
            'Erro',
            `Falha ao carregar os dados da O.S tente novamente! ${e}`,
          );
        })
        .finally(() => setLoadingStatus(false));
    }
  }, []);

  return (
    <>
      <div className="flex align-items-center justify-content-center m-3">
        <div className="card mt-5" style={{ width: 576, alignSelf: 'center' }}>
          <div className="p-fluid grid formgrid">
            <img
              src={
                business.imgUrl
                  ? `${process.env.REACT_APP_SERVER_URL}/files/img/${business.imgUrl}`
                  : `${process.env.REACT_APP_SERVER_URL}/files/img/no-photo.jpg`
              }
              alt="Logo da empresa"
              className="field col-4 mb-0"
              width={128}
              height={128}
            />
            <div className="field col-8 my-1">
              <div className="field">{business.name}</div>
              <div className="field">
                {business.street + business.complement}
              </div>
              <div className="field">{`${business.city} - ${business.uf}`}</div>
              <div className="field">
                {business.doc.length === 14 ? 'CNPJ' : 'CPF'}.:{' '}
                {business.doc.length === 14
                  ? cnpj.format(business.doc)
                  : cpf.format(business.doc)}
              </div>
            </div>
            <Divider type="solid" className="my-1" />
            <div className="field col-12 mt-3">
              <div className="field">
                Nome.: <strong>{customer}</strong>
              </div>
              <div className="field mb-1">
                Abertura O.S.:{' '}
                <strong>
                  {dateOs !== null
                    ? format(new Date(dateOs), `dd/MM/yy 'às' H:mm`)
                    : ' - '}
                </strong>
              </div>
              <div className="field mb-1">
                Previsão entrega.:{' '}
                <strong>
                  {previsaoOs !== null
                    ? format(new Date(previsaoOs), `dd/MM/yy 'às' H:mm`)
                    : ' - '}
                </strong>
              </div>
              <div className="field">
                <h5>Laudo</h5>
                <article>
                  {laudoOs !== null && laudoOs !== '' ? laudoOs : ' - '}
                </article>
              </div>
            </div>
            <Divider type="solid" className="my-1" />
            <div className="field col-12 mt-3">
              <h3 className="text-center">RESUMO O.S [ {osNum} ]</h3>
            </div>
            <div className="field col-12">
              <Stepper
                steps={statusOpts
                  .sort((a, b) => a.position - b.position)
                  .map((i: any) => {
                    return { title: i.title };
                  })}
                activeStep={statusOpts.findIndex(i => i.id === status)}
                size={54}
                activeColor="#2ECC71"
                completeColor="#2ECC71"
                circleFontSize={18}
                titleFontSize={16}
              />
            </div>
            <Divider type="solid" />
            <div className="field col-4 text-center">
              <a href="#" className="no-underline">
                <i className="fa-solid fa-phone mr-3"></i>
                TELEFONE
              </a>
            </div>
            <div className="field col-4 text-center">
              <a href="#" className="no-underline">
                <i className="fa-solid fa-link mr-3"></i>
                SITE
              </a>
            </div>
            <div className="field col-4 text-center">
              <a href="#" className="no-underline">
                <i className="fa-solid fa-hand-holding-dollar mr-3"></i>
                SERVIÇOS
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
