import React, { useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** compoentns */
import { Button } from 'primereact/button';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Toast } from 'primereact/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import RegisterCashHeader from '../../register-cash/header';

const CashMovimentationCreate: React.FC = (): React.ReactElement => {
  const [value, setValue] = useState<number | null>();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** navigate */
  const navigate = useHistory();

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schemaCreateCash = Yup.object({
        doc_ser: Yup.string()
          .max(3, 'O número de caracteres máximo são 3')
          .required('Por favor, informe série!'),
        doc_num: Yup.string().required(
          'Por favor, informe número do documento!, ',
        ),
        value: Yup.string().required('Por favor, informe o valor!'),
        obs: Yup.string(),
      });
      await schemaCreateCash.validate(data, { abortEarly: false });

      await api.post('/register-cash-movimentations/c/input', {
        ...data,
        value,
      });
      navigate.push('/orders/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <RegisterCashHeader />
      <div className="card w-6">
        <Form ref={form} onSubmit={handleSubmit}>
          <Toast ref={toast} />
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Doc. Série</label>
              <InputText name="doc_ser" placeholder="000" />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Doc. Número</label>
              <InputText keyfilter="int" name="doc_num" placeholder="000" />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Valor</label>
              <InputNumber
                name="value"
                value={value}
                placeholder="R$ 0,00"
                onValueChange={e => setValue(e.value)}
                mode="currency"
                currency="BRL"
                minFractionDigits={2}
                maxFractionDigits={5}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Obs</label>
              <InputTextArea name="obs" placeholder="Digite aqui..." />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CashMovimentationCreate;
