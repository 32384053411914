import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

/** empty brand */
const emptyBrand = {
  id: '',
  description: '',
  image_url: '',
  status: '',
  limit: 0,
  required: '',
  title: '',
  b_id: '',
};

const BrandsList = (): React.ReactElement => {
  /** state */
  const [brands, setBrands] = useState<any>();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [brand, setBrand] = useState<any>();
  const [dialogDeleteBrandsDialog, setDialogDeleteBrands] = useState(false);
  const [loading, setLoading] = useState(true);

  /** ref */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const deleteBrand = async () => {
    try {
      await api.delete(`/brands/${brand.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const hideDeleteBrandDialog = () => {
    setDialogDeleteBrands(false);
  };

  const confirmDeleteAdditional = (brand: any) => {
    setBrand(brand);
    setDialogDeleteBrands(true);
  };

  const deleteProduct = () => {
    const _brands = brands.filter(val => val.id !== brand.id);
    setBrands(_brands);
    setDialogDeleteBrands(false);
    setBrand(emptyBrand);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteBrand();
  };

  const deleteAdditionalDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteBrandDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const imageBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const statusBodyTemplate = () => {
    return (
      <div className="flex">
        <Checkbox checked={true}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/brands/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteAdditional(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Marcas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e: React.FormEvent<HTMLInputElement>) =>
            setGlobalFilter(e.target.value)
          }
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchBrands = async () => {
    try {
      const { data } = await api.get('/brands');
      setBrands(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchBrands();
  }, []);

  return (
    <>
      <Button
        label="Nova Marca"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/brands/create')}
      />
      <div className="card">
        <DataTable
          value={brands}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} marcas"
          globalFilter={globalFilter}
          emptyMessage="Não existe marcas."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            sortable
            body={codeBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column header="Marca" body={imageBodyTemplate} sortable></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
            sortable
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteBrandsDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteAdditionalDialogFooter}
        onHide={hideDeleteBrandDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {brand && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{brand?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default BrandsList;
