import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { CalendarChangeParams } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTableSelectParams } from 'primereact/datatable';
import React, { useRef, useState } from 'react';
import { validate } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import CalendarRange from '../../../components/Inputs/CalendarRange';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import useLoadOsStatus from '../../../hooks/useLoadOsStatus';
import { useOrderService } from '../../../hooks/useOrderService';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import IOs from '../dtos/IOs';
import { IFilter, emptyFilter } from './dtos/empty-filter';
import Prioridades from '../types/Prioridades';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: IOs;
}

interface IDocuments {
  id: string;
}

const SidebarSearchOS = ({ isOpen, onRequestClose, data }: IProps) => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState<boolean>(false);
  const [filter, setFilter] = useState<IFilter>(emptyFilter);
  const [documents, setDocuments] = useState<IDocuments[]>([]);

  const { data: status } = useLoadOsStatus();
  const { orderService, setData, clearData, fetchData } = useOrderService();

  const searchOs = async (e: any) => {
    try {
      setLoad(true);
      await api
        .post('/os/v1/search', { filter })
        .then(({ data }) => {
          if (data) {
            setDocuments(data.listOSs);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    } catch (err: any) {
      toast('warn', 'Alerta', messageRequestError(err));
    } finally {
      setLoad(false);
    }
  };

  const handleSelectOs = async (e: DataTableSelectParams) => {
    setLoad(true);
    if (validate(e.data.id)) {
      fetchData(e.data.id);
      toast('info', 'Info', 'O.S regastada com sucesso!');
      onRequestClose();
      setLoad(false);
    }
  };

  return (
    <SideBar
      visible={isOpen}
      onHide={() => onRequestClose()}
      position="right"
      style={{ width: '50vw' }}
    >
      <h3>Buscar O.S</h3>
      <Form
        ref={formRef}
        onSubmit={d => searchOs(d)}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="field col-12 md:col-8">
          <label htmlFor="keyword">Nome</label>
          <InputText
            name="keyword"
            placeholder="Ex.: João da Silva"
            value={filter.keyword}
            onChange={e =>
              setFilter({ ...filter, keyword: e.currentTarget.value })
            }
          />
        </div>
        <div className="field col-12 md:col-4">
          <label htmlFor="period">Data início</label>
          <CalendarRange
            name="period"
            showIcon
            value={filter.period}
            onChange={(e: Partial<CalendarChangeParams>) =>
              setFilter({ ...filter, period: e.value as Date[] })
            }
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="kind_atendimento">Tipo atendimento</label>
          <InputDropDown
            name="kind_atendimento"
            options={[
              { label: 'Interno', value: 'INT' },
              { label: 'Externo', value: 'EXT' },
            ]}
            placeholder="Ex.: Interno"
            value={filter.kind_atendimento}
            onChange={e => setFilter({ ...filter, kind_atendimento: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="os_number">Número O.S</label>
          <InputNumber
            onFocus={e => e.target.select()}
            name="os_number"
            placeholder="Ex.: 705"
            value={filter.os_number}
            onChange={e => setFilter({ ...filter, os_number: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="priority">Prioridade</label>
          <MultiSelect
            name="priority"
            options={Prioridades}
            placeholder="Ex.: Alta"
            value={filter.priority}
            onChange={e => setFilter({ ...filter, priority: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="status_os">Situação</label>
          <MultiSelect
            name="status_os"
            options={status?.map(i => {
              return { value: i.id, label: i.title };
            })}
            placeholder="Ex.: Manutenção"
            value={filter.status_os}
            onChange={e => setFilter({ ...filter, status_os: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="status">Status doc.</label>
          <InputDropDown
            name="status"
            options={[
              { label: 'Ativo', value: 'A' },
              { label: 'Cancelado', value: 'C' },
            ]}
            placeholder="Ex.: Ativo"
            value={filter.status}
            onChange={e => setFilter({ ...filter, status_os: e.value })}
          />
        </div>
        <div className="field col-12 md:col-2 flex align-items-end">
          <Button
            name="btn_search"
            label="Buscar"
            icon="fa-solid fa-search"
            type="submit"
            loading={load}
          />
        </div>
        <>
          {documents.length > 0 && (
            <DataTable
              value={documents}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="O.S não encontradas..."
              selectionMode="single"
              onRowSelect={e => handleSelectOs(e)}
              loading={load}
              className="field col-12 md:col-12"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column field="os_number" header="O.S" align={'center'}></Column>
              <Column field="customer" header="Cliente" align={'left'}></Column>
              <Column
                field="date_open"
                header="Abertura"
                align={'center'}
                body={e => renderColumnDate(e.date_open)}
              ></Column>
              <Column
                field="date_close"
                header="Fechamento"
                align={'center'}
                body={e => renderColumnDate(e.date_close)}
              ></Column>
              <Column
                field="os_status"
                header="Situação"
                align={'center'}
              ></Column>
              <Column
                field="total"
                header="Total"
                align={'center'}
                body={e => renderColumnDecimal(e.total)}
              ></Column>
              <Column
                field="status"
                header="Status"
                align={'center'}
                body={e => renderColumnStatus(e)}
              ></Column>
            </DataTable>
          )}
        </>
      </Form>
    </SideBar>
  );
};

export default SidebarSearchOS;
