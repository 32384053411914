import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonHelp from '../../../components/Buttons/ButtonHelp';

interface IProps {
  videoId?: string;
}

const TopMenu = ({ videoId }: IProps): React.ReactElement => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Preços"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/products-prices/list')}
      />
      <Button
        label="Preço"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/products-prices/create')}
      />
      <Button
        label="Tabela"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/table-prices/create')}
      />
      <Button
        label="Produto"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/products/create')}
      />
      <Button
        label="Produtos"
        icon="fa-solid fa-boxes-stacked"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/products/list`)}
      />
      <Button
        label="Edição massa"
        className="mb-2 mr-2"
        icon="fa-solid fa-list-check"
        onClick={() => navigate.push('/products-prices/adjust')}
      />
      <Button
        label="Tabela preços"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/table-prices')}
      />

      {videoId && <ButtonHelp key={null} videoId={videoId} />}
    </>
  );
};

export default TopMenu;
