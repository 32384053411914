import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import { Option } from '../../../../types/optional';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const ReportCommissionsSalles = (): React.ReactElement => {
  const hoje = new Date();

  const form = useRef<FormHandles>(null);
  const showToast = useToastContext();

  const [commisioneds, setCommisioneds] = useState<Option[]>([]);
  const [selectedCommisioned, setSelectedComissioned] = useState([]);

  const [cliente, setCliente] = useState<IOptionsDTO>({ label: '', value: '' });
  const [clienteOpts, setClienteOpts] = useState<IOptionsDTO[]>([]);

  const [operationNature, setOperationNature] = useState<Array<Option>>([]);
  const [selectedOperationNature, setSelectedOperationNature] = useState([]);

  const [paymentConditions, setPaymentConditions] = useState<Array<Option>>([]);
  const [selectedPaymentCondition, setSelectedPaymentCondition] = useState<
    Array<string>
  >([]);

  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const [isLoad, setIsLoad] = useState(false);

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setClienteOpts(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      showToast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const handleSubmit = async (data: any) => {
    try {
      setIsLoad(true);

      const cli: string[] = [];

      if (validate(cliente.value)) {
        cli.push(cliente.value);
      }

      const { data: response } = await api.post('/reports/commissions', {
        dateStart: `${format(initialDate as Date, 'yyyy-MM-dd')}`,
        dateEnd: `${format(finalDate as Date, 'yyyy-MM-dd')}`,
        commissioned_ids: selectedCommisioned || [],
        customer_ids: cli || [],
        natureza_operacao_ids: selectedOperationNature || [],
        payment_condition_ids: selectedPaymentCondition || [],
      });

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.report}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    setIsLoad(true);
    api
      .get(`/commissioneds`)
      .then(({ data }) => {
        const optComissioneds = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setCommisioneds(optComissioneds);
      })
      .finally(() => setIsLoad(false));
    setIsLoad(true);
    api
      .post(`/naturezas-operacao/list/short`, {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setOperationNature(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      })
      .finally(() => setIsLoad(false));
    setIsLoad(true);
    api
      .get('/payments-condition')
      .then(({ data }) => {
        const paymentConditions = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setPaymentConditions(paymentConditions);
      })
      .finally(() => setIsLoad(false));
  }, []);

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Form
          ref={form}
          onSubmit={handleSubmit}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Cliente</label>
              <InputAutoComplete
                name="customerId"
                suggestions={clienteOpts}
                value={cliente}
                completeMethod={e => searchCustomer(e.query)}
                field="label"
                onChange={e => setCliente(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar cliente..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Condição de Pagamento</label>
              <MultiSelect
                name="commissionedId"
                value={selectedPaymentCondition}
                options={paymentConditions}
                placeholder="Selecionar..."
                onChange={e => setSelectedPaymentCondition(e.value)}
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Comissionado</label>
              <MultiSelect
                name="commissionedId"
                value={selectedCommisioned}
                options={commisioneds}
                placeholder="Selecionar..."
                onChange={e => setSelectedComissioned(e.value)}
                display="chip"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Natureza de Operação</label>
              <MultiSelect
                name="natOperationId"
                options={operationNature}
                value={selectedOperationNature}
                placeholder="Selecionar..."
                onChange={e => setSelectedOperationNature(e.value)}
                display="chip"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Inicial</label>
              <Calendar
                name="initial_date"
                value={initialDate as Date}
                onChange={e => setInitialDate(e.value as Date)}
                dateFormat="dd/mm/yy"
                placeholder="00/00/00"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Final</label>
              <Calendar
                name="final_date"
                value={finalDate}
                onChange={e => setFinalDate(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="00/00/00"
              />
            </div>

            <div className="field col-12 md:col-12">
              <Button
                label={isLoad ? 'Aguardar...' : 'Imprimir'}
                className="p-button-success"
                icon="fa-solid fa-print"
                loading={isLoad}
                disabled={isLoad}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportCommissionsSalles;
