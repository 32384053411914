import { format, parse } from 'date-fns';

export function convertStringToDate(dataString: string): string {
  // Converter a string para um objeto de data
  const data = parse(dataString, 'yyyyMMdd', new Date());

  // Formatar a data no novo formato 'DD/MM/YY'
  const dataFormatada = format(data, 'dd/MM/yy');

  return dataFormatada;
}
