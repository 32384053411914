import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';

interface IProps {
  image_url: string | null;
  show: boolean;
  position?: 'top' | 'left' | 'right' | 'bottom';
}

const TooltipImage: React.FC<IProps> = ({
  image_url,
  show,
  position,
}: IProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <div className="m-1">
      {show && (
        <>
          {image_url !== '' && image_url !== null && (
            <div
              className="flex bg-gray-700 flex-wrap justify-content-center p-1"
              style={{
                position: 'absolute',
                height: '230px',
                width: '220px',
                zIndex: 9999,
                borderRadius: '5px',
                color: '#fff',
                marginTop:
                  position === 'bottom'
                    ? '10px'
                    : position === 'top'
                    ? '-250px'
                    : '-125px',
                marginLeft:
                  position === 'bottom' || position === 'top'
                    ? '-210px'
                    : position === 'left'
                    ? '-420px'
                    : '10px',
              }}
            >
              <>
                <Image
                  className="mt-1"
                  src={`${process.env.REACT_APP_SERVER_URL}/files/img/${image_url}`}
                  width="200"
                  height="200"
                  preview
                />
              </>

              <label style={{ marginTop: '-4px' }}>Clique para ampliar</label>
            </div>
          )}
          {image_url === '' ||
            (image_url === null && (
              <div
                className="flex bg-gray-700 align-items-center justify-content-center"
                style={{
                  position: 'absolute',
                  zIndex: 9999,
                  borderRadius: '5px',
                  marginTop:
                    position === 'bottom'
                      ? '20px'
                      : position === 'top'
                      ? '-50px'
                      : '-17px',
                  marginLeft:
                    position === 'bottom' || position === 'top'
                      ? '-250px'
                      : position === 'left'
                      ? '-530px'
                      : '10px',
                }}
              >
                <div className="flex w-full flex-wrap justify-content-center align-items-center p-2">
                  <i
                    className="fa-solid fa-image mr-1"
                    style={{ color: '#fff' }}
                  ></i>
                  <label className="mx-auto" style={{ color: '#fff' }}>
                    Produto sem foto cadastrada!
                  </label>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default TooltipImage;
