const copyTextClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);

    return true;
  } catch (error) {
    console.error('Erro ao copiar o texto: ', error);
    return false;
  }
};

export default copyTextClipboard;
