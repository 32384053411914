import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { Dialog } from '../../../../components/Modal';
import api from '../../../../services/api';
import { DataTable } from '../../../../components/DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnDateTime from '../../../../components/DataTableColumns/RenderColumnDateTime';
import { ICrmCardDates } from '../dtos/ICrmDTO';
import useToastContext from '../../../../hooks/toast';
import { EnumStatus } from '../../../../enum/EnumStatus';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  card_id: string;
  panel_id: string;
  schedules: any[];
}
const DialogSchedule = ({
  isOpen,
  onRequestClose,
  card_id,
  panel_id,
  schedules,
}: IProps) => {
  const hoje = new Date();
  const [load, setLoad] = useState(false);
  const toast = useToastContext();

  const [schedule, setSchedule] = useState<Date | Date[] | undefined>(hoje);
  const [schedulesList, setSchedules] = useState<ICrmCardDates[]>([]);

  useEffect(() => {
    if (schedules) {
      const schedulesActive = schedules.filter(
        s => s.status === EnumStatus.ATIVO,
      );
      setSchedules(schedulesActive);
    }
  }, [isOpen]);

  const loadSchedules = async () => {
    setLoad(true);

    await api
      .post('/crm/card/dates/list', {
        card_id,
      })
      .then(async ({ data }) => {
        if (data) {
          setSchedules(data);
        }
      })
      .finally(() => setLoad(false));
  };

  const handleCreateSchedule = async () => {
    setLoad(true);
    await api
      .post(`/crm/card/dates`, {
        data: {
          card_id: card_id,
          panel_id: panel_id,
          date_start: hoje,
          date_end: hoje,
          send_alert: true,
          date_alert: schedule,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          setSchedule(hoje);
          await loadSchedules();
        }
      })
      .finally(() => setLoad(false));
  };

  const handleRemSchedule = async (id: string) => {
    const noId = schedulesList.filter(i => i.id !== id);
    setSchedules(noId);

    setLoad(true);
    await api
      .delete(`/crm/card/dates/${id}/${panel_id}`)
      .then(async ({ data }) => {
        if (data) toast('success', 'Sucesso', 'Lembrete removido com sucesso!');
      })
      .finally(() => setLoad(false));
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemSchedule(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  return (
    <Dialog
      header={`Agendamento para dia.: ${format(
        schedule as Date,
        'dd/MM/yyyy H:mm',
      )}`}
      visible={isOpen}
      style={{ width: 512 }}
      onHide={() => onRequestClose()}
    >
      <Form
        ref={null}
        onSubmit={() => null}
        className="p-fluid grid formgrid"
        placeholder={''}
      >
        <Calendar
          name="Lembrete"
          value={schedule}
          onChange={e => setSchedule(e.value as Date)}
          placeholder="00/00/00"
          dateFormat="dd/mm/yy"
          showTime
          showIcon
          minDate={hoje}
          stepMinute={10}
          inline
          className="field col-12"
        />
        {schedulesList && schedulesList.length > 0 && (
          <label className="field col-12">Lembretes adicionados</label>
        )}
        <DataTable
          value={schedulesList}
          className="field col-12"
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          emptyMessage="Nenhum lembrete adicionado..."
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords}"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column
            field="date_alert"
            header="Data do lembrete"
            body={e => renderColumnDateTime(e.date_alert)}
            align={'center'}
          ></Column>
          <Column
            header="*"
            body={e => renderCollumnMenu(e.id)}
            align={'center'}
          ></Column>
        </DataTable>
        <div className="field col-12">
          <Button
            label="Adicionar"
            icon="fa-solid fa-plus"
            type="button"
            className="p-button-success"
            onClick={async () => await handleCreateSchedule()}
            loading={load}
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default DialogSchedule;
