import { format } from 'date-fns';
import { Carousel } from 'primereact/carousel';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { AppContext } from '../../context/AppContext';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';
import formatCurrency from '../../utils/FormatCurrency';
import messageRequestError from '../../utils/messageRequestError';
import { DataTable } from '../DataTable';
import renderColumnDate from '../DataTableColumns/RenderColumnDate';
import renderColumnDateTime from '../DataTableColumns/RenderColumnDateTime';
import renderColumnDecimal from '../DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../DataTableColumns/RenderColumnShortName';
import Progress from '../progress-bar';
import { EnumDashElementsId } from './dtos/EnumDashElementsId';
import { IDashboard, emptyDashboard } from './dtos/IDashboard';
import { IFavoriteForm } from './dtos/IFavoriteForm';
import { IItemBlog } from './dtos/IItemBlogDTO';
import { itemBlogTemplate } from './item-blog-template';
import { responsiveOptions } from './types/ResponsiveOptions';

const Dashboard = () => {
  const { state } = useContext(AppContext);
  const toast = useToastContext();

  const [posts, setPosts] = useState<IItemBlog[]>([]);
  const [dashboard, setDashboard] = useState<IDashboard>(emptyDashboard);

  const [isLoad, setIsLoad] = useState(false);
  const [favoriteForms, setFavoriteForms] = useState<IFavoriteForm[]>([]);

  const [stockLowDialogVisible, setStockLowDialogVisible] =
    useState<boolean>(false);

  const [openRegistersDialogVisible, setOpenRegistersDialogVisible] =
    useState<boolean>(false);

  const [ordersDialogVisible, setOrdersDialogVisible] =
    useState<boolean>(false);

  const months: Array<string> = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  type selletChartData = {
    labels: Array<string>;
    datasets: {
      data: Array<number>;
      backgroundColor: Array<string>;
    }[];
  };

  type DataSet = {
    label: string;
    data: Array<number>;
    backgroundColor: string;
  };

  type billsData = {
    labels: Array<string>;
    datasets: Array<DataSet>;
  };

  const [productsSold, setProductsSold] = useState<selletChartData>();
  const [salesMirror, setSalesMirror] = useState<selletChartData>();
  const [dashBills, setDashBills] = useState<billsData | undefined>();

  const loadPosts = async () => {
    setIsLoad(true);
    await api
      .post('/b166er/admin/system/news/last8', {
        paginate: { page: 1, perPage: 10, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setPosts(data);
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };
  const loadDashboard = async () => {
    setIsLoad(true);
    await api
      .get('/dashboard/info')
      .then(({ data }) => {
        if (data) {
          setDashboard(data);
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const mountFavorites = () => {
    const forms: { module: string; pathname: string }[] = [];
    const favorites = Object.entries(state.favorites).map(([key, value]) => ({
      [key]: value,
    }));
    const fav = favorites.map(objeto => Object.keys(objeto)[0]);
    for (const f of fav) {
      const form = state.forms.find(i => i.code === f);
      if (form) {
        forms.push({ module: form?.name, pathname: form?.code });
      }
    }
    setFavoriteForms(forms);
  };

  useEffect(() => {
    Promise.all([loadPosts(), loadDashboard()]);
  }, []);

  useEffect(() => {
    mountFavorites();
    mountChartProductsSold(dashboard.product_solds.solds);
    mountChartBills(dashboard.wallet_summary.resume);
    mountChartSalesMirror(dashboard.orders_summary.orders_month);
  }, [dashboard]);

  type ProductSeller = {
    title: string;
    total: number;
  };
  const mountChartProductsSold = (dataset: Array<ProductSeller>) => {
    const selletChartData = {
      labels: [] as Array<string>,
      datasets: [
        { data: [] as Array<number>, backgroundColor: [] as Array<string> },
      ],
    };
    if (dataset && dataset.length > 0) {
      dataset.forEach(item => {
        selletChartData.labels.push(item.title.substring(0, 20));
        selletChartData.datasets[0].data.push(item.total);
        selletChartData.datasets[0].backgroundColor.push(
          `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
            Math.random() * 255,
          )},${Math.floor(Math.random() * 255)})`,
        );
      });
      setProductsSold(selletChartData);
    }
  };

  type DashBills = {
    period: string;
    entrada: number;
    saida: number;
  };

  const mountChartBills = (dataset: Array<DashBills>) => {
    if (dataset.length > 6) dataset = dataset.slice(dataset.length - 6);

    const chartBillsData = {
      labels: [] as Array<string>,
      datasets: [
        {
          label: 'Recébidas',
          backgroundColor: '#42A5F5',
          data: [] as Array<number>,
        },
        {
          label: 'Pagas',
          backgroundColor: '#FFA726',
          data: [] as Array<number>,
        },
        {
          label: 'Lucro',
          backgroundColor: '#2ECC71',
          data: [] as Array<number>,
        },
      ],
    };
    if (dataset && dataset.length > 0) {
      dataset.forEach(item => {
        const date = item.period.split('-');
        chartBillsData.labels.push(
          `${months[Number(date[1]) - 1]}/${date[0].substring(2)}`,
        );
        chartBillsData.datasets[0].data.push(item.entrada);
        chartBillsData.datasets[1].data.push(item.saida * -1);
        chartBillsData.datasets[2].data.push(item.entrada + item.saida);
      });
      setDashBills(chartBillsData);
    }
  };

  type DashSalesMirrorGraph = {
    period: string;
    total: number;
  };
  const mountChartSalesMirror = (dataset: Array<DashSalesMirrorGraph>) => {
    const selletChartData = {
      labels: [] as Array<string>,
      datasets: [
        {
          label: '',
          data: [] as Array<number>,
          backgroundColor: [] as Array<string>,
        },
      ],
    };
    if (dataset && dataset.length > 0) {
      dataset.forEach(item => {
        const date = item.period.split('-');
        selletChartData.labels.push(
          `${months[Number(date[1]) - 1]}/${date[0].substring(2)}`,
        );
        selletChartData.datasets[0].data.push(item.total);
        selletChartData.datasets[0].backgroundColor.push('#3498DB');
        selletChartData.datasets[0].label = 'Últimos 12 meses';
      });
      setSalesMirror(selletChartData);
    }
  };

  const getLightTheme = () => {
    const basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            display: false,
            drawBorder: false,
            color: '#ebedef',
          },
        },
        y: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
            drawBorder: false,
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <>
      <Progress isLoad={isLoad} />
      <div className="layout-dashboard z-5">
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=5562985099000&text&type=phone_number&app_absent=0"
          className="w-5rem h-5rem border-circle flex justify-content-center align-items-center cursor-pointer fixed z-5"
          style={{ background: '#28a745', bottom: 25, right: 25 }}
          rel="noreferrer"
        >
          <i className="fa-brands fa-whatsapp text-5xl text-white"></i>
        </a>
        <div className="grid">
          {posts && posts.length > 0 && (
            <div className="col-12 pb-0">
              <div className="col-12 p-0">
                <Carousel
                  header={<h5>Novidades</h5>}
                  value={posts}
                  numVisible={3}
                  numScroll={1}
                  responsiveOptions={responsiveOptions}
                  className="custom-carousel"
                  circular
                  autoplayInterval={5000}
                  itemTemplate={itemBlogTemplate}
                />
              </div>
            </div>
          )}

          {favoriteForms.length > 0 && (
            <>
              <div className="col-12 pb-0">
                <h5>Últimos acessos</h5>
              </div>
              <div className="col-12">
                <div className="grid">
                  {favoriteForms.map((item: any, indice: number) => (
                    <Link
                      className="col-12 md:col-3 mb-2 h-2rem border-round p-0 flex cursor-pointer"
                      to={item.pathname}
                      key={indice}
                    >
                      <div className="w-10 flex ">
                        <div className="w-3 bg-primary h-full border-round-left p-3 flex justify-content-center align-items-center">
                          <i className="pi pi-star-fill"></i>
                        </div>
                        <div
                          className="w-full border-round-right bg-white-100 px-3 flex align-items-center"
                          style={{ border: '1px solid var(--primary-color)' }}
                        >
                          <span className="font-semibold">
                            {item.module && item.module.length > 32
                              ? `${item.module.slice(0, 32)}...`
                              : item.module}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}

          {dashboard && dashboard.dash_elements.length > 0 && (
            <>
              {dashboard.dash_elements.some(
                item =>
                  item.visible === true &&
                  item.element_id !== EnumDashElementsId.billsChart &&
                  item.element_id !== EnumDashElementsId.salesMirror &&
                  item.element_id !== EnumDashElementsId.productsMostSold,
              ) && (
                <>
                  <div className="col-12 pb-0 pt-0">
                    <h5 className="m-0">Resumos</h5>
                  </div>
                  <div className="col-12 p-0 flex flex-wrap">
                    {dashboard.dash_elements.some(
                      item =>
                        item.element_id === EnumDashElementsId.ordersToday &&
                        item.visible === true,
                    ) && (
                      <div
                        className="xl:col-3 md:col-2 col-12 cursor-pointer"
                        onClick={() => setOrdersDialogVisible(true)}
                        key={v4()}
                      >
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                          <span className="overview-icon">
                            <i className="pi pi-shopping-cart"></i>
                          </span>
                          <span className="overview-title">Pedidos</span>
                          <div className="grid overview-detail m-0">
                            <div className="col-12 border-none">
                              <div className="overview-number">
                                {dashboard.order_summary.order.qnt}
                              </div>
                              <div className="overview-subtext">Faturados</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {dashboard.dash_elements.some(
                      item =>
                        item.element_id === EnumDashElementsId.valueSoldToday &&
                        item.visible === true,
                    ) && (
                      <div className="col-6 md:col-3 xl:col-3" key={v4()}>
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
                          <span className="overview-icon">
                            <i className="pi pi-dollar"></i>
                          </span>
                          <span className="overview-title">Vendas</span>
                          <div className="grid overview-detail m-0">
                            <div className="col-12">
                              <div className="overview-number">
                                {formatCurrency(
                                  dashboard.order_summary.order.total,
                                )}
                              </div>
                              <div className="overview-subtext">Reais </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {dashboard.dash_elements.some(
                      item =>
                        item.element_id === EnumDashElementsId.stockLow &&
                        item.visible === true,
                    ) && (
                      <div
                        className="col-12 md:col-3 xl:col-3 cursor-pointer"
                        onClick={() => setStockLowDialogVisible(true)}
                        key={v4()}
                      >
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-4 text-red-700 border-orange-500">
                          <span className="overview-icon bg-orange-500">
                            <i className="pi pi-inbox text-orange-300"></i>
                          </span>
                          <span className="overview-title text-orange-500">
                            Estoque baixo
                          </span>
                          <div className="grid overview-detail m-0">
                            <div className="col-12">
                              <div className="overview-number">
                                {dashboard.product_stock.stock_low}
                              </div>
                              <div className="overview-subtext">
                                Produto(os)
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {dashboard.dash_elements.some(
                      item =>
                        item.element_id === EnumDashElementsId.openRegisters &&
                        item.visible === true,
                    ) && (
                      <div
                        className="col-12 md:col-3 xl:col-3 cursor-pointer"
                        onClick={() => setOpenRegistersDialogVisible(true)}
                        key={v4()}
                      >
                        <div
                          className="card grid-nogutter widget-overview-box widget-overview-box-4"
                          style={{ borderColor: '#d9372b' }}
                        >
                          <span
                            className="overview-icon"
                            style={{ backgroundColor: '#d9372b' }}
                          >
                            <i
                              className="pi pi-inbox"
                              style={{ color: '#ff8980' }}
                            ></i>
                          </span>
                          <span
                            className="overview-title"
                            style={{ color: '#d9372b' }}
                          >
                            Caixas abertos
                          </span>
                          <div className="grid overview-detail m-0">
                            <div className="col-12 border-none">
                              <div className="overview-number">
                                {formatCurrency(
                                  dashboard.cash_summary.cash_summary.total,
                                )}
                              </div>
                              <div className="overview-subtext">
                                {dashboard.cash_summary.cash_summary.qnt} -
                                Registradora(as)
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {dashboard.dash_elements.some(
                item =>
                  item.visible === true &&
                  (item.element_id === EnumDashElementsId.billsChart ||
                    item.element_id === EnumDashElementsId.productsMostSold ||
                    item.element_id === EnumDashElementsId.salesMirror),
              ) && (
                <>
                  <div className="col-12 pb-0">
                    <h5 className="m-0">Gráficos</h5>
                  </div>

                  {dashboard.dash_elements.some(
                    item =>
                      item.element_id === EnumDashElementsId.salesMirror &&
                      item.visible === true,
                  ) && (
                    <div
                      className="col-12 md:col-4 xl:col-4"
                      style={{ minWidth: 480 }}
                      key={1}
                    >
                      <div
                        className="card grid-nogutter orders"
                        style={{ height: 480 }}
                      >
                        <div className="card-header">
                          <h4>Espelho de vendas</h4>
                        </div>

                        <div className="grid">
                          <div className="col-12">
                            <div className="overview-chart">
                              <Chart
                                type="bar"
                                data={salesMirror}
                                options={basicOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {dashboard.dash_elements.some(
                    item =>
                      item.element_id === EnumDashElementsId.productsMostSold &&
                      item.visible === true,
                  ) && (
                    <div
                      className="col-12 md:col-4 xl:col-4 justify-content-center"
                      style={{ minWidth: 460 }}
                      key={3}
                    >
                      <div className="card" style={{ height: 480 }}>
                        <div className="card-header">
                          <h4>10 Produtos + vendidos</h4>
                        </div>
                        <div className="grid">
                          <div className="col-12">
                            <div className="overview-chart">
                              <Chart
                                type="pie"
                                className="w-full md:w-30rem"
                                id="revenue-chart"
                                data={productsSold}
                                options={{
                                  plugins: {
                                    legend: {
                                      labels: {
                                        usePointStyle: true,
                                      },
                                      position: 'right',
                                    },
                                  },
                                }}
                                key={3}
                              ></Chart>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {dashboard.dash_elements.some(
                    item =>
                      item.element_id === EnumDashElementsId.billsChart &&
                      item.visible === true,
                  ) && (
                    <div
                      className="col-12 md:col-4 xl:col-4"
                      style={{ minWidth: 480 }}
                      key={2}
                    >
                      <div className="card" style={{ height: 480 }}>
                        <div className="card-header">
                          <h4>Recebidas x Pagas x Lucro</h4>
                        </div>
                        <div className="grid">
                          <div className="col-12">
                            <div className="overview-chart">
                              <Chart
                                type="bar"
                                data={dashBills}
                                options={basicOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Dialog
        visible={ordersDialogVisible}
        onHide={() => setOrdersDialogVisible(false)}
        style={{ width: '50vw' }}
        draggable={false}
        header={`Pedidos de hoje.: ${format(new Date(), `dd/MM/yy 'às' H:m`)}`}
      >
        <DataTable
          value={dashboard.order_summary.orders}
          selectionMode="single"
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          responsiveLayout="scroll"
          emptyMessage="Nenhum pedido selecionado!"
          size="small"
        >
          <Column
            field="#"
            header="#"
            body={renderColumnPosition}
            align={'center'}
          ></Column>
          <Column field="order" header="Pedido" align={'center'}></Column>
          <Column
            field="sell_date"
            header="Data"
            body={e => renderColumnDate(e.sell_date)}
            align={'center'}
          ></Column>
          <Column
            field="total"
            header="Total"
            body={e => renderColumnDecimal(e.total)}
          ></Column>
        </DataTable>
      </Dialog>
      <Dialog
        visible={false}
        onHide={() => {}}
        style={{ width: '60vw' }}
        draggable={false}
        header="Vendido hoje"
      >
        <h2></h2>
      </Dialog>
      <Dialog
        visible={false}
        onHide={() => {}}
        style={{ width: '60vw' }}
        draggable={false}
        header="Visitas hoje"
      >
        <h2></h2>
      </Dialog>

      <Dialog
        visible={stockLowDialogVisible}
        onHide={() => setStockLowDialogVisible(false)}
        style={{ width: '60vw' }}
        draggable={false}
        header="Abaixo do Estoque"
      >
        <DataTable
          value={dashboard.product_stock.products}
          selectionMode="single"
          dataKey="id"
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          paginator
          responsiveLayout="scroll"
          emptyMessage="Nenhum produto selecionado!"
          size="small"
        >
          <Column
            header="#"
            body={renderColumnPosition}
            align={'center'}
          ></Column>
          <Column field="codigo" header="Código" align={'center'}></Column>
          <Column field="title" header="Produto"></Column>
          <Column field="unity" header="UND"></Column>
          <Column
            field="stock_min"
            header="Estq. Min."
            body={e => renderColumnDecimal(e.stock_min)}
            align={'center'}
            bodyStyle={{ color: '#3498DB' }}
          ></Column>

          <Column
            field="stock"
            header="Estq. Atual"
            body={e => renderColumnDecimal(e.stock)}
            bodyStyle={{ color: '#E74C3C' }}
            align={'center'}
          ></Column>

          <Column
            header="Comprar"
            body={e =>
              renderColumnDecimal(Number(e.stock_min) - Number(e.stock))
            }
            align={'center'}
            bodyStyle={{ color: '#27AE60' }}
          ></Column>
        </DataTable>
      </Dialog>
      <Dialog
        visible={openRegistersDialogVisible}
        onHide={() => setOpenRegistersDialogVisible(false)}
        style={{ width: '60vw' }}
        draggable={false}
        header="Caixas abertos"
      >
        <DataTable
          value={dashboard.cash_summary.registers}
          selectionMode="single"
          dataKey="id"
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          paginator
          responsiveLayout="scroll"
          emptyMessage="Nenhum caixa selecionado!"
          size="small"
        >
          <Column
            header="#"
            body={renderColumnPosition}
            align={'center'}
          ></Column>
          <Column field="title" header="Caixa" align={'left'}></Column>
          <Column
            field="balance_open_date"
            header="Abertura"
            align={'center'}
            body={e => renderColumnDateTime(e.balance_open_date)}
          ></Column>
          <Column
            field="last_record"
            header="Últ. lançamento"
            align={'center'}
            body={e => renderColumnDateTime(e.last_record)}
          ></Column>
        </DataTable>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
