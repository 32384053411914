import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { addMonths, endOfMonth, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DropdownChangeParams } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnCpfCnpj from '../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { SideBar as ModalSearchProvider } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import PerfectDivision from '../../../utils/perfectDivision';

import Occorrence from '../../bills-to-receive/types/Occorrence';
import WeekDays from '../../bills-to-receive/types/WeekDays';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import BillsToPayHeader from '../header';
import { IBillsPayableItemDTO } from './dtos/IBillsPayableItemDTO';
import { IProviderSearchItemDTO } from './dtos/IProviderSearchItemDTO';
import useSearchProvider from '../../../hooks/useSearchProvider';

const BillsToPayCreate: React.FC = () => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  /** useStates */
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [providersOpts, setProvidersOpts] = useState<IProviderSearchItemDTO[]>(
    [],
  );
  const [provider, setProvider] = useState<IProviderSearchItemDTO>({
    id: '',
    codigo: 0,
    customer: '',
    name: '',
    cpf_cnpj: '',
    block: false,
    image_url: '',
    status: '',
  });

  const [occurrenceOpts, setOccurrenceOpts] =
    useState<IOptionsDTO[]>(Occorrence);
  const [occurrence, setOccurrence] = useState<string>(occurrenceOpts[0].value);

  const [emited, setEmited] = useState<Date>(hoje);
  const [dateToPay, setDateToPay] = useState<Date>(hoje);
  const [value, setValue] = useState<number>(0);

  const [costCenterOpts, setCostCenterOpts] = useState<IOptionsDTO[]>([]);
  const [costCenter, setCostCenter] = useState<string>('');

  const [docSerie, setDocSerie] = useState<string>('');
  const [docNum, setDocNum] = useState<string>('');

  const [paymentConditionOpts, setPaymentConditionOpts] = useState<
    IOptionsDTO[]
  >([]);
  const [paymentCondition, setPaymentCondition] = useState<string>('');

  const [obs, setObs] = useState<string>('');

  const [parcel, setParcel] = useState<number>(2);

  const [dateStart, setDateStart] = useState<Date>(hoje);
  const [dateEnd, setDateEnd] = useState<Date>(endOfMonth(hoje));
  const [weekDay, setWeekDay] = useState<string>(WeekDays[0].value);

  const [billsPayableItems, setBillsPayableItems] = useState<
    IBillsPayableItemDTO[]
  >([]);

  const [modalSearchProviderVisible, setModalSearchProviderVisible] =
    useState<boolean>(false);
  const [providerKeyword, setProviderKeyword] = useState<string>('');

  /** functions */
  const searchProvider = async (keyword: string) => {
    setIsLoad(true);

    try {
      const providers = await useSearchProvider({ search: keyword });

      if (providers) {
        const opts = providers.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            customer: item.customer,
            name: item.name,
            cpf_cnpj: item.cpf_cnpj,
            block: item.block,
            image_url: item.image_url,
            status: item.status,
          };
        });

        setProvidersOpts(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      if (!validate(provider.id)) {
        toast(
          'warn',
          'Alerta',
          'Informe um cliente para cadastrar a conta a pagar!',
        );
        return;
      }

      d.emited = emited as Date;
      d.date_to_pay = dateToPay as Date;
      d.value = Number(value);

      const unValidation = Yup.object({
        cost_center: Yup.string()
          .uuid('Id do centro de custo é inválido!')
          .required('Informe um centro de custo!'),
        doc_serie: Yup.string().required('Informe a série do documento!'),
        doc_number: Yup.string().required('Informe o número do documento!'),
        payment_condition: Yup.string()
          .uuid('O id da condição de pagamento é inválido!')
          .required('Informe uma condição de pagamento!'),
        occorrence: Yup.string().required('Informe a ocorrência das parcelas!'),
        emited: Yup.date().required('Informe a data de emissão!'),
        date_to_pay: Yup.date().required('Informe a data de pagamento!'),
        value: Yup.number()
          .positive('O valor deve ser um número positivo!')
          .required('Informe o valor da conta a pagar!'),
      });
      const paValidation = Yup.object({
        cost_center: Yup.string()
          .uuid('Id do centro de custo é inválido!')
          .required('Informe um centro de custo!'),
        doc_serie: Yup.string().required('Informe a série do documento!'),
        doc_number: Yup.string().required('Informe o número do documento!'),
        payment_condition: Yup.string()
          .uuid('O id da condição de pagamento é inválido!')
          .required('Informe uma condição de pagamento!'),
        occorrence: Yup.string().required('Informe a ocorrência das parcelas!'),
        emited: Yup.date().required('Informe a data de emissão!'),
        date_to_pay: Yup.date().required('Informe a data de pagamento!'),
        value: Yup.number()
          .positive('O valor deve ser um número positivo!')
          .required('Informe o valor da conta a pagar!'),
        parcel: Yup.number()
          .positive('As parcelas deve ser um número positivo!')
          .min(2, 'A quantidade mínima de parcelas é 2!')
          .required('Informe a quantidade de parcelas!'),
      });

      if (d.occorrence === 'UN') {
        await unValidation.validate(d, { abortEarly: false });
      } else {
        d.parcel = Number(parcel);

        if (billsPayableItems.length === 0) {
          toast('warn', 'Alerta', 'Clique no botão gerar as parcelas!');
          return;
        }

        const summary = billsPayableItems.reduce((acc, item) => {
          acc += Number(item.value);
          return acc;
        }, 0);

        if (Number(value.toFixed(2)) !== Number(summary.toFixed(2))) {
          toast(
            'warn',
            'Alerta',
            `O valor criado das parcelas ${new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
              summary,
            )} não bate com o valor total do documento ${new Intl.NumberFormat(
              'BRL',
              { minimumFractionDigits: 2, maximumFractionDigits: 2 },
            ).format(value)}`,
          );
          return;
        }
        await paValidation.validate(d, { abortEarly: false });
      }

      const res = await api.post('/bills-payable/v2/create', {
        bill: {
          provider_id: provider.id,
          cost_center_id: costCenter,
          doc_serie: docSerie,
          doc_number: docNum,
          payment_condition_id: paymentCondition,
          occorrence: occurrence,
          emited: emited,
          date_to_pay: dateToPay,
          value: value,
          obs: obs,
        },
        parcels:
          billsPayableItems.length > 0
            ? billsPayableItems.map(i => {
                return i;
              })
            : [],
      });

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Conta a pagar cadastrada com sucesso!');

        navigate.push('/bills/pay/list');
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleSelectProvider = (e: any) => {
    if (!validate(e.id)) {
      toast(
        'warn',
        'Alerta',
        `O id do cliente [${e.name}] não é valido, tentar novamnete!`,
      );
      return;
    }

    setProvider(e);
    setModalSearchProviderVisible(false);
  };

  const handleOpenModalSearchProvider = () => {
    setModalSearchProviderVisible(true);
  };

  const handleRemItemParcels = (id: string) => {
    const itensNoId = billsPayableItems.filter(i => i.id !== id);
    setBillsPayableItems(itensNoId);
  };

  const listCostCenter = async () => {
    try {
      setIsLoad(true);
      const res = await api.get('/cost-center');

      if (res.status >= 200 || res.status <= 299) {
        const opts = res.data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setCostCenterOpts(opts);
        const def = opts.find((i: any) => i.label === 'Despesas');
        setCostCenter(def.value);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const listPaymentCondition = async () => {
    try {
      setIsLoad(true);
      const res = await api.get('/payments-condition');

      if (res.status >= 200 || res.status <= 299) {
        const opts = res.data.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setPaymentConditionOpts(opts);
        const def = opts.find((i: any) => i.label === 'Dinheiro');
        setPaymentCondition(def.value);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleGenerateParcels = () => {
    if (parcel <= 1) {
      toast('warn', 'Alerta', 'A quantidade mínima de parcelas é 2!');
      return;
    }
    const valueParcels = PerfectDivision(value, parcel);
    let pos: number = 0;
    const bills: IBillsPayableItemDTO[] = [];
    for (const item of valueParcels) {
      bills.push({
        id: v4(),
        pos,
        parcel: `${pos + 1}#${parcel}`,
        date_to_pay: pos === 0 ? dateToPay : addMonths(dateToPay, pos),
        value: item,
      });
      pos++;
    }
    setBillsPayableItems([]);
    setBillsPayableItems(bills);
  };

  const handleChangeOccurrence = (e: DropdownChangeParams) => {
    setOccurrence(e.value);
    if (e.value === 'PA') {
      setBillsPayableItems([]);
    }
  };

  /** render */
  const renderButtonRemItem = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItemParcels(id)}
        />
      </div>
    );
  };

  /** useEffect */
  useEffect(() => {
    listCostCenter();
    listPaymentCondition();
  }, []);

  return (
    <>
      <BillsToPayHeader key={null} videoId="h2lSz8RqkF0" />
      {isLoad && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-12 sm:w-full"
        />
      )}
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card w-8"
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <Button
              label={provider.id === '' ? 'Buscar fornecedor' : provider.name}
              type="button"
              icon="fa-solid fa-search"
              iconPos="right"
              className={`p-button-outlined ${
                provider.id === '' ? 'p-button-danger' : 'p-button-info'
              } ml-auto`}
              onClick={handleOpenModalSearchProvider}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="cost_center">Centro de custo</label>
            <InputDropDown
              name="cost_center"
              options={costCenterOpts}
              value={costCenter}
              placeholder="Selecionar..."
              onChange={e => setCostCenter(e.value)}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="doc_serie">Doc. serie</label>
            <InputText
              name="doc_serie"
              value={docSerie}
              onChange={e => setDocSerie(e.currentTarget.value)}
              placeholder="0"
              tooltip={'Série da nota ou ROM ou PED'}
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="doc_number">Doc. número</label>
            <InputText
              name="doc_number"
              value={docNum}
              onChange={e => setDocNum(e.currentTarget.value)}
              placeholder="0"
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="payment_condition">Condição de pagamento</label>
            <InputDropDown
              name="payment_condition"
              options={paymentConditionOpts}
              value={paymentCondition}
              placeholder="Selecionar..."
              onChange={e => setPaymentCondition(e.value)}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="occorrence">Ocorrência</label>
            <InputDropDown
              name="occorrence"
              options={occurrenceOpts}
              value={occurrence}
              placeholder="Selecionar..."
              onChange={e => handleChangeOccurrence(e)}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="emited">Emissão</label>
            <Calendar
              name="emited"
              dateFormat="dd/mm/yy"
              value={emited as Date}
              onChange={e => {
                if (e.value !== null) setEmited(e.value as Date);
              }}
              placeholder={format(emited, 'dd/MM/yy')}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="date_to_pay">Vencimento</label>
            <Calendar
              name="date_to_pay"
              dateFormat="dd/mm/yy"
              value={dateToPay as Date}
              onChange={e => {
                if (e.value !== null) setDateToPay(e.value as Date);
              }}
              placeholder={format(dateToPay, 'dd/MM/yy')}
              showIcon
              iconPos="right"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="value">Valor</label>
            <InputNumber
              name="value"
              value={value}
              onChange={e => setValue(Number(e.value))}
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              tooltip={'Informe o valor total do documento'}
              tooltipOptions={{ position: 'bottom' }}
              disabled={provider.id === ''}
            />
          </div>

          {occurrence === 'PA' && (
            <>
              <div className="field col-12 md:col-3">
                <label htmlFor="parcel">Parcelas</label>
                <InputNumber
                  name="parcel"
                  value={parcel}
                  onChange={e => setParcel(Number(e.value))}
                  placeholder="0,00"
                  tooltip={'Informe a quantidade de parcelas'}
                  tooltipOptions={{ position: 'bottom' }}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  showButtons
                  buttonLayout="horizontal"
                  min={2}
                  max={999}
                  disabled={value <= 0}
                />
              </div>
              <div className="field col-12 md:col-3 flex align-items-end">
                <Button
                  label="Gerar parcelas"
                  className="p-button-primary"
                  type="button"
                  loading={isLoad}
                  disabled={value <= 0}
                  onClick={handleGenerateParcels}
                />
              </div>
              <div className="field col-12 md:col-9"></div>

              {/** table of parcels */}
              <div className="field col-12 md:col-12">
                <h5 className="text-center">Parcelas</h5>
                <DataTable
                  value={billsPayableItems}
                  responsiveLayout="scroll"
                  selectionMode="single"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 20, 30]}
                  size="small"
                  emptyMessage="Nenhum item encontrado!"
                  loading={isLoad}
                  onSelectionChange={e => null}
                >
                  <Column header="#" body={renderColumnPosition}></Column>
                  <Column field="parcel" header="Parcela"></Column>
                  <Column
                    header="Vencimento"
                    body={e => renderColumnDate(e.date_to_pay)}
                  ></Column>
                  <Column
                    header="Valor"
                    body={e => renderColumnDecimal(e.value)}
                  ></Column>
                  <Column
                    header="*"
                    body={row => renderButtonRemItem(row.id)}
                  ></Column>
                </DataTable>
              </div>
            </>
          )}

          <div className="field col-12 md:col-12">
            <InputTextEdit
              name="obs"
              style={{ height: '10vh' }}
              value={obs}
              onTextChange={e => setObs(e.htmlValue || '')}
              placeholder="E.: Pagamento da padaria."
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>

      <ModalSearchProvider
        visible={modalSearchProviderVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchProviderVisible(false)}
      >
        <h3>Buscar fornecedor</h3>
        <Form
          ref={null}
          onSubmit={() => null}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="provider_keyword"
                placeholder="Nome do cliente"
                type={'text'}
                value={providerKeyword}
                onChange={e => setProviderKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                // disabled={providerKeyword.length < 3 ? true : false}
                onClick={() => searchProvider(providerKeyword)}
                loading={isLoad}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={providersOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={isLoad}
                onSelectionChange={e => handleSelectProvider(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="name" header="Nome"></Column>
                <Column
                  field="cpf_cnpj"
                  header="CPF/CNPJ"
                  body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </ModalSearchProvider>
    </>
  );
};

export default BillsToPayCreate;
