import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';
import { City, emptyCity } from '../dtos/ICity';
import { AxiosError, AxiosResponse } from 'axios';
import messageRequestError from '../../../utils/messageRequestError';
import { ICity } from '../../person/dtos/IPerson';
import useToastContext from '../../../hooks/toast';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';

const CityList = (): React.ReactElement => {
  const navigate = useHistory();
  const toast = useToastContext();

  const hoje = new Date();

  const [isLoad, setIsLoad] = useState(false);
  const [cities, setCities] = useState<ICity[]>([]);

  const [globalFilter, setGlobalFilter] = useState('');

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Cidades&lsquo;s</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target?.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const loadCities = async () => {
    setIsLoad(true);
    await api
      .post('/cities/list', {
        pagination: { page: 1, perPage: 10000, status: ['A'] },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCities(res.data);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    loadCities();
  }, []);

  return (
    <>
      <div className="card">
        <DataTable
          value={cities}
          dataKey="id"
          paginator
          loading={isLoad}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} cidades"
          globalFilter={globalFilter}
          emptyMessage="Não existe cidades."
          header={header}
          responsiveLayout="scroll"
          size="small"
          rows={10}
          rowsPerPageOptions={[10, 50, 100]}
        >
          <Column
            field="code"
            header="N°"
            sortable
            body={renderColumnPosition}
          ></Column>
          <Column field="name" header="Cidade"></Column>
          <Column field="uf.sigla" header="UF"></Column>
          <Column field="uf.ibge" header="IBGE"></Column>
          <Column field="siafi" header="SIAFI"></Column>
        </DataTable>
      </div>
    </>
  );
};

export default CityList;
