export enum EnumDashElementsId {
  productsMostSold = '12686d0e-3e5f-11ed-b8b6-135c11e831d6',
  stockLow = '10877ea8-3e5f-11ed-8947-bb7dd49faf5d',
  openRegisters = '0fe9ed50-3e5f-11ed-bb79-0f6742a6fe76',
  billsChart = '11b7e196-3e5f-11ed-8052-87088ebce927',
  salesMirror = '1117f406-3e5f-11ed-804c-3bf5fcd704d1',
  ordersToday = '0e38d66a-3e5f-11ed-b7f3-ab2aa5686c47',
  valueSoldToday = '0ecbacb0-3e5f-11ed-b70c-770daa32bf52',
  storeVisits = '0f593e2c-3e5f-11ed-829b-ff0b5f339285',
}
