import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OsMenuHeader = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Listar"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => router.push('/os/list')}
      />

      <Button
        label="Nova O.S"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/os/new')}
      />

      <Button
        label="Objetos/Equip."
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/os/object')}
      />

      <Button
        label="Serviços"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/os/service')}
      />

      <Button
        label="Contrato"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => router.push('/os/contract')}
      />

      <Button
        label="Nfse"
        className="mb-2 mr-2"
        icon="fa-solid fa-file"
        onClick={() => router.push('/nfse/export/list')}
      />
    </>
  );
};

export default OsMenuHeader;
