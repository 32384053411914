import IOptionsDTO from '../pages/business/dtos/IOptionsDTO';

const situacaoCadastralOpts: IOptionsDTO[] = [
  { value: '01', label: 'Nula' },
  { value: '02', label: 'Ativa' },
  { value: '03', label: 'Suspensa' },
  { value: '04', label: 'Inapta' },
  { value: '08', label: 'Baixada' },
];

export default situacaoCadastralOpts;
