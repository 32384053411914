import React from 'react';

/** table primereact */
import { Divider as Divid, DividerProps } from 'primereact/divider';

/** rewriting table component type */
interface DataDividProp extends DividerProps {
  children?: React.ReactElement;
}

export const Divider = ({
  children,
  ...props
}: DataDividProp): React.ReactElement => {
  return <Divid {...props}>{children}</Divid>;
};
