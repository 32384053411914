import React from 'react';

/** dialog primereact */
import { Dialog as Dialogs, DialogProps } from 'primereact/dialog';

/** rewriting dialog component type */
interface DataDialogProp extends DialogProps {
  children: React.ReactElement[] | React.ReactElement;
}

export const Dialog = ({
  children,
  ...props
}: DataDialogProp): React.ReactElement => {
  return <Dialogs {...props}>{children}</Dialogs>;
};
