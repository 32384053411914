import React, { useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';

const TablePriceEdit = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [activeIn, setActiveIn] = useState<Date | Date[] | undefined>(
    new Date(),
  );
  const [offIn, setOffIn] = useState<Date | Date[] | undefined>(new Date());
  const [status, setStatus] = useState('A');
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const navigate = useHistory();

  const params: { id: string } = useParams();

  const handleSubmit = async () => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schemaTablePrice = Yup.object({
        title: Yup.string().required('Por favor, informe título!'),
        description: Yup.string(),
      });

      await schemaTablePrice.validate(
        { title, description },
        { abortEarly: false },
      );

      await api.put(`/table-prices/${params.id}`, {
        table_price: {
          title: title,
          description: description,
          status: status,
          date_on: activeIn,
          date_off: offIn,
        },
      });
      navigate.push('/table-prices/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get(`/table-prices/${params.id}`)
      .then(({ data }) => {
        setActiveIn(new Date(data.date_on));
        setOffIn(new Date(data.date_off));
        setTitle(data.title);
        setDescription(data.description || data.title);
        setStatus(data.status);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/table-prices/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card  md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Título</label>
              <InputText
                name="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Título da tabela"
              />
            </div>
            <div className="field col-12 md:col-6 flex align-items-end">
              <InputSwitch
                name="status"
                className="mr-4"
                falseValue="C"
                trueValue="A"
                checked={status}
                onChange={e => setStatus(`${e.value}`)}
              />
              <label htmlFor="dropdown" className="m-0">
                Ativo
              </label>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Descrição</label>
              <InputTextEdit
                name="description"
                style={{ height: 150 }}
                value={description}
                onTextChange={e => setDescription(e.htmlValue || '')}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Ativo Em</label>
              <Calendar
                name="date_on"
                value={activeIn}
                dateFormat="dd/mm/yy"
                onChange={e => setActiveIn(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Desativo Em</label>
              <Calendar
                name="date_off"
                value={offIn}
                dateFormat="dd/mm/yy"
                onChange={e => setOffIn(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TablePriceEdit;
