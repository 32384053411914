import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const TopMenu = (): React.ReactElement => {
  const router = useHistory();

  return (
    <>
      <Button
        label="Comissão vendas"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/reports/commissions/salles')}
      />

      <Button
        label="Comissão O.S"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => router.push('/reports/commissions/os')}
      />
    </>
  );
};

export default TopMenu;
