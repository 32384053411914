import React, { useRef, useState } from 'react';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from '../../../components/Inputs/InputText';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** services */
import api from '../../../services/api';

/**utils */
import { ProgressBar } from 'primereact/progressbar';
import getValidationErrors from '../../../utils/getErrorsValidation';

const TagCreate = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** navigate */
  const navigate = useHistory();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schemaTag = Yup.object({
        tag: Yup.string().required('Por favor, informe o título!'),
      });
      await schemaTag.validate(data, { abortEarly: false });

      await api.post(`/tags`, { ...data, status: 'A' });
      navigate.push('/tags/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push(`/tags/list`)}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Título</label>
              <InputText name="tag" placeholder="Título da Tag" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
                type="reset"
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" loading={loadingStatus} type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TagCreate;
