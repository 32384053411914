/* eslint-disable react/jsx-no-undef */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Divider } from '../../../../components/Divider';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../../components/Inputs/InputTextEdit';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import messageRequestError from '../../../../utils/messageRequestError';
import { ICrmPanel, IUser } from '../dtos/ICrmDTO';
import { Dialog } from '../../../../components/Modal';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: any;
}

const SidebarPanel = ({
  isOpen,
  onRequestClose,
  data,
}: IProps): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [panel, setPanel] = useState<string | null>(null);
  const [isLoad, setIsLoad] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [confirmDeletePanelDialogVisible, setConfirmDeletePanelDialogVisible] =
    useState(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<string>('');

  const [transferPanelDialogVisible, setTransferPanelDialogVisible] =
    useState<boolean>(false);

  const receiveData = (d: any) => {
    if (d) {
      setPanel(d.id);
      setTitle(d.title);
      setDescription(d.description);
    }
  };

  const handleSubmit = async (d: any) => {
    formRef.current?.setErrors({});

    try {
      const schemaUserGroup = Yup.object({
        title: Yup.string().required('Por favor, informe um título!'),
      });
      await schemaUserGroup.validate(d, { abortEarly: false });

      setIsLoad(true);
      if (!panel || panel === null) {
        await api
          .post('/crm/panel', {
            data: {
              position: 0,
              color: '#ffffff',
              title: title,
              description: description,
            },
          })
          .then(({ data }) => {
            if (data) {
              toast('success', 'Sucesso', 'Painel criado com sucesso!');
              onRequestClose();
              setTitle('');
              setDescription('');
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setIsLoad(false));
      } else {
        await api
          .put('/crm/panel', {
            data: {
              id: panel,
              position: null,
              color: '#ffffff',
              title: title,
              description: description,
            },
          })
          .then(({ data }) => {
            if (data) {
              toast('success', 'Sucesso', 'Painel editado com sucesso!');
              onRequestClose();
              setTitle('');
              setDescription('');
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setIsLoad(false));
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    }
  };

  const loadUsers = async () => {
    setIsLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(
            data.map((u: any) => {
              return {
                ...u,
                label: u.name,
                value: u.id,
              };
            }),
          );
        }
      })
      .finally(() => setIsLoad(false));
  };

  const handleTransferPanel = async () => {
    setIsLoad(true);

    await api
      .post('/crm/panel/transfer', {
        data: {
          panel_ids: [panel],
          user_target_id: user,
        },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Painel transferido com sucesso!');
          onRequestClose();
          setTransferPanelDialogVisible(false);
        }
      })
      .catch((err: any) => messageRequestError(err))
      .finally(() => setIsLoad(false));
  };

  const handleDisablePanel = async () => {
    setIsLoad(true);
    await api
      .delete(`/crm/panel/${panel}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Painel excluído criado com sucesso!');
          onRequestClose();
          setTitle('');
          setDescription('');
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  useEffect(() => {
    receiveData(data);
    loadUsers();
  }, [isOpen]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        style={{ width: '60vw' }}
      >
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div
            className={`field col-12 ${
              panel && panel !== null ? 'md:col-9' : ''
            }`}
          >
            <label htmlFor="title">Título</label>
            <InputText
              name="title"
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
              placeholder="Ex.: Painel de vendas SP"
              autoFocus
            />
          </div>

          {panel && (
            <div className="field col-12 md:col-3 flex align-items-end">
              <Button
                label="Transferir painel"
                icon="fa-solid fa-paper-plane"
                type="button"
                loading={isLoad}
                onClick={() => setTransferPanelDialogVisible(true)}
              />
            </div>
          )}

          <div className="field col-12">
            <label htmlFor="description">Descrição</label>
            <InputTextEdit
              name="description"
              value={description}
              onTextChange={e => setDescription(e.textValue)}
              placeholder="Ex.: Explique porque esse painel é necessário!"
              style={{ minHeight: 128 }}
            />
          </div>

          <Divider />

          {panel && panel !== null && (
            <>
              <div className="field col-12 md:col-6">
                <Button
                  label="Excluir painel"
                  icon="fa-solid fa-trash"
                  className="p-button-danger"
                  type="button"
                  loading={isLoad}
                  onClick={() => setConfirmDeletePanelDialogVisible(true)}
                />
              </div>
            </>
          )}

          <div
            className={`field col-12 ${
              panel && panel !== null ? 'md:col-6' : ''
            }`}
          >
            <Button
              label={!panel || panel === null ? 'Salvar' : 'Editar'}
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </Form>
      </SideBar>
      <Dialog
        visible={confirmDeletePanelDialogVisible}
        style={{ width: '450px' }}
        header="Excluir?"
        modal
        onHide={() => setConfirmDeletePanelDialogVisible(false)}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '1.5rem' }}
          />
          <span>{`Tem certeza de que deseja excluir o painel [${title}]?`}</span>
          <div className="p-fluid grid formgrid pt-5">
            <div className="field col-12 md:col-6">
              <Button
                label="Não"
                type="button"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
                onClick={() => setConfirmDeletePanelDialogVisible(false)}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Sim"
                icon="pi pi-check"
                className="p-button-success"
                type="button"
                loading={isLoad}
                onClick={() => {
                  handleDisablePanel();
                  setConfirmDeletePanelDialogVisible(false);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Transferir painel"
        visible={transferPanelDialogVisible}
        className="w-10 md:w-4"
        onHide={() => setTransferPanelDialogVisible(false)}
      >
        <Form
          ref={null}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
          onSubmit={handleTransferPanel}
        >
          <div className="field col-12">
            <label htmlFor="user">Usuário</label>
            <InputDropDown
              name="user"
              options={users}
              value={user}
              placeholder="Selecione..."
              onChange={e => setUser(e.value)}
              filter
            />
          </div>

          <div className="field col-12">
            <Button
              name="btn_transfer"
              type="button"
              onClick={handleTransferPanel}
              label="Transferir"
              loading={isLoad}
              className="p-button-success"
              icon="pi pi-check"
            />
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default SidebarPanel;
