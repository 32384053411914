/* eslint-disable react/jsx-no-undef */
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { InputMask } from '../../../../components/Inputs/InputMask';
import { InputText } from '../../../../components/Inputs/InputText';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api, { apiRfb } from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import { situacaoCadastral } from '../types/situacaoCadastral';
import Progress from '../../../../components/progress-bar';
import { Divider } from '../../../../components/Divider';
import situacaoCadastralOpts from '../../../../types/situacaoCadastralOpts';
import { Button } from 'primereact/button';
import { AxiosError, AxiosResponse } from 'axios';
import formatPhoneNumber from '../../../../utils/strings/formatPhoneNumber';
import { formatPostCode } from '../../../../utils/strings/formatPostCode';
import { emptyBusiness, IBusiness, ISocio } from '../dtos/IBusinessDTO';
import { convertStringToDate } from '../../../../utils/date/ConvertStringToDate';
import LimpaCpfCnpj from '../../../../utils/LimpaCpfCnpj';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
}

const SidebarRfbData = ({
  isOpen,
  onRequestClose,
  id,
}: IProps): React.ReactElement => {
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [data, setData] = useState<IBusiness>(emptyBusiness);

  const loadData = async (id: string) => {
    setLoad(true);
    await api
      .get(`/rfb/estabelecimento/cnpj/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          const socios: ISocio[] = [];
          res.data.socios.forEach((s: any) => {
            if (
              !socios.some(
                socio => socio.identificador_socio === s.identificador_socio,
              )
            )
              socios.push(s);
          });

          setData({
            ...res.data,
            telefone_1: formatPhoneNumber(res.data.telefone_1),
            cep: formatPostCode(res.data.cep),
            correio_eletronico: res.data.correio_eletronico.toLowerCase(),
            socios,
          });
        }
      })
      .catch((err: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [isOpen]);

  const createWhatsAppLink = (phone: string): string => {
    // Limpa o número do telefone, removendo qualquer caractere não numérico
    const cleanPhone = phone.replace(/\D/g, '');

    // Mensagem personalizada
    const message =
      'Oi, achei o contato da sua empresa no sitedareceita.com.br e queria tirar uma dúvida sobre a sua empresa. Você pode me ajudar?';

    // Codifica a mensagem para a URL
    const encodedMessage = encodeURIComponent(message);

    // Constrói o link do WhatsApp com o telefone e a mensagem codificada
    return `https://wa.me/55${cleanPhone}?text=${encodedMessage}`;
  };

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => {
          setData(emptyBusiness);
          onRequestClose();
        }}
        className="w-11 md:w-6"
      >
        <Progress isLoad={load} />
        <section>
          <h5 className="text-center">CADASTRO NACIONAL DA PESSOA JURÍDICA</h5>
          <Form
            ref={null}
            onSubmit={() => {}}
            className="p-fluid grid formgrid"
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="field col-8 mb-1 pb-1">
              <label htmlFor="cnpj">Número de inscrição (CNPJ)</label>
              <InputText
                name="cnpj"
                value={data.cnpj}
                placeholder="00.000.000/0000-00"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-4 mb-1 pb-1">
              <label htmlFor="data_inicio_atividade">Abertura</label>
              <InputText
                name="data_inicio_atividade"
                value={data.data_inicio_atividade}
                placeholder="00/00/0000"
                className="text-center surface-300"
                readOnly
              />
            </div>

            <div className="field col-12 mb-1 pb-1">
              <label htmlFor="razao_social">
                Nome empresarial (Razão social)
              </label>
              <InputText
                name="razao_social"
                value={data.empresa ? data.empresa.razao_social : '***'}
                placeholder="Ex.: Empresa Acme Ltda"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-12 mb-1 pb-1">
              <label htmlFor="nome_fantasia">Nome fantásia</label>
              <InputText
                name="nome_fantasia"
                value={data.nome_fantasia || '***'}
                placeholder="Ex.: Empresa Acme Ltda"
                className="surface-300"
                readOnly
              />
            </div>

            <Divider align="left">
              <div className="inline-flex align-items-center">
                <i className="pi pi-phone mr-2"></i>
                <b>Contatos</b>
              </div>
            </Divider>

            <div
              className={`field col-${
                data.telefone_1 && data.telefone_1 !== '' ? '10' : '12'
              } md:col-4 mb-1 pb-1`}
            >
              <label htmlFor="telefone" className="w-full">
                Telefone
              </label>
              <InputText
                name="telefone"
                value={data.telefone_1}
                placeholder="Ex.: (99) 99999-9999"
                className={`surface-300`}
                readOnly
              />
            </div>
            {data.telefone_1 && data.telefone_1 !== '' && (
              <div className="field col-2 md:col-1 flex align-items-end mb-1 pb-1">
                <Button
                  icon="pi pi-whatsapp"
                  type="button"
                  className="w-full"
                  onClick={() => {
                    const a = document.createElement('a');
                    a.href = createWhatsAppLink(data.telefone_1 || '');
                    a.target = '_blank';
                    a.click();
                  }}
                />
              </div>
            )}
            <div className={`field col mb-1 pb-1`}>
              <label htmlFor="email">E-mail</label>
              <InputText
                name="email"
                value={data.correio_eletronico || '***'}
                placeholder="Ex.: email@email.com"
                className="surface-300"
                readOnly
              />
            </div>

            {data.telefone_1 && data.telefone_1 !== '' && (
              <div className="field col-2 md:col-1 flex align-items-end mb-1 pb-1">
                <Button
                  icon="pi pi-envelope"
                  type="button"
                  className="w-full"
                  onClick={() => {
                    const a = document.createElement('a');
                    a.href = `https://mail.google.com/mail/?view=cm&fs=1&to=${data.correio_eletronico}`;
                    a.target = '_blank';
                    a.click();
                  }}
                />
              </div>
            )}

            <Divider align="left">
              <div className="inline-flex align-items-center">
                <i className="pi pi-map-marker mr-2"></i>
                <b>Endereço</b>
              </div>
            </Divider>

            {data.logradouro !== '' && (
              <>
                <div className="field col-12 md:col-4 mb-1 pb-1 flex align-items-end">
                  <Button
                    label="Procurar no Google Maps"
                    icon="pi pi-map"
                    type="button"
                    onClick={() => {
                      const a = document.createElement('a');
                      a.href = `https://google.com.br/maps/search/Rua ${data.logradouro} ${data.numero} ${data.bairro} ${data.cidade?.descricao} ${data.uf}`;
                      a.target = '_blank';
                      a.click();
                    }}
                  />
                </div>
              </>
            )}

            <div className="field col-6 md:col-3 mb-1 pb-1">
              <label htmlFor="cep">CEP</label>
              <InputText
                name="cep"
                value={data.cep}
                placeholder="Ex.: 99.999-999"
                className="text-center surface-300"
                readOnly
              />
            </div>

            <div className="field col-6 md:col-2 mb-1 pb-1">
              <label htmlFor="numero">Número</label>
              <InputText
                name="numero"
                value={data.numero}
                placeholder="S/N"
                className="text-center surface-300"
                readOnly
              />
            </div>

            <div className="md:col-7"></div>

            <div className="field col-12 md:col-7 mb-1 pb-1">
              <label htmlFor="logradouro">Logradouro</label>
              <InputText
                name="logradouro"
                value={data.logradouro}
                placeholder="Ex.: Rua da Amizade"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-12 md:col-5 mb-1 pb-1">
              <label htmlFor="bairro">Bairro</label>
              <InputText
                name="bairro"
                value={data.bairro || '***'}
                placeholder="Ex.: Centro"
                className="surface-300"
                readOnly
              />
            </div>
            <div className="field col-12 md:col-6 mb-1 pb-1">
              <label htmlFor="complemento">Complemento</label>
              <InputText
                name="complemento"
                value={data.complemento || '***'}
                placeholder="Ex.: Qd. 01 Lt. 01"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-8 md:col-4 mb-1 pb-1">
              <label htmlFor="cidade">Cidade</label>
              <InputText
                name="cidade"
                value={data && data.cidade ? data.cidade.descricao : '***'}
                placeholder="Ex.: Goiânia"
                className="text-center surface-300"
                readOnly
              />
            </div>

            <div className="field col-4 md:col-2 mb-1 pb-1">
              <label htmlFor="uf">UF</label>
              <InputText
                name="uf"
                value={data.uf}
                placeholder="Ex.: GO"
                className="text-center surface-300"
                readOnly
              />
            </div>

            <Divider align="left">
              <div className="inline-flex align-items-center">
                <i className="pi pi-briefcase mr-2"></i>
                <b>Fiscal</b>
              </div>
            </Divider>

            <div className="field col-4 mb-1 pb-1">
              <label htmlFor="porte">Porte</label>
              <InputText
                name="porte"
                value={'***'}
                placeholder="Ex.: Empreendedor indivídual"
                className="surface-300"
                readOnly
              />
            </div>
            <div className="field col-8 mb-1 pb-1">
              <label htmlFor="nat_ju">Natureza Jurídica</label>
              <InputText
                name="nat_ju"
                value={'***'}
                placeholder="Ex.: 999-9 - Sociedade XYZ"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-12 mb-1 pb-1">
              <label htmlFor="cnae_pricipal">
                Atividade Econômica Principal (CNAE)
              </label>
              <InputText
                name="cnae_pricipal"
                value={
                  data.cnaePrincipal
                    ? `${data.cnaePrincipal.codigo} - ${data.cnaePrincipal.descricao}`
                    : '***'
                }
                placeholder="Ex.: 99.99-9-99 - Ramo de atividade xyz"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-12 mb-1 pb-1">
              <label htmlFor="ente_federativo">
                Ente Federativo Responsável (EFR)
              </label>
              <InputText
                name="ente_federativo"
                value={data.pais || '***'}
                placeholder="Ex.: BRASIL"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-5 md:col-8 mb-1 pb-1">
              <label htmlFor="situacao_cadastral">Situação cadastral</label>
              <InputText
                name="situacao_cadastral"
                value={
                  situacaoCadastralOpts.find(
                    (i: IOptionsDTO) => i.value === data.situacao_cadastral,
                  )?.label
                }
                placeholder="Ex.: ATIVA"
                className="surface-300"
                readOnly
              />
            </div>

            <div className="field col-7 md:col-4 mb-1 pb-1">
              <label htmlFor="data_situacao_cadastral">
                Data da situação cadastral
              </label>
              <InputText
                name="data_situacao_cadastral"
                placeholder="***"
                value={data.data_situacao_cadastral}
                className="surface-300 text-center"
                readOnly
              />
            </div>

            <DataTable
              header={<h5>Atividades secundárias</h5>}
              value={data.cnaesSecundarios}
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Sem atividades secundárias..."
              selectionMode="single"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate={`Exibindo {first} / {last} de {totalRecords} atividades secundárias.`}
              className="field col-12 mb-1 pb-1"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column header="Código" field="codigo"></Column>
              <Column header="Descrição" field="descricao"></Column>
            </DataTable>
            <DataTable
              header={<h5>Sócios</h5>}
              value={data.socios}
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Sem sócios..."
              selectionMode="single"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate={`Exibindo {first} / {last} de {totalRecords} sócios.`}
              className="field col-12 mb-1 pb-1"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column header="Nome" field="nome_socio_razao_social"></Column>
              <Column header="CPF/CNPJ" field="cpf_cnpj_socio"></Column>
              <Column
                header="Data entrada"
                body={e =>
                  convertStringToDate(LimpaCpfCnpj(e.data_entrada_sociedade))
                }
              ></Column>
            </DataTable>
          </Form>
        </section>
      </SideBar>
    </>
  );
};

export default SidebarRfbData;
