export default function formatDecimal(
  value: string | number,
  decimals?: number,
): string {
  let formatedValue: string = '';

  // Converte string para number
  if (typeof value === 'string') {
    const conValue = Number(value.replace(',', '.'));
    formatedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: decimals || 2,
      maximumFractionDigits: decimals || 2,
    }).format(conValue);
  }

  if (typeof value === 'number') {
    formatedValue = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: decimals || 2,
      maximumFractionDigits: decimals || 2,
    }).format(value);
  }

  return formatedValue;
}
