import React, { useContext, useEffect } from 'react';
import {
  Redirect,
  RouteProps,
  Route as RouterDom,
  useLocation,
} from 'react-router-dom';

/** context */
import { AppContext } from '../context/AppContext';
import { countHits } from '../utils/count_hits';

interface RoutePropPrivate extends RouteProps {
  isPrivate: boolean;
  component: React.ComponentType;
}

export const RoutePrivate: React.FC<RoutePropPrivate> = ({
  component: Component,
  ...rest
}) => {
  const { user } = JSON.parse(localStorage.getItem('user') || '{}');
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(AppContext);

  const setFavorites = async () => {
    if (pathname) {
      countHits(state, pathname, dispatch);
    }
  };

  useEffect(() => {
    setFavorites();
  }, [pathname]);

  return (
    <RouterDom
      {...rest}
      render={() => {
        return user?.token ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: '/signin' }} />
        );
      }}
    />
  );
};
