import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { addMonths, format } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import {
  Dialog as ModalAdicComplementar,
  Dialog as ModalDescComplementar,
  Dialog as ModalPayments,
  Dialog as ModalTransporter,
} from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import Round from '../../../utils/Round';
import freteOpts from '../../../utils/all_kind_frete';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { currencyToFloat } from '../../../utils/number';
import PerfectDivision from '../../../utils/perfectDivision';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IProductInOpts from '../../order/Create/dtos/IProductInOpts';
import { FastFormPerson } from '../../person/fast-form-person';
import InputProductsHeader from '../input-products-header';
import IInputItensDTO from './dtos/IInputItensDTO';
import IItensPagamentoDTO from './dtos/IItensPagamentoDTO';
import IRequestDTO from './dtos/IRequestDTO';
import { EnumBehavior } from '../../../enum/EnumBehavior';
import { EnumStatus } from '../../../enum/EnumStatus';

const InputProduct: React.FC = () => {
  const hoje = new Date();
  const toast = useToastContext();

  const inputRef = useRef<HTMLInputElement>(null);

  /** states */
  const [loadingStatus, setLoad] = useState<boolean>(false);
  const [productFocus, setProductFocus] = useState<boolean>(false);

  const [fornecedor, setFornecedor] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });
  const [fornecedorOpts, setFornecedorOpts] = useState<IOptionsDTO[]>([]);

  const [dataEmissao, setDataEmissao] = useState<Date | Date[] | undefined>(
    hoje,
  );
  const [dataEntrada, setDataEntrada] = useState<Date | Date[] | undefined>(
    hoje,
  );
  const [naturezaOperacao, setNaturezaOperacao] = useState<string>();
  const [naturezaOperacaoOpts, setNaturezaOperacaoOpts] = useState<
    IOptionsDTO[]
  >([]);
  const [serie, setSerie] = useState<string>();
  const [numero, setNumero] = useState<string>();
  const [valorDocumento, setValorDocumento] = useState<number>(0);

  const [rowId, setRowId] = useState<string>();

  const [produto, setProduto] = useState<IProductInOpts>({
    sku: '',
    label: '',
    value: '',
    qnt_in: 2,
    price_in: 2,
    stock: 0,
    image_url: '',
  });
  const [produtosOpts, setProdutosOpts] = useState<IProductInOpts[]>([]);
  const [qntIn, setQntIn] = useState<number>(2);
  const [priceIn, setPriceIn] = useState<number>(2);

  const [quantidade, setQuantidade] = useState<number>(0);

  const [vlrUnit, setVlrUnit] = useState<number>(0);
  const [ttVlrUnit, setTtVlrUnit] = useState<number>(0);

  const [adic, setAdic] = useState<number>(0);
  const [ttAdic, setTtAdic] = useState<number>(0);

  const [desc, setDesc] = useState<number>(0);
  const [ttDesc, setTtDesc] = useState<number>(0);

  const [vlrLiq, setVlrLiq] = useState<number>(0);
  const [ttVlrLiq, setTtVlrLiq] = useState<number>(0);

  const [itensEntrada, setItensEntrada] = useState<IInputItensDTO[]>([]);

  const [transportadora, setTransportadora] = useState<IOptionsDTO>();
  const [transportadoraOpts, setTransportadoraOpts] = useState<IOptionsDTO[]>(
    [],
  );
  const [tipoFrete, setTipoFrete] = useState<string>(freteOpts[1].value);
  const [ctSerie, setCtSerie] = useState<string>();
  const [ctNumero, setCtNumbero] = useState<string>();
  const [freteValor, setFreteValor] = useState<number>(0);

  const [pagVencimento, setPagVencimento] = useState<
    Date | Date[] | undefined
  >();
  const [pagValor, setPagValor] = useState<number>();
  const [pagCentroCusto, setPagCentroCusto] = useState<string>();
  const [centroCustoOpts, setCentroCustoOpts] = useState<IOptionsDTO[]>([]);
  const [pagParcelas, setPagParcelas] = useState<number>();
  const [pagObs, setPagObs] = useState<string>();

  const [itensPagamentoTmp, setItensPagamentoTmp] = useState<
    IItensPagamentoDTO[]
  >([]);
  const [itensPagamento, setItensPagamento] = useState<IItensPagamentoDTO[]>(
    [],
  );

  const [totalItens, setTotalItens] = useState<number>(0);
  const [totalQnt, setTotalQnt] = useState<number>(0);
  const [totalUnit, setTotalUnit] = useState<number>(0);
  const [totalDescontos, setTotalDescontos] = useState<number>(0);
  const [totalAdicionais, setTotalAdicionais] = useState<number>(0);
  const [totalEntrada, setTotalEntrada] = useState<number>(0);

  const [adicComplementar, setAdicComplementar] = useState<number>(0);
  const [descComplementar, setDescComplementar] = useState<number>(0);

  const [providerModalVisible, setProviderModalVisible] =
    useState<boolean>(false);
  const [productModalVisible, setProductModalVisible] =
    useState<boolean>(false);
  const [transporterModalVisible, setTransporterModalVisible] =
    useState<boolean>(false);
  const [paymentModalVisible, setPaymentModalVisible] =
    useState<boolean>(false);

  const [modalAdicComplementarVisible, setModalAdicComplementarVisible] =
    useState<boolean>(false);
  const [modalDescComplementarVisible, setModalDescComplementarVisible] =
    useState<boolean>(false);

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const formRefRow = useRef<FormHandles>(null);
  const formRefTransporter = useRef<FormHandles>(null);
  const formRefPayment = useRef<FormHandles>(null);

  /** hooks */
  const navigate = useHistory();

  /** functions */
  const handleSubmit = async () => {
    try {
      setLoad(true);
      formRef.current?.setErrors({});

      if (!validate(fornecedor.value)) {
        toast('warn', 'Alerta', 'Informe um fornecedor na entrada!');
        return;
      }

      if (!validate(naturezaOperacao || '')) {
        toast('warn', 'Alerta', 'Informe um fornecedor na entrada!');
        return;
      }

      if (!serie) {
        toast('warn', 'Alerta', 'Informe a série da entrada!');
        return;
      }

      if (!numero) {
        toast('warn', 'Alerta', 'Informe o número da entrada!');
        return;
      }

      if (!valorDocumento || valorDocumento === 0) {
        toast('warn', 'Alerta', 'Informe um valor para a entrada!');
        return;
      }

      if (itensEntrada.length === 0) {
        toast('warn', 'Alerta', 'Informe os itens da entrada!');
        return;
      }

      itensEntrada.forEach(i => {
        if (!validate(i.product_id)) {
          toast(
            'warn',
            'Alerta',
            `Informe novamente o item.: ${i.product_description} pois seus dados estão inconsistentes no formulário!`,
          );
          return;
        }
      });

      if (Round(valorDocumento) !== Round(totalEntrada)) {
        formRef.current?.setErrors({
          doc_value: 'Corrigir valor do documento!',
        });

        toast(
          'warn',
          'Alerta',
          `O total do documento ${new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            valorDocumento,
          )} é diferente do total apurado da entrada ${new Intl.NumberFormat(
            'pt-BR',
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
          ).format(totalEntrada)}`,
        );
        return;
      }

      const input: IRequestDTO = {
        input_header: {
          provider_id: fornecedor.value,
          nat_operation_id: naturezaOperacao || '',
          doc_serie: serie || '',
          doc_number: numero || '',
          doc_value: valorDocumento,
          date_emited: dataEmissao as Date,
          date_in: dataEntrada as Date,
          additional_info: '',
          discount_value: totalDescontos,
          additional_value: totalAdicionais,
          obs: `${pagObs}`,
        },
        input_items: itensEntrada.map(i => {
          return {
            ...i,
          };
        }),
        input_transporter: {
          transporter_id: transportadora ? transportadora.value : null,
          kind_freight: tipoFrete || null,
          ct_serie: ctSerie || null,
          ct_number: ctNumero || null,
          freight_value: freteValor || null,
        },
        input_bills:
          itensPagamento.length > 0
            ? itensPagamento.map(i => {
                return {
                  id: i.id,
                  cost_center_id: i.centro_custo_id,
                  date_to_pay: i.vencimento,
                  parcel: i.parcela,
                  parcels: i.parcelas,
                  value_doc: i.valorDocumento,
                  value_parcel: i.valorParcela,
                };
              })
            : [],
      };

      const res = await api.post('/input-products', input);

      if (res.status === 200) {
        toast('success', 'Sucesso', 'Entrada realizada com sucesso!');
      }

      navigate.push('/input-products/list');
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', error.response?.data.validation.body.message);
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleSubmitTransporter = async (data: any) => {
    try {
      setLoad(true);

      formRefTransporter.current?.setErrors({});

      data.ct_value = currencyToFloat(data.ct_value);

      const schemaValidation = Yup.object().shape({
        transporter_id: Yup.string()
          .uuid('O id do transportador é inválido!')
          .required('Transportadorar é obrigatória!'),
        kind_frete: Yup.string().required('Informe o tipo de frete!'),
        ct_serie: Yup.string().required(
          'Informe a série do reconhecimento de frete!',
        ),
        ct_numero: Yup.string().required(
          'Informe o número do reconhecimento de frete!',
        ),
        ct_value: Yup.number().positive(),
      });

      await schemaValidation.validate(data, { abortEarly: false });

      setTransporterModalVisible(false);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefTransporter.current?.setErrors(errors);

        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleSubmitPayment = async (data: any) => {
    setItensPagamento(itensPagamentoTmp);
    setPaymentModalVisible(false);
  };

  const handleGeneratePaymentsParcels = async () => {
    if (pagValor === 0 || pagValor === undefined) {
      formRefPayment.current?.setErrors({ valor: 'Informe um valor!' });
      toast('warn', 'Alerta', 'Informe um valor para pagamento!');
      return;
    }

    if (pagParcelas === 0 || pagParcelas === undefined) {
      formRefPayment.current?.setErrors({
        parcelas: 'Informe uma quantidade de parcelas!',
      });
      toast('warn', 'Alerta', 'Informe um valor para as parcelas!');
      return;
    }

    if (pagVencimento === undefined) {
      formRefPayment.current?.setErrors({
        vencimento: 'Informe um centro de custo!',
      });
      toast('warn', 'Alerta', 'Informe um centro de custos!');
      return;
    }

    if (pagCentroCusto === undefined) {
      formRefPayment.current?.setErrors({
        cost_center: 'Informe um centro de custo!',
      });
      toast('warn', 'Alerta', 'Informe um centro de custos!');
      return;
    }

    const p = PerfectDivision(pagValor, pagParcelas);
    const pgtos: IItensPagamentoDTO[] = [];

    for (let i = 0; i < pagParcelas; i++) {
      const pgto: IItensPagamentoDTO = {
        id: v4(),
        centro_custo_id: pagCentroCusto,
        parcela: i + 1,
        parcelas: pagParcelas,
        valorDocumento: pagValor,
        valorParcela: p[i],
        vencimento: addMonths(pagVencimento as Date, i),
      };

      pgtos.push(pgto);
    }

    setItensPagamentoTmp(pgtos);
  };

  const sharedDescComplementar = () => {
    if (itensEntrada.length > 0) {
      const division = PerfectDivision(descComplementar, totalItens);

      const itensEntradaRecalculados: IInputItensDTO[] = [];
      for (let i = 0; i < totalItens; i++) {
        if (division[i] > itensEntrada[i].vlr_liq_total) {
          toast(
            'warn',
            'Falha',
            'Não é possível dar um desconto maior que o valor do produto!',
          );
        }
        itensEntradaRecalculados.push({
          ...itensEntrada[i],
          desc: division[i] / itensEntrada[i].qnt,
          tt_desc: division[i],
        });
      }

      setItensEntrada(itensEntradaRecalculados);
    }
  };

  const sharedAdicComplementar = () => {
    if (itensEntrada.length > 0) {
      const division = PerfectDivision(adicComplementar, totalItens);

      const itensEntradaRecalculados: IInputItensDTO[] = [];

      for (let i = 0; i < totalItens; i++) {
        if (division[i] > itensEntrada[i].vlr_liq_total) {
          toast(
            'warn',
            'Falha',
            'Não é possível dar um desconto maior que o valor do produto!',
          );
        }

        itensEntradaRecalculados.push({
          ...itensEntrada[i],
          adic: division[i] / itensEntrada[i].qnt,
          tt_adic: division[i],
        });
      }

      setItensEntrada(itensEntradaRecalculados);
    }
  };

  const searchProvider = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      setLoad(true);
      await api
        .post(`/persons/provider/src`, {
          data: {
            search: `${e.query}`,
            status: ['A'],
          },
        })
        .then(({ data }) => {
          if (data) {
            setFornecedorOpts(
              data.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              }),
            );
          } else {
            toast('error', 'Error', 'Nenhum fornecedor encontrado!');
          }
        })
        .catch((error: any) => {
          toast('error', 'Error', error.response.data.error);
          return;
        })
        .finally(() => setLoad(false));
    }
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    formRef.current?.setErrors({});

    if (fornecedor.value === '' || !fornecedor.value) {
      formRef.current?.setErrors({ provider_id: 'Informe um fornecedor!' });
      toast(
        'warn',
        'Alerta',
        'Informe um fornecedor para a entrada de produtos!',
      );
      return;
    }

    if (naturezaOperacao === '' || !naturezaOperacao) {
      formRef.current?.setErrors({
        nat_ope_id: 'Informe uma natureza de operação!',
      });
      toast(
        'warn',
        'Alerta',
        'Informe uma natureza de operação para fazer a entrada!',
      );
      return;
    }

    if (serie === '' || !serie) {
      formRef.current?.setErrors({ serie: 'Informe uma serie!' });
      toast('warn', 'Alerta', 'Informe a série do documento!');
      return;
    }

    if (numero === '' || !numero) {
      formRef.current?.setErrors({ number: 'Informe o número do documento!' });
      toast('warn', 'Alerta', 'Informe o número do documento!');
      return;
    }

    if (valorDocumento === 0 || !valorDocumento) {
      toast('warn', 'Alerta', 'Informe o valor do documento!');
      formRef.current?.setErrors({
        doc_value: 'Informe o número do documento!',
      });
      return;
    }

    if (e.query.length >= 3) {
      await api
        .get(`/products/v2/search?src=${e.query}`)
        .then(({ data }) => {
          const opts = data.products.map((item: any) => {
            return {
              value: item.id,
              label: item.titulo,
              sku: item.sku || '',
              stock: item.estoque,
              price_in: item.price_in,
              qnt_in: item.qnt_in,
            };
          });

          if (opts.length <= 0) {
            toast('error', 'Error', 'Nenhum produto encontrado!');
          }

          setProdutosOpts(opts);
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast('warn', 'Alerta', e.response.data.error);
          } else {
            toast('error', 'Erro', e.message);
          }
        });
    }
  };

  const handleProductSelected = (e: any) => {
    handleClearProductRowOnSelected();
    setProduto(e.value);
    setQntIn(e.qnt_in || 2);
    setPriceIn(e.price_in || 2);
  };

  const handleAddItem = async (data: any) => {
    try {
      setLoad(true);
      formRefRow.current?.setErrors({});

      data.product_id = produto.value;

      if (!validate(data.product_id)) {
        setProductFocus(true);
        toast(
          'warn',
          'Alerta',
          'Buscar o produto novamente, falha na inserção!',
        );
        return;
      }

      data.adic = currencyToFloat(data.adic);
      data.desc = currencyToFloat(data.desc);
      data.qnt = currencyToFloat(data.qnt);
      data.total = currencyToFloat(data.total);
      data.vlr_liq = currencyToFloat(data.vlr_liq);
      data.vlr_unit = currencyToFloat(data.vlr_unit);

      const schemaValidation = Yup.object().shape({
        product_id: Yup.string().uuid().required('Produto é obrigatório!'),
        qnt: Yup.number()
          .positive('Informe uma quantidade valida!')
          .required('Quantidade é obrigatória!'),
        vlr_unit: Yup.number().min(0).required('Valor unitário é obrigatório!'),
        adic: Yup.number().min(0).required(),
        desc: Yup.number()
          .min(0)
          .max(vlrUnit, 'O desconto não pode ser maior que o valor unitário!')
          .required(),
        vlr_liq: Yup.number().positive(
          'Valorlíquido deve ser um número positivo!',
        ),
        total: Yup.number().positive('Total deve ser um número positivo!'),
      });

      await schemaValidation.validate(data, { abortEarly: false });

      const newItem: IInputItensDTO = {
        id: v4(),
        position: itensEntrada.length + 1,
        sku: produto.sku,
        product_id: produto.value,
        product_description: produto.label,
        qnt_in: produto.qnt_in,
        price_in: produto.price_in,

        qnt: Number(quantidade),

        vlr_unit: Number(vlrUnit),
        tt_unit: Number(ttVlrUnit),

        desc: Number(desc),
        tt_desc: Number(ttDesc),

        adic: Number(adic),
        tt_adic: Number(ttAdic),

        ali_ipi: 0,
        ipi: 0,
        tt_ipi: 0,

        ali_pis: 0,
        pis: 0,
        tt_pis: 0,

        ali_cofins: 0,
        cofins: 0,
        tt_cofins: 0,

        vlr_liq: Number(vlrLiq),
        vlr_liq_total: Number(ttVlrLiq),

        inserted_at: hoje,
      };

      setItensEntrada([...itensEntrada.filter(r => r.id !== rowId), newItem]);

      handleClearProductRow();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', error.response?.data.validation.body.message);
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefRow.current?.setErrors(errors);

        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoad(false);
      setProductFocus(true);
      inputRef?.current?.focus();
    }
  };

  const handleClearProductRowOnSelected = () => {
    setRowId('');
    setQuantidade(0);
    setVlrUnit(0);
    setTtVlrUnit(0);
    setDesc(0);
    setTtDesc(0);
    setAdic(0);
    setTtAdic(0);
    setTtVlrLiq(0);
    setVlrLiq(0);
    setTtVlrLiq(0);
  };

  const handleClearProductRow = () => {
    setRowId('');
    setProduto({
      sku: '',
      label: '',
      value: '',
      stock: 0,
      price_in: 2,
      qnt_in: 2,
      image_url: '',
    });
    setQuantidade(0);
    setVlrUnit(0);
    setTtVlrUnit(0);
    setDesc(0);
    setTtDesc(0);
    setAdic(0);
    setTtAdic(0);
    setTtVlrLiq(0);
    setVlrLiq(0);
    setTtVlrLiq(0);
  };

  const handleClearFormTransporter = () => {
    setTransportadora({
      label: '',
      value: '',
    });
    setTipoFrete(freteOpts[1].value);
    setCtSerie('');
    setCtNumbero('');
    setFreteValor(0);
    setTransporterModalVisible(false);
  };

  const handleSelectItemEntrada = (rowId: string) => {
    const item = itensEntrada.find(i => i.id === rowId);

    if (item) {
      setRowId(item.id);
      setProduto({
        sku: item.sku,
        label: item.product_description,
        value: item.product_id,
        stock: 0,
        price_in: item.price_in,
        qnt_in: item.qnt_in,
        image_url: '',
      });
      setQuantidade(item.qnt);
      setVlrUnit(item.vlr_unit);
      setTtVlrUnit(item.tt_unit);
      setDesc(item.desc);
      setTtDesc(item.tt_desc);
      setAdic(item.adic);
      setTtAdic(item.tt_adic);
      setVlrLiq(item.vlr_liq);
      setTtVlrLiq(item.vlr_liq_total);
    } else {
      toast('warn', 'Alerta', 'Falha ao buscar o item selecionado!');
      return;
    }
  };

  const handleRemItemEntrada = (rowId: string) => {
    const itensNoId = itensEntrada.filter(r => r.id !== rowId);
    setItensEntrada(itensNoId);
    handleClearProductRow();
  };

  const handleClearFormPayments = () => {
    setPagCentroCusto('');
    setPagValor(0);
    setPagParcelas(0);
    setPagVencimento(undefined);
    setPagObs('');
    setItensPagamento([]);
    setItensPagamentoTmp([]);
  };

  const handleOpenProviderModal = () => {
    setProviderModalVisible(true);
  };

  const handleOpenProductModal = () => {
    setProductModalVisible(true);
  };

  const handleOpenTransporterModal = () => {
    setTransporterModalVisible(true);
  };

  const handleOpenPaymentModalVisible = () => {
    if (pagValor === 0 || pagValor === undefined) {
      setPagValor(totalEntrada);
      setPagParcelas(1);
      setPagVencimento(hoje);
    }

    setPaymentModalVisible(true);
  };

  /** useEffect */
  useEffect(() => {
    setLoad(true);
    api
      .get('/params/input/default')
      .then(({ data }) => {
        setNaturezaOperacao(data.defaultInputParams.natureza_operacao.id);
      })
      .finally(() => setLoad(false));
    setLoad(true);

    api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['entrada', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setNaturezaOperacaoOpts(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      })
      .finally(() => setLoad(false));

    setLoad(true);
    api
      .post('/persons/transporters', {
        pagination: {
          page: 1,
          perPage: 100,
          status: [EnumStatus.ATIVO],
        },
      })
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setTransportadoraOpts(opts);
      })
      .finally(() => setLoad(false));

    setLoad(true);
    api
      .get('cost-center')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });

        setCentroCustoOpts(opts);
      })
      .finally(() => setLoad(false));
  }, []);

  /** calcula os itens da linha de inserção  */
  useEffect(() => {
    const _ttVlrUnit = vlrUnit * quantidade;
    setTtVlrUnit(_ttVlrUnit);

    const _ttDesc = desc * quantidade;
    setTtDesc(_ttDesc);

    const _ttAdic = adic * quantidade;
    setTtAdic(_ttAdic);

    const _vlrLiq = vlrUnit + adic - desc;
    setVlrLiq(_vlrLiq);

    const _ttVlrLiq = (vlrUnit + adic - desc) * quantidade;
    setTtVlrLiq(_ttVlrLiq);
  }, [
    itensEntrada,
    produto,
    quantidade,
    vlrUnit,
    desc,
    adic,
    vlrLiq,
    ttVlrUnit,
  ]);

  useEffect(() => {
    const itensEntradaRecalculados: IInputItensDTO[] = [];

    for (const item of itensEntrada) {
      const _vlrLiq = item.vlr_unit + item.adic - item.desc;
      const _ttVlrLiq = (item.vlr_unit + item.adic - item.desc) * item.qnt;

      itensEntradaRecalculados.push({
        ...item,
        vlr_liq: _vlrLiq,
        vlr_liq_total: _ttVlrLiq,
      });
    }

    setItensEntrada(itensEntradaRecalculados);
  }, [modalAdicComplementarVisible, modalDescComplementarVisible]);

  /** calcula os totais dos itens da tabela */
  useEffect(() => {
    const summaryTotais = itensEntrada.reduce(
      (acc, item) => {
        acc.totalItens++;
        acc.totalQnt += item.qnt;
        acc.totalUnit += item.tt_unit;
        acc.totalDescontos += item.tt_desc;
        acc.totalAdicionais += item.tt_adic;
        acc.totalEntrada += item.vlr_liq_total;
        return acc;
      },
      {
        totalItens: 0,
        totalQnt: 0,
        totalUnit: 0,
        totalDescontos: 0,
        totalAdicionais: 0,
        totalEntrada: 0,
      },
    );

    setTotalItens(summaryTotais.totalItens);
    setTotalQnt(summaryTotais.totalQnt);
    setTotalUnit(summaryTotais.totalUnit);
    setTotalDescontos(summaryTotais.totalDescontos);
    setTotalAdicionais(summaryTotais.totalAdicionais);
    setTotalEntrada(summaryTotais.totalEntrada + freteValor);
  }, [itensEntrada, freteValor, adicComplementar, descComplementar]);

  /** render */
  const itemTemplateAutoComplete = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const renderModalDescComplementarFooter = () => {
    return (
      <div>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-outlined p-button-danger"
          onClick={() => setModalDescComplementarVisible(false)}
        />
        <Button
          label="Aplicar"
          icon="pi pi-check"
          className="p-button-outlined p-button-primary"
          onClick={() => {
            setModalDescComplementarVisible(false);
            sharedDescComplementar();
          }}
        />
      </div>
    );
  };

  const renderModalAdicComplementarFooter = () => {
    return (
      <div>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-outlined p-button-danger"
          onClick={() => setModalAdicComplementarVisible(false)}
        />
        <Button
          label="Aplicar"
          icon="pi pi-check"
          className="p-button-outlined p-button-primary"
          onClick={() => {
            setModalAdicComplementarVisible(false);
            sharedAdicComplementar();
          }}
        />
      </div>
    );
  };

  const formatTableColumnToDecimal = (row: any, property: string) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(row[property]);
  };

  const formatTableColumnToDate = (rowData: any) => {
    return <span>{format(rowData.vencimento, 'dd/MM/yyyy')}</span>;
  };

  const formatTableColumnId = (rowData: any, attr: any) => {
    return <span>{attr.rowIndex + 1}</span>;
  };

  const buttonRemItemTable = (rowId: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItemEntrada(rowId)}
        />
      </div>
    );
  };

  return (
    <>
      <InputProductsHeader />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={() => null}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="provider_id">
                Fornecedor
                <i
                  className={`pi ${
                    validate(fornecedor.value)
                      ? 'pi-search text-blue-500'
                      : 'pi-plus-circle text-green-500'
                  } ml-2 cursor-pointer icon-sidebar-person`}
                  onClick={() => handleOpenProviderModal()}
                ></i>
              </label>
              <InputAutoComplete
                name="provider_id"
                suggestions={fornecedorOpts}
                value={fornecedor}
                completeMethod={e => searchProvider(e)}
                field="label"
                onChange={e => setFornecedor(e.value)}
                itemTemplate={itemTemplateAutoComplete}
                placeholder="Buscar fornecedor..."
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="date_emited">Data emissão</label>
              <Calendar
                name="date_emited"
                value={dataEmissao}
                onChange={e => setDataEmissao(e.value)}
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="date_in">Data entrada</label>
              <Calendar
                name="date_in"
                value={dataEntrada}
                onChange={e => setDataEntrada(e.value)}
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="nat_ope_id">Natureza de operação</label>
              <InputDropDown
                name="nat_ope_id"
                options={naturezaOperacaoOpts}
                value={naturezaOperacao}
                placeholder="Selecionar..."
                onChange={e => setNaturezaOperacao(e.value)}
                emptyMessage="Não existe fornecedores"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="serie">Serie</label>
              <InputText
                name="serie"
                value={serie}
                onChange={e => setSerie(e.currentTarget.value)}
                placeholder="000"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="number">Numero</label>
              <InputText
                name="number"
                value={numero}
                keyfilter="int"
                onChange={e => setNumero(e.currentTarget.value)}
                placeholder="000"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_value">Valor</label>
              <InputNumber
                name="doc_value"
                value={valorDocumento}
                onChange={e => setValorDocumento(Number(e.value))}
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <hr />
          </div>
        </Form>

        <Divider type="dashed">
          <span className="p-tag">
            <i className="fa-solid fa-boxes-stacked mx-2"></i>
            ITENS
          </span>
        </Divider>

        <Form
          ref={formRefRow}
          onSubmit={handleAddItem}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="product_id">
                Produto
                <i
                  className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                  onClick={() => handleOpenProductModal()}
                ></i>
              </label>
              <InputAutoComplete
                inputRef={inputRef}
                name="product_id"
                suggestions={produtosOpts}
                value={produto}
                completeMethod={e => searchProduct(e)}
                field="label"
                onSelect={e => handleProductSelected(e)}
                onChange={e => setProduto(e.value)}
                itemTemplate={itemTemplateAutoComplete}
                placeholder="Buscar produto..."
                autoFocus={productFocus}
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Qnt.</label>
              <InputNumber
                name="qnt"
                value={quantidade}
                onValueChange={e => setQuantidade(Number(e.value))}
                mode="decimal"
                minFractionDigits={qntIn}
                placeholder="0,00"
                maxFractionDigits={qntIn}
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Valor Unit.</label>
              <InputNumber
                name="vlr_unit"
                value={vlrUnit}
                onValueChange={e => setVlrUnit(Number(e.value))}
                mode="decimal"
                minFractionDigits={priceIn}
                maxFractionDigits={priceIn}
                placeholder="0,00"
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Adic.</label>
              <InputNumber
                name="adic"
                value={adic}
                onValueChange={e => setAdic(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00"
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Desc.</label>
              <InputNumber
                name="desc"
                value={desc}
                onValueChange={e => setDesc(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00"
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Vlr. Liq.</label>
              <InputNumber
                name="vlr_liq"
                value={vlrLiq}
                onValueChange={e => setVlrLiq(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00"
                disabled
                className="surface-200"
              />
            </div>

            <div className="field col-12 md:col-1 pr-0">
              <label htmlFor="qnt">Total</label>
              <InputNumber
                name="total"
                value={ttVlrLiq}
                onValueChange={e => setTtVlrLiq(Number(e.value))}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00"
                disabled
                className="surface-200"
              />
            </div>

            <div className="field col-12 md:col-2 flex justify-content-center align-items-end">
              <Button
                type="submit"
                loading={loadingStatus}
                className="p-button-success"
                icon="pi pi-plus"
              />

              <Button
                type="reset"
                loading={loadingStatus}
                className="p-button-danger ml-3"
                icon="pi pi-minus"
              />
            </div>
          </div>
        </Form>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <DataTable
              value={itensEntrada}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Produtos não informados..."
              selectionMode="single"
              onRowSelect={e => handleSelectItemEntrada(e.data.id)}
            >
              <Column
                className="my-0 py-1"
                field="sku"
                header="sku"
                sortable
              ></Column>
              <Column
                className="my-0 py-1"
                field="product_description"
                header="Produto"
                sortable
              ></Column>
              <Column
                className="my-0 py-1"
                field="qnt"
                header="Qnt."
                body={row => formatTableColumnToDecimal(row, 'qnt')}
                sortable
              ></Column>
              <Column
                className="my-0 py-1"
                field="vlr_unit"
                header="Vlr."
                body={row => formatTableColumnToDecimal(row, 'vlr_unit')}
                sortable
              ></Column>
              <Column
                className="my-0 py-1"
                field="adic"
                header="Adic."
                body={row => formatTableColumnToDecimal(row, 'adic')}
              ></Column>
              <Column
                className="my-0 py-1"
                field="desc"
                header="Desc."
                body={row => formatTableColumnToDecimal(row, 'desc')}
              ></Column>
              <Column
                className="my-0 py-1"
                field="vlr_liq"
                header="Vlr.Liq."
                body={row => formatTableColumnToDecimal(row, 'vlr_liq')}
              ></Column>
              <Column
                className="my-0 py-1"
                field="vlr_liq_total"
                header="Total"
                body={row => formatTableColumnToDecimal(row, 'vlr_liq_total')}
              ></Column>
              <Column header="*" className="my-0 py-1"></Column>
              <Column
                className="my-0 py-1"
                body={row => buttonRemItemTable(row.id)}
              ></Column>
            </DataTable>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div
            className="field col-12 md:col-4"
            onClick={() => handleOpenTransporterModal()}
          >
            <Divider align="left" type="dashed" className="cursor-pointer">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="fa-solid fa-truck mx-2"></i>
                  TRANSPORTADORA
                </span>

                <i className="pi pi-plus-circle text-green-500 ml-2"></i>
              </div>
            </Divider>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Transportadora</div>
              <div className="col-6 md:col-6 text-right">
                {transportadora?.label}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Tipo frete</div>
              <div className="col-6 md:col-6 text-right">{tipoFrete}</div>
            </div>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Rec. de frete</div>
              <div className="col-6 md:col-6 text-right">
                Serie: {ctSerie || 0} Numero: {ctNumero || 0}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Valor</div>
              <div className="col-6 md:col-6 text-right">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRl',
                }).format(freteValor)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Qnt. produtos</div>
              <div className="col-6 md:col-6 text-right">{totalItens}</div>
            </div>

            <div className="flex align-items-center justify-content-around mb-1">
              <div className="col-6 md:col-6">Qnt. itens</div>
              <div className="col-6 md:col-6 text-right">{totalQnt}</div>
            </div>
          </div>
          <div
            className="field col-12 md:col-4"
            onClick={() => handleOpenPaymentModalVisible()}
          >
            <Divider align="left" type="dashed" className="cursor-pointer">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="fa-solid fa-barcode mx-2"></i>
                  PAGAMENTOS
                </span>
                <i className="pi pi-plus-circle text-green-500 ml-2"></i>
              </div>
            </Divider>

            {itensPagamento.length > 0 ? (
              itensPagamento.map(i => {
                return (
                  <div
                    key={i.id}
                    className="flex align-items-center justify-content-around mb-1"
                  >
                    <div className="col-6 md:col-4 text-center">{`${i.parcela}#${i.parcelas}`}</div>
                    <div className="col-6 md:col-4 text-center">{`${format(
                      i.vencimento,
                      'dd/MM/yy',
                    )}`}</div>
                    <div className="col-6 md:col-4 text-center">
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(i.valorParcela)}
                    </div>
                  </div>
                );
              })
            ) : (
              <span>Nenhuma contas a pagar lançada!</span>
            )}

            {itensPagamento.length > 0 && (
              <div className="flex align-items-center justify-content-around mb-1">
                <div className="col-6 md:col-4 text-center"></div>
                <div className="col-6 md:col-4 text-center"></div>
                <div
                  className="col-6 md:col-4 text-center text-900"
                  style={{ borderTop: '1px solid #c8c8c8' }}
                >
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(pagValor || 0)}
                </div>
              </div>
            )}
          </div>
          <div className="field col-12 md:col-4">
            <Divider align="left" type="dashed">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="fa-solid fa-dollar-sign mx-2"></i>
                  TOTAIS
                </span>
              </div>
            </Divider>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">DOCUMENTO</div>
              <div className="col-6 md:col-6 text-right">
                {new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(totalUnit)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">FRETE</div>
              <div className="col-6 md:col-6 text-right">
                {new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(freteValor)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">
                DESCONTOS
                <i
                  className="pi pi-minus-circle text-pink-600 ml-2 cursor-pointer"
                  onClick={() => setModalDescComplementarVisible(true)}
                ></i>
                <Tag
                  severity="danger"
                  value={`${new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(descComplementar)}`}
                  className="ml-3"
                ></Tag>
              </div>
              <div className="col-6 md:col-6 text-right">
                {new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(totalDescontos)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">
                ADICIONAIS
                <i
                  className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                  onClick={() => setModalAdicComplementarVisible(true)}
                ></i>
                <Tag
                  severity="success"
                  value={new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(adicComplementar)}
                  className="ml-3"
                ></Tag>
              </div>
              <div className="col-6 md:col-6 text-right">
                {new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(totalAdicionais)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">
                <strong>TOTAL</strong>
              </div>
              <div className="col-6 md:col-6 text-right">
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(totalEntrada)}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-6 md:col-6">
            <Button
              name="limpar"
              loading={loadingStatus}
              type="reset"
              className="p-button-danger"
              label="Limpar"
              icon="pi pi-trash"
              onClick={() => null}
            />
          </div>
          <div className="field col-6 md:col-6">
            <Button
              name="faturar"
              loading={loadingStatus}
              type="button"
              className="p-button-success ml-1"
              label="Salvar"
              icon="pi pi-check"
              onClick={() => handleSubmit()}
            />
          </div>
        </div>

        <FastFormPerson
          isOpen={providerModalVisible}
          onRequestClose={() => setProviderModalVisible(false)}
          behavior={
            validate(fornecedor.value)
              ? EnumBehavior.UPDATE
              : EnumBehavior.CREATE
          }
          personId={fornecedor.value}
          personType="P"
        />

        <ModalTransporter
          header="Custos com frete"
          visible={transporterModalVisible}
          onHide={() => {
            setTransporterModalVisible(false);
            handleClearFormTransporter();
          }}
          style={{ width: '50vw' }}
        >
          <Form
            ref={formRefTransporter}
            onSubmit={handleSubmitTransporter}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-12">
                <label htmlFor="transporter_id">Transportadora</label>
                <InputDropDown
                  name="transporter_id"
                  options={transportadoraOpts}
                  value={transportadora?.value}
                  onChange={e =>
                    setTransportadora(
                      transportadoraOpts.find(i => i.value === e.value),
                    )
                  }
                  placeholder="Selecionar..."
                  filter
                  emptyFilterMessage="Nenhum item encontrado..."
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="kind_frete">Tipo de frete</label>
                <InputDropDown
                  name="kind_frete"
                  options={freteOpts}
                  value={tipoFrete}
                  onChange={e => setTipoFrete(e.value)}
                  placeholder="Selecionar..."
                  filter
                  emptyFilterMessage="Nenhum item encontrado..."
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="serie">Serie</label>
                <InputText
                  name="ct_serie"
                  value={ctSerie}
                  onChange={e => setCtSerie(e.currentTarget.value)}
                  placeholder="000"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="serie">Número</label>
                <InputText
                  name="ct_numero"
                  value={ctNumero}
                  onChange={e => setCtNumbero(e.currentTarget.value)}
                  placeholder="000"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="doc_value">Valor</label>
                <InputNumber
                  name="ct_value"
                  value={freteValor}
                  onChange={e => setFreteValor(Number(e.value))}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
            </div>

            <div className="p-fluid grid formgrid">
              <div className="field col-6 md:col-6">
                <Button
                  name="limpar"
                  loading={loadingStatus}
                  type="reset"
                  className="p-button-danger"
                  label="Limpar"
                  icon="pi pi-trash"
                  onClick={() => handleClearFormTransporter()}
                />
              </div>
              <div className="field col-6 md:col-6">
                <Button
                  loading={loadingStatus}
                  type="submit"
                  className="p-button-success ml-1"
                  label="Registrar"
                  icon="pi pi-check"
                />
              </div>
            </div>
          </Form>
        </ModalTransporter>

        <ModalPayments
          header="Contas a pagar"
          visible={paymentModalVisible}
          onHide={() => {
            setPaymentModalVisible(false);
          }}
          style={{ width: '50vw' }}
        >
          <Form
            ref={formRefPayment}
            onSubmit={handleSubmitPayment}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-9">
                <label htmlFor="serie">Fornecedor</label>
                <InputText
                  name="provider_id"
                  value={fornecedor.label}
                  placeholder="Informe o fornecedor"
                  readOnly
                  className="surface-300"
                />
              </div>

              <div className="field col-12 md:col-3 flex align-items-end">
                <Button
                  name="btn_gerar"
                  loading={loadingStatus}
                  type="button"
                  className="p-button-info"
                  icon="fa-solid fa-table-list"
                  label="Gerar"
                  onClick={handleGeneratePaymentsParcels}
                  disabled={
                    pagValor === 0 ||
                    pagParcelas === 0 ||
                    pagVencimento === undefined ||
                    pagCentroCusto === ''
                      ? true
                      : false
                  }
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="valor">Valor</label>
                <InputNumber
                  name="valor"
                  value={pagValor}
                  onChange={e => setPagValor(Number(e.value))}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  placeholder="R$ 0,00"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="parcelas">Parcelas</label>
                <InputNumber
                  name="parcelas"
                  value={pagParcelas}
                  onChange={e => setPagParcelas(Number(e.value))}
                  showButtons
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  buttonLayout="horizontal"
                  decrementButtonClassName="p-button-danger"
                  incrementButtonClassName="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  useGrouping={false}
                  placeholder="0"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="vencimento">Vencimento</label>
                <Calendar
                  name="vencimento"
                  value={pagVencimento}
                  onChange={e => setPagVencimento(e.value)}
                  dateFormat="d/m/yy"
                  minDate={hoje}
                  showIcon
                  placeholder="00/00/00"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="cost_center">Centro de custo</label>
                <InputDropDown
                  name="cost_center"
                  options={centroCustoOpts}
                  value={pagCentroCusto}
                  onChange={e => setPagCentroCusto(e.value)}
                  placeholder="Selecionar..."
                  filter
                  emptyFilterMessage="Nenhum item encontrado..."
                />
              </div>
            </div>

            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-12">
                <DataTable
                  value={itensPagamentoTmp}
                  responsiveLayout="scroll"
                  selectionMode="single"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 20, 30]}
                  size="small"
                  emptyMessage="Nenhuma proposta encontrada!"
                >
                  <Column
                    field="id"
                    header="#"
                    body={formatTableColumnId}
                  ></Column>
                  <Column field="parcela" header="Parcela"></Column>
                  <Column
                    field="vencimento"
                    header="Vencimento"
                    body={row => formatTableColumnToDate(row)}
                  ></Column>
                  <Column
                    field="valorDocumento"
                    header="Documento"
                    body={row =>
                      formatTableColumnToDecimal(row, 'valorDocumento')
                    }
                  ></Column>
                  <Column
                    field="valorParcela"
                    header="Parcela"
                    body={row =>
                      formatTableColumnToDecimal(row, 'valorParcela')
                    }
                  ></Column>
                  <Column header="*"></Column>
                </DataTable>
              </div>
            </div>

            <div className="p-fluid grid formgrid">
              <div className="field col-6 md:col-6">
                <Button
                  name="limpar"
                  loading={loadingStatus}
                  type="reset"
                  className="p-button-danger"
                  label="Limpar"
                  icon="pi pi-trash"
                  onClick={handleClearFormPayments}
                />
              </div>
              <div className="field col-6 md:col-6">
                <Button
                  loading={loadingStatus}
                  type="submit"
                  className="p-button-success ml-1"
                  label="Salvar"
                  icon="pi pi-check"
                />
              </div>
            </div>
          </Form>
        </ModalPayments>

        <ModalAdicComplementar
          header="Adicional complementar"
          visible={modalAdicComplementarVisible}
          style={{ width: '30vw' }}
          onHide={() => setModalAdicComplementarVisible(false)}
          footer={renderModalAdicComplementarFooter}
        >
          <Form
            ref={null}
            onSubmit={() => null}
            className="p-fluid grid formgrid"
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="field col-12 md:col-12">
              <label htmlFor="adic_complementar">Valor</label>
              <InputNumber
                name="adic_complementar"
                value={adicComplementar}
                onChange={e => setAdicComplementar(Number(e.value))}
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
          </Form>
        </ModalAdicComplementar>

        <ModalDescComplementar
          header="Desconto complementar"
          visible={modalDescComplementarVisible}
          style={{ width: '30vw' }}
          onHide={() => setModalDescComplementarVisible(false)}
          footer={renderModalDescComplementarFooter}
        >
          <Form
            ref={null}
            onSubmit={() => null}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-12">
                <label htmlFor="desc_complementar">Valor</label>
                <InputNumber
                  name="desc_complementar"
                  value={descComplementar}
                  onChange={e => setDescComplementar(Number(e.value))}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
            </div>
          </Form>
        </ModalDescComplementar>
      </div>
    </>
  );
};

export default InputProduct;
