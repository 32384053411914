import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ProductHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Produto"
        icon="fa-solid fa-box"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/products/create`)}
      />

      <Button
        label="Produtos"
        icon="fa-solid fa-boxes-stacked"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/products/list`)}
      />

      <Button
        label="Entrada estoque"
        icon="fa-solid fa-truck-ramp-box"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/input-products/list`)}
      />

      <Button
        label="Inventário"
        icon="fa-solid fa-list-check"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/inventory/abertura`)}
      />

      <Button
        label="Conversão"
        icon="fa-solid fa-arrows-rotate"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/product/convert`)}
      />

      <Button
        label="Estoque"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push(`/reports/stock-control/current-count`)}
      />

      <Button
        label="Preços"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/products-prices/list')}
      />
      <Button
        label="Preço"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/products-prices/create')}
      />
    </>
  );
};

export default ProductHeaderButtons;
