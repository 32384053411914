import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** types */
import { CostCenterModel } from '../../../types/cost';

/** services */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** empty cost center */

const emptyCostCenter = {
  b_id: '',
  created_at: '',
  description: '',
  id: '',
  kind: '',
  status: '',
  title: '',
  updated_at: '',
  user_id: '',
};

const CostCenterList = (): React.ReactElement => {
  /** state */
  const [costCenters, setCostCenters] = useState<CostCenterModel[]>([]);
  const [costCenter, setCostCenter] = useState(emptyCostCenter);
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  /** state dialog */
  const [dialogDeleteCostCenterDialog, setDialogDeleteCostCenter] =
    useState(false);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  /** delete dialog */

  const hideDeleteComissionedDialog = () => {
    setDialogDeleteCostCenter(false);
  };

  const confirmDeleteCostCenter = (brand: any) => {
    setCostCenter(brand);
    setDialogDeleteCostCenter(true);
  };

  const deleteCostCenterApi = async () => {
    try {
      await api.delete(`/cost-center/${costCenter.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteComissioned = () => {
    const _costCenter = costCenters.filter(val => val.id !== costCenter.id);
    setCostCenters(_costCenter);
    setDialogDeleteCostCenter(false);
    setCostCenter(emptyCostCenter);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteCostCenterApi();
  };

  const deleteComissionedDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteComissionedDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteComissioned}
      />
    </React.Fragment>
  );

  /** table column */

  const codeBodyTemplate = (rowData: CostCenterModel, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const kindBodyTemplate = (rowData: CostCenterModel) => {
    return (
      <>
        <span>{rowData.kind === 'E' ? 'Entrada' : 'Saida'}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: CostCenterModel) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: CostCenterModel) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteCostCenter(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Centro de Custos</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchCostCenter = async () => {
    try {
      const { data } = await api.get('/cost-center');
      setCostCenters(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Error',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchCostCenter();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/cost-center/create')}
      />
      <div className="card">
        <DataTable
          value={costCenters}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} centro de custo"
          globalFilter={globalFilter}
          emptyMessage="Não existe Centro de custo."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column field="k" header="Tipo" body={kindBodyTemplate}></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteCostCenterDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteComissionedDialogFooter}
        onHide={hideDeleteComissionedDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {costCenter && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{costCenter?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CostCenterList;
