import React, { useRef, useState } from 'react';

/* react-router-dom */
import { useHistory, Link } from 'react-router-dom';

/* unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/* toast */
import useToastContext from '../../hooks/toast';

/* components */
import { InputText } from '../../components/Inputs/InputText';
import { InputPassword } from '../../components/Inputs/InputPassword';
import { InputMask } from '../../components/Inputs/InputMask';

import { Button } from 'primereact/button';

/* Yup */
import * as Yup from 'yup';

/* utils */
import getValidationErrors from '../../utils/getErrorsValidation';

/* api */
import api from '../../services/api';

/* SigninModel */
type SignupModel = {
  name: string;
  email: string;
  phone: string;
  password: string;
  confirm_password: string;
};

export const Signup = (): React.ReactElement => {
  /* location */
  const navigate = useHistory();
  const toast = useToastContext();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /* refs */
  const formRef = useRef<FormHandles>(null);

  const [phone, setPhone] = useState('');

  const handleSignup = async (data: SignupModel) => {
    try {
      setLoadingStatus(true);

      const signinSchema = Yup.object().shape({
        name: Yup.string().required('Por favor, Insira um nome'),
        email: Yup.string()
          .required('Por favor, Insira um e-mail')
          .email('Informe um e-mail válido!'),
        phone: Yup.string().required('Por favor, Insira um telefone'),
        password: Yup.string().required('Por favor, Insira uma senha'),
        confirm_password: Yup.string()
          .required('Por favor, Insira a confirmação da senha')
          .oneOf([Yup.ref('password')], 'Suas senhas não conferem.'),
      });

      formRef.current?.setErrors({});

      await signinSchema.validate(data, { abortEarly: false });

      await api.post('/users/key-user', data);

      toast('success', 'Cadastro realizado com sucesso!');

      navigate.push('/');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <Form onSubmit={handleSignup} ref={formRef}>
      <div className="login-body">
        <div className="login-wrapper">
          <div className="login-panel">
            <img
              src="assets/layout/images/logo.svg"
              className="logo"
              alt="Webger - Sistema de gestão 100% online"
            />

            <div
              className="login-form"
              style={{ width: '60vw', minWidth: '310px' }}
            >
              <h2>Cadastre-se</h2>
              <p>
                já tem uma conta? <Link to="/signin">Entrar</Link>
              </p>

              <InputText placeholder="Nome" name="name" />
              <InputText placeholder="Email" name="email" />
              <InputMask
                mask="(99) 99999-9999"
                placeholder="Telefone"
                value={phone}
                onChange={e => setPhone(e.value)}
                name="phone"
              />
              <InputPassword
                placeholder="Senha"
                name="password"
                feedback={false}
                toggleMask={true}
              />
              <InputPassword
                placeholder="Confirmar senha"
                name="confirm_password"
                feedback={false}
                toggleMask={true}
              />

              <Button
                type="submit"
                label="Cadastrar"
                loading={loadingStatus}
              ></Button>
            </div>

            <p>
              Algum problema?{' '}
              <a
                href="https://wa.me/5562985099000"
                target={'_blank'}
                rel="noreferrer"
              >
                Clique aqui
              </a>{' '}
              e deixe-nos ajudá-lo.
            </p>
          </div>
          <div className="login-image">
            <div className="login-image-content text-center">
              <h1>Indique e ganhe isenção em uma mensalidade</h1>
              <div className="grid mt-4 pl-8 pr-8" style={{ color: '#ffffff' }}>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-box-open mr-3"></i>
                  ESTOQUE
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-wallet mr-3"></i>
                  CONTAS A PAGAR
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NF-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NFC-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NFS-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-money-bill-1 mr-3"></i>
                  CONTAS A RECEBER
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-list-check mr-3"></i>
                  INVENTÁRIO
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-phone-volume mr-3"></i>
                  CRM
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-cash-register mr-3"></i>
                  PDV - FRENTE DE CAIXA
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-motorcycle mr-3"></i>
                  DELIVERY
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-industry mr-3"></i>
                  PCP
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-store mr-3"></i>
                  LOJA VIRTUAL
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-boxes-packing mr-3"></i>
                  MARKET PLACES
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-mobile-screen mr-3"></i>
                  VITRINE DE PRODUTOS
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-money-bill-trend-up mr-3"></i>
                  PERFORMA+
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-barcode mr-3"></i>
                  BOLETOS
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-retweet mr-3"></i>
                  LOCAÇÕES
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-cube mr-3"></i>
                  CORREIOS
                </div>
              </div>
            </div>
            <div className="image-footer">
              <p>Siga nos nas redes sociais</p>
              <div className="icons">
                <i
                  className="pi pi-whatsapp"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-facebook"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-instagram"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-youtube"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
