import { useQuery } from 'react-query';
import { IPaymentCondition } from '../pages/paymentCondition/dtos/IPaymentCondition';
import api from '../services/api';

export default function useLoadPaymentConditions() {
  const url = 'payments-condition';
  const { data, error, isLoading, isFetching } = useQuery<IPaymentCondition[]>(
    [url, url],
    () => api.get(url).then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );

  return { data, error, isLoading, isFetching };
}
