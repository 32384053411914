import React, { useRef, useEffect } from 'react';

/* unform */
import { useField } from '@unform/core';

import { InputSwitch as Input, InputSwitchProps } from 'primereact/inputswitch';

/* props inputText */
interface PropsInput extends InputSwitchProps {
  name: string;
}

export const InputSwitch: React.FC<PropsInput> = ({ name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'props.checked',
    });
  }, [fieldName, registerField]);

  return <Input name={name} ref={inputRef} {...rest} />;
};
