import { v4 } from 'uuid';

export interface IPerson {
  id: string;
  codigo: number;
  type: string;
  customer: string;
  provider: string;
  transporter: string;
  name: string;
  nickname: string;
  cpf_cnpj: string;
  rg_ie: string;
  sex: string;
  birthday: Date;
  civil_status: string;
  block: string;
  image_url: string;
  regime_tributario: string;
  insc_suframa: string;
  insc_municipal: string;
  contribuinte: string;
  obs: string;
}

export interface ICity {
  id: string;
  ibge: string;
  name: string;
  uf: string;
  label?: string;
  value?: string;
}

export const emptyCity: ICity = {
  id: v4(),
  ibge: '',
  name: '',
  uf: '',
};

export interface IAddress {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city_id: string;
  city?: ICity;
  post_code: string;
}

export interface IContact {
  phone_cel: string;
  phone_fixed: string;
  phone_mobile: string;
  phone_whatsapp: string;
  email: string;
  site: string;
  facebook: string;
  instagram: string;
  youtube: string;
  twitter: string;
}

export interface IPersonUpdate {
  person: IPerson;
  address: IAddress;
  contact: IContact;
}

export interface IPersonCreate extends IPersonUpdate {}

export const emptyPersonUpdate: IPersonUpdate = {
  person: {
    id: v4(),
    codigo: 9999,
    type: 'F',
    customer: 'S',
    provider: 'N',
    transporter: 'N',
    name: '',
    nickname: '',
    cpf_cnpj: '',
    rg_ie: '',
    sex: 'O',
    birthday: new Date(),
    civil_status: 'S',
    block: 'N',
    image_url: '',
    regime_tributario: 'N',
    insc_suframa: '',
    insc_municipal: '',
    contribuinte: '0',
    obs: '',
  },
  address: {
    street: '',
    number: 's/n',
    complement: '',
    neighborhood: '',
    city_id: '',
    city: {
      id: '',
      ibge: '',
      name: '',
      uf: 'GO',
    },
    post_code: '',
  },
  contact: {
    phone_cel: '',
    phone_fixed: '',
    phone_mobile: '',
    phone_whatsapp: '',
    email: '',
    site: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
  },
};

export interface ICreateCustomerShort {
  name: string;
  cpf_cnpj: string | undefined;
  phone: string | undefined;
  address: IAddress | undefined;
}
