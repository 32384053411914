import React, { useRef, useState, useEffect } from 'react';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** compoentns */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { Option } from '../../../types/optional';
import { Toast } from 'primereact/toast';

const UserRecorderEdit: React.FC = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** navigate */
  const navigate = useHistory();

  /** params */
  const params: { id: string } = useParams();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** operator */
  const [operatorExclusive, setOperatorExcluise] = useState('S');
  const [registerOnlyOwn, setRegisterOnlyOwn] = useState('S');

  /** wallet */
  const [wallet, setWallet] = useState<Option[]>([]);
  const [selectedWallet, setSelectedWallet] = useState();

  /** user */
  const [user, setUser] = useState<Option[]>([]);
  const [selectedUser, setSelectedUser] = useState();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleRegister = async (data: any) => {
    try {
      setLoadingStatus(true);

      const schemaRegister = Yup.object({
        title: Yup.string().required(
          'Por favor, informe o nome da registradora!',
        ),
        operator_id: Yup.string().required('Por favor, informe o operador!'),
        operator_exclusive: Yup.string(),
        register_only_own: Yup.string(),
        wallet_id: Yup.string().required('Por favor, informe a carteira!'),
      });

      await schemaRegister.validate(data, { abortEarly: false });

      await api.put(`/register-user/${params.id}`, data);

      navigate.push('/register-user/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get(`/wallets`)
      .then(({ data }) => {
        const optWallet = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setWallet(optWallet);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A', 'C'],
        },
      })
      .then(({ data }) => {
        const optUsers = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setUser(optUsers);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get(`/register-user/${params.id}`)
      .then(({ data }) => {
        form.current?.setData({
          title: data.title,
        });
        setSelectedUser(data.operator_id);
        setSelectedWallet(data.wallet_id);
        setOperatorExcluise(data.operator_exclusive);
        setRegisterOnlyOwn(data.register_only_own);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/register-user/list')}
      />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleRegister}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Nome</label>
              <InputText name="title" placeholder="Nome da registradora" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Usuário</label>
              <InputDropDown
                name="operator_id"
                options={user}
                value={selectedUser}
                onChange={e => setSelectedUser(e.value)}
                placeholder="Selecionar..."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Carteira</label>
              <InputDropDown
                name="wallet_id"
                value={selectedWallet}
                options={wallet}
                onChange={e => setSelectedWallet(e.value)}
                placeholder="Selecionar..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6 flex align-items-center">
              <InputSwitch
                className="mr-4"
                name="operator_exclusive"
                falseValue="N"
                trueValue="S"
                checked={operatorExclusive}
                onChange={e => setOperatorExcluise(`${e.value}`)}
              />

              <label htmlFor="dropdown" className="m-0">
                Operador Exclusivo
              </label>
            </div>
            <div className="field col-12 md:col-6 flex align-items-center">
              <InputSwitch
                className="mr-4"
                falseValue="N"
                trueValue="S"
                name="register_only_own"
                checked={registerOnlyOwn}
                onChange={e => setRegisterOnlyOwn(`${e.value}`)}
              />

              <label htmlFor="dropdown" className="m-0">
                Registra apenas seus pedidos?
              </label>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default UserRecorderEdit;
