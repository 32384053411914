import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { Option } from '../../../types/optional';
import getValidationErrors from '../../../utils/getErrorsValidation';
import RegisterCashHeader from '../../register-cash/header';

const ReportCash: React.FC = (): React.ReactElement => {
  const [load, setLoad] = useState<boolean>(false);

  const [selectProvider, setSelectProvider] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState<any>();

  const [register, setRegister] = useState<Array<any>>([]);
  const [cashRegister, setCashRegister] = useState<Array<Option>>([]);
  const [selectedCashRegister, setSelectedCashRegister] = useState();

  const [width, setWidth] = useState('60vw');
  const [error, setError] = useState(false);

  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  const searchCashRegister = async (userRegister: any) => {
    try {
      setLoad(true);
      const { data } = await api.get(
        `/register-cash/list?registerId=${userRegister.id}`,
      );
      const cashRegisterResponse = data.map((item: any) => {
        return {
          label: `${item.serie} | ${format(
            new Date(item.balance_open_date),
            'dd/MM/yy H:mm',
          )} | ${
            item?.balance_close_date
              ? format(new Date(item.balance_close_date), 'dd/MM/yy H:mm')
              : 'ABERTO'
          }`,
          value: `${item.serie}`,
        };
      });
      setCashRegister(cashRegisterResponse);
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
    } finally {
      setLoad(false);
    }
  };

  const selectRegister = (e: DataTableSelectionChangeParams) => {
    setSelectedRegister(e.value);
    setSelectProvider(false);
    setError(false);
    searchCashRegister(e.value);
  };

  const handleReports = async (data: any) => {
    try {
      setLoad(true);
      if (!selectedRegister?.id) {
        toast('error', 'Error', 'Por favor, selecione a registradora!', 10000);
        setError(true);
        return;
      }

      const schemaReports = Yup.object({
        cash: Yup.string().required('Por favor, Selecione o caixa'),
      });

      await schemaReports.validate(data, { abortEarly: false });

      const { data: response } = await api.get(
        `/reports/register/movimentation`,
        {
          params: {
            registerId: selectedRegister?.id,
            cashId: data.cash,
          },
        },
      );

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
      form.current?.setErrors({});
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0], 10000);
      } else {
        toast('error', 'Error', error.response?.data?.error, 10000);
      }
    } finally {
      setLoad(false);
    }
  };

  const listRegisterUser = async () => {
    setLoad(true);
    await api
      .get('/register-user')
      .then(({ data }) => {
        setRegister(data);
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    listRegisterUser();
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });
  }, []);

  return (
    <>
      <RegisterCashHeader />
      <div className="card md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Registradoras</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedRegister
                    ? `${selectedRegister.title}`
                    : 'Selecionar registradora'
                }
                aria-haspopup
                onClick={() => setSelectProvider(true)}
                aria-controls="overlay_panel"
                className={
                  !error
                    ? 'select-product-button w-7'
                    : 'p-button-raised p-button-danger w-7'
                }
                loading={load}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="">Selecione o caixa</label>
              <InputDropDown
                name="cash"
                options={cashRegister}
                value={selectedCashRegister}
                disabled={!selectedRegister ? true : false}
                className={!selectedRegister ? 'surface-200' : ''}
                onChange={e => setSelectedCashRegister(e.value)}
                placeholder="Selecionar..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                loading={load}
                className="p-button-danger"
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={load ? 'Aguarde...' : 'Imprimir'}
                icon="fa-solid fa-print"
                loading={load}
                className="p-button-success"
                type="submit"
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={selectProvider}
        position="right"
        style={{ width }}
        onHide={() => setSelectProvider(false)}
      >
        <DataTable
          value={register}
          selectionMode="single"
          selection={selectedRegister}
          onSelectionChange={e => selectRegister(e)}
          dataKey="id"
          responsiveLayout="scroll"
          emptyMessage="Mão existe registradoras."
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
        >
          <Column field="codigo" header="Código"></Column>
          <Column field="title" header="Nome"></Column>
          <Column field="status" header="Status"></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default ReportCash;
