import React, { useRef, useEffect } from 'react';

/* unform */
import { useField } from '@unform/core';

import { InputMask as Input, InputMaskProps } from 'primereact/inputmask';

/* props inputText */
interface PropsInput extends InputMaskProps {
  name: string;
}

export const InputMask: React.FC<PropsInput> = ({
  name,
  placeholder,
  className,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Input
      placeholder={placeholder}
      name={name}
      inputRef={inputRef}
      {...rest}
      className={error ? `p-invalid ${className}` : `${className}`}
    />
  );
};
