import React, { createContext, useEffect, useReducer } from 'react';
import api from '../services/api';

import {
  ActionProps,
  StateProps,
  initialState,
  useReducerApp,
} from '../reducers/AppReducer';

type ContextType = {
  state: StateProps;
  dispatch: React.Dispatch<any>;
};

export const AppContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

export const mainReducer = (
  state: StateProps,
  action: ActionProps,
): StateProps => ({
  ...useReducerApp(state, action),
});

type Props = { children: React.ReactNode };

const AppProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const localState = JSON.parse(localStorage.getItem('user') || '{}');

  const [state, dispatch] = useReducer(mainReducer, localState || initialState);

  api.defaults.headers.common.authorization = `Bearer ${state?.user?.token}`;
  api.defaults.headers.common['api-b-key'] = state?.user?.b_id;

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
