import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addMonths, endOfDay, endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { CalendarChangeParams } from 'primereact/calendar';
import { Column } from 'primereact/column';
import React, { useRef, useState } from 'react';
import { validate } from 'uuid';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../../components/Divider';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import InputNumberRange from '../../../../components/Inputs/InputNumberRange';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import ConsignedTopMenuReport from '../top-menu-report';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const ReportRouteSearch = (): React.ReactElement => {
  const hoje = new Date();
  const formRef = useRef<FormHandles>(null);

  const [isLoad, setIsLoad] = useState(false);
  const toast = useToastContext();

  const [keyword, setKeyword] = useState('');
  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<IOptionsDTO[]>([]);

  const [dateOpen, setDateOpen] = useState<Date | Date[] | undefined>([
    startOfMonth(hoje),
    endOfMonth(hoje),
  ]);
  const [datePrevision, setDatePrevision] = useState<Date | Date[] | undefined>(
    [startOfMonth(addMonths(hoje, 1)), endOfMonth(addMonths(hoje, 2))],
  );
  const [dateClose, setDateClose] = useState<Date | Date[] | undefined>([
    startOfMonth(hoje),
    endOfDay(hoje),
  ]);

  const [orders, setOrders] = useState<number[]>([0, 1000000.0]);
  const [devolutions, setDevolutions] = useState<number[]>([0, 1000000.0]);
  const [gifts, setGifts] = useState<number[]>([0, 1000000.0]);
  const [commissions, setCommissions] = useState<number[]>([0, 1000000.0]);
  const [total, setTotal] = useState<number[]>([0, 1000000.0]);

  const [block, setBlock] = useState<boolean | undefined>(undefined);
  const statusOpts = [
    { value: 'T', label: 'Todos' },
    { value: 'A', label: 'Ativo' },
    { value: 'C', label: 'Cancelado' },
  ];
  const [status, setStatus] = useState<string>('T');

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddCustomer = (id: string) => {
    if (validate(id)) {
      const hasCustomer = customer.find((i: IOptionsDTO) => i.value === id);
      if (!hasCustomer) {
        const _customer = customers.find((i: IOptionsDTO) => i.value === id);
        if (_customer) {
          setCustomer([...customer, _customer]);
        }
      }

      setKeyword('');
    }
  };

  const clearForm = () => {
    setKeyword('');
    setCustomer([]);
    setCustomers([]);
    setDateOpen([]);
    setDatePrevision([]);
    setDateClose([]);
    setOrders([]);
    setDevolutions([]);
    setGifts([]);
    setCommissions([]);
    setTotal([]);
    setStatus('T');
    setBlock(false);
  };

  const handleRemCustomer = (id: string) => {
    if (validate(id)) {
      const noId = customer.filter((i: IOptionsDTO) => i.value !== id);
      if (noId) {
        setCustomer(noId);
      }
    }
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    await api
      .post('/reports/consigned/routes', {
        data: {
          customers: customer.map((i: IOptionsDTO) => {
            return i.value;
          }),
          date_open: dateOpen,
          date_prevision: datePrevision,
          date_close: dateClose,
          totals: {
            orders: [
              orders[0] === null ? 0 : orders[0],
              orders[1] === null ? 100000.0 : orders[1],
            ],
            devolutions: [
              devolutions[0] === null ? 0 : devolutions[0],
              devolutions[1] === null ? 100000.0 : devolutions[1],
            ],
            gifts: [
              gifts[0] === null ? 0 : gifts[0],
              gifts[1] === null ? 100000.0 : gifts[1],
            ],
            commissions: [
              commissions[0] === null ? 0 : commissions[0],
              commissions[1] === null ? 100000.0 : commissions[1],
            ],
            total: [
              total[0] === null ? 0 : total[0],
              total[1] === null ? 100000.0 : total[1],
            ],
          },
          block: block,
          status: [status],
        },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Relatório gerado com sucesso!');
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-rotas',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const itemTemplateCustomer = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemCustomer(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  return (
    <>
      <ConsignedTopMenuReport />
      <div className="card w-10">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgroup"
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="field col-12 mb-1 pb-1 mb-0 pb-0">
            <label htmlFor="customer">Cliente</label>
            <InputAutoComplete
              name="customer"
              suggestions={customers}
              value={keyword}
              completeMethod={e => searchCustomer(e.query)}
              onChange={e => setKeyword(e.value)}
              field="label"
              onSelect={e => handleAddCustomer(e.value.value)}
              itemTemplate={itemTemplateCustomer}
              placeholder="Buscar cliente..."
            />
          </div>

          {customer.length > 0 && (
            <DataTable
              value={customer}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Sem clientes..."
              selectionMode="single"
              loading={isLoad}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} clientes."
              className="field col-12"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column header="Nome" field="label"></Column>
              <Column
                header="*"
                body={e => renderCollumnMenu(e.value)}
                align={'center'}
              ></Column>
            </DataTable>
          )}
          <div className="field col-4">
            <label htmlFor="date_open">Aberturas</label>
            <CalendarRange
              name="date_open"
              showIcon
              value={dateOpen}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setDateOpen(e.value as Date[])
              }
            />
          </div>

          <div className="field col-4">
            <label htmlFor="date_prevision">Previsão acerto</label>
            <CalendarRange
              name="date_prevision"
              showIcon
              value={datePrevision}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setDatePrevision(e.value as Date[])
              }
            />
          </div>

          <div className="field col-4">
            <label htmlFor="date_close">Fechamento</label>
            <CalendarRange
              name="date_close"
              showIcon
              value={dateClose}
              onChange={(e: Partial<CalendarChangeParams>) =>
                setDateClose(e.value as Date[])
              }
            />
          </div>

          <div className="field col-2">
            <label htmlFor="orders_range">Pedidos entre</label>
            <InputNumberRange
              name={'orders_range'}
              defaultMinValue={0}
              defaultMaxValue={100000}
              onMinValueChange={e => {
                setOrders([e as number, orders[1]]);
              }}
              onMaxValueChange={e => {
                setOrders([orders[0], e as number]);
              }}
              range={orders}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="devolutions_range">Devoluções entre</label>
            <InputNumberRange
              name={'devolutions_range'}
              defaultMinValue={0}
              defaultMaxValue={100000}
              range={devolutions}
              onMinValueChange={e => {
                setDevolutions([e as number, devolutions[1]]);
              }}
              onMaxValueChange={e => {
                setDevolutions([devolutions[0], e as number]);
              }}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="gifts_range">Brindes entre</label>
            <InputNumberRange
              name={'gifts_range'}
              defaultMinValue={0}
              defaultMaxValue={100000}
              range={gifts}
              onMinValueChange={e => {
                setGifts([e as number, gifts[1]]);
              }}
              onMaxValueChange={e => {
                setGifts([gifts[0], e as number]);
              }}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="commissions_range">Comissão entre</label>
            <InputNumberRange
              name={'commissions_range'}
              defaultMinValue={0}
              defaultMaxValue={100000}
              range={commissions}
              onMinValueChange={e => {
                setCommissions([e as number, commissions[1]]);
              }}
              onMaxValueChange={e => {
                setCommissions([commissions[0], e as number]);
              }}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="totals_range">Acertos entre</label>
            <InputNumberRange
              name={'totals_range'}
              defaultMinValue={-100000}
              defaultMaxValue={100000}
              range={total}
              onMinValueChange={e => {
                setTotal([e as number, total[1]]);
              }}
              onMaxValueChange={e => {
                setTotal([total[0], e as number]);
              }}
            />
          </div>

          <Divider />

          <div className="field col-4 mb-0 pb-0">
            <label htmlFor="status">Status</label>
            <InputDropDown
              name="status"
              value={status}
              options={statusOpts}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
              filter
              required
            />
          </div>

          <div className="field col-3">
            <div className="flex justify-content-between py-2">
              <InputSwitch
                name="block"
                checked={block}
                onChange={e => setBlock(e.value)}
              />
              <span className="block">Finalizadas</span>
            </div>
          </div>

          <Divider />

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Cancelar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => clearForm()}
              loading={isLoad}
            />
          </div>

          <div className="field md:col-6 mb-0 pb-0">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportRouteSearch;
