import React, { useState, useRef } from 'react';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** services */
import api from '../../../../services/api';

/** date fns */
import { endOfDay } from 'date-fns/esm';
import { startOfDay } from 'date-fns';

/** components */
import { Button } from 'primereact/button';
import InputDate from '../../../../components/Inputs/InputCalendar';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Toast } from 'primereact/toast';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';

const optionStatus = [
  { label: 'Ativo', value: 'A' },
  { label: 'Cancelado', value: 'C' },
  { label: 'Desativado', value: 'D' },
];

const ReportsCheckByProvider: React.FC = (): React.ReactElement => {
  /** initial date */

  const date = new Date();
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  const [selectedOptionStatus, setSelectedOptionStatus] = useState();

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const handleReports = async (data: any) => {
    try {
      const schema = Yup.object().shape({
        inital_date: Yup.string()
          .required('Data Inicial é Obrigatorio!')
          .nullable(),
        final_date: Yup.string()
          .required('Data Final é Obrigatorio!')
          .nullable(),
        status: Yup.string().required('Status é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });

      const { data: response } = await api.post(`/reports/consultants`, {
        filter: {
          date_start: `${startOfDay(initialDate as Date)}`,
          date_end: `${endOfDay(finalDate as Date)}`,
          status: data.status !== '' ? data.status : null,
        },
      });

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
        '_blank',
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="inital_date"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="final_date"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Status</label>
              <InputDropDown
                name="status"
                value={selectedOptionStatus}
                options={optionStatus}
                placeholder="Selecionar..."
                onChange={e => setSelectedOptionStatus(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsCheckByProvider;
