import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../../components/DataTable';
import Calendar from '../../../../components/Inputs/InputCalendar';
import MenuPopUp from '../../../../components/MenuPopPup';
import useToastContext from '../../../../hooks/toast';
import api, { apiTSBOL } from '../../../../services/api';
import { Option } from '../../../../types/optional';
import { numberToCurrency } from '../../../../utils/number';
import HeaderButtons from '../../header-buttons';
import { EnumStatus } from '../../../../enum/EnumStatus';
import messageRequestError from '../../../../utils/messageRequestError';
import LimpaCpfCnpj from '../../../../utils/LimpaCpfCnpj';
import stringToDate from '../../../../utils/date/StringToDate';
import { saveAs } from 'file-saver';
import openReports from '../../../../utils/openReports';

const situationOption = [
  { label: 'Salvo', value: 'SALVO' },
  { label: 'Cancelado', value: 'CANCELADO' },
  { label: 'Estacionado', value: 'ESTACIONADO' },
  { label: 'Processando', value: 'PROCESSANDO' },
  { label: 'FALHA', value: 'FALHA' },
  { label: 'EMITIDOS', value: 'EMITIDO' },
];

const statusOptions = [
  { label: 'Todos', value: 'T' },
  { label: 'Ativo', value: 'A' },
  { label: 'Cancelado', value: 'C' },
];

const TitleList = (): React.ReactElement => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [situation, setSituation] = useState('');

  const toast = useToastContext();

  const date = new Date();
  const [initialDate, setInitialDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );

  const [tickets, setTickets] = useState<Array<any>>([]);
  const [ticketsSelected, setTicketsSelected] = useState<Array<any>>([]);

  const [src, setSrc] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('A');

  const [user, setUser] = useState<any>();

  const [users, setUsers] = useState<Array<Option>>([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [valueFirst, setValueFirst] = useState<number | null>();
  const [valueSecond, setValueSecond] = useState<number | null>();

  const [business, setBusiness] = useState<any>();

  const [loading, setLoading] = useState(true);

  const [menuAction, setMenuAction] = useState({
    titulo_id: '',
    id_integracao: '',
    bol_url: '',
  });

  const form = useRef<FormHandles>(null);
  const menu = useRef<any>(null);

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const loadBusiness = async (id: string) => {
    setLoading(true);

    await api
      .get(`/business/${id}`)
      .then(({ data }) => {
        if (data) {
          setBusiness(data);
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user) {
      loadBusiness(user.b_id);
    }
  }, [user]);

  const generateRemessa = async (titulosId: string[]) => {
    try {
      setLoading(true);
      const res = await api.post('/v2/boleto/remessa', {
        titulos: titulosId,
        group: false,
      });

      if (res.status >= 200 && res.status <= 299) {
        toast(
          'sucess',
          'Sucesso',
          'Arquivo de remessa gerado com sucesso!',
          10000,
        );
      }

      navigate.push('/boleto/v1/remessa/list');
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
      return;
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (idIntegracao: string) => {
    try {
      setLoading(true);

      const res = await api.get('/v2/boleto/titulo/v2/status', {
        params: {
          idIntegracao: idIntegracao,
        },
      });

      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso!', 'Status Atualizado!', 10000);
      }

      searchTickets();
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
      return;
    } finally {
      setLoading(false);
    }
  };

  const printTicket = async (id: string) => {
    const ticket = tickets.find(t => t.titulo_id === id);

    if (ticket) {
      window.open(ticket.bol_url, '_blank');
    }
  };

  // const generatePrintLote = async (id: string) => {
  //   setLoading(true);

  //   await api
  //     .post('/v2/boleto/titulo/generate/print/lote', {
  //       tipo_impressao: '1',
  //       ticket_id: id,
  //     })
  //     .then(({ data }) => {
  //       if (data) {
  //         if (data.situacao === 'PROCESSANDO') {
  //           searchTickets();
  //         }
  //       }
  //     })
  //     .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
  //     .finally(() => setLoading(false));
  // };

  const printLote = async (titulo_id: string) => {
    toast('info', 'Aguarde', 'Por favor, aguarde enquanto geramos o carnê...');

    setLoading(true);

    await api
      .get(`/v2/boleto/titulo/print/lote/${titulo_id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'carne-boleto',
          );
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoading(false));
  };

  const cancelTicket = async (tituloId: string) => {
    try {
      setLoading(true);

      const res = await api.delete(`/boleto/cancel/${tituloId}`);

      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso', 'Boleto cancelado com sucesso!', 10000);
      }

      searchTickets();
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useHistory();

  const searchTickets = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(`/v2/boleto/titulo/se/search`, {
        params: {
          dateStart: initialDate,
          dateEnd: finalDate,
          src: src,
          status: selectedStatus,
          situacao: situation,
          userId: selectedUser,
          remCreate: '',
          valueStart: valueFirst ? valueFirst : '',
          valueEnd: valueSecond ? valueSecond : '',
        },
      });

      let parcelsByCustomer: {
        nome_cliente: string;
        emissao: string;
        titulos: any[];
      }[] = [];

      data.titulos = data.titulos
        .sort((a: any, b: any) => {
          const numParcelaA = Number(
            a.parcela.substring(0, a.parcela.indexOf('/')),
          );
          const numParcelaB = Number(
            b.parcela.substring(0, b.parcela.indexOf('/')),
          );

          return numParcelaA - numParcelaB;
        })
        .sort((a: any, b: any) => a.nome_cliente.localeCompare(b.nome_cliente));

      data.titulos.forEach((t: any) => {
        if (!parcelsByCustomer.some(p => p.nome_cliente === t.nome_cliente)) {
          parcelsByCustomer.push({
            nome_cliente: t.nome_cliente,
            emissao: t.emissao,
            titulos: [],
          });
        }
      });

      data.titulos.forEach((t: any) => {
        parcelsByCustomer.forEach((p, index) => {
          if (t.nome_cliente === p.nome_cliente)
            parcelsByCustomer[index] = {
              ...parcelsByCustomer[index],
              titulos: [...parcelsByCustomer[index].titulos, t],
            };
        });
      });

      parcelsByCustomer = parcelsByCustomer.sort((a, b) => {
        return (
          new Date(stringToDate(b.emissao)).getTime() -
          new Date(stringToDate(a.emissao)).getTime()
        );
      });

      const tickets: any[] = [];

      parcelsByCustomer.forEach(p => {
        tickets.push(...p.titulos);
      });

      setTickets(tickets);
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelect = (ticket: any) => {
    if (!ticket.tem_remessa) {
      if (!ticketsSelected.some(t => t.titulo_id === ticket.titulo_id)) {
        setTicketsSelected([...ticketsSelected, ticket]);
      } else {
        setTicketsSelected(
          ticketsSelected.filter(t => t.titulo_id !== ticket.titulo_id),
        );
      }
    }
  };

  const codeBodyTemplate = (rowData: any, params: any) => {
    return (
      <>
        <span>{params.rowIndex + 1}</span>
      </>
    );
  };

  const valueBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>{numberToCurrency(parseFloat(rowData.valor))}</span>
      </>
    );
  };

  const bodyTemplateBank = (rowData: any) => {
    return (
      <>
        <span>{rowData.nome_banco}</span>
        {rowData.tem_remessa === true ? (
          <span className={`ml-2 customer-badge status-renewal`}>REM</span>
        ) : (
          ''
        )}
      </>
    );
  };

  const bodyTemplateNFe = (rowData: any) => {
    if (
      rowData.nfe_serie &&
      rowData.nfe_serie !== null &&
      rowData.nfe_numero &&
      rowData.nfe_numero !== null
    ) {
      return (
        <div className="flex no-wrap">
          <p>{`${rowData.nfe_serie} # ${rowData.nfe_numero}`}</p>
        </div>
      );
    }
  };

  const situationBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status !== EnumStatus.CANCELADO && (
          <>
            {rowData.situacao === 'SALVO' ? (
              <span className={`customer-badge status-new`}>
                {rowData.situacao}
              </span>
            ) : rowData.situacao === ('FALHA' || 'CANCELADO') ? (
              <span className={`product-badge status-outofstock`}>
                {rowData.situacao}
              </span>
            ) : (
              <span className={`product-badge status-instock`}>
                {rowData.situacao}
              </span>
            )}
          </>
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status === 'A' && (
          <span className={`product-badge status-instock mx-1`}>A</span>
        )}

        {rowData.status === 'C' && (
          <span className={`product-badge status-outofstock mx-1`}>C</span>
        )}
      </>
    );
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any,
  ) => {
    menu?.current?.toggle(event);
    setMenuAction({ ...data });
  };

  const renderColumnButtons = (rowData: any) => {
    const items: any[] = [];

    if (!rowData.tem_remessa && rowData.situacao === 'EMITIDO') {
      items.push({
        label: 'Gerar Remessa parcela',
        icon: 'pi pi-upload',
        command: () => {
          generateRemessa([rowData.titulo_id]);
        },
      });
    }
    if (rowData.situacao !== 'EMITIDO' && rowData.situacao !== 'CANCELADO') {
      items.push({
        label: 'Atualizar Status',
        icon: 'pi pi-refresh',
        command: () => {
          updateStatus(rowData.id_integracao);
        },
      });
    }

    const qntParcels = Number(rowData.parcela.split('/')[1]);

    if (rowData.situacao === 'EMITIDO') {
      items.push({
        label: 'Imprimir parcela',
        icon: 'pi pi-print',
        command: () => {
          printTicket(rowData.titulo_id);
        },
      });

      if (qntParcels > 1) {
        items.push({
          label: 'Imprimir carnê',
          icon: 'pi pi-print',
          command: () => {
            printLote(rowData.titulo_id);
          },
        });
      }

      items.push({
        label: 'Whatsapp',
        icon: 'pi pi-whatsapp',
        command: () => {
          sendWhatsapp(rowData);
        },
      });
    }

    if (rowData.status === EnumStatus.ATIVO) {
      items.push({
        label: 'Cancelar Boleto',
        icon: 'pi pi-times-circle',
        command: () => {
          cancelTicket(rowData.titulo_id);
        },
      });
    }
    return <MenuPopUp model={items} rowData={rowData} setData={() => {}} />;
  };

  const sendWhatsapp = async (ticket: any) => {
    const qntParcels = Number(ticket.parcela.split('/')[1]);

    let url = ticket.bol_url;

    if (qntParcels > 1) {
      setLoading(true);

      await api
        .get(`/v2/boleto/titulo/print/lote/${ticket.titulo_id}`)
        .then(({ data }) => {
          if (data) {
            url = `${
              process.env.REACT_APP_SERVER_URL
            }/files/pdf/${encodeURIComponent(data)}`;
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
          return;
        })
        .finally(() => setLoading(false));
    }

    const encodedMessage = encodeURIComponent(
      `Boleto ${ticket.origem} - ${ticket.nome_cliente}.`,
    );

    const encodedPdfUrl = encodeURIComponent(
      `Clique no link para fazer o download: ${url}`,
    );

    const encodedFooterMessage = encodeURIComponent(
      `Emitido por Webger - Sistema de Gestão Empresarial.`,
    );

    const whatsappUrl = `https://api.whatsapp.com/send?${
      ticket.fone_cliente !== null
        ? `phone=55${LimpaCpfCnpj(ticket.fone_cliente)}&`
        : ''
    }text=${encodedMessage}%0A${encodedPdfUrl}%0A${encodedFooterMessage}`;

    window.open(whatsappUrl, '_blank');
  };

  const renderMenuDatatable = () => {
    if (tickets.length > 0) {
      const items: any[] = [];

      if (tickets.length > 0) {
        items.push({
          label: 'Selecionar todos',
          icon: 'pi pi-check',
          command: () => {
            setTicketsSelected(tickets.filter(t => !t.tem_remessa));
          },
        });
      }

      if (ticketsSelected.length > 0) {
        items.push({
          label: 'Desmarcar todos',
          icon: 'pi pi-times',
          command: () => {
            setTicketsSelected([]);
          },
        });

        items.push({
          label: 'Gerar remessa(s)',
          icon: 'pi pi-upload',
          command: () => {
            const titulosIds = ticketsSelected.map(t => {
              return t.titulo_id;
            });

            generateRemessa(titulosIds);
          },
        });
      }

      return <MenuPopUp model={items} setData={() => {}} rowData={null} />;
    }
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Boletos</h5>
      <div className="flex mr-3">
        <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onChange={e => setGlobalFilter(e.currentTarget.value)}
            placeholder="Buscar..."
          />
        </span>
        {renderMenuDatatable()}
      </div>
    </div>
  );

  const handleApplyFilter = () => {
    try {
      searchTickets();
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error, 10000);
    }
  };

  const renderColumnUser = (rowData: any) => {
    return `${rowData.usuario.slice(0, rowData.usuario.indexOf(' ') + 2)}.`;
  };

  useEffect(() => {
    loadUserData();
    searchTickets();
    api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        const optUsers = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setUsers(optUsers);
      });
  }, []);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <HeaderButtons />

        <div className="card">
          <Form
            ref={form}
            onSubmit={handleApplyFilter}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid mb-3">
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Busca Inteligente</label>

                <div className="p-inputgroup">
                  <Button label="Pesquisar" />
                  <InputText
                    placeholder="Nome do cliente..."
                    value={src}
                    onChange={e => setSrc(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Emissão inicial</label>
                <Calendar
                  name="date_intial"
                  value={initialDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e: any) => setInitialDate(e.value)}
                  showIcon
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="basic">Emissão final</label>
                <Calendar
                  name="date_final"
                  value={finalDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e: any) => setFinalDate(e.value)}
                  showIcon
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">Situação</label>
                <Dropdown
                  value={situation}
                  options={situationOption}
                  placeholder="Selecionar"
                  onChange={e => setSituation(e.value)}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="dropdown">Status</label>
                <Dropdown
                  inputId="dropdown"
                  value={selectedStatus}
                  options={statusOptions}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedStatus(e.value)}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="dropdown">Usuário</label>
                <Dropdown
                  value={selectedUser}
                  options={users}
                  placeholder="Selecionar..."
                  onChange={e => setSelectedUser(e.value)}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="dropdown">Valor Entre</label>
                <InputNumber
                  value={valueFirst}
                  onChange={e => setValueFirst(e.value)}
                  minFractionDigits={2}
                  placeholder="0,00"
                />
              </div>
              <div className="field col-12 md:col-2 flex align-items-end">
                <InputNumber
                  value={valueSecond}
                  minFractionDigits={2}
                  placeholder="0,00"
                  onChange={e => setValueSecond(e.value)}
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid flex justify-content-end">
              <div className="col-12 md:col-4">
                <Button label="Filtrar Boletos" icon="pi pi-filter" />
              </div>
            </div>
          </Form>
        </div>
        <div className="col card">
          <div className="grid" style={{ gap: 9 }}>
            <div className="col">
              <DataTable
                header={header}
                value={tickets}
                dataKey="titulo_id"
                loading={loading}
                globalFilter={globalFilter}
                selection={ticketsSelected}
                onRowClick={e => handleRowSelect(e.data)}
                metaKeySelection={false}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                responsiveLayout="scroll"
                selectionMode="multiple"
                dragSelection
                emptyMessage="Não existe boletos"
                loadingIcon="pi pi-spinner text-primary"
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} boletos"
              >
                <Column header="#" body={codeBodyTemplate}></Column>
                <Column field="nome_cliente" header="Cliente" sortable></Column>
                <Column
                  header="Banco"
                  field="nome_banco"
                  body={bodyTemplateBank}
                ></Column>
                <Column header="Origem" field="origem" sortable></Column>
                <Column header="NF-e" body={bodyTemplateNFe} sortable></Column>
                <Column field="emissao" header="Emissão" sortable></Column>
                <Column
                  field="vencimento"
                  header="Vencimento"
                  sortable
                ></Column>
                <Column field="parcela" header="Parcela" sortable></Column>
                <Column
                  field="valor"
                  header="Valor"
                  body={valueBodyTemplate}
                ></Column>
                <Column
                  field="situacao"
                  header="Situação"
                  body={situationBodyTemplate}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                ></Column>
                <Column
                  field="usuario"
                  header="Usuário"
                  body={e => renderColumnUser(e)}
                ></Column>
                <Column body={renderColumnButtons}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TitleList, comparisonFn);
