/* eslint-disable react/react-in-jsx-scope */
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { validate } from 'uuid';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { Dialog } from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IPersonsTableDTO from '../dtos/IPersonsTableDTO';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  companies: IPersonsTableDTO[];
}

const DialogSendCrmData = ({ isOpen, onRequestClose, companies }: IProps) => {
  interface IRequest {
    user_id: string;
    panel_id: string;
    column_id: string;
    companies: string[];
  }

  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [cards, setCards] = useState<IRequest>({} as IRequest);

  const [users, setUsers] = useState<IOptionsDTO[]>([]);
  const [panels, setPanels] = useState<IOptionsDTO[]>([]);
  const [columns, setColumns] = useState<IOptionsDTO[]>([]);

  const loadUsers = async () => {
    setLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.name,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadPanelsByUser = async (id: string) => {
    setLoad(true);
    await api
      .get(`/crm/panels/user/${id}`)
      .then(({ data }) => {
        if (data) {
          setPanels(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadColumnsByPanel = async (id: string) => {
    setLoad(true);
    await api
      .get(`/crm/column/panel/${id}`)
      .then(({ data }) => {
        if (data) {
          setColumns(
            data
              .sort((a, b) => a.position - b.position)
              .map((i: any) => {
                return {
                  value: i.id,
                  label: i.title,
                };
              }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleSubmit = async (d: any) => {
    setLoad(true);
    await api
      .post('/crm/card/person', { data: cards })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Card criado com sucesso!');
          onRequestClose();
          setCards({} as IRequest);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    loadUsers();

    const companiesIds: string[] = [];

    if (companies && companies.length > 0)
      companies.forEach(c => companiesIds.push(c.id));

    setCards({ ...cards, companies: companiesIds });
  }, [isOpen]);

  return (
    <>
      <Dialog
        header="Enviar dados desta empresa para CRM?"
        visible={isOpen}
        style={{ width: 512 }}
        onHide={() => onRequestClose()}
      >
        <Form
          ref={null}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
        >
          <div className="field col-12">
            <label htmlFor="user">Usuário</label>
            <InputDropDown
              name="user"
              options={users}
              value={cards.user_id}
              placeholder="Selecione..."
              onChange={async e => {
                setCards({ ...cards, user_id: e.value });
                await loadPanelsByUser(e.value);
              }}
              filter
            />
          </div>

          <div className="field col-6">
            <label htmlFor="panel">Painel</label>
            <InputDropDown
              name="panel"
              options={panels}
              value={cards.panel_id}
              placeholder="Selecione..."
              disabled={!validate(cards.user_id)}
              onChange={async e => {
                setCards({ ...cards, panel_id: e.value });
                await loadColumnsByPanel(e.value);
              }}
              filter
            />
          </div>

          <div className="field col-6">
            <label htmlFor="collumn">Coluna</label>
            <InputDropDown
              name="collumn"
              value={cards.column_id}
              options={columns}
              placeholder="Selecione..."
              disabled={!validate(cards.panel_id)}
              onChange={e => {
                setCards({ ...cards, column_id: e.value });
              }}
              filter
            />
          </div>

          <div className="field col-12">
            <Button
              label="Adicionar"
              icon="fa-solid fa-check"
              type="submit"
              className="p-button-success"
              disabled={!validate(cards.column_id)}
              loading={load}
            />
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default DialogSendCrmData;
