import React, { useState, useRef, useEffect } from 'react';

/** react router dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../../services/api';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** utils */
import { endOfMonth, format, startOfMonth } from 'date-fns';
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { PickList, PickListChangeParams } from 'primereact/picklist';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import { Customer } from '../../../../types/person';
import { Option } from '../../../../types/optional';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import { Divider } from '../../../../components/Divider';
import { Tag } from 'primereact/tag';
import HeaderButtons from '../../header-buttons';
import { emptyOption } from '../../../business/dtos/IOptionsDTO';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import useToastContext from '../../../../hooks/toast';
import Progress from '../../../../components/progress-bar';
import messageRequestError from '../../../../utils/messageRequestError';
import { Checkbox } from 'primereact/checkbox';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const TitleCreate: React.FC = (): React.ReactElement => {
  const [source, setSource] = useState<any[]>([]);
  const [target, setTarget] = useState<any[]>([]);

  /** router */
  const navigate = useHistory();

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  /** customer */
  const [costumer, setCustomer] = useState<Option>();
  const [customers, setCustomers] = useState<Option[]>([]);

  /** banks */
  const [banks, setBanks] = useState<Array<Option>>([]);
  const [selectedBank, setSelectedBank] = useState();

  /** biils to receible */
  const [billsToReceive, setBiilsToReceible] = useState<Array<any>>([]);

  /** date */
  const today = new Date();
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(today.getFullYear(), today.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    new Date(today.getFullYear(), today.getMonth() + 1, 0),
  );

  /** enableButton */
  const [buttonEnable, setButtonEnable] = useState(true);

  const [groupParcels, setGroupParcels] = useState<boolean>(true);

  /** search consumer */
  const searchConstumer = async (event: AutoCompleteCompleteMethodParams) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search: event.query });

      if (customers) {
        const opts = customers.map((item: Customer) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setCustomers(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const selectCustomer = () => {
    setButtonEnable(false);
  };

  /** search bills */
  const searchBillToReceible = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    try {
      e.preventDefault();

      if (!costumer?.label) {
        toast('error', 'Error', 'Por favor, selecione o cliente');
        form.current?.setFieldError('customer_id', 'error');
        return;
      }

      setIsLoad(true);
      const { data } = await api
        .get('/bills-receivable/se/search', {
          params: {
            searchField: costumer?.label,
            dateStart: initialDate,
            dateEnd: finalDate,
          },
        })
        .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
        .finally(() => setIsLoad(false));

      setBiilsToReceible(data);
      setSource(data);
    } catch (error: any) {
      toast('error', 'Error', error.response.data.error);
    }
  };

  const handleReports = async (data: any) => {
    if (!target.length) {
      toast(
        'error',
        'Error',
        'Por favor, informe algum documento para gerar boletos!',
      );
      return;
    }

    try {
      setIsLoad(true);

      const schemaReports = Yup.object({
        boleto_config_id: Yup.string().required(
          'Por favor, informe o configuração do boleto!',
        ),
      });

      await schemaReports.validate(data, { abortEarly: false });

      const bills_receivable = target.map((item: any) => item.id);

      await api.post('/v2/boleto/titulo', {
        bills_receivable,
        boleto_setup_id: data.boleto_config_id,
      });
      navigate.push('/boleto/v1/titulo/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', messageRequestError(error), 10000);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const onChange = (event: PickListChangeParams) => {
    const movingAll =
      event.source.length === 0 &&
      event.target.length === billsToReceive.length;

    if (groupParcels && !movingAll) {
      const parcel = event.target.find(
        (bill: any) => !target.find((b: any) => b.id === bill.id),
      );

      if (parcel) {
        const qntParcels = Number(
          parcel.parcel_sr.substring(parcel.parcel_sr.indexOf('#') + 1),
        );

        const parcelsGrouped: any[] = source.filter((bill: any) => {
          const billQntParcels = Number(
            bill.parcel_sr.substring(bill.parcel_sr.indexOf('#') + 1),
          );

          return (
            bill.document_sr === parcel.document_sr &&
            bill.document_num === parcel.document_num &&
            qntParcels === billQntParcels
          );
        });

        if (parcelsGrouped.length < qntParcels) {
          toast(
            'warn',
            'Alerta',
            `Foram encontradas apenas ${parcelsGrouped.length} de ${qntParcels} parcelas. Por favor, verifique o período informado na busca!`,
            8000,
          );
          return;
        }

        if (parcelsGrouped.length > qntParcels) {
          toast(
            'warn',
            'Alerta',
            `Não foi possível agrupar as parcelas, pois encontramos numerações repetidas para esse pedido! Ex.: 1#12, 2#12, ..., 1#12. Por favor, selecione as parcelas manualmente.`,
            8000,
          );
          return;
        }

        if (parcelsGrouped.length === qntParcels) {
          setTarget([...target, ...parcelsGrouped]);
          const newSource = source.filter(
            bill => !parcelsGrouped.find(b => bill.id === b.id),
          );
          setSource(newSource);
        }
      }
    } else {
      setSource(event.source);
      setTarget(event.target);
    }
  };

  const clearForm = () => {
    setCustomer(emptyOption);
    setInitialDate(startOfMonth(today));
    setFinalDate(endOfMonth(today));
    setBiilsToReceible([]);
    setSource([]);
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };
  const itemTemplateSource = (item: any) => {
    return (
      <div
        className={`row my-0 py-0 ${
          item.status_payment !== 'A' ? 'surface-200' : ''
        }`}
      >
        <div className="col flex">
          <div className="col-3 px-1">
            Doc. {item.document_num}- Sr. {item.document_sr}
          </div>
          <div className="col-1 px-1">{item.parcel_sr} </div>
          <div className="col-3 px-1">
            Venc. {format(new Date(item.date_to_pay), 'dd/MM/yy')}
          </div>
          <div className="col-2 px-1">
            Total{': '}
            {new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.value_total)}
          </div>
          <div className="col-2 px-1">
            Parcela{': '}
            {new Intl.NumberFormat('BRL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.value)}
          </div>

          <div className="px-2">
            {item.status_payment === 'A' ? (
              <Button
                className="customer-badge status-new px-1"
                tooltip="Aguardando"
              >
                {item.status_payment}
              </Button>
            ) : (
              <Button
                className="customer-badge status-unqualified px-1"
                tooltip={item.status_payment === 'V' ? 'Vencido' : 'Cancelado'}
              >
                {item.status_payment}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    api.get('/v2/boleto/setup').then(({ data }) => {
      const opts = data.setups.map((item: any) => {
        return {
          label: `${item.title}-${item.convenio.cedenteConta.conta_agencia} - ${item.convenio.cedenteConta.conta_numero}`,
          value: `${item.id}`,
        };
      });
      if (opts.length === 0) {
        toast(
          'warn',
          'Alerta',
          'Nenhuma configuração para emissão de boletos foi encontrada!',
        );
      } else {
        setBanks(opts);
        setSelectedBank(opts[0].value);
      }
    });
  }, []);

  return (
    <>
      <HeaderButtons />
      <Progress isLoad={isLoad} />
      <div className="card">
        <Toast ref={toast} />
        <Form
          ref={form}
          onSubmit={handleReports}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label style={{ flex: 2 }}>Cliente</label>
              <InputAutoComplete
                name="customer_id"
                value={costumer}
                completeMethod={e => searchConstumer(e)}
                suggestions={customers}
                field="label"
                onSelect={() => selectCustomer()}
                onChange={e => setCustomer(e.value)}
                style={{ flex: 4 }}
                itemTemplate={itemTemplate}
                placeholder="Buscar Cliente..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label>Data inicial</label>
              <Calendar
                name="date_initial"
                value={initialDate}
                onChange={e => setInitialDate(e.value)}
                dateFormat="dd/mm/yy"
                showIcon
              />
            </div>
            <div className="field col-12 md:col-4">
              <label>Data final</label>
              <Calendar
                name="date_initial"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
                showIcon
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end">
              <Button
                label="Buscar"
                disabled={buttonEnable}
                onClick={e => searchBillToReceible(e)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="">Configurações do Boleto</label>
              <InputDropDown
                name="boleto_config_id"
                value={selectedBank}
                options={banks}
                placeholder="Selecionar..."
                onChange={e => setSelectedBank(e.value)}
              />
            </div>
          </div>
          <Divider align="left" type="dashed" className="cursor-pointer">
            <div className="inline-flex align-items-center">
              <span className="p-tag">
                <i className="fa-solid fa-file mx-2"></i>
                Selecione documentos para gerar os boletos
                <i className="fa-solid fa-barcode mx-2"></i>
              </span>
            </div>
          </Divider>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-4 ml-8 mb-0 flex justify-content-betweeb py-2">
              <span>Agrupar parcelas?</span>
              <InputSwitch
                name="group_parcels"
                className="ml-2"
                checked={groupParcels}
                onChange={e => setGroupParcels(e.value)}
                tooltipOptions={{ position: 'top' }}
                tooltip="Marque para selecionar todas as parcelas do boleto e desmarque para selecionar uma a uma"
              />
            </div>

            <div className="field col-12 md:col">
              <PickList
                source={source}
                target={target}
                itemTemplate={itemTemplateSource}
                sourceHeader={`DOCUMENTOS A RECEBER${
                  source.length > 0 ? `: ${source.length}` : ''
                }`}
                targetHeader={`GERAR BOLETOS${
                  target.length > 0 ? `: ${target.length}` : ''
                }`}
                sourceStyle={{ height: '342px' }}
                targetStyle={{ height: '342px' }}
                onChange={onChange}
                onMoveAllToTarget={e => {
                  setTarget(billsToReceive);
                  setSource([]);
                }}
                onMoveAllToSource={e => {
                  setSource(billsToReceive);
                  setTarget([]);
                }}
              ></PickList>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                className="p-button-raised p-button-danger"
                icon="fa-solid fa-x"
                type="button"
                onClick={() => clearForm()}
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Gerar" icon="fa-solid fa-plus" loading={isLoad} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TitleCreate;
