import React, { useState, useRef } from 'react';

/** react-rouer-dom */
import { useHistory } from 'react-router-dom';

/** uuid */
import { v4 } from 'uuid';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputMask } from '../../../../components/Inputs/InputMask';
import InputDate from '../../../../components/Inputs/InputCalendar';

import { Toast } from 'primereact/toast';
import { endOfDay } from 'date-fns/esm';
import { FileUpload } from 'primereact/fileupload';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';
import { InputPhone } from '../../../../components/Inputs/InputPhone';

const LeadsCreate = (): React.ReactElement => {
  const navigate = useHistory();

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);
  const upload = useRef<any>(null);

  /** state */
  const [place, setPlace] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  /** id */
  const id = v4();

  const date = new Date();
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** handle consultant */

  const handleSubmit = async (data: any) => {
    formRef.current?.setErrors({});

    try {
      setLoadingStatus(true);
      const schema = Yup.object({
        nome: Yup.string().required('Por favor, informe o nome'),
        email: Yup.string()
          .email('Por favor, informe email válido!')
          .required('Por favor, informe um email'),
        whatsapp: Yup.string()
          .required('Por favor, informe o whatsapp!')
          .matches(
            /\(\d{2,}\)\s?\d?\s?\d{4,}-?\d{4}/g,
            'Por favor, informe número de telefone válido',
          ),
        seguradora: Yup.string()
          .nullable(true)
          .min(8, 'Seguradora deve conter no mínimo 8 caracteres!')
          .transform((_, val) => (val !== '' ? val : null)),

        placa: Yup.string().required('Por favor, informe a placa!'),
      });
      await schema.validate(data, { abortEarly: false });

      const { data: response } = await api.post('/mkt/lead/in', {
        lead: {
          ...data,
          vigencia_start: initialDate,
          id,
          vigencia_end: finalDate,
          seguradora: data.seguradora !== '' ? data.seguradora : null,
          apolice_url: null,
          obs: data.obs !== '' ? data.obs : null,
        },
      });

      if (upload.current?.files) {
        const form = new FormData();
        form.set('apolice_url', upload.current.files[0]);
        await api.patch(
          `/mkt/lead/upload/in/${response?.createInLead?.id}`,
          form,
        );
      }

      navigate.push('/mkt/lead/listar');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast(
          'error',
          'Error',
          error.response?.data?.error || error.response?.data?.message,
        );
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const changePlace = (place: string) => {
    setPlace(place);
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/mkt/lead/listar')}
      />
      <div className="card col-12 md:col-6">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Nome</label>
              <InputText name="nome" placeholder="Nome..." />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">E-mail</label>
              <InputText name="email" placeholder="example@mail.com" />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Whatsapp</label>
              <InputPhone
                name="whatsapp"
                placeholder="(00) 00000-0000"
                value={whatsapp}
                onChange={e => setWhatsapp(e.currentTarget.value)}
              />
            </div>
            <div className="field col-6 md:col-6">
              <label htmlFor="basic">Placa</label>
              <InputMask
                name="placa"
                placeholder="000-0000"
                mask="***-****"
                value={place}
                onChange={e => changePlace(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Seguradora</label>
              <InputText
                name="seguradora"
                placeholder="Meu carro seguro Ltda"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Vigência início</label>
              <InputDate
                name="vigencia_start"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
                placeholder="00/00/0000"
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Vigência fim</label>
              <InputDate
                name="vigencia_end"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
                placeholder="00/00/0000"
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Apolice</label>
              <FileUpload
                mode="basic"
                name="demo[]"
                accept="image/*, application/pdf"
                ref={upload}
                chooseLabel="Escolher arquivo"
                maxFileSize={1000000}
                className=""
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Obs</label>
              <InputTextArea name="obs" />
            </div>
          </div>
          <Button
            type="submit"
            label="Salvar"
            className="w-full p-button-success"
            loading={loadingStatus}
          />
        </Form>
      </div>
    </>
  );
};

export default LeadsCreate;
