import React, { useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** utils */
import api from '../../../services/api';

import { ProgressBar } from 'primereact/progressbar';
import useToastContext from '../../../hooks/toast';
import showErrors from '../../../utils/error';

const GroupCreate: React.FC = () => {
  const router = useHistory();
  const formRef = useRef<FormHandles>(null);

  /** state */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** ref */
  const toast = useToastContext();

  const handleSubmit = async (data: { title: string; description: string }) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const schemaGroup = Yup.object({
        title: Yup.string().required('Por favor, informe o nome do grupo!'),
      });

      await schemaGroup.validate(data, { abortEarly: false });

      const res = await api.post('/groups', data);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Categoria do produto criada com sucesso!');
        router.push('/groups/list');
      }
    } catch (error: any) {
      showErrors(error, formRef, toast);
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => router.push('/groups/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="card md:w-6 sm:w-full">
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Nome do grupo</label>
              <InputText placeholder="Grupo..." name="title" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <InputTextEdit name="description" style={{ height: '150px' }} />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                type="reset"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default GroupCreate;
