import React, { useState, useRef } from 'react';

/** yup */
import * as Yup from 'yup';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** service */
import api from '../../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { Toast } from 'primereact/toast';

const ReportsNoSell: React.FC = (): React.ReactElement => {
  const [qntDays, setQntDays] = useState<number | null>(1);

  /** ref */
  const toast = useRef<any>(null);

  /** showToast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** ref */
  const form = useRef<FormHandles>(null);

  const handleReports = async (data: any) => {
    try {
      const schemaReports = Yup.object({
        qnt_days: Yup.string().required('Por favor, informe o status!'),
      });
      await schemaReports.validate(data, { abortEarly: false });

      const { data: response } = await api.get(`/reports/products/no-sell?`, {
        params: {
          qndDays: data.status,
        },
      });
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Quantidade dias</label>
              <InputNumber
                name="qnt_days"
                inputId="minmax-buttons"
                value={qntDays}
                onValueChange={e => setQntDays(e.value)}
                mode="decimal"
                showButtons
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <Button label="Gerar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsNoSell;
