export default [
  {
    label: 'Unico',
    value: 'UN',
  },
  {
    label: 'Parcelado',
    value: 'PA',
  },
];
