import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../../components/DataTable';

/** services */
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import api from '../../../../services/api';
import { parseToCnpj, parseToCpf } from '../../../../utils/cpf_cnpj_format';

/** menu options */

const AssignorList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** cedentes */
  const [cedentes, setCedentes] = useState<Array<any>>([]);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const updateStatus = async () => {
    try {
      await api.put(`/v2/boleto/cedente`);
      searchCedentes();
      showToast('success', 'Sucesso!', 'Status atualizado!');
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  const newAssigtor = async () => {
    try {
      await api.post('/v2/boleto/cedente');
      showToast('success', 'Sucesso', 'Cedente Gerado');
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  const searchCedentes = async () => {
    try {
      const { data } = await api.get('/v2/boleto/cedente');
      setCedentes(data.cedentes);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  /** table */

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const cpfCnpjBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.cedente_cpf_cnpj.length > 11
            ? parseToCnpj(rowData.cedente_cpf_cnpj)
            : parseToCpf(rowData.cedente_cpf_cnpj)}
        </span>
      </>
    );
  };

  const updatedAtBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>{format(new Date(rowData.updated_at), 'dd/MM/yyyy')}</span>
      </>
    );
  };

  const actionBodyTemplate = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-refresh"
          label="Atualizar"
          className="p-button-raised mr-4"
          onClick={() => updateStatus()}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Cedentes</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  useEffect(() => {
    searchCedentes();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => newAssigtor()}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={cedentes}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} cedentes"
          globalFilter={globalFilter}
          emptyMessage="Não existe Cedentes."
          header={header}
          responsiveLayout="scroll"
        >
          <Column field="code" header="Id" body={codeBodyTemplate}></Column>

          <Column header="Cedente" field="cedente_razao_social"></Column>
          <Column
            field="price"
            header="CPF/CNPJ"
            body={cpfCnpjBodyTemplate}
          ></Column>
          <Column
            field="status"
            header="Status"
          // body={statusBodyTemplate}
          ></Column>
          <Column
            header="Atualizado"
            field="updated_at"
            body={updatedAtBodyTemplate}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default AssignorList;
