import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputColor } from '../../../components/Inputs/InputColor';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar as SideBarSearchOsStatus } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';

const osStatus: React.FC = () => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** hooks */
  const router = useHistory();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [posicao, setPosicao] = useState<number>(0);
  const [titulo, setTitulo] = useState<string>('');
  const [isLast, setIsLast] = useState<boolean>(false);
  const [fieldColor, setFieldColor] = useState<string>('#3498DB');

  interface IStatusOpts {
    value: string;
    label: string;
    position: number;
    is_last: boolean;
    field_color: string;
  }
  const [statusOpts, setStatusOpts] = useState<Array<IStatusOpts>>([]);

  const [modalSearchOsStatusVisible, setModalSearchOsStatusVisible] =
    useState<boolean>(false);
  const [statusKeyword, setStatusKeyword] = useState<string>('');

  /** functions */
  const clearForm = async () => {
    setLoadingStatus(false);
    setId('');
    setPosicao(0);
    setTitulo('');
    setIsEdit(false);
    setIsLast(false);
    setFieldColor('#3498DB');
  };

  const handleSubmit = async () => {
    try {
      setLoadingStatus(true);
      if (isEdit) {
        const res = await api.put('/os/status', {
          osStatus: {
            id,
            position: posicao,
            title: titulo,
            is_last: isLast,
            field_color: fieldColor,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Status editado com sucesso!');
        }
      } else {
        const res = await api.post('/os/status', {
          osStatus: {
            title: titulo,
            is_last: isLast,
            field_color: fieldColor,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Status cadastrado com sucesso!');
        }
      }

      clearForm();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchStatus = async () => {
    try {
      setLoadingStatus(true);
      const res = await api.get('/os/status/list?page=1&perPage=50&status=A');

      if (res.status >= 200 && res.status <= 299) {
        const opts = res.data.listAllOsStatus.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
            position: i.position,
            is_last: i.is_last,
            field_color: i.field_color,
          };
        });

        setStatusOpts(opts);
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSelectStatus = async (id: string) => {
    const dt = statusOpts.find(i => i.value === id);

    if (!dt) {
      toast('warn', 'Alerta', 'Falha ao selecionar o item!');
      return;
    }

    setId(dt.value);
    setPosicao(dt.position);
    setTitulo(dt.label);
    setIsLast(dt.is_last);
    setFieldColor(dt.field_color);
    setIsEdit(true);
    setModalSearchOsStatusVisible(false);
  };

  const handleRemStatus = async (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Falha ao exlcuir, tente novamente!');
      return;
    }

    try {
      setLoadingStatus(true);

      const res = await api.delete(`/os/status/${id}`);
      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Item excluído com sucesso!');
        searchStatus();
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** render */
  const renderRemButton = (id: string) => {
    return (
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemStatus(id)}
      />
    );
  };

  return (
    <>
      <Button
        label="Novo"
        className="mb-3"
        icon="fa-solid fa-plus"
        onClick={() => clearForm()}
      />
      <Button
        label="Buscar"
        className="mb-3 mx-2"
        icon="fa-solid fa-search"
        onClick={() => {
          setModalSearchOsStatusVisible(true);
          searchStatus();
        }}
      />
      <Button
        label="O.S"
        className="mb-3 mx-2"
        icon="fa-solid fa-wrench"
        onClick={() => router.push('/os/new')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 sm:w-full"
        />
      )}
      <div className="card w-6">
        <Form ref={formRef} onSubmit={() => handleSubmit()}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              <label htmlFor="position">Posição</label>
              <InputNumber
                name="position"
                placeholder="0"
                value={posicao}
                onChange={e => setPosicao(Number(e.value))}
                disabled={isEdit ? false : true}
              />
            </div>
            <div className="field col-9">
              <label htmlFor="title">Status da O.S</label>
              <InputText
                name="title"
                placeholder="Ex.: Análise/Concluído..."
                value={titulo}
                onChange={e => setTitulo(e.currentTarget.value)}
                maxLength={16}
              />
            </div>

            <div className="field col-6">
              <div className="flex flex-column align-items-left">
                <label htmlFor="field_color" className="font-bold block mb-2">
                  COR DO STATUS
                </label>
                <InputColor
                  inputId="field_color"
                  name="field_color"
                  format="hex"
                  value={fieldColor}
                  onChange={e => setFieldColor(e.value as string)}
                  className="mb-2"
                  defaultColor="c8c8c8"
                />
                <span>{!fieldColor ? 'SEM COR' : fieldColor}</span>
              </div>
            </div>

            <div className="field col-6">
              <div className="flex align-items-center justify-content-end">
                <label htmlFor="is_last">Último status?</label>
                <InputSwitch
                  name="is_last"
                  className="ml-3"
                  checked={isLast}
                  onChange={e => setIsLast(e.value)}
                />
              </div>
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => clearForm()}
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={isEdit ? 'Editar' : 'Salvar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchOsStatus
        visible={modalSearchOsStatusVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchOsStatusVisible(false)}
      >
        <h3>Buscar status das O.S</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="keyword"
                placeholder="Manutenção..."
                type={'text'}
                value={statusKeyword}
                onChange={e => setStatusKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={statusKeyword.length < 3 ? true : false}
                onClick={() => searchStatus()}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={statusOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectStatus(e.value.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="label" header="Título"></Column>
                <Column
                  header="Encerramento"
                  body={r => (r.is_last === true ? 'Sim' : 'Não')}
                ></Column>
                <Column
                  header="*"
                  body={e => renderRemButton(e.value)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchOsStatus>
    </>
  );
};

export default osStatus;
