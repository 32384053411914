import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonHelp from '../../../components/Buttons/ButtonHelp';

interface IProps {
  videoId?: string;
}

const BillsToPayHeader = ({ videoId }: IProps): React.ReactElement => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Listar"
        className="mb-2 mr-2"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/bills/pay/list')}
      />
      <Button
        label="Nova"
        className="mb-2 mr-2"
        icon="fa-solid fa-plus"
        onClick={() => navigate.push('/bills/pay/create')}
      />
      <Button
        label="Baixar"
        className="mb-2 mr-2"
        icon="fa-solid fa-hand-holding-dollar"
        onClick={() => navigate.push('/bills/pay/drop')}
      />
      <Button
        label="Relatório"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => navigate.push('/reports/bills-payable/by-provider')}
      />

      {videoId && <ButtonHelp key={null} videoId={videoId} />}
    </>
  );
};

export default BillsToPayHeader;
