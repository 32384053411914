import { format, startOfMonth } from 'date-fns';
import { lastDayOfMonth } from 'date-fns/esm';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../../components/DataTable';
import renderColumnShortName from '../../../../components/DataTableColumns/RenderColumnShortName';
import MenuPopUp from '../../../../components/MenuPopPup';
import { AppContext } from '../../../../context/AppContext';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import HeaderButtons from '../../header-buttons';
import { Dialog } from '../../../../components/Modal';
import messageRequestError from '../../../../utils/messageRequestError';
import Progress from '../../../../components/progress-bar';
import { Tooltip } from 'primereact/tooltip';
import SidebarDownloadRemittancePack from '../sidebar-download-remittance';

const situationOption = [
  { label: 'Nenhum', value: '' },
  { label: 'Salvo', value: 'SALVO' },
  { label: 'Cancelado', value: 'CANCELADO' },
  { label: 'Estacionado', value: 'ESTACIONADO' },
  { label: 'Processando', value: 'PROCESSANDO' },
  { label: 'Gerada', value: 'GERADA' },
];

type RemittanceType = {
  rem_id: string;
  cliente: string;
  emitido: Date | null;
  numero: number;
  valor: number;
  arquivo: string;
  situacao: string;
  status: string;
  usuario: string;
  mensagem: string;
};

const RemittanceList = (): React.ReactElement => {
  const [remittances, setRemittances] = useState<Array<RemittanceType>>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [src, setSrc] = useState('');
  const [userIdSelect, setUserIdSelect] = useState('');
  const [initialDate, setInitialDate] = useState(startOfMonth(new Date()));
  const [finalDate, setFinalDate] = useState(lastDayOfMonth(new Date()));
  const [situation, setSituation] = useState('');
  const [valueStart, setValueStart] = useState();
  const [valueEnd, setValueEnd] = useState();

  const [remId, setRemId] = useState<string>('');
  const [confirmCancelDialogVisible, setConfirmCancelDialogVisible] =
    useState<boolean>(false);

  const [sidebarDownloadPackVisible, setSidebarDownloadPackVisible] =
    useState<boolean>(false);

  const { state } = useContext(AppContext);

  const [load, setLoad] = useState<boolean>(false);

  const [selectedRemittence, setSelectedRemittene] = useState<RemittanceType>({
    rem_id: '',
    cliente: '',
    emitido: null,
    numero: 0,
    valor: 0,
    arquivo: '',
    situacao: '',
    status: '',
    usuario: '',
    mensagem: '',
  });

  const statusOptions = [
    { label: 'Todos', value: 'T' },
    { label: 'Ativo', value: 'A' },
    { label: 'Cancelado', value: 'C' },
  ];
  const [selectedStatus, setSelectedStatus] = useState('A');

  /* refs */
  const toast = useToastContext();
  const menu = useRef<any>(null);

  const [loading] = useState(false);

  /* navigate */
  const navigate = useHistory();

  const printRemittance = (arquivo: string) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/files/${state.user.contract_id}/rem/${arquivo}`;
    window.open(url);
  };

  async function updateStatus(rem_id: string) {
    try {
      await api.get('/v2/boleto/titulo/v2/status', {
        params: {
          idIntegracao: rem_id,
        },
      });
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error);
    }
  }

  const cancelRemittance = async () => {
    setLoad(true);

    await api
      .delete(`/v2/boleto/remessa/cancel/${remId}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Remessa cancelada com sucesso!');
          setConfirmCancelDialogVisible(false);
          searchRemittances();
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  const renderColumnPosition = (rowData: RemittanceType, params: any) => {
    return params.rowIndex + 1;
  };

  const renderColumnDecimal = (rowData: RemittanceType) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(rowData.valor);
  };

  const renderColumnFile = (rowData: RemittanceType) => {
    return (
      <>
        <span
          className="file_name"
          data-pr-tooltip="Nosso número - Cliente - Valor"
          data-pr-position="top"
        >
          {rowData.arquivo}
        </span>
      </>
    );
  };

  const messageBodyTemplate = (rowData: RemittanceType) => {
    return (
      <>
        <span>{rowData.mensagem}</span>
      </>
    );
  };

  const cancelRemittanceDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={() => {
          setRemId('');
          setConfirmCancelDialogVisible(false);
        }}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={cancelRemittance}
      />
    </React.Fragment>
  );

  const renderColumnSituation = (rowData: RemittanceType) => {
    if (rowData.status === 'A')
      return (
        <>
          {rowData.situacao === 'GERADA' ? (
            <>
              <Button
                className="customer-badge status-new"
                tooltip={rowData.mensagem}
              >
                {rowData.situacao}
              </Button>
            </>
          ) : rowData.situacao === 'SALVO' ? (
            <Button
              className="customer-badge status-qualified"
              tooltip={rowData.mensagem}
            >
              {rowData.situacao}
            </Button>
          ) : (
            <Button
              className="customer-badge status-unqualified"
              tooltip={rowData.mensagem}
            >
              {rowData.situacao}
            </Button>
          )}
        </>
      );
  };

  const renderColumnStatus = (rowData: RemittanceType) => {
    return rowData.status === 'A' ? (
      <i className="fa-solid fa-check text-blue-500"></i>
    ) : (
      <i className="fa-solid fa-xmark text-pink-500"></i>
    );
  };

  const renderColumnDate = (rowData: RemittanceType) => {
    if (rowData.emitido !== null) {
      return <span>{format(new Date(rowData.emitido), 'dd/MM/yyyy')}</span>;
    } else {
      return <span>-</span>;
    }
  };

  const selectedOptionMenu = (
    rowData: RemittanceType,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setSelectedRemittene(rowData);
    menu?.current?.toggle(event);
  };

  const renderColumnButtons = (rowData: RemittanceType) => {
    if (rowData.status === 'A') {
      const items = [
        {
          label: 'Baixar remessa',
          icon: 'pi pi-cloud-download',
          command: () => {
            printRemittance(rowData.arquivo);
          },
        },
      ];

      if (rowData.situacao !== 'GERADA')
        items.push({
          label: 'Atualizar Status',
          icon: 'pi pi-refresh',
          command: () => {
            updateStatus(rowData.rem_id);
          },
        });

      if (rowData.situacao === 'GERADA')
        items.push({
          label: 'Cancelar',
          icon: 'pi pi-times-circle',
          command: () => {
            setRemId(rowData.rem_id);
            setConfirmCancelDialogVisible(true);
          },
        });

      return (
        <MenuPopUp
          model={items}
          rowData={rowData}
          setData={setSelectedRemittene}
        />
      );
    }
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="flex"></div>
      <span className="block md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const searchRemittances = async () => {
    setLoad(true);
    try {
      const { data } = await api.get('/v2/boleto/remessa/search', {
        params: {
          dateStart: initialDate,
          dateEnd: finalDate,
          status: selectedStatus,
          src: src,
          situacao: situation,
          userId: userIdSelect,
          valueStart: valueStart,
          valueEnd: valueEnd,
        },
      });
      setRemittances(data.remessas);
    } catch (error: any) {
      toast('error', 'Error', error.response?.data?.error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    searchRemittances();
  }, []);

  useEffect(() => {
    if (remId !== '') {
      const remittance = remittances.find(r => r.rem_id === remId);
      if (remittance) setSelectedRemittene(remittance);
    }
  }, [remId]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <HeaderButtons />
        <div className="card">
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="search">Cliente</label>
              <InputText
                name="search"
                placeholder="Ex.: João Silva"
                value={src}
                onChange={e => setSrc(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Situação</label>
              <Dropdown
                inputId="dropdown"
                value={situation}
                options={situationOption}
                onChange={e => setSituation(e.value)}
                optionLabel="label"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Status</label>
              <Dropdown
                inputId="dropdown"
                value={selectedStatus}
                options={statusOptions}
                placeholder="Selecionar..."
                onChange={e => setSelectedStatus(e.value)}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="basic">Data inicial</label>
              <Calendar
                id="basic"
                value={initialDate}
                onChange={(e: any) => setInitialDate(e.value)}
                dateFormat="dd/mm/yy"
                showIcon
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="basic">Data final</label>
              <Calendar
                id="basic"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={(e: any) => setFinalDate(e.value)}
                showIcon
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid flex justify-content-end">
            <div className="col-6 md:col-2">
              <Button
                label="Baixar lote"
                icon="pi pi-cloud-download"
                className="p-button-success"
                onClick={() => setSidebarDownloadPackVisible(true)}
                loading={load}
              />
            </div>
            <div className="col-6 md:col-2">
              <Button
                label="Filtrar"
                icon="pi pi-filter"
                onClick={() => searchRemittances()}
                loading={load}
              />
            </div>
          </div>
        </div>

        <Progress isLoad={load} />

        <Tooltip target=".file_name" />

        <DataTable
          value={remittances}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive card"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} remessa"
          globalFilter={globalFilter}
          emptyMessage="Não existe remessas."
          header={header}
          responsiveLayout="scroll"
        >
          <Column header="N°" body={renderColumnPosition}></Column>
          <Column header="Cliente" field="cliente" sortable></Column>
          <Column header="Origem" field="origem"></Column>
          <Column body={renderColumnDate} header="Emitido"></Column>
          <Column header="Valor" body={renderColumnDecimal} sortable></Column>
          <Column header="N." field="numero"></Column>
          <Column
            header="Arquivo"
            className="file_name"
            body={renderColumnFile}
            sortable
          ></Column>
          <Column
            header="Situação"
            body={renderColumnSituation}
            sortable
          ></Column>

          <Column body={renderColumnStatus} header="Status" sortable></Column>
          <Column
            header="Usuário"
            field="usuario"
            body={row => renderColumnShortName(row.usuario || '')}
          ></Column>

          <Column header="*" body={renderColumnButtons}></Column>
        </DataTable>

        <Dialog
          visible={confirmCancelDialogVisible}
          onHide={() => {
            setConfirmCancelDialogVisible(false);
            setRemId('');
          }}
          header="Confirmar"
          footer={cancelRemittanceDialogFooter}
          draggable={false}
          modal
          className="w-10 md:w-4"
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            ></i>
            <span> Tem certeza que deseja excluir esta remessa?</span>
          </div>
        </Dialog>

        <SidebarDownloadRemittancePack
          isOpen={sidebarDownloadPackVisible}
          onRequestClose={() => {
            setSidebarDownloadPackVisible(false);
          }}
        />
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RemittanceList, comparisonFn);
