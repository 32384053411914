import { useQuery } from 'react-query';
import { IUser } from '../pages/user/dtos/IUser';
import api from '../services/api';

interface IFind {
  paginate: {
    page: number;
    perPage: number;
    status: string[];
  };
}

export default function useLoadUsers({ paginate }: IFind) {
  const url = '/users/list';
  const { data, error, isLoading, isFetched } = useQuery<IUser[]>(
    ['users', url],
    () =>
      api
        .post(url, {
          pagination: { page: 1, perPage: 1000, status: paginate.status },
        })
        .then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
