import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../../components/DataTable';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IProductOpts from '../../order/Create/dtos/IProductOpts';
import ProductPriceHeader from '../top-menu';

const ProductPricesAdjust = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [editTypeOpts, setEditTypeOpts] = useState<IOptionsDTO[]>([
    { label: 'Tabela de preços', value: 'T' },
    { label: 'Produtos', value: 'P' },
  ]);
  const [editType, setEditType] = useState<string>(editTypeOpts[0].value);

  const [tablePricesOpts, setTablePricesOpts] = useState<IOptionsDTO[]>([]);
  const [tablePrices, setTablePrices] = useState<Array<string>>([]);

  const [fieldsToChange, setFieldsToChange] = useState<any>({
    vlr_ven: true,
    vlr_min: false,
    vlr_max: false,
  });

  const [productOpts, setProductOpts] = useState<IProductOpts[]>([]);
  const [products, setProducts] = useState<IProductOpts[]>([]);
  const [product, setProduct] = useState<IProductOpts>({
    cost: 0,
    sku: '',
    label: '',
    value: '',
    qnt_out: 2,
    price_out: 2,
    stock: 0,
    image_url: '',
  });

  const [valueTypeOpts, setValueTypeOpts] = useState<IOptionsDTO[]>([
    { label: 'Porcentagem Ex.: 3.0%', value: 'P' },
    { label: 'Valor Ex.: 3,00', value: 'V' },
  ]);
  const [valueType, setValueType] = useState<string>(valueTypeOpts[0].value);

  const [value, setValue] = useState<number>(0);

  /** functions */
  const handleSubmit = async (d: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      d.adjust_value = value;

      const schemaValidation = Yup.object({
        adjust_value: Yup.number().required('O valor é obrigatório!'),
      });

      await schemaValidation.validate(d, { abortEarly: false });

      if (tablePrices.length === 0) {
        toast('warn', 'Alerta', 'Informe pelo menos uma tabela de preços!');
        return;
      }

      if (fieldsToChange.length === 0) {
        toast(
          'warn',
          'Alerta',
          'Informe pelo menos um preço que será editado!',
        );
        return;
      }

      // requisição por tabela de preços
      if (editType === 'T') {
        const res = await api.post('/products-prices/v2/adjust-by-table', {
          tableItems: tablePrices,
          fields: fieldsToChange,
          value_by: valueType,
          value,
        });
        if (res.status >= 200 || res.status <= 299) {
          toast(
            'success',
            'Sucesso',
            'Ajuste de preços por tabela de preços realizado com sucesso!',
          );

          setTablePrices([]);
          setFieldsToChange({ vlr_ven: true, vlr_min: false, vlr_max: false });
          setValueType('P');
          setValue(0);
        }
      } else {
        // requisição por produtos

        if (products.length === 0) {
          toast('warn', 'Alerta', 'Informe pelo menos um produto!');
          return;
        }

        const items: Array<{ product_id: string; table_price_id: string }> = [];
        for (const t of tablePrices) {
          for (const p of products) {
            items.push({
              table_price_id: t,
              product_id: p.value,
            });
          }
        }

        const res = await api.post('/products-prices/v2/adjust-by-product', {
          productItems: items,
          fields: fieldsToChange,
          value_by: valueType,
          value,
        });

        if (res.status >= 200 || res.status <= 299) {
          toast(
            'success',
            'Sucesso',
            'Ajuste de preços por produtos realizado com sucesso!',
          );
          setTablePrices([]);
          setFieldsToChange({ vlr_ven: true, vlr_min: false, vlr_max: false });
          setProducts([]);
          setValueType('V');
          setValue(0);
        }
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', e.response?.data.validation.body.message);
        }
      }

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error, 10000);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    if (tablePrices.length === 0) {
      toast('warn', 'Alerta', 'Informe a tabela de preços!');
      return;
    }

    if (e.query.length >= 3) {
      await api
        .get(`/products/v2/search?src=${e.query}`)
        .then(({ data }) => {
          const opts = data.products.map((item: any) => {
            return {
              value: item.id,
              label: item.titulo,
              sku: item.sku,
              stock: item.estoque,
              price_out: item.price_out,
              qnt_out: item.qnt_out,
            };
          });

          if (opts.length <= 0) {
            toast('error', 'Error', 'Nenhum produto encontrado!');
          }

          setProductOpts(opts);
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast('warn', 'Alerta', e.response.data.error);
          } else {
            toast('error', 'Erro', e.message);
          }
        });
    }
  };

  const handleProductSelected = (d: AutoCompleteSelectParams) => {
    if (validate(d.value)) {
      const hasInTable = products.find(p => p.value === d.value);
      if (hasInTable) {
        toast(
          'warn',
          'Alerta',
          `O produto.: ${hasInTable.label} já foi adicionado na tabela!`,
        );
        return;
      }

      const _p = productOpts.find(p => p.value === d.value);
      if (_p) {
        setProducts([...products, _p]);
        setProduct({
          cost: 0,
          sku: '',
          label: '',
          value: '',
          qnt_out: 2,
          price_out: 2,
          stock: 0,
          image_url: '',
        });
      }
    }
  };

  const handleRemProduct = (id: string) => {
    const noId = products.filter((i: any) => i.value !== id);
    setProducts(noId);
  };

  const listTablePrices = async () => {
    setLoadingStatus(true);
    await api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            label: i.title,
            value: i.id,
          };
        });
        setTablePricesOpts(opts);
      })
      .catch((e: any) => {
        toast('error', 'Error', e.response.data.error);
        return;
      })
      .finally(() => setLoadingStatus(false));
  };

  /** renders */
  const renderButtonRemoveProduct = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemProduct(id)}
        />
      </div>
    );
  };

  const renderItemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** useEffects */
  useEffect(() => {
    listTablePrices();
  }, []);

  return (
    <>
      <ProductPriceHeader />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="w-6"
        />
      )}
      <Form ref={formRef} onSubmit={d => handleSubmit(d)}>
        <div className="card w-6">
          <div className="p-fluid grid formgrid">
            <div className="field col-12">
              <SelectButton
                value={editType}
                onChange={e => setEditType(e.value)}
                options={editTypeOpts}
              />
            </div>

            <div className="field col-12">
              <label htmlFor="table_prices">Tabelas de preço</label>
              <MultiSelect
                name="table_prices"
                options={tablePricesOpts}
                value={tablePrices}
                placeholder="Selecionar..."
                onChange={e => setTablePrices(e.value)}
                tooltip={'Tabelas de preços'}
                tooltipOptions={{ position: 'bottom' }}
                display="chip"
              />
            </div>

            <label className="field col-12 md:col" htmlFor="fields_to_change">
              Ajustar quais campos?
            </label>
            <div className="field col-12 md:col flex">
              <div className="field col-4 md:col py-0">
                <div className="flex justify-content-between py-2">
                  <InputSwitch
                    name="vlr_min"
                    trueValue={true}
                    falseValue={false}
                    checked={fieldsToChange.vlr_ven}
                    onChange={e => {
                      setFieldsToChange({
                        ...fieldsToChange,
                        vlr_ven: e.value,
                      });
                    }}
                  />
                  <span className="mr-2">Valor de venda</span>
                </div>
              </div>
              <div className="field col-4 md:col py-0">
                <div className="flex justify-content-between py-2">
                  <InputSwitch
                    name="vlr_min"
                    trueValue={true}
                    falseValue={false}
                    checked={fieldsToChange.vlr_min}
                    onChange={e => {
                      setFieldsToChange({
                        ...fieldsToChange,
                        vlr_min: e.value,
                      });
                    }}
                  />
                  <span className="mr-2">Valor Mínimo</span>
                </div>
              </div>
              <div className="field col-4 md:col py-0">
                <div className="flex justify-content-between py-2">
                  <InputSwitch
                    name="vlr_max"
                    trueValue={true}
                    falseValue={false}
                    checked={fieldsToChange.vlr_max}
                    onChange={e => {
                      setFieldsToChange({
                        ...fieldsToChange,
                        vlr_max: e.value,
                      });
                    }}
                  />
                  <span className="mr-2">Valor Máximo</span>
                </div>
              </div>
            </div>

            {editType === 'P' && (
              <>
                <div className="field col-12">
                  <label htmlFor="product_id">Produto</label>
                  <InputAutoComplete
                    name="product_id"
                    value={product}
                    completeMethod={e => searchProduct(e)}
                    suggestions={productOpts}
                    field="label"
                    onSelect={e => handleProductSelected(e.value)}
                    onChange={e => setProduct(e.value)}
                    itemTemplate={renderItemTemplate}
                    placeholder="Buscar Produto..."
                    autoFocus={true}
                    disabled={tablePrices.length === 0}
                  />
                </div>

                {products.length > 0 && (
                  <DataTable
                    value={products}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 20, 30]}
                    size="small"
                    emptyMessage="Nenhum produto informado!"
                    className="field col-12"
                  >
                    <Column header="#" body={renderColumnPosition}></Column>
                    <Column field="label" header="Descrição"></Column>
                    <Column
                      header="*"
                      body={row => renderButtonRemoveProduct(row.value)}
                    ></Column>
                  </DataTable>
                )}
              </>
            )}

            <div className="field col-12">
              <SelectButton
                value={valueType}
                onChange={e => setValueType(e.value)}
                options={valueTypeOpts}
              />
            </div>

            <div className="field col-6"></div>
            <div className="field col-6">
              <label htmlFor="adjust_value">
                {valueType === 'P' ? 'Porcentagem (%)' : 'Valor (R$)'}
              </label>
              <InputNumber
                name="adjust_value"
                value={value}
                onChange={e => setValue(Number(e.value))}
                placeholder={valueType === 'P' ? '0,00 %' : 'R$ 0,00'}
                minFractionDigits={2}
                maxFractionDigits={2}
                onFocus={e => e.target.setSelectionRange(0, 1)}
              />
            </div>
          </div>

          <Divider />

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={loadingStatus ? 'Aguarde...' : 'Reajustar'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ProductPricesAdjust;
