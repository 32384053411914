import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** types */
import { Option } from '../../../types/optional';

/** api */
import api from '../../../services/api';

/** utils */
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';

/** components */
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';

import { Toast } from 'primereact/toast';
import useSearchCustomer from '../../../hooks/useSearchCustomer';
import useToastContext from '../../../hooks/toast';

const ReportOrderNotRegistred = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toastRef = useRef<any>(null);

  const toast = useToastContext();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [commisioneds, setCommisioneds] = useState<Option[]>([]);
  const [selectedCommisioned, setSelectedComissioned] = useState();

  /** customer */
  const [customer, setSearchCustomer] = useState<any>();
  const [customers, setCustomers] = useState<any[]>([]);

  /** operation nature */
  const [operationNature, setOperationNature] = useState<Array<Option>>([]);
  const [selectedOperationNature, setSelectedOperationNature] = useState();

  const date = new Date();

  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfDay(new Date(date.getFullYear(), date.getMonth(), 1)),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toastRef?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const handleReport = async (data: any) => {
    try {
      const { data: response } = await api.get(
        '/reports/orders/no-registred/list',
        {
          params: {
            dateStart: `${startOfDay(initialDate as Date)}`,
            dateEnd: `${endOfDay(finalDate as Date)}`,
            commissionedId: data.commissionedId,
            customerId: customer?.value,
            natOperationId: data.natOperationId,
          },
        },
      );
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
      setSelectedComissioned(undefined);
      setSelectedOperationNature(undefined);
      form.current?.reset();
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    }
  };

  useEffect(() => {
    api.get(`/commissioneds`).then(({ data }) => {
      const optComissioneds = data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCommisioneds(optComissioneds);
    });
    api
      .post(`/naturezas-operacao/list/short`, {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setOperationNature(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      });
  }, []);

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toastRef} />
        <Form
          ref={form}
          onSubmit={handleReport}
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Comissionado</label>
              <InputDropDown
                name="commissionedId"
                value={selectedCommisioned}
                options={commisioneds}
                placeholder="Selecionar..."
                onChange={e => setSelectedComissioned(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Cliente</label>
              <InputAutoComplete
                name="customerId"
                value={customer}
                completeMethod={e => searchCustomer(e.query)}
                suggestions={customers}
                field="label"
                onChange={e => setSearchCustomer(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar cliente..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Natureza de Operação</label>
              <InputDropDown
                name="natOperationId"
                options={operationNature}
                value={selectedOperationNature}
                placeholder="Selecionar..."
                onChange={e => setSelectedOperationNature(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Inicial</label>
              <Calendar
                name="initial_date"
                value={initialDate}
                showIcon
                onChange={e => setInitialDate(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="">Data Final</label>
              <Calendar
                name="final_date"
                value={finalDate}
                showIcon
                onChange={e => setFinalDate(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mt-3 ">
            <div className="field col-12 md:col">
              <Button label="Gerar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportOrderNotRegistred;
