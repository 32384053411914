import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { OrderList } from 'primereact/orderlist';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputText } from '../../../components/Inputs/InputText';
import { Dialog } from '../../../components/Modal';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import useLoadUsers from '../../../hooks/useLoadUsers';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import TopMenu from '../../user/top-menu';
import { ISystemForm } from '../dtos/ISystemFormsDTO';
import { IUserDashboard } from '../dtos/IUserDashboardDTO';
import { IUserSystemAccess } from '../dtos/IUserSystemAccessDTO';

const AccessUserCreate = (): React.ReactElement => {
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const formClone = useRef<FormHandles>(null);
  const router = useHistory();

  const [isLoad, setIsLoad] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const params: { id: string } = useParams();

  const { data: users } = useLoadUsers({
    paginate: { page: 1, perPage: 100, status: ['A', 'C'] },
  });
  const [user, setUser] = useState<string>('');
  const [cloneUserAccess, setCloneUserAccess] = useState<string[]>([]);

  const [access, setAccess] = useState<ISystemForm[]>([]);
  const [modules, setModules] = useState<IOptionsDTO[]>([]);

  const [forms, setForms] = useState<ISystemForm[]>([]);
  const [filteredForms, setFilteredForms] = useState<ISystemForm[]>([]);

  const [userAccess, setUserAccess] = useState<ISystemForm[]>([]);
  const [filteredUserAccess, setFilteredUserAccess] = useState<ISystemForm[]>(
    [],
  );

  const [userDashboard, setUserDashboard] = useState<IUserDashboard[]>([]);

  const [srcForm, setSrcForm] = useState('');
  const [srcAccess, setSrcAccess] = useState('');

  const clearSearch = () => {
    setSrcForm('');
    setSrcAccess('');
  };

  const clearForm = () => {
    clearSearch();
    setUser('');
    setForms([]);
    setUserAccess([]);
  };

  const add = (id: string) => {
    const hasAccess = userAccess.find(i => i.form_id === id);
    if (!hasAccess) {
      const item = access.find(i => i.form_id === id);
      if (item) {
        setUserAccess([...userAccess, item]);
        setForms(forms.filter(i => i.form_id !== id));
      }
      clearSearch();
    }
  };

  const addAll = () => {
    setUserAccess([...userAccess, ...forms]);
    setForms([]);
    clearSearch();
  };

  const rem = (id: string) => {
    const filterAccess = userAccess.filter(i => i.form_id !== id);
    const _access = access.find(i => i.form_id === id);
    if (_access) {
      setForms([...forms, _access]);
    }
    setUserAccess(filterAccess);
    clearSearch();
  };

  const remAll = () => {
    setUserAccess([]);
    setForms([]);
  };

  const searchForm = (src: string) => {
    if (src.length >= 1) {
      setFilteredForms(
        forms.filter(i =>
          i.form_label.toLocaleLowerCase().includes(src.toLocaleLowerCase()),
        ),
      );
    }
  };

  const searchAccess = (src: string) => {
    if (src.length >= 1) {
      setFilteredUserAccess(
        userAccess.filter(i =>
          i.form_label.toLocaleLowerCase().includes(src.toLocaleLowerCase()),
        ),
      );
    }
  };

  const handleChangeModule = (e: any) => {
    if (!validate(user)) {
      toast('warn', 'Alerta', 'Selecione um usuário primeiro!');
      return;
    }

    if (validate(e)) {
      const noAccess = access.filter(
        i => !userAccess.some(u => u.form_id === i.form_id),
      );
      setForms(noAccess.filter(i => i.module_id === e));
      clearSearch();
    }
  };

  const loadSystemForms = async () => {
    setIsLoad(true);
    await api
      .get(`/system-forms`)
      .then(({ data }) => {
        if (data) {
          setModules(
            data.reduce((a: IOptionsDTO[], i: ISystemForm) => {
              const hasModule = a.find(m => m.value === i.module_id);
              if (!hasModule) {
                a.push({
                  value: i.module_id,
                  label: i.module_title,
                });
              }
              return a;
            }, [] as IOptionsDTO[]),
          );

          setAccess(data);
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadDashboardUser = async (id?: string) => {
    setIsLoad(true);
    await api
      .get(`/users/dashboard/u/${user || id}`)
      .then(({ data }) => {
        if (data) {
          setUserDashboard(data);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleCloneAccess = async (d: any) => {
    try {
      setIsLoad(true);
      formClone.current?.setErrors({});

      d.user_master = cloneUserAccess[0];
      d.user_slave = cloneUserAccess[1];
      const schema = Yup.object({
        user_master: Yup.string().required(
          'Por favor, informe o usuário que terá acesso copiados!',
        ),
        user_slave: Yup.string().required(
          'Por favor, informe o usuário que receberá o acesso copiado!',
        ),
      });

      await schema.validate(d, { abortEarly: false });

      await api
        .post('/user-system-access/clone', {
          data: { master: d.user_master, slave: d.user_slave },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso!', 'Acesso de usuário copiado!');
            router.push('/user-access/set/list');
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formClone.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleChangePanelVisible = async (
    e: InputSwitchChangeEvent,
    id: string,
  ) => {
    if (e.value === true) {
      await enablePanel(id);
    }
    if (e.value === false) {
      await disablePanel(id);
    }
    await loadDashboardUser();
  };

  const enablePanel = async (id: string) => {
    setIsLoad(true);
    await api
      .put(`/users/dashboard/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Painel habilitado com sucesso!');
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const disablePanel = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/users/dashboard/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Painel desabilitado com sucesso!');
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadUserAccess = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/user-system-access/${id}`)
      .then(({ data }) => {
        if (data) {
          setUserAccess(
            data.map((i: IUserSystemAccess) => {
              return {
                form_id: i.form_id,
                form_cod: i.form?.form_cod,
                module_id: i.form?.module_id,
                module_position: i.form?.module.position,
                module_title: i.form?.module.title,
                form_label: i.form?.label,
                form_url: i.form?.url,
                form_qnt_cliques: 0,
              };
            }),
          );
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      d.user_id = user;
      d.access = userAccess.length;

      const schema = Yup.object({
        user_id: Yup.string()
          .uuid('Informe o usuário')
          .required('Informe o usuário'),
        access: Yup.number().min(1, 'Informe pelo menos 1 acesso!'),
      });

      await schema.validate(d, { abortEarly: false });

      await api
        .put('/user-system-access', {
          data: { forms: userAccess.map(i => i.form_id), user_id: params.id },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Acessos criados com sucesso!');
            router.push('/user-access/set/list');
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplateModule = (item: IOptionsDTO) => {
    return (
      <div
        className="flex flex-wrap p-2 py-0 align-items-center gap-3"
        onClick={() => handleChangeModule(item.value)}
      >
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">{item.label}</span>
        </div>
        <Button
          key={item.value}
          icon={`fa-solid fa-arrow-right`}
          onClick={() => handleChangeModule(item.value)}
        />
      </div>
    );
  };

  const itemTemplateForm = (item: IOptionsDTO) => {
    return (
      <div
        className="flex flex-wrap p-2 py-0 align-items-center gap-3"
        onClick={() => add(item.value)}
      >
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">{item.label}</span>
        </div>
        <Button
          key={item.value}
          icon={`fa-solid fa-plus`}
          onClick={() => add(item.value)}
        />
      </div>
    );
  };

  const itemTemplateAccess = (item: ISystemForm) => {
    return (
      <div
        className="flex flex-wrap p-2 py-0 align-items-center gap-3"
        onClick={() => rem(item.form_id)}
      >
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <div className="flex align-items-center gap-2">
            <i className="fa-solid fa-gear text-sm"></i>
            <span className="font-bold">{`${item.form_label}`}</span>
            <span className="text-sm">{`(${item.module_title})`}</span>
          </div>
        </div>
        <Button
          key={item.form_id}
          icon={`fa-solid fa-xmark`}
          className="p-button-danger"
          onClick={() => rem(item.form_id)}
        />
      </div>
    );
  };

  const renderFooterAccess = () => {
    return (
      <div>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => setDialogVisible(false)}
          className="p-button-danger p-button-text"
        />
        <Button
          label="Salvar"
          icon="pi pi-check"
          type="submit"
          loading={isLoad}
          onClick={() => formClone.current?.submitForm()}
          autoFocus
        />
      </div>
    );
  };

  const itemTemplateDashboard = (item: IUserDashboard) => {
    return (
      <div className="flex mb-2" key={item.id}>
        <InputSwitch
          name="field"
          checked={item.visible}
          onChange={e => handleChangePanelVisible(e, item.id)}
        />
        <label htmlFor="dropdown" className="ml-3">
          {item.dashboard_item.title}
        </label>
      </div>
    );
  };

  useEffect(() => {
    loadSystemForms();

    if (validate(params.id)) {
      setUser(params.id);
      Promise.all([loadDashboardUser(params.id), loadUserAccess(params.id)]);
    }
  }, []);

  useEffect(() => {
    if (srcForm.length >= 1) {
      searchForm(srcForm);
    }

    if (srcAccess.length >= 1) {
      searchAccess(srcAccess);
    }
  }, [srcForm, srcAccess]);

  return (
    <>
      <TopMenu />
      <Progress isLoad={isLoad} />
      <Form ref={formRef} onSubmit={d => handleSubmit(d)} className="card">
        <div className="p-fluid grid formgroup">
          <div className="field col-6">
            <label htmlFor="user_id">Usuário</label>
            <InputDropDown
              name="user_id"
              value={user}
              options={users
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(i => {
                  return {
                    value: i.id,
                    label: i.name,
                  };
                })}
              readOnly
              disabled
            />
          </div>

          <div className="field col-3 pr-0 flex align-items-end justify-content-center">
            <Button
              type="button"
              label="Clonar acessos"
              loading={isLoad}
              className="p-button-info"
              icon="fa-solid fa-clone"
              onClick={() => setDialogVisible(true)}
            />
          </div>

          <TabView className="w-full">
            <TabPanel header="Acessos" className="flex">
              {/** modules */}
              <div className="col-4">
                <OrderList
                  dataKey="value"
                  value={modules.sort((a, b) => a.label.localeCompare(b.label))}
                  onChange={e => setModules(e.value)}
                  itemTemplate={itemTemplateModule}
                  header="Módulos"
                ></OrderList>
              </div>

              {/** forms */}
              <div className="col-4">
                <div className="field">
                  <InputText
                    name="forms_filter"
                    placeholder="Buscar..."
                    value={srcAccess}
                    onChange={e => setSrcForm(e.currentTarget.value)}
                    disabled={forms.length == 0 ? true : false}
                  />
                </div>
                <OrderList
                  dataKey="value"
                  value={
                    srcForm.length === 0
                      ? forms
                          .sort((a, b) =>
                            a.form_label.localeCompare(b.form_label),
                          )
                          .map(i => {
                            return { value: i.form_id, label: i.form_label };
                          })
                      : filteredForms
                          .sort((a, b) =>
                            a.form_label.localeCompare(b.form_label),
                          )
                          .map(i => {
                            return { value: i.form_id, label: i.form_label };
                          })
                  }
                  itemTemplate={itemTemplateForm}
                  header={
                    <div className="flex flex-wrap p-2 py-0 align-items-center gap-3">
                      <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                        Formulários
                      </div>
                      <Button
                        icon={`fa-solid fa-xmark`}
                        label="Add. todos"
                        className="p-button-success flex-1"
                        size="small"
                        onClick={() => addAll()}
                        disabled={forms.length === 0 ? true : false}
                      />
                    </div>
                  }
                ></OrderList>
              </div>

              {/** access */}
              <div className="col-4">
                <div className="field">
                  <InputText
                    name="access_filter"
                    placeholder="Buscar..."
                    value={srcAccess}
                    onChange={e => setSrcAccess(e.currentTarget.value)}
                    disabled={userAccess.length === 0 ? true : false}
                  />
                </div>
                <OrderList
                  dataKey="value"
                  value={
                    srcAccess.length === 0
                      ? userAccess.sort((a, b) =>
                          a.form_label.localeCompare(b.form_label),
                        )
                      : filteredUserAccess.sort((a, b) =>
                          a.form_label.localeCompare(b.form_label),
                        )
                  }
                  itemTemplate={itemTemplateAccess}
                  header={
                    <div className="flex flex-wrap p-2 py-0 align-items-center gap-3">
                      <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                        Acessos
                      </div>
                      <Button
                        icon={`fa-solid fa-xmark`}
                        label="Rem. todos"
                        className="p-button-danger flex-1"
                        size="small"
                        onClick={() => remAll()}
                        disabled={userAccess.length === 0 ? true : false}
                      />
                    </div>
                  }
                ></OrderList>
              </div>
            </TabPanel>
            <TabPanel header="Dashboard">
              {userDashboard && userDashboard.length > 0
                ? userDashboard
                    .sort((a, b) =>
                      a.dashboard_item.title.localeCompare(
                        b.dashboard_item.title,
                      ),
                    )
                    .map(i => itemTemplateDashboard(i))
                : ''}
            </TabPanel>
          </TabView>

          <Divider />

          <div className="field col-6">
            <Button
              label="Limpar"
              type="reset"
              onClick={() => clearForm()}
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>

      <Dialog
        header="Clonar acesso"
        visible={dialogVisible}
        style={{ width: '50vw' }}
        footer={renderFooterAccess}
        onHide={() => setDialogVisible(false)}
      >
        <Form ref={formClone} onSubmit={d => handleCloneAccess(d)}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Copiar acesso de: </label>
              <InputDropDown
                name="user_master"
                value={cloneUserAccess[0]}
                onChange={e =>
                  setCloneUserAccess([e.value, cloneUserAccess[1]])
                }
                options={users
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(i => {
                    return {
                      value: i.id,
                      label: i.name,
                    };
                  })}
                placeholder="Selecionar..."
              />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="basic">Aplicar acesso para:</label>
              <InputDropDown
                name="user_slave"
                value={cloneUserAccess[1]}
                onChange={e =>
                  setCloneUserAccess([cloneUserAccess[0], e.value])
                }
                options={users
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(i => {
                    return {
                      value: i.id,
                      label: i.name,
                    };
                  })}
                placeholder="Selecionar..."
              />
            </div>
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default AccessUserCreate;
