import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addDays } from 'date-fns';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import PaymentConditionsHeaderButtons from '../top-menu';

const PaymentConditionCreate = () => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  /** useState */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const yesNoOpts = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
  ];

  const [title, setTitle] = useState<string>();
  const [paymentTypeOpts] = useState<Array<IOptionsDTO>>([
    { label: 'Cartão Crédito', value: 'CCR' },
    { label: 'Cartão Débito', value: 'CDE' },
    { label: 'Pix', value: 'PIX' },
    { label: 'Dinheiro', value: 'DNH' },
    { label: 'Boleto', value: 'BLT' },
    { label: 'Cheque', value: 'CHQ' },
    { label: 'Duplicata', value: 'DPL' },
  ]);
  const [paymentType, setPaymentType] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const typeJurosOpts: Array<IOptionsDTO> = [
    {
      label: 'Dia (1 dia)',
      value: 'dia',
    },
    {
      label: 'Semana (7 dias)',
      value: 'sem',
    },
    {
      label: 'Mês (30 dias)',
      value: 'mes',
    },
    {
      label: 'Ano (365 dias)',
      value: 'ano',
    },
  ];
  const [typeJuros, setTypeJuros] = useState<string>('mes');
  const [isPreDatado, setIsPredatado] = useState<boolean>(false);
  const [isCard, setIsCard] = useState<boolean>(false);
  const [autoDrop, setAutoDrop] = useState<boolean>(true);
  const [typeCrediaryOpts, setTypeCrediaryOpts] = useState<Array<IOptionsDTO>>(
    [],
  );
  const [typeCrediary, setTypeCrediary] = useState<string>('');
  const [minValue, setMinValue] = useState<number>(1);
  const [maxValue, setMaxValue] = useState<number>(100000);
  const [qntParcels, setQntParcels] = useState<number>(1);
  const [variationDays, setVariationsDays] = useState<number>(30);
  const [taxJurosMin, setTaxJurosMin] = useState<number>(0);
  const [taxJurosBase, setTaxJurosBase] = useState<number>(0);
  const [multa, setMulta] = useState<number>(0);
  const [activeOn, setActiveOn] = useState<Date | Date[]>(hoje);
  const [unActiveOn, setUnActiveOn] = useState<Date | Date[]>(
    addDays(hoje, 365),
  );

  const [showStore, setShowStore] = useState<boolean>(false);

  /** functions */
  const handleSubmit = async (d: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      d.min_value = minValue;
      d.max_value = maxValue;
      d.tax_juros_min = taxJurosMin;

      if (paymentType === 'CDE' || paymentType === 'CCR') setIsCard(true);

      if (paymentType === 'DNH') {
        const schemaValidation = Yup.object({
          title: Yup.string().required('Informe um título!'),
          payment_type: Yup.string().required('Informe o tipo de pagamento!'),
          obs: Yup.string(),
          min_value: Yup.number()
            .positive('Informe um número positivo em valor mínimo!')
            .required('Informe o valor mínimo!'),
          max_value: Yup.number()
            .positive('Informe um número positivo em valor máximo!')
            .required('Informe o valor máximo!'),
        });
        await schemaValidation.validate(d, { abortEarly: false });
      } else if (paymentType === 'PIX') {
        const schemaValidation = Yup.object({
          title: Yup.string().required('Informe um título!'),
          payment_type: Yup.string().required('Informe o tipo de pagamento!'),
          obs: Yup.string(),
          min_value: Yup.number()
            .positive('Informe um número positivo em valor mínimo!')
            .required('Informe o valor mínimo!'),
          max_value: Yup.number()
            .positive('Informe um número positivo em valor máximo!')
            .required('Informe o valor máximo!'),
          auto_drop: Yup.boolean(),
          pre_datado: Yup.boolean(),
        });
        await schemaValidation.validate(d, { abortEarly: false });
      } else {
        const schemaValidation = Yup.object({
          title: Yup.string().required('Informe um título!'),
          payment_type: Yup.string().required('Informe o tipo de pagamento!'),
          obs: Yup.string(),
          type_juros: Yup.string().required('Informe o tipo de juros!'),
          is_predatatado: Yup.boolean(),
          is_card: Yup.boolean(),
          auto_drop: Yup.boolean(),
          type_crediary: Yup.string().required('Informe o crediário!'),
          min_value: Yup.number()
            .positive('Informe um número positivo em valor mínimo!')
            .required('Informe o valor mínimo!'),
          max_value: Yup.number()
            .positive('Informe um número positivo em valor máximo!')
            .required('Informe o valor máximo!'),
          qnt_parcels: Yup.number()
            .positive(
              'Informe um número positivo para a quantidade de parcelas!',
            )
            .required('Informe a quantidade de parcelas!'),
          variation_days: Yup.number()
            .min(0, 'Informe um número positivo para a variação de dias!')
            .required('Informe a variação de dias!'),
          tax_juros_min: Yup.number().required(
            'Informe a taxa de juros mínima!',
          ),
        });
        await schemaValidation.validate(d, { abortEarly: false });
      }

      const res = await api.post('/payments-condition', {
        data: {
          title,
          description: obs,
          sigla: paymentType,
          tipo_juros: typeJuros,
          pre_datado: isPreDatado.toString(),
          cartao: isCard.toString(),
          crediary_id: typeCrediary,
          cobranca_min: minValue,
          cobranca_max: maxValue,
          qnt_parcelas: qntParcels,
          variacao_dias: variationDays,
          taxa_juros_base: taxJurosBase,
          taxa_juros_min: taxJurosMin,
          date_on: activeOn,
          date_off: unActiveOn,
          show_store: showStore as boolean,
          auto_drop: autoDrop,
        },
      });

      if (res.status >= 200 || res.status <= 299) {
        toast(
          'success',
          'Sucesso',
          'Condição de pagamento cadastrada com sucesso!',
        );
        navigate.push('/payment-condition/list');
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listCrediaries = async () => {
    setLoadingStatus(true);
    api
      .get('/crediaries')
      .then(({ data }) => {
        const opts = data.map((i: any) => {
          return {
            label: i.title,
            value: i.id,
          };
        });
        setTypeCrediaryOpts(opts);
        setTypeCrediary(opts[0].value);
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar os crediários da empresa!');
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffect */
  useEffect(() => {
    listCrediaries();
  }, []);

  useEffect(() => {
    if (paymentType === 'DNH' || paymentType === 'PIX') {
      setVariationsDays(0);
      setIsPredatado(false);
      setAutoDrop(true);
    }
  }, [paymentType]);

  return (
    <>
      <PaymentConditionsHeaderButtons />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card w-10"
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-8">
            <label htmlFor="title">Título</label>
            <InputText
              name="title"
              placeholder="Ex.: Duplicata 30dd"
              value={title}
              onChange={e => setTitle(e.currentTarget.value)}
            />
          </div>

          <div className="field col-4">
            <label htmlFor="payment_type">Tipo de pagamento</label>
            <InputDropDown
              name="payment_type"
              options={paymentTypeOpts.sort((a, b) =>
                a.label.localeCompare(b.label),
              )}
              value={paymentType}
              onChange={d => setPaymentType(d.value)}
              placeholder="Selecionar..."
              emptyFilterMessage="Nenhum item encontrado.."
              filter
              showClear
              tooltip="Caso seja informado o tipo de pagamento como dinheiro, esse valor irá cair no saldo do caixa!"
            />
          </div>

          <div className="field col-12">
            <label htmlFor="obs">Obs</label>
            <InputTextEdit
              name="obs"
              style={{ height: 150 }}
              value={obs}
              onTextChange={e => setObs(e.textValue)}
              placeholder="Ex.: Condição de pagamento especial para o João da Silva"
            />
          </div>

          <div className="field col-3">
            <label htmlFor="min_value">Cobrança mínima</label>
            <InputNumber
              name="min_value"
              value={minValue}
              onChange={e => setMinValue(e.value as number)}
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
            />
          </div>

          <div className="field col-3">
            <label htmlFor="max_value">Cobrança máxima</label>
            <InputNumber
              name="max_value"
              value={maxValue}
              onChange={e => setMaxValue(e.value as number)}
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
            />
          </div>

          {paymentType !== 'DNH' && (
            <>
              <div className="field col-3">
                <label>Baixa automática?</label>
                <SelectButton
                  value={autoDrop}
                  onChange={e => setAutoDrop(e.value)}
                  options={yesNoOpts}
                />
              </div>
              <div className="field col-3">
                <label>Pré-datado?</label>
                <SelectButton
                  value={isPreDatado}
                  onChange={e => setIsPredatado(e.value)}
                  options={yesNoOpts}
                />
              </div>
              <div className="field col-3">
                <label htmlFor="type_juros">Tipo de juros</label>
                <InputDropDown
                  name="type_juros"
                  value={typeJuros}
                  onChange={e => setTypeJuros(e.value)}
                  options={typeJurosOpts}
                  placeholder="Selecionar..."
                />
              </div>

              <div className="field col-3">
                <label htmlFor="tax_juros_base">Juros base</label>
                <InputNumber
                  name="tax_juros_base"
                  value={taxJurosBase}
                  onChange={e => setTaxJurosBase(e.value as number)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                />
              </div>

              <div className="field col-3">
                <label htmlFor="tax_juros_min">Juros mínimo</label>
                <InputNumber
                  name="tax_juros_min"
                  value={taxJurosMin}
                  onChange={e => setTaxJurosMin(e.value as number)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                />
              </div>

              <div className="field col-3">
                <label htmlFor="multa">Multa</label>
                <InputNumber
                  name="multa"
                  value={multa}
                  onChange={e => setMulta(e.value as number)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  suffix="%"
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="qnt_parcels">Qnt. parcelas</label>
                <InputNumber
                  name="qnt_parcels"
                  value={qntParcels}
                  onValueChange={e => setQntParcels(e.value as number)}
                  placeholder="0"
                  showButtons
                  buttonLayout="horizontal"
                  min={0}
                  max={100}
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="variation_days">Variação de dias</label>
                <InputNumber
                  name="variation_days"
                  value={variationDays}
                  onValueChange={e => setVariationsDays(e.value as number)}
                  placeholder="0"
                  showButtons
                  buttonLayout="horizontal"
                  min={0}
                  max={100}
                />
              </div>

              <div className="field col-3">
                <label htmlFor="type_crediary">Tipo de crediário</label>
                <InputDropDown
                  name="type_crediary"
                  value={typeCrediary}
                  onChange={e => setTypeCrediary(e.value)}
                  options={typeCrediaryOpts}
                  placeholder="Selecionar..."
                />
              </div>
            </>
          )}

          <Divider />

          <div className="field col-3">
            <label htmlFor="active_on">Ativa dia</label>
            <Calendar
              name="active_on"
              value={activeOn}
              dateFormat="dd/mm/yy"
              onChange={e => setActiveOn(e.value as Date)}
              showIcon
            />
          </div>

          <div className="field col-3">
            <label htmlFor="un_active_on">Desativa dia</label>
            <Calendar
              name="un_active_on"
              value={unActiveOn}
              dateFormat="dd/mm/yy"
              onChange={e => setUnActiveOn(e.value as Date)}
              showIcon
            />
          </div>

          <div className="field md:col-3 flex align-items-center justify-content-center">
            <InputSwitch
              name="show_store"
              checked={showStore}
              onChange={e => setShowStore(e.value)}
              className="mr-3"
            />
            <label htmlFor="show_store" className="m-0">
              Usar na loja virtual?
            </label>
          </div>
        </div>
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default PaymentConditionCreate;
