import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectParams } from 'primereact/datatable';
import { DropdownChangeParams } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import useToastContext from '../../../hooks/toast';
import { useOrderService } from '../../../hooks/useOrderService';
import { IProductRowOs } from '../../../pages/consigned/order/dtos/IConOrderDTO';
import { emptyProductRowOs } from '../../../pages/consigned/order/types/emptyProductRowOs';
import { emptyProductPriceSearch } from '../../../pages/order/types/EmptyProductPriceSearch';
import {
  IPrice,
  IProductPriceSearch,
} from '../../../pages/product/dtos/IProductDTO';
import api from '../../../services/api';
import formatCurrency from '../../../utils/FormatCurrency';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { DataTable } from '../../DataTable';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import { Divider } from '../../Divider';
import { InputAutoComplete } from '../../Inputs/InputAutoComplete';
import { InputDropDown } from '../../Inputs/InputDropDown';
import { InputNumber } from '../../Inputs/InputNumber';
import { InputTextArea } from '../../Inputs/InputTextArea';

interface IProps {
  show: {
    title: boolean;
    obs: boolean;
  };
  setup: {
    isKeyboard: boolean;
  };
}

const InputRowProductOS: React.FC<IProps> = ({ show, setup }) => {
  const formRef = useRef<FormHandles>(null);
  const productRefKeyword = useRef(null);
  const productRefSku = useRef(null);
  const qntRef = useRef(null);
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [productFocusKeyword, setProductFocusKeyword] =
    useState<boolean>(false);
  const [productFocusSku, setProductFocusSku] = useState<boolean>(false);
  const [isKeyBoard, setIsKeyBoard] = useState<boolean>(setup.isKeyboard);
  const [qntFocus, setQntFocus] = useState(false);

  const [products, setProducts] = useState<IProductPriceSearch[]>([]);
  const [product, setProduct] = useState<IProductPriceSearch>(
    emptyProductPriceSearch,
  );
  const [item, setItem] = useState<Partial<IProductRowOs>>(emptyProductRowOs);

  const { orderService, addProduct, remProduct } = useOrderService();

  const [keyword, setKeyword] = useState<string | number>('');

  const searchProductByKeyword = async (
    e: AutoCompleteCompleteMethodParams,
  ) => {
    if (e.query.length >= 3) {
      api
        .post(`/products/v2/prices/search`, {
          data: {
            keyword: e.query,
            show: { page: 1, perPage: 1000, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProducts(
              data.map((i: IProductPriceSearch) => {
                return {
                  ...i,
                  value: i.id,
                  label: i.title,
                };
              }),
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const searchProductBySku = async (e: any) => {
    if (keyword.toString().length < 3) {
      toast(
        'warn',
        'Alerta',
        'SKU do produto deve ter no mínimo 3 caracteres!',
      );
      return;
    }

    setLoad(true);

    await api
      .get(`products/v2/prices/sku/${keyword}`)
      .then(({ data }) => {
        if (data) {
          setProduct({ ...data });

          setItem({
            ...item,
            id: v4(),
            sku: data.sku,
            product_id: data.id,
            value: data.id,
            product_description: data.title,
            label: data.title,
            table_price_id: data.prices[0].tablePrice.id,
            table_price_description: data.prices[0].tablePrice.title,
            stock: Number(data.stock),
            qnt: 1,
            vlr_unit: Number(data.prices[0].vlr_venda),
            qnt_out: data.decimalHouses[0].qnt_out,
            price_out: data.decimalHouses[0].price_out,
            total: Number(data.prices[0].vlr_venda),
          });
        }

        setQntFocus(true);
        qntRef?.current?.focus();
      })
      .catch(err => {
        toast(
          'warn',
          'Ops...',
          'Nenhum produto foi encontrado com os parâmetros informados!' +
            messageRequestError(err),
        );
      })
      .finally(() => setLoad(false));
  };

  const handleSelectProduct = (e: AutoCompleteSelectParams) => {
    const p: IProductPriceSearch = e.value;

    if (!validate(p.id)) {
      toast('warn', 'Alerta', 'Falha ao selecionar o produto!');
      return;
    }

    if (p.prices && p.prices.length === 0) {
      toast(
        'warn',
        'Alerta',
        `O produto.: ${p.title} não tem nenhum preço cadastrado em nosso sistema!`,
      );
    }

    setItem(emptyProductRowOs);

    setProduct({ ...p });

    const _lucre = Number(p.prices[0].vlr_venda) - Number(product.cost_billing);
    const _lucreAliq = (_lucre * 100) / Number(p.prices[0].vlr_venda);

    setItem({
      ...item,
      id: v4(),
      product_id: p.id,
      value: p.id,
      product_description: p.title,
      label: p.title,
      table_price_id: p.prices[0].table_price_id,
      table_price_description: p.prices[0].tablePrice.title,
      stock: Number(p.stock),
      lucre: _lucre,
      lucre_aliq: _lucreAliq,
      vlr_unit: Number(p.prices[0].vlr_venda),
      qnt_out: p.decimalHouses[0].qnt_out,
      price_out: p.decimalHouses[0].price_out,
      total: Number(p.prices[0].vlr_venda),
    });
  };

  const clearRow = () => {
    setItem(emptyProductRowOs);
    setProduct(emptyProductPriceSearch);
    setKeyword('');
    if (isKeyBoard) {
      setProductFocusSku(true);
      productRefSku.current?.focus();
    } else {
      setProductFocusKeyword(true);
      productRefKeyword.current?.focus();
    }
  };

  const handleAddProduct = async (d: any) => {
    try {
      setLoad(true);
      formRef.current?.setErrors({});

      d.product_keyword = item.product_id;
      d.table_price_id = item.table_price_id;
      d.qnt = item.qnt;
      d.vlr_unit = item.vlr_unit;
      d.adic = item.adic;
      d.desc = item.desc;
      d.obs = item.obs;

      const schema = Yup.object({
        product_keyword: Yup.string()
          .uuid('Informe um produto para adicionar à venda!')
          .required('Informe um produto para adicionar à venda!'),
        table_price_id: Yup.string()
          .uuid('Informe uma tabela de preços para o produto!')
          .required('Informe uma tabela de preços para o produto!'),
        qnt: Yup.number()
          .positive('Informe uma quantidade!')
          .required('Informe uma quantidade!'),
      });

      await schema.validate(d, { abortEarly: false });

      setItem({ ...item, id: v4() });
      addProduct(item);

      clearRow();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleRemProduct = (id: string) => {
    remProduct(id);
  };

  const handleSelectTablePrice = (e: DropdownChangeParams) => {
    const tp = product.prices.find(i => i.table_price_id === e.value);
    if (tp) {
      const _lucre = Number(tp.vlr_venda) - Number(product.cost_billing);
      const _lucreAliq = (_lucre * 100) / Number(tp.vlr_venda);
      setItem({
        ...item,
        table_price_id: tp.table_price_id,
        table_price_description: tp.tablePrice.title,
        lucre: _lucre,
        lucre_aliq: _lucreAliq,
        vlr_unit: Number(tp.vlr_venda),
      });
    }
  };

  const handleRowSelect = async (e: DataTableSelectParams) => {
    const i = orderService.osProducts.find(i => i.id === e.data.id);
    if (i) {
      await api
        .get(`/products/li/${i.product_id}`)
        .then(({ data }) => {
          if (data) {
            setProduct({
              ...product,
              id: i.product_id || '',
              value: i.product_id || '',
              sku: i.sku || '',
              title: i.product_description || '',
              label: i.product_description || '',
              stock: i.stock,
              prices: data.map((i: any) => {
                return {
                  ...i,
                  vlr_venda: Number(i.vlr_venda),
                  tablePrice: {
                    id: i.table_price_id,
                    title: i.table_price_title,
                  },
                };
              }),
            });
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
        });

      setItem({ ...i });

      if (isKeyBoard) {
        setKeyword(i.sku || '');
        setProductFocusSku(true);
        productRefSku.current?.focus();
      } else {
        setProductFocusKeyword(true);
        productRefKeyword.current?.focus();
      }
    }
  };

  const calculateProduct = () => {
    if (item) {
      const _liq =
        Number(item.vlr_unit) + Number(item.adic) - Number(item.desc);
      const _total = _liq * Number(item.qnt);

      const _addAliq = (Number(item.adic) * 100) / Number(item.vlr_unit);
      const _descAliq = (Number(item.desc) * 100) / Number(item.vlr_unit);

      setItem({
        ...item,
        vlr_liq: _liq,
        adic_aliq: _addAliq,
        desc_aliq: _descAliq,
        total: _total,
      });
    }
  };

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemProduct(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  useEffect(() => {
    calculateProduct();
  }, [item.qnt, item.vlr_unit, item.adic, item.desc]);

  return (
    <>
      {show.title && (
        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">PRODUTO</span>
          </div>
        </Divider>
      )}

      <Form
        ref={formRef}
        onSubmit={d => handleAddProduct(d)}
        className="p-fluid grid fromgroup pt-2"
      >
        {isKeyBoard === false && (
          <div className="field col-12 md:col-3 lg:col-3 mb-0">
            <label htmlFor="product_keyword">
              Produto
              <i
                className={`fa-solid fa-keyboard ${
                  isKeyBoard ? 'text-green-500' : 'text-grey-500'
                } ml-2 cursor-pointer`}
                onClick={() => setIsKeyBoard(!isKeyBoard)}
              ></i>
            </label>
            <InputAutoComplete
              inputRef={productRefKeyword}
              name="product_keyword"
              value={product}
              field="title"
              completeMethod={e => searchProductByKeyword(e)}
              suggestions={products}
              onSelect={e => handleSelectProduct(e)}
              onChange={e => setProduct({ ...product, title: e.value })}
              itemTemplate={e => itemTemplateProduct(e)}
              placeholder={`${
                isKeyBoard ? 'Inserir código + Enter' : 'Buscar produto...'
              }`}
              autoFocus={productFocusKeyword}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setQntFocus(true);
                  qntRef?.current?.focus();
                }
              }}
            />
            <Tag
              severity={
                Number(item.qnt) > Number(item.stock)
                  ? 'danger'
                  : item?.qnt === Number(item.stock)
                  ? 'success'
                  : 'info'
              }
              value={`Estoque.: ${formatCurrency(item?.stock || 0)} `}
              className="py-0"
            ></Tag>
          </div>
        )}
        {isKeyBoard === true && (
          <div className="field col-12 md:col-3 lg:col-3 mb-0">
            <label htmlFor="product_input">
              SKU/Código
              <i
                className={`fa-solid fa-keyboard ${
                  isKeyBoard ? 'text-green-500' : 'text-grey-500'
                } ml-2 cursor-pointer`}
                onClick={() => setIsKeyBoard(!isKeyBoard)}
              ></i>
            </label>
            <InputNumber
              inputRef={productRefSku}
              name="product_input"
              value={keyword as number}
              onChange={e => {
                setKeyword(e.value as number | 0);
              }}
              placeholder="Inserir código + Enter"
              autoFocus={productFocusSku}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  searchProductBySku(e.currentTarget.value);
                }
              }}
              aria-describedby="product_input"
            />
            <div className="flex justify-content-between mt-1">
              <Tag
                severity={
                  Number(item.qnt) > Number(item.stock)
                    ? 'danger'
                    : item?.qnt === Number(item.stock)
                    ? 'success'
                    : 'info'
                }
                value={`Estoque.: ${formatCurrency(item?.stock || 0)} `}
                className="py-0"
              ></Tag>
              <small id="product_input" className="ml-2">
                {item.product_description
                  ? item.product_description
                  : 'Digite SKU + Enter'}
              </small>
            </div>
          </div>
        )}
        <div className="field col-12 md:col-3 lg:col-3 mb-0">
          <label htmlFor="table_price_id">Tabela preço</label>
          <InputDropDown
            name="table_price_id"
            options={product.prices.map((i: IPrice) => {
              return {
                value: i.table_price_id,
                label: i.tablePrice.title,
              };
            })}
            value={item.table_price_id}
            onChange={e => handleSelectTablePrice(e)}
            disabled={product.prices.length === 0 ? true : false}
            className={product.prices.length === 0 ? `surface-200` : ``}
            placeholder="Selecionar..."
          />
          <Tag
            severity={
              Number(item?.lucre) < 0
                ? 'danger'
                : Number(item?.lucre) >= 0
                ? 'success'
                : 'info'
            }
            value={`Lcr.: ${formatCurrency(
              item?.lucre || 0,
            )} ~ ${formatCurrency(item?.lucre_aliq || 100)}%`}
            className="py-0"
          ></Tag>
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="qnt">Qnt.</label>
          <InputNumber
            inputRef={qntRef}
            name="qnt"
            value={item.qnt}
            onChange={e => {
              setItem({ ...item, qnt: Number(e.value) });
            }}
            mode="decimal"
            minFractionDigits={item.qnt_out}
            placeholder="0,00"
            maxFractionDigits={item.qnt_out}
            autoFocus={qntFocus}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setQntFocus(false);
                if (isKeyBoard) {
                  setProductFocusKeyword(true);
                  productRefKeyword?.current?.focus();
                } else {
                  setProductFocusSku(true);
                  productRefSku?.current?.focus();
                }

                handleAddProduct(item);
              }
            }}
            disabled={product.prices.length === 0 ? true : false}
            className={product.prices.length === 0 ? `surface-200` : ``}
          />
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="vlr_unit">Vlr.</label>
          <InputNumber
            name="vlr_unit"
            value={item.vlr_unit}
            onChange={e => setItem({ ...item, vlr_unit: e.value as number })}
            mode="decimal"
            placeholder="R$ 0,00"
            minFractionDigits={item.price_out || 2}
            maxFractionDigits={item.price_out || 2}
            disabled={product.prices.length === 0 ? true : false}
            className={product.prices.length === 0 ? `surface-200` : ``}
          />
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="adic">Add.</label>
          <InputNumber
            name="adic"
            value={item.adic}
            onChange={e => setItem({ ...item, adic: e.value as number })}
            mode="decimal"
            placeholder="R$ 0,00"
            minFractionDigits={item.price_out || 2}
            maxFractionDigits={item.price_out || 2}
            disabled={product.prices.length === 0 ? true : false}
            className={product.prices.length === 0 ? `surface-200` : ``}
          />
          <Tag
            severity={'info'}
            value={`${formatDecimal(Number(item.adic_aliq) || 0)}%`}
            className="py-0"
          ></Tag>
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="add">Desc.</label>
          <InputNumber
            name="desc"
            value={item.desc}
            onChange={e => setItem({ ...item, desc: e.value as number })}
            mode="decimal"
            placeholder="R$ 0,00"
            minFractionDigits={item.price_out || 2}
            maxFractionDigits={item.price_out || 2}
            disabled={product.prices.length === 0 ? true : false}
            className={product.prices.length === 0 ? `surface-200` : ``}
          />
          <Tag
            severity={'danger'}
            value={`${formatDecimal(Number(item.desc_aliq) | 0)}%`}
            className="py-0"
          ></Tag>
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="liq">Liq.</label>
          <InputNumber
            name="liq"
            value={item.vlr_liq}
            onChange={e => setItem({ ...item, vlr_liq: e.value as number })}
            mode="decimal"
            placeholder="R$ 0,00"
            disabled
            className="surface-200"
            minFractionDigits={item.price_out || 2}
            maxFractionDigits={item.price_out || 2}
          />
        </div>
        <div className="field col-6 md:col-1 lg:col-1 mb-0">
          <label htmlFor="total">Total</label>
          <InputNumber
            name="total"
            value={item.total}
            onChange={e => setItem({ ...item, total: e.value as number })}
            mode="decimal"
            placeholder="R$ 0,00"
            disabled
            className="surface-200"
            minFractionDigits={item.price_out || 2}
            maxFractionDigits={item.price_out || 2}
          />
        </div>
        {show.obs && (
          <div className="field col-12 md:col-11 mt-1">
            <label htmlFor="obs">Observações produto</label>
            <InputTextArea
              name="obs"
              value={item.obs}
              onChange={e => setItem({ ...item, obs: e.currentTarget.value })}
              placeholder="Ex: Usado para troca da peça XYZ..."
            />
          </div>
        )}
        <div className="field col-12 md:col-1 lg:col-1 pr-0 flex align-items-center justify-content-center">
          <Button
            type="submit"
            loading={load}
            disabled={!validate(item.product_id || '')}
            className="p-button-success w-6"
            icon="fa-solid fa-plus"
            onClick={e => handleAddProduct(item)}
          />
        </div>

        {orderService.osProducts.length > 0 && (
          <>
            <Divider />
            <DataTable
              value={orderService.osProducts}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Produtos não encontrados..."
              selectionMode="single"
              onRowSelect={e => handleRowSelect(e)}
              loading={load}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} produtos de consignados."
              className="field col-12"
            >
              <Column header="#" body={renderColumnPosition}></Column>
              <Column header="Produto" field="product_description"></Column>
              <Column header="Tabela" field="table_price_description"></Column>
              <Column
                header="Qnt."
                body={r => renderColumnDecimal(r.qnt)}
              ></Column>
              <Column
                header="Vlr."
                body={r => renderColumnDecimal(r.vlr_unit)}
              ></Column>
              <Column
                header="Add."
                body={r => renderColumnDecimal(r.adic)}
              ></Column>
              <Column
                header="Desc."
                body={r => renderColumnDecimal(r.desc)}
              ></Column>
              <Column
                header="Liq."
                body={r => renderColumnDecimal(r.vlr_liq)}
              ></Column>
              <Column
                header="Total"
                body={r => renderColumnDecimal(r.total)}
              ></Column>
              <Column
                header="*"
                body={e => renderCollumnMenu(e.id)}
                align={'center'}
              ></Column>
            </DataTable>
          </>
        )}
      </Form>
    </>
  );
};

export default InputRowProductOS;
