import React, { useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** api */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from '../../../components/Inputs/InputNumber';

const ReportCustomerBirthDay = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const toast = useRef<any>(null);

  const [days, setDays] = useState<number | null>(30);

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReportsCustomerActive = async (data: any) => {
    try {
      setLoadingStatus(true);
      const { data: response } = await api.get(`/reports/customers/active`, {
        params: {
          days: data.days.match(/\d+/)[0],
        },
      });
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      showToast('error', 'Error', error.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReportsCustomerActive}>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col">
              <label htmlFor="">Filtra por Dias</label>
              <InputNumber
                showButtons
                buttonLayout="horizontal"
                name="days"
                min={1}
                value={days}
                onChange={e => setDays(e.value)}
              />
            </div>
            <div className="field col-12 md:col">
              <Button
                label="Imprimir"
                icon="fa-solid fa-print"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportCustomerBirthDay;
