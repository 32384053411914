import React from 'react';
import { Link } from 'react-router-dom';

export const Error = (): React.ReactElement => {
  return (
    <div className="exception-body error">
      <img
        src="assets/layout/images/logo-white.svg"
        alt="diamond-layout"
        className="logo"
      />

      <div className="exception-content">
        <div className="exception-title">Ops...</div>
        <div className="exception-detail">Algo inesperado aconteceu!</div>
        <Link to="/">
          <i className="fa-solid fa-hand-point-left mr-3"></i>
          Voltar
        </Link>
      </div>
    </div>
  );
};
