import { Button } from 'primereact/button';
import React, { useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Dialog as Modal } from '../../../../../components/Modal';
import useToastContext from '../../../../../hooks/toast';

interface IProps {
  id: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const ReportDevolution = ({ id, isOpen, onRequestClose }: IProps) => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const toast = useToastContext();

  /** useStates */

  /** functions */
  const searchDevolution = async (id: string) => {
    return null;
  };

  const loadWindow = () => {
    const componentString = ReactDOMServer.renderToString(<ContentHtml />);
    var docs = iframeRef.current?.contentWindow?.document;
    docs?.open();
    docs?.write(componentString);
    docs?.close();
  };

  const print = () => {
    loadWindow();
    window.print();
    iframeRef.current?.contentWindow?.print();
  };

  /** renders */
  const headerModalPrint = () => {
    return (
      <Button
        type="button"
        label="Imprimir"
        icon="fa-solid fa-print"
        onClick={() => print()}
      ></Button>
    );
  };

  const ContentHtml = () => {
    return (
      <div className="grid w-12" id="invoice-content" ref={iframeRef}>
        <div className="invoice w-12">
          <div className="invoice-header">
            <div className="invoice-company">
              <img
                id="invoice-logo"
                className="logo-image"
                src="https://www.primefaces.org/diamond-react/assets/layout/images/logo-dark.svg"
                alt="diamond-layout"
              />
              <div className="company-name">INDUSTRIAS ACME LTDA</div>
              <div>Rua Goiás, Qd 1 Lt 4, Vila Lucimar</div>
              <div>Inhumas-GO - CEP.: 75.403.568</div>
              <div>Fone.: 99 9999-9999</div>
            </div>
            <div>
              <div className="invoice-title">DEVOLUÇÃO</div>
              <div className="invoice-details">
                <div className="invoice-label">DATA DEV.</div>
                <div className="invoice-value">15/05/23</div>

                <div className="invoice-label">DEVOLUÇÃO</div>
                <div className="invoice-value">8523</div>

                <div className="invoice-label">DATA PED.</div>
                <div className="invoice-value">01/05/23</div>

                <div className="invoice-label">PEDIDO</div>
                <div className="invoice-value">8411</div>
              </div>
            </div>
          </div>
          <div className="invoice-to">
            <div className="bill-to">CLIENTE</div>
            <div className="invoice-to-info">
              <div>Nome.: John Doe da Silva</div>
              <div>Cpf/Cnpj.: 00.000.000/0000-00</div>
            </div>
          </div>
          <div className="invoice-items">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Produto</th>
                  <th>Qnt. venda</th>
                  <th>Vlr.</th>
                  <th>Total venda</th>
                  <th>Qnt. devolvida</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1</th>
                  <th>111 produto 1</th>
                  <th>10,0</th>
                  <th>1,00</th>
                  <th>10,00</th>
                  <th>1,00</th>
                  <th>1,00</th>
                </tr>
                <tr>
                  <th>2</th>
                  <th>222 produto 2</th>
                  <th>20,0</th>
                  <th>1,00</th>
                  <th>10,00</th>
                  <th>2,00</th>
                  <th>20,00</th>
                </tr>
                <tr>
                  <th>3</th>
                  <th>333 produto 1</th>
                  <th>30,0</th>
                  <th>1,00</th>
                  <th>20,00</th>
                  <th>5,00</th>
                  <th>5,00</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="invoice-summary">
            <div className="invoice-notes">
              <b>Obs.:</b>
              <p>
                A devolução gera crédito de contas a pagar para o cliente pegar
                da forma que lhe for melhor
              </p>
              <div></div>
            </div>
            <div>
              <div className="invoice-details">
                <div className="invoice-label">ITENS</div>
                <div className="invoice-value">8,00</div>

                <div className="invoice-label">TOTAL</div>
                <div className="invoice-value">26,00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        visible={true}
        onShow={() => loadWindow()}
        onHide={() => onRequestClose()}
        style={{
          display: isOpen ? 'flex' : 'none',
        }}
        header={headerModalPrint}
        contentStyle={{
          display: isOpen ? 'flex' : 'none',
          width: '98vw',
          height: '98vh',
        }}
        modal={isOpen}
      >
        <ContentHtml />
      </Modal>
    </>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.logoUrl === nextProps.logoUrl
  );
};

export default React.memo(ReportDevolution, comparisonFn);
