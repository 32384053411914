import React, { useRef, useState, useEffect } from 'react';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** typing */
import { Option } from '../../../types/optional';
import { Customer } from '../../../types/person';

/** services */
import api from '../../../services/api';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputMask } from '../../../components/Inputs/InputMask';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';

/** link with seller */
const linkWithSeller = [
  {
    label: 'Faturamento',
    value: 'F',
  },
  {
    label: 'Vendedor',
    value: 'V',
  },
];

/** comission on */
const comissionOnOptions = [
  {
    label: 'Produto',
    value: 'P',
  },
  {
    label: 'Serviço',
    value: 'S',
  },
  {
    label: 'Geral',
    value: 'G',
  },
];

interface Comissioned {
  name: string;
  nickname: string;
  cpf_cnpj: string;
  user_sis_id: string;
  vinculo: string;
  comissao: string;

  comissao_produto_a_vista: string;
  comissao_produto_a_prazo: string;

  comissao_servico_a_vista: string;
  comissao_servico_a_prazo: string;

  comissao_geral_a_vista: string;
  comissao_geral_a_prazo: string;

  acompanha_meta_venda: string;
  acompanha_proposta: string;
  permicao_gerar: string;
}

const ComissionedEdit = (): React.ReactElement => {
  /* ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** comissioned */
  const [, setComissioned] = useState<Comissioned>();

  /** params */
  const params: { id: string } = useParams();

  /** navigate */
  const navigate = useHistory();

  /** cpf or cnpj */
  const [cpfOrCnpj, setCpfOrCnpj] = useState('');
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** typePerson */
  const [typePerson, setTypePerson] = useState('F');

  /** users */
  const [users, setUsers] = useState<Array<Option>>([]);
  const [userSelected, setUserSelected] = useState('');

  /** seller */
  const [linkSeller, setLinkSeller] = useState('');

  /** comissionOn */
  const [comissionOn, setComissionOn] = useState('');

  /** follow sales target */
  const [followSales, setFolloSales] = useState('P');

  /** sales proposal */
  const [salesPropoposal, setSalesProposal] = useState('P');

  /** seller generate */
  const [sellerGenerate, setSellerGenerate] = useState('P');

  /** comission product */
  const [comissionProductInCash, setComissionProductInCash] = useState<
    number | null
  >(0);
  const [comissionProductInTerm, setComissionProductInTerm] = useState<
    number | null
  >(0);

  /** comission service */
  const [comissionServiceInCash, setComissionServiceInCash] = useState<
    number | null
  >(0);
  const [comissionServiceInTerm, setComissionServiceInTerm] = useState<
    number | null
  >(0);

  /** comission generally */
  const [comissionGenerallyInCash, setComissionGenerallyInCash] = useState<
    number | null
  >(0);
  const [comissionGenerallyInTerm, setComissionGenerallyInTerm] = useState<
    number | null
  >(0);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** handle add Comissioned */
  const handleAddComissioned = async (data: Comissioned) => {
    try {
      setLoadingStatus(true);

      const schemaComissioned = Yup.object({
        name: Yup.string().required('Por favor, informe nome do vendedor!'),
        nickname: Yup.string(),
        cpf_cnpj: Yup.string().required(
          'Por favor, informe cpf/cnpj do vendedor',
        ),
        user_sis_id: Yup.string().required('Por favor, informe o usuário!'),
        vinculo: Yup.string().required('Por favor, informe o vínculo!'),
        comissao: Yup.string().required('Por favor, informe comissão sobre!'),
        comissao_produto_a_vista: Yup.string().required(
          'Por favor, informe comissão produto à vista!',
        ),
        comissao_produto_a_prazo: Yup.string().required(
          'Por favor, informe comissão produto a prazo!',
        ),
        comissao_servico_a_vista: Yup.string().required(
          'Por favor, informe comissão serviço à vista',
        ),
        comissao_servico_a_prazo: Yup.string().required(
          'Por favor, informe comissão serviço a prazo',
        ),
        comissao_geral_a_vista: Yup.string().required(
          'Por favor, informe comissão geral à vista',
        ),
        comissao_geral_a_prazo: Yup.string().required(
          'Por favor, informe comissão geral a prazo',
        ),
      });

      await schemaComissioned.validate(data, { abortEarly: false });

      await api.put(`/commissioneds/${params.id}`, {
        ...data,
        comissao_produto_a_vista: parseFloat(
          data.comissao_produto_a_vista.replace(',', '.'),
        ),
        comissao_produto_a_prazo: parseFloat(
          data.comissao_produto_a_prazo.replace(',', '.'),
        ),
        comissao_servico_a_vista: parseFloat(
          data.comissao_servico_a_vista.replace(',', '.'),
        ),
        comissao_servico_a_prazo: parseFloat(
          data.comissao_servico_a_prazo.replace(',', '.'),
        ),
        comissao_geral_a_vista: parseFloat(
          data.comissao_geral_a_vista.replace(',', '.'),
        ),
        comissao_geral_a_prazo: parseFloat(
          data.comissao_geral_a_prazo.replace(',', '.'),
        ),
      });

      navigate.push('/commissioners/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        const optionUser = data.map((item: Customer) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setUsers(optionUser);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get(`/commissioneds/${params.id}`)
      .then(({ data }) => {
        setTypePerson(data.cpf_cnpj.length > 11 ? 'J' : 'F');
        setComissioned(data);
        formRef.current?.setData({
          name: data.name,
          nickname: data.nickname,
        });
        setComissionGenerallyInCash(data.comissao_geral_a_vista);
        setComissionGenerallyInTerm(data.comissao_geral_a_prazo);
        setComissionProductInCash(data.comissao_produto_a_vista);
        setComissionProductInTerm(data.comissao_produto_a_prazo);
        setComissionServiceInCash(data.comissao_servico_a_vista);
        setComissionServiceInTerm(data.comissao_servico_a_prazo);
        setCpfOrCnpj(data.cpf_cnpj);
        setUserSelected(data.user_sis_id);
        setLinkSeller(data.vinculo);
        setComissionOn(data.comissao);
        setFolloSales(data.acompanha_meta_venda);
        setSalesProposal(data.acompanha_proposta);
        setSellerGenerate(data.permicao_gerar);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/commissioners/list')}
      />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card">
        <Toast ref={toast}></Toast>

        <Form ref={formRef} onSubmit={handleAddComissioned} placeholder={''}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label>Nome</label>
              <InputText name="name" placeholder="Nome do Vendedor" />
            </div>
            <div className="field col-12 md:col-6">
              <label>Apelido/Nome Fantasia</label>
              <InputText
                name="nickname"
                placeholder="Apelido/Nome Fantasia do vendedor"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label>CPF/CNPJ</label>
              {cpfOrCnpj && typePerson && (
                <InputMask
                  mask={
                    typePerson === 'F' ? '999.999.999-99' : '99.999.999/9999-99'
                  }
                  name="cpf_cnpj"
                  value={cpfOrCnpj}
                  disabled
                  autoClear={false}
                  onChange={e => setCpfOrCnpj(e.value)}
                  className="surface-200"
                />
              )}
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Usuário</label>
              <InputDropDown
                name="user_sis_id"
                options={users}
                value={userSelected}
                placeholder="Selecionar"
                disabled
                style={{ background: 'var(--surface-200)' }}
                onChange={e => setUserSelected(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label>Vinculo com o Vendedor</label>
              <InputDropDown
                options={linkWithSeller}
                name="vinculo"
                value={linkSeller}
                onChange={e => setLinkSeller(e.value)}
                placeholder="Selecionar"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label>Comisssão sobre</label>
              <InputDropDown
                options={comissionOnOptions}
                value={comissionOn}
                onChange={e => setComissionOn(e.value)}
                placeholder="Selecionar"
                name="comissao"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid mt-2 w-12">
            <div className="field col-12 md:col-4 w-4 flex justify-content-center">
              <label>Comissão por produto</label>
            </div>

            <div className="field col-12 md:col-4 w-4 flex justify-content-center">
              <label>Comissão por serviço</label>
            </div>

            <div className="field col-12 md:col-4 w-4 flex justify-content-center">
              <label>Comissão geral </label>
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`À vista`} className="py-0"></Tag>
              <InputNumber
                name="comissao_produto_a_vista"
                suffix="%"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={comissionProductInCash}
                onChange={e => setComissionProductInCash(e.value)}
                placeholder="0,00%"
                className="mb-1"
              />
            </div>
            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`A prazo`} className="py-0"></Tag>
              <InputNumber
                name="comissao_produto_a_prazo"
                suffix="%"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={comissionProductInTerm}
                onChange={e => setComissionProductInTerm(e.value)}
                placeholder="0,00%"
              />
            </div>
            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`À vista`} className="py-0"></Tag>
              <InputNumber
                name="comissao_servico_a_vista"
                suffix="%"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={comissionServiceInCash}
                onChange={e => setComissionServiceInCash(e.value)}
                placeholder="0,00%"
                className="mb-1"
              />
            </div>
            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`A prazo`} className="py-0"></Tag>
              <InputNumber
                name="comissao_servico_a_prazo"
                suffix="%"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={comissionServiceInTerm}
                onChange={e => setComissionServiceInTerm(e.value)}
                placeholder="0,00%"
              />
            </div>

            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`À vista`} className="py-0"></Tag>
              <InputNumber
                name="comissao_geral_a_vista"
                suffix="%"
                value={comissionGenerallyInCash}
                onChange={e => setComissionGenerallyInCash(e.value)}
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00%"
                className="mb-1"
              />
            </div>
            <div className="field col-12 md:col-2">
              <Tag severity={'info'} value={`A prazo`} className="py-0"></Tag>
              <InputNumber
                name="comissao_geral_a_prazo"
                suffix="%"
                value={comissionGenerallyInTerm}
                onChange={e => setComissionGenerallyInTerm(e.value)}
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00%"
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid mb-6">
            <div className="field col-12 md:col-4 flex flex-column">
              <label htmlFor="dropdown" className="w-max m-auto mb-5">
                Acompanhar Meta de Vendas?
              </label>
              <div className="field col-12 md:col flex align-items-center justify-content-center">
                <label className="m-0">Própio</label>
                <InputSwitch
                  name="acompanha_meta_venda"
                  className="ml-3 mr-3"
                  falseValue="P"
                  trueValue="T"
                  checked={followSales}
                  onChange={e => setFolloSales(`${e.value}`)}
                />
                <label className="m-0">Todos</label>
              </div>
            </div>
            <div className="field col-12 md:col-4 flex flex-column">
              <label htmlFor="dropdown" className="w-max m-auto mb-5">
                Acompanhar Propostas de Vendas?
              </label>
              <div className="field col-12 md:col flex align-items-center justify-content-center">
                <label htmlFor="dropdown" className="m-0">
                  Própio
                </label>
                <InputSwitch
                  name="acompanha_proposta"
                  className="ml-3 mr-3"
                  falseValue="P"
                  trueValue="T"
                  checked={salesPropoposal}
                  onChange={e => setSalesProposal(`${e.value}`)}
                />
                <label htmlFor="dropdown" className="m-0">
                  Todos
                </label>
              </div>
            </div>
            <div className="field col-12 md:col-4 flex flex-column">
              <label htmlFor="dropdown" className="w-max m-auto mb-5">
                O Vendedor Pode Gerar?
              </label>
              <div className="field col-12 md:col flex align-items-center justify-content-center">
                <label htmlFor="dropdown" className="m-0">
                  Propostas
                </label>
                <InputSwitch
                  name="permicao_gerar"
                  className="ml-3 mr-3"
                  checked={sellerGenerate}
                  falseValue="PRO"
                  trueValue="PED"
                  onChange={e => setSellerGenerate(`${e.value}`)}
                />
                <label htmlFor="dropdown" className="m-0">
                  Pedidos/Propostas
                </label>
              </div>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ComissionedEdit;
