import React, { useState, useRef } from 'react';

/** yup */
import * as Yup from 'yup';

/** components */
import { Button } from 'primereact/button';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import InputDate from '../../../../components/Inputs/InputCalendar';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import api from '../../../../services/api';
import { Option } from '../../../../types/optional';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import { format } from 'date-fns/esm';
import { Toast } from 'primereact/toast';

const ReportsCheckByProvider: React.FC = (): React.ReactElement => {
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [providers, setProviders] = useState();
  const [provider, setSearchProvider] = useState<any>();

  /** initial date */
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    new Date(),
  );

  /** router */

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const handleReports = async (data: any) => {
    try {
      const schemaReports = Yup.object({
        providerId: Yup.string().required('Por favor, informe o fornecedor!'),
      });
      await schemaReports.validate(data, { abortEarly: false });
      const { data: response } = await api.get(
        `/reports/bills-payable/cheque-provider/${provider?.value}`,
        {
          params: {
            dateStart: format(initialDate as Date, 'yyyy-MM-dd'),
            dateEnd: format(finalDate as Date, 'yyyy-MM-dd'),
          },
        },
      );
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  /** ammount options */
  const ammountOptions = (data: any) => {
    return data.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  /** autoComplete */
  const searchProvider = (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      api
        .get(`/persons/provider/src?search=${event.query}`)
        .then(({ data }) => {
          const opts = ammountOptions(data.providers);
          opts.length <= 0 &&
            showToast('error', 'Error', 'Nenhum fornecedor encontrado!');

          setProviders(opts);
        })
        .catch(error => showToast('error', 'Error', error.response.data.error));
    }
  };

  return (
    <>
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Fornecedor</label>
              <InputAutoComplete
                name="providerId"
                value={provider}
                completeMethod={e => searchProvider(e)}
                suggestions={providers}
                className="w-6"
                field="label"
                onChange={e => setSearchProvider(e.value)}
                itemTemplate={itemTemplate}
                placeholder="Buscar fornecedores..."
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="date_initial"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="date_final"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsCheckByProvider;
