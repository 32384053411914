import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { InputPassword } from '../../components/Inputs/InputPassword';
import { InputText } from '../../components/Inputs/InputText';
import { AppContext } from '../../context/AppContext';
import { definedCredentials } from '../../hooks/auth';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getErrorsValidation';

type SigninModel = {
  email: string;
  password: string;
};

export const Signin = (): React.ReactElement => {
  const navigate = useHistory();
  const toast = useToastContext();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const { dispatch } = useContext(AppContext);

  const formRef = useRef<FormHandles>(null);

  const handleSignin = async (data: SigninModel) => {
    try {
      setIsLoad(true);

      data.email = data.email.trim();
      data.password = data.password.trim();

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('Por favor, Insira um e-mail')
          .email('Informe um e-mail válido!'),
        password: Yup.string().required('Por favor, Insira uma senha'),
      });

      await schema.validate(data, { abortEarly: false });

      const { data: credentials } = await api.post('/sessions', data);

      dispatch({
        type: 'SET_ACCESS',
        payload: { access: credentials._p },
      });

      credentials &&
        dispatch({
          type: 'SET_USER',
          payload: {
            user: {
              ...credentials.user,
              token: credentials.token,
              b_id: credentials._b,
              b_p: credentials._p,
            },
          },
        });

      definedCredentials(credentials);

      navigate.push('/');

      toast('success', 'Login realizado com sucesso!');
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <Form onSubmit={handleSignin} ref={formRef}>
      <div className="login-body">
        <div className="login-wrapper">
          <div className="login-panel">
            <img
              src="assets/layout/images/logo.svg"
              className="logo"
              alt="Webger - Sistema de gestão 100% online"
            />
            <div
              className="login-form"
              style={{ width: '60vw', minWidth: '310px' }}
            >
              <h2>Entrar</h2>
              <p>
                Deseja criar uma conta? <Link to="/signup">Nova conta</Link>
              </p>
              <InputText placeholder="Email" name="email" />
              <InputPassword
                placeholder="Senha"
                name="password"
                feedback={false}
                toggleMask={true}
              />
              <Button type="submit" label="Entrar" loading={isLoad}></Button>
              <p className="mt-4">
                <Link to="/forgot-password">Esqueceu a senha?</Link>
              </p>
            </div>

            <p>
              Algum problema?{' '}
              <a
                href="https://wa.me/5562985099000"
                target={'_blank'}
                rel="noreferrer"
              >
                Clique aqui
              </a>{' '}
              deixe-nos ajudá-lo.
            </p>
          </div>
          <div className="login-image">
            <div className="login-image-content text-center">
              <h1>Indique e ganhe isenção em uma mensalidade</h1>
              <div className="grid mt-4 pl-8 pr-8" style={{ color: '#ffffff' }}>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-box-open mr-3"></i>
                  ESTOQUE
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-wallet mr-3"></i>
                  CONTAS A PAGAR
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NF-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NFC-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-file-pdf mr-3"></i>
                  NFS-E
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-money-bill-1 mr-3"></i>
                  CONTAS A RECEBER
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-list-check mr-3"></i>
                  INVENTÁRIO
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-phone-volume mr-3"></i>
                  CRM
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-cash-register mr-3"></i>
                  PDV - FRENTE DE CAIXA
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-motorcycle mr-3"></i>
                  DELIVERY
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-industry mr-3"></i>
                  PCP
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-store mr-3"></i>
                  LOJA VIRTUAL
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-boxes-packing mr-3"></i>
                  MARKET PLACES
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-mobile-screen mr-3"></i>
                  VITRINE DE PRODUTOS
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-money-bill-trend-up mr-3"></i>
                  PERFORMA+
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-barcode mr-3"></i>
                  BOLETOS
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-retweet mr-3"></i>
                  LOCAÇÕES
                </div>
                <div className="md:col-4" style={{ fontSize: '1.5rem' }}>
                  <i className="fa-solid fa-cube mr-3"></i>
                  CORREIOS
                </div>
              </div>
            </div>
            <div className="image-footer">
              <p>Siga nos nas redes sociais</p>
              <div className="icons">
                <i
                  className="pi pi-whatsapp"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-facebook"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-instagram"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
                <i
                  className="pi pi-youtube"
                  style={{ color: 'var(--gray-400)' }}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
