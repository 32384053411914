import { Button } from 'primereact/button';
import React from 'react';

interface IProps {
  loadingStatus: boolean;
}

const FormFooter = (e: IProps) => {
  return (
    <div className="p-fluid grid formgrid">
      <div className="field col-12 md:col-6">
        <Button
          label="Limpar"
          type="reset"
          icon="pi pi-times"
          className="p-button-danger"
          loading={e.loadingStatus}
        />
      </div>

      <div className="field col-12 md:col-6">
        <Button
          label="Salvar"
          icon="pi pi-check"
          className="p-button-success"
          type="submit"
          loading={e.loadingStatus}
        />
      </div>
    </div>
  );
};

export default FormFooter;
