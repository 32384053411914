import React, { useEffect, useRef, useState } from 'react';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** services */
import api from '../../../../services/api';

/** components */
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../../components/DataTableColumns/RenderColumnStatus';
import InputDate from '../../../../components/Inputs/InputCalendar';
import { SideBar } from '../../../../components/Sidebar';
import { numberToCurrency } from '../../../../utils/number';
import openReports from '../../../../utils/openReports';
import InputProductsHeader from '../../../input-products/input-products-header';

const ReportsReceiptProduct: React.FC = (): React.ReactElement => {
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const hoje = new Date();

  const [selectedInput, setSelectedInput] = useState<any>();
  const [selectProvider, setSelectProvider] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  /** initial date */
  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const selectInput = (e: any) => {
    setSelectedInput(e.value);
    setSelectProvider(false);
    setError(false);
  };

  /** error */
  const [error, setError] = useState(false);

  /** width */
  const [width, setWidth] = useState('60vw');

  /** input product */
  const [inputProducts, setInputProducts] = useState();

  /** ref */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** table */
  const codeColumnBody = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const docColumnBody = (rowData: any) => {
    return (
      <span>
        {rowData.doc_number} - {rowData.doc_serie}
      </span>
    );
  };

  const valueColumnBody = (rowData: any) => {
    return <span>{numberToCurrency(parseFloat(rowData.doc_value))}</span>;
  };

  const handleReports = async () => {
    try {
      setLoadingStatus(true);

      if (!selectedInput?.id) {
        setError(true);
        showToast('error', 'Error', 'Por favor, informe a entrada!');
        return;
      }

      const { data } = await api.get(`/reports/input/${selectedInput.id}`, {
        params: {
          dateStart: initialDate,
          dateEnd: finalDate,
        },
      });
      openReports(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
        'entrada',
      );
    } catch (error: any) {
      showToast('error', 'Error', error?.response?.data.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Entradas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  useEffect(() => {
    setLoadingStatus(true);

    api.get('/input-products').then(({ data }) => {
      setInputProducts(data);
    });
    if (window.innerWidth < 1145) {
      setWidth('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWidth('100vw');
      } else {
        setWidth('70vw');
      }
    });

    setLoadingStatus(false);
  }, []);

  return (
    <>
      <InputProductsHeader />
      <div className="card md:w-6 sm:w-full">
        <Form ref={form} onSubmit={handleReports}>
          <Toast ref={toast} />
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Entrada de Produtos</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedInput
                    ? `${selectedInput.provider} - Sr.: ${
                        selectedInput.serie
                      } - Doc.: ${
                        selectedInput.number
                      } - ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(selectedInput.value)}`
                    : 'Selecionar entrada...'
                }
                aria-haspopup
                onClick={() => setSelectProvider(true)}
                aria-controls="overlay_panel"
                className={
                  error
                    ? `select-product-button w-9 p-button-raised p-button-danger`
                    : `select-product-button w-9`
                }
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Inicial</label>
              <InputDate
                name="date_initial"
                value={initialDate}
                dateFormat="dd/mm/yy"
                onChange={e => setInitialDate(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Data Final</label>
              <InputDate
                name="date_final"
                value={finalDate}
                dateFormat="dd/mm/yy"
                onChange={e => setFinalDate(e.value)}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-12">
              <Button
                label="Imprimir"
                className="p-button-raised p-button-success"
                icon="fa-solid fa-print"
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={selectProvider}
        position="right"
        style={{ width }}
        onHide={() => setSelectProvider(false)}
      >
        <DataTable
          value={inputProducts}
          selectionMode="single"
          selection={selectedInput}
          onSelectionChange={e => selectInput(e)}
          dataKey="id"
          header={header}
          globalFilter={globalFilter}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          responsiveLayout="scroll"
          loading={loadingStatus}
        >
          <Column field="id" header="#" body={renderColumnPosition}></Column>
          <Column field="provider" header="Fornecedor"></Column>
          <Column field="serie" header="Série" align={'center'}></Column>
          <Column field="number" header="Número" align={'center'}></Column>
          <Column
            field="value"
            header="Total"
            body={e => renderColumnDecimal(e.value)}
            align={'right'}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={e => renderColumnStatus(e)}
            align={'center'}
          ></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default ReportsReceiptProduct;
