import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../../components/Inputs/InputTextEdit';
import { Dialog } from '../../../../components/Modal';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}
const DialogAction = ({ isOpen, onRequestClose }: IProps) => {
  const [load, setLoad] = useState(false);

  const toast = useToastContext();

  const [actionTitle, setActionTitle] = useState('');
  const [actionIcon, setActionIcon] = useState('');
  const [actionDescription, setActionDescription] = useState('');

  const handleCreateAction = async (d: any) => {
    setLoad(true);
    await api
      .post('crm/action', {
        data: {
          icon: d.icon,
          title: d.title,
          description: d.description,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          setActionTitle('');
          setActionIcon('');
          setActionDescription('');
          onRequestClose();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  return (
    <Dialog
      header="Cadastrar interações?"
      visible={isOpen}
      style={{ width: 512 }}
      onHide={() => onRequestClose()}
    >
      <Form
        ref={null}
        onSubmit={d => handleCreateAction(d)}
        className="p-fluid grid formgrid"
      >
        <div className="field col-12">
          <label htmlFor="title">Titulo</label>
          <InputText
            name="title"
            value={actionTitle}
            onChange={e => setActionTitle(e.currentTarget.value)}
            placeholder="Ex.: Whatsapp"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="icon">Icone</label>
          <InputDropDown
            name="icon"
            value={actionIcon}
            onChange={e => setActionIcon(e.value)}
            options={[
              {
                value: 'fa-brands fa-whatsapp',
                label: (
                  <>
                    <i className="fa-brands fa-whatsapp mr-3"></i>Mensagem via
                    whatsapp
                  </>
                ),
              },
              {
                value: 'fa-solid fa-phone',
                label: (
                  <>
                    <i className="fa-solid fa-phone mr-3"></i>Ligação fixo
                  </>
                ),
              },
              {
                value: 'fa-solid fa-mobile-screen',
                label: (
                  <>
                    <i className="fa-solid fa-mobile-screen mr-3"></i>Ligação
                    celular
                  </>
                ),
              },
              {
                value: 'fa-solid fa-envelope',
                label: (
                  <>
                    <i className="fa-solid fa-envelope mr-3"></i>Envio de email
                  </>
                ),
              },
              {
                value: 'fa-solid fa-person-chalkboard',
                label: (
                  <>
                    <i className="fa-solid fa-person-chalkboard mr-3"></i>
                    Apresentação
                  </>
                ),
              },
              {
                value: 'fa-solid fa-sack-dollar',
                label: (
                  <>
                    <i className="fa-solid fa-sack-dollar mr-3"></i>
                    Venda
                  </>
                ),
              },
              {
                value: 'fa-solid fa-xmark',
                label: (
                  <>
                    <i className="fa-solid fa-xmark mr-3"></i>
                    Sem resultados
                  </>
                ),
              },
            ]}
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-12">
          <label htmlFor="description">Descrição</label>
          <InputTextEdit
            name="description"
            value={actionDescription}
            onTextChange={e => setActionDescription(e.textValue)}
            placeholder="Ex.: Envio de mensagens pelo whatsapp"
            style={{ height: 128 }}
            className="line-heigth-3"
          />
        </div>

        <div className="field col-12">
          <Button
            label="Salvar"
            icon="fa-solid fa-check"
            type="submit"
            className="p-button-success"
            loading={load}
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default DialogAction;
