import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import { IProposalList } from '../Create/dtos/IProposalList';
import { useOrder } from '../../../hooks/useOrder';
import { InputText } from 'primereact/inputtext';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string | null;
}

const OrderSidebarProposal = ({
  isOpen,
  onRequestClose,
  id,
}: IProps): React.ReactElement => {
  const toast = useToastContext();
  const router = useHistory();

  const [load, setLoad] = useState(false);
  const { proposals } = useOrder();
  const [propose, setPropose] = useState<IProposalList>({} as IProposalList);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const handlePropolseSelect = (e: any) => {
    onRequestClose();
    router.push(`/orders/create/${e.value.id}`);
  };

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        style={{ width: '60vw' }}
      >
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h3>Selecionar Proposta</h3>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Buscar..."
              autoComplete="off"
            />
          </span>
        </div>

        <DataTable
          value={proposals}
          responsiveLayout="scroll"
          selectionMode="single"
          paginator
          globalFilter={globalFilter}
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          emptyMessage="Nenhuma proposta encontrada!"
          selection={propose}
          onSelectionChange={e => {
            handlePropolseSelect(e);
          }}
          loading={load}
        >
          <Column field="id" header="#" body={renderColumnPosition}></Column>
          <Column field="order" header="Pedido"></Column>
          <Column field="name" header="Cliente"></Column>
          <Column
            header="Data"
            body={r => renderColumnDate(r.order_date)}
          ></Column>
          <Column
            header="Valor"
            body={r => renderColumnDecimal(r.order_total)}
          ></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default OrderSidebarProposal;
