import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** utils */

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import api from '../../../services/api';

/**  utils */
import { Dialog } from '../../../components/Modal';
import { parseToCnpj, parseToCpf } from '../../../utils/cpf_cnpj_format';

interface ComissionedResponse {
  id: string;
  name: string;
  cpf_cnpj: string;
  status: string;
  userCreate: {
    name: string;
  };
}

const emptyComissioned = {
  id: '',
  name: '',
  cpf_cnpj: '',
  status: '',
  userCreate: {
    name: '',
  },
};

/** menu options */

const ComissionedList = (): React.ReactElement => {
  /** state */
  const [comissioneds, setComissioneds] = useState<Array<ComissionedResponse>>(
    [],
  );

  const [comissioned, setComissioned] = useState(emptyComissioned);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');

  /** ref */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** dialog delete comissioned */

  const confirmDeleteComissioned = (brand: any) => {
    setComissioned(brand);
    setDialogDeleteComissioned(true);
  };

  const [dialogDeleteComissionedsDialog, setDialogDeleteComissioned] =
    useState(false);

  const hideDeleteComissionedDialog = () => {
    setDialogDeleteComissioned(false);
  };

  const deleteComissionedApi = async () => {
    try {
      await api.delete(`/commissioneds/${comissioned.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteComissioned = () => {
    const _comissioneds = comissioneds.filter(val => val.id !== comissioned.id);
    setComissioneds(_comissioneds);
    setDialogDeleteComissioned(false);
    setComissioned(emptyComissioned);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteComissionedApi();
  };

  const deleteComissionedDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteComissionedDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteComissioned}
      />
    </React.Fragment>
  );

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: ComissionedResponse, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const cpfOrCnpjBodyTemplate = (rowData: ComissionedResponse) => {
    return (
      <>
        <span>
          {rowData.cpf_cnpj.length > 11
            ? parseToCnpj(rowData.cpf_cnpj)
            : parseToCpf(rowData.cpf_cnpj)}
        </span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: ComissionedResponse) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: ComissionedResponse) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/commissioners/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteComissioned(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Comissionados</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchCommisioneds = async () => {
    try {
      const { data } = await api.get('/commissioneds');
      setComissioneds(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchCommisioneds();
  }, []);

  return (
    <>
      <Button
        label="Novo Comissionado"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/commissioners/create')}
      />
      <div className="card">
        <DataTable
          value={comissioneds}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} comissionados"
          globalFilter={globalFilter}
          emptyMessage="Não existe Comissionados."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="id" header="N°" body={codeBodyTemplate}></Column>
          <Column field="name" header="Nome"></Column>
          <Column header="CPF ou CNPJ" body={cpfOrCnpjBodyTemplate}></Column>
          <Column field="userCommissioned.name" header="Usuário"></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteComissionedsDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteComissionedDialogFooter}
        onHide={hideDeleteComissionedDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {comissioned && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{comissioned?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ComissionedList;
