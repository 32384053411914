import { FormHandles } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import IOsServiceDTO from '../dtos/IOsServiceDTO';

import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../../components/Inputs/InputTextEdit';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const FastFormOsService = ({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement => {
  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [numServico, setNumServico] = useState<number>(0);
  const [titulo, setTitulo] = useState<string>('');
  const [unidServico, setUnidServico] = useState<string>('');
  const [unidServicoOpts, setUnidServicoOpts] = useState<IOptionsDTO[]>([]);
  const [preco, setPreco] = useState<number>(0);
  const [taxIssqn, setTaxIssqn] = useState<number>(0);
  const [taxCidadeCod, setTaxCidadeCod] = useState<string>('');
  const [comissao, setComissao] = useState<number>(0);
  const [obs, setObs] = useState<string>('');

  const [servicoOpts, setServicoOpts] = useState<IOsServiceDTO[]>([]);

  /** functions */
  const clearForm = async () => {
    setId('');
    setNumServico(0);
    setTitulo('');
    setUnidServico('');
    setPreco(0);
    setTaxIssqn(0);
    setTaxCidadeCod('');
    setComissao(0);
    setObs('');

    onRequestClose();
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);

      formRef.current?.setErrors({});

      data.price = Number(preco);

      const validation = Yup.object({
        title: Yup.string().required('Informar o título!'),
        service_unity: Yup.string()
          .uuid('Informe unidade do serviço!')
          .required('Informe unidade do serviço!'),
        price: Yup.number()
          .positive('Informe um preço!')
          .required('Informe um preço!'),
      });
      await validation.validate(data, { abortEarly: false });

      if (isEdit) {
        const res = await api.put('/os/service', {
          osService: {
            id,
            title: titulo,
            service_unity_id: unidServico,
            price: preco,
            tax_issqn: taxIssqn,
            tax_cod_city: taxCidadeCod,
            comission: comissao,
            obs,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Serviço editado com sucesso!');
        }
      } else {
        const res = await api.post('/os/service', {
          osService: {
            title: titulo,
            service_unity_id: unidServico,
            price: preco,
            tax_issqn: taxIssqn,
            tax_cod_city: taxCidadeCod,
            comission: comissao,
            obs,
          },
        });

        if (res.status >= 200 || res.status <= 299) {
          toast('success', 'Sucesso', 'Serviço cadastrado com sucesso!');
        }
      }

      listServices();
      clearForm();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listServices = async () => {
    setLoadingStatus(true);
    await api
      .get('/os/service/list?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsService.map((i: any) => {
          return {
            id: i.id,
            service_number: i.service_number,
            title: i.title,
            service_unity_id: i.service_unity_id,
            unity: i.serviceUnity.title,
            price: Number(i.price),
            tax_issqn: Number(i.tax_issqn),
            tax_cod_city: i.tax_cod_city,
            comission: Number(i.comission),
            obs: i.obs,
          };
        });

        setServicoOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0]);
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffet */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/os/service-unity?status=A')
      .then(({ data }) => {
        const opts = data.listAllOsServiceUnity.map((i: any) => {
          return {
            value: i.id,
            label: i.title,
          };
        });

        setUnidServicoOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formRef.current?.setErrors(errors);
          toast('error', 'Error', e.errors[0]);
        } else {
          toast('error', 'Error', e.response?.data?.error);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });

    listServices();
  }, []);

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => onRequestClose()}
      style={{ width: '60vw' }}
    >
      <h3>Novo serviço</h3>
      <Form ref={formRef} onSubmit={e => handleSubmit(e)}>
        <div className="p-fluid grid formgrid">
          <div className="field col-2">
            <label htmlFor="num_service">Num. serviço</label>
            <InputText
              name="num_service"
              placeholder="000"
              value={numServico}
              disabled
            />
          </div>

          <div className="field col-8">
            <label htmlFor="title">Serviço</label>
            <InputText
              name="title"
              placeholder="Ex.: Manutenção..."
              value={titulo}
              onChange={e => setTitulo(e.currentTarget.value)}
              autoFocus={isEdit}
            />
          </div>

          <div className="field col-12 md:col-2 mx-0">
            <label htmlFor="service_unity">Unidade</label>
            <InputDropDown
              name="service_unity"
              options={unidServicoOpts}
              value={unidServico}
              onChange={e => setUnidServico(e.value)}
              placeholder="Selecionar..."
              tooltip={'Hr, Min, Dia...'}
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="price">Preço</label>
            <InputNumber
              name="price"
              placeholder="0,00"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={preco}
              onChange={e => setPreco(Number(e.value))}
              mode="decimal"
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="commission">Comissão (%)</label>
            <InputNumber
              name="commission"
              placeholder="0,00%"
              value={comissao}
              onChange={e => setComissao(Number(e.value))}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="tax_issqn">Taxa ISSQN (%)</label>
            <InputNumber
              name="tax_issqn"
              placeholder="0,00%"
              value={taxIssqn}
              onChange={e => setTaxIssqn(Number(e.value))}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="tax_cod_city">Código cidade</label>
            <InputText
              name="tax_cod_city"
              placeholder="Ex.: AAA00000"
              value={taxCidadeCod}
              onChange={e => setTaxCidadeCod(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-12">
            <InputTextEdit
              name="obs"
              style={{ height: '10vh' }}
              value={obs}
              onTextChange={e => setObs(e.htmlValue || '')}
              placeholder="Este produto tem uma ótima saída!"
            />
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={isEdit ? 'Editar' : 'Salvar'}
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </div>
      </Form>
    </SideBar>
  );
};

export default FastFormOsService;
