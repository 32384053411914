import React from 'react';

const renderColumnFlag: React.FC = (row: any) => {
  return row === true ? (
    <i className="fa-solid fa-flag text-green-500"></i>
  ) : (
    <i className="fa-solid fa-flag text-gray-300"></i>
  );
};

export default renderColumnFlag;
