import { emptyUser } from '../../../user/dtos/IUser';

const today = new Date();

export interface IUser {
  id: string;
  name: string;
  avatar_url: string;
  value?: string;
  label?: string;
}

export interface ICrmAction {
  id: string;
  position: number;
  icon: string;
  title: string;
  description: string;
  user_id: string;
}

export interface ICrmCardActivity {
  id: string;
  card_id: string;
  description: string;
  action_id: string;
  action: ICrmAction;
  time_start: number;
  time_end: number;
  time: number;
  user_id: string;
  user: IUser;
  status: string;
  created_at: Date;
}

export interface ICrmTag {
  id: string;
  position: number;
  color: string;
  title: string;
  user_id: string;
}

export interface ICrmAttach {
  id: string;
  position: number;
  file_url: string;
  user_id: string;
}

export interface ICrmChecklist {
  id: string;
  card_id: string;
  position: number;
  description: string;
  checked: boolean;
}

export interface IPersonShort {
  id: string;
  codigo: number;
  customer: string;
  provider: string;
  transporter: string;
  name: string;
  nickname: string;
  cpf_cnpj: string;
  rg_ie: string;
  image_url: string;
}

export interface ICrmCardCustomer {
  id: string;
  card_id: string;
  customer_id: string;
  customer: IPersonShort;
  user_id: string;
}

export interface ICrmCardDates {
  id: string;
  card_id: string;
  date_start: Date;
  date_end: Date;
  send_alert: boolean;
  date_alert: Date;
  user_id: string;
}

export interface ICrmCardOrder {
  id: string;
  card_id: string;
  order_id: string;
  user_id: string;
}

export interface ICrmCardProduct {
  id: string;
  card_id: string;
  product_id: string;
  qnt: number;
  value: number;
  total: number;
  user_id: string;
}

export interface ICrmCardSellers {
  id: string;
  card_id: string;
  seller_id: string;
  seller: IUser;
  user_id: string;
  status: string;
}

export interface ICrmCard {
  id: string;
  column_id: string;
  num: number;
  position: number;
  title: string;
  description: string;
  value: number;
  priority: string;
  rfb_id: string | null;
  person_id: string | null;
  component: string | null;
  user_id: string;
  user: IUser;
  crmAttachs: ICrmAttach[];
  crmChecklists: ICrmChecklist[];
  crmTags: ICrmTag[];
  crmCardActivities: ICrmCardActivity[];
  crmCardCustomer: ICrmCardCustomer[];
  crmCardDates: ICrmCardDates[];
  crmCardOrders: ICrmCardOrder[];
  crmCardProducts: ICrmCardProduct[];
  crmCardSellers: ICrmCardSellers[];
  status: string;
}

export const emptyCrmCard: ICrmCard = {
  id: '',
  column_id: '',
  num: 0,
  position: 0,
  title: '',
  description: '',
  value: 0,
  priority: '',
  rfb_id: null,
  person_id: null,
  component: null,
  user_id: '',
  user: {
    ...emptyUser,
    avatar_url: '',
  },
  crmAttachs: [],
  crmChecklists: [],
  crmTags: [],
  crmCardActivities: [],
  crmCardCustomer: [],
  crmCardDates: [],
  crmCardOrders: [],
  crmCardProducts: [],
  crmCardSellers: [],
  status: '',
};

export interface ICrmColumn {
  id: string;
  panel_id: string;
  title: string;
  description: string;
  position: number;
  color: string;
  user_id: string;
  user: IUser;
  crmCards: ICrmCard[];
}

export interface ICard {
  id: string;
  laneId: string;
  position: number;
  title: string;
  description: string;
  label: string;
  draggable: boolean;
  style: any;
  cardStyle: any;
  value: number;
  members: string[];
  priority: string;
  interactions: string[];
  tags: { id: string; title: string; bgcolor: string }[];
  schedules: string[];
}

export const emptyCard: ICard = {
  id: '',
  laneId: '',
  position: 0,
  title: '',
  description: '',
  label: '',
  draggable: false,
  style: undefined,
  cardStyle: undefined,
  tags: [],
  schedules: [],
  value: 0,
  members: [],
  priority: '',
  interactions: [],
};

export interface Ilanes {
  id: string;
  position: number;
  currentPage: number;
  title: string;
  label: string;
  draggable: boolean;
  metadata: any;
  cards: ICard[];
}

export interface IBoard {
  title: string;
  description: string;
  user: {
    id: string;
    name: string;
  };
  created_at: Date;
  lanes: Ilanes[];
}

export const emptyBoard: IBoard = {
  title: '',
  description: '',
  user: {
    id: '',
    name: '',
  },
  created_at: today,
  lanes: [],
};

export interface ICrmPanel {
  id: string;
  position: number;
  color: string;
  title: string;
  description: string;
  user_id: string;
  user: IUser;
  crmColumns: ICrmColumn[];
  qnt_cards: number;
  qnt_new_cards: number;
  qnt_new_tasks: number;
  status: string;
}

export const FLAT_TRANSLATION_TABLE = {
  'Add another lane': '+ Nova coluna',
  'Click to add card': '+ Novo card',
  'Delete lane': 'Excluir',
  'Lane actions': 'Menu',
  'button.Add lane': '+ Nova coluna',
  'button.Add card': '+ Novo card',
  'button.Cancel': 'Cancelar',
  'placeholder.title': 'Título',
  'placeholder.description': 'Descrição',
  'placeholder.label': 'Valor',
} as any;
