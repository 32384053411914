import React, { useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import getValidationErrors from '../../utils/getErrorsValidation';

import api from '../../services/api';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { InputText } from '../../components/Inputs/InputText';
import useToastContext from '../../hooks/toast';

const SmartSearch = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [products, setProducts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const searchProduct = async (search: string) => {
    try {
      const { data } = await api.get(`/products/smart/search?src=${search}`);
      setProducts(data.products);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast('error', 'Error', error.response?.data?.error, 8000);
    }
  };

  const handleSearch = async (data: { search: string }) => {
    form.current?.setErrors({});

    try {
      setLoading(true);

      const schemaSearch = Yup.object({
        search: Yup.string().required(
          'Por favor, informe o valor a ser pesquisado!',
        ),
      });

      await schemaSearch.validate(data, { abortEarly: false });

      setProducts([]);
      await searchProduct(data.search);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0], 8000);
      } else {
        setLoading(false);
        toast('error', 'Error', error.response?.data?.error, 8000);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="card p-0 md:col-8 sm:col-12">
        <div className="col">
          <Form ref={form} onSubmit={handleSearch}>
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-8 sm:col-12">
                <label htmlFor="">Busca inteligente</label>
                <InputText name="search" placeholder="Digite aqui..." />
              </div>
              <div className="field col-12 md:col-4 sm:col-12 flex align-items-end">
                <Button icon="pi pi-search" label="Buscar" />
              </div>
            </div>
          </Form>
        </div>
      </div>

      {loading && (
        <div className="flex justify-content-center">
          <ProgressSpinner style={{ width: '50px', height: '50px' }} />
        </div>
      )}
      {products.length > 0 &&
        products.map((item: any, indice: number) => (
          <>
            <div className="card my-2 col-12 md:col-8">
              <div className="p-fluid grid formgrid">
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Cód.
                  </label>
                  <p>{item.codigo}</p>
                </div>
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Sku.
                  </label>
                  <p>{item.sku}</p>
                </div>
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Estq.
                  </label>
                  <p>
                    {item.stock
                      ? new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(item.stock)
                      : 0}
                  </p>
                </div>

                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Localização
                  </label>
                  <p>{item.stock_location || 'n/a'}</p>
                </div>

                <div className="col-12">
                  <label htmlFor="" className="text-400">
                    Produto
                  </label>
                  <p className="text-900">{item.title}</p>
                </div>

                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Tabela
                  </label>
                </div>
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Preço
                  </label>
                </div>
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Min.
                  </label>
                </div>
                <div className="col-3">
                  <label htmlFor="" className="text-400">
                    Max.
                  </label>
                </div>

                {item.productPrices.map((itemPrice: any, index: number) => {
                  return (
                    <>
                      <div className="col-3">
                        <p>{itemPrice?.tablePrice?.title}</p>
                      </div>
                      <div className="col-3">
                        <p>
                          {new Intl.NumberFormat('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(itemPrice.vlr_venda)}
                        </p>
                      </div>
                      <div className="col-3">
                        <p>
                          {new Intl.NumberFormat('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(itemPrice.qnt_venda_min)}
                        </p>
                      </div>
                      <div className="col-3">
                        <p>
                          {new Intl.NumberFormat('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(itemPrice.qnt_venda_max)}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default SmartSearch;
