import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useRef, useState } from 'react';
import Calendar from '../../../../components/Inputs/InputCalendar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import openReports from '../../../../utils/openReports';
import ProductHeaderButtons from '../../../product/header-buttons';

const ReportsMostSell = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  /** functions */
  const handleSubmit = async (d: any) => {
    setLoadingStatus(true);
    await api
      .get('/reports/products/top100', {
        params: {
          dateStart,
          dateEnd,
        },
      })
      .then(d => {
        openReports(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${d.data.fileName}`,
          '100+vendidos',
        );
      })
      .catch(e => {
        toast('error', 'Erro', e.response.data.error);
      })
      .finally(() => setLoadingStatus(false));
  };

  return (
    <>
      <ProductHeaderButtons />
      <div className="card md:w-6">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
        >
          <div className="field col-6">
            <label htmlFor="date_start">Data início</label>
            <Calendar
              name="date_start"
              value={dateStart}
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              onChange={e => setDateStart(e.value as Date)}
              showIcon
            />
          </div>
          <div className="field col-6">
            <label htmlFor="date_end">Data fim</label>
            <Calendar
              name="date_end"
              value={dateEnd}
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              onChange={e => setDateEnd(e.value as Date)}
              showIcon
            />
          </div>

          <Divider />

          <div className="field col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsMostSell;
