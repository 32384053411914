/* eslint-disable react/no-deprecated */
/* eslint-disable react/react-in-jsx-scope */
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import AccessLevelProvider from './context/AppAccessLevel';
import AppContextProvider from './context/AppContext';
import AppSettingProvider from './context/AppSettingContext';
import AppToastContext from './context/AppToastContext';
import ContextProviders from './context/ContextProviders';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { cacheTime: 10000, staleTime: 0, refetchOnWindowFocus: false },
  },
});

ReactDOM.render(
  <HashRouter>
    <AppContextProvider>
      <AppSettingProvider>
        <AccessLevelProvider>
          <QueryClientProvider client={queryClient}>
            <ContextProviders>
              <AppToastContext>
                <AppWrapper />
              </AppToastContext>
            </ContextProviders>
          </QueryClientProvider>
        </AccessLevelProvider>
      </AppSettingProvider>
    </AppContextProvider>
  </HashRouter>,
  document.getElementById('root'),
);
