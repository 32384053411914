export const uploadImageEmptyTemplate = (img_url: string) => {
  return !img_url ? (
    <>
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-0"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
      </div>
    </>
  ) : (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <img
          alt=""
          role="presentation"
          src={`${process.env.REACT_APP_SERVER_URL}/files/img/${img_url}`}
          width={100}
        />
      </div>
    </div>
  );
};
