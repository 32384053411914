import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, isDate, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { CalendarChangeParams } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { InputNumberChangeParams } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnCpfCnpj from '../../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import RenderColumnStatusPayment from '../../../components/DataTableColumns/RenderColumnStatusPayment';
import CalendarRange from '../../../components/Inputs/CalendarRange';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import {
  SideBar as SidebarDrop,
  SideBar as SidebarSearchDocuments,
  SideBar as SidebarSearchProvider,
} from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import FormatCurrency from '../../../utils/FormatCurrency';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import {
  IBillsPayable,
  IBillsPayableDropsSummary,
  IProvider,
} from '../dtos/IBillsToPayDropDTO';
import BillsToPayHeader from '../header';

const BillsToPayDrop = (): React.ReactElement => {
  const hoje = new Date();
  const [load, setLoad] = useState<boolean>(false);

  const doc: { id: string } = useParams();

  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();

  const [keyword, setKeyword] = useState<string>('');
  const [provider, setProvider] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });
  const [providers, setProviders] = useState<Array<IProvider>>([]);

  const [bill, setBill] = useState<IBillsPayable>();
  const [bills, setBills] = useState<Array<IBillsPayable>>([]);

  const [drops, setDrops] = useState<Array<IBillsPayable>>([]);

  const [dateOnPay, setDateOnPay] = useState<Date | Date[] | undefined>(hoje);
  const [juros, setJuros] = useState<number>(0);
  const [multa, setMulta] = useState<number>(0);
  const [add, setAdd] = useState<number>(0);
  const [desc, setDesc] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const [summary, setSummary] = useState<IBillsPayableDropsSummary>({
    total: 0,
    total_add: 0,
    total_desc: 0,
    total_docs: 0,
    total_juros: 0,
    total_multa: 0,
  });

  const [period, setPeriod] = useState<Date[]>([
    startOfMonth(hoje),
    endOfMonth(hoje),
  ]);

  const [sidebarDocumentsVisible, setSidebarDocumentsVisible] =
    useState<boolean>(false);
  const [sidebarDropVisible, setSidebarDropVisible] = useState<boolean>(false);
  const [sidebarProviderVisible, setSidebarProviderVisible] =
    useState<boolean>(false);

  const searchProvider = async (keyword: string) => {
    if (keyword.length >= 3) {
      setLoad(true);
      await api
        .get(`/persons/provider/src?search=${keyword}`)
        .then(({ data }) => {
          if (data.providers.length === 0) {
            toast('warn', 'Alerta', 'Nenhum cliente encontrado!');
            return;
          }
          setProviders(
            data.providers.map((i: IProvider) => {
              return i;
            }),
          );
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', err);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const handleSelectProvider = async (d: DataTableSelectionChangeParams) => {
    if (!validate(d.id)) {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar o fornecedor, tente novamente!',
      );
      return;
    }
    setProvider({
      value: d.id,
      label: d.name,
    });
    setSidebarProviderVisible(false);
  };

  const loadBillById = async (id: string) => {
    if (!validate(id)) {
      toast(
        'warn',
        'Alerta',
        'Informe um id valido para carregar o documento a ser baixado!',
      );
      return;
    }

    const hasDrop = drops.find(i => i.id === id);
    if (!hasDrop) {
      setLoad(true);
      await api
        .get(`/bills-payable/${id}`)
        .then(({ data }) => {
          if (!data.listBillPayableById) {
            toast(
              'warn',
              'Alerta',
              'Nenhuma conta a pagar foi encontrada no nome deste fornecedor!',
            );
            setBills([]);
            return;
          }

          const drop: IBillsPayable = {
            ...data.listBillPayableById,
            adicional: 0,
            desconto: 0,
            total:
              Number(data.listBillPayableById.value_total) +
              Number(data.listBillPayableById.juros) +
              Number(data.listBillPayableById.multa),
          };

          setProvider({ value: drop.provider.id, label: drop.provider.name });
          setDrops([drop]);
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  const searchBillsPayable = async () => {
    setLoad(true);

    for (const item of period) {
      if (!isDate(item)) {
        toast(
          'warn',
          'Alerta',
          'Informe um período de datas validas para a busca!',
        );
        setLoad(false);
        return;
      }
    }

    await api
      .post(`/bills-payable/v2/search`, {
        filter: {
          provider_id: provider.value,
          period: {
            start: period[0] || startOfMonth(hoje),
            end: period[1] || endOfMonth(hoje),
          },
        },
      })
      .then(({ data }) => {
        if (data.searchBillsPayableToPay.length === 0) {
          toast(
            'warn',
            'Alerta',
            'Nenhuma conta a pagar foi encontrada no nome deste fornecedor!',
          );
          setBills([]);
          return;
        }

        setBills(
          data.searchBillsPayableToPay.map((i: IBillsPayable) => {
            return {
              ...i,
              adicional: 0,
              desconto: 0,
              total: Number(i.value_total) + Number(i.juros) + Number(i.multa),
            };
          }),
        );
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', err);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleOpenSidebarSearchBills = async () => {
    setSidebarDocumentsVisible(true);
  };

  const handleClearBill = () => {
    setBill({
      id: '',
      adicional: 0,
      date_to_pay: hoje,
      date_on_pay: hoje,
      desconto: 0,
      description: '',
      document_num: '',
      document_sr: '',
      emited_day: hoje,
      juros: 0,
      multa: 0,
      parcel_sr: '',
      provider_id: '',
      status: '',
      status_payment: '',
      total: 0,
      value: 0,
      value_total: 0,
      provider: {
        id: '',
        cpf_cnpj: '',
        name: '',
        nickname: '',
      },
    });
  };

  const clearForm = () => {
    setProvider({ value: '', label: '' });
    setProviders([]);
    handleClearBill();
    setBills([]);
    setDrops([]);
    setPeriod([startOfMonth(hoje), endOfMonth(hoje)]);
  };

  const handleEditBill = () => {
    if (bill) {
      const noBillInDrops = drops.filter(i => i.id !== bill.id);

      if (bill.date_on_pay === null) {
        bill.date_on_pay = hoje;
      }

      setDrops([...noBillInDrops, bill]);

      setSidebarDropVisible(false);

      handleClearBill();
    }
  };

  const handleSetDateOnPay = (d: Date) => {
    const _b: IBillsPayable = { ...bill, date_on_pay: d };
    setBill(_b);
  };

  const handleSetJuros = (d: InputNumberChangeParams) => {
    const _j = Number(d.value);
    if (!isNaN(_j)) {
      const _b: IBillsPayable = { ...bill, juros: _j };
      setBill(_b);
    }
  };

  const handleSetMulta = (d: InputNumberChangeParams) => {
    const _m = Number(d.value);
    if (!isNaN(_m)) {
      const _b: IBillsPayable = { ...bill, multa: _m };
      setBill(_b);
    }
  };

  const handleSetAdicionais = (d: InputNumberChangeParams) => {
    const _a = Number(d.value);
    if (!isNaN(_a)) {
      const _b: IBillsPayable = { ...bill, adicional: _a };
      setBill(_b);
    }
  };

  const handleSetDescontos = (d: InputNumberChangeParams) => {
    const _d = Number(d.value);
    if (!isNaN(_d)) {
      const _b: IBillsPayable = { ...bill, desconto: _d };
      setBill(_b);
    }
  };

  const handleSubmit = async (d: any) => {
    setLoad(true);
    await api
      .post('/bills-payable/drop', {
        data: {
          bills: drops.map((i: IBillsPayable) => {
            return {
              bill_id: i.id,
              date_on_pay: new Date(i.date_on_pay || hoje),
              vlr_bill: Number(i.value_total),
              vlr_juros: Number(i.juros),
              vlr_multa: Number(i.multa),
              vlr_add: Number(i.adicional),
              vlr_desc: Number(i.desconto),
              total: Number(i.total),
            };
          }),
          total_docs: summary.total_docs,
          total_juros: summary.total_juros,
          total_multa: summary.total_multa,
          total_add: summary.total_add,
          total_desc: summary.total_desc,
          total: summary.total,
          obs: '',
        },
      })
      .then(({ data }) => {
        if (data.dropBillsPayable === true) {
          toast('success', 'Sucesso', 'Baixas realizadas com sucesso!');
          clearForm();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleAddBill = (d: IBillsPayable) => {
    if (!validate(d.id)) {
      toast('warn', 'Alerta', 'Selecione um documento válido!');
      return;
    }

    const hasBill = drops.find((i: IBillsPayable) => i.id === d.id);
    if (hasBill) {
      toast('warn', 'Alerta', 'Esta conta já foi adicionada para baixa!');
      return;
    }

    setDrops([...drops, d]);

    const filterBills = bills.filter(i => i.id !== d.id);
    setBills(filterBills);
  };

  const handleRemDrop = (id: string) => {
    if (!validate(id)) {
      toast('warn', 'Alerta', 'Selecione um documento válido!');
      return;
    }
    const filterDrops = drops.filter(i => i.id !== id);
    setDrops(filterDrops);
  };

  const handleSelectDrop = (d: DataTableSelectionChangeParams) => {
    if (!validate(d.id)) {
      toast('warn', 'Alerta', 'Selecione um documento válido!');
      return;
    }

    setSidebarDropVisible(true);

    const hasBill = drops.find(i => i.id === d.id);
    if (!hasBill) {
      toast('warn', 'Alerta', 'Documento não encontrado nas baixas!');
      return;
    }
    setBill(hasBill);
  };

  const renderRemoveDrop = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemDrop(id)}
        />
      </div>
    );
  };

  useEffect(() => {
    const summary = drops.reduce(
      (a, i) => {
        a.documentos += Number(i.value_total);
        a.juros += Number(i.juros);
        a.multa += Number(i.multa);
        a.desconto += Number(i.desconto);
        a.adicional += Number(i.adicional);
        a.total += Number(i.value_total) + Number(i.juros) + Number(i.multa);
        return a;
      },
      {
        documentos: 0,
        juros: 0,
        multa: 0,
        desconto: 0,
        adicional: 0,
        total: 0,
      },
    );

    setSummary({
      total_docs: summary.documentos,
      total_juros: summary.juros,
      total_multa: summary.multa,
      total_add: summary.adicional,
      total_desc: summary.desconto,
      total: summary.total,
    });
  }, [drops]);

  useEffect(() => {
    const total =
      Number(bill?.value_total) +
      Number(bill?.juros) +
      Number(bill?.multa) +
      Number(bill?.adicional) -
      Number(bill?.desconto);
    if (!isNaN(total)) {
      if (bill) {
        setBill({ ...bill, total: Number(total) });
      }
    }
  }, [bill?.juros, bill?.multa, bill?.adicional, bill?.desconto]);

  useEffect(() => {
    loadBillById(doc.id);
  }, [doc.id]);

  return (
    <>
      <BillsToPayHeader />
      {load && <ProgressBar mode="indeterminate" style={{ height: '4px' }} />}
      <div className="flex">
        <Fieldset legend="Totais" className="card w-2 mx-2">
          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Documentos.:</p>
            <p className="m-0 my-1 p-0">{FormatCurrency(summary.total_docs)}</p>
          </div>

          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Descontos.:</p>
            <p className="m-0 my-1 p-0">{FormatCurrency(summary.total_desc)}</p>
          </div>

          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Adicionais.:</p>
            <p className="m-0 my-1 p-0">{FormatCurrency(summary.total_add)}</p>
          </div>

          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Juros.:</p>
            <p className="m-0 my-1 p-0">
              {FormatCurrency(summary.total_juros)}
            </p>
          </div>

          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Multas.:</p>
            <p className="m-0 my-1 p-0">
              {FormatCurrency(summary.total_multa)}
            </p>
          </div>

          <div className="flex justify-content-between">
            <p className="m-0 my-1 p-0">Total.:</p>
            <p className="m-0 my-1 p-0">{FormatCurrency(summary.total)}</p>
          </div>
        </Fieldset>

        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="card w-10 mx-2"
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-6 md:col-6">
              <Button
                label={
                  validate(provider.value)
                    ? provider.label
                    : 'Buscar o fornecedor'
                }
                type="button"
                icon={`fa-solid ${
                  !validate(provider.value) ? 'fa-search' : 'fa-check'
                }`}
                iconPos="right"
                className={`p-button-outlined ${
                  provider.value === '' ? 'p-button-danger' : 'p-button-info'
                } ml-auto`}
                onClick={() => setSidebarProviderVisible(true)}
              />
            </div>

            <div className="field col-6 md:col-6">
              <Button
                label={
                  drops.length === 0
                    ? 'Buscar documentos'
                    : 'Adicionar mais documentos'
                }
                type="button"
                icon={`fa-solid ${
                  drops.length === 0 ? 'fa-search' : 'fa-check'
                }`}
                iconPos="right"
                className={`p-button-outlined ${
                  drops.length === 0 ? 'p-button-danger' : 'p-button-info'
                } ml-auto`}
                onClick={() => handleOpenSidebarSearchBills()}
                disabled={!validate(provider.value)}
              />
            </div>

            <DataTable
              value={drops}
              responsiveLayout="scroll"
              selectionMode="single"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum item encontrado!"
              loading={load}
              onSelectionChange={d => handleSelectDrop(d.value)}
              className="field col-12 md:col-12"
            >
              <Column
                field="value"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column field="provider.name" header="Fornecedor"></Column>
              <Column field="document_sr" header="Ser."></Column>
              <Column field="document_num" header="Num."></Column>
              <Column field="parcel_sr" header="Parcela"></Column>
              <Column
                field="date_to_pay"
                header="Vencimento"
                body={d => renderColumnDate(d.date_to_pay)}
              ></Column>
              <Column
                field="date_on_pay"
                header="Baixa"
                body={d => renderColumnDate(d.date_on_pay)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={d => renderColumnDecimal(d.value)}
              ></Column>
              <Column
                field="juros"
                header="Juros"
                body={d => renderColumnDecimal(d.juros)}
              ></Column>
              <Column
                field="multa"
                header="Multa"
                body={d => renderColumnDecimal(d.multa)}
              ></Column>
              <Column
                field="desconto"
                header="Desconto"
                body={d => renderColumnDecimal(d.desconto || 0)}
              ></Column>
              <Column
                field="adicional"
                header="Adicional"
                body={d => renderColumnDecimal(d.adicional || 0)}
              ></Column>
              <Column
                field="total"
                header="Total"
                body={d => renderColumnDecimal(d.total)}
              ></Column>
              <Column
                field="status_payment"
                header="Status"
                body={d =>
                  RenderColumnStatusPayment(d.status, d.status_payment)
                }
              ></Column>
              <Column header="*" body={d => renderRemoveDrop(d.id)}></Column>
            </DataTable>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Baixar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </Form>
      </div>

      <SidebarSearchProvider
        visible={sidebarProviderVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setSidebarProviderVisible(false)}
      >
        <h3>Buscar fornecedor</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="provider_keyword">Pesquisa</label>
              <InputText
                name="provider_keyword"
                placeholder="Nome do fornecedor"
                type={'text'}
                value={keyword}
                onChange={e => setKeyword(e.currentTarget.value)}
                autoFocus={sidebarProviderVisible}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={keyword.length < 3 ? true : false}
                onClick={() => searchProvider(keyword)}
                loading={load}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={providers}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={load}
                onSelectionChange={e => handleSelectProvider(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={renderColumnPosition}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="name" header="Nome"></Column>
                <Column
                  field="cpf_cnpj"
                  header="CPF/CNPJ"
                  body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SidebarSearchProvider>

      <SidebarSearchDocuments
        visible={sidebarDocumentsVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setSidebarDocumentsVisible(false)}
      >
        <h3>Documentos vencidos e a vencer</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-4">
              <label htmlFor="period.start">Período</label>
              <CalendarRange
                name="period.start"
                showIcon
                value={period}
                onChange={(e: CalendarChangeParams) =>
                  setPeriod(e.value as Date[])
                }
              />
            </div>

            <div className="field col-4 flex align-items-end justify-content-end">
              <Button
                label="Buscar"
                icon="fa-solid fa-search"
                iconPos="right"
                onClick={() => searchBillsPayable()}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <DataTable
              value={bills}
              responsiveLayout="scroll"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 30]}
              size="small"
              emptyMessage="Nenhum item encontrado!"
              loading={load}
              selectionMode="single"
              onSelectionChange={e => handleAddBill(e.value)}
              className="field col-12 md:col-12"
            >
              <Column
                field="value"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column field="provider.name" header="Fornecedor"></Column>
              <Column field="document_sr" header="Ser."></Column>
              <Column field="document_num" header="Num."></Column>
              <Column field="parcel_sr" header="Parcela"></Column>
              <Column
                field="date_to_pay"
                header="Vencimento"
                body={d => renderColumnDate(d.date_to_pay)}
              ></Column>
              <Column
                field="date_on_pay"
                header="Baixa"
                body={d => renderColumnDate(d.date_on_pay)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={d => renderColumnDecimal(d.value)}
              ></Column>
              <Column
                field="juros"
                header="Juros"
                body={d => renderColumnDecimal(d.juros)}
              ></Column>
              <Column
                field="multa"
                header="Multa"
                body={d => renderColumnDecimal(d.multa)}
              ></Column>
              <Column
                field="value_total"
                header="Total"
                body={d => renderColumnDecimal(d.value_total)}
              ></Column>
              <Column
                field="status_payment"
                header="Status"
                body={d =>
                  RenderColumnStatusPayment(d.status, d.status_payment)
                }
              ></Column>
              <Column field="" header="*"></Column>
            </DataTable>
          </div>
        </Form>
      </SidebarSearchDocuments>

      <SidebarDrop
        visible={sidebarDropVisible}
        // visible={true}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setSidebarDropVisible(false)}
      >
        <h3>Editar baixa</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-10">
              <label htmlFor="provider.name">Fornecedor</label>
              <InputText
                name="provider.name"
                value={bill?.provider?.name || ''}
                disabled
                readOnly
              />
            </div>

            <div className="field col-2">
              <label htmlFor="date_on_pay">Data baixa</label>
              <Calendar
                name="date_on_pay"
                value={bill?.date_on_pay || hoje}
                onChange={e => handleSetDateOnPay(e.value as Date)}
                dateFormat="d/m/y"
                maxDate={hoje}
                showIcon
              />
            </div>

            <div className="field col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                name="value"
                value={bill?.value}
                minFractionDigits={2}
                disabled
                readOnly
              />
            </div>

            <div className="field col-2">
              <label htmlFor="value_total">Restante</label>
              <InputNumber
                name="value_total"
                value={bill?.value_total}
                minFractionDigits={2}
                disabled
                readOnly
              />
            </div>

            <div className="field col-2">
              <label htmlFor="juros">Juros</label>
              <InputNumber
                name="juros"
                value={bill?.juros}
                onChange={e => handleSetJuros(e)}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-2">
              <label htmlFor="multa">Multa</label>
              <InputNumber
                name="multa"
                value={bill?.multa}
                onChange={e => handleSetMulta(e)}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-2">
              <label htmlFor="adicional">Adicionais</label>
              <InputNumber
                name="adicional"
                value={bill?.adicional || 0}
                onChange={e => handleSetAdicionais(e)}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-2">
              <label htmlFor="desconto">Descontos</label>
              <InputNumber
                name="desconto"
                value={bill?.desconto || 0}
                onChange={e => handleSetDescontos(e)}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-2">
              <label htmlFor="total">Total</label>
              <InputNumber
                name="total"
                value={bill?.total}
                placeholder="0,00"
                minFractionDigits={2}
                disabled
                readOnly
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                onClick={() => {
                  handleClearBill();
                }}
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Editar"
                icon="pi pi-check"
                onClick={() => handleEditBill()}
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </Form>
      </SidebarDrop>
    </>
  );
};

export default BillsToPayDrop;
