import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import useToastContext from '../../../hooks/toast';
import formatCurrency from '../../../utils/FormatCurrency';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { FastFormPerson } from '../../person/fast-form-person';
import OrderHeaderButtons from '../header-buttons';
import OrderSidebarProposal from '../order-sidebar-proposals';
import { OrderSidebarResume } from '../order-sidebar-resume';
import { emptySummary, ISummary } from './dtos/IOrderDTO';
import './custom.css';
import { useOrder } from '../../../hooks/useOrder';
import InputRowProductOrder from '../../../components/input-row/product-order';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { Tooltip } from 'primereact/tooltip';
import Progress from '../../../components/progress-bar';
import { EnumBehavior } from '../../../enum/EnumBehavior';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

const OrderForm: React.FC = () => {
  /** refs & focus */

  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);

  /** states */
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [summary, setSummary] = useState<ISummary>(emptySummary);

  const params: { id: string } = useParams();

  const {
    order,
    loadContext,
    commissioneds,
    natOpes,
    stores,
    tablePrices,
    clearData,
    fetchProposal,
    loadNextOrderSequence,
    setOrderHeader,
    setOrder,
  } = useOrder();

  /** effects */
  useEffect(() => {
    calculateOrder();
  }, [order.orderBody]);

  useEffect(() => {
    if (order.orderPayments.length > 0) {
      const totalPayments = order.orderPayments.reduce((a, i) => {
        a += Number(i.value_parcel);
        return a;
      }, 0);

      if (Number(order.orderHeader.order_total) > Number(totalPayments)) {
        setOrder({ ...order, orderPayments: [] });
        toast(
          'info',
          'Alerta',
          'O valor total do pedido mudou. Por favor, informe os pagamentos novamente.',
        );
      }
    }
  }, [order.orderHeader.order_total]);

  /** functions */
  const calculateOrder = () => {
    const _summary = order.orderBody.reduce(
      (a, i) => {
        const total = Number(i.vlr_liq) * Number(i.qnt);

        a.qntProdutos += 1;
        a.qntItems += Number(i.qnt);
        a.totalVenda += Number(i.vlr_unit) * Number(i.qnt);
        a.totalDescontos += Number(i.desc) * Number(i.qnt);
        a.totalAdicionais += Number(i.adic) * Number(i.qnt);
        a.totalJuros = 0;
        a.totalCommission += 0;
        a.total += Number(total);
        return a;
      },
      {
        qntProdutos: 0,
        qntItems: 0,
        totalVenda: 0,
        totalDescontos: 0,
        totalAdicionais: 0,
        totalJuros: 0,
        totalCommission: 0,
        total: 0,
      },
    );

    setSummary({ ..._summary });
    setOrderHeader({
      ...order.orderHeader,
      order_value: _summary.totalVenda,
      order_total: _summary.total,
    });
  };

  interface INatOpe {
    label: string;
    value: string;
    add_info: string;
  }

  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);

  const [sidebarResumeVisible, setSidebarResumeVisible] =
    useState<boolean>(false);
  const [sidebarProposalVisible, setSidebarProposalVisible] =
    useState<boolean>(false);
  const [sidebarCustomerVisible, setSidebarCustomerVisible] = useState(false);

  const handleChangeObs = (obs: string) => {
    setOrder({
      ...order,
      orderHeader: {
        ...order.orderHeader,
        obs,
      },
      orderTransporter: {
        ...order.orderTransporter,
        obs,
      },
    });
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      d.commissioned = order.orderHeader.commissioned_id;
      d.nat_ope = order.orderHeader.natureza_operacao_id;
      d.store = order.orderHeader.store_id;
      d.customer =
        order.orderHeader.customer_id !== null
          ? order.orderHeader.customer_id
          : '';
      d.items = order.orderBody;

      const validation = Yup.object({
        commissioned: Yup.string()
          .uuid('Informe o vendedor/comissionado para venda!')
          .required('Informe o vendedor/comissionado para venda!'),
        nat_ope: Yup.string()
          .uuid('Informe a natureza de operação da venda!')
          .required('Informe a natureza de operação da venda!'),
        store: Yup.string()
          .uuid('Informe a loja onde a venda foi feita!')
          .required('Informe a loja onde a venda foi feita!'),
        customer: Yup.string()
          .uuid('Informe qual o cliente do pedido/proposta!')
          .required('Informe qual o cliente do pedido/proposta!'),
        items: Yup.array()
          .min(1, 'Informe pelo menos 1 produto no pedido/proposta!')
          .required('Informe pelo menos 1 produto no pedido/proposta!'),
      });
      await validation.validate(d, { abortEarly: false });

      setSidebarResumeVisible(true);
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Erro', err.errors[0]);
      } else {
        toast('error', 'Erro', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    if (params.id && validate(params.id)) {
      fetchProposal(params.id);
    } else {
      loadNextOrderSequence();
    }
  }, []);

  /** templates */

  const itemTemplateCustomer = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  return (
    <>
      <OrderHeaderButtons />
      <div className="card w-full m-0">
        <Progress isLoad={isLoad || loadContext} className="mb-2" />

        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgroup"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="field col-12 md:col-4 mb-0 pb-0">
            <label htmlFor="commissioned">Vendedor/Comissionado</label>
            <InputDropDown
              name="commissioned"
              value={order.orderHeader.commissioned_id}
              options={
                commissioneds
                  ? commissioneds.map(c => {
                      return {
                        value: c.id,
                        label: c.name,
                      };
                    })
                  : []
              }
              placeholder="Selecionar..."
              onChange={e =>
                setOrderHeader({
                  ...order.orderHeader,
                  commissioned_id: e.value,
                })
              }
              filter
              required
            />
          </div>

          <div className="field col-12 md:col-4 mb-0 pb-0">
            <label htmlFor="nat_ope">Natureza operação</label>
            <InputDropDown
              name="nat_ope"
              value={order.orderHeader.natureza_operacao_id}
              options={
                natOpes
                  ? natOpes.map(n => {
                      return {
                        value: n.id,
                        label: n.title,
                      };
                    })
                  : []
              }
              placeholder="Selecionar..."
              onChange={e => {
                setOrderHeader({
                  ...order.orderHeader,
                  natureza_operacao_id: e.value,
                  obs: natOpes
                    ? natOpes.find(n => n.id === e.value)?.info_adicional || ''
                    : '',
                });
              }}
              filter
              required
            />
          </div>

          <div className="field col-6 md:col-2 mb-0 pb-0">
            <label htmlFor="store">Loja</label>
            <InputDropDown
              name="store"
              value={order.orderHeader.store_id}
              options={
                stores
                  ? stores.map(s => {
                      return {
                        value: s.id,
                        label: s.title,
                      };
                    })
                  : []
              }
              placeholder="Selecionar..."
              onChange={e =>
                setOrderHeader({ ...order.orderHeader, store_id: e.value })
              }
              filter
              required
            />
          </div>

          <div className="field col-6 md:col-2 mb-0 pb-0">
            <label htmlFor="sequence">Sequência</label>
            <InputNumber
              name="sequence"
              value={order.orderHeader.sequence}
              placeholder="000"
              readOnly
              disabled
              className="surface-200"
            />
          </div>

          <Tooltip target=".icon-sidebar-person" />

          <div className="field col-12 md:col-5 mb-1 pb-1 mb-0 pb-0">
            <label htmlFor="customer">
              Cliente
              <i
                className={`pi ${
                  validate(order.orderHeader.customer_id)
                    ? 'pi-search text-blue-500'
                    : 'pi-plus-circle text-green-500'
                } ml-2 cursor-pointer icon-sidebar-person`}
                onClick={() => setSidebarCustomerVisible(true)}
                data-pr-tooltip={
                  validate(order.orderHeader.customer_id)
                    ? 'Consultar dados do cliente selecionado'
                    : 'Cadastrar novo cliente'
                }
                data-pr-position="right"
              ></i>
            </label>
            <InputAutoComplete
              name="customer"
              suggestions={customers}
              value={order.orderHeader.customer}
              completeMethod={e => searchCustomer(e.query)}
              field="label"
              onChange={e => {
                setOrderHeader({
                  ...order.orderHeader,
                  customer: e.value,
                  customer_id: e.value.value,
                });
              }}
              itemTemplate={itemTemplateCustomer}
              placeholder="Buscar cliente..."
            />
          </div>

          <div className="field col-6 md:col-3">
            <label htmlFor="table_price">Tabela de preços</label>
            <InputDropDown
              name="table_price"
              value={order.orderHeader.table_price_id}
              options={
                tablePrices
                  ? tablePrices.map(t => {
                      return {
                        value: t.id,
                        label: t.title,
                      };
                    })
                  : []
              }
              placeholder="Selecionar..."
              onChange={e =>
                setOrderHeader({
                  ...order.orderHeader,
                  table_price_id: e.value,
                })
              }
              filter
              tooltip="Selecione a tabela de preços padrão para os itens desse pedido"
              tooltipOptions={{ position: 'top' }}
            />
          </div>

          <div className="field col-6 md:col-4 flex justify-content-end align-items-end">
            <Button
              type="button"
              label="Propostas"
              loading={isLoad || loadContext}
              className="p-button-info"
              icon="fa-solid fa-magnifying-glass"
              onClick={() => setSidebarProposalVisible(true)}
            />
          </div>
        </Form>

        <InputRowProductOrder
          show={{
            title: true,
            obs: true,
          }}
          setup={{
            isKeyboard: false,
          }}
          className="p-2"
        />

        <div className="p-fluid grid formgroup">
          <div className="field col-12 md:col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">RESUMOS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Aliq. comissão:</div>
              <div className="flex">0,00</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Valor comissão:</div>
              <div className="flex">0,00</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt. produtos:</div>
              <div className="flex">
                {formatCurrency(summary?.qntProdutos || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt. itens: </div>
              <div className="flex">
                {formatCurrency(summary?.qntItems || 0)}
              </div>
            </div>
          </div>

          <div className="field col-12 md:col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">ANOTAÇÕES</span>
              </div>
            </Divider>
            <Form
              ref={null}
              placeholder={''}
              onSubmit={() => {}}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              <InputTextArea
                name="obs"
                value={order.orderHeader.obs}
                onChange={e => handleChangeObs(e.currentTarget.value)}
                placeholder="Obs.:"
                className="flex w-full"
                style={{ maxHeight: '150px', minHeight: '120px' }}
                maxLength={1024}
                tooltip="Esse campo é usado no preenchimento das informações complementares da NF-e"
              />
            </Form>
          </div>

          <div className="field col-12 md:col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">TOTAIS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Total produtos:</div>
              <div className="flex">
                {formatCurrency(summary?.totalVenda || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Adicionais:</div>
              <div className="flex">
                {formatCurrency(summary?.totalAdicionais || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Juros:</div>
              <div className="flex">
                {formatCurrency(summary?.totalJuros || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Descontos:</div>
              <div className="flex">
                {formatCurrency(summary?.totalDescontos || 0)}
              </div>
            </div>
            <div
              className="flex justify-content-between flex-wrap my-2 py-2 font-bold"
              style={{ borderTop: '0.2rem dashed var(--surface-500)' }}
            >
              <div className="flex">Total:</div>
              <div className="flex">{formatCurrency(summary?.total || 0)}</div>
            </div>
          </div>

          <div className="field col-12 md:col-6 mb-0 pb-0">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad || loadContext}
              onClick={() => clearData()}
            />
          </div>

          <div className="field col-12 md:col-6 mb-0 pb-0">
            <Button
              label="Confirmar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              onClick={() => formRef.current?.submitForm()}
              loading={isLoad || loadContext}
            />
          </div>

          <OrderSidebarResume
            isOpen={sidebarResumeVisible}
            onRequestClose={() => setSidebarResumeVisible(false)}
          />

          <OrderSidebarProposal
            isOpen={sidebarProposalVisible}
            onRequestClose={() => setSidebarProposalVisible(false)}
            id={null}
          />

          <FastFormPerson
            isOpen={sidebarCustomerVisible}
            onRequestClose={() => setSidebarCustomerVisible(false)}
            behavior={
              validate(order.orderHeader.customer_id)
                ? EnumBehavior.UPDATE
                : EnumBehavior.CREATE
            }
            personId={order.orderHeader.customer_id}
            personType="C"
          />
        </div>
      </div>
    </>
  );
};

export default OrderForm;
