import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Toast } from 'primereact/toast';
import api from '../../../services/api';

/** menu options */

const CstList = (): React.ReactElement => {
  /** state */

  const [globalFilter, setGlobalFilter] = useState('');

  const [cst, setCst] = useState<Array<any>>([]);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** ref */
  const toast = useRef<any>(null);

  /* refs */

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const statusBodyTemplate = () => {
    return (
      <div className="flex">
        <Checkbox checked={true}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = () => {
    return (
      <div className="flex justify-content-between">
        <Button label="Editar" className="p-button-raised mr-4" />
        <Button label="Excluir" className="p-button-raised p-button-danger" />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">CSTs</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchTribCst = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(`/trib-cst/icms`);

      setCst(data);
    } catch (error: any) {
      toast.current.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchTribCst();
  }, []);

  return (
    <>
      <div className="card">
        <Toast ref={toast} />
        <DataTable
          value={cst}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} cst"
          globalFilter={globalFilter}
          emptyMessage="Não existe Cst."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="id" header="N°" body={codeBodyTemplate}></Column>
          <Column field="codigo" header="Código"></Column>
          <Column field="descricao" header="Descrição"></Column>
        </DataTable>
      </div>
    </>
  );
};

export default CstList;
