import React, { useRef, useEffect, useState } from 'react';

/** Form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/** types */
import { Option } from '../../../../types/optional';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../../services/api';

/** utils */
import getValidationErrors from '../../../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { Toast } from 'primereact/toast';

const typeAccount = [
  { label: 'Poupança', value: 'POUPANÇA' },
  { label: 'Corrente', value: 'CORRENTE' },
];

const AccountEdit = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** banks */
  const [banksOptions, setBanksOptions] = useState<Option[]>([]);
  const [selectedBank, setSelectedBank] = useState();

  /** typeAccount */
  const [selectedTypeAccount, setSelectedTypeAccount] = useState();

  /** navigate */
  const navigate = useHistory();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** params */
  const params: { id: string } = useParams();

  const handleSetup = async (data: any) => {
    try {
      const schema = Yup.object().shape({
        conta_codigo_banco: Yup.string().required('Banco é Obrigatório!'),
        conta_agencia: Yup.string().required('Agência é Obrigatório!'),
        conta_agencia_dv: Yup.string().required('Digito é Obrigatório'),
        conta_numero: Yup.string().required('Conta é Obrigatório'),
        conta_numero_dv: Yup.string().required('Digito é Obrigatório'),
        conta_tipo: Yup.string().required('Tipo de conta é Obrigatório'),
        conta_codigo_beneficiario: Yup.string().required(
          'Beneficiario Obrigatório',
        ),
      });
      await schema.validate(data, { abortEarly: false });

      await api.put(`/v2/boleto/conta/${params.id}`, { ...data });

      navigate.push('/boleto/v1/conta/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    api.get('/bancos').then(data => {
      const banks = data.data.map((bank: any) => {
        return {
          label: `${bank.codigo} - ${bank.nome}`,
          value: bank.codigo,
        };
      });
      setBanksOptions(banks);
    });
    api.get(`/v2/boleto/conta/${params.id}`).then(({ data }) => {
      setSelectedBank(data.conta?.conta_codigo_banco);
      setSelectedTypeAccount(data.conta?.conta_tipo);
      form.current?.setData({
        conta_agencia: data.conta?.conta_agencia,
        conta_agencia_dv: data.conta?.conta_agencia_DV,
        conta_numero: data.conta?.conta_numero,
        conta_numero_dv: data.conta?.conta_numero_DV,
        conta_codigo_beneficiario: data.conta?.conta_codigo_beneficiario,
      });
    });
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/boleto/v1/conta/list')}
      />
      <Toast ref={toast} />
      <div className="card md:w-7 sm:w-full">
        <Form ref={form} onSubmit={handleSetup}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Banco</label>
              <InputDropDown
                name="conta_codigo_banco"
                placeholder="Selecionar..."
                options={banksOptions}
                filter
                value={selectedBank}
                onChange={e => setSelectedBank(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Agência</label>
              <InputText
                name="conta_agencia"
                keyfilter="int"
                placeholder="0000"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Digito</label>
              <InputText
                name="conta_agencia_dv"
                placeholder="0"
                maxLength={2}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Conta</label>
              <InputText
                name="conta_numero"
                keyfilter="int"
                placeholder="00000"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Digito</label>
              <InputText name="conta_numero_dv" maxLength={2} placeholder="0" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Tipo conta</label>
              <InputDropDown
                name="conta_tipo"
                options={typeAccount}
                value={selectedTypeAccount}
                placeholder="Selecionar..."
                onChange={e => setSelectedTypeAccount(e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="dropdown">Código Beneficiário</label>
              <InputText
                name="conta_codigo_beneficiario"
                keyfilter="int"
                placeholder="0000"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 ">
            <div className="field col-12 md:col">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AccountEdit;
