import React, { useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** form */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { InputText } from '../../../components/Inputs/InputText';
import WalletHeader from '../header';

const WalletCreate = (): React.ReactElement => {
  /** ref  */
  const form = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** navigate */
  const navigate = useHistory();

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** handle */
  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      form.current?.setErrors({});
      const schemaWallet = Yup.object({
        title: Yup.string().required('Por favor, informe o título!'),
      });
      await schemaWallet.validate(data, { abortEarly: false });
      await api.post('/wallets', data);
      navigate.push('/wallet/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <WalletHeader />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-6 md:w-full"
        />
      )}
      <div className="card md:w-6 md:w-full">
        <Toast ref={toast} />
        <Form ref={form} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="dropdown">Título</label>
              <InputText name="title" placeholder="Título da carteira" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
                onClick={() => navigate.push('/wallet/list')}
              />
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Salvar" loading={loadingStatus} />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default WalletCreate;
