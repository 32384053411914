import { v4 } from 'uuid';
import { IPaymentCondition } from '../../../paymentCondition/dtos/IPaymentCondition';

const today = new Date();

export interface IOrderHeader {
  order_id: string;
  order_type: string;
  sequence?: number;
  order_date?: Date;

  store_id: string | null;
  customer_id: string;
  customer?: {
    value: string;
    label: string;
  };
  commissioned_id: string | null;
  natureza_operacao_id: string | null;
  order_origin_id: string | null;
  payment_condition_id?: string;
  /** Este campo está sendo mantido apenas para lidar com pedidos
   * feitos antes da atualização */

  fit_descount: number;
  discount_total: number;

  fit_additional: number;
  additional_total: number;

  order_value: number;

  order_total: number;

  juros_total: number;

  obs: string;
  doc_key: string | null;

  table_price_id?: string;

  other_expenses: number;
}

export const emptyOrderHeader: IOrderHeader = {
  order_id: v4(),
  order_type: 'PED',
  order_date: today,
  store_id: null,
  sequence: 0,
  customer_id: '',
  customer: {
    value: '',
    label: '',
  },
  commissioned_id: null,
  natureza_operacao_id: null,
  order_origin_id: null,
  fit_descount: 0,
  discount_total: 0,
  fit_additional: 0,
  additional_total: 0,
  order_value: 0,
  order_total: 0,
  juros_total: 0,
  obs: '',
  doc_key: null,
  table_price_id: '',
  other_expenses: 0,
};

export interface IOrderBody {
  id: string;
  position: number;
  sku: string;
  product_id: string;
  product_description: string;
  stock: number;
  table_price_id: string;
  table_price_description: string;
  qnt_out: number;
  price_out: number;
  qnt: number;
  vlr_unit: number;
  tt_unit: number;
  obs: string | null;
  image_url?: string | null;

  desc: number;
  tt_desc: number;

  adic: number;
  tt_adic: number;

  ali_juros: number;
  juros: number;
  tt_juros: number;

  vlr_liq: number;
  vlr_liq_total: number;

  inserted_at: Date;
}

export const emptyOrderBody: IOrderBody = {
  id: '',
  position: 0,
  sku: '',
  product_id: '',
  product_description: '',
  stock: 0,
  table_price_id: '',
  table_price_description: '',
  qnt_out: 0,
  price_out: 0,
  qnt: 0,
  vlr_unit: 0,
  tt_unit: 0,
  desc: 0,
  tt_desc: 0,
  adic: 0,
  tt_adic: 0,
  ali_juros: 0,
  juros: 0,
  tt_juros: 0,
  vlr_liq: 0,
  vlr_liq_total: 0,
  inserted_at: today,
  obs: null,
  image_url: null,
};

export interface IOrderTransporter {
  transporter_id: string | null;
  vehicle_plate: string | null;
  driver: string | null;
  delivery_date: Date | Date[] | undefined;
  kind_freight: string;
  quantity: number;
  kind: string | null;
  brand: string | null;
  seal_number: string | null;
  weight_brute: number;
  weight_liquid: number;
  cubage: number;
  obs: string | null;
  generate_promissory?: boolean;
  emit_nfe?: boolean;
  print_order?: boolean;
  transporter_cost: number;
}

export const emptyOrderTransporter: IOrderTransporter = {
  transporter_id: null,
  vehicle_plate: null,
  driver: null,
  delivery_date: today,
  kind_freight: 'OUT',
  quantity: 0,
  kind: null,
  brand: null,
  seal_number: null,
  weight_brute: 0,
  weight_liquid: 0,
  cubage: 0,
  obs: null,
  generate_promissory: false,
  emit_nfe: false,
  print_order: true,
  transporter_cost: 0,
};

export interface IOrderPayment {
  id: string;
  value_parcel: number;
  parcel: number;
  group_id?: string /** Este campo será usado quando a condição de 
  pagamento tiver mais de uma parcela para facilitar a manipulação */;
  payment_condition_id: string;
  payment_condition: Partial<IPaymentCondition> | undefined;

  due_date: Date;
  description: string;
  first_parcel?: Date;
}

export const emptyOrderPayment: IOrderPayment = {
  id: '',
  value_parcel: 0,
  parcel: 0,
  payment_condition_id: '',
  payment_condition: undefined,
  due_date: today,
  description: '',
  first_parcel: undefined,
};

export interface IOrderDTO {
  orderHeader: IOrderHeader;
  orderBody: IOrderBody[];
  orderTransporter: IOrderTransporter;
  orderPayments: Partial<IOrderPayment>[];
}

export const emptyOrder: IOrderDTO = {
  orderHeader: emptyOrderHeader,
  orderBody: [],
  orderTransporter: emptyOrderTransporter,
  orderPayments: [],
};

export interface ISummary {
  qntProdutos: number;
  qntItems: number;
  totalVenda: number;
  totalDescontos: number;
  totalAdicionais: number;
  totalJuros: number;
  totalCommission: number;
  total: number;
}

export const emptySummary: ISummary = {
  qntProdutos: 0,
  qntItems: 0,
  totalVenda: 0,
  totalDescontos: 0,
  totalAdicionais: 0,
  totalJuros: 0,
  totalCommission: 0,
  total: 0,
};

export interface IProposal {
  id: string;
  order: number;
  name: string;
  order_date: Date;
  order_total: number;
}
