import styled from 'styled-components';

export const Tabs = styled.div<{
  classEnabled: boolean;
  enableBefore: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 39.89px;
  background-color: var(--surface-ground);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  justify-content: center;
  cursor: pointer;
  border-right: 0;
  outline: none;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-bottom: 20px solid #fff;
    border-left: ${props =>
    props.classEnabled
      ? '20px solid var(--primary-color)'
      : '20px solid #fff'};
    content: '';
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: ${props =>
    props.enableBefore && '20px solid var(--surface-ground)'};
    content: '';
  }
`;
