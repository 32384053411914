import { Button } from 'primereact/button';
import React from 'react';
import { useHistory } from 'react-router-dom';

const VariationItemsHeader = (): React.ReactElement => {
  const routes = useHistory();
  return (
    <>
      <Button
        label="Variações"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => routes.push('/variations-items/list')}
      />

      <Button
        label="Variação"
        icon="pi pi-plus"
        className="mb-2 mr-2"
        onClick={() => routes.push('/variations/create')}
      />

      <Button
        label="Item variação"
        icon="pi pi-plus"
        className="mb-2 mr-2"
        onClick={() => routes.push('/variations-items/create')}
      />
    </>
  );
};

export default VariationItemsHeader;
