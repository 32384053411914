export default function ClearName(name: string): string {
  const firstName = name.split(' ')[0];
  const lastName: string = name.split(' ')[1];
  if (lastName) {
    const lastNameAbrev =
      lastName.length > 1
        ? ` ${lastName.substring(0, 1)}.`
        : lastName.length === 1
        ? ` ${lastName}.`
        : '';
    return firstName + lastNameAbrev;
  }
  return firstName;
}
