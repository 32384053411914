import React, { useCallback } from 'react';

/** components */
import { InputText } from '../InputText';

import { InputTextProps } from 'primereact/inputtext';

/* props inputText */
interface PropsInput extends InputTextProps {
  name: string;
}

export const InputPhone: React.FC<PropsInput> = props => {
  const phone = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.maxLength = 15;
    let { value } = e.currentTarget;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{1})(\d)/, '($1$2) ');
    value = value.replace(/(\d{4,5})(\d{4})/, '$1-$2');

    e.currentTarget.value = value;
    return e.bubbles;
  };

  const applyMask = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    phone(e);
  }, []);

  return <InputText {...props} onKeyUp={applyMask} />;
};
