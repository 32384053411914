import React, { useRef } from 'react';

import { Button } from 'primereact/button';
import { Menu, MenuProps } from 'primereact/menu';

interface MenuPropsPopUp extends MenuProps {
  rowData: any;
  setData: React.SetStateAction<any>;
}

const MenuPopUp: React.FC<MenuPropsPopUp> = ({
  rowData,
  setData,
  ...props
}) => {
  const menu = useRef<Menu>(null);

  return (
    <>
      <Menu {...props} popup ref={menu} />
      <Button
        icon="pi pi-bars"
        type="button"
        onClick={event => {
          setData(rowData);
          menu.current?.toggle(event);
        }}
      />
    </>
  );
};

export default MenuPopUp;
