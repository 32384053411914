import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** types */
import { CostCenterModel } from '../../../types/cost';

/** services */
import api from '../../../services/api';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** empty cost center */

const emptyCrediary = {
  b_id: '',
  created_at: '',
  description: '',
  id: '',
  status: '',
  title: '',
  updated_at: '',
};

const CrediaryList = (): React.ReactElement => {
  /** state */
  const [crediarys, setCrediarys] = useState<CostCenterModel[]>([]);
  const [crediary, setCrediary] = useState(emptyCrediary);
  const [globalFilter, setGlobalFilter] = useState('');

  /** state dialog */
  const [dialogDeleteCrediaryDialog, setDialogDeleteCrediary] = useState(false);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  /** loading */
  const [loading, setLoading] = useState(true);

  /** delete dialog */

  const hideDeleteCrediaryDialog = () => {
    setDialogDeleteCrediary(false);
  };

  const confirmDeleteCostCenter = (crediary: any) => {
    setCrediary(crediary);
    setDialogDeleteCrediary(true);
  };

  const deleteCostCenterApi = async () => {
    try {
      await api.delete(`/crediaries/${crediary.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteComissioned = () => {
    const _costCenter = crediarys.filter(val => val.id !== crediary.id);
    setCrediarys(_costCenter);
    setDialogDeleteCrediary(false);
    setCrediary(emptyCrediary);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteCostCenterApi();
  };

  const deleteCrediaryDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteCrediaryDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteComissioned}
      />
    </React.Fragment>
  );

  /** table column */

  const codeBodyTemplate = (rowData: CostCenterModel, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const statusBodyTemplate = (rowData: CostCenterModel) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: CostCenterModel) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised"
          onClick={() => navigate.push(`/crediaries/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteCostCenter(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Crediário</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchCrediaries = async () => {
    try {
      const { data } = await api.get('/crediaries');
      setCrediarys(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response.data.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchCrediaries();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/crediaries/create')}
      />
      <div className="card">
        <DataTable
          value={crediarys}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} crediário"
          globalFilter={globalFilter}
          emptyMessage="Não existe Crediário."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            header="N°"
            sortable
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column field="description" header="Descrição"></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteCrediaryDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteCrediaryDialogFooter}
        onHide={hideDeleteCrediaryDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {crediary && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{crediary?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CrediaryList;
