import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Dialog } from '../../Modal';

interface IProps {
  videoId: string;
}

const ButtonHelp = ({ videoId }: IProps): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Button
        label="Ajuda"
        className="mb-2 mr-2"
        icon="fa-solid fa-circle-question"
        onClick={() => setVisible(true)}
      />

      <Dialog
        header="Ajuda"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        <iframe
          width="100%"
          height={512}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen={false}
        ></iframe>
      </Dialog>
    </>
  );
};

export default ButtonHelp;
