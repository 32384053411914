import React from 'react';

const RenderColumnStatusBills = (status: string): React.ReactElement => {
  return (
    <>
      {status === 'A' && (
        <span className={`product-badge status-instock`}>Ok</span>
      )}
      {status === 'C' && (
        <span className={`product-badge status-outofstock`}>C</span>
      )}
    </>
  );
};

export default RenderColumnStatusBills;
