import React, { useEffect, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/** utils */
import { format } from 'date-fns';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';

/** menu options */

const TablePriceList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** table prices */
  const [tablePrices, setTablePrices] = useState<Array<any>>([]);
  const [tablePrice, setTablePrice] = useState<any>();

  /* dialog */
  const [deleteTablePriceDialog, setDeleteTablePriceDialog] = useState(false);

  /* navigate */
  const navigate = useHistory();

  /** loading */
  const [loading, setLoading] = useState(true);

  /** toast */
  const toast = useToastContext();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const dateBodyTemplate = (rowData: any, column: string) => {
    return (
      <>
        <span>{format(new Date(rowData[column]), 'dd/MM/yyyy')}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return <Checkbox checked={rowData.status === 'A'} />;
  };

  const confirmDeleteTablePrice = (store: any) => {
    setTablePrice(store);
    setDeleteTablePriceDialog(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/table-prices/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteTablePrice(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Tabelas de Preço</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  /** dialog */

  const hideDeleteTablePriceDialog = () => {
    setDeleteTablePriceDialog(false);
  };

  const deleteTablePriceApi = async () => {
    try {
      const res = await api.delete(`/table-prices/${tablePrice.id}`);

      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso', 'Tabela de preços deletada com sucesso!');
      }
    } catch (error) {
      toast('error', 'Falha', 'Não foi possível deletar a tabela de preços!');
    }
  };

  const deleteTablePrice = () => {
    const _tablePrices = tablePrices.filter(
      (val: any) => val.id !== tablePrice.id,
    );
    setTablePrices(_tablePrices);
    setDeleteTablePriceDialog(false);
    setTablePrice(undefined);
    deleteTablePriceApi();
  };

  const deleteTablePriceDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteTablePriceDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteTablePrice}
      />
    </React.Fragment>
  );

  const searchTablePrice = async () => {
    try {
      const { data } = await api.post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A', 'C'] },
      });
      setTablePrices(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast('error', 'Falha', error.response?.data?.error);
    }
  };

  useEffect(() => {
    searchTablePrice();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/table-prices/create')}
      />
      <div className="card">
        <DataTable
          value={tablePrices}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} tabela de preços"
          globalFilter={globalFilter}
          emptyMessage="Não existe tabelas."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column
            header="Início"
            field="date_on"
            body={rowData => dateBodyTemplate(rowData, 'date_on')}
          ></Column>
          <Column
            field="date_off"
            header="Expiração"
            body={rowData => dateBodyTemplate(rowData, 'date_off')}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteTablePriceDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteTablePriceDialogFooter}
        onHide={hideDeleteTablePriceDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {tablePrice && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{tablePrice?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default TablePriceList;
