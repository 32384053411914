import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDate from '../../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../../../../components/DataTableColumns/RenderColumnShortName';
import renderColumnStatus from '../../../../components/DataTableColumns/RenderColumnStatus';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import InputNumberRange from '../../../../components/Inputs/InputNumberRange';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { InputText } from '../../../../components/Inputs/InputText';
import MenuPopUp from '../../../../components/MenuPopPup';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import ConsignedTopMenu from '../../top-menu';
import { IRouteList } from '../dtos/IRouteDTO';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const ConsignedRouteList: React.FC = () => {
  const hoje = new Date();

  const router = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [routes, setRoutes] = useState<IRouteList[]>([]);

  const [keyword, setKeyword] = useState<string | null>('');
  const [block, setBlock] = useState<boolean | null>(null);
  const [sequence, setSequence] = useState<number | null>(null);
  const [totalInterval, setTotalInterval] = useState<number[]>([
    -100000.0, 100000.0,
  ]);
  const [period, setPeriod] = useState<Date[]>([
    startOfMonth(hoje),
    endOfMonth(hoje),
  ]);

  const handleSubmit = async (d: any) => {
    setLoad(true);
    await api
      .post('/consigned/route/search', {
        data: {
          keyword: keyword || null,
          filters: {
            block: block || null,
            sequence: sequence || null,
            total: [
              totalInterval[0] === null ? -100000.0 : totalInterval[0],
              totalInterval[1] === null ? 100000.0 : totalInterval[1],
            ],
            period: {
              start: period[0],
              end: period[1],
            },
          },
          show: {
            page: 1,
            perPage: 100,
            status: 'T',
          },
        },
      })
      .then(({ data }) => {
        if (data) {
          setRoutes(
            data.map((i: any) => {
              return {
                id: i.id,
                b_id: '',
                sequence: i.sequence,
                customer_id: i.customer_id,
                date_open: i.date_open,
                date_prevision: i.date_prevision,
                date_close: i.date_close,
                total_order: Number(i.total_order),
                total_devolution: Number(i.total_devolution),
                total_gift: Number(i.total_gift),
                total: Number(i.total),
                obs: '',
                block: i.block,
                user_id: i.user_id,
                status: i.status,
                customer: {
                  id: i.customer_id,
                  b_id: '',
                  codigo: 0,
                  customer: '',
                  name: i.customer_name,
                  cpf_cnpj: '',
                  block: '',
                  image_url: '',
                  status: '',
                  last_transaction: null,
                  type: null,
                },
                user: {
                  id: i.user_id,
                  b_id: '',
                  name: i.user_name,
                  status: '',
                },
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
        setRoutes([]);
      })
      .finally(() => setLoad(false));
  };

  const loadRoutes = async () => {
    setLoad(true);
    await api
      .post('/consigned/route/list', {
        data: { page: 1, perPage: 1000, block: null, status: 'T' },
      })
      .then(({ data }) => {
        if (data) {
          setRoutes(data);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleCancelRoute = async (id: string) => {
    setLoad(true);
    await api
      .delete(`/consigned/route/${id}`)
      .then(async ({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Rota cancelada com sucesso!');
          await loadRoutes();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const renderCollumnBlock = (block: boolean) => {
    return (
      <>
        {block === true && <i className="fa-solid fa-lock"></i>}

        {block === false && (
          <i className="fa-solid fa-lock-open" style={{ color: 'green' }}></i>
        )}
      </>
    );
  };

  const printRoute = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/route/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-rota',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const printOrdersRouteCheck = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/orders/route/check/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-conferencia-pedidos',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const printDevolutionsRouteCheck = async (id: string) => {
    setLoad(true);
    await api
      .get(`/reports/consigned/devolutions/route/check/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-conferencia-devolucoes',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const exportExcelNextTravel = async (id: string) => {
    setLoad(true);
    await api
      .get(`/consigned/route/excel/next/travel/${id}`)
      .then(({ data }) => {
        if (data) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Lista próxima viagem');

          worksheet.addRow(['Webger - Consignados']);

          let tableRows = [];

          for (const item of data) {
            tableRows.push([
              item.sku,
              item.outputs,
              item.returns,
              item.sold,
              '',
            ]);
          }

          worksheet.addTable({
            name: 'Lista',
            ref: 'A3',
            headerRow: true,
            totalsRow: false,
            columns: [
              { name: 'SKU', filterButton: true },
              { name: 'SAÍDA', filterButton: false },
              { name: 'RETORNO', filterButton: true },
              { name: 'VENDIDO', filterButton: false },
              { name: 'SEPARAÇÃO', filterButton: true },
            ],
            rows: tableRows,
          });

          worksheet.columns = [
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 10,
            },
            {
              style: {
                alignment: { vertical: 'middle', horizontal: 'center' },
              },
              width: 15,
            },
          ];

          workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveAs(blob, 'webger-consignado-lista-proxima-viagem.xlsx');
          });
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const createRouteWithDevolutions = async (id: string) => {
    setLoad(true);
    await api
      .get(`/consigned/route/create/with/old/route/${id}`)
      .then(({ data }) => {
        if (data) {
          toast(
            'success',
            'Sucesso',
            'Nova rota criada a partir das devoluções da rota selecionada criada com sucesso!',
          );
          loadRoutes();
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err), 10000);
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    loadRoutes();
  }, []);

  const mountMenu = (row: any) => {
    const items: any[] = [];

    if (row.status !== 'C' && row.block === false) {
      items.push({
        label: 'Editar',
        icon: 'fa-solid fa-pen-to-square',
        command: () => router.push(`/consigned/route/open/${row.id}`),
      });
    }

    if (row.status !== 'C' && row.block === true) {
      items.push({
        label: 'Vender devoluções',
        icon: 'fa-solid fa-boxes-packing',
        command: async () => await createRouteWithDevolutions(row.id),
      });
    }

    if (row.status !== 'C' && row.block === false) {
      items.push({
        label: 'Pedido',
        icon: 'fa-solid fa-file-arrow-up',
        command: () => router.push(`/consigned/order/create/${row.id}`),
      });
    }

    if (row.status !== 'C' && row.block === false) {
      items.push({
        label: 'Devolução',
        icon: 'fa-solid fa-file-arrow-down',
        command: () => router.push(`/consigned/devolution/create/${row.id}`),
      });
    }

    if (row.status !== 'C' && row.block === true) {
      items.push({
        label: 'Ver',
        icon: 'fa-solid fa-eye',
        command: () => router.push(`/consigned/route/open/${row.id}`),
      });
    }

    items.push({
      label: 'Imprimir',
      icon: 'fa-solid fa-print',
      command: () => {
        printRoute(row.id);
      },
    });

    items.push({
      label: 'Conferência pedidos',
      icon: 'fa-solid fa-check-square',
      command: () => {
        printOrdersRouteCheck(row.id);
      },
    });

    items.push({
      label: 'Conferência devoluções',
      icon: 'fa-solid fa-check-square',
      command: () => {
        printDevolutionsRouteCheck(row.id);
      },
    });

    items.push({
      label: 'Exportar excel próxima viagem',
      icon: 'fa-solid fa-file-export',
      command: () => {
        exportExcelNextTravel(row.id);
      },
    });

    if (row.status !== 'C' && row.block !== true) {
      items.push({
        label: 'Cancelar',
        icon: 'fa-solid fa-trash',
        command: () => handleCancelRoute(row.id),
      });
    }

    return items;
  };

  const renderCollumnMenu = (row: any) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={() => {}} />
      </>
    );
  };

  return (
    <>
      <ConsignedTopMenu />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card mb-1"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgroup">
          <div className="field col-5">
            <label htmlFor="keyword">Nome</label>
            <InputText
              name="keyword"
              placeholder="Ex.: João da Silva"
              value={keyword || ''}
              onChange={e => setKeyword(e.currentTarget.value)}
            />
          </div>

          <div className="field col-1">
            <label htmlFor="sequence">N. Cons.</label>
            <InputNumber
              name="sequence"
              placeholder="000"
              value={sequence}
              onChange={e => setSequence(e.value)}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="period">Total entre</label>
            <InputNumberRange
              name={'total_range'}
              defaultMinValue={-100000}
              defaultMaxValue={100000}
              range={totalInterval}
              onMinValueChange={e => {
                setTotalInterval([e as number, totalInterval[1]]);
              }}
              onMaxValueChange={e => {
                setTotalInterval([totalInterval[0], e as number]);
              }}
            />
          </div>

          <div className="field col-2">
            <label htmlFor="period">Período</label>
            <CalendarRange
              name="period"
              value={period}
              onChange={e => setPeriod(e.value as Date[])}
              placeholder="00/00/00 ~ 00/00/00"
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              name="btn_search"
              label="Buscar"
              icon="fa-solid fa-search"
              type="submit"
              loading={load}
            />
          </div>

          <div className="field col-4 flex align-items-end my-0 py-0">
            <label htmlFor="dropdown" className="">
              Aberto
            </label>
            <InputSwitch
              name="block"
              className="mx-3"
              checked={block}
              onChange={e => setBlock(e.value)}
            />
            <label htmlFor="dropdown" className="">
              Encerrado
            </label>
          </div>
        </div>
      </Form>

      <DataTable
        value={routes}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Rotas/Consignados não encontrados..."
        selectionMode="single"
        loading={load}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} pedidos consignados."
        className="card"
      >
        <Column header="#" body={renderColumnPosition}></Column>
        <Column header="N." field="sequence" sortable></Column>
        <Column header="Cliente" field="customer.name" sortable></Column>
        <Column
          header="Abertura"
          body={r => renderColumnDate(r.date_open)}
        ></Column>
        <Column
          header="Fechamento"
          body={r => renderColumnDate(r.date_close)}
        ></Column>
        <Column
          header="Pedidos (R$)"
          body={r => renderColumnDecimal(r.total_order)}
          sortable
        ></Column>
        <Column
          header="Devoluções (R$)"
          body={r => renderColumnDecimal(r.total_devolution)}
          sortable
        ></Column>
        <Column
          header="Brindes"
          body={r => renderColumnDecimal(r.total_gift)}
          sortable
        ></Column>
        <Column
          header="Total"
          body={r => renderColumnDecimal(r.total)}
        ></Column>
        <Column
          header="Encerrado"
          body={r => renderCollumnBlock(r.block)}
          align={'center'}
        ></Column>
        <Column
          header="Status"
          body={r => renderColumnStatus(r)}
          align={'center'}
        ></Column>
        <Column
          header="Usuário"
          body={r => renderColumnShortName(r.user.name)}
        ></Column>
        <Column header="*" body={e => renderCollumnMenu(e)}></Column>
      </DataTable>
    </>
  );
};

export default ConsignedRouteList;
