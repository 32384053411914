import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import RenderColumnStatusBills from '../../../components/DataTableColumns/RenderColumnStatusBills';
import RenderColumnStatusPayment from '../../../components/DataTableColumns/RenderColumnStatusPayment';
import Calendar from '../../../components/Inputs/InputCalendar';
import MenuPopUp from '../../../components/MenuPopPup';
import { EnumStatusPayment } from '../../../enum/EnumStatusPayment';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { CostCenterModel } from '../../../types/cost';
import { Option } from '../../../types/optional';
import formatCurrency from '../../../utils/FormatCurrency';
import messageRequestError from '../../../utils/messageRequestError';
import openReports from '../../../utils/openReports';
import { IBillsToPayList } from '../dtos/IBillsToPay';
import BillsToPayHeader from '../header';
import { EnumStatus } from '../../../enum/EnumStatus';

const situationOption = [
  { name: 'Todos', value: null },
  { name: 'Pago', value: 'P' },
  { name: 'Aguardando', value: 'A' },
  { name: 'Vencido', value: 'V' },
  { name: 'Cancelado', value: 'C' },
];

const BillToPayList = (): React.ReactElement => {
  const hoje = new Date();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [initialDate, setInitialDate] = useState(
    new Date(hoje.getFullYear(), hoje.getMonth(), 1),
  );
  const [finalDate, setFinalDate] = useState(
    new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0),
  );
  const [situation, setSituation] = useState('');
  const [billsToPay, setBillsToPay] = useState<IBillsToPayList[]>([]);
  const [billToPay, setBillToPay] = useState<IBillsToPayList>();
  const [costCenter, setCostCenter] = useState<Option[] | undefined>(undefined);
  const [costCenterSelected, setSelectedCostCenter] = useState('');
  const [search, setSearch] = useState('');
  const [menuOption, setMenuOption] = useState({
    bp_id: '',
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status_payment: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    center_cost: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });

  interface ISummary {
    overdue: number;
    payed: number;
    to_pay: number;
  }
  const [summary, setSummary] = useState<ISummary>({
    payed: 0,
    to_pay: 0,
    overdue: 0,
  });

  const [loading, setLoading] = useState(true);
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const menu = useRef<any>(null);
  const navigate = useHistory();

  const generateReceipt = async (id: string) => {
    const reportsGenerated = await api.get(
      `${process.env.REACT_APP_SERVER_URL}/reports/receipt?billId=${id}`,
    );

    reportsGenerated &&
      openReports(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${reportsGenerated.data.fileName}`,
        'contas-pagar',
      );
  };

  const searchBills = async () => {
    try {
      const { data } = await api.get(`bills-payable/se/search`, {
        params: {
          searchField: search,
          dateStart: initialDate,
          dateEnd: finalDate,
        },
      });

      setBillsToPay(data);

      const sum = data.reduce(
        (a: any, i: any) => {
          if (i.status_payment === EnumStatusPayment.PAGO) {
            a.payed += Number(i.value);
          }

          if (i.status_payment !== EnumStatusPayment.PAGO) {
            a.to_pay += Number(i.value_rest);
          }

          if (i.status_payment === EnumStatusPayment.VENCIDO) {
            a.overdue += Number(i.value_rest);
          }

          return a;
        },
        {
          overdue: 0,
          payed: 0,
          to_pay: 0,
        },
      );

      setSummary(sum);
    } catch (err: any) {
      toast('error', 'Error', messageRequestError(err));
    } finally {
      setLoading(false);
    }
  };

  const cancelBill = async (id: string) => {
    try {
      setLoading(true);
      await api.delete(
        `${process.env.REACT_APP_SERVER_URL}/bills-payable/${id}`,
      );
      searchBills();
    } catch (err: any) {
      toast('error', 'Error', messageRequestError(err));
    } finally {
      setLoading(false);
    }
  };

  const onGlobalFilterStatusPayment = (value: any) => {
    const _filter = { ...filters };
    _filter['status_payment'].value = value;

    setFilters(_filter);
    // setGlobalFilter(value);
  };

  const onGlobalFilterCostCenter = (value: any) => {
    const _filter = { ...filters };
    _filter['center_cost'].value = value;

    setFilters(_filter);
    // setGlobalFilter(value);
  };

  const onGlobalFilterChange2 = (e: any) => {
    const value = e.target.value;
    const _filter = { ...filters };
    _filter['global'].value = value;

    setGlobalFilter(value);
  };

  const filterToCostCenter = (e: string) => {
    setSelectedCostCenter(e);
  };

  const mountMenu = (row: IBillsToPayList) => {
    const items: any[] = [];

    if (row.status !== EnumStatus.CANCELADO) {
      if (row.status_payment !== EnumStatusPayment.PAGO) {
        items.push({
          label: `Baixar`,
          icon: 'pi pi-check-square',
          command: () => {
            navigate.push(`/bills/pay/drop/${row.bp_id}`);
          },
        });
      }

      items.push(
        {
          label: 'Imprimir Recibo',
          icon: 'pi pi-fw pi-print',
          command: () => generateReceipt(row.bp_id),
        },
        {
          label: 'Cancelar',
          icon: 'pi pi-fw pi-trash',
          command: () => cancelBill(row.bp_id),
        },
      );
    }

    return items;
  };

  const renderColumnMenu = (row: IBillsToPayList) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={setBillToPay} />
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0 mb-2">Contas a Pagar</h5>
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">Pagas</h5>
          <span className={`product-badge status-instock`}>
            {formatCurrency(summary.payed)}
          </span>
        </div>
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">A Pagar</h5>
          <span className={`product-badge status-lowstock`}>
            {formatCurrency(summary.to_pay)}
          </span>
        </div>
        <div className="flex align-items-center mr-4 mb-2">
          <h5 className="m-0 mr-3">Atrasadas</h5>
          <span className={`product-badge status-outofstock`}>
            {formatCurrency(summary.overdue)}
          </span>
        </div>
      </div>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={onGlobalFilterChange2}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  useEffect(() => {
    searchBills();
  }, []);

  useEffect(() => {
    api.get(`/cost-center`).then(({ data }) => {
      const costCenterOption = data.map((item: CostCenterModel) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
      costCenterOption.unshift({
        label: 'Todos',
        value: null,
      });
      setCostCenter(costCenterOption);
    });
  }, []);

  return (
    <>
      <BillsToPayHeader />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Form ref={formRef} onSubmit={d => searchBills()}>
              <div className="p-fluid grid formgrid mb-3">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Fornecedor</label>

                  <div className="p-inputgroup">
                    <Button label="Pesquisar" />
                    <InputText
                      placeholder="Ex.: João da Pipoca"
                      name="search"
                      onChange={e => setSearch(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Data Inicial</label>
                  <Calendar
                    name="date_initial"
                    value={initialDate}
                    dateFormat="dd/mm/yy"
                    onChange={(e: any) => setInitialDate(e.value)}
                    showIcon
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Data Final</label>
                  <Calendar
                    name="date_final"
                    value={finalDate}
                    dateFormat="dd/mm/yy"
                    onChange={(e: any) => setFinalDate(e.value)}
                    showIcon
                  />
                </div>
                <div className="field col-12 md:col-4">
                  {/* <span className="p-float-label"> */}
                  <label htmlFor="dropdown">Status</label>
                  <Dropdown
                    inputId="dropdown"
                    value={situation}
                    options={situationOption}
                    placeholder="Selecionar..."
                    onChange={e => {
                      setSituation(e.value);
                      onGlobalFilterStatusPayment(e.value);
                    }}
                    optionLabel="name"
                  />
                  {/* </span> */}
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="dropdown">Centro de Custo</label>
                  <Dropdown
                    value={costCenterSelected}
                    options={costCenter}
                    placeholder="Selecionar..."
                    onChange={e => {
                      filterToCostCenter(e.value);
                      onGlobalFilterCostCenter(e.value);
                    }}
                    optionLabel="label"
                  />
                </div>
                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    label="Filtrar Contas"
                    icon="pi pi-filter"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          </div>
          <div className="col">
            <div className="grid">
              <div className="col card">
                <DataTable
                  value={billsToPay}
                  selection={selectedProducts}
                  onSelectionChange={e => setSelectedProducts(e.value)}
                  dataKey="id"
                  paginator
                  rows={10}
                  filters={filters}
                  loading={loading}
                  loadingIcon="pi pi-spinner text-primary"
                  rowsPerPageOptions={[10, 20, 30]}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} contas"
                  globalFilter={globalFilter}
                  emptyMessage="Não existe contas."
                  header={header}
                  responsiveLayout="scroll"
                  size="small"
                >
                  <Column
                    field="br_id"
                    header="#"
                    body={renderColumnPosition}
                  ></Column>
                  <Column
                    field="number"
                    header="N°"
                    headerStyle={{ width: '5%', minWidth: '10rem' }}
                  ></Column>
                  <Column
                    field="sr_parcel"
                    header="Parcela"
                    headerStyle={{ width: '5%', minWidth: '10rem' }}
                  ></Column>
                  <Column
                    header="Vencimento"
                    body={d => renderColumnDate(d.data_due)}
                    headerStyle={{ width: '14%', minWidth: '10rem' }}
                  ></Column>
                  <Column field="provider_name" header="Fornecedor"></Column>
                  <Column
                    field="value"
                    header="Total"
                    body={d => renderColumnDecimal(d.value)}
                    sortable
                  ></Column>
                  <Column
                    field="center_cost"
                    hidden
                    header="Centro de custo"
                  ></Column>
                  <Column
                    field="status_payment"
                    header="Situação"
                    align={'center'}
                    body={d =>
                      RenderColumnStatusPayment(d.status, d.status_payment)
                    }
                    sortable
                  ></Column>
                  <Column
                    field="status"
                    header="Status"
                    align={'center'}
                    body={d => RenderColumnStatusBills(d.status)}
                    sortable
                  ></Column>
                  <Column header="*" body={d => renderColumnMenu(d)}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const comparisonFn = function (prevProps: Window, nextProps: Window) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BillToPayList, comparisonFn);
