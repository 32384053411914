import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import Progress from '../../../../components/progress-bar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import { IPanel } from '../dtos/IPanelDTO';
import SidebarPanel from '../sidebar-panel';
import CrmTopMenu from '../top-menu';

const CrmPanels = (): React.ReactElement => {
  const [load, setLoad] = useState(false);

  const router = useHistory();
  const toast = useToastContext();

  const [panel, setPanel] = useState<IPanel>();
  const [panels, setPanels] = useState<IPanel[]>([]);
  const [user, setUser] = useState<any>();
  const [sidebarPanelVisible, setSidebarPanelVisible] = useState(false);

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const loadPanels = async (id: string) => {
    setLoad(true);
    await api
      .get(`/crm/panels/user/${id}`)
      .then(({ data }) => {
        if (data) {
          setPanels(
            data.map((i: any) => {
              return {
                id: i.id,
                position: i.position,
                title: i.title,
                description:
                  i.description !== null && i.description.length > 64
                    ? `${i.description.slice(0, 64)}...`
                    : i.description,
                qnt_cards: i.qnt_cards,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleOpenPanel = (id: string) => {
    if (validate(id)) {
      router.push(`/mkt/crm/panel/${id}`);
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    if (user && validate(user.id)) {
      loadPanels(user.id);
    }
  }, [user]);

  return (
    <>
      <CrmTopMenu />
      <Progress isLoad={load} className="mb-2" />
      <div className="grid">
        {panels.map((i: IPanel) => (
          <div key={i.id} className="col-6 md:col-3">
            <div
              className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
              onClick={() => handleOpenPanel(i.id)}
            >
              <div className="flex justify-content-between align-items-stretch">
                <div className="flex w-10 justify-content-between mb-3 cursor-pointer">
                  <div>
                    <div className="text-900 font-medium text-xl">
                      {i.title}
                    </div>
                    <span className="block text-500 font-medium mb-3">
                      {i.description}
                    </span>
                  </div>
                </div>

                <div
                  className="flex align-items-center justify-content-center border-round cursor-pointer"
                  style={{
                    width: '32px',
                    height: '32px',
                    maxHeight: '32px',
                    maxWidth: '32px',
                    backgroundColor: 'var(--primary-color)',
                    color: '#fff',
                  }}
                  onClick={(event: any) => {
                    //usado para não disparar o evento de onclick da div pai
                    event.stopPropagation();
                    setPanel(panels.find(e => e.id === i.id));
                    setSidebarPanelVisible(true);
                  }}
                >
                  <i className="pi pi-bars text-xl"></i>
                </div>
              </div>

              <span className="text-green-500 font-medium">{i.qnt_cards} </span>
              <span className="text-500">Cards</span>
            </div>
          </div>
        ))}
        <div className="col-1 flex justify-content-start align-items-end">
          <Button
            icon="fa-solid fa-plus "
            className="p-button-rounded"
            aria-label="User"
            type="button"
            onClick={() => setSidebarPanelVisible(true)}
          />
        </div>
      </div>

      <SidebarPanel
        isOpen={sidebarPanelVisible}
        onRequestClose={async () => {
          setSidebarPanelVisible(false);
          setPanel({} as IPanel);
          await loadPanels(user.id);
        }}
        data={panel}
      />
    </>
  );
};

export default CrmPanels;
