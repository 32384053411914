import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { addMonths, endOfDay, format, subMonths } from 'date-fns';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';

/** dtos */
import IOptionsDTO from '../business/dtos/IOptionsDTO';
import IClienteDTO from './dtos/IClienteDTO';
import IContaReceberDTO from './dtos/IContaReceberDTO';
import IDropBoletoDTO from './dtos/IDropBoletoDTO';
import IDropCartaoDTO from './dtos/IDropCartaoDTO';
import IDropChequePrazoDTO from './dtos/IDropChequePrazoDTO';
import IDropChequeVistaDTO from './dtos/IDropChequeVistaDTO';
import IDropDepositoDTO from './dtos/IDropDepositoDTO';
import IDropPixDTO from './dtos/IDropPixDTO';
import IDropTedDTO from './dtos/IDropTedDTO';

/** components */
import {
  SideBar as SideBarBoleto,
  SideBar as SideBarCartaoCredito,
  SideBar as SideBarCartaoDebito,
  SideBar as SideBarChequesPrazo,
  SideBar as SideBarChequesVista,
  SideBar as SideBarCliente,
  SideBar as SideBarDeposito,
  SideBar as SideBarDocumento,
  SideBar as SideBarPix,
  SideBar as SideBarTed,
} from '../../components/Sidebar';

import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { InputMask as InputM } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText as InputT } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tag } from 'primereact/tag';

import { DataTable } from '../../components/DataTable';
import renderColumnCpfCnpj from '../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnDate from '../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../components/DataTableColumns/RenderColumnPosition';
import renderColumnPositiveNegative from '../../components/DataTableColumns/RenderColumnPositiveNegative';
import renderColumnStatus from '../../components/DataTableColumns/RenderColumnStatus';
import { Divider } from '../../components/Divider';
import Calendar from '../../components/Inputs/InputCalendar';
import { InputChecked } from '../../components/Inputs/InputChecked';
import { InputDropDown } from '../../components/Inputs/InputDropDown';
import { InputMask } from '../../components/Inputs/InputMask';
import { InputNumber } from '../../components/Inputs/InputNumber';
import { InputText } from '../../components/Inputs/InputText';

import { ProgressBar } from 'primereact/progressbar';
import { useHistory, useParams } from 'react-router-dom';
import getValidationErrors from '../../utils/getErrorsValidation';
import PerfectDivision from '../../utils/perfectDivision';
import BillsToReceivableHeader from '../bills-to-receive/header';
import IDropDTO from './dtos/IDropDTO';
import useSearchCustomer from '../../hooks/useSearchCustomer';

const dropBillReceivable = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const toast = useToastContext();
  const navigate = useHistory();
  const params: { id: string } = useParams();

  const formSubmit = useRef<FormHandles>(null);
  const formDinheiro = useRef<FormHandles>(null);
  const formChequeVista = useRef<FormHandles>(null);
  const formChequePrazo = useRef<FormHandles>(null);
  const formBoleto = useRef<FormHandles>(null);
  const formTed = useRef<FormHandles>(null);
  const formDeposito = useRef<FormHandles>(null);
  const formPix = useRef<FormHandles>(null);
  const formCartaoCredito = useRef<FormHandles>(null);
  const formCartaoDebito = useRef<FormHandles>(null);

  /** states */
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [clienteOpts, setClienteOpts] = useState<IClienteDTO[]>([]);
  const [cliente, setCliente] = useState<IClienteDTO>({
    id: '',
    codigo: 0,
    customer: '',
    name: '',
    cpf_cnpj: '',
    block: '',
    image_url: '',
    status: '',
  });
  const [clienteKeyword, setClientekeyword] = useState<string>('');

  const [contaReceberOpts, setContaReceberOpts] = useState<IContaReceberDTO[]>(
    [],
  );
  const [contaReceber, setContaReceber] = useState<IContaReceberDTO>({
    id: '',
    name: '',
    serie: '',
    number: '',
    parcel: '',
    value: 0,
    value_total: 0,
    duo_date: '',
    emited_date: '',
    days_delay: 0,
    juros: 0,
    multa: 0,
    total: 0,
  });

  const [dataRecebimento, setDataRecebimento] = useState<Date>(hoje);
  const [valorRecebido, setValorRecebido] = useState<number>(0);
  const [desconto, setDesconto] = useState<number>(0);
  const [outros, setOutros] = useState<number>(0);

  const [restante, setRestante] = useState<number>(0);

  const [sideBarChequesVistaVisible, setSideBarChequesVistaVisible] =
    useState<boolean>(false);
  const [sideBarChequesPrazoVisible, setSideBarChequesPrazoVisible] =
    useState<boolean>(false);
  const [sideBarBoletoVisible, setSideBarBoletoVisible] =
    useState<boolean>(false);
  const [sideBarTedVisible, setSideBarTedVisible] = useState<boolean>(false);
  const [sideBarDepositoVisible, setSideBarDepositoVisible] =
    useState<boolean>(false);
  const [sideBarPixVisible, setSideBarPixVisible] = useState<boolean>(false);
  const [sideBarCartaoCreditoVisible, setSideBarCartaoCreditoVisible] =
    useState<boolean>(false);
  const [sideBarCartaoDebitoVisible, setSideBarCartaoDebitoVisible] =
    useState<boolean>(false);
  const [sideBarClienteVisible, setSideBarClienteVisible] =
    useState<boolean>(false);
  const [sideBarDocumentoVisible, setSideBarDocumentoVisible] =
    useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const [rowId, setRowId] = useState<string>('');

  const [bancoOpts, setBancoOpts] = useState<IOptionsDTO[]>([]);
  const [bandeiraCartaoOpts, _] = useState<IOptionsDTO[]>([
    { label: 'American Express', value: 'american-express' },
    { label: 'Diners Club International', value: 'diners-club-int' },
    { label: 'Elo', value: 'elo' },
    { label: 'Hiper', value: 'hiper' },
    { label: 'Hipercard', value: 'hipercard' },
    { label: 'Mastercard', value: 'master-card' },
    { label: 'Sodexo', value: 'sodexo' },
    { label: 'Ticket', value: 'ticket' },
    { label: 'Visa', value: 'visa' },
    { label: 'VR Benefícios', value: 'vr-beneficios' },
  ]);
  const [contaOpts, setContaOpts] = useState<IOptionsDTO[]>([]);
  const [pixOpts, setPixOpts] = useState<IOptionsDTO[]>([]);

  const [recDinheiro, setRecDinheiro] = useState<number>(0);
  const [recChequeVista, setRecChequeVista] = useState<number>(0);
  const [recChequePrazo, setRecChequePrazo] = useState<number>(0);
  const [recBoleto, setRecBoleto] = useState<number>(0);
  const [recTed, setRecTed] = useState<number>(0);
  const [recDeposito, setRecDeposito] = useState<number>(0);
  const [recPix, setRecPix] = useState<number>(0);
  const [recCartaoCredito, setRecCartaoCredito] = useState<number>(0);
  const [recCartaoDebito, setRecCartaoDebito] = useState<number>(0);

  const [resDinheiro, setResDinheiro] = useState<number>(0);
  const [resChequeVista, setResChequeVista] = useState<number>(0);
  const [resChequePrazo, setResChequePrazo] = useState<number>(0);
  const [resBoleto, setResBoleto] = useState<number>(0);
  const [resTed, setResTed] = useState<number>(0);
  const [resDeposito, setResDeposito] = useState<number>(0);
  const [resPix, setResPix] = useState<number>(0);
  const [resCartaoCredito, setResCartaoCredito] = useState<number>(0);
  const [resCartaoDebito, setResCartaoDebito] = useState<number>(0);

  const [totalDigDinheiro, setTotalDigDinheiro] = useState<number>(0);
  const [totalDigChequeVista, setTotalDigChequeVista] = useState<number>(0);
  const [totalDigChequePrazo, setTotalDigChequePrazo] = useState<number>(0);
  const [totalDigBoleto, setTotalDigBoleto] = useState<number>(0);
  const [totalDigTed, setTotalDigTed] = useState<number>(0);
  const [totalDigDeposito, setTotalDigDeposito] = useState<number>(0);
  const [totalDigPix, setTotalDigPix] = useState<number>(0);
  const [totalDigCartaoCredito, setTotalDigCartaoCredito] = useState<number>(0);
  const [totalDigCartaoDebito, setTotalDigCartaoDebito] = useState<number>(0);

  const [itensChequeVista, setItensChequeVista] = useState<
    IDropChequeVistaDTO[]
  >([]);
  const [cqaKindPeople, setCqaKindPeople] = useState<boolean>(false);
  const [cqaBankId, setCqaBankId] = useState<string>('');
  const [cqaOtherOwner, setCqaOtherOwner] = useState<boolean>(false);
  const [cqaBankAgence, setCqaBankAgence] = useState<string>('');
  const [cqaBankAccount, setCqaBankAccount] = useState<string>('');
  const [cqaChequeNumber, setCqaChequeNumber] = useState<string>('');
  const [cqaValue, setCqaValue] = useState<number>(0);
  const [cqaCpfCnpj, setCqaCpfCnpj] = useState<string>('');
  const [cqaTitular, setCqaTitular] = useState<string>('');
  const [cqaChequeEmited, setCqaChequeEmited] = useState<Date>(hoje);
  const [cqaChequeDuoDate, setCqaChequeDuoDate] = useState<Date>(hoje);

  const [itensChequePrazo, setItensChequePrazo] = useState<
    IDropChequePrazoDTO[]
  >([]);
  const [cqpKindPeople, setCqpKindPeople] = useState<boolean>(false);
  const [cqpBankId, setCqpBankId] = useState<string>('');
  const [cqpOtherOwner, setCqpOtherOwner] = useState<boolean>(false);
  const [cqpBankAgence, setCqpBankAgence] = useState<string>('');
  const [cqpBankAccount, setCqpBankAccount] = useState<string>('');
  const [cqpChequeNumber, setCqpChequeNumber] = useState<string>('');
  const [cqpValue, setCqpValue] = useState<number>(0);
  const [cqpCpfCnpj, setCqpCpfCnpj] = useState<string>('');
  const [cqpTitular, setCqpTitular] = useState<string>('');
  const [cqpChequeEmited, setCqpChequeEmited] = useState<Date>(hoje);
  const [cqpChequeDuoDate, setCqpChequeDuoDate] = useState<Date>(hoje);

  const [itensBoleto, setItensBoleto] = useState<IDropBoletoDTO[]>([]);
  const [bolContaId, setBolContaId] = useState<string>('');
  const [bolValor, setBolValor] = useState<number>(0);
  const [bolPagamento, setBolPagamento] = useState<Date>(hoje);

  const [itensTed, setItensTed] = useState<IDropTedDTO[]>([]);
  const [tedContaId, setTedContaId] = useState<string>('');
  const [tedValor, setTedValor] = useState<number>(0);
  const [tedPagamento, setTedPagamento] = useState<Date>(hoje);

  const [itensDeposito, setItensDeposito] = useState<IDropDepositoDTO[]>([]);
  const [depContaId, setDepContaId] = useState<string>('');
  const [depValor, setDepValor] = useState<number>(0);
  const [depPagamento, setDepPagamento] = useState<Date>(hoje);

  const [itensPix, setItensPix] = useState<IDropPixDTO[]>([]);
  const [pixConta, setPixConta] = useState<string>('');
  const [pixChave, setPixChave] = useState<string>('');
  const [pixValor, setPixValor] = useState<number>(0);
  const [pixPagamento, setPixPagamento] = useState<Date>(hoje);

  const [itensCartaoCredito, setItensCartaoCredito] = useState<
    IDropCartaoDTO[]
  >([]);
  const [ccBandeiraCartao, setCcBandeiraCartao] = useState<string>('');
  const [ccParcela, setCcParcela] = useState<number>(0);
  const [ccValor, setCcValor] = useState<number>(0);

  const [itensCartaoDebito, setItensCartaoDebito] = useState<IDropCartaoDTO[]>(
    [],
  );
  const [cdBandeiraCartao, setCdBandeiraCartao] = useState<string>('');
  const [cdParcela, setCdParcela] = useState<number>(0);
  const [cdValor, setCdValor] = useState<number>(0);

  /** render */
  const cqaRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemChequeVista(id)}
        type="button"
      />
    );
  };

  const cqpRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemChequePrazo(id)}
        type="button"
      />
    );
  };

  const bolRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemBoleto(id)}
        type="button"
      />
    );
  };

  const pixRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemPix(id)}
        type="button"
      />
    );
  };

  const tedRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemTed(id)}
        type="button"
      />
    );
  };

  const depRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemDeposito(id)}
        type="button"
      />
    );
  };

  const ccRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemCartaoCredito(id)}
        type="button"
      />
    );
  };

  const cdRenderColumnButtonRemove = (id: string) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => handleRemCartaoDebito(id)}
        type="button"
      />
    );
  };

  const renderColumnConta = (id: string) => {
    return contaOpts.find(i => i.value === id)?.label;
  };

  const renderColumnBandeira = (bandeira: string) => {
    return bandeiraCartaoOpts.find(i => i.value === bandeira)?.label;
  };

  const renderColumnChave = (id: string) => {
    return pixOpts.find(i => i.value === id)?.label;
  };

  const tabHeaderTemplate = (options: any, isError: boolean) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={`${options.className} mx-3`}
        style={
          isError
            ? { background: '#ff623e', color: '#fff', fontWeight: 200 }
            : { fontWeight: 200 }
        }
      >
        <i
          className={
            isError
              ? 'fa-solid fa-triangle-exclamation mr-2'
              : 'fa-regular fa-check mr-2'
          }
        />
        {options.titleElement}
      </button>
    );
  };

  const headerDatatableDocument = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <span className="block w-full mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputT
          name="search"
          type="search"
          className="w-full"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  /** functions */
  const searchCustomer = async (keyword: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search: keyword });

      if (customers) {
        const opts = customers.map((item: any) => {
          return {
            id: item.id,
            codigo: item.codigo,
            customer: item.customer,
            name: item.name,
            cpf_cnpj: item.cpf_cnpj,
            block: item.block,
            image_url: item.image_url,
            status: item.status,
          };
        });

        setClienteOpts(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const searchDocument = async (customerId: string) => {
    if (customerId) {
      setIsLoad(true);
      await api
        .get(`/bills-receivable/v2/receivable/docs/${customerId}`)
        .then(({ data }) => {
          if (data.length === 0) {
            toast(
              'warn',
              'Alerta',
              `Nenhuma conta a receber foi encontrada do cliente [${cliente.name}]`,
            );
            return;
          }

          const opts = data.listBillsToReceiveByCustomerId.map(
            (item: IContaReceberDTO) => {
              return {
                id: item.id,
                name: item.name,
                serie: item.serie,
                number: item.number,
                parcel: item.parcel,
                value: item.value,
                value_total: item.value_total,
                duo_date: item.duo_date,
                emited_date: item.emited_date,
                days_delay: item.days_delay,
                juros: item.juros,
                multa: item.multa,
                total: item.total,
              };
            },
          );

          setContaReceberOpts(opts);
        })
        .catch((e: any) => {
          toast('error', 'Erro', e.response.data.error);
          return;
        })
        .finally(() => setIsLoad(false));
    }
  };

  const handleSelectCustomer = (e: DataTableSelectionChangeParams) => {
    const customer = clienteOpts.find(i => i.id === e.id);

    if (customer) {
      if (customer.id !== cliente.id) {
        setContaReceberOpts([]);
        setContaReceber({
          id: '',
          name: '',
          serie: '',
          number: '',
          parcel: '',
          value: 0,
          value_total: 0,
          duo_date: '',
          emited_date: '',
          days_delay: 0,
          juros: 0,
          multa: 0,
          total: 0,
        });
      }

      setCliente(customer);
      searchDocument(customer.id);
      setSideBarClienteVisible(false);
    } else {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar o cliente. Atualize o navegador e tente novamente!',
      );
      return;
    }
  };

  const handleSelectDocument = (e: DataTableSelectionChangeParams) => {
    const document = contaReceberOpts.find(
      (i: IContaReceberDTO) => i.id === e.value.id,
    );

    if (document) {
      setContaReceber(document);
      setSideBarDocumentoVisible(false);
    } else {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar a conta a receber. Atualize o navegador e tente novamente!',
      );
      return;
    }
  };

  const clearFormChequeVista = () => {
    setRowId('');
    setCqaOtherOwner(false);
    setCqaBankId('');
    setCqaBankAgence('');
    setCqaBankAccount('');
    setCqaChequeNumber('');
    setCqaValue(0);
    setCqaKindPeople(false);
    setCqaCpfCnpj('');
    setCqaChequeEmited(hoje);
    setCqaChequeDuoDate(hoje);
  };

  const clearFormChequePrazo = () => {
    setRowId('');
    setCqpOtherOwner(false);
    setCqpBankId('');
    setCqpBankAgence('');
    setCqpBankAccount('');
    setCqpChequeNumber('');
    setCqpValue(0);
    setCqpKindPeople(false);
    setCqpCpfCnpj('');
    setCqpChequeEmited(hoje);
    setCqpChequeDuoDate(hoje);
  };

  const clearFormBoleto = () => {
    setRowId('');
    setBolContaId('');
    setBolPagamento(hoje);
    setBolValor(0);
  };

  const clearFormTed = () => {
    setRowId('');
    setTedContaId('');
    setTedPagamento(hoje);
    setTedValor(0);
  };

  const clearFormDeposito = () => {
    setRowId('');
    setDepContaId('');
    setDepPagamento(hoje);
    setDepValor(0);
  };

  const clearFormPix = () => {
    setRowId('');
    setPixConta('');
    setPixChave('');
    setPixPagamento(hoje);
    setPixValor(0);
  };

  const clearFormCartaoCredito = () => {
    setRowId('');
    setCcBandeiraCartao('');
    setCcParcela(0);
    setCcValor(0);
  };

  const clearFormCartaoDebito = () => {
    setRowId('');
    setCdBandeiraCartao('');
    setCdParcela(0);
    setCdValor(0);
  };

  const checkToAddDinheiro = () => {
    if (recDinheiro > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em dinheiro ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recDinheiro,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }
    setTotalDigDinheiro(recDinheiro);
  };

  const checkToAddChequeVista = () => {
    if (recChequeVista > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em cheque à vista ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recChequeVista,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarChequesVistaVisible(true);
  };

  const checkToAddChequePrazo = () => {
    if (recChequePrazo > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em cheque à prazo ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recChequePrazo,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarChequesPrazoVisible(true);
  };

  const checkToAddBoleto = () => {
    if (recBoleto > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em boleto ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recBoleto,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarBoletoVisible(true);
  };

  const checkToAddTed = () => {
    if (recTed > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em TED ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recTed,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarTedVisible(true);
  };

  const checkToAddDeposito = () => {
    if (recDeposito > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em Deposito ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recDeposito,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarDepositoVisible(true);
  };

  const checkToAddPix = () => {
    if (recPix > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em PIX ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recPix,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarPixVisible(true);
  };

  const checkToAddCartaoCredito = () => {
    if (recCartaoCredito > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em cartão crédito ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recCartaoCredito,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarCartaoCreditoVisible(true);
  };

  const checkToAddCartaoDebito = () => {
    if (recCartaoDebito > Number(valorRecebido - restante)) {
      toast(
        'warn',
        'Alerta',
        `O valor da baixa em cartão debito ${new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(
          recCartaoDebito,
        )} é maior que o valor restante a baixar ${new Intl.NumberFormat(
          'BRL',
          { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(valorRecebido - restante)}`,
      );
    }

    setSideBarCartaoDebitoVisible(true);
  };

  const handleAddChequeVista = async (data: any) => {
    try {
      setIsLoad(true);
      formChequeVista.current?.setErrors({});

      const chequeVista: IDropChequeVistaDTO = {
        id: v4(),
        order_in: itensChequeVista.length + 1,
        other_owner: cqaOtherOwner,
        kind_people: cqaKindPeople,
        cpf_cnpj: cqaCpfCnpj,
        titular: cqaTitular,
        bank_id: cqaBankId,
        bank_agence: cqaBankAgence,
        bank_account: cqaBankAccount,
        cheque_number: cqaChequeNumber,
        value: cqaValue,
        cheque_emited: cqaChequeEmited,
        cheque_due_date: cqaChequeDuoDate,
      };

      const validationChequeVista = Yup.object()
        .required()
        .shape({
          id: Yup.string()
            .uuid()
            .required('Falha ao carregar o id do cheque, atualize a página!'),
          order_in: Yup.number()
            .min(0)
            .required('Falha ao carregar a ordem do cheque atualize a página!'),
          other_owner: Yup.boolean(),
          kind_people: Yup.boolean().required(
            'Informe se é pessoa física ou jurídica o emissor do cheque!',
          ),
          cpf_cnpj: Yup.string()
            .min(11, 'Informe o cpf/cnpj do emissor do cheque!')
            .max(18, 'Informe o cpf/cnpj do emissor do cheque!')
            .required('Informe o cpf/cnpj do emissor do cheque!'),
          titular: Yup.string().required('Informe o titular do cheque!'),
          bank_id: Yup.string()
            .uuid('Informe o id do banco!')
            .required('Informe o banco do cheque!'),
          bank_agence: Yup.string()
            .min(3, 'Agência bancária deve ter no mínimo 3 caracteres!')
            .max(9, 'Agência bançaria deve ter no máximo 9 caracteres!')
            .required('Informe a agencia do cheque!'),
          bank_account: Yup.string().required('Informe a conta do cheque!'),
          cheque_number: Yup.string().required('Informe o número do cheque!'),
          value: Yup.number()
            .min(5, 'O valor do cheque deve ser maior que R$ 5,00')
            .required('Informe o valor do cheque!'),
          cheque_emited: Yup.date()
            .min(
              subMonths(hoje, 6),
              'Esse cheque foi emitido há mais de 6 meses, portanto já prescreveu! Procure seu cliente.',
            )
            .required(),
          cheque_due_date: Yup.date().max(
            endOfDay(hoje),
            'Esse é o formulário de cadastro de cheques à vista, para cheques a prazo procure o outro formulário!',
          ),
        });

      await validationChequeVista.validate(chequeVista, { abortEarly: false });

      setItensChequeVista([
        ...itensChequeVista.filter(r => r.id !== rowId),
        chequeVista,
      ]);

      clearFormChequeVista();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formChequeVista.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddChequePrazo = async (data: any) => {
    try {
      setIsLoad(true);
      formChequePrazo.current?.setErrors({});

      const chequePrazo: IDropChequePrazoDTO = {
        id: v4(),
        order_in: itensChequePrazo.length + 1,
        other_owner: cqpOtherOwner,
        kind_people: cqpKindPeople,
        cpf_cnpj: cqpCpfCnpj,
        titular: cqpTitular,
        bank_id: cqpBankId,
        bank_agence: cqpBankAgence,
        bank_account: cqpBankAccount,
        cheque_number: cqpChequeNumber,
        value: cqpValue,
        cheque_emited: cqpChequeEmited,
        cheque_due_date: cqpChequeDuoDate,
      };

      const validationChequePrazo = Yup.object()
        .required()
        .shape({
          id: Yup.string()
            .uuid()
            .required('Falha ao carregar o id do cheque, atualize a página!'),
          order_in: Yup.number()
            .min(0)
            .required('Falha ao carregar a ordem do cheque atualize a página!'),
          other_owner: Yup.boolean(),
          kind_people: Yup.boolean().required(
            'Informe se é pessoa física ou jurídica o emissor do cheque!',
          ),
          cpf_cnpj: Yup.string()
            .min(11, 'Informe o cpf/cnpj do emissor do cheque!')
            .max(18, 'Informe o cpf/cnpj do emissor do cheque!')
            .required('Informe o cpf/cnpj do emissor do cheque!'),
          titular: Yup.string().required('Informe o titular do cheque!'),
          bank_id: Yup.string()
            .uuid('Informe o id do banco!')
            .required('Informe o banco do cheque!'),
          bank_agence: Yup.string()
            .min(3, 'Agência bancária deve ter no mínimo 3 caracteres!')
            .max(9, 'Agência bançaria deve ter no máximo 9 caracteres!')
            .required('Informe a agencia do cheque!'),
          bank_account: Yup.string().required('Informe a conta do cheque!'),
          cheque_number: Yup.string().required('Informe o número do cheque!'),
          value: Yup.number()
            .min(5, 'O valor do cheque deve ser maior que R$ 5,00')
            .required('Informe o valor do cheque!'),
          cheque_emited: Yup.date()
            .min(
              subMonths(hoje, 6),
              'Esse cheque foi emitido há mais de 6 meses, portanto já prescreveu! Procure seu cliente.',
            )
            .required(),
          cheque_due_date: Yup.date().required(
            'Informe a data de vencimento do cheque!',
          ),
        });

      await validationChequePrazo.validate(chequePrazo, { abortEarly: false });

      setItensChequePrazo([
        ...itensChequePrazo.filter(r => r.id !== rowId),
        chequePrazo,
      ]);

      clearFormChequePrazo();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formChequePrazo.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddBoleto = async (data: any) => {
    try {
      setIsLoad(true);
      formBoleto.current?.setErrors({});

      const boleto: IDropBoletoDTO = {
        id: v4(),
        conta: bolContaId,
        data_pgto: bolPagamento,
        value: bolValor,
      };

      const validationBoleto = Yup.object()
        .required()
        .shape({
          conta: Yup.string()
            .uuid('Informe o id da conta!')
            .required('Informe uma conta!'),
          data_pgto: Yup.date().required('Informe a data de recebimento!'),
          value: Yup.number()
            .positive('Informe um valor maior que 0.')
            .required('Informe um valor!'),
        });

      await validationBoleto.validate(boleto, { abortEarly: false });

      setItensBoleto([...itensBoleto.filter(r => r.id !== rowId), boleto]);

      clearFormBoleto();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formBoleto.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddTed = async (data: any) => {
    try {
      setIsLoad(true);
      formTed.current?.setErrors({});

      const ted: IDropTedDTO = {
        id: v4(),
        conta_id: tedContaId,
        data_pgto: tedPagamento,
        value: tedValor,
      };

      const validationTed = Yup.object()
        .required()
        .shape({
          conta_id: Yup.string()
            .uuid('Informe o id da conta!')
            .required('Informe uma conta!'),
          data_pgto: Yup.date().required('Informe a data de recebimento!'),
          value: Yup.number()
            .positive('Informe um valor maior que 0.')
            .required('Informe um valor!'),
        });

      await validationTed.validate(ted, { abortEarly: false });

      setItensTed([...itensTed.filter(r => r.id !== rowId), ted]);

      clearFormTed();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formTed.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddDeposito = async (data: any) => {
    try {
      setIsLoad(true);
      formDeposito.current?.setErrors({});

      const deposito: IDropDepositoDTO = {
        id: v4(),
        conta: depContaId,
        data_pgto: depPagamento,
        value: depValor,
      };

      const validationDeposito = Yup.object()
        .required()
        .shape({
          conta: Yup.string()
            .uuid('Informe o id da conta!')
            .required('Informe uma conta!'),
          data_pgto: Yup.date().required('Informe a data de recebimento!'),
          value: Yup.number()
            .positive('Informe um valor maior que 0.')
            .required('Informe um valor!'),
        });

      await validationDeposito.validate(deposito, { abortEarly: false });

      setItensDeposito([
        ...itensDeposito.filter(r => r.id !== rowId),
        deposito,
      ]);

      clearFormDeposito();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formDeposito.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddPix = async (data: any) => {
    try {
      setIsLoad(true);
      formPix.current?.setErrors({});

      const pix: IDropPixDTO = {
        id: v4(),
        conta: pixConta,
        key: pixChave,
        value: pixValor,
        data_pgto: pixPagamento,
      };

      const validationPix = Yup.object()
        .required()
        .shape({
          conta: Yup.string()
            .uuid('Informe o id da conta!')
            .required('Informe uma conta!'),
          key: Yup.string()
            .uuid('Informe o id da chave pix!')
            .required('Informe uma chave pix!'),
          data_pgto: Yup.date().required('Informe a data de recebimento!'),
          value: Yup.number()
            .positive('Informe um valor maior que 0.')
            .required('Informe um valor!'),
        });

      await validationPix.validate(pix, { abortEarly: false });

      setItensPix([...itensPix.filter(r => r.id !== rowId), pix]);

      clearFormPix();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formPix.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddCartaoCredito = async (data: any) => {
    try {
      setIsLoad(true);
      formCartaoCredito.current?.setErrors({});

      const action = v4();
      const p = PerfectDivision(ccValor, ccParcela);
      const cc: IDropCartaoDTO[] = [];

      for (let i = 0; i < ccParcela; i++) {
        cc.push({
          id: v4(),
          insert: action,
          bandeira: ccBandeiraCartao,
          data_vencimento: addMonths(dataRecebimento, i + 1),
          parcela: i + 1,
          value: p[i],
        });
      }

      const objSchema = Yup.object().shape({
        id: Yup.string()
          .uuid('Informe o id da parcela!')
          .required('Informe uma parcela!'),
        insert: Yup.string()
          .uuid('Falha ao gerar o id de inserção!')
          .required('Falha ao gerar o id de inserção!'),
        bandeira: Yup.string().required('Informe a bandeira!'),
        data_vencimento: Yup.date().required(
          'Falha ao gerar a data de vencimento!',
        ),
        parcela: Yup.number().positive().required('Informe a parcela!'),
        value: Yup.number()
          .positive('O valor deve ser positivo!')
          .required('Informe o valor total!'),
      });
      const validationCC = Yup.array()
        .min(1, 'Informe pelo menos uma parcela!')
        .of(objSchema);

      await validationCC.validate(cc, { abortEarly: false });

      setItensCartaoCredito([...cc]);

      clearFormCartaoCredito();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formCartaoCredito.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleAddCartaoDebito = async (data: any) => {
    try {
      setIsLoad(true);
      formCartaoDebito.current?.setErrors({});

      const action = v4();
      const p = PerfectDivision(cdValor, cdParcela);
      const cd: IDropCartaoDTO[] = [];

      for (let i = 0; i < cdParcela; i++) {
        cd.push({
          id: v4(),
          insert: action,
          bandeira: cdBandeiraCartao,
          data_vencimento: addMonths(dataRecebimento, i + 1),
          parcela: i + 1,
          value: p[i],
        });
      }

      const objSchema = Yup.object().shape({
        id: Yup.string()
          .uuid('Informe o id da parcela!')
          .required('Informe uma parcela!'),
        insert: Yup.string()
          .uuid('Falha ao gerar o id de inserção!')
          .required('Falha ao gerar o id de inserção!'),
        bandeira: Yup.string().required('Informe a bandeira!'),
        data_vencimento: Yup.date().required(
          'Falha ao gerar a data de vencimento!',
        ),
        parcela: Yup.number().positive().required('Informe a parcela!'),
        value: Yup.number()
          .positive('O valor deve ser positivo!')
          .required('Informe o valor total!'),
      });
      const validationCd = Yup.array()
        .min(1, 'Informe pelo menos uma parcela!')
        .of(objSchema);

      await validationCd.validate(cd, { abortEarly: false });

      setItensCartaoDebito([...cd]);

      clearFormCartaoDebito();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formCartaoDebito.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleRemChequeVista = async (id: string) => {
    setItensChequeVista(itensChequeVista.filter(r => r.id !== id));
    clearFormChequeVista();
  };

  const handleRemChequePrazo = async (id: string) => {
    setItensChequePrazo(itensChequePrazo.filter(r => r.id !== id));
    clearFormChequePrazo();
  };

  const handleRemBoleto = async (id: string) => {
    setItensBoleto(itensBoleto.filter(r => r.id !== id));
    clearFormBoleto();
  };

  const handleRemTed = async (id: string) => {
    setItensTed(itensTed.filter(r => r.id !== id));
    clearFormTed();
  };

  const handleRemDeposito = async (id: string) => {
    setItensDeposito(itensDeposito.filter(r => r.id !== id));
    clearFormDeposito();
  };

  const handleRemPix = async (id: string) => {
    setItensPix(itensPix.filter(r => r.id !== id));
    clearFormPix;
  };

  const handleRemCartaoCredito = async (id: string) => {
    setItensCartaoCredito([]);
    clearFormCartaoCredito();
  };

  const handleRemCartaoDebito = async (id: string) => {
    setItensCartaoDebito([]);
    clearFormCartaoDebito();
  };

  const handleSelectChequeVista = async (id: string) => {
    const item = itensChequeVista.find(i => i.id === id);

    if (item) {
      setRowId(item.id);
      setCqaOtherOwner(item.other_owner);
      setCqaBankId(item.bank_id);
      setCqaBankAgence(item.bank_agence);
      setCqaBankAccount(item.bank_account);
      setCqaChequeNumber(item.cheque_number);
      setCqaValue(item.value);
      setCqaKindPeople(item.kind_people);
      setCqaCpfCnpj(item.cpf_cnpj);
      setCqaChequeEmited(item.cheque_emited);
      setCqaChequeDuoDate(item.cheque_due_date);
    } else {
      toast('warn', 'Alerta', 'Falha ao selecionar o cheque, tente novamente!');
      return;
    }
  };

  const handleSelectChequePrazo = async (id: string) => {
    const item = itensChequePrazo.find(i => i.id === id);

    if (item) {
      setRowId(item.id);
      setCqpOtherOwner(item.other_owner);
      setCqpBankId(item.bank_id);
      setCqpBankAgence(item.bank_agence);
      setCqpBankAccount(item.bank_account);
      setCqpChequeNumber(item.cheque_number);
      setCqpValue(item.value);
      setCqpKindPeople(item.kind_people);
      setCqpCpfCnpj(item.cpf_cnpj);
      setCqpChequeEmited(item.cheque_emited);
      setCqpChequeDuoDate(item.cheque_due_date);
    } else {
      toast('warn', 'Alerta', 'Falha ao selecionar o cheque, tente novamente!');
      return;
    }
  };

  const handleSelectBoleto = async (id: string) => {
    const item = itensBoleto.find(i => i.id === id);
    if (item) {
      setRowId(item.id);
      setBolContaId(item.conta);
      setBolPagamento(item.data_pgto as Date);
      setBolValor(item.value);
    } else {
      toast('warn', 'Alerta', 'Falha ao selecionar o boleto, tente novamente!');
      return;
    }
  };

  const handleSelectTed = async (id: string) => {
    const item = itensTed.find(i => i.id === id);
    if (item) {
      setRowId(item.id);
      setTedContaId(item.conta_id);
      setTedPagamento(item.data_pgto);
      setTedValor(item.value);
    } else {
      toast('warn', 'Alerta', 'Falha ao selecionar o TED, tente novamente!');
      return;
    }
  };

  const handleSelectDeposito = async (id: string) => {
    const item = itensDeposito.find(i => i.id === id);
    if (item) {
      setRowId(item.id);
      setDepContaId(item.conta);
      setDepPagamento(item.data_pgto);
      setDepValor(item.value);
    } else {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar o Deposito, tente novamente!',
      );
      return;
    }
  };

  const handleSelectPix = async (id: string) => {
    const item = itensPix.find(i => i.id === id);
    if (item) {
      setRowId(item.id);
      setPixConta(item.conta);
      setPixChave(item.key);
      setPixPagamento(item.data_pgto);
      setPixValor(item.value);
    } else {
      toast('warn', 'Alerta', 'Falha ao selecionar o Pix, tente novamente!');
      return;
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      setIsLoad(true);
      formSubmit.current?.setErrors({});

      const drop: IDropDTO = {
        customer_id: cliente.id,
        document: {
          document_id: contaReceber.id,
          data_recebimento: dataRecebimento as Date,
          desconto: desconto,
          outros: outros,
          valor_recebimento: valorRecebido,
        },
        drops: {
          dinheiro: {
            value: totalDigDinheiro > 0 ? totalDigDinheiro : 0,
          },
          chequesVista:
            itensChequeVista.length > 0
              ? itensChequeVista.map(i => {
                  return {
                    order_in: i.order_in as number,
                    other_owner: i.other_owner as boolean,
                    cpf_cnpj: i.cpf_cnpj,
                    titular: i.titular,
                    bank_id: i.bank_id,
                    bank_agence: i.bank_agence,
                    bank_account: i.bank_account,
                    cheque_number: i.cheque_number,
                    value: i.value as number,
                    cheque_emited: i.cheque_emited as Date,
                  };
                })
              : [],
          chequesPrazo:
            itensChequePrazo.length > 0
              ? itensChequePrazo.map(i => {
                  return {
                    order_in: i.order_in as number,
                    other_owner: i.other_owner as boolean,
                    cpf_cnpj: i.cpf_cnpj,
                    titular: i.titular,
                    bank_id: i.bank_id,
                    bank_agence: i.bank_agence,
                    bank_account: i.bank_account,
                    cheque_number: i.cheque_number,
                    value: i.value as number,
                    cheque_emited: i.cheque_emited as Date,
                    due_date: i.cheque_due_date as Date,
                  };
                })
              : [],
          boleto:
            itensBoleto.length > 0
              ? itensBoleto.map(i => {
                  return {
                    conta: i.conta,
                    value: i.value as number,
                    data_pgto: i.data_pgto as Date,
                  };
                })
              : [],
          teds:
            itensTed.length > 0
              ? itensTed.map(i => {
                  return {
                    conta: i.conta_id as string,
                    data_pgto: i.data_pgto as Date,
                    value: i.value as number,
                  };
                })
              : [],
          depositos:
            itensDeposito.length > 0
              ? itensDeposito.map(i => {
                  return {
                    conta: i.conta,
                    data_pgto: i.data_pgto as Date,
                    value: i.value as number,
                  };
                })
              : [],
          pix:
            itensPix.length > 0
              ? itensPix.map(i => {
                  return {
                    conta: i.conta,
                    key: i.key,
                    data_pgto: i.data_pgto as Date,
                    value: i.value as number,
                  };
                })
              : [],
          cartoesCredito:
            itensCartaoCredito.length > 0
              ? itensCartaoCredito.map(i => {
                  return {
                    bandeira: i.bandeira,
                    data_vencimento: i.data_vencimento as Date,
                    parcela: i.parcela as number,
                    value: i.value as number,
                  };
                })
              : [],
          cartoesDebito:
            itensCartaoDebito.length > 0
              ? itensCartaoDebito.map(i => {
                  return {
                    bandeira: i.bandeira,
                    data_vencimento: i.data_vencimento as Date,
                    parcela: i.parcela as number,
                    value: i.value as number,
                  };
                })
              : [],
        },
      };

      const validationChequeVista = Yup.object().shape({
        order_in: Yup.number().required(),
        other_owner: Yup.boolean().required(),
        cpf_cnpj: Yup.string().required(),
        titular: Yup.string().required(),
        bank_id: Yup.string().uuid().required(),
        bank_agence: Yup.string().required(),
        bank_account: Yup.string().required(),
        cheque_number: Yup.string().required(),
        value: Yup.number().positive().required(),
        cheque_emited: Yup.date().required(),
      });

      const validationChequePrazo = Yup.object().shape({
        order_in: Yup.number().required(),
        other_owner: Yup.boolean().required(),
        cpf_cnpj: Yup.string().required(),
        titular: Yup.string().required(),
        bank_id: Yup.string().uuid().required(),
        bank_agence: Yup.string().required(),
        bank_account: Yup.string().required(),
        cheque_number: Yup.string().required(),
        value: Yup.number().positive().required(),
        cheque_emited: Yup.date().required(),
        due_date: Yup.date().required(),
      });

      const validationBoleto = Yup.object().shape({
        conta: Yup.string().uuid().required(),
        value: Yup.number().min(0).required(),
        data_pgto: Yup.date().required(),
      });

      const validationPix = Yup.object().shape({
        conta: Yup.string().uuid().required(),
        key: Yup.string().required(),
        data_pgto: Yup.date().required(),
        value: Yup.number().positive().required(),
      });

      const validationCartao = Yup.object().shape({
        bandeira: Yup.string().required(),
        data_vencimento: Yup.date().required(),
        parcela: Yup.number().required(),
        value: Yup.number().positive().required(),
      });

      const validationSchema = Yup.object()
        .required()
        .shape({
          customer_id: Yup.string()
            .uuid('Informe o id do cliente!')
            .required('Informe o cliente!'),
          document: Yup.object()
            .required()
            .shape({
              document_id: Yup.string()
                .uuid('Informe o id do documento!')
                .required('Informe o documento a ser baixado!'),
              data_recebimento: Yup.date()
                .required('Informe a data de recebimento!')
                .default(hoje),
              desconto: Yup.number()
                .min(0, 'O valor mínimo de desconto é zero!')
                .max(
                  contaReceber.value_total,
                  `O valor máximo de desconto autorizado a se aplicar é ${new Intl.NumberFormat(
                    'BRL',
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                  ).format(contaReceber.value_total)}`,
                )
                .default(0),
              outros: Yup.number()
                .min(0, 'O valor mínimo do campo outros (add) é 0!')
                .default(0),
              valor_recebimento: Yup.number()
                .min(0)
                .max(
                  contaReceber.value_total,
                  `O valor máximo de recebimento do documento é de ${new Intl.NumberFormat(
                    'BRL',
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                  ).format(contaReceber.value_total)}!`,
                ),
            }),
          drops: Yup.object()
            .required()
            .shape({
              dinheiro: Yup.object().shape({
                value: Yup.number().min(0),
              }),
              chequesVista: Yup.array()
                .min(0)
                .of(validationChequeVista)
                .default([]),
              chequesPrazo: Yup.array()
                .min(0)
                .of(validationChequePrazo)
                .default([]),
              boleto: Yup.array().min(0).of(validationBoleto).default([]),
              teds: Yup.array().min(0).of(validationBoleto).default([]),
              depositos: Yup.array().min(0).of(validationBoleto).default([]),
              pix: Yup.array().min(0).of(validationPix).default([]),
              cartaoCredito: Yup.array()
                .min(0)
                .of(validationCartao)
                .default([]),
              cartaoDebito: Yup.array().min(0).of(validationCartao).default([]),
            }),
        });

      await validationSchema.validate(drop, { abortEarly: false });

      const res = await api.post('/bills-receivable/v2/drop', drop);

      if (res.status >= 200 || res.status <= 299) {
        toast('success', 'Sucesso', 'Baixa realizada com sucesso!');
      }

      navigate.push('/bills/receive/list');
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formSubmit.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  /** useEffect */
  useEffect(() => {
    if (params.id) {
      setIsLoad(true);
      api
        .get(`/bills-receivable/v2/${params.id}`)
        .then(({ data }) => {
          if (!data) {
            toast(
              'warn',
              'Alerta',
              'Falha ao carregar a conta a receber! Repita o processo!',
            );
            return;
          }

          setContaReceber({
            id: data[0].id,
            name: data[0].name,
            serie: data[0].serie,
            number: data[0].number,
            parcel: data[0].parcel,
            value: Number(data[0].value),
            value_total: data[0].value_total,
            duo_date: data[0].duo_date,
            emited_date: data[0].emited_date,
            days_delay: data[0].days_delay,
            juros: data[0].juros,
            multa: data[0].multa,
            total: data[0].total,
          });

          setCliente({
            id: data[0].cli_id,
            codigo: data[0].cli_codigo,
            name: data[0].name,
            block: '',
            cpf_cnpj: '',
            customer: '',
            image_url: '',
            status: '',
          });
        })
        .catch((e: any) => {
          toast('error', 'Erro', e.response.data.error);
          return;
        })
        .finally(() => setIsLoad(false));
    }
  }, []);

  useEffect(() => {
    /** carga inicial da janela */
    setIsLoad(true);
    api
      .get('/bancos')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo} - ${item.nome}`,
            value: item.id,
          };
        });
        setBancoOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          toast('error', 'Erro', e.response?.data.error);
          return;
        } else {
          toast('error', 'Erro', 'Falha ao buscar os bancos no sistema!');
          return;
        }
      })
      .finally(() => setIsLoad(false));

    setIsLoad(true);
    api
      .get('/business-banks-accounts')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.bank.codigo} - ag: ${item.bank_agence} - cc: ${item.bank_account} - titular: ${item.titular}`,
            value: item.id,
          };
        });

        setContaOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          toast('error', 'Erro', e.response?.data.error);
          return;
        } else {
          toast(
            'error',
            'Erro',
            'Falha ao buscar os contas bancarias no sistema!',
          );
          return;
        }
      })
      .finally(() => setIsLoad(false));

    setIsLoad(true);
    api
      .get('/business-pix-keys')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.key,
            value: item.id,
          };
        });

        setPixOpts(opts);
      })
      .catch((e: any) => {
        if (e instanceof AxiosError) {
          toast('error', 'Erro', e.response?.data.error);
          return;
        } else {
          toast('warning', 'Alerta', 'Falha ao buscar os pix no sistema!');
          return;
        }
      })
      .finally(() => setIsLoad(false));
  }, []);

  /** calcula totais digitados */
  useEffect(() => {
    setTotalDigChequeVista(
      itensChequeVista.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigChequePrazo(
      itensChequePrazo.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigBoleto(
      itensBoleto.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigTed(
      itensTed.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigDeposito(
      itensDeposito.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigPix(
      itensPix.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigCartaoCredito(
      itensCartaoCredito.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );

    setTotalDigCartaoDebito(
      itensCartaoDebito.reduce((acc, item) => {
        acc += item.value;
        return acc;
      }, 0),
    );
  }, [
    recDinheiro,
    itensChequeVista,
    itensChequePrazo,
    itensBoleto,
    itensTed,
    itensDeposito,
    itensPix,
    itensCartaoCredito,
    itensCartaoDebito,
  ]);

  /** calcula o restante de cada meio de pgto */
  useEffect(() => {
    setResDinheiro(recDinheiro - totalDigDinheiro);
    setResChequeVista(recChequeVista - totalDigChequeVista);
    setResChequePrazo(recChequePrazo - totalDigChequePrazo);
    setResBoleto(recBoleto - totalDigBoleto);
    setResTed(recTed - totalDigTed);
    setResDeposito(recDeposito - totalDigDeposito);
    setResPix(recPix - totalDigPix);
    setResCartaoCredito(recCartaoCredito - totalDigCartaoCredito);
    setResCartaoDebito(recCartaoDebito - totalDigCartaoDebito);
  }, [
    recDinheiro,
    totalDigDinheiro,
    recChequeVista,
    totalDigChequeVista,
    recChequePrazo,
    totalDigChequePrazo,
    recBoleto,
    totalDigBoleto,
    recTed,
    totalDigTed,
    recDeposito,
    totalDigDeposito,
    recPix,
    totalDigPix,
    recCartaoCredito,
    totalDigCartaoCredito,
    recCartaoDebito,
    totalDigCartaoDebito,
  ]);

  useEffect(() => {
    setRestante(
      totalDigDinheiro +
        totalDigChequeVista +
        totalDigChequePrazo +
        totalDigBoleto +
        totalDigTed +
        totalDigDeposito +
        totalDigPix +
        totalDigCartaoCredito +
        totalDigCartaoDebito,
    );
  }, [
    totalDigDinheiro,
    totalDigChequeVista,
    totalDigChequePrazo,
    totalDigBoleto,
    totalDigTed,
    totalDigDeposito,
    totalDigPix,
    totalDigCartaoCredito,
    totalDigCartaoDebito,
  ]);

  return (
    <>
      <BillsToReceivableHeader />
      {isLoad && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-12 sm:w-full"
        />
      )}
      <div className="card">
        <Form
          ref={formSubmit}
          onSubmit={(e: any) => handleSubmit(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="btn_search_customer">Cliente</label>
              <Button
                name="btn_search_customer"
                type="button"
                label={
                  cliente.id === ''
                    ? 'Buscar cliente...'
                    : `${cliente.codigo} - ${cliente.name}`
                }
                aria-haspopup
                onClick={() => setSideBarClienteVisible(true)}
                icon={cliente.id === '' ? 'pi pi-search' : 'fa-solid fa-check'}
                className={cliente.id === '' ? '' : 'p-button-secondary'}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="btn_search_document">Documento</label>
              <Button
                name="btn_search_document"
                type="button"
                label={
                  contaReceber.id === ''
                    ? 'Buscar documento...'
                    : `SR: ${contaReceber?.serie} NUM: ${
                        contaReceber.number
                      } PARCELA: ${contaReceber.parcel} VENC: ${format(
                        new Date(contaReceber.duo_date),
                        'dd/MM/yy',
                      )} VALOR: ${new Intl.NumberFormat('BRL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(contaReceber.value_total)}`
                }
                aria-haspopup
                onClick={() => setSideBarDocumentoVisible(true)}
                icon={
                  contaReceber.id === '' ? 'pi pi-search' : 'fa-solid fa-check'
                }
                className={contaReceber.id === '' ? '' : 'p-button-secondary'}
                disabled={cliente.id === '' ? true : false}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="valorDocumento">Valor documento</label>
              <InputNumber
                name="valorDocumento"
                className="surface-200 w-full"
                disabled
                prefix="R$ "
                value={Number(contaReceber.value_total) || 0}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="valorAmortizado">Valor amortizado</label>
              <InputNumber
                name="valorAmortizado"
                className="surface-200 w-full"
                disabled
                prefix="R$ "
                value={Number(contaReceber.value_total) || 0}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="firstname3">Valor receber</label>
              <InputNumber
                name="aux"
                className="surface-200 w-full"
                disabled
                prefix="R$ "
                value={Number(contaReceber.value_total) || 0}
                minFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_data_emissao">Data emissão</label>
              <Calendar
                name="doc_data_emissao"
                className="surface-200 w-full text-center"
                disabled
                value={new Date(contaReceber.emited_date)}
                placeholder="00/00/0000"
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="dataVencimento">Data vencimento</label>
              <Calendar
                name="dataVencimento"
                className="surface-200 w-full"
                disabled
                value={new Date(contaReceber.duo_date)}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="lastname3">Dias atraso</label>
              <InputText
                name="aux"
                className="surface-200 w-full"
                disabled
                value={contaReceber.days_delay || 0}
              />
            </div>

            <Divider align="left" type="dashed">
              <div className="inline-flex align-items-center">
                <span className="p-tag">INFORMAÇÕES</span>
              </div>
            </Divider>

            <div className="field col-12 md:col-2">
              <label htmlFor="calendar">Data recebimento</label>
              <Calendar
                name="calendar"
                value={dataRecebimento}
                onChange={e => setDataRecebimento(e.value as Date)}
                showIcon
                dateFormat="dd/mm/yy"
                disabled={contaReceber.id === '' ? true : false}
                placeholder="00/00/0000"
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_valor_recebido">Valor recebido</label>
              <InputNumber
                name="doc_valor_recebido"
                value={valorRecebido}
                onChange={e => setValorRecebido(e.value as number)}
                onFocus={() =>
                  setValorRecebido(Number(contaReceber.value_total))
                }
                max={(contaReceber.value_total as number) || 0}
                minFractionDigits={2}
                disabled={contaReceber.id === '' ? true : false}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_desconto">Desconto ( - )</label>
              <InputNumber
                name="doc_desconto"
                value={desconto}
                onValueChange={e => setDesconto((e.value as number) || 0)}
                max={(contaReceber.value_total as number) || 0}
                minFractionDigits={2}
                disabled={contaReceber.id === '' ? true : false}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_juros">Juros ( + )</label>
              <InputNumber
                name="doc_juros"
                value={(contaReceber.juros as number) || 0}
                disabled
                className="surface-200"
                minFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="doc_multa">Multa ( + )</label>
              <InputNumber
                name="doc_multa"
                value={(contaReceber.multa as number) || 0}
                disabled
                className="surface-200"
                minFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-1">
              <label htmlFor="doc_outros">Outros ( + )</label>
              <InputNumber
                value={outros}
                onValueChange={e => setOutros((e.value as number) || 0)}
                name="doc_outros"
                minFractionDigits={2}
                disabled={contaReceber.id === '' ? true : false}
              />
            </div>

            <div className="field col-12 md:col-1">
              <label htmlFor="doc_troco">Troco</label>
              <InputNumber
                name="doc_troco"
                disabled
                className="surface-200"
                minFractionDigits={2}
                placeholder="R$ 0,00"
              />
            </div>

            <Divider align="left" type="dashed">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  TIPOS DE RECEBIMENTO
                  {restante > 0
                    ? ` - DIGITAR: ${new Intl.NumberFormat('BRL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(valorRecebido - restante)}`
                    : ''}
                </span>
              </div>
            </Divider>
          </div>

          <TabView renderActiveOnly={false} scrollable>
            <TabPanel
              header={`Dinheiro ${
                totalDigDinheiro > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigDinheiro)
                  : ''
              }`}
              leftIcon="fa-solid fa-money-bill-1-wave mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resDinheiro)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recDinheiro}
                    onValueChange={e => setRecDinheiro(Number(e.value || 0))}
                    onFocus={() => setRecDinheiro(Number(valorRecebido))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled={valorRecebido <= 0 ? true : false}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="informado">Recebido</label>
                  <InputNumber
                    name="informado"
                    value={totalDigDinheiro}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label="Add. dinheiro"
                    onClick={() => checkToAddDinheiro()}
                    disabled={recDinheiro <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Cheque à vista ${
                totalDigChequeVista > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigChequeVista)
                  : ''
              }`}
              leftIcon="fa-solid fa-money-check-dollar mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resChequeVista)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recChequeVista}
                    onValueChange={e => setRecChequeVista(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    // max={valorRecebido}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigChequeVista}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensChequeVista.length === 0
                        ? 'Add. cheques'
                        : 'Exibir cheques'
                    }
                    onClick={() => checkToAddChequeVista()}
                    disabled={recChequeVista <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Cheque à prazo ${
                totalDigChequePrazo > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigChequePrazo)
                  : ''
              }`}
              leftIcon="fa-solid fa-money-check-dollar mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resChequePrazo)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recChequePrazo}
                    onValueChange={e => setRecChequePrazo(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    // max={valorRecebido}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigChequePrazo}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensChequePrazo.length === 0
                        ? 'Add. cheques'
                        : 'Exibir cheques'
                    }
                    onClick={() => checkToAddChequePrazo()}
                    disabled={recChequePrazo <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Boleto ${
                totalDigBoleto > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigBoleto)
                  : ''
              }`}
              leftIcon="fa-solid fa-barcode mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resBoleto)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recBoleto}
                    onValueChange={e => setRecBoleto(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigBoleto}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={itensBoleto ? 'Exibir boleto' : 'Add. boleto'}
                    onClick={() => checkToAddBoleto()}
                    disabled={recBoleto <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`TED ${
                totalDigTed > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigTed)
                  : ''
              }`}
              leftIcon="fa-solid fa-money-bill-transfer mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resTed)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recTed}
                    onValueChange={e => setRecTed(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigTed}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensBoleto.length > 0 ? 'Exibir TED`s' : 'Add. TED`s'
                    }
                    onClick={() => checkToAddTed()}
                    disabled={recTed <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Deposito ${
                totalDigDeposito > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigDeposito)
                  : ''
              }`}
              leftIcon="fa-solid fa-envelope-open-text mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resDeposito)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recDeposito}
                    onValueChange={e => setRecDeposito(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigDeposito}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensDeposito.length > 0
                        ? 'Exibir depositos'
                        : 'Add. depositos'
                    }
                    onClick={() => checkToAddDeposito()}
                    disabled={recDeposito <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`PIX ${
                totalDigPix > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigPix)
                  : ''
              }`}
              leftIcon="fa-brands fa-pix mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resPix)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recPix}
                    onValueChange={e => setRecPix(Number(e.value || 0))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigPix}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={itensPix.length > 0 ? 'Exibir PIX`s' : 'Add. PIX`s'}
                    onClick={() => checkToAddPix()}
                    disabled={recPix <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Cartão crédito ${
                totalDigCartaoCredito > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigCartaoCredito)
                  : ''
              }`}
              leftIcon="fa-solid fa-credit-card mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resCartaoCredito)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recCartaoCredito}
                    onValueChange={e => setRecCartaoCredito(Number(e.value))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigCartaoCredito}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensCartaoCredito.length > 0
                        ? 'Exibir parcelas cartão'
                        : 'Add. parcelas cartão'
                    }
                    onClick={() => checkToAddCartaoCredito()}
                    disabled={recCartaoCredito <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header={`Cartão debito ${
                totalDigCartaoDebito > 0
                  ? new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalDigCartaoDebito)
                  : ''
              }`}
              leftIcon="fa-solid fa-credit-card mr-2"
              disabled={valorRecebido <= 0 ? true : false}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">
                    Recebido
                    <Tag
                      className="mx-2 py-0 my-0"
                      icon="pi pi-info-circle"
                      severity="info"
                      value={`Restante: ${new Intl.NumberFormat('BRL', {
                        currency: 'BRL',
                        style: 'currency',
                      }).format(resCartaoDebito)}`}
                    ></Tag>
                  </label>
                  <InputNumber
                    name="aux"
                    value={recCartaoDebito}
                    onValueChange={e => setRecCartaoDebito(Number(e.value))}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="basic">Valor digitado</label>
                  <InputNumber
                    name="aux"
                    value={totalDigCartaoDebito}
                    mode="currency"
                    currency="BRL"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled
                    className="surface-200"
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end">
                  <Button
                    icon="fa-solid fa-plus"
                    label={
                      itensCartaoDebito.length > 0
                        ? 'Exibir parcelas cartão'
                        : 'Add. parcelas cartão'
                    }
                    onClick={() => checkToAddCartaoDebito()}
                    disabled={recCartaoDebito <= 0 ? true : false}
                    type="button"
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Baixar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={isLoad}
                disabled={valorRecebido !== restante ? true : false}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarChequesVista
        visible={sideBarChequesVistaVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarChequesVistaVisible(false)}
      >
        <Form
          ref={formChequeVista}
          onSubmit={(e: any) => handleAddChequeVista(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de cheques à vista</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recChequeVista || 0)}
                severity={'info'}
              ></Tag>
            </div>
            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigChequeVista)}
                severity={'success'}
              ></Tag>
            </div>
            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recChequeVista - totalDigChequeVista)}
                severity={'danger'}
              ></Tag>
            </div>
            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <div className="flex-align-items-center">
                <InputChecked
                  name="other_owner"
                  checked={cqaOtherOwner}
                  onChange={e => setCqaOtherOwner(e.checked)}
                />
                <label htmlFor="basic" className="ml-3">
                  Cheque em nomes de terceiros?
                </label>
              </div>
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="bank_id">Banco</label>
              <InputDropDown
                name="bank_id"
                options={bancoOpts}
                value={cqaBankId}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhum banco encontrado no sistema..."
                onChange={e => setCqaBankId(e.value)}
                placeholder="Selecione o banco..."
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="bank_agence">Agência</label>
              <InputText
                name="bank_agence"
                placeholder="0000-0"
                value={cqaBankAgence}
                onChange={e => setCqaBankAgence(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="bank_account">Conta</label>
              <InputText
                name="bank_account"
                placeholder="000000-0"
                value={cqaBankAccount}
                onChange={e => setCqaBankAccount(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="cheque_number">N. Cheque</label>
              <InputText
                name="cheque_number"
                placeholder="000000"
                value={cqaChequeNumber}
                onChange={e => setCqaChequeNumber(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={cqaValue}
                onValueChange={e => setCqaValue(Number(e.value))}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                // max={resChequeVista}
              />
            </div>
            <div className="field col-12 md:col-12">
              <div className="flex align-items-center">
                <label htmlFor="kind_people">Pessoa jurídica</label>
                <InputSwitch
                  checked={cqaKindPeople}
                  onChange={e => setCqaKindPeople(e.value)}
                  name="kind_people"
                  className="mx-3"
                />
                <label htmlFor="kind_people">Pessoa física</label>
              </div>
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="cpf_cnpj">{cqaKindPeople ? 'CPF' : 'CNPJ'}</label>
              <InputMask
                name="cpf_cnpj"
                mask={cqaKindPeople ? '999.999.999-99' : '99.999.999/9999-99'}
                placeholder={
                  cqaKindPeople ? '000.000.000-00' : '000.0000.0000/0000-00'
                }
                value={cqaCpfCnpj}
                onChange={e => setCqaCpfCnpj(e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="titular">Titular</label>
              <InputText
                name="titular"
                placeholder="Ex: João da Silva"
                value={cqaTitular}
                onChange={e => setCqaTitular(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cheque_emited">Emissão</label>
              <Calendar
                value={cqaChequeEmited}
                onChange={e => setCqaChequeEmited(e.value as Date)}
                name="cheque_emited"
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cheque_due_date">Vencimento</label>
              <Calendar
                value={cqaChequeDuoDate}
                onChange={e => setCqaChequeDuoDate(e.value as Date)}
                name="cheque_due_date"
                dateFormat="dd/mm/yy"
                showIcon
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button
                label="Adicionar"
                icon="pi pi-plus"
                type="submit"
                loading={isLoad}
              />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectChequeVista(e.data.id)}
              loading={isLoad}
              value={itensChequeVista}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum cheque cadastrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column field="order_in" header="#"></Column>
              <Column field="titular" header="Titular"></Column>
              <Column field="bank_agence" header="Agência"></Column>
              <Column field="bank_account" header="Conta"></Column>
              <Column field="cheque_number" header="N. Cheque"></Column>
              <Column
                field="cheque_emited"
                header="Emissão"
                body={r => renderColumnDate(r.cheque_emited)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                header="*"
                body={r => cqaRenderColumnButtonRemove(r.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarChequesVista>

      <SideBarChequesPrazo
        visible={sideBarChequesPrazoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarChequesPrazoVisible(false)}
      >
        <Form
          ref={formChequePrazo}
          onSubmit={e => handleAddChequePrazo(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de cheques à prazo</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recChequePrazo || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigChequePrazo)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recChequePrazo - totalDigChequePrazo)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>
            <div className="field col-12 md:col-6">
              <div className="flex-align-items-center">
                <InputChecked
                  name="other_owner"
                  checked={cqpOtherOwner}
                  onChange={e => setCqpOtherOwner(e.checked)}
                />
                <label htmlFor="basic" className="ml-3">
                  Cheque em nomes de terceiros?
                </label>
              </div>
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="bank_id">Banco</label>
              <InputDropDown
                name="bank_id"
                options={bancoOpts}
                value={cqpBankId}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhum banco encontrado no sistema..."
                onChange={e => setCqpBankId(e.value)}
                placeholder="Selecione o banco..."
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Agência</label>
              <InputText
                name="bank_agence"
                placeholder="0000-0"
                value={cqpBankAgence}
                onChange={e => setCqpBankAgence(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Conta</label>
              <InputText
                name="bank_account"
                placeholder="000000-0"
                value={cqpBankAccount}
                onChange={e => setCqpBankAccount(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">N. Cheque</label>
              <InputText
                name="cheque_number"
                placeholder="000000"
                value={cqpChequeNumber}
                onChange={e => setCqpChequeNumber(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={cqpValue}
                onValueChange={e => setCqpValue(Number(e.value))}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                // max={resChequeVista}
              />
            </div>
            <div className="field col-12 md:col-12">
              <div className="flex align-items-center">
                <label htmlFor="kind_people">Pessoa jurídica</label>
                <InputSwitch
                  checked={cqpKindPeople}
                  onChange={e => setCqpKindPeople(e.value)}
                  name="kind_people"
                  className="mx-3"
                />
                <label htmlFor="kind_people">Pessoa física</label>
              </div>
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="cpf_cnpj">{cqpKindPeople ? 'CPF' : 'CNPJ'}</label>
              <InputMask
                name="cpf_cnpj"
                mask={cqpKindPeople ? '999.999.999-99' : '99.999.999/9999-99'}
                placeholder={
                  cqpKindPeople ? '000.000.000-00' : '000.0000.0000/0000-00'
                }
                value={cqpCpfCnpj}
                onChange={e => setCqpCpfCnpj(e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="titular">Titular</label>
              <InputText
                name="titular"
                placeholder="Ex: João da Silva"
                value={cqpTitular}
                onChange={e => setCqpTitular(e.currentTarget.value)}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cheque_emited">Emissão</label>
              <Calendar
                value={cqpChequeEmited}
                onChange={e => setCqpChequeEmited(e.value as Date)}
                name="cheque_emited"
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="cheque_due_date">Vencimento</label>
              <Calendar
                value={cqpChequeDuoDate}
                onChange={e => setCqpChequeDuoDate(e.value as Date)}
                name="cheque_due_date"
                dateFormat="dd/mm/yy"
                showIcon
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button
                label="Adicionar"
                icon="pi pi-plus"
                type="submit"
                loading={isLoad}
              />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectChequePrazo(e.data.id)}
              loading={isLoad}
              value={itensChequePrazo}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum cheque cadastrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column field="order_in" header="#"></Column>
              <Column field="titular" header="Titular"></Column>
              <Column field="bank_agence" header="Agência"></Column>
              <Column field="bank_account" header="Conta"></Column>
              <Column field="cheque_number" header="N. Cheque"></Column>
              <Column
                field="cheque_emited"
                header="Emissão"
                body={r => renderColumnDate(r.cheque_emited)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                header="*"
                body={r => cqpRenderColumnButtonRemove(r.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarChequesPrazo>

      <SideBarBoleto
        visible={sideBarBoletoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarBoletoVisible(false)}
      >
        <Form
          ref={formBoleto}
          onSubmit={e => handleAddBoleto(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de boletos</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recBoleto || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigBoleto)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recBoleto - totalDigBoleto)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="conta">Conta</label>
              <InputDropDown
                name="conta"
                options={contaOpts}
                value={bolContaId}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma conta encontrada no sistema..."
                onChange={e => setBolContaId(e.value)}
                placeholder="Selecione a conta..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                inputId="minmaxfraction"
                value={bolValor}
                onValueChange={e => setBolValor(Number(e.value || 0))}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="data_pgto">Pagamento</label>
              <Calendar
                value={bolPagamento as Date}
                onChange={e => setBolPagamento(e.value as Date)}
                name="data_pgto"
                dateFormat="dd/mm/yy"
                placeholder="00/00/0000"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button
                label="Adicionar"
                icon={`fa-solid fa-plus`}
                type="submit"
              />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectBoleto(e.data.id)}
              loading={isLoad}
              value={itensBoleto}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum boleto cadastrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                header="Conta"
                body={e => renderColumnConta(e.conta)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="data_pgto"
                header="Recebimento"
                body={e => renderColumnDate(e.data_pgto)}
              ></Column>
              <Column
                header="*"
                body={e => bolRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarBoleto>

      <SideBarTed
        visible={sideBarTedVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarTedVisible(false)}
      >
        <Form
          ref={formTed}
          onSubmit={e => handleAddTed(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de TED`s</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recTed || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigTed)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recTed - totalDigTed)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="conta_id">Conta</label>
              <InputDropDown
                name="conta_id"
                options={contaOpts}
                value={tedContaId}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma conta encontrada no sistema..."
                onChange={e => setTedContaId(e.value)}
                placeholder="Selecione a conta..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                inputId="minmaxfraction"
                value={tedValor}
                onValueChange={e => setTedValor(e.value as number)}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="data_pgto">Pagamento</label>
              <Calendar
                value={tedPagamento}
                onChange={e => setTedPagamento(e.value as Date)}
                name="data_pgto"
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button label="Adicionar" icon="fa-solid fa-plus" type="submit" />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectTed(e.data.id)}
              loading={isLoad}
              value={itensTed}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum cheque cadastrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                header="Conta"
                body={e => renderColumnConta(e.conta_id)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="data_pgto"
                header="Recebimento"
                body={e => renderColumnDate(e.data_pgto)}
              ></Column>
              <Column
                header="*"
                body={e => tedRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarTed>

      <SideBarDeposito
        visible={sideBarDepositoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarDepositoVisible(false)}
      >
        <Form
          ref={formDeposito}
          onSubmit={e => handleAddDeposito(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de Depositos</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recDeposito || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigDeposito)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recDeposito - totalDigDeposito)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="conta">Conta</label>
              <InputDropDown
                name="conta"
                options={contaOpts}
                value={depContaId}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma conta encontrada no sistema..."
                onChange={e => setDepContaId(e.value)}
                placeholder="Selecione a conta..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={depValor}
                onValueChange={e => setDepValor(e.value as number)}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="basic">Pagamento</label>
              <Calendar
                value={depPagamento}
                onChange={e => setDepPagamento(e.value as Date)}
                name="cheque_emited"
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button label="Adicionar" icon="fa-solid fa-plus" type="submit" />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectDeposito(e.data.id)}
              loading={isLoad}
              value={itensDeposito}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum deposito cadastrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                header="Conta"
                body={e => renderColumnConta(e.conta)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="data_pgto"
                header="Depositado"
                body={e => renderColumnDate(e.data_pgto)}
              ></Column>
              <Column
                header="*"
                body={e => depRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarDeposito>

      <SideBarPix
        visible={sideBarPixVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarPixVisible(false)}
      >
        <Form
          ref={formPix}
          onSubmit={e => handleAddPix(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Cadastro de PIX</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recPix || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigPix)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recPix - totalDigPix)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="conta">Conta</label>
              <InputDropDown
                name="conta"
                options={contaOpts}
                value={pixConta}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma conta encontrada no sistema..."
                onChange={e => setPixConta(e.value)}
                placeholder="Selecione a conta..."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="key">Chave</label>
              <InputDropDown
                name="key"
                options={pixOpts}
                value={pixChave}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhum pix encontrado no sistema..."
                onChange={e => setPixChave(e.value)}
                placeholder="Selecione a chave pix..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={pixValor}
                onValueChange={e => setPixValor(e.value as number)}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>

            <div className="field col-12 md:col-2">
              <label htmlFor="basic">Data transferência</label>
              <Calendar
                value={pixPagamento}
                onChange={e => setPixPagamento(e.value as Date)}
                name="cheque_emited"
                dateFormat="dd/mm/yy"
                showIcon
                maxDate={hoje}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button label="Adicionar" icon="fa-solid fa-plus" type="submit" />
            </div>

            <DataTable
              selectionMode="single"
              onRowSelect={e => handleSelectPix(e.data.id)}
              loading={isLoad}
              value={itensPix}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhuma transferência via pix cadastrada..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                header="Conta"
                body={e => renderColumnConta(e.conta)}
              ></Column>
              <Column
                header="Conta"
                body={e => renderColumnChave(e.key)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="data_pgto"
                header="Recebimento"
                body={e => renderColumnDate(e.data_pgto)}
              ></Column>
              <Column
                header="*"
                body={e => pixRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarPix>

      <SideBarCartaoCredito
        visible={sideBarCartaoCreditoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarCartaoCreditoVisible(false)}
      >
        <Form
          ref={formCartaoCredito}
          onSubmit={e => handleAddCartaoCredito(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Lançamento cartão crédito</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recCartaoCredito || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigCartaoCredito)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recCartaoCredito - totalDigCartaoCredito)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="bandeira">Bandeira do cartão</label>
              <InputDropDown
                name="bandeira"
                options={bandeiraCartaoOpts}
                value={ccBandeiraCartao}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma bandeira cartão encontrada no sistema..."
                onChange={e => setCcBandeiraCartao(e.value)}
                placeholder="Selecione a bandeira..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="parcela">Parcelas</label>
              <InputNumber
                name="parcela"
                value={ccParcela}
                onChange={e => setCcParcela(e.value as number)}
                disabled={ccBandeiraCartao === '' ? true : false}
                className={`${ccBandeiraCartao === '' ? 'surface-200' : ''}`}
                placeholder="0"
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-danger"
                decrementButtonIcon="fa-solid fa-minus"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="fa-solid fa-plus"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={ccValor}
                onChange={e => setCcValor(e.value as number)}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                disabled={ccBandeiraCartao === '' ? true : false}
                className={`${ccBandeiraCartao === '' ? 'surface-200' : ''}`}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button label="Adicionar" icon="fa-solid fa-plus" type="submit" />
            </div>

            <DataTable
              selectionMode="single"
              // onRowSelect={e => handleSelectCartaoCredito(e.data.id)}
              loading={isLoad}
              value={itensCartaoCredito}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhuma lançamento de cartão crédito..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                field="bandeira"
                header="Bandeira"
                body={e => renderColumnBandeira(e.bandeira)}
              ></Column>
              <Column field="parcela" header="Parcela"></Column>
              <Column
                field="data_vencimento"
                header="Vencimento"
                body={e => renderColumnDate(e.data_vencimento)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                header="*"
                body={e => ccRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarCartaoCredito>

      <SideBarCartaoDebito
        visible={sideBarCartaoDebitoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarCartaoDebitoVisible(false)}
      >
        <Form
          ref={formCartaoDebito}
          onSubmit={e => handleAddCartaoDebito(e)}
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <h3 className="mb-3">Lançamento cartão debito</h3>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-3">
              Valor a pagar:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recCartaoDebito || 0)}
                severity={'info'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor digitado:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(totalDigCartaoDebito)}
                severity={'success'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Valor restante:{' '}
              <Tag
                value={new Intl.NumberFormat('BRL', {
                  currency: 'BRL',
                  style: 'currency',
                }).format(recCartaoDebito - totalDigCartaoDebito)}
                severity={'danger'}
              ></Tag>
            </div>

            <div className="field col-12 md:col-3">
              Troco:{' '}
              <Tag value="R$ 0,00" style={{ background: '#8E44AD' }}></Tag>
            </div>

            <Divider></Divider>

            <div className="field col-12 md:col-6">
              <label htmlFor="bandeira">Bandeira do cartão</label>
              <InputDropDown
                name="bandeira"
                options={bandeiraCartaoOpts}
                value={cdBandeiraCartao}
                filter
                showClear
                emptyFilterMessage="Nenhum item encontrado..."
                emptyMessage="Nenhuma bandeira cartão encontrada no sistema..."
                onChange={e => setCdBandeiraCartao(e.value)}
                placeholder="Selecione a bandeira..."
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="parcela">Parcelas</label>
              <InputNumber
                name="parcela"
                value={cdParcela}
                onChange={e => setCdParcela(e.value as number)}
                disabled={cdBandeiraCartao === '' ? true : false}
                className={`${cdBandeiraCartao === '' ? 'surface-200' : ''}`}
                placeholder="0"
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-danger"
                decrementButtonIcon="fa-solid fa-minus"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="fa-solid fa-plus"
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="value">Valor</label>
              <InputNumber
                value={cdValor}
                onChange={e => setCdValor(e.value as number)}
                name="value"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                disabled={cdBandeiraCartao === '' ? true : false}
                className={`${cdBandeiraCartao === '' ? 'surface-200' : ''}`}
              />
            </div>

            <div className="field col-12 md:col-2 flex align-items-end">
              <Button label="Adicionar" icon="fa-solid fa-plus" type="submit" />
            </div>

            <DataTable
              selectionMode="single"
              // onRowSelect={e => handleSelectCartaoCredito(e.data.id)}
              loading={isLoad}
              value={itensCartaoDebito}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhuma lançamento de cartão debito..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column
                field="bandeira"
                header="Bandeira"
                body={e => renderColumnBandeira(e.bandeira)}
              ></Column>
              <Column field="parcela" header="Parcela"></Column>
              <Column
                field="data_vencimento"
                header="Vencimento"
                body={e => renderColumnDate(e.data_vencimento)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                header="*"
                body={e => cdRenderColumnButtonRemove(e.id)}
              ></Column>
            </DataTable>
          </div>
        </Form>
      </SideBarCartaoDebito>

      <SideBarCliente
        visible={sideBarClienteVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarClienteVisible(false)}
      >
        <>
          <h3>Buscar cliente</h3>
          <Form
            ref={null}
            onSubmit={() => searchCustomer(clienteKeyword)}
            placeholder={''}
            onPointerEnterCapture={null}
            onPointerLeaveCapture={null}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-8">
                <InputText
                  name="customer_keyword"
                  placeholder="Ex.: João da Silva"
                  type={'text'}
                  value={clienteKeyword}
                  onChange={e => setClientekeyword(e.currentTarget.value)}
                  autoFocus={true}
                />
              </div>
              <div className="field col-12 md:col-4">
                <Button
                  label="Buscar"
                  icon="fa-solid fa-search"
                  iconPos="right"
                  type="submit"
                  disabled={clienteKeyword.length < 3 ? true : false}
                />
              </div>

              <DataTable
                selectionMode="single"
                loading={isLoad}
                value={clienteOpts}
                responsiveLayout="scroll"
                rows={99}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum cliente encontrado..."
                paginator={true}
                className="field col-12 md:col-12"
                onSelectionChange={e => handleSelectCustomer(e.value)}
              >
                <Column header="#" body={renderColumnPosition}></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="name" header="Nome"></Column>
                <Column
                  field="cpf_cnpj"
                  header="CPF/CNPJ"
                  body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                ></Column>
                <Column field="block" header="Bloqueado"></Column>
                <Column
                  field="status"
                  header="Status"
                  body={renderColumnStatus}
                ></Column>
              </DataTable>
            </div>
          </Form>
        </>
      </SideBarCliente>

      <SideBarDocumento
        visible={sideBarDocumentoVisible}
        position="right"
        style={{ width: '70vw' }}
        onHide={() => setSideBarDocumentoVisible(false)}
      >
        <>
          <h3>Buscar documento</h3>
          <div className="p-fluid grid formgrid">
            <div className="p-inputgroup field col-12 md:col-4">
              <span className="p-inputgroup-addon">Nome</span>
              <InputT
                name="document_keyword"
                placeholder="..."
                value={cliente.name || '[cliente não selecionado]'}
                readOnly
                disabled
              />
            </div>

            <div className="p-inputgroup field col-12 md:col-4">
              <span className="p-inputgroup-addon">Cpf/Cnpj</span>
              <InputM
                name="document_cpf_cnpj"
                placeholder={
                  cliente.cpf_cnpj.length === 11
                    ? '000.000.000-00'
                    : '00.000.000/0000-00'
                }
                value={cliente.cpf_cnpj || '-'}
                mask={
                  cliente.cpf_cnpj.length === 11
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                readOnly
                disabled
              />
            </div>

            <div className="field col-12 md:col-4 flex align-items-center">
              {cliente.id !== '' ? (
                cliente.block === 'S' ? (
                  <Tag
                    value="Bloqueado"
                    icon="fa-solid fa-lock"
                    rounded
                    className="px-3"
                    severity={'danger'}
                  />
                ) : (
                  <Tag
                    value="Habilitado"
                    icon="fa-solid fa-lock-open"
                    rounded
                    className="px-3"
                    severity={'success'}
                  />
                )
              ) : (
                ''
              )}
            </div>

            <DataTable
              header={headerDatatableDocument}
              globalFilter={globalFilter}
              selection={contaReceber}
              onSelectionChange={e => handleSelectDocument(e)}
              selectionMode="single"
              loading={isLoad}
              value={contaReceberOpts}
              responsiveLayout="scroll"
              rows={99}
              size="small"
              emptyMessage="Nenhum documento encontrado..."
              paginator={true}
              className="field col-12 md:col-12"
            >
              <Column
                field="id"
                header="#"
                body={renderColumnPosition}
              ></Column>
              <Column field="name" header="Nome"></Column>
              <Column field="serie" header="Série"></Column>
              <Column field="number" header="Número"></Column>
              <Column field="parcel" header="Parcela"></Column>
              <Column
                field="duo_date"
                header="Vencimento"
                body={e => renderColumnDate(e.duo_date)}
              ></Column>
              <Column
                field="value"
                header="Valor"
                body={e => renderColumnDecimal(e.value)}
              ></Column>
              <Column
                field="days_delay"
                header="Atraso"
                body={e => renderColumnPositiveNegative(e.days_delay)}
              ></Column>
              <Column
                field="juros"
                header="Juros"
                body={e => renderColumnDecimal(e.juros)}
              ></Column>
              <Column
                field="multa"
                header="Multa"
                body={e => renderColumnDecimal(e.multa)}
              ></Column>
              <Column
                field="total"
                header="Total"
                body={e => renderColumnDecimal(e.total)}
              ></Column>
            </DataTable>
          </div>
        </>
      </SideBarDocumento>
    </>
  );
};

export default dropBillReceivable;
