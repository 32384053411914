import { IConDevolution } from '../dtos/IConDevolutionDTO';

export const emptyConDevolution: IConDevolution = {
  id: '',
  b_id: '',
  route_id: '',
  route: {
    id: '',
    sequence: 0,
  },
  sequence: 0,
  customer_id: '',
  customer: {
    id: '',
    name: '',
  },
  table_price_id: '',
  date_digitation: new Date(),
  date_devolution: null,
  nat_ope_id: '',
  nfe_emited: false,
  qnt_products: 0,
  qnt_items: 0,
  total: 0,
  checked: false,
  type_order: '',
  obs: '',
  items: [],
  status: '',
  user_id: '',
  user: {
    id: '',
    name: '',
  },
  created_at: new Date(),
  updated_at: new Date(),
};
