import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** types */
import { Option } from '../../../types/optional';

/** api */
import api from '../../../services/api';

/** utils */
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';

/** components */
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';

import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import OrderHeaderButtons from '../../order/header-buttons';
import useSearchCustomer from '../../../hooks/useSearchCustomer';

const ReportOrder = (): React.ReactElement => {
  const form = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [statusOpts, setStatusOpts] = useState<Array<IOptionsDTO>>([
    { label: 'Todos', value: 'T' },
    { label: 'Ativos', value: 'A' },
    { label: 'Cancelados', value: 'C' },
  ]);
  const [status, setStatus] = useState<string>('T');

  const [commisioneds, setCommisioneds] = useState<IOptionsDTO[]>([]);
  const [comissioned, setComissioned] = useState<string>('');

  const [users, setUsers] = useState<Array<IOptionsDTO>>([]);
  const [user, setUser] = useState<string>('');

  /** customer */
  const [customer, setSearchCustomer] = useState<any>();
  const [customers, setCustomers] = useState<any[]>([]);

  /** operation nature */
  const [natOpes, setNatOpes] = useState<Array<Option>>([]);
  const [natOpe, setNatOpe] = useState<string>('');

  const date = new Date();

  const [initialDate, setInitialDate] = useState<Date | Date[] | undefined>(
    startOfDay(new Date(date.getFullYear(), date.getMonth(), 1)),
  );
  const [finalDate, setFinalDate] = useState<Date | Date[] | undefined>(
    endOfDay(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
  );

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const listUsers = async () => {
    setIsLoad(true);
    await api
      .post(`/users/list`, {
        pagination: {
          page: 1,
          perPage: 1000,
          status: ['A'],
        },
      })
      .then(d => {
        const opts = d.data.map((i: any) => {
          return {
            value: i.id,
            label: i.name,
          };
        });
        setUsers(opts);
      })
      .catch((e: any) => {
        toast('error', 'Erro', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const handleReport = async (data: any) => {
    setIsLoad(true);
    try {
      const { data: response } = await api.post('/reports/v2/orders/list', {
        filter: {
          date_start: `${startOfDay(initialDate as Date)}`,
          date_end: `${endOfDay(finalDate as Date)}`,
          commissioned_id: comissioned || null,
          nat_operation_id: natOpe || null,
          customer_id: customer?.value || null,
          user_id: user || null,
          status,
        },
      });

      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (e: any) {
      toast('error', 'Erro', messageRequestError(e));
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    setIsLoad(true);
    api
      .get(`/commissioneds`)
      .then(({ data }) => {
        const optComissioneds = data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setCommisioneds(optComissioneds);
      })
      .finally(() => setIsLoad(false));

    setIsLoad(true);
    api
      .post(`/naturezas-operacao/list/short`, {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setNatOpes(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      })
      .finally(() => setIsLoad(false));

    listUsers();
  }, []);

  return (
    <>
      <OrderHeaderButtons />
      <div className="card md:w-6 sm:w-full">
        <Form
          ref={form}
          onSubmit={handleReport}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerLeaveCapture={null}
          onPointerEnterCapture={null}
        >
          <div className="field col-6">
            <label htmlFor="commissioned_id">Comissionado</label>
            <InputDropDown
              name="commissioned_id"
              value={comissioned}
              options={commisioneds}
              placeholder="Selecionar..."
              onChange={e => setComissioned(e.value)}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="user_id">Usuário</label>
            <InputDropDown
              name="user_id"
              options={users}
              value={user}
              placeholder="Selecionar..."
              onChange={e => setUser(e.value)}
            />
          </div>
          <div className="field col-12">
            <label htmlFor="">Cliente</label>
            <InputAutoComplete
              name="customerId"
              value={customer}
              completeMethod={e => searchCustomer(e.query)}
              suggestions={customers}
              field="label"
              onChange={e => setSearchCustomer(e.value)}
              itemTemplate={itemTemplate}
              placeholder="Buscar cliente..."
            />
          </div>
          <div className="field col-12">
            <label htmlFor="">Natureza de operação</label>
            <InputDropDown
              name="natOperationId"
              options={natOpes}
              value={natOpe}
              placeholder="Selecionar..."
              onChange={e => setNatOpe(e.value)}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="status">Status pedidos</label>
            <InputDropDown
              name="status"
              options={statusOpts}
              value={status}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
            />
          </div>
          <div className="field col-4">
            <label htmlFor="">Data inicial</label>
            <Calendar
              name="initial_date"
              value={initialDate}
              onChange={e => setInitialDate(e.value)}
              dateFormat="dd/mm/yy"
              showIcon
              iconPos="right"
            />
          </div>
          <div className="field col-4">
            <label htmlFor="">Data final</label>
            <Calendar
              name="final_date"
              value={finalDate}
              onChange={e => setFinalDate(e.value)}
              dateFormat="dd/mm/yy"
              showIcon
              iconPos="right"
            />
          </div>
          <div className="field col-12">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportOrder;
