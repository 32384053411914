import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Toast } from 'primereact/toast';
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';

/** menu options */

const TagList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /** tag */
  const [tags, setTags] = useState<Array<any>>([]);
  const [tag, setTag] = useState<any>();

  /* navigate */
  const navigate = useHistory();

  /** dialog */
  const [deleteTageDialog, setDeleteTagDialog] = useState(false);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** toast */
  const toast = useRef<any>(null);

  const hideDeleteTageDialog = () => {
    setDeleteTagDialog(false);
  };

  const deleteTagApi = async () => {
    try {
      await api.delete(`/tags/${tag.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteTag = () => {
    const _tags = tags.filter((val: any) => val.id !== tag.id);
    setTags(_tags);
    setDeleteTagDialog(false);
    setTag(undefined);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Tag Deletada',
      life: 3000,
    });
    deleteTagApi();
  };

  const deleteTablePriceDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteTageDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteTag}
      />
    </React.Fragment>
  );

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const confirmDeleteTag = (store: any) => {
    setTag(store);
    setDeleteTagDialog(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/tags/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteTag(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Tags</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const searchTags = async () => {
    try {
      const { data } = await api.get(`/tags`);
      setTags(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchTags();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/tags/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={tags}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} tags"
          globalFilter={globalFilter}
          emptyMessage="Não existe Tags."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="tag" header="Título"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '10%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={deleteTageDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteTablePriceDialogFooter}
        onHide={hideDeleteTageDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {tag && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>{tag?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default TagList;
