import React, { useEffect, useRef } from 'react';

/* unform */
import { useField } from '@unform/core';

/* password primereact */
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';

/* props inputText */
interface PropsInput extends AutoCompleteProps {
  name: string;
}

export const InputAutoComplete: React.FC<PropsInput> = ({ name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <AutoComplete
      inputRef={inputRef}
      {...rest}
      className={error ? 'p-invalid' : ''}
    />
  );
};
