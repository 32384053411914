export default function toNumber(
  value: number | string,
  decimalHouses?: number,
): number {
  // Converte o value para um número
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Verifica se o valor é um número válido
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(numberValue)) {
    // Aplica formatação para duas casas decimais e
    // converte de volta para número
    const formattedNumber = parseFloat(numberValue.toFixed(decimalHouses || 2));
    return formattedNumber;
  }
  throw new Error('Valor inválido');
}
