import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import { INatureOperation } from '../dtos/INatureOperation';

const ListOperationNature = (): React.ReactElement => {
  const toast = useToastContext();
  const [natureOperations, setNatureOperations] = useState<INatureOperation[]>(
    [],
  );

  const [natureOperation, setNatureOperation] = useState<any>({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [dialogDeleteNature, setDialogDeleteNature] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const cfopInOutBodyTemplate = (rowData: any) => {
    return (
      <>
        <span>
          {rowData.cfopIn ? rowData.cfopIn.codigo : '0000'} -{' '}
          {rowData.cfopOut ? rowData.cfopOut.codigo : '0000'}
        </span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status === 'A' && (
          <i className="fa-solid fa-check text-green-500"></i>
        )}
      </>
    );
  };

  const actionBodyTemplate = (row: any) => {
    return (
      <div className="flex justify-content-end">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/operation-nature/edit/${row.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => handleRemoveNatureOperation(row.id)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Naturezas de operação</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const hideDeleteNatureDialog = () => {
    setDialogDeleteNature(false);
  };

  const handleRemoveNatureOperation = async (id: string) => {
    setLoading(true);
    await api
      .delete(`/naturezas-operacao/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Natureza removida com sucesso!');
          loadNatureOperations();
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadNatureOperations = async () => {
    setLoading(true);
    await api
      .post(`/naturezas-operacao/list`, {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['entrada', 'saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        setNatureOperations(data);
      })
      .catch(err => null)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadNatureOperations();
  }, [, natureOperation]);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/operation-nature/create')}
      />
      <div className="card">
        <DataTable
          value={natureOperations}
          selection={natureOperation}
          onSelectionChange={e => setNatureOperation(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} naturezas"
          globalFilter={globalFilter}
          emptyMessage="Não existe natureza de operações."
          header={header}
          responsiveLayout="scroll"
        >
          <Column field="code" header="N°" body={codeBodyTemplate}></Column>
          <Column field="title" header="Título"></Column>
          <Column header="Aplicação" field="application.title"></Column>
          <Column
            field="price"
            header="CFOP Entrada/Saída"
            body={cfopInOutBodyTemplate}
          ></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default ListOperationNature;
