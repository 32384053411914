import React, { useEffect, useRef, useState } from 'react';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../../components/DataTable';

/** history */
import { useHistory } from 'react-router-dom';

/** services */
import { Toast } from 'primereact/toast';
import api from '../../../../services/api';

/** menu options */

const SetupList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState('');

  /* setups */
  const [setups, setSetups] = useState<Array<any>>([]);

  /** navigate */
  const navigate = useHistory();

  /** toast */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/boleto/v1/setup/update/${rowData.id}`)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">SETUPS</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const searchSetup = async () => {
    try {
      const { data } = await api.get('/v2/boleto/setup');
      setSetups(data.setups);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchSetup();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/boleto/v1/setup/create')}
      />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={setups}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} setup boletos"
          globalFilter={globalFilter}
          emptyMessage="Não existe setup."
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column field="title" header="Título"></Column>
          <Column
            field="titulo_doc_especie"
            header="Espécie de documento"
          ></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default SetupList;
