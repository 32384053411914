import React, { useEffect, useRef, useState } from 'react';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** services */
import api from '../../../services/api';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** options */
import { Option } from '../../../types/optional';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText as InputTe } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputText } from '../../../components/Inputs/InputText';
import { currencyToFloat, numberToCurrency } from '../../../utils/number';
import InventoryHeaderButtons from '../header-buttons';

const orderOptions = [
  {
    label: 'SKU do Produto',
    value: 'ID',
  },
  {
    label: 'Descrição do produto',
    value: 'DE',
  },
  {
    label: 'Sequencia de inserção',
    value: 'SE',
  },
];

const InventoryClose = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  const [today, setToday] = useState(new Date());
  const [inventoryOpened, setOpenedInventory] = useState<Option[]>([]);
  const [inventory, setInventory] = useState<any>();
  const [selectedInventory, setSelectedInventory] = useState('');
  const [selectedOrder, setSelectedOrder] = useState();
  const [, setStatus] = useState('');
  const [teamAccount, setTeamAccount] = useState('');
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** navigate */
  const navigate = useHistory();

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      data.inventory_id = selectedInventory;
      data.team_count =
        teamAccount && teamAccount !== null ? teamAccount : 'Não informado';

      const schemaInventory = Yup.object({
        inventory_id: Yup.string().required(
          'Por favor, selecione o inventário',
        ),
        team_count: Yup.string().required(
          'Por favor, selecione à equipe de contagem',
        ),
      });
      await schemaInventory.validate(data, { abortEarly: false });

      await api.post('/inventory/close', {
        ...data,
        obs: data.team_count,
      });
      navigate.push('/reports/invetory/diff');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchInventory = async (id: string) => {
    try {
      const { data } = await api.get(`inventory/full/${id}`);
      setInventory(data.items);
      setTeamAccount(data.items[0].team_count);
      const diference = currencyToFloat(data.items.qnt_diferenca);
      if (diference === 0) {
        setStatus('igual');
      }
      if (diference < 0) {
        setStatus('faltando');
      }
      if (diference > 0) {
        setStatus('sobrando');
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  const columnBodyDigit = (rowData: any, column: string) => {
    return <span>{numberToCurrency(parseFloat(rowData[column]))}</span>;
  };

  const columnStatus = (rowData: any) => {
    const diference = parseFloat(rowData.qnt_diferenca);
    return diference !== 0 ? (
      <i className="pi pi-circle-fill" style={{ color: 'red' }}></i>
    ) : (
      <i className="pi pi-circle-fill" style={{ color: 'green' }}></i>
    );
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/inventory/li/short/opened')
      .then(({ data }) => {
        const inventoryOpened = data.map((item: any) => {
          return {
            label: `${item.num_sequencia} - Lote`,
            value: item.id,
          };
        });
        setOpenedInventory(inventoryOpened);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  useEffect(() => {
    if (selectedInventory?.length > 0) {
      searchInventory(selectedInventory);
    }
  }, [selectedInventory]);

  return (
    <>
      <InventoryHeaderButtons />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Toast ref={toast} />
        {loadingStatus && (
          <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
        )}
        <div className="card">
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Lote do Inventário</label>
              <InputDropDown
                name="inventory_id"
                options={inventoryOpened}
                placeholder="Selecionar"
                value={selectedInventory}
                onChange={e => setSelectedInventory(e.value)}
              />
            </div>
            <div className="field col-12 md:col-2">
              <label htmlFor="dropdown">Data Início</label>
              <Calendar
                value={(today, setToday)}
                showIcon
                disabled
                dateFormat="d/m/yy"
                className="surface-200"
                placeholder="00/00/00"
              />
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor="dropdown">Seq. Ini.</label>
              <InputTe
                disabled
                className="surface-200"
                value={selectedInventory ? '1' : '00'}
              />
            </div>
            <div className="field col-12 md:col-1">
              <label htmlFor="dropdown">Seq. Fin.</label>

              <InputTe
                disabled
                className="surface-200"
                value={selectedInventory ? '1' : '00'}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="dropdown">Ordem</label>
              <Dropdown
                options={orderOptions}
                value={selectedOrder}
                placeholder="Selecionar..."
                onChange={e => setSelectedOrder(e.value)}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Equipe contagem</label>
              <InputText
                name="team_count"
                placeholder="Equipe"
                value={teamAccount}
                onChange={e => setTeamAccount(e.currentTarget.value)}
                readOnly
                className="surface-200"
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end">
              <Button
                label="Limpar"
                className="p-button-raised p-button-danger"
                type="reset"
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <DataTable
                value={inventory}
                responsiveLayout="scroll"
                emptyMessage="Nenhuma opção"
                paginator
                rows={100}
                rowsPerPageOptions={[100, 150, 200]}
                size="small"
              >
                <Column field="sequence" header="SEQ"></Column>
                <Column field="product.sku" header="SKU"></Column>
                <Column field="product.title" header="Descrição"></Column>
                <Column field="product.unity" header="UND"></Column>
                <Column
                  field="product.stock"
                  header="Estoque"
                  body={rowData => columnBodyDigit(rowData, 'qnt_estoque')}
                ></Column>
                <Column
                  field="product.stock"
                  header="Digitado"
                  body={rowData => columnBodyDigit(rowData, 'qnt_contada')}
                ></Column>
                <Column
                  field="product.stock"
                  header="Diferença"
                  body={rowData => columnBodyDigit(rowData, 'qnt_diferenca')}
                ></Column>
                <Column
                  header="Status"
                  body={columnStatus}
                  align="center"
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Encerrar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default InventoryClose;
