import React, { useEffect, useRef, useState } from 'react';

/* unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** yup */
import * as Yup from 'yup';

/** services */
import api from '../../../services/api';

/** react-router-dom */
import { useHistory, useParams } from 'react-router-dom';

/* typing */
import { Additional } from '../../../types/additional';
import { AdditionalItem } from '../../../types/additionalItem';
import { Option } from '../../../types/optional';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** components */
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import { currencyToFloat } from '../../../utils/number';

const AdditionalItemEdit = (): React.ReactElement => {
  /** state */
  const [totalSize, setTotalSize] = useState(0);
  const [optionAdditional, setOptionAddition] = useState<Option[]>();
  const [additionalById, setSelectAdditionalById] = useState<any>(null);
  const [, setItemAdditional] = useState<AdditionalItem>();
  const [valueDefault, setDefaultValue] = useState();
  const [description, setDescription] = useState('');
  const [figure, setFigure] = useState<string | undefined>('');
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  /** params */
  const params: { id: string } = useParams();

  /** form ref */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);

  /** toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** navigate */
  const navigate = useHistory();

  /** form submited */
  const handleSubmit = async (data: AdditionalItem) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});
      const schmemaAdditional = Yup.object({
        title: Yup.string().required('Por Favor, Informe um Título'),
        description: Yup.string(),
        unt: Yup.number()
          .typeError('Informe um número')
          .required('Por Favor, Informe uma Quantidade'),
        value: Yup.string().required('Por Favor, Informe um Valor'),
      });
      await schmemaAdditional.validate(data, { abortEarly: false });

      const form = new FormData();

      form.append('additional_id', data.additional_id);
      form.append('title', data.title);
      form.append('description', data.description);
      form.append(
        'image_url',
        fileUploadRef.current.files ? fileUploadRef.current?.files[0] : figure,
      );
      form.append('unt', `${data.unt}`);
      form.append('value', `${currencyToFloat(data.value)}`);
      form.append('status', 'A');

      if (fileUploadRef.current.files || !figure) {
        await api.delete(`/additionals-itens/image/del/${params.id}`);
      }

      await api.put(`/additionals-itens/${params.id}`, form);
      showToast('success', 'Sucesso', 'Adicional Atualizado');
      navigate.push('/additionals-items/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const onAdditionalChange = (e: { value: string }) => {
    setSelectAdditionalById(e.value);
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 33 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return !figure ? (
      <>
        <div className="flex align-items-center flex-column">
          <i
            className="pi pi-image mt-3 p-5"
            style={{
              fontSize: '5em',
              borderRadius: '50%',
              backgroundColor: 'var(--surface-b)',
              color: 'var(--surface-d)',
            }}
          ></i>
          <span
            style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
            className="my-5"
          >
            Arraste e solte a imagem aqui
          </span>
        </div>
      </>
    ) : (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt=""
            role="presentation"
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${figure}`}
            width={100}
          />
        </div>

        <Button
          type="button"
          icon="pi pi-times"
          style={{ width: 33 }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => setFigure('')}
        />
      </div>
    );
  };

  const chooseOptions = {
    icon: '',
    className: 'p-button p-component',
    label: 'Selecionar Imagem',
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('additionals')
      .then(({ data }) => {
        const options = data.map((item: Additional) => {
          return {
            label: item.title,
            value: item.id,
            figure: item.image_url,
          };
        });
        setOptionAddition(options);
      })
      .finally(() => setLoadingStatus(false));
    setLoadingStatus(true);
    api
      .get(`/additionals-itens/${params.id}`)
      .then(({ data }) => {
        setItemAdditional(data);
        setSelectAdditionalById(data.additional_id);
        setFigure(data.image_url);
        setDefaultValue(data.value);
        formRef.current?.setData({ title: data.title });
        formRef.current?.setData({ unt: data.unt });
        setDescription(data.description);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/additionals-items/list')}
      />
      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card w">
        <Toast ref={toast} />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Grupos Adicionais</label>
              <InputDropDown
                placeholder="Selecione"
                name="additional_id"
                value={additionalById}
                options={optionAdditional}
                onChange={onAdditionalChange}
                disabled
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex">
            <div className="col p-0 min-width-300">
              <div className="field col-12 md:col">
                <label htmlFor="basic">Título</label>
                <InputText placeholder="Nome do item" name="title" />
              </div>
              <div className="field col-12 md:col">
                <label htmlFor="basic">Descrição</label>
                <InputTextEdit
                  name="description"
                  style={{ height: 150 }}
                  value={description}
                />
              </div>
            </div>
            <div className="col p-0 max-w-300">
              <div className="field col-12 md:col mt-5">
                <FileUpload
                  ref={fileUploadRef}
                  name="demo"
                  url="./upload"
                  headerTemplate={headerTemplate}
                  accept="image/*"
                  itemTemplate={itemTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                />
              </div>
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Qnt</label>
              <InputNumber name="unt" showButtons />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Valor</label>
              <InputNumber
                name="value"
                value={valueDefault}
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
          </div>
          <div className="col flex justify-content-end">
            <Button
              label="Cancelar"
              className="p-button-lg p-button-raised p-button-danger mr-3"
            />
            <Button
              label="Salvar"
              className="p-button-lg"
              loading={loadingStatus}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AdditionalItemEdit;
