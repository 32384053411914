interface IRequestDTO {
  number: string | number;
}
export default function CompletNumberWithZeros({
  number,
}: IRequestDTO): string {
  const oldNumber = number.toString();
  const fullNumber = '0000000000000';
  const newNumber = `${fullNumber.slice(
    0,
    fullNumber.length - oldNumber.length,
  )}${oldNumber}`;

  return newNumber;
}
