import React, { useEffect, useRef, useState } from 'react';

/** yup */
import * as Yup from 'yup';

/** unform */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** services */
import api from '../../../services/api';

/** types */
import { BankModel } from '../../../types/banks';

/* components **/
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText as InText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';
import { currencyToFloat } from '../../../utils/number';

const typeAccount = [
  { label: 'Poupança', value: 'P' },
  { label: 'Corrente', value: 'C' },
  { label: 'Simples', value: 'S' },
];

const BankAccountCreate = (): React.ReactElement => {
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [selectBankAside, setSelectBankAside] = useState(false);
  /** select one bank in aside */
  const [selectedBank, setBank] = useState<BankModel>({} as BankModel);
  /** width aside */
  const [width, setWith] = useState('60vw');
  /** all banks */
  const [banks, setBanks] = useState();
  /** filter table */
  const [globalFilter, setGlobalFilter] = useState('');
  /** selected type account */
  const [typeAccountSelected, setSelectTypeAccount] = useState('');
  /** error select bank */
  const [errorBank, setErrorBank] = useState(false);
  /** agence */
  const [agence, setAgence] = useState('');
  /** account */
  const [account, setAccount] = useState('');

  /** refs */
  const formRef = useRef<FormHandles>(null);
  const toast = useRef<any>(null);

  /** router */
  const navigate = useHistory();

  /** toast  */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  /** table */
  const codeBodyTemplate = (rowData: BankModel, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const header = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left w-full">
          <i className="pi pi-search" />
          <InText
            name="search"
            value={globalFilter}
            onChange={e => setGlobalFilter(e.target.value)}
            placeholder="Buscar banco..."
            className="w-full"
          />
        </span>
      </div>
    );
  };

  /** handle account bank */
  const handleSubmit = async (data: any) => {
    try {
      setLoadingStatus(true);
      formRef.current?.setErrors({});

      const schemaCreateAccount = Yup.object({
        bank_id: Yup.string().required('Por favor, informe o banco!'),
        bank_agence: Yup.string().required('Por favor, informe uma agência!'),
        bank_account: Yup.string().required('Por favor, informe uma conta!'),
        cnab: Yup.string().required('Por favor, informe um cnab!'),
        titular: Yup.string().required('Por favor, informe um titular!'),
        bank_limit: Yup.string().required('Por favor, informe um límite!'),
        kind_account: Yup.string().required(
          'Por favor, informe tipo da conta!',
        ),
      });

      if (!data.bank_id) {
        setErrorBank(true);
      }

      await schemaCreateAccount.validate(data, { abortEarly: false });
      await api.post('/business-banks-accounts', {
        ...data,
        bank_limit: currencyToFloat(data.bank_limit),
      });
      navigate.push('/business-bank-accounts/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** select bank */
  const selectBank = (value: BankModel) => {
    setBank(value);
    setSelectBankAside(false);
  };

  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/bancos')
      .then(({ data }) => {
        setBanks(data);
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1145) {
      setWith('100vw');
    }
    window.addEventListener('resize', (e: Event) => {
      const target = e.target as Window;
      if (target.innerWidth < 1145) {
        setWith('100vw');
      } else {
        setWith('70vw');
      }
    });
  });

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/business-bank-accounts/list')}
      />
      {loadingStatus && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="md:w-8 sm:w-full"
        />
      )}
      <div className="card md:w-8 sm:w-full">
        <Toast ref={toast} />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col flex justify-content-between align-items-center ">
              <label htmlFor="basic">Banco</label>
              <Button
                type="button"
                icon="pi pi-search"
                label={
                  selectedBank.id ? selectedBank?.nome : 'Selecionar Banco'
                }
                aria-haspopup
                style={{
                  background: errorBank ? '#ffcdd2' : '',
                  color: errorBank ? '#c63737' : '',
                }}
                onClick={() => setSelectBankAside(true)}
                aria-controls="overlay_panel"
                className="select-product-button w-7"
              />
            </div>
          </div>
          <InputText
            type="hidden"
            name="bank_id"
            value={selectedBank?.id || ''}
          />
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Tipo de Conta</label>
              <InputDropDown
                options={typeAccount}
                value={typeAccountSelected}
                onChange={e => setSelectTypeAccount(e.value)}
                name="kind_account"
                placeholder="Selecionar"
                disabled={!selectedBank.id}
                style={{
                  background: !selectedBank.id ? 'var(--surface-200)' : '',
                }}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Agência</label>
              <InputText
                name="bank_agence"
                placeholder="000000"
                keyfilter="num"
                value={agence}
                disabled={!selectedBank.id}
                className={`${!selectedBank.id ? 'surface-200' : ''}`}
                onChange={e => setAgence(e.currentTarget.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Conta</label>
              <InputText
                name="bank_account"
                placeholder="000000"
                value={account}
                onChange={e => setAccount(e.currentTarget.value)}
                disabled={!selectedBank.id}
                className={`${!selectedBank.id ? 'surface-200' : ''}`}
              />
            </div>
          </div>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <label htmlFor="basic">Titular</label>
              <InputText
                name="titular"
                placeholder="Joe Doe"
                disabled={!selectedBank.id}
                className={`${!selectedBank.id ? 'surface-200' : ''}`}
              />
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="basic">CNAB</label>
              <InputText
                name="cnab"
                keyfilter="int"
                placeholder="00000"
                disabled={!selectedBank.id}
                className={`${!selectedBank.id ? 'surface-200' : ''}`}
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="basic">Límite</label>
              <InputNumber
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                placeholder="0,00"
                name="bank_limit"
                disabled={!selectedBank.id}
                className={`${!selectedBank.id ? 'surface-200' : ''}`}
              />
            </div>
          </div>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Cancelar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={loadingStatus}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Confirmar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>
      <SideBar
        visible={selectBankAside}
        position="right"
        style={{ width }}
        onHide={() => setSelectBankAside(false)}
      >
        <DataTable
          value={banks}
          header={header}
          globalFilter={globalFilter}
          selectionMode="single"
          selection={selectedBank}
          onSelectionChange={e => selectBank(e.value)}
          dataKey="id"
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
        >
          <Column field="id" header="#" body={codeBodyTemplate}></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="codigo" header="Código"></Column>
        </DataTable>
      </SideBar>
    </>
  );
};

export default BankAccountCreate;
