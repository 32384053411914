import { IConOrder } from '../dtos/IConOrderDTO';

const hoje = new Date();

export const emptyConOrder: IConOrder = {
  id: '',
  route_id: '',
  routeOfOrder: {
    id: '',
    sequence: 0,
  },
  sequence: 0,
  customer_id: '',
  customer: {
    id: '',
    codigo: 0,
    customer: '',
    name: '',
    cpf_cnpj: '',
    block: '',
    image_url: '',
    status: '',
    last_transaction: hoje,
    type: '',
  },
  commissioned_id: null,
  date_digitation: hoje,
  date_sell: hoje,
  nat_ope_id: '',
  order_type: '',
  invoiced: false,
  nfe_emited: false,
  discount: 0,
  additional: 0,
  fees: 0,
  qnt_products: 0,
  qnt_itens: 0,
  total_comission: 0,
  order_value: 0,
  order_total: 0,
  expire_day: hoje,
  checked: false,
  obs: '',
  items: [],
  payments: [],
  user_id: '',
  status: '',
};
