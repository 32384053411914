import { createContext, ReactNode, useState } from 'react';
import {
  emptyParametersConsigned,
  IParametersConsigned,
} from '../pages/parameters/dtos/IParametersConsigned';
import api from '../services/api';

interface IContextData {
  paramsConsigned: IParametersConsigned | undefined;
  setParamsConsigned: (paramsConsigned: IParametersConsigned) => void;
  clearParamsConsigned: () => void;
  fetchParamsConsigned: () => void;
}

export const ParamsConsignedContext = createContext<IContextData | undefined>(
  undefined,
);

export function ParamsConsignedProvider({ children }: { children: ReactNode }) {
  const [paramsConsigned, setParams] = useState<
    IParametersConsigned | undefined
  >(undefined);

  const setParamsConsigned = (params: IParametersConsigned) => {
    setParams({ ...params });
  };

  const clearParamsConsigned = () => {
    setParamsConsigned(emptyParametersConsigned);
  };

  const fetchParamsConsigned = () => {
    api.get('/params/consigned/default').then(({ data }) => {
      if (data) {
        setParamsConsigned({ ...data.defaultConsignedParams });
      }
    });
  };

  return (
    <ParamsConsignedContext.Provider
      value={{
        paramsConsigned,
        setParamsConsigned,
        clearParamsConsigned,
        fetchParamsConsigned,
      }}
    >
      {children}
    </ParamsConsignedContext.Provider>
  );
}
