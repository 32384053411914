import { v4 } from 'uuid';

export interface INfceResume {
  id: string;
  order_id: string;
  order: number;
  customer_id: string;
  customer_name: string;
  ts_id: string;
  id_integracao: string;
  serie: string;
  numero: number;
  emissao: Date;
  valor: number;
  url_xml: string;
  url_pdf: string;
  status: string;
  created_at: Date;
}

export const emptyNfceResume: INfceResume = {
  id: v4(),
  order_id: '',
  order: 0,
  customer_id: '',
  customer_name: '',
  ts_id: '',
  id_integracao: '',
  serie: '',
  numero: 0,
  emissao: new Date(),
  valor: 0,
  url_xml: '',
  url_pdf: '',
  status: '',
  created_at: new Date(),
};
