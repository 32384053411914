import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

/** menu options */

const emptyCfop = {
  aplicacao: '',
  codigo: '',
  created_at: '',
  descricao: '',
  id: '',
  status: '',
  updated_at: '',
};

interface Cfop {
  aplicacao: string;
  codigo: string;
  created_at: string;
  descricao: string;
  id: string;
  status: string;
  updated_at: string;
}

const CestList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');

  /** cfop */
  const [cfop, setCfop] = useState<Cfop>({} as Cfop);

  /** delete cfop state */
  const [dialogDeleteCfopsDialog, setDialogDeleteCfop] = useState(false);

  /** cfops */
  const [cfops, setCfops] = useState<Array<Cfop>>([]);

  /* navigate */
  const navigate = useHistory();

  /** ref */
  const toast = useRef<any>(null);

  /** loading */
  const [loading, setLoading] = useState(true);

  /** dialog delete cfop template */

  const hideDeleteCfopDialog = () => {
    setDialogDeleteCfop(false);
  };

  const deleteCfopRequest = async () => {
    try {
      await api.delete(`/cfops/${cfop.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteCfop = () => {
    const _cfops = cfops.filter(val => val.id !== cfop.id);
    setCfops(_cfops);
    setDialogDeleteCfop(false);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteCfopRequest();
    setCfop(emptyCfop);
  };

  const deleteCfopDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteCfopDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCfop}
      />
    </React.Fragment>
  );

  /** table */

  const codeBodyTemplate = (rowData: Cfop, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const statusBodyTemplate = (rowData: Cfop) => {
    return (
      <div className="flex">
        <Checkbox checked={rowData.status === 'A'}></Checkbox>
      </div>
    );
  };

  const actionBodyTemplate = (rowData: Cfop) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/cest/edit/${rowData.id}`)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">CEST&lsquo;s</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.target?.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchCest = async () => {
    try {
      const { data } = await api.get('/cest');
      setCfops(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchCest();
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/cest/create')}
      />
      <div className="card">
        <DataTable
          value={cfops}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={8}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} cest"
          globalFilter={globalFilter}
          emptyMessage="Não existe CEST's."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="code" header="N°" body={codeBodyTemplate}></Column>
          <Column field="codigo" header="Código"></Column>
          <Column field="descricao" header="Descrição"></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            header="Ações"
            body={actionBodyTemplate}
            headerStyle={{ width: '5%', minWidth: '10rem' }}
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteCfopsDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteCfopDialogFooter}
        onHide={hideDeleteCfopDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {cfop && (
            <span>
              Tem certeza de que deseja excluir&nbsp;
              <b>cfop</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CestList;
