import React, { useRef } from 'react';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';

const NcmCreate = (): React.ReactElement => {
  const navigate = useHistory();

  /** useRef */
  const toast = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);

  /** show toast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleNcm = async (data: { codigo: string; descricao: string }) => {
    try {
      const schemaNcm = Yup.object({
        codigo: Yup.string().required('Por favor, informe o código!'),
        descricao: Yup.string().required('Por favor, informe a descrição!'),
      });

      await schemaNcm.validate(data, { abortEarly: false });

      await api.post(`/ncm`, data);
      navigate.push('/ncm/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/ncm/list')}
      />
      <div className="card md:w-6 sm:w-full">
        <Toast ref={toast} />

        <Form ref={formRef} onSubmit={handleNcm}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Código</label>
              <InputText name="codigo" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">Descrição</label>
              <InputTextArea name="descricao" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
                onClick={() => navigate.push('/ncm/list')}
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default NcmCreate;
