import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../../components/DataTable';
import { Dialog } from '../../../../components/Modal';

/** services */
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import api from '../../../../services/api';

/** menu options */

type Group = {
  description: string;
  group_father: string;
  group_id: string;
  id: string;
  status: string;
  title: string;
};

const LeadsList = (): React.ReactElement => {
  /** state */
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const [dialogDeleteGroup, setDialogDeleteGroup] = useState(false);

  /** state dialog */
  const [consultant, setConsultant] = useState<any>();

  /** consultant */
  const [consultants, setConsustants] = useState<Array<any>>([]);

  /** loading */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);

  /* refs */
  const toast = useRef<any>(null);

  /* navigate */
  const navigate = useHistory();

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {attr?.rowIndex + 1}
      </>
    );
  };

  const columnCreateAt = (rowData: any) => {
    return <span>{format(new Date(rowData.created_at), 'dd/MM/yyyy')}</span>;
  };

  const statusBodyTemplate = (rowData: Group) => {
    return rowData.status === 'A' ? (
      <i className="fa-solid fa-check"></i>
    ) : (
      <i className="fa-solid fa-xmark"></i>
    );
  };

  const renderColumnLuckNumber = (rowData: any) => {
    return rowData.luck_number !== '' ? (
      <span>{rowData.luck_number}</span>
    ) : (
      <i className="pi pi-thumbs-down"></i>
    );
  };

  const confirmDeleteConsultantt = (group: any) => {
    setConsultant(group);
    setDialogDeleteGroup(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-end">
        {rowData.luck_number !== '' && (
          <Button
            icon="fa-solid fa-dice-five"
            className="p-button-raised mr-4"
            disabled
            onClick={() => null}
          />
        )}

        {rowData.luck_number === '' && (
          <Button
            icon="fa-solid fa-dice-five"
            className="p-button-raised mr-4"
            onClick={() => generateLuckNumber(rowData)}
            tooltip="A criação de um número de sorteio é única e não pode ser alterada!"
          />
        )}

        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/mkt/lead/editar/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger mr-4"
          onClick={() => confirmDeleteConsultantt(rowData)}
        />
      </div>
    );
  };

  const apoliceColumn = (rowData: any) => {
    return rowData.apolice_url === null ? (
      <i className="fa-solid fa-xmark"></i>
    ) : (
      <i
        className="fa-solid fa-download cursor-pointer"
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.apolice_url}`,
          );
        }}
      ></i>
    );
  };

  /** delete dialog  */

  const hideDeleteGroupDialog = () => {
    setDialogDeleteGroup(false);
  };

  const deleteConsultantApi = async () => {
    try {
      setLoadingStatus(true);

      await api.delete(`/mkt/lead/${consultant.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  const generateLuckNumber = async (rowData: any) => {
    try {
      setLoadingStatus(true);
      const res = await api.post(`/mkt/lead/luck-number/${rowData.id}`);

      if (res.status === 200) {
        toast.current?.show({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Número da sorte gerado com sucesso!',
          life: 3000,
        });
      }

      await searchLeads();
    } catch (e: any) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Falha',
        detail: e.response.data.error,
        life: 3000,
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  const deleteGroup = () => {
    const _consultants = consultants.filter(val => val.id !== consultant.id);
    setConsustants(_consultants);
    hideDeleteGroupDialog();
    setConsultant(undefined);
    deleteConsultantApi();
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Lead Deletado',
      life: 3000,
    });
  };

  const deleteConsultantDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteGroupDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteGroup}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lead&lsquo;s</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchLeads = async () => {
    try {
      setLoadingStatus(true);

      const { data } = await api.get('/mkt/lead?page=1&perPage=1000&status=A');

      setConsustants(data.listAllLeads);
    } catch (error: any) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Alerta',
        detail: 'Falha ao listar os leads. Tente novamente!',
        life: 3000,
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    setLoadingStatus(true);
    searchLeads();
    setLoadingStatus(false);
  }, []);

  return (
    <>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mb-3"
        onClick={() => navigate.push('/mkt/lead/novo')}
      />
      <Button
        label="Consultores"
        icon="pi pi-list"
        className="mb-3 ml-3"
        onClick={() => navigate.push('/mkt/consultor/listar')}
      />
      <div className="card">
        <Toast ref={toast} />
        <DataTable
          value={consultants}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} of {totalRecords} leads"
          globalFilter={globalFilter}
          emptyMessage="Não existe leads."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column field="code" header="N°" body={codeBodyTemplate}></Column>
          <Column field="nome" header="Nome"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="whatsapp" header="Whatsapp"></Column>
          <Column
            field="whatsapp"
            header="Criado Em"
            body={columnCreateAt}
          ></Column>
          <Column header="Arq." body={apoliceColumn}></Column>
          <Column
            field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate}
          ></Column>
          <Column
            header="N. Sorte"
            field="luck_number"
            body={renderColumnLuckNumber}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteGroup}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteConsultantDialogFooter}
        onHide={hideDeleteGroupDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {consultant && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{consultant?.nome}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default LeadsList;
