import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from '../../../../components/Divider';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import { IProductRow } from '../../order/dtos/IConOrderDTO';
import { IConDevolution } from '../dtos/IConDevolutionDTO';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  devolutionDTO: Partial<IConDevolution>;
  isCreate: boolean;
  routeCustomer: string;
}

const SidebarDevolutionCheckout = ({
  isOpen,
  onRequestClose,
  devolutionDTO,
  routeCustomer,
  isCreate,
}: IProps): React.ReactElement => {
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const router = useHistory();

  const [load, setLoad] = useState<boolean>(false);

  const [isOrder, setIsOrder] = useState('PRO');

  const [obs, setObs] = useState<string | undefined>('');

  const handleSubmit = async (d: any) => {
    if (isCreate === true) {
      setLoad(true);
      await api
        .post('/consigned/devolution/create', {
          data: {
            devolution: {
              route_id: devolutionDTO.route_id,
              customer_id: devolutionDTO.customer_id,
              table_price_id: devolutionDTO.table_price_id,
              nat_ope_id: devolutionDTO.nat_ope_id,
              type_order: isOrder,
              qnt_products: devolutionDTO.qnt_products,
              qnt_items: devolutionDTO.qnt_items,
              total: devolutionDTO.total,
              obs: obs,
            },
            items: devolutionDTO.items?.map((i: Partial<IProductRow>) => {
              return {
                id: i.id,
                pos: i.pos,
                table_price_id: i.table_price_id,
                product_id: i.product_id,
                qnt: i.qnt,
                vlr_unit: i.vlr_unit,
                add: i.add,
                desc: i.desc,
                vlr_liq: i.vlr_liq,
                total: i.total,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Devolução criada com sucesso!');
            router.push(`/consigned/route/open/${devolutionDTO.route_id}`);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        })
        .finally(() => setLoad(false));
    } else {
      setLoad(true);
      await api
        .put('/consigned/devolution/update', {
          data: {
            devolution: {
              id: devolutionDTO.id,
              route_id: devolutionDTO.route_id,
              customer_id: devolutionDTO.customer_id,
              table_price_id: devolutionDTO.table_price_id,
              nat_ope_id: devolutionDTO.nat_ope_id,
              type_order: isOrder,
              qnt_products: devolutionDTO.qnt_products,
              qnt_items: devolutionDTO.qnt_items,
              total: devolutionDTO.total,
              obs: obs,
            },
            items: devolutionDTO.items?.map((i: Partial<IProductRow>) => {
              return {
                id: i.id,
                pos: i.pos,
                table_price_id: i.table_price_id,
                product_id: i.product_id,
                qnt: i.qnt,
                vlr_unit: i.vlr_unit,
                add: i.add,
                desc: i.desc,
                vlr_liq: i.vlr_liq,
                total: i.total,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Devolução editada com sucesso!');
            router.push(`/consigned/route/open/${devolutionDTO.route_id}`);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        })
        .finally(() => setLoad(false));
    }
  };

  const clearForm = () => {
    setIsOrder('PRO');
    setObs('');
  };

  useEffect(() => {
    setObs(devolutionDTO.obs);
  }, [isOpen]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        style={{ width: '40vw' }}
      >
        <h3>Checkout</h3>
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <Divider />

          <div className="field col-4 pb-0 mb-0"></div>
          <div className="field col-4 pb-0 mb-0">
            <div className="flex justify-content-between">
              <span className="">Proposta</span>
              <InputSwitch
                name="order_type"
                checked={isOrder}
                falseValue="PRO"
                trueValue="PED"
                onChange={e => setIsOrder(e.value.toString())}
              />
              <span className="">Pedido</span>
            </div>
          </div>
          <div className="field col-4 pb-0 mb-0"></div>

          <Divider />

          <div className="field col-12">
            <label htmlFor="obs">Observações/Anotações.:</label>
            <InputTextArea
              name="obs"
              value={obs}
              onChange={e => setObs(e.currentTarget.value)}
              placeholder="Ex: O cliente já fez 2 trocas..."
              rows={5}
              cols={30}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="fa-solid fa-xmark"
              className="p-button-danger"
              onClick={() => clearForm()}
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="fa-solid fa-hand-holding-dollar"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </Form>
      </SideBar>
    </>
  );
};

export default SidebarDevolutionCheckout;
