import React from 'react';

const renderColumnPositiveNegative = (num: number) => {
  return num < 0 ? (
    <span className="text-blue-500">{num}</span>
  ) : (
    <span className="text-pink-500">{num}</span>
  );
};

export default renderColumnPositiveNegative;
