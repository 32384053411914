import { v4 } from 'uuid';
import { IProductPriceSearch } from '../../product/dtos/IProductDTO';

const id = v4();

export const emptyProductPriceSearch: IProductPriceSearch = {
  id: id,
  value: id,
  b_id: '',
  codigo: 0,
  sku: '',
  ean: '',
  title: '',
  label: '',
  unity: '',
  block: false,
  stock_location: '',
  stock: 0,
  cost_billing: 0,
  status: '',
  decimalHouses: [
    { product_id: id, price_in: 2, price_out: 2, qnt_in: 2, qnt_out: 2 },
  ],
  prices: [],
};
