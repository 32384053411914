import { diffDays } from '@fullcalendar/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addMonths, isDate } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDate from '../../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import RenderColumnLock from '../../../../components/DataTableColumns/RenderColumnLock';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnShortName from '../../../../components/DataTableColumns/RenderColumnShortName';
import renderColumnStatus from '../../../../components/DataTableColumns/RenderColumnStatus';
import { Divider } from '../../../../components/Divider';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../../components/Inputs/InputCalendar';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import formatCurrency from '../../../../utils/FormatCurrency';
import messageRequestError from '../../../../utils/messageRequestError';
import openReports from '../../../../utils/openReports';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import ConsignedTopMenu from '../../top-menu';
import { IRoute, ISummary } from '../dtos/IRouteDTO';
import {
  IParametersConsigned,
  emptyParametersConsigned,
} from '../../../parameters/dtos/IParametersConsigned';
import { useParamsConsigned } from '../../../../hooks/useParamsConsigned';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const ConsignedRouteOpen: React.FC = () => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const router = useHistory();
  const params: { id: string } = useParams();
  const reactRouter = useHistory();
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [route, setRoute] = useState<IRoute | null>(null);
  const [block, setBlock] = useState<boolean>(false);
  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<IOptionsDTO>({
    value: '',
    label: '',
  });

  const [dateOpen, setDateOpen] = useState<Date | Date[] | undefined>(hoje);
  const [datePrevision, setDatePrevision] = useState<Date | Date[] | undefined>(
    addMonths(hoje, 1),
  );
  const [dateClose, setDateClose] = useState<Date | Date[] | undefined>(hoje);

  const [sequence, setSequence] = useState<number | undefined>(undefined);

  const [commission, setCommission] = useState<number>(0);
  const [gift, setGift] = useState<number>(0);

  const [obs, setObs] = useState<string>('');

  const [summary, setSummary] = useState<ISummary | undefined>(undefined);

  const { paramsConsigned, fetchParamsConsigned } = useParamsConsigned();

  const calculateSummary = async () => {
    if (validate(params.id)) {
      let orderSummary: any;

      if (paramsConsigned && paramsConsigned.stock_control === true) {
        orderSummary = route?.orders.reduce(
          (a, i) => {
            if (i.status === 'A' && i.customer_id !== customer.value) {
              a.qntSellProducts += Number(i.qnt_products);
              a.qntSellItems += Number(i.qnt_itens);
              a.totalAdd += Number(i.additional);
              a.totalDesc += Number(i.discount);
              a.totalOrders += Number(i.order_value);
              a.total += Number(i.order_total);

              if (i.checked === true) {
                a.qntChecks += 1;
              }

              if (i.nfe_emited === true) {
                a.nfeOrder += 1;
              }
            }
            return a;
          },
          {
            qntSellProducts: 0,
            qntSellItems: 0,
            qntChecks: 0,
            nfeOrder: 0,
            totalAdd: 0,
            totalDesc: 0,
            totalOrders: 0,
            total: 0,
          },
        );
      } else {
        orderSummary = route?.orders.reduce(
          (a, i) => {
            if (i.status === 'A') {
              a.qntSellProducts += Number(i.qnt_products);
              a.qntSellItems += Number(i.qnt_itens);
              a.totalAdd += Number(i.additional);
              a.totalDesc += Number(i.discount);
              a.totalOrders += Number(i.order_value);
              a.total += Number(i.order_total);

              if (i.checked === true) {
                a.qntChecks += 1;
              }

              if (i.nfe_emited === true) {
                a.nfeOrder += 1;
              }
            }
            return a;
          },
          {
            qntSellProducts: 0,
            qntSellItems: 0,
            qntChecks: 0,
            nfeOrder: 0,
            totalAdd: 0,
            totalDesc: 0,
            totalOrders: 0,
            total: 0,
          },
        );
      }

      const devolutionSummary = route?.devolutions.reduce(
        (a, i) => {
          if (i.status === 'A') {
            a.qntDevProdutcs += Number(i.qnt_products);
            a.qntDevItems += Number(i.qnt_items);
            if (i.checked === true) {
              a.qntChecks += 1;
            }

            if (i.nfe_emited === true) {
              a.nfeDevolution += 1;
            }

            a.totalDevolutions += Number(i.total);
            a.total += Number(i.total);
          }
          return a;
        },
        {
          qntDevProdutcs: 0,
          qntDevItems: 0,
          qntChecks: 0,
          nfeDevolution: 0,
          totalAdd: 0,
          totalDesc: 0,
          totalDevolutions: 0,
          total: 0,
        },
      );

      const calcSell =
        Number(orderSummary?.total || 0) -
        Number(devolutionSummary?.total || 0);
      const totalCommission = (calcSell * commission) / 100 || 0;
      const liquid = calcSell - totalCommission - gift;

      let margin: number = 0;
      if (devolutionSummary && orderSummary) {
        margin =
          ((Number(devolutionSummary.total) + totalCommission + gift) * 100) /
          Number(orderSummary.total);
      }

      setSummary({
        nfeDevolution: devolutionSummary?.nfeDevolution || 0,
        nfeOrder: orderSummary?.nfeOrder || 0,
        qntChecks:
          Number(orderSummary?.qntChecks || 0) +
          Number(devolutionSummary?.qntChecks || 0),
        qntSellItems: orderSummary?.qntSellItems || 0,
        qntSellProducts: orderSummary?.qntSellProducts || 0,
        qntDevItems: devolutionSummary?.qntDevItems || 0,
        qntDevProducts: devolutionSummary?.qntDevProdutcs || 0,
        totalOrders: orderSummary?.total || 0,
        totalDevolutions: devolutionSummary?.total || 0,
        totalAdd: orderSummary?.totalAdd || 0,
        totalDesc: orderSummary?.totalDesc || 0,
        aliQCommission: commission,
        totalComission: totalCommission,
        totalGifts: gift,
        sell: calcSell,
        margin: 100 - margin,
        total: liquid,
      });
    }
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const headerOrder = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <Button
        icon="fa-solid fa-plus"
        className="p-button-success p-button-outlined p-button-rounded"
        type="button"
        onClick={() => {
          reactRouter.push(`/consigned/order/create/${route?.id}`);
        }}
        disabled={route?.block}
      />
      <span className="text-xl text-900 font-bold">
        Pedidos:{' '}
        {new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(summary?.totalOrders || 0)}
      </span>
    </div>
  );

  const headerDevolution = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <Button
        icon="fa-solid fa-plus"
        className="p-button-success p-button-outlined p-button-rounded"
        type="button"
        onClick={() => {
          reactRouter.push(`/consigned/devolution/create/${route?.id}`);
        }}
        disabled={route?.block}
      />
      <span className="text-xl text-900 font-bold">
        Devoluções:{' '}
        {new Intl.NumberFormat('BRL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(summary?.totalDevolutions || 0)}
      </span>
    </div>
  );

  const loadRoute = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/consigned/route/full/${id}`)
      .then(({ data }) => {
        if (data) {
          setRoute(data);

          setCustomer({
            value: data.customer_id,
            label: data.customer.name,
          });

          setDateOpen(new Date(data.date_open));

          setDatePrevision(new Date(data.date_prevision));

          setDateClose(new Date(data.date_close));

          setSequence(data.sequence);
          setCommission(Number(data.aliq_commission));
          setGift(Number(data.total_gift));
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  // prettier-ignore
  const handleSubmit = async (d: any) => {

    if (!customer.value || customer.value === '') {
      toast('warn', 'Alerta', 'Informe o cliente!')
    } else {

      if (validate(params.id)) {
        // edita registro
        if (route?.block) {
          toast(
            'error',
            'Erro',
            'Não é mais possível editar uma rota que já foi finalizada!',
          );
        }
        setIsLoad(true);
        await api
          .put('/consigned/route/update', {
            data: {
              id: params.id,
              customer_id: customer.value,
              block: block,
              date_open: dateOpen,
              date_prevision: datePrevision,
              date_close: dateClose,
              total_order: summary?.totalOrders || 0,
              total_devolution: summary?.totalDevolutions || 0,
              total_gift: summary?.totalGifts || 0,
              aliq_commission: commission,
              total_commission: summary?.totalComission || 0,
              score: summary?.margin || 0,
              total: summary?.total || 0,
              obs: obs,
            },
          })
          .then(({ data }) => {
            if (data) {
              toast('success', 'Editado', 'Rota/Consignado editado com sucesso!');
              router.push('/consigned/route/list');
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setIsLoad(false));
      } else {
        // salva novo registro
        setIsLoad(true);
        await api
          .post('/consigned/route/open', {
            data: {
              customer_id: customer.value,
              date_open: dateOpen,
              date_prevision: datePrevision,
              date_close: undefined,
              total_order: summary?.totalOrders || 0,
              total_devolution: summary?.totalDevolutions || 0,
              total_gift: summary?.totalGifts || 0,
              aliq_commission: commission,
              total_commission: summary?.totalComission || 0,
              score: summary?.margin || 0,
              total: summary?.total || 0,
              obs: obs,
            },
          })
          .then(({ data }) => {
            if (data) {
              toast('success', 'Salvo', 'Rota/Consignado criado com sucesso!');
              router.push('/consigned/route/list');
            }
          })
          .catch((err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
          })
          .finally(() => setIsLoad(false));
      }
    }
  };

  const printOrder = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/consigned/order/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-pedido',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const printDevolution = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/consigned/devolution/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'consignado-devolucao',
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const renderOrderColumnView = (id: string) => {
    return (
      <>
        <i
          className="fa-solid fa-eye text-grey-500"
          onClick={async () => {
            await printOrder(id);
          }}
        ></i>
      </>
    );
  };

  const renderDevolutionColumnView = (id: string) => {
    return (
      <>
        <i
          className="fa-solid fa-eye text-grey-500"
          onClick={async () => {
            await printDevolution(id);
          }}
        ></i>
      </>
    );
  };

  useEffect(() => {
    if (validate(params.id)) {
      loadRoute(params.id);
    }
    if (!paramsConsigned) fetchParamsConsigned();
  }, []);

  useEffect(() => {
    calculateSummary();
  }, [
    route?.orders,
    route?.devolutions,
    commission,
    gift,
    paramsConsigned ? paramsConsigned.stock_control : false,
    customer.value,
  ]);

  return (
    <>
      <ConsignedTopMenu />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="card"
        placeholder={''}
        onPointerLeaveCapture={null}
        onPointerEnterCapture={null}
      >
        <div className="p-fluid grid formgroup">
          <div className="field col-5 mb-1 pb-1">
            <label htmlFor="dropdown">
              Cliente
              <i
                className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                onClick={() => null}
              ></i>
            </label>
            <InputAutoComplete
              name="customer_id"
              suggestions={customers}
              value={customer}
              completeMethod={e => searchCustomer(e.query)}
              field="label"
              onChange={e => setCustomer(e.value)}
              itemTemplate={itemTemplate}
              placeholder="Buscar cliente..."
              disabled={route?.block}
            />
          </div>

          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="date_open">Abertura</label>
            <Calendar
              name="date_open"
              value={dateOpen}
              onChange={e => setDateOpen(e.value)}
              dateFormat="d/m/y"
              placeholder="00/00/00"
              showIcon
              disabled={route?.block}
            />
          </div>

          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="date_prevision">Previsão</label>
            <Calendar
              name="date_prevision"
              value={datePrevision}
              onChange={e => setDatePrevision(e.value)}
              dateFormat="d/m/y"
              placeholder="00/00/00"
              showIcon
              disabled={route?.block}
            />
          </div>

          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="date_close">Fechamento</label>
            <Calendar
              name="date_close"
              value={dateClose}
              onChange={e => setDateClose(e.value)}
              placeholder="00/00/00"
              dateFormat="d/m/y"
              readOnlyInput
              disabled
              showIcon
              className="surface-200"
            />
          </div>

          <div className="field col-1 mb-0 pb-0">
            <label htmlFor="sequence">Rota</label>
            <InputNumber
              name="sequence"
              value={sequence}
              placeholder="000"
              readOnly
              disabled
              className="surface-200"
            />
          </div>

          <DataTable
            header={headerOrder}
            value={route?.orders.sort((a, b) => a.sequence - b.sequence)}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Pedidos não encontrados..."
            selectionMode="single"
            loading={isLoad}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} pedidos."
            className="field col-6"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="N." field="sequence"></Column>
            <Column
              header="Digitação"
              body={r => renderColumnDate(r.date_digitation)}
            ></Column>
            <Column
              header="Cliente"
              body={r => renderColumnShortName(r.customer.name)}
            ></Column>
            <Column
              header="Total"
              body={r => renderColumnDecimal(r.order_total)}
            ></Column>
            <Column
              header="Check"
              body={r => RenderColumnLock(r.checked)}
              align={'center'}
            ></Column>
            <Column
              header="Ver"
              body={r => renderOrderColumnView(r.id)}
              align={'center'}
            ></Column>
            <Column
              header="Status"
              body={r => renderColumnStatus(r)}
              align={'center'}
            ></Column>
          </DataTable>

          <DataTable
            header={headerDevolution}
            value={route?.devolutions.sort((a, b) => a.sequence - b.sequence)}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Devoluções não encontradas..."
            selectionMode="single"
            loading={isLoad}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} devoluções."
            className="field col-6"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="N." field="sequence"></Column>
            <Column
              header="Digitação"
              body={r => renderColumnDate(r.date_digitation)}
            ></Column>
            <Column
              header="Cliente"
              body={r => renderColumnShortName(r.customer.name)}
            ></Column>
            <Column
              header="Total"
              body={r => renderColumnDecimal(r.total)}
            ></Column>
            <Column
              header="Check"
              body={r => RenderColumnLock(r.checked)}
              align={'center'}
            ></Column>
            <Column
              header="Ver"
              body={r => renderDevolutionColumnView(r.id)}
              align={'center'}
            ></Column>
            <Column
              header="Status"
              body={r => renderColumnStatus(r)}
              align={'center'}
            ></Column>
          </DataTable>

          <div className="col-2">
            <label htmlFor="commission">Comissão (%)</label>
            <InputNumber
              name="commission"
              value={commission}
              onChange={e => {
                setCommission(Number(e.value));
              }}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00 %"
              onFocus={e => e.target.select()}
              disabled={
                route?.orders.length === 0 || route?.block ? true : false
              }
              className={
                route?.orders.length === 0 || route?.block ? `surface-200` : ``
              }
              readOnly={route?.block}
            />
          </div>

          <div className="col-2">
            <label htmlFor="gifts">Brindes</label>
            <InputNumber
              name="gifts"
              value={gift}
              onChange={e => {
                setGift(Number(e.value));
              }}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00 %"
              onFocus={e => e.target.select()}
              disabled={route?.orders.length === 0 ? true : false}
              className={
                route?.orders.length === 0 || route?.block ? `surface-200` : ``
              }
              readOnly={route?.block}
            />
          </div>

          <div className="field col-2 flex align-items-end mb-0">
            <InputSwitch
              name="checked"
              className="mr-3"
              checked={block}
              onChange={e => setBlock(e.value as boolean)}
              disabled={route?.block}
            />
            <label htmlFor="dropdown" className="m-0">
              Encerrado
            </label>
          </div>

          <Divider />

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">RESUMOS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Produtos vendidos.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntSellProducts || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Itens vendidos.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntSellItems || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Produtos devolvidos.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntDevProducts || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Itens devolvidos.:</div>
              <div className="flex">
                {formatCurrency(summary?.qntDevItems || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Ped/Dev. conferidos.:</div>
              <div className="flex">{`${summary?.qntChecks || 0}#${
                Number(route?.orders.length || 0) +
                Number(route?.devolutions.length || 0)
              }`}</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt nfe remessa.:</div>
              <div className="flex">
                {formatCurrency(summary?.nfeOrder || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Qnt nfe devolução.:</div>
              <div className="flex">
                {formatCurrency(summary?.nfeDevolution || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Dias em aberto:</div>
              <div className="flex">
                {route && route.date_open && isDate(new Date(route?.date_open))
                  ? Number(
                      diffDays(new Date(route?.date_open), hoje).toFixed(2),
                    ) < 1
                    ? '[hoje]'
                    : Number(
                        diffDays(new Date(route?.date_open), hoje),
                      ).toFixed(2)
                  : '[fechado]'}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Dias p/ fechamento.:</div>
              <div className="flex">
                {route &&
                route.date_prevision &&
                isDate(new Date(route.date_prevision))
                  ? Number(
                      diffDays(hoje, new Date(route.date_prevision)),
                    ).toFixed(0)
                  : '[sem previsão]'}
              </div>
            </div>
          </div>

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">ANOTAÇÕES</span>
              </div>
            </Divider>
            Obs.: {obs.length >= 64 ? obs.slice(0, 64) : obs}
          </div>

          <div className="field col-4 text-lg">
            <Divider align="left" type="dashed" className="mt-0">
              <div className="inline-flex align-items-center">
                <span className="p-tag">TOTAIS</span>
              </div>
            </Divider>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Total vendas:</div>
              <div className="flex">
                {formatCurrency(summary?.totalOrders || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Total devoluções:</div>
              <div className="flex">
                {formatCurrency(summary?.totalDevolutions || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Vendido:</div>
              <div className="flex">{formatCurrency(summary?.sell || 0)}</div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Brindes:</div>
              <div className="flex">
                {formatCurrency(summary?.totalGifts || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Comissão:</div>
              <div className="flex">
                {formatCurrency(summary?.totalComission || 0)}
              </div>
            </div>
            <div className="flex justify-content-between flex-wrap">
              <div className="flex">Margem:</div>
              <div className="flex">
                {formatCurrency(summary?.margin || 0)}%
              </div>
            </div>
            <div
              className="flex justify-content-between flex-wrap my-2 py-2 font-bold"
              style={{ borderTop: '0.2rem dashed var(--surface-500)' }}
            >
              <div className="flex">Total:</div>
              <div className="flex">{formatCurrency(summary?.total || 0)}</div>
            </div>
          </div>

          <Divider />

          <div className="field col-6 mb-0 pb-0">
            <Button
              label="Cancelar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
              disabled={route?.block}
            />
          </div>

          <div className="field col-6 mb-0 pb-0">
            <Button
              label="Finalizar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
              disabled={route?.block}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ConsignedRouteOpen;
