import React from 'react';
import {
  RouteProps,
  useLocation,
  withRouter,
  Redirect,
} from 'react-router-dom';

const AppBreadcrumb: React.FC = (props: any) => {
  const location = useLocation();
  const pathname = location.pathname;

  const splitPathname = pathname.split('/');

  splitPathname.pop();

  splitPathname.shift();

  splitPathname.join('/');

  const joinPathname = splitPathname.join('/');

  const regexString = `\\/${joinPathname}\\/:id`;

  const regex = new RegExp(regexString, 'g');

  let name = pathname.replace('/', '');
  if (props.routers) {
    const currentRouter = props.routers.find(
      (router: RouteProps) => router.path === pathname,
    );
    const routerDynamic = props.routers.find((router: any) =>
      regex.test(router.path),
    );

    if (!currentRouter?.meta && !routerDynamic?.meta) {
      return <Redirect to="/notfound" />;
    }

    name = currentRouter
      ? currentRouter['meta'].breadcrumb[0].label
      : routerDynamic['meta'].breadcrumb[0].label;
  }
  return <span>{name}</span>;
};

export default withRouter(AppBreadcrumb);
