import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

const BusinessHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  return (
    <>
      <Button
        label="Listar"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/business/list')}
      />

      <Button
        label="Clientes"
        icon="fa-solid fa-users"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/persons/list')}
      />

      <Button
        label="Produtos"
        icon="fa-solid fa-box"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/products/listage')}
      />

      <Button
        label="Configurações"
        icon="fa-solid fa-gears"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/params')}
      />
    </>
  );
};

export default BusinessHeaderButtons;
