import { useQuery } from 'react-query';
import { IUser } from '../pages/user/dtos/IUser';
import api from '../services/api';

export default function useLoadOsTechnicals() {
  const url = '/users/list';
  const { data, error, isLoading, isFetched } = useQuery<IUser[]>(
    ['users', url],
    () =>
      api
        .post(url, { pagination: { page: 1, perPage: 100, status: ['A'] } })
        .then(response => response.data),
    { staleTime: 60000, cacheTime: 60000 },
  );
  return { data, error, isLoading, isFetched };
}
