import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import formatCurrency from '../../../utils/FormatCurrency';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { IProductPriceSearch } from '../../product/dtos/IProductDTO';
import { IProductPrice, emptyProductPrice } from '../dtos/IProductPriceDTO';
import TopMenu from '../top-menu';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { SelectButton } from 'primereact/selectbutton';

const ProductPriceCreate: React.FC = () => {
  const productRef = useRef(null);
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const router = useHistory();
  const params: { id: string } = useParams();

  const [isLoad, setIsLoad] = useState(false);
  const [productFocusKeyword, setProductFocusKeyword] =
    useState<boolean>(false);
  const [productPrice, setProductPrice] =
    useState<IProductPrice>(emptyProductPrice);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const typeMarginOpts: IOptionsDTO[] = [
    { label: 'Custo', value: 'C' },
    { label: 'Venda', value: 'V' },
  ];

  const [typeMargin, setTypeMargin] = useState<string>(typeMarginOpts[0].value);

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const handleSubmit = async (d: any) => {
    try {
      formRef.current?.setErrors({});

      d.product_id = productPrice.product_id;
      d.table_price_id = productPrice.table_price_id;
      d.vlr_venda = productPrice.vlr_venda;
      d.qnt_venda_min = productPrice.qnt_venda_min;
      d.qnt_venda_max = productPrice.qnt_venda_max;

      const schema = Yup.object({
        product_id: Yup.string().uuid().required('Informe um produto!'),
        table_price_id: Yup.string()
          .uuid()
          .required('Informe uma tabela de preços!'),
        vlr_venda: Yup.number().required('Informe o valor de vendas!'),
        qnt_venda_min: Yup.number()
          .required('Informe a quantidade mínima de venda!')
          .default(1),
        qnt_venda_max: Yup.number()
          .required('Informe a quantidade máxima de venda!')
          .default(1000),
      });
      await schema.validate(d, { abortEarly: false });

      if (
        productPrice.product.cost_billing &&
        productPrice.product.cost_billing !== 0 &&
        productPrice.vlr_venda < productPrice.product.cost_billing
      )
        toast(
          'warn',
          'Alerta',
          'O valor de venda do produto está abaixo do valor de custo!',
        );

      await api
        .put(`/products-prices/v2/update`, {
          data: {
            id: productPrice.id,
            table_price_id: productPrice.table_price_id,
            product_id: productPrice.product_id,
            vlr_venda: productPrice.vlr_venda,
            vlr_venda_min_s_lib: productPrice.vlr_venda_min_s_lib,
            vlr_venda_min_c_lib: productPrice.vlr_venda_min_c_lib,
            qnt_venda_min: productPrice.qnt_venda_min,
            qnt_venda_max: productPrice.qnt_venda_max,
            comissao: productPrice.comissao,
            margin: productPrice.margin,
            lucre: productPrice.lucre,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Preço editado com sucesso!');
            setProductPrice(emptyProductPrice);
            router.push('/products-prices/list');
          }
        })
        .catch(err => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setIsLoad(false));
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ['Lucro', 'Custo', 'Comissão'],
      datasets: [
        {
          data: [
            productPrice.lucre > 0 ? productPrice.lucre : 0,
            productPrice.product.cost_billing,
            productPrice.comissao
              ? (Number(productPrice.comissao) / 100) *
                Number(productPrice.vlr_venda)
              : 0,
          ],
          backgroundColor: [
            documentStyle.getPropertyValue('--green-500'),
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--blue-500'),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--blue-400'),
          ],
        },
      ],
    };
    const options = {
      cutout: '60%',
    };

    setChartData(data);
    setChartOptions(options);
  }, [productPrice]);

  const loadProductPrice = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/products-prices/v2/${id}`)
      .then(({ data }) => {
        if (data) {
          setProductPrice({
            ...data,
            product: {
              ...data.product,
              cost_billing: Number(data.product.cost_billing),
            },
          });
        }
      })
      .catch(err => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  useEffect(() => {
    if (validate(params.id)) {
      loadProductPrice(params.id);
    }
  }, []);

  useEffect(() => {
    if (
      typeMargin === 'V' &&
      productPrice.margin + productPrice.comissao >= 100
    ) {
      setProductPrice({
        ...productPrice,
        margin: 0,
        comissao: 0,
        vlr_venda: productPrice.product.cost_billing,
      });
      toast(
        'warn',
        'Alerta',
        'O valor da margem mais a comissão não pode ser maior ou igual a 100%!',
      );
    } else {
      handleChangeMargin(Number(productPrice.margin));
      handleChangeCommission(Number(productPrice.comissao));
    }
  }, [typeMargin]);

  useEffect(() => {
    if (productPrice.comissao === 0) handleChangeMargin(productPrice.margin);
  }, [productPrice.comissao]);

  const handleChangeMargin = (margin: number) => {
    if (productPrice.product.cost_billing) {
      if (typeMargin === 'C') {
        const vlr_venda =
          productPrice.product.cost_billing +
          productPrice.product.cost_billing * (margin / 100);

        setProductPrice({
          ...productPrice,
          margin: margin,
          vlr_venda: vlr_venda,
          vlr_venda_min_c_lib: vlr_venda,
          vlr_venda_min_s_lib: vlr_venda,
          lucre:
            vlr_venda -
            productPrice.product.cost_billing -
            Number(vlr_venda * (productPrice.comissao / 100)),
        });
      } else {
        if (margin + productPrice.comissao >= 100) {
          setProductPrice({ ...productPrice, margin: 0 });
          toast(
            'warn',
            'Alerta',
            'O valor da margem mais a comissão não pode ser maior ou igual a 100%!',
          );
        } else {
          const vlr_venda =
            (100 * productPrice.product.cost_billing) /
            (100 - productPrice.comissao - margin);

          setProductPrice({
            ...productPrice,
            margin: margin,
            vlr_venda: vlr_venda,
            vlr_venda_min_c_lib: vlr_venda,
            vlr_venda_min_s_lib: vlr_venda,
            lucre:
              vlr_venda -
              productPrice.product.cost_billing -
              Number(vlr_venda * (productPrice.comissao / 100)),
          });
        }
      }
    }
  };

  const handleChangeCommission = (commission: number) => {
    if (commission >= 100) {
      setProductPrice({ ...productPrice, comissao: 0 });
      toast('error', 'Erro', 'A comissão não pode ser maior ou igual a 100%!');
    } else {
      if (productPrice.product.cost_billing) {
        if (typeMargin === 'C') {
          const addByMargin =
            productPrice.product.cost_billing * (productPrice.margin / 100);
          let vlr_venda = productPrice.product.cost_billing + addByMargin;
          const vlr_commission = (vlr_venda * commission) / (100 - commission);
          vlr_venda += vlr_commission;

          setProductPrice({
            ...productPrice,
            comissao: commission,
            vlr_venda: vlr_venda,
            vlr_venda_min_c_lib: vlr_venda,
            vlr_venda_min_s_lib: vlr_venda,
            lucre:
              vlr_venda -
              productPrice.product.cost_billing -
              Number(vlr_venda * (commission / 100)),
          });
        } else {
          if (productPrice.margin + commission >= 100) {
            setProductPrice({ ...productPrice, comissao: 0 });
            toast(
              'warn',
              'Alerta',
              'O valor da margem mais a comissão não pode ser maior ou igual a 100%!',
            );
          } else {
            const addByMargin =
              (productPrice.product.cost_billing * productPrice.margin) /
              (100 - productPrice.margin);
            let vlr_venda = productPrice.product.cost_billing + addByMargin;
            const vlr_commission =
              (vlr_venda * commission) / (100 - commission);
            vlr_venda += vlr_commission;

            setProductPrice({
              ...productPrice,
              comissao:
                commission + productPrice.margin >= 100 ? 0 : commission,
              vlr_venda: vlr_venda,
              vlr_venda_min_c_lib: vlr_venda,
              vlr_venda_min_s_lib: vlr_venda,
              lucre:
                vlr_venda -
                productPrice.product.cost_billing -
                Number(vlr_venda * (commission / 100)),
            });
          }
        }
      }
    }
  };

  const handleChangeVlrVenda = (vlr_venda: number) => {
    let margin = 0;

    if (productPrice.product.cost_billing) {
      if (typeMargin === 'C') {
        const vlrVendaWithoutCommission =
          vlr_venda - vlr_venda * (productPrice.comissao / 100);
        margin = Math.round(
          (vlrVendaWithoutCommission * 100) /
            productPrice.product.cost_billing -
            100,
        );
      } else {
        const vlrVendaWithoutCommission =
          vlr_venda - vlr_venda * (productPrice.comissao / 100);
        margin = Math.round(
          100 -
            (productPrice.product.cost_billing * 100) /
              vlrVendaWithoutCommission,
        );
      }
    }

    setProductPrice({
      ...productPrice,
      vlr_venda: Number(vlr_venda),
      vlr_venda_min_c_lib: Number(vlr_venda),
      vlr_venda_min_s_lib: Number(vlr_venda),
      margin: Number(vlr_venda) === 0 ? 0 : margin,
      lucre: productPrice.product.cost_billing
        ? Number(vlr_venda) -
          productPrice.product.cost_billing -
          Number(Number(vlr_venda) * (productPrice.comissao / 100))
        : 100,
    });
  };

  return (
    <>
      <TopMenu />
      <Progress isLoad={isLoad} />
      <div className="flex my-1 py-1">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="card w-6"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="p-fluid grid formgroup">
            <div className="field col-12 my-1 py-0">
              <label htmlFor="product_id">Produto</label>
              <InputAutoComplete
                inputRef={productRef}
                name="product_id"
                value={{
                  value: productPrice.product.id,
                  label: productPrice.product.title,
                }}
                field="label"
                itemTemplate={e => itemTemplateProduct(e)}
                placeholder={'Selecione...'}
                autoFocus={productFocusKeyword}
                readOnly
                disabled
              />
              <Tag
                severity={'info'}
                value={`Estoque.: ${formatCurrency(
                  productPrice.product.stock || 0,
                )} `}
                className="py-0"
              ></Tag>
            </div>

            <div className="field col-12 my-1 py-0">
              <label htmlFor="table_price_id">Tabela de preços</label>
              <InputDropDown
                name="table_price_id"
                placeholder="Selecione..."
                options={[
                  {
                    value: productPrice.table_price_id,
                    label: productPrice.tablePrice?.title || '',
                  },
                ]}
                value={productPrice.table_price_id}
                onChange={e => {
                  setProductPrice({ ...productPrice, table_price_id: e.value });
                }}
                disabled
              />
            </div>

            <Divider />

            <div className="field col-3 my-1 py-0">
              <label htmlFor="cost">Custo</label>
              <InputNumber
                name="cost"
                value={productPrice.product.cost_billing}
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                disabled={true}
                className="surface-200"
              />
            </div>

            <div className="field col-3 my-1 py-0">
              <label htmlFor="margem">Margem</label>
              <InputNumber
                name="margem"
                value={productPrice.margin}
                disabled={productPrice.product.cost_billing === 0}
                onChange={e => handleChangeMargin(Number(e.value))}
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                suffix="%"
              />
            </div>

            <div className="field col-3 my-1 py-0">
              <label htmlFor="commissao">Comissão</label>
              <InputNumber
                name="commissao"
                value={productPrice.comissao}
                onChange={e => handleChangeCommission(Number(e.value))}
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={2}
                maxFractionDigits={2}
                disabled={false}
                className=""
                suffix="%"
              />
            </div>

            <div className="field col-3 my-1 py-0">
              <label htmlFor="vlr_venda">Valor venda</label>
              <InputNumber
                name="vlr_venda"
                value={productPrice.vlr_venda}
                onChange={e => handleChangeVlrVenda(Number(e.value))}
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                disabled={false}
                className=""
              />
            </div>

            <div className="field col-4 my-1 py-0">
              <label htmlFor="vlr_venda_min_s_lib">Valor sem liberação</label>
              <InputNumber
                name="vlr_venda_min_s_lib"
                value={productPrice.vlr_venda_min_s_lib}
                onChange={e =>
                  setProductPrice({
                    ...productPrice,
                    vlr_venda_min_s_lib: Number(e.value),
                  })
                }
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                disabled={false}
                className=""
              />
            </div>

            <div className="field col-4 my-1 py-0">
              <label htmlFor="vlr_venda_min_c_lib">Valor com liberação</label>
              <InputNumber
                name="vlr_venda_min_c_lib"
                value={productPrice.vlr_venda_min_c_lib}
                onChange={e =>
                  setProductPrice({
                    ...productPrice,
                    vlr_venda_min_c_lib: Number(e.value),
                  })
                }
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].price_out
                    : 2
                }
                disabled={false}
                className=""
              />
            </div>

            <div className="field col-4"></div>

            <div className="field col-4 my-1 py-0">
              <label htmlFor="qnt_venda_min">Qnt. min. venda</label>
              <InputNumber
                name="qnt_venda_min"
                value={productPrice.qnt_venda_min}
                onChange={e =>
                  setProductPrice({
                    ...productPrice,
                    qnt_venda_min: Number(e.value),
                  })
                }
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].qnt_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].qnt_out
                    : 2
                }
                disabled={false}
                className=""
              />
            </div>

            <div className="field col-4 my-1 py-0">
              <label htmlFor="qnt_venda_max">Qnt. max. venda</label>
              <InputNumber
                name="qnt_venda_max"
                value={productPrice.qnt_venda_max}
                onChange={e =>
                  setProductPrice({
                    ...productPrice,
                    qnt_venda_max: Number(e.value),
                  })
                }
                mode="decimal"
                placeholder="0,00"
                minFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].qnt_out
                    : 2
                }
                maxFractionDigits={
                  productPrice.product.decimalHouses
                    ? productPrice.product.decimalHouses[0].qnt_out
                    : 2
                }
                disabled={false}
                className=""
              />
            </div>
            <div className="field col-4 my-1 py-0">
              <label htmlFor="">Margem sobre </label>
              <SelectButton
                value={typeMargin}
                options={typeMarginOpts}
                onChange={e => {
                  if (e.value && e.value !== null)
                    setTypeMargin(e.value.toString());
                }}
              />
            </div>

            <Divider />

            <div className="field col-6 my-1 py-0">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={isLoad}
              />
            </div>

            <div className="field col-6 my-1 py-0">
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={isLoad}
              />
            </div>
          </div>
        </Form>
        <div className="card mt-1 ml-2 h-25rem">
          <Chart
            type="doughnut"
            data={chartData}
            options={chartOptions}
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default ProductPriceCreate;
