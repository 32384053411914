import React from 'react';

/** table primereact */
import { DataTable as DataTables, DataTableProps } from 'primereact/datatable';

/** rewriting table component type */
interface DataTableProp extends DataTableProps {
  children: React.ReactElement[];
}

export const DataTable = ({
  children,
  ...props
}: DataTableProp): React.ReactElement => {
  return <DataTables {...props}>{children}</DataTables>;
};
