import { FormHandles } from '@unform/core';

// import useToastContext from '../hooks/toast';
import getValidationErrors from './getErrorsValidation';

/** yup */
import * as Yup from 'yup';

const showErrors = (
  error: any,
  formRef: React.RefObject<FormHandles>,
  toast: any,
) => {
  // yup callback
  if (error instanceof Yup.ValidationError) {
    const errors = getValidationErrors(error);
    formRef.current?.setErrors(errors);
    toast('error', 'Error', error.errors[0]);
  } else {
    toast('error', 'Error', error.response?.data?.error);
  }

  // axios callback

  // express callback
};

export default showErrors;
