import { AutoComplete } from 'primereact/autocomplete';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from '../AppSubmenu';

/** react-router-dom */
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';

type MenuOption = {
  name: string;
  code: string;
};

const AppMenu = (props: any): React.ReactElement => {
  const { state } = useContext(AppContext);

  const [options, setOptions] = useState<Array<MenuOption>>([]);
  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState<Array<MenuOption>>([]);

  const router = useHistory();

  const onInputKeydown = (event: React.KeyboardEvent) => {
    const key = event.which;

    //escape, tab and enter
    if (key === 27 || key === 9 || key === 13) {
      props.onSearchHide(event);
    }
  };

  const openRouter = (e: any) => {
    router.push(e.value.code);
    setSelectedCountry1(null);
  };

  const searchOptions = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredOptions;
      if (!event.query.trim().length) {
        _filteredOptions = [...options];
      } else {
        if (options === undefined) {
          return;
        }

        _filteredOptions = options.filter((options: any) => {
          return options.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      const ordenedFilteredOptions = _filteredOptions.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      setFilteredOptions(ordenedFilteredOptions);
    }, 250);
  };

  const selectedItemTemplate = () => {
    return <div style={{ backgroundColor: 'red' }}>adsadsa</div>;
  };

  useEffect(() => {
    setOptions(state.forms);
  }, [state.forms]);

  return (
    <div className="layout-sidebar" onClick={props.onMenuClick}>
      <>
        {props.menuMode === 'slim' && (
          <Link to="/" className="logo">
            <img
              id="app-logo"
              className="logo-image"
              src="/assets/layout/images/logo-white.svg"
              alt="diamond layout"
            />
            {/* <span className="app-name">ebger</span> */}
          </Link>
        )}
        {props.menuMode !== 'slim' && (
          <Link to="/" className="logo">
            <img
              id="app-logo"
              className="logo-image"
              src="/webger.svg"
              style={{
                width: props.menuMode !== 'slim' ? '100%' : '2rem',
                height: props.menuMode !== 'slim' ? 'auto' : '2rem',
              }}
              alt="diamond layout"
            />
          </Link>
        )}

        <div className="layout-menu-container">
          {props.menuMode === 'static' && (
            <AutoComplete
              value={selectedCountry1}
              suggestions={filteredOptions}
              completeMethod={searchOptions}
              className="w-full"
              field="name"
              placeholder="Buscar..."
              onChange={e => setSelectedCountry1(e.value)}
              optionGroupTemplate={selectedItemTemplate}
              onSelect={e => openRouter(e)}
              onKeyPress={onInputKeydown}
              autoFocus
            />
          )}
          <AppSubmenu
            items={props.model}
            menuMode={props.menuMode}
            parentMenuItemActive
            menuActive={props.active}
            mobileMenuActive={props.mobileMenuActive}
            root
            onMenuitemClick={props.onMenuitemClick}
            onRootMenuitemClick={props.onRootMenuitemClick}
          />
        </div>
      </>
    </div>
  );
};

export default AppMenu;
