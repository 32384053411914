import React, { useRef } from 'react';

/** components */
import { Button } from 'primereact/button';

/** form */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/** yup */
import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import api from '../../../../services/api';

const ReportsNoStock: React.FC = (): React.ReactElement => {
  /** ref */
  const form = useRef<FormHandles>(null);

  /** ref */
  const toast = useRef<any>(null);

  /** showToast */
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string,
  ) => {
    toast?.current?.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  const handleReports = async () => {
    try {
      const { data: response } = await api.get(`/reports/products/no-stock`);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${response.fileName}`,
      );
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        form.current?.setErrors(errors);
        showToast('error', 'Error', error.errors[0]);
      } else {
        showToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <div className="card md:w-6 md:w-full">
        <Form ref={form} onSubmit={handleReports}>
          <div className="p-fluid grid formgrid ">
            <div className="field col-12 md:col-12">
              <Button label="Gerar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportsNoStock;
