import React, { useEffect, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/* components */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';

/** services */
import api from '../../../services/api';

/** utils */
import { format } from 'date-fns';
import { Toast } from 'primereact/toast';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import { currencyToFloat, numberToCurrency } from '../../../utils/number';
import PaymentConditionsHeaderButtons from '../top-menu';

/** menu options */

const PayemntConditionList = (): React.ReactElement => {
  /** state */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');

  /** payment condition */
  const [paymentCondition, setPaymentCondition] = useState<Array<any>>([]);
  const [condition, setCondition] = useState<any>();

  /** dialog */
  const [dialogDeleteConditionPayment, setDialogDeleteConditionPatment] =
    useState(false);

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useToastContext();
  /* navigate */
  const navigate = useHistory();

  /** table template */

  const hideDeleteConditionPaymentDialog = () => {
    setDialogDeleteConditionPatment(false);
  };

  const confirmDeleteCondition = (condtion: any) => {
    setCondition(condtion);
    setDialogDeleteConditionPatment(true);
  };

  const deleteConditionApi = async () => {
    try {
      await api.delete(`/payments-condition/${condition.id}`);
    } catch (err) {
      toast('error', 'Erro', messageRequestError(err));
    }
  };

  const deleteConditionPayment = () => {
    const _conditions = paymentCondition.filter(val => val.id !== condition.id);
    setPaymentCondition(_conditions);
    hideDeleteConditionPaymentDialog();
    setCondition({});
    toast('success', 'Sucesso', 'Condição de pagamento deletada com sucesso!');
    deleteConditionApi();
  };

  const clonePaymentCondition = async (id: string) => {
    try {
      await api.get(`/payments-condition/clone/${id}`);
      await searchPaymentCondition();
    } catch (error: any) {
      toast('warn', 'Alerta', 'Erro ao clonar condição de pagamento!');
    }
  };

  const deletePaymentDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteConditionPaymentDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteConditionPayment}
      />
    </React.Fragment>
  );

  const feesBodyTemplate = (row: any, field: string) => {
    return <span>{numberToCurrency(currencyToFloat(row[field]))}</span>;
  };

  const codeBodyTemplate = (row: any, attr: any) => {
    return <>{attr?.rowIndex + 1}</>;
  };

  const dateBodyColumn = (row: any) => {
    return (
      <span>
        {format(new Date(row.date_on), 'dd/MM/yyyy')} -{' '}
        {format(new Date(row.date_off), 'dd/MM/yyyy')}
      </span>
    );
  };

  const actionBodyTemplate = (row: any) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-1"
          onClick={() => navigate.push(`/payment-condition/edit/${row.id}`)}
        />
        <Button
          icon="pi pi-clone"
          className="p-button-raised p-button-secondary mr-4"
          onClick={() => clonePaymentCondition(row.id)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteCondition(row)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Condições de pagamento</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchPaymentCondition = async () => {
    try {
      const { data } = await api.get('/payments-condition');
      setPaymentCondition(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      toast('warn', 'Alerta', messageRequestError(err));
    }
  };

  useEffect(() => {
    searchPaymentCondition();
  }, []);

  return (
    <>
      <PaymentConditionsHeaderButtons />
      <Toast ref={toast} />
      <div className="card">
        <DataTable
          value={paymentCondition}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} condição de pagamentos"
          globalFilter={globalFilter}
          emptyMessage="Não existe condições de pagamento."
          header={header}
          responsiveLayout="scroll"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column field="title" header="Título" sortable></Column>
          <Column
            header="Parcelas"
            field="qnt_parcelas"
            align={'center'}
            sortable
          ></Column>
          <Column
            header="Juros (%)"
            field="taxa_juros_base"
            body={row => feesBodyTemplate(row, 'taxa_juros_base')}
          ></Column>
          <Column
            field="multa"
            header="Multa"
            body={row => feesBodyTemplate(row, 'multa')}
          ></Column>
          <Column field="crediary.title" header="Crediário"></Column>
          <Column
            header="Ativa/Desativa"
            body={dateBodyColumn}
            align={'center'}
          ></Column>
          <Column
            header="Status"
            body={r => renderColumnStatus(r)}
            align={'center'}
            sortable
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeleteConditionPayment}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deletePaymentDialogFooter}
        onHide={hideDeleteConditionPaymentDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {condition && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{condition?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default PayemntConditionList;
