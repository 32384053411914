import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Progress from '../../components/progress-bar';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';
import messageRequestError from '../../utils/messageRequestError';
import { InputAutoComplete } from '../../components/Inputs/InputAutoComplete';
import IOptionsDTO from '../business/dtos/IOptionsDTO';
import { Divider } from '../../components/Divider';
import { EnumStatus } from '../../enum/EnumStatus';
import { InputText } from '../../components/Inputs/InputText';
import { FormHandles, Scope } from '@unform/core';
import { DataTable } from '../../components/DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../components/DataTableColumns/RenderColumnPosition';
import { validate } from 'uuid';
import { IBusinessDTO } from '../business/dtos/IBusinessDTO';
import { InputMask } from '../../components/Inputs/InputMask';
import renderColumnCpfCnpj from '../../components/DataTableColumns/RenderColumnCpfCnpj';
import renderColumnStatus from '../../components/DataTableColumns/RenderColumnStatus';
import MenuPopUp from '../../components/MenuPopPup';
import formatPhoneNumber from '../../utils/strings/formatPhoneNumber';
import LimpaCpfCnpj from '../../utils/LimpaCpfCnpj';
import copyTextClipboard from '../../utils/strings/copyToClipboard';
import convertIsoDateToString from '../../utils/date/convertIsoDateToString';
import { TabViews } from '../../components/TabPanel';
import { TabPanel } from 'primereact/tabview';
import { CopyButton } from '../../components/CopyToClipboard';
import { AxiosError, AxiosResponse } from 'axios';

interface IUser {
  id: string;
  name: string;
  cpf_cnpj: string;
  phone: string;
  email: string;
  key_user: boolean;
  status: string;
}

interface IBusiness {
  id: string;
  name: string;
  nickname: string;
  cpf_cnpj: string;
  status: string;
  users: IUser[];
  created_at?: string;
  value: string;
  label: string;
}

const emptyBusiness: IBusiness = {
  id: '',
  name: '',
  nickname: '',
  cpf_cnpj: '',
  status: '',
  users: [],
  value: '',
  label: '',
};

const ControlPanel = () => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();
  const toast = useToastContext();

  const today = new Date();

  const [businesses, setBusinesses] = useState<IBusinessDTO[]>([]);
  const [business, setBusiness] = useState<IBusiness>(emptyBusiness);

  useEffect(() => {
    loadUserData();
  }, []);

  const searchBusiness = async (e: any) => {
    if (e.query.length >= 3) {
      setIsLoad(true);
      api
        .post(`/business/search/`, {
          filter: {
            search: e.query,
          },
          pagination: {
            page: 1,
            perPage: 10,
            status: [EnumStatus.ATIVO],
          },
        })
        .then(({ data }) => {
          if (data.length > 0) {
            setBusinesses(
              data.map((i: any) => {
                return {
                  ...i,
                  value: i.id,
                  label: `${i.nickname ? i.nickname : '***'} / ${i.name}`,
                };
              }),
            );
          } else {
            toast('error', 'Erro', 'Nenhuma empresa encontrada!');
            return;
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
          return;
        })
        .finally(() => setIsLoad(false));
    }
  };

  const unblockBusiness = async () => {
    setIsLoad(true);

    await api
      .put(`/business/unblock/${business.id}`)
      .then(({ data }) => {
        if (data) {
          setBusiness({
            ...business,
            status: EnumStatus.ATIVO,
          });
          toast('success', 'Sucesso', 'Empresa desbloqueada com sucesso!');
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setIsLoad(false));
  };

  const blockBusiness = async () => {
    setIsLoad(true);

    await api
      .put(`/business/block/${business.id}`)
      .then(({ data }) => {
        if (data) {
          setBusiness({
            ...business,
            status: EnumStatus.CANCELADO,
          });
          toast('success', 'Sucesso', 'Empresa bloqueada com sucesso!');
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setIsLoad(false));
  };

  const blockUser = async (id: string) => {
    setIsLoad(true);

    await api
      .delete(`/users/disable/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          toast('success', 'Sucesso', 'Usuário bloqueado com sucesso!');
          setBusiness({
            ...business,
            users: business.users.map(u => {
              if (u.id === id) return { ...u, status: EnumStatus.CANCELADO };
              return u;
            }),
          });
        }
      })
      .catch((err: AxiosError) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };
  const unblockUser = async (id: string) => {
    setIsLoad(true);

    await api
      .post(`/users/enable/${id}`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          toast('success', 'Sucesso', 'Usuário desbloqueado com sucesso!');
          setBusiness({
            ...business,
            users: business.users.map(u => {
              if (u.id === id) return { ...u, status: EnumStatus.ATIVO };
              return u;
            }),
          });
        }
      })
      .catch((err: AxiosError) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const clearCacheAll = async () => {
    setIsLoad(true);
    await api
      .post('/cache/clear-all')
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Cache limpo com sucesso!');
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');

    if (u.id && u.id !== '26cba209-30d2-4d1e-83c0-45361f89f939')
      navigate.push('/access');
    if (u) setUser(u);
  };

  const itemTemplateBusiness = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  const mountMenuUsers = (rowData: IUser) => {
    const items: any[] = [];

    if (rowData.status === EnumStatus.ATIVO) {
      items.push({
        label: 'Bloquear usuário',
        icon: 'fa-solid fa-lock',
        command: () => blockUser(rowData.id),
      });
    } else {
      items.push({
        label: 'Desbloquear usuário',
        icon: 'fa-solid fa-lock-open',
        command: () => unblockUser(rowData.id),
      });
    }

    items.push({
      label: 'Copiar id',
      icon: 'fa-solid fa-copy',
      command: async () => {
        const copied = await copyTextClipboard(rowData.id);

        toast(
          copied ? 'success' : 'warn',
          copied ? 'Sucesso' : 'Falha',
          copied
            ? 'Copiado para a área de transferência.'
            : 'Falha ao copiar para a área de transferência.',
        );
      },
    });

    return items;
  };

  const renderColumnUserMenu = (e: IUser) => {
    const mountOpts = mountMenuUsers(e);

    return <MenuPopUp model={mountOpts} rowData={e} setData={() => {}} />;
  };

  const renderColumnName = (e: IUser) => {
    return (
      <div className="flex">
        {e.key_user && <span className="p-tag mr-2 fa-solid fa-key"></span>}
        <p>{e.name}</p>
      </div>
    );
  };

  const renderColumnPhone = (e: IUser) => {
    return (
      <a
        target="_blank"
        href={`https://wa.me/55${LimpaCpfCnpj(e.phone)}`}
        rel="noreferrer"
      >
        <p>{formatPhoneNumber(e.phone)}</p>
      </a>
    );
  };

  return (
    <>
      {user && user.id === '26cba209-30d2-4d1e-83c0-45361f89f939' && (
        <Form
          ref={formRef}
          onSubmit={() => {}}
          className="card"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <Scope path="control_panel">
            <TabViews renderActiveOnly={false}>
              <TabPanel header="Cache">
                <Scope path="cache">
                  <div className="p-fluid grid formgrid">
                    <div className="field col-6 md:col-4">
                      <Button
                        name="clear_cache"
                        onClick={() => clearCacheAll()}
                        label="Limpar cache total"
                        type="button"
                      />
                    </div>
                  </div>
                </Scope>
              </TabPanel>
              <TabPanel header="Empresas">
                <Scope path="empresa">
                  <Progress isLoad={isLoad} className="mb-2" />
                  <div className="p-fluid grid formgrid">
                    <div className="field col-10 md:col-6">
                      <label htmlFor="business">Buscar empresa</label>
                      <InputAutoComplete
                        name="business"
                        suggestions={businesses}
                        value={business}
                        completeMethod={e => searchBusiness(e)}
                        field="label"
                        onChange={e => {
                          setBusiness(e.value);
                        }}
                        itemTemplate={itemTemplateBusiness}
                        placeholder="Buscar empresa (id/Nome/CNPJ)"
                        onFocus={e => e.target.select()}
                      />
                    </div>

                    <div
                      className={`field col-12 md:col-2 flex align-items-end`}
                    >
                      <Button
                        label="Limpar"
                        icon="pi pi-times"
                        type="button"
                        onClick={() => setBusiness(emptyBusiness)}
                      />
                    </div>
                    <div
                      className={`field col-12 md:col-2 flex align-items-end`}
                    >
                      <Button
                        label={
                          business.status === EnumStatus.ATIVO
                            ? 'Bloquear'
                            : 'Desbloquear'
                        }
                        icon={
                          business.status === EnumStatus.ATIVO
                            ? 'fa-solid fa-lock'
                            : 'fa-solid fa-lock-open'
                        }
                        className={`${
                          business.status === EnumStatus.ATIVO
                            ? 'p-button-danger'
                            : 'p-button-success'
                        }`}
                        disabled={!validate(business.id)}
                        type="button"
                        onClick={() => {
                          business.status === EnumStatus.ATIVO
                            ? blockBusiness()
                            : unblockBusiness();
                        }}
                      />
                    </div>
                    <div className="md:col-2"></div>

                    <div className="field col-12 md:col-4">
                      <label htmlFor="name">Nome / Razão Social</label>
                      <InputText
                        name="name"
                        value={business.name}
                        placeholder="Ex.: Indústria ACME LTDA"
                        readOnly
                      />
                    </div>
                    <div className="field col-12 md:col-3">
                      <label htmlFor="nickname">Apelido / Nome Fantasia</label>
                      <InputText
                        name="nickname"
                        value={business.nickname}
                        placeholder="Ex.: ACME"
                        readOnly
                      />
                    </div>
                    <div className="field col-12 md:col-3">
                      <label htmlFor="cpf_cnpj">CPF / CNPJ</label>
                      <InputMask
                        name="cpf_cnpj"
                        value={business.cpf_cnpj}
                        placeholder="Ex.: 12.345.678/9123-45"
                        mask="99.999.999/9999-99"
                        readOnly
                        className="w-10"
                      />
                      <CopyButton text={business.cpf_cnpj} />
                    </div>
                    <div className="field col-12 md:col-2">
                      <label htmlFor="created_at">Data de criação</label>
                      <InputText
                        name="created_at"
                        value={convertIsoDateToString(
                          business.created_at || today.toISOString(),
                        )}
                        className="text-center"
                        placeholder="Ex.: 30/05/2016"
                        readOnly
                      />
                    </div>

                    <div className="field col-12 md:col-4">
                      <label className="w-full" htmlFor="id">
                        b_id
                      </label>
                      <InputText
                        name="id"
                        value={business.id}
                        placeholder="Ex.: 265b70ad-0a54-41c8-91bc-e20b6ae2e11e"
                        readOnly
                        className="w-10"
                      />
                      <CopyButton text={business.id} />
                    </div>

                    <div className="field col-12">
                      <label htmlFor="businesss.users">Usuários</label>
                      <DataTable
                        value={business.users}
                        selectionMode="single"
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        responsiveLayout="scroll"
                        emptyMessage="Nenhum usuário encontrado."
                        size="small"
                      >
                        <Column
                          field="#"
                          header="#"
                          body={renderColumnPosition}
                          align={'center'}
                        />
                        <Column
                          field="name"
                          header="Nome"
                          body={renderColumnName}
                        />
                        <Column field="email" header="E-mail" />
                        <Column
                          field="cpf_cnpj"
                          header="CPF/CNPJ"
                          body={e => renderColumnCpfCnpj(e.cpf_cnpj)}
                        />
                        <Column
                          field="phone"
                          header="Telefone"
                          body={renderColumnPhone}
                        />
                        <Column
                          field="status"
                          header="Status"
                          body={renderColumnStatus}
                        />
                        <Column header="*" body={renderColumnUserMenu} />
                      </DataTable>
                    </div>
                  </div>
                </Scope>
              </TabPanel>
            </TabViews>
          </Scope>
        </Form>
      )}
    </>
  );
};

export default ControlPanel;
