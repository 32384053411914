import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { endOfMonth, startOfMonth } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Divider } from '../../../../components/Divider';
import { InputAutoComplete } from '../../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../../components/Inputs/InputCalendar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import BillsToReceivableHeader from '../../header';
import formatDecimal from '../../../../utils/numbers/FormatDecimal';
import useSearchCustomer from '../../../../hooks/useSearchCustomer';

const BillsreceivableDropByPeriod: React.FC = () => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const msgs = useRef<Messages>(null);

  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [customers, setCustomers] = useState<Array<IOptionsDTO>>([]);
  const [customer, setCustomer] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });

  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje as Date),
  );

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        const opts = customers.map((i: any) => {
          return {
            value: i.id,
            label: `${i.name} - ${
              i.cpf_cnpj.toString().length === 11
                ? cpf.format(i.cpf_cnpj)
                : cnpj.format(i.cpf_cnpj)
            }`,
          };
        });

        setCustomers(opts);
      }
    } catch (err: any) {
      toast('error', 'Erro', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      d.customer_id = customer.value;
      d.date_start = dateStart;
      d.date_end = dateEnd;

      const schemaValidation = Yup.object().shape({
        customer_id: Yup.string()
          .uuid('O id do cliente é inválido!')
          .required('Informe um cliente!'),
        date_start: Yup.date().required('Data início é obrigatória!'),
        date_end: Yup.date().required('Data fim é obrigatória!'),
      });
      await schemaValidation.validate(d, { abortEarly: false });

      showMessage();

      await api
        .post('/bills-receivable/v2/drop/by/period', {
          customer_id: customer.value,
          period: { start: dateStart, end: dateEnd },
        })
        .then(({ data }) => {
          if (data.dropByPeriod > 0) {
            toast(
              'success',
              'Sucesso',
              'Baixas realizadas com sucesso! Gere o relatório de contas a receber e confira a baixa de todos os documentos!',
              5000,
            );
            toast(
              'success',
              'Sucesso',
              `Total das baixas: ${formatDecimal(data.dropByPeriod)}`,
              5000,
            );
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Erro', err.errors[0]);
        return;
      }

      toast('error', 'Erro', messageRequestError(err), 10000);
    } finally {
      setIsLoad(false);
      hideMessage();
    }
  };

  const showMessage = () => {
    msgs.current?.show({
      severity: 'info',
      summary: 'Atenção ',
      detail:
        'Essa rotina pode demorar 5 minutos ou mais para ser realizada. Aguarde a execução da rotina!',
      sticky: true,
      closable: true,
    });
  };

  const hideMessage = () => {
    msgs.current?.clear();
  };

  return (
    <>
      <BillsToReceivableHeader />
      <Messages ref={msgs} />

      <div className="card w-6">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="field col-12">
            <label htmlFor="customer_id">Cliente</label>
            <InputAutoComplete
              name="customer_id"
              suggestions={customers}
              value={customer}
              completeMethod={e => searchCustomer(e.query)}
              onChange={e => setCustomer(e.value)}
              onSelect={e => setCustomer(e.value)}
              field="label"
              itemTemplate={itemTemplate}
              placeholder="Buscar aqui..."
            />
          </div>

          <div className="field col-6">
            <label htmlFor="date_start">Data início</label>
            <Calendar
              name="date_start"
              placeholder="00/00/00"
              dateFormat="d/m/yy"
              value={dateStart}
              onChange={e => setDateStart(e.value as Date)}
              showIcon={true}
              iconPos="right"
            />
          </div>

          <div className="field col-6">
            <label htmlFor="date_end">Data fim</label>
            <Calendar
              name="date_end"
              placeholder="00/00/00"
              dateFormat="d/m/yy"
              value={dateEnd}
              onChange={e => setDateEnd(e.value as Date)}
              minDate={dateStart as Date}
              showIcon={true}
              iconPos="right"
            />
          </div>

          <Divider />

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              loading={isLoad}
              className="p-button-danger"
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={isLoad ? 'Aguarde...' : 'Baixar'}
              icon="fa-solid fa-hand-holding-dollar"
              loading={isLoad}
              className="p-button-success"
              type="submit"
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default BillsreceivableDropByPeriod;
