import React, { useRef } from 'react';

/** yup */
import * as Yup from 'yup';

/** unform */
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

/* utils */
import getValidationErrors from '../../../utils/getErrorsValidation';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/** components */
import { Button } from 'primereact/button';
import { InputText } from '../../../components/Inputs/InputText';
import useToastContext from '../../../hooks/toast';

const BankCreate = (): React.ReactElement => {
  /** redirect */
  const navigate = useHistory();

  const addToast = useToastContext();

  /** ref */
  const formRef = useRef<FormHandles>(null);

  const handleCreateBank = async (data: any) => {
    try {
      const schemaApplicationCreate = Yup.object({
        codigo: Yup.number().required('Por favor, Informe um código!'),
        nome: Yup.string().required('Por favor, Informe uma nome!'),
        url: Yup.string().url('Informe uma url Válida!'),
      });

      await schemaApplicationCreate.validate(data, { abortEarly: false });
      await api.post('/bancos', { ...data, status: 'A' });
      addToast('success', 'Ok', 'Banco editado com sucesso!');
      navigate.replace('/banks/list');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        addToast('error', 'Error', error.errors[0]);
      } else {
        addToast('error', 'Error', error.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Button
        label="Listar"
        className="mb-3"
        icon="fa-solid fa-list"
        onClick={() => navigate.push('/banks/list')}
      />

      <div className="card md:w-6 sm:w-full">
        <Form ref={formRef} onSubmit={handleCreateBank}>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col-4">
              <label htmlFor="basic">Código</label>
              <InputText name="codigo" placeholder="000000" />
            </div>
            <div className="field col-12 md:col-8">
              <label htmlFor="basic">Nome</label>
              <InputText name="nome" placeholder="nome..." />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3">
            <div className="field col-12 md:col">
              <label htmlFor="basic">URL</label>
              <InputText placeholder="Ex: https://..." name="url" />
            </div>
          </div>
          <div className="p-fluid grid formgrid mb-3 flex justify-content-between">
            <div className="field col-12 md:col-5">
              <Button
                label="Cancelar"
                className="p-button-raised p-button-danger"
              />
            </div>
            <div className="field col-12 md:col-5">
              <Button label="Salvar" />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BankCreate;
