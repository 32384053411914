import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { Dialog } from '../../../../components/Modal';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import { ICrmCard } from '../dtos/ICrmDTO';
interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  card: ICrmCard;
  panelId: string;
}

const DialogPriority = ({ isOpen, onRequestClose, card, panelId }: IProps) => {
  const toast = useToastContext();

  const [priority, setPriority] = useState(card.priority);

  const [load, setLoad] = useState(false);

  const handleSubmit = async (d: any) => {
    setLoad(true);
    await api
      .put(`/crm/card`, {
        data: {
          id: card.id,
          column_id: card.column_id,
          title: card.title,
          description: card.description,
          value: card.value,
          priority: priority,
          panel_id: panelId,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          onRequestClose();
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  return (
    <Dialog
      header="Qual a prioridade desse card?"
      visible={isOpen}
      style={{ width: 512 }}
      onHide={() => onRequestClose()}
    >
      <Form
        ref={null}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid"
      >
        <div className="field col-12">
          <label htmlFor="priority">Prioridade</label>
          <InputDropDown
            name="priority"
            value={priority}
            onChange={e => setPriority(e.value)}
            options={[
              { value: 'B', label: 'Baixa' },
              { value: 'M', label: 'Média' },
              { value: 'A', label: 'Alta' },
            ]}
          />
        </div>
        <div className="field col-12">
          <Button
            label="Salvar"
            icon="fa-solid fa-check"
            type="submit"
            className="p-button-success"
          />
        </div>
      </Form>
    </Dialog>
  );
};

export default DialogPriority;
