import React, { createContext, useReducer, useEffect } from 'react';

/**reducer  */
import {
  SettingReducerApp,
  initialState,
  AppSetting,
  ActionProps,
} from '../reducers/AppConfigReducer';

type ContextType = {
  state: AppSetting;
  dispatch: React.Dispatch<any>;
};

export const AppSettingContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

export const mainReducer = (
  state: AppSetting,
  action: ActionProps,
): AppSetting => ({
  ...SettingReducerApp(state, action),
});

type Props = { children: React.ReactNode };

const AppSettingProvider: React.FC<Props> = ({
  children,
}): React.ReactElement => {
  const localState = JSON.parse(localStorage.getItem('app') || '{}');

  const [state, dispatch] = useReducer(mainReducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem('app', JSON.stringify(state));
  }, [state]);

  return (
    <AppSettingContext.Provider value={{ state, dispatch }}>
      {children}
    </AppSettingContext.Provider>
  );
};

export default AppSettingProvider;
