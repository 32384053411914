import React, { useEffect, useRef, useState } from 'react';

/* react-router-dom */

/* components */
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';

/** services */
import { Dialog } from '../../../components/Modal';
import api from '../../../services/api';

/** menu options */

const CsosnList = (): React.ReactElement => {
  /** state */

  const [globalFilter, setGlobalFilter] = useState('');

  /** csosn */
  const [csosn, setCsosn] = useState<Array<any>>([]);
  const [cso, setCso] = useState<any>();

  /** loading */
  const [loading, setLoading] = useState(true);

  /* refs */
  const toast = useRef<any>(null);

  /** dialog */
  const [dialogDeletCsosnDialog, setDialogDeletCsosn] = useState(false);

  const codeBodyTemplate = (rowData: any, attr: any) => {
    return (
      <>
        <span>{attr?.rowIndex + 1}</span>
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.status === 'A' ? (
          <Checkbox checked={true}></Checkbox>
        ) : (
          <i className="pi pi-times text-pink-700"></i>
        )}
      </>
    );
  };

  const confirmDeleteCsosn = (brand: any) => {
    setCso(brand);
    setDialogDeletCsosn(true);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex justify-content-between">
        <Button icon="pi pi-pencil" className="p-button-raised mr-4" disabled />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => confirmDeleteCsosn(rowData)}
        />
      </div>
    );
  };

  const hideDeleteCsosnDialog = () => {
    setDialogDeletCsosn(false);
  };

  const deleteCsosnApi = async () => {
    try {
      await api.delete(`/tributos-csosn/${cso.id}`);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: 'Erro ao deletar',
        life: 3000,
      });
    }
  };

  const deleteCsosn = () => {
    const _csosn = csosn.filter(val => val.id !== cso.id);
    setCsosn(_csosn);
    setDialogDeletCsosn(false);
    setCso(undefined);
    toast.current?.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Adicional Deletado',
      life: 3000,
    });
    deleteCsosnApi();
  };

  const deleteCsosnDialogFooter = (
    <React.Fragment>
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-text p-button-danger"
        onClick={hideDeleteCsosnDialog}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCsosn}
      />
    </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">CSOSN</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  const searchTribCsosn = async () => {
    try {
      const { data } = await api.get(`/tributos-csosn`);
      setCsosn(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Falha',
        detail: error.response?.data?.error,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    searchTribCsosn();
  }, []);

  return (
    <>
      <div className="card">
        <DataTable
          value={csosn}
          dataKey="id"
          paginator
          rows={10}
          loading={loading}
          loadingIcon="pi pi-spinner text-primary"
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} csosn"
          globalFilter={globalFilter}
          emptyMessage="Não existe CSOSN."
          header={header}
          responsiveLayout="scroll"
          size="small"
        >
          <Column
            field="code"
            header="N°"
            body={codeBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column
            field="codigo"
            header="Código"
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column header="Descrição" field="descricao"></Column>
          <Column
            header="Status"
            body={statusBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}
          ></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Dialog
        visible={dialogDeletCsosnDialog}
        style={{ width: '450px' }}
        header="Confirmar"
        modal
        footer={deleteCsosnDialogFooter}
        onHide={hideDeleteCsosnDialog}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {cso && (
            <span>
              Tem certeza de que deseja excluir&nbsp;<b>{cso?.title}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CsosnList;
