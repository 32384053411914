export type AppLevelAccessProps = {
  accessLevel: Array<string>;
};

export const initialState: AppLevelAccessProps = {
  accessLevel: [],
};

export type ActionProps = {
  payload: AppLevelAccessProps;
  type: string;
};

export const AccessLevelReducerApp = (
  state: AppLevelAccessProps,
  action: ActionProps,
): AppLevelAccessProps => {
  switch (action.type) {
    case 'setAccessLevel':
      return { ...state, accessLevel: action.payload.accessLevel };
  }
  return state;
};
